import {connect} from "react-redux";
import './content.css'
import {Link, useHistory} from 'react-router-dom'
import CSV from '../../../../../img/CSV.png'
import Excel from '../../../../../img/Excel.png'
import Print from '../../../../../img/Print.png'
import Data from '../../../../../img/Data.png'
import Pdf from '../../../../../img/PDF.png'
import Edit from '../../../../../img/Edit.png'
import Delete from '../../../../../img/Delete.png'
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {IoIosArrowDown} from 'react-icons/io'
import users from "../../../../../reducer/users";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import XaridReducer, {getXaridProductType} from "../../Haridlar/reducer/XaridReducer";
import contentReducer, {getContent, getContentById, deleteContent,getContentByPage} from "../../../../../reducer/contentReducer";
import Korish from "../../../../../img/Korish.png";
import ContentReducer from "../../../../../reducer/contentReducer";
import Loading from "../../../../Loading";
import ModalLoading from "../../../../ModalLoading";
import {Box, TablePagination} from "@mui/material";
import formatDate from "../../../../../util";

function Content({
                     contentReducer,
                     getContent,
                     users,
                     XaridReducer,
                     getXaridProductType,
                     getContentById,
                     deleteContent,
                     getContentByPage
                 }) {

    const {t} = useTranslation()
    const [input, setInput] = useState(
        {
            search: '',
            typeName: '',
            typeAmount: '',
            val: [[]],
        })

    const [mainBranchId,setMainBranchId] = useState(null)

    useEffect(()=>{
        setMainBranchId(users.branchId)
    },[])




    const [active, setActive] = useState(false)
    const [active2, setActive2] = useState(false)

    function toggle() {
        setActive(!active)
        setSearchContent(null)
        setComboArray([])
        setSelectedProduct(null)
        setSelectedContent(null)
    }

    function toggle2() {
        setActive2(!active2)

    }


    const history = useHistory()


    useEffect(() => {
        getXaridProductType(mainBranchId ? mainBranchId : users.branchId)
    }, [mainBranchId])


    const [searchContent, setSearchContent] = useState(null)
    const [ComboArray, setComboArray] = useState([])

    const [selectedProduct, setSelectedProduct] = useState(null)
    const [selectedContent, setSelectedContent] = useState(null)

    function pushesh(item) {
        setSelectedProduct({
            name: item.name,
            productId: item.productId,
            productTypePriceId: item.productTypePriceId,
            measurement: item.measurementName
        })
        setSearchContent(null)
        setComboArray([])
        setproductActive(false)
    }

    function pusheshContent(item) {
        setSelectedContent({
            name: item.product ? item.product?.name : item.productTypePrice?.name,
            id: item.id
        })
        setcontentActive(false)
    }



    function save() {
        history.push(`/main/production/addContent/${selectedProduct.productId}/${selectedProduct.productTypePriceId}/${selectedProduct.name}/${selectedProduct.measurement}/${selectedContent ? selectedContent.id : null}/${mainBranchId ? mainBranchId : users.branchId}`)
        setLoading(false)
    }

    useEffect(()=>{
        if (contentReducer.saveBoolean){
            input.typeName = ''
            input.val = [[]]
            let b = {...input}
            setInput(b)
        }
    },[contentReducer.current])
    // function onChangeSearch(e) {
    //     setSearchContent(e.target.value)
    //     let a = []
    //     XaridReducer.xaridMahsulot.filter(val => {
    //         if (e.target.value === '') {
    //             setComboArray([])
    //         } else if (val.name.toUpperCase().includes(e.target.value.toUpperCase())) {
    //             a.push(val)
    //         } else if (val.barcode.includes(e.target.value)) {
    //             a.push(val)
    //         }
    //
    //     })
    //     setComboArray(a)
    // }
    function onChangeSearch(e) {
        setSearchContent(e.target.value);
        let a = XaridReducer.xaridMahsulot?.filter((val) => {
            if (e.target.value === '') {
                return true; // Include all items when search is empty
            } else if (
                val.name.toUpperCase().includes(e.target.value.toUpperCase()) ||
                val.barcode.includes(e.target.value)
            ) {
                return true; // Include items matching the search input
            }
            return false; // Exclude items that don't match the search input
        });
        setComboArray(a);
    }

    const [deletemodal, setdeletemodal] = useState(false)


    const [editId, setEditId] = useState(null)

    const [productActive, setproductActive] = useState(false)
    const [contentActive, setcontentActive] = useState(false)
    const [contentProduct, setcontentProduct] = useState(null)
    const [deletecurrent,setdeletecurrent] = useState(false)
    function editt(id) {
        history.push(`/main/production/addContent/${id}/${mainBranchId ? mainBranchId : users.branchId}`)
    }

    const [deleteID, setdeletID] = useState('')

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(5);
    const [query, setQuery] = useState(null);


    const handleQueryChange = (event) => {
        event.persist();
        setQuery(event.target.value === '' ? null : event.target.value);
        getContentByPage({
            branchId:mainBranchId,
            params:{
                page:page,
                size:limit,
                name:event.target.value === '' ? null : event.target.value
            }
        })
    };

    const handlePageChange = (_event, newPage) => {
        setPage(newPage);
        getContentByPage({
            branchId:mainBranchId,
            params:{
                page:newPage,
                size:limit,
                name:query ?query:null
            }
        })
    };

    const handleLimitChange = (event) => {
        setLimit(parseInt(event.target.value));
        getContentByPage({
            branchId:mainBranchId,
            params:{
                page:page,
                size:parseInt(event.target.value),
                name:query ? query :null
            }
        })
    };


    useEffect(() => {
        getContent(mainBranchId ? mainBranchId : users.branchId)
        getContentByPage({
            branchId:mainBranchId ? mainBranchId : users.branchId,
            params:{
                page:page,
                size:limit,
                name:null
            }
        })
    }, [contentReducer.current,mainBranchId])


    function changeBranchId(e){
        setMainBranchId(e.target.value)
        setSearchContent(null)
        setComboArray([])
        setSelectedProduct(null)
        setSelectedContent(null)
        setQuery('')
    }


    function deleteType() {
        deleteContent(deleteID)
        setLoading(false)
        deleteModaltoggle()
        setdeletID('')
        setdeletecurrent(!deletecurrent)
    }

    useEffect(()=>{
        if (deletecurrent===true){
            getContent(mainBranchId ? mainBranchId : users.branchId)
            setdeletecurrent(false)
        }else {
        }
    },[])
    function deleteModaltoggle(productId) {
        setdeletID(productId)
        setdeletemodal(!deletemodal)
    }
    function viewContentOne(id) {
        getContentById(id)
        toggle2()
    }
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setLoading(true)
    }, [contentReducer.getBoolean])
    useEffect(() => {
        setLoading(false)
    }, [])

    return (
        <div className="col-md-12 mt-4 mb-4">
            <div className="textHeaderBL">
                <h2>{t('num.29')}</h2>
            </div>
            <div className="rowStyleBL">
                <div className="qoshish">
                    <h5></h5>
                    {
                        users.addContent ?
                            <button className={'btn btn-primary'} onClick={toggle}>+ {t('Buttons.2')}</button>
                            : ''
                    }
                </div>
                {
                    users.viewContent ?
                        loading?
                            contentReducer.contentPage?
                        <div>
                            <div className="izlashBL">
                                <div className="izlashBox1">
                                    <button><img src={CSV} alt=""/> Export CSV</button>
                                    <button><img src={Excel} alt=""/> Export Excel</button>
                                    <button><img src={Print} alt=""/> Print</button>
                                    <button><img src={Pdf} alt=""/>Export PDF</button>

                                </div>
                                {
                                    users.branches.length > 1  ?
                                        <div className="izlashBox2">
                                            <select name="" id="" onChange={changeBranchId} value={mainBranchId}>
                                                {
                                                    users.branches.map(item=>
                                                        <option value={item.id}>{item.name}</option>
                                                    )
                                                }
                                            </select>
                                        </div> : ''
                                }

                                <div className="izlashBox2">
                                    <input type="text" placeholder={t('num.37')}
                                           onChange={handleQueryChange} value={query}
                                    />
                                </div>
                            </div>

                            <div className="table-responsive  mb-4">
                                <table className='table  table-bordered mt-4'>
                                    <thead>
                                    <tr>
                                        <th>T/R</th>
                                        <th>{t('num.29')}</th>
                                        <th>{t('num.38')}</th>
                                        <th>{t('num.8')}</th>
                                        <th>{t('num.11')}</th>
                                        <th>{t('num.39')}</th>
                                        <th>{t('num.40')}</th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    {
                                        contentReducer.contentPage?.content
                                            .map((item, index) =>
                                                <tr key={item.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{ item.product ? item?.product?.name :item?.productTypePrice?.name}</td>
                                                    <td>{item.product ?  item?.product?.category?.name : item?.productTypePrice?.product?.category?.name}</td>
                                                    <td>{item?.quantity} {item.product ?  item?.product?.measurement?.name :item?.productTypePrice?.product?.measurement?.name}</td>
                                                    <td>{item?.totalPrice}</td>
                                                    <td>{parseFloat(item?.totalPrice / item?.quantity).toFixed(2)} so'm</td>
                                                    <td className={'gap-3'}>
                                                        {
                                                            users.viewContent ?
                                                                <button onClick={() => viewContentOne(item.id)}
                                                                        className='btn btn-primary'>
                                                                    <img
                                                                        src={Korish}
                                                                        alt=""/> {t('Buttons.8')}
                                                                </button> : ''
                                                        }
                                                        {
                                                            users.editContent ?
                                                                <button className='btn btn-success'
                                                                        onClick={() => editt(item.id)}>
                                                                    <img
                                                                        src={Edit} alt=""/> {t('Buttons.1')}
                                                                </button> : ''
                                                        }
                                                        {
                                                            users.deleteContent ?
                                                                <button className='btn btn-danger'
                                                                        onClick={() => deleteModaltoggle(item.id)}>
                                                                    <img src={Delete} alt=""/> {t('Buttons.3')}
                                                                </button> : ''
                                                        }


                                                    </td>


                                                </tr>)
                                    }

                                    </tbody>
                                </table>
                                <Box p={2}>
                                    <TablePagination
                                        component="div"
                                        count={contentReducer.contentPage ? contentReducer.contentPage.totalElements : 0}
                                        onPageChange={handlePageChange}
                                        onRowsPerPageChange={handleLimitChange}
                                        page={page}
                                        rowsPerPage={limit}
                                        rowsPerPageOptions={[10, 20, 30]}
                                    />
                                </Box>
                            </div>
                        </div>:<div className={'border border-2'}>
                                    <h4 className={'text-center'}>{contentReducer.message || t('Darslik.mtt')}</h4>
                                </div> :<Loading/> : ''
                }
                <Modal isOpen={deletemodal} toggle={deleteModaltoggle}>
                    <ModalBody>
                        <h5>{t('Buttons.12')} ?</h5>
                    </ModalBody>
                    <ModalFooter>
                        <button onClick={() => deleteType()}
                                className={'btn btn-outline-primary'}>{t('Buttons.3')}</button>
                        <button onClick={() => {
                            setdeletemodal(!deletemodal)
                            setdeletID('')
                        }}
                                className={'btn btn-outline-primary'}>{t('Buttons.7')}</button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={active} toggle={toggle}>
                    <ModalHeader>
                        {t('num.41')}
                    </ModalHeader>
                    <ModalBody>
                        <h5>{t('num.41')}</h5>
                        <div className={'mb-3'}>
                            <button onClick={() => setproductActive(prev => !prev)} className={'content-btn'}>
                                {
                                    selectedProduct ? selectedProduct.name : 'Tanlang'
                                }

                                <IoIosArrowDown className={productActive ? 'content-icon-rotate' : 'content-icon'}/>
                            </button>
                            {
                                productActive === true ? <div className={'content-search '}>
                                    <div className={'position-relative'}>
                                        {
                                            users.branches.length > 1  ?
                                                <div className="izlashBox2 mb-2 ">
                                                    <select className={'form-control'} name="" id="" onChange={changeBranchId} value={mainBranchId}>
                                                        {
                                                            users.branches.map(item=>
                                                                <option value={item.id}>{item.name}</option>
                                                            )
                                                        }
                                                    </select>
                                                </div> : ''
                                        }
                                        <input className={'content-input form-control'} value={searchContent}
                                               onChange={onChangeSearch} type="text"/>
                                        {
                                            ComboArray.length !== 0 ?
                                                <div className={'combo-trade-array position-absolute z-index'} style={{maxHeight:'300px',overflowY:'scroll'}}>
                                                    {
                                                        ComboArray.map(item =>
                                                            <p onClick={() => pushesh(item)}>
                                                                {item.name + ' '+ item.barcode}
                                                            </p>
                                                        )

                                                    }
                                                </div>
                                                : searchContent === null ?
                                                    <p className={'p-0 m-0'}>Please enter 1 or more character</p>
                                                    : <p className={'p-0 m-0'}>No result found</p>
                                        }
                                    </div>
                                </div> : ''
                            }

                        </div>
                        <h5>Tarkibdan nusxa olish</h5>
                        <div>
                            <button onClick={() => setcontentActive(prev => !prev)} className={'content-btn'}>
                                {
                                    selectedContent ? selectedContent.name : 'Mavjud emas'
                                }

                                <IoIosArrowDown className={contentActive ? 'content-icon-rotate' : 'content-icon'}/>
                            </button>
                            {
                                contentActive === true ?
                                    <div className={'content-search p-0 '}>
                                    <div className={'position-relative p-0 '} >
                                        {
                                            contentReducer.content ?
                                            contentReducer?.content?.length !== 0 ?
                                                <div className={' bg-white p-0 position-absolute z-index'} style={{maxHeight:'300px',overflowY:'scroll'}}>
                                                    <button
                                                        className={`border-top-0 content-btn ${selectedContent === null ? 'active-content' : 'content-hover'}`}
                                                        onClick={() => {
                                                            setSelectedContent(null)
                                                            setcontentActive(false)
                                                        }}>
                                                        {t('num.42')}
                                                    </button>
                                                    {
                                                        contentReducer.content.map(item =>
                                                            <button key={item.id}
                                                                    className={`content-btn ${selectedContent ? selectedContent.id === item.id ? 'active-content' : 'content-hover' : 'content-hover'}`}
                                                                    onClick={() => pusheshContent(item)}>
                                                                {item.product ? item?.product?.name : item?.productTypePrice?.name}
                                                            </button>
                                                        )

                                                    }
                                                </div>
                                                : searchContent === null :''
                                        }
                                    </div>
                                </div> : ''
                            }
                        </div>

                    </ModalBody>
                    <ModalFooter>
                        <button className={'btn btn-primary'} onClick={save}>{t('Buttons.6')}</button>
                        <button className={'btn btn-outline-primary'} onClick={toggle}>{t('Buttons.7')}</button>
                    </ModalFooter>
                </Modal>


            </div>

            <Modal isOpen={active2} toggle={toggle2} style={{maxWidth: '600px', width: '100%'}}>
                <ModalHeader>
                    {
                        contentReducer.oneContent ?
                            <h6>{
                                contentReducer.oneContent.product ?
                                    contentReducer.oneContent.product.name : contentReducer.oneContent.productTypePrice.name
                            }</h6> : ''
                    }
                </ModalHeader>
                <ModalBody>
                    <table className={'table table-bordered table-striped'}>
                        <thead className={'bg-success text-white fw-bold'}>
                        <tr>
                            <td>{t('num.29')}</td>
                            <td>{t('num.28')}</td>
                            {/*<td>{t('num.43')}</td>*/}
                            <td>{t('num.31')}</td>
                            <td>{t('num.32')}</td>
                            <td>{t('num.11')}</td>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            contentReducer.contentProductList ?
                                contentReducer.contentProductList.map(item =>
                                    <tr>
                                        <td>{item.product ? item.product?.name : item?.productTypePrice?.name}</td>
                                        <td>{item.quantity} {item.product ? item.product?.measurement?.name : item?.productTypePrice?.measurement?.name}</td>
                                        {/*<td></td>*/}
                                        <td>
                                            <input type="checkbox" disabled={true} checked={!item.byProduct}/>
                                        </td>
                                        <td>
                                            <input type="checkbox" disabled={true} checked={item.byProduct}/>
                                        </td>
                                        <td>{item.totalPrice} {t('num.12')}</td>
                                    </tr>) : ''
                        }
                        </tbody>
                    </table>
                    <div className="row ">
                        <div className="col-md-12 mb-4 d-flex justify-content-end">
                            <div className="col-md-6 d-flex align-items-center justify-content-end">
                                <h6 className={'p-0 m-0 me-1'}>{t('num.20')}</h6>
                                {
                                    contentReducer.oneContent ?
                                        <p className={'p-0 m-0'}>  {
                                            contentReducer.oneContent.contentPrice} {t('num.12')}</p> : ''
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 d-flex flex-wrap">
                            <div className="col-md-12 align-items-center d-flex justify-content-end">
                                <h6 className={'p-0 m-0 me-1'}>{t('num.44')} </h6>
                                {
                                    contentReducer.oneContent ?
                                        <p className={'p-0 m-0 '}> {
                                            contentReducer.oneContent.quantity}
                                            {contentReducer.oneContent.product ? contentReducer.oneContent.product?.measurement?.name : contentReducer.oneContent?.productTypePrice?.measurement?.name}</p> : ''
                                }
                            </div>
                            <div className="col-md-12 d-flex align-items-center justify-content-end">
                                <h6 className={"p-0 m-0 me-1"}>{t('num.45')}</h6>
                            </div>
                            {
                                contentReducer.costGetDtoList ?
                                    contentReducer.costGetDtoList.map(item=>
                                        <div className="col-md-12 d-flex align-items-center justify-content-end">
                                            <h6 className={"p-0 m-0 me-1"}>{item.costTypeName}:</h6>

                                                    <p className={"p-0 m-0"}>{item.sum} {t('num.12')}</p>

                                        </div>)

                                    :''
                            }
                            <div className="col-md-12 d-flex align-items-center justify-content-end">
                                <h6 className={"p-0 m-0 me-1"}>{t('num.46')}</h6>
                                {
                                    contentReducer.oneContent ?
                                        <p className={"p-0 m-0"}>  {
                                            contentReducer.oneContent.cost}
                                            ({contentReducer.oneContent.costEachOne ? 'Narxga' : 'Har biriga'}) {t('num.12')}</p> : ''
                                }
                            </div>
                            <div className="offset-6 col-md-6 d-flex align-items-center justify-content-end">
                                <h6 className={'p-0 m-0 me-1'}>
                                    {t('num.47')}
                                </h6>
                                {
                                    contentReducer.oneContent ?
                                        <p className={'p-0 m-0'}>  {
                                            contentReducer.oneContent.totalPrice} {t('num.12')}
                                        </p> : ''
                                }
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button onClick={toggle2} className={'btn btn-primary'}>{t('num.48')}</button>
                </ModalFooter>
            </Modal>

        </div>)
}

export default connect((XaridReducer,users, contentReducer), {
    getXaridProductType, getContent, getContentById, deleteContent,getContentByPage
})(Content)
