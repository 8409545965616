import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../api";
import {toast} from "react-toastify";

const slice = createSlice({
    name: 'project',
    initialState: {
        project:null,
        projectPage:null,
        projectOne: null,
        projectStatus: null,
        content: null,
        current:false,
        getBoolean:false,
        saveBoolean:false,
        message:'',
        static:null
    },
    reducers: {
        getFrom: (state, action) => {
            if (action.payload.success){
                state.project = action.payload.object
            }
            else{
                state.project = null
                state.message = action.payload.message
            }
            state.getBoolean = !state.getBoolean
            state.saveBoolean = false
        },
        getStatic:(state,action)=>{
          if (action.payload.success){
              state.static = action.payload.object
          }
          else{
              state.static = null
          }
        },
        getFromPage: (state, action) => {
            if (action.payload.success){
                state.projectPage = action.payload.object
            }
            else{
                state.projectPage = null
                state.message = action.payload.message
            }
            state.getBoolean = !state.getBoolean
            state.saveBoolean = false
        },
        getOne:(state,action)=>{
            if (action.payload.success){
                state.projectOne = action.payload.object
            }else {
                state.message = action.payload.message
            }
            state.getBoolean =!state.getBoolean
            state.saveBoolean=false
        },
        getProjectStatus:(state,action)=>{
            if (action.payload.success){
                state.projectStatus = action.payload.object
            }else {
                state.message = action.payload.message
            }
            state.getBoolean =!state.getBoolean
            state.saveBoolean=false
        },
        savefrom: (state,action) => {
            if (action.payload.success){
                toast.success('Saqlandi')
                state.saveBoolean = true
            }
            else{
                toast.error(action.payload.message)
            }
            state.current=!state.current
        },
        editfrom: (state,action) => {
            if (action.payload.success){
                toast.success('edited')
                state.saveBoolean = true
            }
            else{
                toast.error(action.payload.message)
            }
            state.current=!state.current

        },
        deletefrom:(state,action)=>{
            if(action.payload.success){
                toast.success('Ruyhatdan o`chirildi')
            }
            else {
                toast(action.payload.message)
            }
            state.current=!state.current
        }
    }
});

export const getProject=(data)=>apiCall({
    url: `/project/get-by-branchId/${data.branchId}`,
    method:'get',
    params:data.params,
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type,
});
export const getStaticId=(data)=>apiCall({
    url: `/project/progress/`+data,
    method:'get',
    onSuccess: slice.actions.getStatic.type,
    onFail: slice.actions.getStatic.type,
});
export const getProjectPageAble=(data)=>apiCall({
    url: `/project/get-by-branch-sorted/${data.branchId}`,
    method:'get',
    params:data.params,
    onSuccess: slice.actions.getFromPage.type,
    onFail: slice.actions.getFromPage.type,
});
export const getProjectPageByName=(data)=>apiCall({
    url: `/project/get-by-name/${data.name}/${data.branchId}`,
    method:'get',
    params:data.params,
    onSuccess: slice.actions.getFromPage.type,
    onFail: slice.actions.getFromPage.type,
});
    export const getOneProject=(data)=>apiCall({
    url: '/project/get-one/'+data,
    method:'get',
    onSuccess: slice.actions.getOne.type,
    onFail: slice.actions.getOne.type,
});
export const getProjectStatus=(data)=>apiCall({
    url: '/project/status/get-by-branch/'+data,
    method:'get',
    onSuccess: slice.actions.getProjectStatus.type,
    onFail: slice.actions.getProjectStatus.type,
});
export const saveProject=(data)=>apiCall({
    url: '/project',
    method:'post',
    data,
    onSuccess: slice.actions.savefrom.type,
    onFail: slice.actions.savefrom.type,
});
export const changeProjectStatus=(data)=>apiCall({
    url: `/project/patch/${data.projectId}/${data.statusId}`,
    method:'put',
    data,
    onSuccess: slice.actions.savefrom.type,
    onFail: slice.actions.savefrom.type,
});
export const editProject=(data)=>apiCall({
    url: '/project/'+data.id,
    method:'put',
    data,
    onSuccess: slice.actions.editfrom.type,
    onFail: slice.actions.editfrom.type,
});
export const deleteProject=(data)=>apiCall({
    url: '/project/'+data,
    method:'delete',
    onSuccess: slice.actions.deletefrom.type,
    onFail: slice.actions.deletefrom.type,
});

export default slice.reducer