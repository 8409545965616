import './yangiOtkazma.css'
import {useEffect, useState} from 'react'
import {connect} from "react-redux";
import users from "../../../../../reducer/users";
import OtkazmaReducer, {getSearchBranch, saveOtkazma,} from "../reducer/OtkazmaReducer";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";
import ExchangeStatusR, {getOtkazmaStatus} from "../../../../../reducer/ExchangeStatusR";
import {useTranslation} from "react-i18next";
import {ImCancelCircle} from "react-icons/im";
import ModalLoading from "../../../../ModalLoading";

function YangiOtkazma({
                          getSearchBranch,
                          ExchangeStatusR,
                          getOtkazmaStatus,
                          users,
                          saveOtkazma,
                          OtkazmaReducer,
                      }) {
    const {t} = useTranslation()
    const history = useHistory()
    const [input, setInput] = useState(
        {
            qisqaeslatma: '',
            status: '',
            bazadan: '',
            bazaga: '',
            sana: '',
            maxsulotizlash: '',
            yulhaqi: 0,
            qisqaeslatma2: '',
            miqdor: '',
            narx: '',
        }
    )

    const [xisob, setXisob] = useState(0)
    const [jamixisob, setjamiXisob] = useState(0)
    const [saveModal,setSaveModal] = useState(false)

    function qisqaeslatma(e) {
        input.qisqaeslatma = e.target.value
        let a = {...input}
        setInput(a)
    }

    function status(e) {
        input.status = e.target.value
        let a = {...input}
        setInput(a)
    }

    function bazadan(e) {
        input.bazadan = e.target.value
        let a = {...input}
        setInput(a)
        if (input.bazadan === input.bazaga && input.bazadan !=='' && input.bazaga !== ''){
            toast.error(t('tes.82'))
        }
        if (input.bazadan === ""){

        }
        else{
            getSearchBranch(input.bazadan)
            setXaridArray([])
            setXaridArrayPost([])
        }

    }

    function bazaga(e) {
        input.bazaga = e.target.value
        let a = {...input}
        setInput(a)

        if (input.bazadan === input.bazaga && input.bazadan !=='' && input.bazaga !== ''){
            toast.error(t('tes.82'))
        }
    }

    function sana(e) {
        input.sana = e.target.value
        let a = {...input}
        setInput(a)
    }

    function yulhaqi(e) {
        input.yulhaqi = e.target.value
        let a = {...input}
        setInput(a)
    }

    function qisqaeslatma2(e) {
        input.qisqaeslatma2 = e.target.value
        let a = {...input}
        setInput(a)
    }

    const [ XaridArrayPost, setXaridArrayPost] = useState([])
    const [XaridArray, setXaridArray] = useState([])
    const [XaridSearchValue, setXaridSearchValue] = useState('')

    function XaridSearch(e) {
        let a = []
        setXaridSearchValue(e.target.value)
        OtkazmaReducer.Search.filter(val => {
            if (e.target.value === '') {
                setXaridArray([])
            } else if(val.name === e.target.value){
                AddXaridArray(val)
            }
            else if(val.barcode === e.target.value){
                AddXaridArray(val)
            }
            else if (val.name.toUpperCase().includes(e.target.value.toUpperCase())) {
                a.push(val)
            } else if (val.barcode.includes(e.target.value)) {
                a.push(val)
            }
        })
        setXaridArray(a)
    }

    function AddXaridArray(item) {
        setXaridArray([])
        setXaridSearchValue('')
        let a = XaridArrayPost
        a.push({
            exchangeProductQuantity:item.amount,
            buyPrice: item.buyPrice,
            productExchangeId: item.productId,
            productTypePriceId: item.productTypePriceId,
            purchaseProductId: item.productTypePriceId === null ? item.productExchangeId : item.productTypePriceId,
            name: item.name,
            measurement: item.measurementName
        })
        setXaridArrayPost(a)
    }

    function DeleteXaridArrayPost(index) {
        setXaridArray([])
        setXaridSearchValue('')
        let a = XaridArrayPost
        a.splice(index, 1)
        setXaridArrayPost(a)
    }
    function ComboChangeAmount(e, index) {
        let b = XaridArrayPost
        let totalSum = 0
        let totalQuantity = 0
        b[index][e.target.name] = e.target.value
        let a = [...XaridArrayPost]
        setXaridArrayPost(a)
        XaridArrayPost.map(item => {
                totalSum += parseFloat(item.purchasedQuantity * item.buyPrice)
                totalQuantity += parseFloat(item.purchasedQuantity)
            }
        )
        setjamiXisob(totalSum)
        setXisob(totalQuantity)
    }



    function saqla() {
        if (input.bazadan === "" || input.bazaga === ""){
            toast(t('tes.83'))
        }
        else if(XaridArrayPost === []){
            toast(t('tes.84'))
        }
        else{
            saveOtkazma({
                shippedBranchId: input.bazadan,
                receivedBranchId: input.bazaga,
                exchangeDate: input.sana,
                description: input.qisqaeslatma,
                exchangeStatusId: input.status,
                exchangeProductDTOS: XaridArrayPost,
                businessId: users.businessId
            })
            setSaveModal(true)
        }

    }

    useEffect(()=>{
            if (OtkazmaReducer.saveExchangeBool){
                setSaveModal(false)
                history.push('/main/exchange/table')
            }
    },[OtkazmaReducer.counter])

    useEffect(() => {
        getOtkazmaStatus()
    }, [])

    const dateObj = new Date();

    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObj.getDate()).slice(-2);
    const year = dateObj.getFullYear();

    const shortDate = `${year}-${month}-${day}`;

    useEffect(()=>{
        input.sana = shortDate
        let a = {...input}
        setInput(a)
    },[])

    return (
        <div className="col-md-12 mt-2">
            <div className="textHeader">
                <h2>{t('BaseList.5')}</h2>
            </div>
            <div className="rowStyleM">
                <div className="row cont">
                    <div className="col-md-6 col-sm-12">
                        <h6>{t('Buttons.17')}:</h6>
                        <input value={input.qisqaeslatma} onChange={qisqaeslatma} className={'form-control'} type="text"
                               placeholder={t('Hamkorlar.eslatma')}/>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <h6>{t('Trade.9')}:</h6>
                        <select name="" id="" value={input.status} onChange={status}>
                            {
                                ExchangeStatusR.exchangestatus.map(item =>
                                    input.status == ''?input.status = item.id:
                                    <option value={item.id}>{item.status}</option>)
                            }
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <h6>{t('BaseList.6')}:</h6>
                        <select name="" id="" value={input.bazadan} onChange={bazadan}>
                            <option value="">{t('baza.bazat')}</option>
                            {
                                users.branches?.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                            }
                        </select>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <h6>{t('BaseList.3')}:</h6>
                        <select name="" id="" value={input.bazaga} onChange={bazaga}>
                            <option value="">{t('baza.bazat')}</option>

                            {
                                users.branches?.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                            }
                            - </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <div className="sana">
                            <h6>{t('Trade.4')}:</h6>
                            <input type="date" value={input.sana} onChange={sana}/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="rowStyle1">
                <div className="qoshish">
                    <h5>{t('BaseList.7')}</h5>
                </div>


                <div className={'col-md-12 mt-4 '}>
                    <div className="row">
                        <div className="col-md-12">
                            <input type="text"
                                   value={XaridSearchValue} onChange={XaridSearch}
                                   className={'form-control'}
                                   placeholder={t('baza.mshkyn')}/>
                            {
                                XaridArray.length !== 0 ?
                                    <div className={'Combo-array'}>
                                        {
                                            XaridArray.map((item,index) =>
                                                <p key={index} onClick={() => AddXaridArray(item)}>
                                                    {item.name + "  __   "}  {item.amount +" "+ item.measurementName}
                                                </p>
                                            )

                                        }
                                    </div>
                                    : ''
                            }
                            {/*--ESKI--*/}
                            {
                                (input.xaridmiqdori === "" && input.donanarxi === "" && input.donasotish === "") ?
                                    <div><p style={{color: "red", textAlign: 'center', marginTop: '4px'}}>{t('baza.mnyshkk')}</p></div> :
                                    ""
                            }
                            <div className="table-responsive">
                                <table className={'table mt-3 border'}>
                                    <thead>
                                    <tr>
                                        <th>{t('ProductEdit.2')}</th>
                                        <th>{t('Purchase.20')}</th>
                                        <th>{t('Purchase.21')}</th>
                                        <th>{t('Purchase.22')}</th>
                                        {/*<th>{t('ProductList.12')}</th>*/}
                                        <th>x</th>
                                    </tr>
                                    </thead>

                                    <tbody>

                                    {
                                        XaridArrayPost.map((item, index) =>
                                            <tr className={'text-center'}>
                                                <td><h4>{item.name}</h4></td>
                                                <td>
                                                    <div>
                                                        <input className={'form-control'}
                                                               name={'exchangeProductQuantity'}
                                                               value={item.exchangeProductQuantity}
                                                               onChange={(e) => ComboChangeAmount(e, index)}
                                                               type="number"/>
                                                        <input className={'form-control'} type="text" disabled={true}
                                                               value={item.measurement}/>
                                                    </div>
                                                </td>
                                                <td><input type="number" disabled={true} className={'form-control'} name={"buyPrice"}
                                                           onChange={(e) => ComboChangeAmount(e, index)}
                                                           value={item.buyPrice} placeholder={item.buyPrice}/>
                                                    <h3>{t('Hamkorlar.sum')}</h3></td>
                                                <td className={'d-flex justify-content-between'}>{item.exchangeProductQuantity * item.buyPrice}
                                                    <h6> {t('Hamkorlar.sum')}</h6></td>
                                                {/*<td><input type="number" className={'form-control'} name={"salePrice"}*/}
                                                {/*           onChange={(e) => ComboChangeAmount(e, index)}*/}
                                                {/*           value={item.salePrice}/><h3>So'm</h3></td>*/}
                                                <td className={'text-danger'}><ImCancelCircle
                                                    onClick={() => DeleteXaridArrayPost(index)}
                                                    style={{width: '30px', height: '30px'}}/></td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </table>
                            </div>
                            <hr/>
                            {/*<h6>{t('Purchase.23')}: {xisob}</h6>*/}
                            {/*<h6>{t('Purchase.22')}: {jamixisob}</h6>*/}
                        </div>
                    </div>
                </div>

                <div className="row cont">
                    <div className="col-md-6 col-sm-12">
                        <h6>{t('BaseList.4')}:</h6>
                        <input type="text" value={input.yulhaqi} onChange={yulhaqi}/>
                    </div>
                </div>

                {
                    input.bazadan!==input.bazaga?
                        <div className='saqlash'>
                                <button className='btn btn-primary' onClick={saqla}>{t('Buttons.6')}</button>
                        </div>:''
                }

            </div>
            <ModalLoading isOpen={saveModal}/>
        </div>
    )
}

export default connect(( users, OtkazmaReducer, ExchangeStatusR), {
    getSearchBranch,
    saveOtkazma,
    getOtkazmaStatus
})(YangiOtkazma)
