import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../api";
import {toast} from "react-toastify";

const slice = createSlice({
    name: 'taskStatus',
    initialState: {
        taskStatus:null,
        current:false,
        getBoolean:false,
        saveBoolean:false,
        deleteBoolean: false,
        message:''
    },
    reducers: {
        getFrom: (state, action) => {
            if (action.payload.success){
                state.taskStatus = action.payload.object
            }
            else{
                state.taskStatus = null
                state.message = action.payload.message
            }
            state.getBoolean = !state.getBoolean
            state.saveBoolean = false
        },
        savefrom: (state,action) => {
            if (action.payload.success){
                toast.success('Saqlandi')
                state.saveBoolean = true
            }
            else{
                toast.error(action.payload.message)
            }
            state.current=!state.current
        },
        editfrom: (state,action) => {
            if (action.payload.success){
                toast.success('Tahrirlandi')
                state.saveBoolean = true
            }
            else{
                toast.error(action.payload.message)
            }
            state.current=!state.current
        },
        deletefrom:(state,action)=>{
            if(action.payload.success){
                toast.success('Ruyhatdan o`chirildi')
                state.deleteBoolean=!state.deleteBoolean
            }
            else {
                toast(action.payload.message)
            }
            state.current=!state.current
        }
    }
});

export const getTaskStatus=(data)=>apiCall({
    url: '/task/status/get-by-branchId/'+data,
    method:'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type,
});
export const saveTaskStatus=(data)=>apiCall({
    url: '/task/status',
    method:'post',
    data,
    onSuccess: slice.actions.savefrom.type,
    onFail: slice.actions.savefrom.type,
});
export const editTaskStatus=(data)=>apiCall({
    url: `/task/status/${data.id}/${data.branchId? `${data.branchId}`:''}`,
    method:'put',
    data,
    onSuccess: slice.actions.editfrom.type,
    onFail: slice.actions.editfrom.type,
});
export const changeTaskStatus=(data)=>apiCall({
    url: `/task/change/${data.id}?isIncrease=${data.isBoolean}`,
    method:'put',
    onSuccess: slice.actions.editfrom.type,
    onFail: slice.actions.editfrom.type,
});
export const deleteTaskStatus=(data)=>apiCall({
    url: '/task/status/'+data,
    method:'delete',
    onSuccess: slice.actions.deletefrom.type,
    onFail: slice.actions.deletefrom.type,
});

export default slice.reducer