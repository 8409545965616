import {useState, forwardRef, useEffect} from 'react';
import {
    Avatar,
    Box,
    Card,
    Grid,
    Slide,
    Divider,
    Tooltip,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableContainer,
    TableRow,
    Button,
    Typography,
    Dialog,
    styled,
} from '@mui/material';
import {useForm} from "react-hook-form";
import {useTranslation} from 'react-i18next';
import {connect} from "react-redux";
import Select from "react-select";
import {toast} from "react-toastify";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    FormGroup,
    Label,
    Button as ButtonBootstarp,
} from "reactstrap";
import Loading from "../../../../Loading";
import users from "../../../../../reducer/users";
import DarsliklarReducer, {getDarslik} from "../reducer/DarsliklarReducer";
import CloseIcon from '@mui/icons-material/Close';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import {axiosCreate} from "../../../../../middleware";
import TestReducer, {deleteTest, deleteTestAll, getViewByTest, saveTest} from "../reducer/TestReducer";


const DialogWrapper = styled(Dialog)(
    () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AvatarError = styled(Avatar)(
    ({theme}) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const ButtonError = styled(Button)(
    ({theme}) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const Test = ({
                  users, DarsliklarReducer, getDarslik,getViewByTest, saveTest, deleteTest,
                  TestReducer, deleteTestAll
                           }) => {
    const {t} = useTranslation()
    const [testModalOpen, setTestModalOpen] = useState({isOpen:false, open: null,id: null})
    const [loading, setLoading] = useState(true)
    const [openConfirmDelete, setOpenConfirmDelete] = useState({isOpen:false,id: null});
    const [selectValue,setSelectValue] = useState(null)
    const [file,setFile] = useState(null)
    const [deleteAllModal, setDeleteAllModal] = useState({isOpen: false, value: null})
    const {register, handleSubmit, reset, formState: {errors}} = useForm()
    const options = DarsliklarReducer?.darsliklar ? DarsliklarReducer?.darsliklar?.map((item) => {
        return ({value: item.id, label: item.name})
    }): null

    useEffect(() => {
        getDarslik(users.businessId)
        setLoading(true)
    }, [])

    const downloadFile = () => {
        axiosCreate('/test/sample',{responseType: 'blob'})
            .then((response) => {
                const href = URL.createObjectURL(response.data);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'simple.docx');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            }).catch(() => {
                toast.warning(t('tes.123'))
        });

    }

    function changeSelect(data) {
        setSelectValue(data)
        getViewByTest(data.value)
    }

    function testModalToggle() {
        testModalOpen.isOpen ? setTestModalOpen({isOpen: false, id:null, open: null}) : setTestModalOpen({isOpen: true, id:null, open: 'create'})
        testModalOpen.isOpen && reset()
    }
    function deleteAllToggle() {
        deleteAllModal.isOpen ? setDeleteAllModal({isOpen: false, value: null}) : setDeleteAllModal({isOpen: true, value: null})
        !deleteAllModal.isOpen && DarsliklarReducer?.darsliklar && setDeleteAllModal((prev) => ({...prev, value: DarsliklarReducer?.darsliklar[0].id}))
    }
    const handleConfirmDelete = (id) => {
        setOpenConfirmDelete({isOpen: true, id: id});
    };

    const closeConfirmDelete = () => {
        setOpenConfirmDelete({isOpen:false, id: null});
    };

    const handleDeleteCompleted = () => {
        openConfirmDelete.id && deleteTestAll([openConfirmDelete.id]);
        setTimeout(() => {
            getViewByTest(selectValue.value)
        },1000)
        setOpenConfirmDelete({isOpen: false, id: null});
    };

    const addTest = (data) => {
        const son = new FormData();
        son.append('file', file);
        file && saveTest({...data, attachmentId: son})
        setTimeout(() => {
            selectValue && selectValue.value && getViewByTest(selectValue.value)
        },1000)
        testModalToggle()
    }

    const deleteAllFunc = (e) => {
        e.preventDefault()
        deleteAllModal.value && deleteTest(deleteAllModal.value)
        setTimeout(() => {
            selectValue.value && getViewByTest(selectValue.value)
        },1000)
        deleteAllToggle();
    }

    return (
        <>
            <Box className="MuiPageTitle-wrapper p-5">
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h3" component="h3" gutterBottom>
                            {t('tes.124')}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Grid
                sx={{
                    px: 4
                }}
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                spacing={4}
            >
                <Grid item xs={12}>
                    <>
                        <Box
                            display="flex"
                            alignItems="center"
                            flexDirection={{xs: 'column', sm: 'row'}}
                            justifyContent={{xs: 'center', sm: 'space-between'}}
                            pb={3}
                        >
                            <div style={{display: 'flex', gap: 10}}>
                                {
                                    users.addLessonChecked && (
                                        <Button
                                            sx={{
                                                mt: {xs: 2, sm: 0}
                                            }}
                                            onClick={() => setTestModalOpen({isOpen: true, open: 'create', id: null })}
                                            variant="contained"
                                            startIcon={<AddTwoToneIcon fontSize="small"/>}
                                        >
                                            {t('Darslik.testq')}
                                        </Button>
                                    )
                                }
                                {
                                    users.deleteLessonChecked && (
                                        <Button
                                            sx={{
                                                mt: {xs: 2, sm: 0}
                                            }}
                                            onClick={deleteAllToggle}
                                            variant="contained"
                                            startIcon={<DeleteTwoToneIcon fontSize="small"/>}
                                            color='error'
                                        >
                                            {t('Darslik.dbo')}
                                        </Button>
                                    )
                                }
                            </div>
                            <div style={{width: 170}}>
                                <Select value={selectValue} placeholder={t('Darslik.dt')} defaultValue={null} options={options} onChange={changeSelect}/>
                            </div>
                        </Box>
                        <Card>
                            <Divider/>
                            {
                                    TestReducer?.test ?
                                    loading ?
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>#</TableCell>
                                                        <TableCell>{t('Darslik.savol')}</TableCell>
                                                        <TableCell>{t('Darslik.tj')}</TableCell>
                                                        <TableCell>{t('Darslik.nj')}</TableCell>
                                                        <TableCell>{t('Darslik.nj')}</TableCell>
                                                        <TableCell>{t('Darslik.nj')}</TableCell>
                                                        {
                                                            users.deleteLessonChecked && (
                                                                <TableCell align="center">{t('Actions')}</TableCell>
                                                            )
                                                        }
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        TestReducer?.test.map((item, index) => <TableRow key={index}>
                                                            <TableCell>{index + 1}</TableCell>
                                                            <TableCell>{item.question}</TableCell>
                                                            <TableCell>{item.a1}</TableCell>
                                                            <TableCell>{item.a2}</TableCell>
                                                            <TableCell>{item.a3}</TableCell>
                                                            <TableCell>{item.a4}</TableCell>
                                                            {
                                                                users.deleteLessonChecked && (
                                                                    <TableCell align="center">
                                                                        <Typography noWrap>
                                                                            <Tooltip title={t('Darslik.och')} arrow>
                                                                                <IconButton
                                                                                    onClick={() => handleConfirmDelete(item.id)}
                                                                                    color="primary"
                                                                                >
                                                                                    <DeleteTwoToneIcon color={'error'} fontSize="small"/>
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </Typography>
                                                                    </TableCell>
                                                                )
                                                            }
                                                        </TableRow>)
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        : <Loading/> : <h4 className={'text-center'}>{t('Darslik.mtt')}</h4>
                            }
                        </Card>
                        <Modal isOpen={testModalOpen.isOpen} toggle={testModalToggle}>
                            <form onSubmit={handleSubmit(addTest)}>
                                <ModalHeader>
                                    <h5>{t('Darslik.testq')}</h5>
                                </ModalHeader>
                                <ModalBody>
                                    <FormGroup style={{display:'flex'}} className='align-items-center justify-content-between'>
                                        <p style={{fontSize: 18, fontWeight: 500,marginBottom:5,color: "red"}}>{t('tes.125')}</p>
                                        <button className="btn btn-primary" onClick={downloadFile}>{t(`tes.126`)}</button>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="lessonId" >
                                            {t('Darslik.dt')}
                                        </Label>
                                        <select
                                            id="lessonId"
                                            className={errors.lessonId ? errors.lessonId?.type === "required" && 'form-select form-select-lg is-invalid' : 'form-select-lg form-select'}
                                            {...register('lessonId', {required: true})}
                                            placeholder={errors.lessonId ? errors.lessonId?.type === "required" && "Lesson is required" : 'Lesson'}
                                        >
                                            {
                                                DarsliklarReducer?.darsliklar ?
                                                    DarsliklarReducer?.darsliklar.map((item) =>
                                                        <option value={item.id}>{item.name}</option>):''
                                            }
                                        </select>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="exampleAttachmentId" >
                                            {t(`tes.127`)}
                                        </Label>
                                        <input
                                            id="exampleAttachmentId"
                                            type="file"
                                            className={'form-control'}
                                            {...register('attachmentId', {required: true})}
                                            placeholder={errors.exampleAttachmentId ? errors.exampleAttachmentId?.type === "required" && "File is required" : 'File'}
                                            onChange={(e) => setFile(e.target.files[0])}
                                        />
                                    </FormGroup>
                                </ModalBody>
                                <ModalFooter>
                                    <ButtonBootstarp type={'submit'} color={'primary'}>
                                        {t('Darslik.s')}
                                    </ButtonBootstarp>
                                    <ButtonBootstarp color="primary" outline onClick={testModalToggle}>{t('Darslik.ch')}</ButtonBootstarp>
                                </ModalFooter>
                            </form>
                        </Modal>
                        <Modal isOpen={deleteAllModal.isOpen} toggle={deleteAllToggle}>
                            <form onSubmit={deleteAllFunc}>
                                <ModalHeader>
                                    <h5>{t('Darslik.to')}</h5>
                                </ModalHeader>
                                <ModalBody>
                                    <FormGroup>
                                        <Label for="lessonId" >
                                            {t('Darslik.dt')}
                                        </Label>
                                        <select
                                            id="lessonId"
                                            className={'form-select-lg form-select'}
                                            name='lessonId'
                                            value={deleteAllModal.value}
                                            required={true}
                                            placeholder={t('Darslik.dt')}
                                            onChange={(data) => setDeleteAllModal((prev) => ({...prev, value: data.target.value}))}
                                        >
                                            {
                                                DarsliklarReducer?.darsliklar ?
                                                    DarsliklarReducer?.darsliklar.map((item) =>
                                                        <option value={item.id}>{item.name}</option>):''
                                            }
                                        </select>
                                    </FormGroup>
                                </ModalBody>
                                <ModalFooter>
                                    <ButtonBootstarp type={'submit'} color='danger' onClick={deleteAllFunc}>
                                        {t('Darslik.och')}
                                    </ButtonBootstarp>
                                    <ButtonBootstarp color="primary" outline onClick={deleteAllToggle}>{t('Darslik.ch')}</ButtonBootstarp>
                                </ModalFooter>
                            </form>
                        </Modal>

                        <DialogWrapper
                            open={openConfirmDelete.isOpen}
                            maxWidth="sm"
                            fullWidth
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={closeConfirmDelete}
                        >
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                flexDirection="column"
                                p={5}
                            >
                                <AvatarError>
                                    <CloseIcon/>
                                </AvatarError>

                                <Typography
                                    align="center"
                                    sx={{
                                        py: 4,
                                        px: 6
                                    }}
                                    variant="h3"
                                >

                                </Typography>

                                <Box>
                                    <Button
                                        variant="text"
                                        size="large"
                                        sx={{
                                            mx: 1
                                        }}
                                        onClick={closeConfirmDelete}
                                    >
                                        {t('Darslik.ch')}
                                    </Button>
                                    <ButtonError
                                        onClick={handleDeleteCompleted}
                                        size="large"
                                        sx={{
                                            mx: 1,
                                            px: 3
                                        }}
                                        variant="contained"
                                    >
                                        {t('Darslik.och')}
                                    </ButtonError>
                                </Box>
                            </Box>
                        </DialogWrapper>
                    </>
                </Grid>
            </Grid>
        </>
    );
};
export default connect((users,DarsliklarReducer, TestReducer), {
    getDarslik,
    getViewByTest,
    saveTest,
    deleteTest,
    deleteTestAll,
})(Test)
