import './haridlar.css';
import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import {NavLink, useHistory, useLocation} from 'react-router-dom';
import { active } from "../../../../reducer/functionreducer";
import { useTranslation } from "react-i18next";
import users from "../../../../reducer/users";
import {Tabs} from "antd";
import HaridlarRoyxati from "./haridlarRoyxati/HaridlarRoyxati";
import Xarid from "./Xarid qilish/Xarid";
import Taminotchilar from "../Hamkorlar/taminotchilar/Taminotchilar";

function Haridlar({ changeLink, link, sidebaractive2, users }) {

    const { t } = useTranslation()
    const history = useHistory()
    const location = useLocation()


    function sidebaractive() {
        const windowWidth = window.innerWidth;
        if (windowWidth <= 1023.9) {
            sidebaractive2()
        }
    }
    const items = [
        {
            label: t("Sidebar.6"),
            key: '/main/purchase/supplier',
            children: <Taminotchilar/>,
        },
        {
            label: t('Sidebar.15'),
            key: '/main/purchase/table',
            children: <HaridlarRoyxati/>,
        },
        // {
        //     label: `Xarid qilish`,
        //     key: '/main/purchase/add',
        //     children: <Xarid/>,
        // },
    ]

    useEffect(() => {
        !items.some(item=>item.key === location.pathname) && history.push('/main/purchase/supplier')
    }, []);
    const onChange = (key) => {
        history.push(key)
    };

    return (
        <div>
            <h4 className={'p-3 mainText'}>{t('Sidebar.15')}</h4>
            <div className={'px-3'}>
                <Tabs
                    activeKey={location.pathname}
                    onChange={onChange}
                    type="card"
                    items={items}
                />
            </div>
            {/*<ul className={'tran'}>*/}
            {/*    {*/}
            {/*        users.viewPurchase || users.viewPurchaseAdmin || users.addPurchase || users.editPurchase || users.deletePurchase ?*/}
            {/*            <li className={`${sidebaractive ? 'ttt' : 'ttt2'}`} onClick={sidebaractive}><NavLink*/}
            {/*                to={'/headerthird/xaridlarRuyxati'}*/}
            {/*                className={isActive => isActive ? 'active-enter-link li-text' : 'li-text'}>{t('Sidebar.16')}</NavLink>*/}
            {/*            </li>*/}
            {/*            : ''*/}
            {/*    }*/}
            {/*    {*/}
            {/*        users.addPurchase ?*/}
            {/*            <li onClick={sidebaractive}><NavLink to={'/headerthird/xaridQilish'}*/}
            {/*                className={isActive => isActive ? 'active-enter-link li-text' : 'li-text'}>{t('Sidebar.17')}</NavLink>*/}
            {/*            </li> : ''*/}
            {/*    }*/}
            {/*</ul>*/}

        </div>
    )
}

export default connect((users), { active })(Haridlar)
