import React, {useEffect, useState} from 'react';
import {Box, Tabs, ToggleButton, ToggleButtonGroup, Tooltip} from "@mui/material";
import {Link, Route, Switch, useHistory} from "react-router-dom";
import TableRowsTwoToneIcon from "@mui/icons-material/TableRowsTwoTone";
import GridViewTwoToneIcon from "@mui/icons-material/GridViewTwoTone";
import {styled} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import Davomad from "./Davomad";
import ByHour from "./BuMember/ByHour";
import ByMember from "./BuMember/ByMember";

function Attendance(props) {
    const {t} = useTranslation();
    const history = useHistory();
    const [openComponent, setOpenComponent] = useState(1)


    const [filters, setFilters] = useState({
        role: null
    });

    const [toggleView, setToggleView] = useState('table_view');
    const handleViewOrientation = (_event, newValue) => {
        setToggleView(newValue);
    };

    const TabsWrapper = styled(Tabs)(
        ({theme}) => `
    @media (max-width: ${theme.breakpoints.values.md}px) {
      .MuiTabs-scrollableX {
        overflow-x: auto !important;
      }

      .MuiTabs-indicator {
          box-shadow: none;
      }
    }
    `
    );
    const handleTabsChange = (_event, tabsValue) => {
        let value = null;

        if (tabsValue !== 'all') {
            value = tabsValue;
        }

        setFilters((prevFilters) => ({
            ...prevFilters,
            role: value
        }));

    };


    return (
        <div>
            <Box
                display="flex"
                alignItems="center"
                flexDirection={{xs: 'column', sm: 'row'}}
                justifyContent={{xs: 'center', sm: 'end'}}
                pb={3}
            >
                <TabsWrapper
                    onChange={handleTabsChange}
                    scrollButtons="auto"
                    textColor="secondary"
                    value={filters.role || 'all'}
                    variant="scrollable"
                >
                    <ToggleButtonGroup
                        sx={{
                            mt: {xs: 2, sm: 0}
                        }}
                        value={toggleView}
                        exclusive
                        onChange={handleViewOrientation}
                    >
                        <Tooltip onClick={()=>setOpenComponent(1)} title={t('Bosh1.davomat')} arrow>
                            <ToggleButton disableRipple value="grid_view">
                                <TableRowsTwoToneIcon/>
                            </ToggleButton>
                        </Tooltip>
                        <Tooltip onClick={()=>setOpenComponent(2)} title={t('Davomat.kch')} arrow>
                            <ToggleButton disableRipple value="grid_view">
                                <GridViewTwoToneIcon/>
                            </ToggleButton>
                        </Tooltip>
                        <ToggleButton onClick={()=>setOpenComponent(3)} disableRipple value="grid_view">
                            <TableRowsTwoToneIcon/>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </TabsWrapper>
            </Box>
            {
                openComponent ===1 ?
            <ByHour/> : openComponent ===2 ?
            <ByMember/> :
            <Davomad/>
            }
        </div>
    );
}

export default Attendance;