import {useTranslation} from "react-i18next";
import './excelButtons.css'
import {Button, Space} from "antd";
import excel from "../assets/excel.png";
// import print from "../assets/print.png";
import lossData from "../assets/lossData.png";

function ExcelButtons({setOpenCheckList, getExcel, isActive}) {
    const {t} = useTranslation();

    return (<Space className="antTableExcel" size="middle" wrap>
            <Button onClick={getExcel} className="d-flex gap-2 buttonsExcelText"
                    type="text"><span>{t('ad.31')}</span><img src={excel} width={24} height={24} alt={'lid'}/></Button>
            {/*<Button className="d-flex gap-2" type="text"><span>{t('ProductEdit.19')}</span><img src={print} width={24} height={24} alt={'lid'}/></Button>*/}


            <Button onClick={() => setOpenCheckList(prev => !prev)} className="d-flex gap-2 buttonsExcelText"
                    type="text"><span>{t('Hamkorlar.mk')}</span><img src={lossData} width={24} height={24} alt={'lid'}/></Button>
        </Space>
    );
}

export default ExcelButtons;
