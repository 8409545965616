import {Link} from 'react-router-dom'
import CSV from '../../../../../img/CSV.png'
import Excel from '../../../../../img/Excel.png'
import Print from '../../../../../img/Print.png'
import Data from '../../../../../img/Data.png'
import Pdf from '../../../../../img/PDF.png'
import Edit from '../../../../../img/Edit.png'
import Delete from '../../../../../img/Delete.png'
import Korish from '../../../../../img/Korish.png'
import './haridlarRoyxati.css'
import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
import XaridReducer, {
    deleteXarid,
    editXarid,
    getXaridByBranch,
    getXaridByBusiness,
    saveXarid, getPurchaseView,
} from "../reducer/XaridReducer";
import TaminotReducer, {getTaminot} from "../../Hamkorlar/reducer/TaminotReducer";
import users from "../../../../../reducer/users";
import tolovreducer, {gettolovholati} from "../../../../../reducer/tolovreducer";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import formatDate from "../../../../../util";
import accountreducer, {getAccount} from "../../../../../reducer/accountreducer";
import Loading from "../../../../Loading";
import ValyutaReducer,{getValyuta} from "../../Settings/DukonSozlamalari/reducers/ValyutaReducer";
import XodimReducer,{getXodimByBranch,getXodim} from "../../Hodimlar/reducer/XodimReducer";
import {TablePagination,Box} from "@mui/material";
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
function HaridlarRoyxati({
                             getXaridByBranch,
                             getXaridByBusiness,
                             getXodimByBranch,getXodim,
                             getTaminot,
                             gettolovholati,
                             deleteXarid,
                             XaridReducer,
                             TaminotReducer,
                             XodimReducer,
                             users,
                             getValyuta,
                             ValyutaReducer,
                             getPurchaseView
                         }) {

    const {t} = useTranslation()
    const [mainBranchId,setMainBranchId] = useState(null)
    const [userId,setUserId] = useState(null)
    const [supplierId,setSupplierId] = useState(null)
    const [date,setDate] = useState('')
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(5);

    const handlePageChange = (_event, newPage) => {
        setPage(newPage);
    };

    const handleLimitChange = (event) => {
        setSize(event.target.value);
    };




    useEffect(() => {
        getTaminot(users.businessId)
        // gettolovholati(users.businessId)
        getValyuta(users.businessId)
    }, [XaridReducer.current])

    useEffect(() => {
         setLoading(false)
        if (users.viewPurchaseAdmin && !mainBranchId){
            getXaridByBusiness({
                businessId: users.businessId,
                params:{
                    page,size,userId,supplierId,date:date==='' ? null :date
                }
            })
        }
        else{
            getXaridByBranch({
                branchId:mainBranchId ? mainBranchId: users.branchId,
                params:{
                    page,size,userId,supplierId,date:date==='' ? null :date
                }
            })
        }
    }, [XaridReducer.current,page,size,userId,supplierId,date,mainBranchId])

    useEffect(()=>{
        if (users.viewUserAdmin && !mainBranchId){
            getXodim(users.businessId)
        }
        else{
            getXodimByBranch(mainBranchId ? mainBranchId : users.branchId)
        }
    },[mainBranchId])

    const [sana3, setsana3] = useState(true)
    const [baza3, setbaza3] = useState(true)
    const [diller3, setdiller3] = useState(true)
    const [xaridstatus3, setxaridstatus3] = useState(true)
    const [donanarxi, setdonanarxi] = useState(true)
    const [jammisumma, setjamisumma] = useState(true)
    const [qisqaeslatma, setqisqaeslatma] = useState(true)
    const [amallar, setamallar] = useState(true)

    const headlist=[
        {
            sana: t('Purchase.7'),
            baza: t('ProductList.10'),
            diller: t('Purchase.2'),
            xaridstatus: t('Purchase.3'),
            donanarxi: t('Purchase.8'),
            jamisumma: t('Purchase.9'),
            qisqaeslatma: t('Buttons.17'),
            amallar: 'Amallar'
        }
    ]

    const [malkamay, setmalkamay] = useState(false)



    const [deletemodal, setdeletemodal] = useState(false)
    const [deleteID, setdeletID] = useState('')

    function deleteFunc() {
        deleteXarid(deleteID)
        deleteModaltoggle('')
    }

    const [viewOnePurchase,setViewOnePurchase] = useState(false)


    function viewOnePurchaseToggle(){
        setViewOnePurchase(!viewOnePurchase)
    }

    function getOneById(id){
        getPurchaseView(id)
        viewOnePurchaseToggle()
    }


    function deleteModaltoggle(item) {
        setdeletemodal(!deletemodal)
        setdeletID(item)
    }

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
    }, [XaridReducer.loading])


    useEffect(() => {
        setLoading(false)
    }, [])

    return (
        <div className="col-md-12 mt-2">
            <div className="textHeaderHarid">
                <h2>{t('Purchase.1')}</h2>
            </div>
            {
                users.viewPurchaseAdmin || users.viewPurchase ?

                    <div className="rowStyleHarid">
                        <div className="qoshish">
                            <h5>{t('Buttons.16')}</h5>
                        </div>
                        <div className="row cont">
                            <div className="col-md-6">
                                <h6>{t('ProductList.8')}:</h6>
                                <select name="" className={'form-control'} value={mainBranchId} onChange={(e)=>setMainBranchId(e.target.value === ""? null : e.target.value)} id="">
                                    {
                                        users.viewPurchaseAdmin &&
                                    <option value="">{t('block.barchasi')}</option>}
                                    {   users.branches ?
                                        users.branches?.map(item => <option value={item.id}>{item?.name}</option>)
                                        :''
                                    }
                                </select>
                            </div>
                            <div className="col-md-6">
                                <h6>{t('Purchase.2')}:</h6>
                                <select name="" id="" className={'form-control'} value={supplierId} onChange={(e)=>setSupplierId(e.target.value === ""? null : e.target.value)}>
                                    <option value="">{t('block.barchasi')}</option>
                                    {
                                        TaminotReducer.taminot?
                                        TaminotReducer.taminot?.map(item => <option key={item.id}
                                                                                   value={item.id}>{item?.name}</option>):''
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <h6>Xodimlar:</h6>
                                <select name="" id="" className={'form-control'} value={userId} onChange={(e)=>setUserId(e.target.value === "" ? null : e.target.value)}>
                                    <option value="">{t('block.barchasi')}</option>
                                    {XodimReducer.xodimlar  &&
                                        XodimReducer.xodimlar.map(item=>
                                            <option value={item.id}>{item.firstName}</option>
                                        )
                                    }
                                </select>
                            </div>
                            <div className="col-md-6 d-flex align-items-center">
                                <div className="sana" style={{width:'100%'}}>
                                    <h6>{t('Purchase.5')}:</h6>
                                    <input  type="date" className={'form-control'} onChange={(e)=>setDate(e.target.value)} value={date}/>
                                </div>
                                <div>
                                    <DisabledByDefaultIcon onClick={()=>setDate('')} fontSize={'large'} color={'error'}/>
                                </div>
                            </div>
                        </div>
                    </div>:''
            }
            <div className="rowStyleBH">
                <div className="qoshishBH">
                    <h5>{t('Purchase.6')}</h5>
                    {
                        users.addTrade ? <Link to={'/main/purchase/add'}>
                            <button className='btn btn-primary'>+{t('Buttons.2')}</button>
                        </Link> : ''
                    }
                </div>
                {
                    users.viewPurchaseAdmin || users.viewPurchase ?
                        loading?
                            XaridReducer.xaridlar?.list?.length > 0?
                        <div>
                            <div className="izlashBH">
                                <div className="izlashBox1">
                                    <button><img src={CSV} alt=""/> Export CSV</button>
                                    <button><img src={Excel} alt=""/> Export Excel</button>
                                    <button><img src={Print} alt=""/> Print</button>
                                    <button><img src={Pdf} alt=""/>Export PDF</button>
                                    <button onClick={() => setmalkamay(!malkamay)}><img src={Data}
                                                                                        alt=""/>{t('Buttons.18')}
                                    </button>
                                    {
                                        malkamay ? headlist?.map(item => <ul className={'ul23'} key={item.id}>
                                            <li onClick={() => setsana3(!sana3)}
                                                className={'li23'}>{sana3 ? item.sana : item.sana + ' <-'}</li>
                                            <li onClick={() => setbaza3(!baza3)}
                                                className={'li23'}>{baza3 ? item.baza : item.baza + ' <-'}</li>
                                            <li onClick={() => setdiller3(!diller3)}
                                                className={'li23'}>{diller3 ? item.diller : item.diller + ' <-'}</li>
                                            <li onClick={() => setxaridstatus3(!xaridstatus3)}
                                                className={'li23'}>{xaridstatus3 ? item.xaridstatus : item.xaridstatus + ' <-'}</li>
                                            <li onClick={() => setdiller3(!donanarxi)}
                                                className={'li23'}>{donanarxi ? item.donanarxi : item.donanarxi + ' <-'}</li>
                                            <li onClick={() => setamallar(!amallar)}
                                                className={'li23'}>{amallar ? item.amallar : item.amallar + ' <-'}</li>
                                        </ul>) : ''
                                    }
                                </div>
                            </div>
                            <div className="table-responsive table-wrapper-scroll-y">
                                <table className='table table-hover table-striped table-bordered mt-4'>
                                    <thead>
                                    {
                                        headlist?.map(item => <tr key={item.id}>
                                            <th>T/R</th>
                                            {
                                                sana3 ? <th>{item.sana}</th> : ''
                                            }
                                            <th>Xarid raqami</th>
                                            {
                                                baza3 ? <th>{item.baza}</th> : ''
                                            }
                                            {
                                                diller3 ? <th>{item.diller}</th> : ''
                                            }
                                            <th>{t('block.qarz')}</th>
                                            {
                                                xaridstatus3 ? <th>{item.xaridstatus}</th> : ''
                                            }
                                            {
                                                jammisumma ? <th>{item.jamisumma}</th> : ''
                                            }
                                            {
                                                qisqaeslatma ? <th>{item.qisqaeslatma}</th> : ''
                                            }
                                            {
                                                amallar ? <th>{item.amallar}</th> : ''
                                            }
                                        </tr>)
                                    }
                                    </thead>
                                    <tbody>
                                    {
                                        XaridReducer.xaridlar?.list?.map((item, index) => <tr key={index}>
                                            <td>{index + 1}</td>
                                            {
                                                sana3 ? <td>{formatDate(item.date)}</td> : ''
                                            }
                                            <td>{item?.invoice}</td>
                                            {
                                                baza3 ? <td>{item.branch?.name}</td> : ''
                                            }
                                            {
                                                diller3 ? <td>{item.supplier?.name}</td> : ''
                                            }
                                            <td>
                                                <p>{item.debtSum} so'm</p>
                                                <p>{item.debtDollar} USD</p>
                                            </td>
                                            {
                                                xaridstatus3 ? <td>{item.purchaseStatus.status}</td> : ''
                                            }
                                            {
                                                jammisumma ? <td>
                                                    <p>{item.totalSum} so'm</p>
                                                    <p>{item.totalDollar} USD</p>
                                                </td> : ''
                                            }
                                            {
                                                qisqaeslatma ? <td>{item.description}</td> : ''
                                            }

                                            {
                                                amallar ? <td>
                                                    {users.editPurchase ?
                                                        <Link to={'/main/purchase/add/' + item.id}>
                                                            <button className='taxrirlash'><img src={Edit}
                                                                                                alt=""/> {t('Buttons.1')}
                                                            </button>
                                                        </Link> : ''}
                                                    {users.viewPurchase ?
                                                            <button className='korish' onClick={()=>getOneById(item.id)}><img src={Korish}
                                                                                                alt=""/> {t('Buttons.4')}
                                                            </button>
                                                      : ''}
                                                    {users.deletePurchase ?
                                                        <button className='ochirish'
                                                                onClick={() => deleteModaltoggle(item.id)}><img
                                                            src={Delete} alt=""/> {t('Buttons.3')}</button> : ''}

                                                    <Modal isOpen={deletemodal} toggle={deleteModaltoggle}>
                                                        <ModalBody>
                                                            <h5>{t('Buttons.12')} ?</h5>
                                                        </ModalBody>
                                                        <ModalFooter>
                                                            <button onClick={() => deleteFunc(item.id)}
                                                                    className={'btn btn-outline-primary'}>{t('Buttons.3')}</button>
                                                            <button onClick={() => deleteModaltoggle('')}
                                                                    className={'btn btn-outline-primary'}>{t('Buttons.7')}</button>
                                                        </ModalFooter>
                                                    </Modal>

                                                </td> : ''
                                            }
                                        </tr>)
                                    }
                                    </tbody>
                                </table>
                                <Box p={2} alignItems={'center'} display={'flex'} justifyContent={'center'}>
                                <TablePagination
                                    component="div"
                                    count={XaridReducer.xaridlar.totalItem}
                                    onPageChange={handlePageChange}
                                    onRowsPerPageChange={handleLimitChange}
                                    page={page}
                                    rowsPerPage={size}
                                    rowsPerPageOptions={[5, 10, 15]}
                                />
                                </Box>
                            </div>
                        </div>:<div className={'border border-2'}>
                                <h4 className={'text-center'}>{XaridReducer.message || t('Darslik.mtt')}</h4>
                            </div> :<Loading/> : ''
                }
            </div>


            <Modal isOpen={viewOnePurchase} size={'xl'} toggle={()=>setViewOnePurchase(!viewOnePurchase)}>
                <ModalHeader>
                    <h4>
                        {t('block.xmk')}
                    </h4>
                </ModalHeader>
                <ModalBody>
                    {
                        XaridReducer.xaridOne ?
                            <div>
                                <div className="col-md-12 d-flex">
                                    <div className="col-md-6">
                                        <h6>{t('block.xs')} {formatDate(XaridReducer?.xaridOne.date)}</h6>
                                        <h6>{t('block.tt')} {XaridReducer?.xaridOne?.paymentMethod?.type}</h6>
                                        <h6>{t('block.th')} {XaridReducer?.xaridOne?.paymentStatus?.status}</h6>
                                        <h6>{t('block.xh')} {XaridReducer?.xaridOne?.purchaseStatus?.status}</h6>
                                    </div>
                                    <div className="col-md-6">
                                        <h6>{t('block.d')} {XaridReducer?.xaridOne?.supplier?.name}</h6>
                                        <h6 className={'d-flex justify-content-between'}> {t('block.js')}
                                            <div>
                                            <h6>{XaridReducer?.xaridOne?.totalSum} {t('Hamkorlar.sum')}</h6>
                                            <h6>{(XaridReducer?.xaridOne?.totalSum/ValyutaReducer?.currency?.course).toFixed(2)} $</h6>
                                        </div>
                                        </h6>
                                        <h6 className={'d-flex justify-content-between'}> {t('block.ts')}
                                            <div>
                                                <h6>{XaridReducer?.xaridOne?.paidSum} {t('Hamkorlar.sum')}</h6>
                                                <h6>{(XaridReducer?.xaridOne?.paidSum/ValyutaReducer?.currency?.course).toFixed(2)} $</h6>
                                            </div>
                                        </h6>
                                        <h6 className={'d-flex justify-content-between'}>{t('Hamkorlar.qarz')} <div>
                                            <h6>{XaridReducer?.xaridOne?.debtSum} {t('Hamkorlar.sum')}</h6>
                                            <h6>{(XaridReducer?.xaridOne?.debtSum/ValyutaReducer?.currency?.course).toFixed(2)} $</h6>
                                        </div></h6>
                                    </div>
                                </div>
                            </div>: t('Darslik.mtt')
                    }
                    {
                        XaridReducer.purchaseProductList ?
                            <table className={'table table-bordered'}>
                                <thead>
                                <tr>
                                    <th>{t('block.m')}</th>
                                    <th>{t('block.mi')}</th>
                                    <th>{t('block.s')}</th>
                                    <th>{t('block.q')}</th>
                                    <th>{t('block.son')}</th>
                                    <th>{t('block.sn')}</th>
                                    <th>{t('block.f')}</th>
                                    <th>{t('block.js')}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    XaridReducer.purchaseProductList.map(item=>
                                        <tr>
                                            {/*<th>{item.product ? item.product?.name : item.productTypePrice?.name}</th>*/}
                                            <th>{item.name}</th>
                                            <th>{item.quantity} {item.measurement}</th>
                                            <th>{item?.soldQuantity} {item.measurement}</th>
                                            <th>{item.quantity-item?.soldQuantity} {item.measurement}</th>
                                            <th>
                                                <div>
                                                    <h6>{item.buyPrice} {t('Hamkorlar.sum')}</h6>
                                                    <h6>{(item.buyPrice/ValyutaReducer?.currency?.course).toFixed(2)} $</h6>
                                                </div>

                                            </th>
                                            <th>
                                                <div>
                                                    <h6>{item.salePrice} {t('Hamkorlar.sum')}</h6>
                                                    <h6>{(item.salePrice/ValyutaReducer?.currency?.course).toFixed(2)} $</h6>
                                                </div>

                                            </th>
                                            {/*<th>{item?.profit}</th>*/}
                                            <th>
                                                <div>
                                                    <h6>{item?.profit} {t('Hamkorlar.sum')}</h6>
                                                    <h6>{(item?.profit/ValyutaReducer?.currency?.course).toFixed(2)} $</h6>
                                                </div>

                                            </th>

                                            <th>
                                                <div>
                                                    <h6>{item.totalSum} {t('Hamkorlar.sum')}</h6>
                                                    <h6>{(item.totalSum/ValyutaReducer?.currency?.course).toFixed(2)} $</h6>
                                                </div>

                                            </th>
                                        </tr>
                                    )
                                }

                                </tbody>
                            </table>:<div><h4 className={'text-center'}>{t('Darslik.mtt')}</h4></div>
                    }
                </ModalBody>
                <ModalFooter>
                    <button className={'btn btn-danger'} onClick={()=>setViewOnePurchase(!viewOnePurchase)}>Chiqish</button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default connect((TaminotReducer, XaridReducer, users, accountreducer, tolovreducer,ValyutaReducer,XodimReducer), {
    getAccount,
    gettolovholati,
    getTaminot,
    saveXarid,
    editXarid,
    deleteXarid,
    getValyuta,
    getPurchaseView,
    getXaridByBranch,
    getXaridByBusiness,
    getXodimByBranch,getXodim
})
(HaridlarRoyxati)
