import {Link} from 'react-router-dom'
import CSV from '../../../../../img/CSV.png'
import Excel from '../../../../../img/Excel.png'
import Print from '../../../../../img/Print.png'
import Data from '../../../../../img/Data.png'
import Pdf from '../../../../../img/PDF.png'
import Edit from '../../../../../img/Edit.png'
import Delete from '../../../../../img/Delete.png'
import './qoldiqlarXisoboti.css'
import {connect} from 'react-redux'
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from 'react'
import QoldiqlarxisobotiReducer, {
    getQoldiq2,
    getQoldiq3,
} from '../reducer/QoldiqlarxisobotiReducer'
import users from "../../../../../reducer/users";
import branchreducer, {getbranch} from "../../../../../reducer/branchreducer";
import FirmaReducer, {getFirma} from "../../Maxsulotlar/reducer/FirmaReducer";
import BolimReducer, {getBolim} from "../../Maxsulotlar/reducer/BolimReducer";
import MainHeaderText from "../../../../Components/MainHeaderText";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import formatDateYear from "../../../../../reducer/sana";
import CardBody from "../../../../Components/CardBody";
import CommonTable from "../../../../Components/CommonTable";
import {prettify} from "../../../../../util";

function QoldiqlarXisoboti({
                               users,
                               getBolim,
                               BolimReducer,
                               getFirma,
                               QoldiqlarxisobotiReducer,
                               FirmaReducer,
                               getQoldiq3,
                               getQoldiq2
                           }) {
    const {t} = useTranslation()

    const [input, setInput] = useState(
        {
            bazatanlash: '',
            firma: '',
            bolim: '',
            bolim2: '',
            ulcovbirligi: '',
            ishlabciqariladigancheck: '',
            view: '',
            izlash: '',
        }
    )


    const columns = [
        {
            title: 'Id',
            dataIndex: 'index',
            width: '3%',
        },
        {
            title: t('block.m'),
            dataIndex: 'name',
            key: 'name',
            width: '10%',

        },
        {
            title: t('cost.baza'),
            dataIndex: 'branch',
            key: 'branch',
            width: '8%',

        },
        {
            title: t('Org.f'),
            dataIndex: 'brand',
            key: 'brand',
            width: '8%',

        },
        {
            title: t('xisob.qmt'),
            dataIndex: 'amount',
            key: 'amount',
            render: (item) => <p className={'m-0'}>{item}</p>,
            width: '10%',
        },
        {
            title: t('xisob.qmson'),
            dataIndex: 'buyPrice',
            key: 'buyPrice',
            render: (item) => <p className={'m-0'}>{prettify(item)} {t('Hamkorlar.sum')}</p>,
            width: '15%',
        },
        {
            title: t('xisob.qmsn'),
            dataIndex: 'salePrice',
            key: 'salePrice',
            render: (item) => <p className={'m-0'}>{prettify(item)} {t('Hamkorlar.sum')}</p>,
            width: '15%',
        },
        {
            title: t('xisob.muts'),
            dataIndex: 'sumByBuyPrice',
            key: 'sumByBuyPrice',
            render: (item) => <p className={'m-0'}>{prettify(item)} {t('Hamkorlar.sum')}</p>,
            width: '15%',
        },
        {
            title: t('xisob.kfs'),
            dataIndex: 'sumBySalePrice',
            key: 'sumBySalePrice',
            render: (item) => <p className={'m-0'}>{prettify(item)} {t('Hamkorlar.sum')}</p>,
            width: '15%',
        },
    ];


    const [branch, setbranch] = useState(null)
    const [category, setCategory] = useState(null)
    const [brand, setBrand] = useState(null)


    function selectBranch(e) {
        if (e.target.value === 'ALL') {
            getQoldiq2({
                businessId: users.businessId
            })
        } else {
            getQoldiq2({
                branchId: e.target.value
            })
        }

        setbranch(e.target.value)
        getQoldiq3({
            branchId: e.target.value,
            category: category === "ALL" ? null : category,
            brand: brand === "ALL" ? null : brand
        })
    }

    function selectCategory(e) {
        setCategory(e.target.value)
        getQoldiq3({
            branchId: branch ? branch : users.branchId,
            category: e.target.value === "ALL" ? null : e.target.value,
            brand: brand === "ALL" ? null : brand
        })
    }

    function selectBrand(e) {
        setBrand(e.target.value)
        getQoldiq3({
            branchId: branch ? branch : users.branchId,
            brand: e.target.value === "ALL" ? null : e.target.value,
            category: category === "ALL" ? null : category,
        })
    }


    function ishlabciqariladigancheck(e) {
        input.ishlabciqariladigancheck = e.target.value
        let a = {...input}
        setInput(a)
    }

    function view(e) {
        input.view = e.target.value
        let a = {...input}
        setInput(a)
    }

    function izlash(e) {
        input.izlash = e.target.value
        let a = {...input}
        setInput(a)
    }

    useEffect(() => {
        // getQoldiq2(users.branchId)
        getQoldiq2({
            businessId: users.businessId
        })
        getQoldiq3({
            branchId: users.branchId,
            brand: null,
            category: null,
        })
        getFirma(users.businessId)
        getBolim(users.businessId)
    }, [])


    return (
        <div className="col-md-12 mt-4">
            <div className="row p-3">
                <MainHeaderText text={t('BalanceReport.1')}/>
            </div>
            <div className="row px-3 align-items-center my-4">
                <div className="col-md-4">
                    <FormControl sx={{m: 1, minWidth: '100%'}} size="small">
                        <InputLabel id="demo-select-small-label">{t('ProductList.10')}</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={branch}
                            label={t('ProductList.10')}
                            IconComponent={KeyboardArrowDownIcon}
                            onChange={selectBranch}
                        >
                            {/*<MenuItem value="ALL">*/}
                            {/*    <em>{t('Hamkorlar.b')}</em>*/}
                            {/*</MenuItem>*/}
                            {
                                users.branches &&
                                users.branches.map(item =>
                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </div>
                <div className="col-md-4">
                    <FormControl sx={{m: 1, minWidth: '100%'}} size="small">
                        <InputLabel id="demo-select-small-label">{t('ProductList.4')}</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={category}
                            label={t('ProductList.4')}
                            IconComponent={KeyboardArrowDownIcon}
                            onChange={selectCategory}
                        >
                            <MenuItem value="ALL">
                                <em>{t('Hamkorlar.b')}</em>
                            </MenuItem>
                            {
                                BolimReducer.bolimlar &&
                                BolimReducer.bolimlar.map(item =>
                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </div>
                <div className="col-md-4">
                    <FormControl sx={{m: 1, minWidth: '100%'}} size="small">
                        <InputLabel id="demo-select-small-label">{t('Firms.1')}</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={brand}
                            label={t('Firms.1')}
                            IconComponent={KeyboardArrowDownIcon}
                            onChange={selectBrand}
                        >
                            <MenuItem value="ALL">
                                <em>{t('Hamkorlar.b')}</em>
                            </MenuItem>
                            {
                                FirmaReducer.firmalar &&
                                FirmaReducer.firmalar.map(item =>
                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </div>
            </div>


            {/*<div className="cardBox">*/}
            {/*    <div className="boxx">*/}
            {/*        <div className="cardlar">*/}
            {/*            <p>{t('BalanceReport.3')} <br/> {t('xisob.ston')}</p>*/}
            {/*            <h3>{QoldiqlarxisobotiReducer.reports2?.totalSumByBuyPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",")} {t('Hamkorlar.sum')}</h3>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="boxx">*/}
            {/*        <div className="cardlar">*/}
            {/*            <p>{t('BalanceReport.3')} <br/> {t('xisob.stoni')}</p>*/}
            {/*            <h3>{QoldiqlarxisobotiReducer.reports2?.totalSumBySalePrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",")} {t('Hamkorlar.sum')}</h3>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="boxx">*/}
            {/*        <div className="cardlar">*/}
            {/*            <p>{t('block.f')}</p>*/}
            {/*            <h3>*/}
            {/*                {(QoldiqlarxisobotiReducer.reports2?.totalSumBySalePrice - QoldiqlarxisobotiReducer.reports2?.totalSumByBuyPrice).toString().replace(/\B(?=(\d{3})+(?!\d))/g,",")}*/}
            {/*                {t('Hamkorlar.sum')}</h3>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="boxx">*/}
            {/*        <div className="cardlar">*/}
            {/*            <p>{t('xisob.FFH')}</p>*/}

            {/*            <h3>{parseFloat((QoldiqlarxisobotiReducer.reports2?.totalSumBySalePrice / QoldiqlarxisobotiReducer.reports2?.totalSumByBuyPrice - 1) * 100).toFixed(2)} %</h3>*/}
            {/*            /!*<h3>{(QoldiqlarxisobotiReducer.reports2.totalSumBySalePrice/QoldiqlarxisobotiReducer.reports2.totalSumByBuyPrice-1)*100}%</h3>*!/*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}


            <div class="row p-3">
                <div class="col-md-12">
                    <CardBody>
                        {
                            QoldiqlarxisobotiReducer.reports ?
                                <div className="table-responsive mb-4">
                                    <CommonTable size={0} page={QoldiqlarxisobotiReducer.reports?.length}
                                                 pagination={false} data={QoldiqlarxisobotiReducer.reports}
                                                 columns={columns}
                                    />
                                </div>
                                : <div>
                                    <h4 className={'text-center'}>{QoldiqlarxisobotiReducer.message}</h4>
                                </div>
                        }
                    </CardBody>
                </div>
            </div>
        </div>
    )
}

export default connect((QoldiqlarxisobotiReducer, FirmaReducer, BolimReducer, users, branchreducer), {
    getbranch,
    getQoldiq3,
    getBolim,
    getFirma,
    getQoldiq2,
})(QoldiqlarXisoboti)
