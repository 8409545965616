import React, {useState, forwardRef, useEffect} from 'react';
import person from '../../../../../img/person-default.png'
import {DragDropContext} from 'react-beautiful-dnd';
import filePicture from '../../../../../img/files.png'
import {connect} from "react-redux";
import {
    Avatar,
    Box,
    Card,
    Checkbox,
    CardActionArea,
    CardContent,
    Grid,
    Slide,
    Tooltip,
    IconButton,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableContainer,
    TableRow,
    ToggleButton,
    ToggleButtonGroup,
    TextField,
    Button,
    Typography,
    Dialog,
    styled,
    DialogTitle,
    DialogContent,
    Autocomplete,
    DialogActions,
    CircularProgress,
    InputLabel,
    Select,
    FormControl, MenuItem, Chip, AvatarGroup, useTheme, Alert, Divider, List, ListItem, ListItemText
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {useTranslation} from 'react-i18next';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import GridViewTwoToneIcon from '@mui/icons-material/GridViewTwoTone';
import TableRowsTwoToneIcon from '@mui/icons-material/TableRowsTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import CircleIcon from '@mui/icons-material/Circle';
import {Formik} from "formik";
import * as Yup from "yup";
import Tasks from "./Tasks";
import taskStatusReducer, {
    saveTaskStatus,
    getTaskStatus,
    deleteTaskStatus,
    editTaskStatus,
    changeTaskStatus
} from "../../../../../reducer/taskStatusReducer";
import users from "../../../../../reducer/users";
import DatePicker from "@mui/lab/DatePicker";
import XodimReducer, {getXodimByBranch} from "../../Hodimlar/reducer/XodimReducer";
import taskTypeReducer, {saveTaskType, getTaskType, deleteTaskType} from "../../../../../reducer/taskTypeReducer";
import ProjectReducer, {getProject} from "../../../../../reducer/ProjectReducer";
import TaskReducer, {
    getTask,
    saveTask,
    deleteTask,
    getTaskPage,
    editTask,
    changeTaskStatusId,
    taskProduction,
    getTaskAllByBranch,
    getTaskByProject, getOneTask, getTaskByName,saveTaskDuplicate
} from "../../../../../reducer/TaskReducer";
import formatDate from "../../../../../util";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {BaseUrl} from "../../../../../middleware";
import contentReducer, {getContent, getContentById} from "../../../../../reducer/contentReducer";
import LaunchTwoToneIcon from "@mui/icons-material/LaunchTwoTone";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import CloudUploadTwoToneIcon from "@mui/icons-material/CloudUploadTwoTone";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import filesReducer, {deleteFiles, saveFiles, clearFiles} from "../../../../../reducer/filesReducer";
import XaridReducer,{getXaridProductType} from "../../Haridlar/reducer/XaridReducer";
import {toast} from "react-toastify";
import LavozimReducer, {getLavozim} from "../../Hodimlar/reducer/LavozimReducer";

const TasksWrapper = styled(Box)(
    ({theme}) => `
      display: flex;
      overflow-y: hidden;
      overflow-x: auto;
      flex-direction: row;
      padding: ${theme.spacing(2)};
  `
);

const TasksWrapperContent = styled(Box)(
    ({theme}) => `
      margin: 0 -${theme.spacing(2)};
  `
);
const CardAddAction = styled(Card)(
    ({theme}) => `
        border: ${theme.colors.primary.main} dashed 1px;
        height: 100px;
        flex: 1;
        min-width: 120px;
        color: ${theme.colors.primary.main};
        
        .MuiCardActionArea-root {
          height: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
        }
        
        .MuiTouchRipple-root {
          opacity: .2;
        }
        
        &:hover {
          border-color: ${theme.colors.alpha.black[100]};
        }
`
);

const AvatarWrapper = styled(Avatar)(
    ({theme}) => `
    background: ${theme.colors.primary.lighter};
    color: ${theme.colors.primary.main};
    width: ${theme.spacing(7)};
    height: ${theme.spacing(7)};
`
);

const BoxUploadWrapper = styled(Box)(
    ({theme}) => `
    border-radius: ${theme.general.borderRadius};
    padding: ${theme.spacing(3)};
    background: ${theme.colors.alpha.black[5]};
    border: 1px dashed ${theme.colors.alpha.black[30]};
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: ${theme.transitions.create(['border', 'background'])};

    &:hover {
      background: ${theme.colors.alpha.white[100]};
      border-color: ${theme.colors.primary.main};
    }
`
);

const AvatarAddWrapper = styled(Avatar)(
    ({theme}) => `
        background: ${theme.colors.alpha.black[5]};
        color: ${theme.colors.primary.main};
        width: ${theme.spacing(8)};
        height: ${theme.spacing(8)};
`
);
const AvatarError = styled(Avatar)(
    ({theme}) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);
const ButtonError = styled(Button)(
    ({theme}) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);
const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
const Results = ({
                     getOneTask,
                     saveTaskStatus,
                     taskStatusReducer,
                     users, saveFiles,
                     getTaskStatus,
                     deleteTaskStatus,
                     editTaskStatus, deleteFiles,
                     XodimReducer, getLavozim, LavozimReducer,
                     taskTypeReducer, saveTaskType, getTaskType, deleteTaskType,
                     TaskReducer, getTask, saveTask, changeTaskStatus, changeTaskStatusId,
                     ProjectReducer, getProject, getXodimByBranch, deleteTask, getTaskPage, editTask,
                     contentReducer, getContent, getContentById, taskProduction, getTaskAllByBranch, getTaskByProject,
                     getTaskByName, filesReducer,XaridReducer,getXaridProductType,saveTaskDuplicate
                 }) => {
    const [selectedItems, setSelectedUsers] = useState([]);
    const {t} = useTranslation();
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [openStatus, setOpenStatus] = useState(false);
    const [openType, setOpenType] = useState(false);
    const [mainBranchId, setMainBranchId] = useState(null)

    const [onFiles, setOnFiles] = useState([])
    const [fileIds, setFileIds] = useState([])
    const [file, setFile] = useState('')
    const [searchContent, setSearchContent] = useState('')
    const [totalTable2, setTotalTable2] = useState(0)
    const [ComboArray, setComboArray] = useState([])
    const [goalAmounContent,setGoalAmountContent] = useState(0)


    useEffect(() => {
        taskFormik.branchId = mainBranchId ? mainBranchId : users.branchId
        let a = {...taskFormik}
        setTaskFormik(a)
    }, [mainBranchId])

    useEffect(() => {
        getTaskStatus(mainBranchId ? mainBranchId : users.branchId)
    }, [taskStatusReducer.current, TaskReducer.current, mainBranchId])

    useEffect(() => {
        getTaskType(mainBranchId ? mainBranchId : users.branchId)
    }, [taskTypeReducer.current, mainBranchId])

    useEffect(() => {
        getTaskPage({
            params: {
                branchId: users.getTaskChecked ? mainBranchId ? mainBranchId : users.branchId : users.branchId,
                userId: users.getTaskChecked ? null : users.id
            }
        })
        getTask({
            params: {
                branchId: users.getTaskChecked ? mainBranchId ? mainBranchId : users.branchId : null,
                page: 0,
                size: 5,
                userId: users.getTaskChecked ? null : users.id
            }
        })
        if (users.addTaskChecked) {
            getTaskAllByBranch({branchId: mainBranchId ? mainBranchId : users.branchId, params: {userId: null}})
        }
    }, [TaskReducer.current, taskStatusReducer.current, mainBranchId])

    useEffect(() => {
        if (users.addTaskChecked) {
            getProject({
                branchId: mainBranchId ? mainBranchId : users.branchId,
                params: {userId: null}
            })
            getXodimByBranch(mainBranchId ? mainBranchId : users.branchId)
            getLavozim(users.businessId)
        }
        if (users.addTaskChecked) {
            getContent(mainBranchId ? mainBranchId : users.branchId)
        }
    }, [mainBranchId])

    const [taskPageList, setTaskPageList] = useState(null)

    useEffect(() => {
        if (TaskReducer.taskPageAble) {
            setTaskPageList(TaskReducer.taskPageAble)
        }
    }, [TaskReducer.getPage, mainBranchId])
    const handleTaskStatusOpen = () => {
        setOpenStatus(true)
    }
    const handleTaskStatusClose = () => {
        setOpenStatus(false)
        setStatusFormik({
            name: '',
            color: '#000000',
            aboolean: false,
        })
        setEditId('')
    }
    const handleTaskTypeOpen = () => {
        setOpenType(true)
    }
    const handleTaskTypeClose = () => {
        setOpenType(false)
    }
    const handleCreateUserOpen = () => {
        setOpen(true);
    };
    const deleteStatus = (id) => {
        deleteTaskStatus(id)
    }
    const [statusFormik, setStatusFormik] = useState({
        name: '',
        color: '#000000',
        aboolean: false,
    })
    const [editID, setEditId] = useState(null)
    const [userPayForm, setUserPayForm] = useState([
        {
            userList: [],
            userListRole:[],
            paySum: 0,
            each: false,
        }
    ])

    function onChangeUser(value, index) {
        let a = {...userPayForm[index]}
        a.userList = value
        userPayForm[index] = a
        let b = [...userPayForm]
        setUserPayForm(b)
    }

    function onChangeRoleUser(value, index) {
        let a = {...userPayForm[index]}
        let xodim = []
        let allSelect = false
        const list = value?.map((lavozim) => {
            if(lavozim.id === "ALL"){
                allSelect = true
            }
            const array = XodimReducer.xodimlar?.filter((qism) => {
                return qism.roleId === lavozim?.id
            })
            xodim = [...xodim,...array]
            return array
        })
        a.userList = allSelect ? XodimReducer.xodimlar : xodim
        a.userListRole = allSelect ? LavozimReducer.lavozimlar : value
        userPayForm[index] = a
        let b = [...userPayForm]
        setUserPayForm(b)
    }

    function onChangeUserPay(value, index) {
        let a = {...userPayForm[index]}
        a.paySum = value
        userPayForm[index] = a
        let b = [...userPayForm]
        setUserPayForm(b)
    }

    function onChangeUserEach(value, index) {
        let a = {...userPayForm[index]}
        a.each = value
        userPayForm[index] = a
        let b = [...userPayForm]
        setUserPayForm(b)
    }

    useEffect(() => {
        calcTotalSum()
    }, [userPayForm])

    const [taskTotalPrice,setTaskTotalPrice] = useState(0)

    function calcTotalSum() {
        let sum = 0
        userPayForm.map(item => {
                if (item.each) {
                    sum += parseInt(item.paySum === '' ? 0 : item.paySum) * item.userList?.length
                } else {
                    sum += parseInt(item.paySum === '' ? 0 : item.paySum)
                }
            }
        )
        setTaskTotalPrice(sum)
    }

    function addNewUserPayForm() {
        userPayForm.push(
            {
                userList: [],
                userListRole:[],
                paySum: 0,
                price: 0,
                each: false,
            }
        )
        let a = [...userPayForm]
        setUserPayForm(a)
    }

    function deleteUserPayForm(index) {
        let a = userPayForm?.filter((item, number) => index !== number)
        setUserPayForm(a)
    }

    const editStatus = (id) => {
        handleTaskStatusOpen()
        setEditId(id)
        if (taskStatusReducer.taskStatus) {
            let a = taskStatusReducer.taskStatus?.filter(item => item.id === id)
            statusFormik.name = a[0].name
            statusFormik.color = a[0].color
            statusFormik.aboolean = a[0].aboolean
            let b = {...statusFormik, rowNumber: a[0].rowNumber}
            setStatusFormik(b)
        }
    }
    const onMore = (id) => {
        changeTaskStatus(
            {
                isBoolean: false,
                id: id
            }
        )
    }
    const onLess = (id) => {
        changeTaskStatus(
            {
                isBoolean: true,
                id: id
            })
    }

    function changeSelectBranch(e) {
        setMainBranchId(e.target.value)
        setProjectUser(null)
        setProjectStages(null)
        taskFormik.branchId = mainBranchId
        taskFormik.dependTask = ''
        taskFormik.each = false
        taskFormik.goalAmount = ''
        taskFormik.importance = ''
        taskFormik.description = ''
        taskFormik.name = ''
        taskFormik.contentId = ''
        taskFormik.productions = false
        taskFormik.projectId = ''
        taskFormik.startDate = new Date()
        taskFormik.taskPrice = ''
        taskFormik.taskTypeId = ''
        taskFormik.stageId = ''
        taskFormik.cost = ''
        let a = {...taskFormik}
        setTaskFormik(a)
    }

    const handleCreateUserClose = () => {
        setOpen(false);
        setEditTaskId(null)
        taskFormik.branchId = mainBranchId
        taskFormik.dependTask = ''
        taskFormik.each = false
        taskFormik.goalAmount = ''
        taskFormik.importance = ''
        taskFormik.name = ''
        taskFormik.contentId = ''
        taskFormik.productions = false
        taskFormik.projectId = ''
        taskFormik.startDate = new Date()
        taskFormik.taskPrice = ''
        taskFormik.taskTypeId = ''
        taskFormik.stageId = ''
        taskFormik.cost = ''
        let a = {...taskFormik}
        setTaskFormik(a)
        setUserPayForm([{
            userList: [],
            userListRole:[],
            paySum: 0,
            each: false
        }])
        setFileIds([])
        setOnFiles([])
    };
    const [contentArray, setContentArray] = useState(null)
    const [contentFormik, setContentFormik] = useState({
        totalQuantity: '',
        invalid: '',
        taskId: '',
        taskStatusId: '',
        date: '',
    })
    const [totalPrice, setTotalPrice] = useState(0)
    const [noChangesContentAmount, setNoChangesContentAmount] = useState(0)

    useEffect(() => {
        if (editTaskId){
            if (TaskReducer.taskOne) {
                let task = {...TaskReducer.taskOne}
                taskFormik.branchId = task.branchId
                taskFormik.dependTask = task.dependTask ? task.dependTask : null
                taskFormik.goalAmount = task.goalAmount
                setGoalAmountContent(task.goalAmount)
                taskFormik.importance = task.importance
                taskFormik.name = task.name
                taskFormik.productions = task.productions
                taskFormik.description = task.description
                taskFormik.projectId = task.projectId
                if (ProjectReducer.project) {
                    let stages = ProjectReducer.project?.filter(item => item.id === task.projectId)
                    let list = {...stages[0]}
                    setProjectStages(list.stageList)
                }
                setTaskTotalPrice(task.taskPrice)
                taskFormik.stageId = task.stageId
                setOnFiles(task.fileDataIdList ? task.fileDataIdList : [])
                let Ids = []
                if (task.fileDataList) {
                    task.fileDataList.map(item => {
                        Ids.push(item.id)
                    })
                }
                setFileIds(Ids)
                let editPayForm = []
                task?.taskPriceList.map(data => {
                    editPayForm.push({
                        userList: data.userList,
                        userListRole:[],
                        paySum: data.price,
                        price: 0,
                        each: data.each,
                        id: data.id
                    })
                })
                setUserPayForm(editPayForm)
                taskFormik.taskTypeId = task.taskTypeId
                taskFormik.contentId = task.contentId
                setDeadLine(task.deadLine)
                if (task.contentProductList) {
                    let contentForm = []
                    let c = 0
                    task.contentProductList.map(item => {
                        contentForm.push({
                            name: item.product ? item.product?.name : item.productTypePrice?.name,
                            measurementName: item.product ? item.product?.measurement.name : item.productTypePrice.product?.measurement.name,
                            amount: item.productWarehouseAmount,
                            contentProductIdForEditOrNull: item.contentProductIdForEditOrNull,
                            delete: item.delete,
                            productId: item.product ? item.product.id : null,
                            productTypePriceId: item.productTypePrice ? item.productTypePrice.id : null,
                            noChangesQuantity: item.quantity,
                            totalPrice:item.totalPrice,
                            quantity: item.quantity,
                            buyPrice: item.product ? item.product?.buyPrice : item.productTypePrice?.buyPrice,
                            salePrice: item.product ? item.product?.salePrice : item.productTypePrice?.salePrice,
                            disabled: false,
                            active: false,
                            byProduct: item.byProduct
                        })
                        if (item.byProduct) {
                            c -= parseInt(item.totalPrice)

                        } else {
                            c += parseInt(item.totalPrice)
                        }
                    })
                    setContentArray(contentForm)
                    setTotalPrice(c)
                }
                if (task.costGetDtoList) {
                    let a = []
                    task.costGetDtoList.map(item => {
                        a.push(
                            {
                                costTypeId:item.costTypeId,
                                costTypeName:item.costTypeName,
                                sum:item.sum,
                                noChangesSum: item.sum
                            }
                        )
                    })
                    totalCost(a)
                    setCostFrom(a)
                }
                getStartDate(task.startDate)
                let form = {...taskFormik}
                setTaskFormik(form)
            }
        }
    }, [TaskReducer.getOneTaskBoolean])

    const [costEach,setCostEach] = useState(false)

    useEffect(() => {
        let a = []
        let c = 0
        if (contentReducer.oneContent) {
            setNoChangesContentAmount(contentReducer?.oneContent?.quantity)
            setCostEach(contentReducer?.oneContent?.costEachOne)
            setGoalAmountContent(contentReducer?.oneContent?.quantity)
            getXaridProductType(contentReducer?.oneContent?.branch?.id)
            taskFormik.cost = contentReducer?.oneContent?.cost
        }
        if (contentReducer.contentProductList) {
                contentReducer.contentProductList.map(item => {
                    a.push({
                        name: item.product ? item.product?.name : item.productTypePrice?.name,
                        measurementName: item.product ? item.product?.measurement.name : item.productTypePrice.product?.measurement.name,
                        amount: item.productWarehouseAmount,
                        contentProductIdForEditOrNull: item.contentProductIdForEditOrNull,
                        delete: item.delete,
                        productId: item.product ? item.product.id : null,
                        productTypePriceId: item.productTypePrice ? item.productTypePrice.id : null,
                        noChangesQuantity: item.quantity,
                        totalPrice:item.totalPrice,
                        quantity: item.quantity,
                        buyPrice: item.product ? item.product?.buyPrice : item.productTypePrice?.buyPrice,
                        salePrice: item.product ? item.product?.salePrice : item.productTypePrice?.salePrice,
                        disabled: false,
                        active: false,
                        byProduct: item.byProduct
                    })
                    if (item.byProduct) {
                        c -= parseInt(item.totalPrice)

                    } else {
                        c += parseInt(item.totalPrice)
                    }
                })
        }
        if (contentReducer.costGetDtoList) {
            let a = []
            contentReducer.costGetDtoList.map(item => {
                a.push(
                    {
                        costTypeId:item.costTypeId,
                        costTypeName:item.costTypeName,
                        sum:item.sum,
                        noChangesSum: item.sum
                    }
                )
            })
            totalCost(a)
            setCostFrom(a)
        }
        let form = {...contentFormik}
        setContentFormik(form)
        setTotalPrice(c)
        setContentArray(a)
    }, [contentReducer.current])

    const [backTotalPrice,setBackTotalPrice] = useState(0)


    function eachCalculate(each,price,sum,order){
        if(each){
            let a = parseFloat(price) + (parseFloat(sum === "" ? 0 : sum) * order)
            setBackTotalPrice(a)
        }
        else{
            let a = parseFloat(price) + parseFloat(sum === "" ? 0 : sum)
            setBackTotalPrice(a)
        }
    }

    const [addSum, setAddSum] = useState(0)

    const [costForm, setCostFrom] = useState([
        {
            costTypeId: '',
            sum: 0,
        }
    ])

    function changeAddSum(e, index) {
        let a = {...costForm[index]}
        a.sum = e.target.value
        costForm[index] = a
        let b = [...costForm]
        setCostFrom(b)
        totalCost(b)
    }

    function totalCost(array) {
        let a = 0
        array.map(item => {
            a += parseFloat(item.sum)
        })
        setAddSum(a)
    }


    const [productionModal, setProductionModal] = useState(false)

    useEffect(()=>{
        eachCalculate(costEach,totalPrice,addSum,goalAmounContent)
    },[goalAmounContent,addSum,totalPrice])
    function changeOrder(value) {
        contentArray.map(item => {
            item.quantity = ((value * item.noChangesQuantity) / noChangesContentAmount)
            if (item.byProduct) {
                item.totalPrice = item.quantity * Math.round((item.salePrice + Number.EPSILON) * 100) / 100

            } else {
                item.totalPrice = item.quantity * Math.round((item.buyPrice + Number.EPSILON) * 100) / 100

            }
        })
        let a = [...contentArray]
        setContentArray(a)
        let c = 0
        contentArray.map(item => {
            if (item.byProduct) {
                c -= parseInt((item.quantity * Math.round((item.salePrice + Number.EPSILON) * 100)) / 100)

            } else {
                c += parseInt((item.quantity * Math.round((item.buyPrice + Number.EPSILON) * 100)) / 100)

            }
        })
        contentFormik.totalQuantity = value
        setTotalPrice(c)
        let form = {...contentFormik}
        costForm.map(item=>{
            if(!costEach){
            item.sum = ((value * item.noChangesSum) / noChangesContentAmount)
            }
        })
        let f = [...costForm]
        totalCost(f)
        setCostFrom(f)
        setContentFormik(form)
    }

    function changeCount(e, id) {
        let c = 0
        contentArray.map((item, index) => {
            if (index === id) {
                if (parseFloat(e.target.value) >= item.amount) {
                    item.quantity = parseFloat(e.target.value)
                    if (item.byProduct) {
                        item.totalPrice = item.quantity * Math.round((item.salePrice + Number.EPSILON) * 100) / 100
                    } else {
                        item.totalPrice = item.quantity * Math.round((item.buyPrice + Number.EPSILON) * 100) / 100

                    }
                    item.active = true
                    item.disabled = false
                } else {
                    item.quantity = parseFloat(e.target.value)
                    if (item.byProduct) {
                        item.totalPrice = item.quantity * Math.round((item.salePrice + Number.EPSILON) * 100) / 100
                    } else {
                        item.totalPrice = item.quantity * Math.round((item.buyPrice + Number.EPSILON) * 100) / 100

                    }
                    item.disabled = false
                    item.active = false
                }
            }
            if (item.byProduct) {
                c -= parseInt((item.quantity * Math.round((item.salePrice + Number.EPSILON) * 100)) / 100)

            } else {
                c += parseInt((item.quantity * Math.round((item.buyPrice + Number.EPSILON) * 100)) / 100)
            }
        })
        let a = [...contentArray]
        setTotalPrice(c)
        let form = {...contentFormik}
        setContentFormik(form)
        setContentArray(a)
    }


    const cancelProduction = () => {
        setProductionModal(false)
        if (TaskReducer.taskPageAble) {
            setTaskPageList(TaskReducer.taskPageAble)
        }
    }

    const handleDragEnd = async ({source, destination, draggableId}) => {
        if (source.droppableId !== destination.droppableId) {
            let get = taskPageList?.filter(item => item.statusId === source.droppableId)
            let getIndex = taskPageList.findIndex(item => item.statusId === source.droppableId)
            let set = taskPageList?.filter(item => item.statusId === destination.droppableId)
            let setIndex = taskPageList.findIndex(item => item.statusId === destination.droppableId)
            let getItems = {...get[0]}
            let setItems = {...set[0]}
            let getItem = getItems.getLessProduct?.filter(item => item.id === draggableId)
            let setItemss = [...setItems.getLessProduct, {...getItem[0], taskStatusId: destination.droppableId}]
            let getItemss = getItems.getLessProduct?.filter(item => item.id !== draggableId)
            let a = {...getItems, getLessProduct: getItemss, totalItems: getItemss.length}
            let b = {...setItems, getLessProduct: setItemss, totalItems: setItemss.length}
            let lists = [...taskPageList]
            lists[getIndex] = a
            lists[setIndex] = b

            let isCompleted = taskStatusReducer.taskStatus.some(item => item.id === destination.droppableId && item.orginalName === 'Completed')
            if (isCompleted) {
                let testProduction = {...getItem[0]}
                if (testProduction.productions) {
                    getOneTask(testProduction.id)
                    contentFormik.taskId = draggableId
                    contentFormik.taskStatusId = destination.droppableId
                    let form = {...contentFormik}
                    setContentFormik(form)
                    setProductionModal(true)
                } else {
                    changeTaskStatusId({
                        id: draggableId,
                        statusId: destination.droppableId
                    })
                }
            } else {
                changeTaskStatusId({
                    id: draggableId,
                    statusId: destination.droppableId
                })
            }


        }
    }



    useEffect(()=>{
        if (TaskReducer.taskOne) {
            let task = {...TaskReducer.taskOne}
            contentFormik.invalid = task.production ? task?.production?.invalid : 0
            contentFormik.date = new Date().getDate()
            contentFormik.taskId = task.id
            contentFormik.totalQuantity = task.goalAmount
            setGoalAmountContent(task.goalAmount)
            setCostEach(task.production ? task.production.costEachOne:false)
            if (task.contentProductList) {
                let contentForm = []
                let c = 0
                task.contentProductList.map(item => {
                    contentForm.push({
                        name: item.product ? item.product?.name : item.productTypePrice?.name,
                        measurementName: item.product ? item.product?.measurement.name : item.productTypePrice.product?.measurement.name,
                        amount: item.productWarehouseAmount,
                        contentProductIdForEditOrNull: item.contentProductIdForEditOrNull,
                        delete: item.delete,
                        productId: item.product ? item.product.id : null,
                        productTypePriceId: item.productTypePrice ? item.productTypePrice.id : null,
                        noChangesQuantity: item.quantity,
                        totalPrice:item.totalPrice,
                        quantity: item.quantity,
                        buyPrice: item.product ? item.product?.buyPrice : item.productTypePrice?.buyPrice,
                        salePrice: item.product ? item.product?.salePrice : item.productTypePrice?.salePrice,
                        disabled: false,
                        active: false,
                        byProduct: item.byProduct
                    })
                    if (item.byProduct) {
                        c -= parseInt(item.totalPrice)

                    } else {
                        c += parseInt(item.totalPrice)
                    }
                })
                setContentArray(contentForm)
                setTotalPrice(c)
            }
            if (task.costGetDtoList) {
                let a = []
                task.costGetDtoList.map(item => {
                    a.push(
                        {
                            costTypeId:item.costTypeId,
                            costTypeName:item.costTypeName,
                            sum:item.sum,
                            noChangesSum: item.sum
                        }
                    )
                })
                totalCost(a)
                setCostFrom(a)
            }
            contentFormik.contentPrice = 0
            let form = {...contentFormik}
            setContentFormik(form)
        }
    },[TaskReducer.getOneTaskBoolean])


    function changeTaskStatusContent(e, task) {
        let isCompleted = taskStatusReducer.taskStatus.some(item => item.id === e.target.value && item.orginalName === 'Completed')
        if (isCompleted) {
            let testProduction = {...task}
            if (testProduction.productions) {
                getOneTask(testProduction.id)
                contentFormik.taskStatusId = e.target.value
                let form = {...contentFormik}
                setContentFormik(form)
                setProductionModal(true)
            } else {
                changeTaskStatusId({
                    id: task.id,
                    statusId: e.target.value
                })
            }
        } else {
            changeTaskStatusId({
                id: task.id,
                statusId: e.target.value
            })
        }
        setQuery('')
        getClear()
    }

    useEffect(() => {
        if (TaskReducer.saveBoolean) {
            setProductionModal(false)
            setTotalPrice(0)
            setContentFormik({
                totalQuantity: '',
                invalid: '',
                taskId: '',
                taskStatusId: '',
                date: '',
            })
            setarr2([])
            setContentArray(null)
        }
    }, [TaskReducer.current])

    const showMore = (id) => {
        const lidMap = new Map();
        // const listMap = []
        if (taskPageList) {
            taskPageList.map(item => {
                if (item.statusId === id) {
                    lidMap[item?.statusId] = item?.getLessProduct?.length + 5
                    // listMap.push({id:item?.statusId,count:item?.getLessProduct?.length + 5})
                } else {
                    if (item?.getLessProduct?.length > 5) {
                        lidMap[item?.statusId] = parseInt(item?.getLessProduct?.length)
                        // listMap.push({id:item?.statusId,count:item?.getLessProduct?.length})

                    }
                }
            })
        }
        getTaskPage({
            params: {
                ...lidMap,
                branchId: users.getTaskChecked ? mainBranchId ? mainBranchId : users.branchId : users.branchId,
                userId: users.getTaskChecked ? null : users.id
            }
        })
    }


    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(5);
    const [query, setQuery] = useState(null);


    const handleQueryChange = (event) => {
        event.persist();
        setQuery(event.target.value);
        setStatusIdFilter(null)
        setProjectIdFilter(null)
        setProjectTypeFilter(null)
        setActiveFilter(false)
        if (event.target.value === '') {
            getTask(
                {
                    params: {
                        branchId: users.getTaskChecked ? mainBranchId ? mainBranchId : users.branchId : null,
                        page: page,
                        size: limit,
                        userId: users.getTaskChecked ? null : users.id
                    }

                }
            )
        } else {
            getTaskByName({
                name: event.target.value,
                params: {
                    page: page,
                    size: limit,
                }
            })
        }
    };

    const handlePageChange = (_event, newPage) => {
        setPage(newPage);
        if (query) {
            getTaskByName({
                name: query,
                params: {
                    page: newPage,
                    size: limit,
                }
            })
        } else {
            getTask(
                {
                    params: {
                        branchId: users.getTaskChecked ? mainBranchId ? mainBranchId : users.branchId : null,
                        page: newPage,
                        size: limit,
                        projectId: projectIdFilter ? projectIdFilter : null,
                        statusId: statusIdFilter ? statusIdFilter : null,
                        typeId: projectTypeFilter ? projectTypeFilter : null,
                        expired: deadlineFilter ? formatDate(new Date()) : null,
                        userId: users.getTaskChecked ? null : users.id

                    }

                }
            )
        }
    };

    const handleLimitChange = (event) => {
        setLimit(parseInt(event.target.value));
        if (query) {
            getTaskByName({
                name: query,
                params: {
                    page: page,
                    size: event.target.value,
                }
            })
        } else {
            getTask(
                {
                    params: {
                        branchId: users.getTaskChecked ? mainBranchId ? mainBranchId : users.branchId : null,
                        page: page,
                        size: event.target.value,
                        projectId: projectIdFilter ? projectIdFilter : null,
                        statusId: statusIdFilter ? statusIdFilter : null,
                        typeId: projectTypeFilter ? projectTypeFilter : null,
                        expired: deadlineFilter ? formatDate(new Date()) : null,
                        userId: users.getTaskChecked ? null : users.id

                    }

                }
            )
        }
    };


    useEffect(() => {
        if (TaskReducer.saveBoolean) {
            handleCreateUserClose()
            getTask(
                {
                    params: {
                        branchId: users.getTaskChecked ? mainBranchId ? mainBranchId : users.branchId : null,
                        page: page,
                        size: limit,
                        userId: users.getTaskChecked ? null : users.id
                    }

                }
            )
        }
    }, [TaskReducer.current])

    const [toggleView, setToggleView] = useState('table_view');

    const handleViewOrientation = (_event, newValue) => {
        setToggleView(newValue);
        getClear()
    };

    const [startDate, getStartDate] = useState(new Date())
    const [deadLine, setDeadLine] = useState(new Date())

    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [deleteTaskId, setDeleteTaskId] = useState('')
    const handleConfirmDelete = (id) => {
        setDeleteTaskId(id)
        setOpenConfirmDelete(true);
    };

    const closeConfirmDelete = () => {
        setOpenConfirmDelete(false);
        setDeleteTaskId('')
    };

    const handleDeleteCompleted = () => {
        deleteTaskType(deleteTaskId)
        setOpenConfirmDelete(false);
    };
    const [openConfirmDeleteTask, setOpenConfirmDeleteTask] = useState(false);
    const [deleteMainTaskId, setDeleteMainTaskId] = useState('')
    const handleConfirmDeleteTask = (id) => {
        setDeleteMainTaskId(id)
        setOpenConfirmDeleteTask(true);
    };

    const [editTaskId, setEditTaskId] = useState(null)
    const [taskFormik, setTaskFormik] = useState({
        branchId: '',
        dependTask: '',
        contentId: '',
        goalAmount: '',
        importance: '',
        description:'',
        name: '',
        productions: false,
        projectId: '',
        taskTypeId: '',
        cost:''
    })
    const handleConfirmUpdateTask = (id) => {
        setEditTaskId(id)
        getOneTask(id)
        handleCreateUserOpen()

    };





    const [projectStages, setProjectStages] = useState(null)
    const [projectUser, setProjectUser] = useState(null)

    function selectStage(e) {
        let stages = ProjectReducer.project?.filter(item => item.id === e.target.value)
        let list = {...stages[0]}
        setProjectStages(list.stageList)
        setProjectUser(list.users)
    }

    const closeConfirmDeleteTask = () => {
        setOpenConfirmDeleteTask(false);
        setDeleteMainTaskId('')
    };

    const handleDeleteCompletedTask = () => {
        deleteTask(deleteMainTaskId)
        setOpenConfirmDeleteTask(false);
    };

    useEffect(() => {
        if (taskStatusReducer.saveBoolean) {
            handleTaskStatusClose()
        }
    }, [taskStatusReducer.current])

    useEffect(() => {
        if (taskTypeReducer.saveBoolean) {
            handleTaskTypeClose()
        }
    }, [taskTypeReducer.current])

    const [deadlineFilter, setDeadlineFilter] = useState(null)
    const [projectIdFilter, setProjectIdFilter] = useState(null)
    const [projectTypeFilter, setProjectTypeFilter] = useState(null)
    const [statusIdFilter, setStatusIdFilter] = useState(null)
    const [activeFilter, setActiveFilter] = useState(false)

    function getOk() {
        if (toggleView === 'table_view') {
            getTask({
                params: {
                    branchId: users.getTaskChecked ? mainBranchId ? mainBranchId : users.branchId : null,
                    page: page,
                    size: limit,
                    expired: deadlineFilter ? formatDate(new Date()) : null,
                    projectId: projectIdFilter ? projectIdFilter : null,
                    statusId: statusIdFilter ? statusIdFilter : null,
                    typeId: projectTypeFilter ? projectTypeFilter : null,
                    userId: users.getTaskChecked ? null : users.id
                }
            })
        } else {
            getTaskPage({
                params: {
                    branchId: users.getTaskChecked ? mainBranchId ? mainBranchId : users.branchId : users.branchId,
                    expired: deadlineFilter ? formatDate(new Date()) : null,
                    typeId: projectTypeFilter ? projectTypeFilter : null,
                    projectId: projectIdFilter ? projectIdFilter : null,
                    userId: users.getTaskChecked ? null : users.id
                }
            })
        }
        setActiveFilter(false)
    }

    function getClear() {
        setStatusIdFilter(null)
        setProjectIdFilter(null)
        setProjectTypeFilter(null)
        if (toggleView === 'table_view') {
            getTask({
                params: {
                    branchId: users.getTaskChecked ? mainBranchId ? mainBranchId : users.branchId : null,
                    page: page,
                    size: limit,
                    projectId: null,
                    typeId: null,
                    statusId: null,
                    userId: users.getTaskChecked ? null : users.id
                }
            })
        } else {
            getTaskPage({
                params: {
                    branchId: users.getTaskChecked ? mainBranchId ? mainBranchId : users.branchId : users.branchId,
                    sourceId: null,
                    typeId: null,
                    userId: users.getTaskChecked ? null : users.id
                }
            })
        }

        setActiveFilter(false)
    }

    const [viewTask, setViewTask] = useState(false)

    function viewTaskToggle() {
        setViewTask(!viewTask)
    }

    function viewFunc(id) {
        viewTaskToggle()
        getOneTask(id)
    }

    function renderUserList(list) {
        let a = []
        list.map(item => {
                item.userList.map(value => {
                    let isHave = a.some(user => user.id === value.id)
                    if (!isHave) {
                        a.push(value)
                    }
                })
            }
        )
        return (
            <AvatarGroup max={4}>
                {a.map((member) => (
                    <Tooltip
                        arrow
                        placement="top"
                        key={member?.firstName}
                        title={member?.firstName + " " + member?.lastName}
                    >
                        <Avatar
                            sx={{
                                width: 30,
                                height: 30
                            }}
                            key={member?.id}
                            src={member?.photo?.id ? `${BaseUrl}/attachment/download/${member?.photo?.id}` : person}
                        />
                    </Tooltip>
                ))}
            </AvatarGroup>
        )
    }


    useEffect(() => {
        if (filesReducer.saveBoolean) {
            let a = [...onFiles, file]
            fileIds.push(filesReducer.files)
            let ids = [...fileIds]
            setFileIds(ids)
            setOnFiles(a)
            clearFiles()
        }
    }, [filesReducer.current])

    const [fileUrl, setFileUrl] = useState(null);

    function getFilesById(id) {
        setFileUrl(`${BaseUrl}/files/download/${id}`)
    }

    function handleEmbedUnload() {
        setFileUrl(null);
    }

    useEffect(() => {
        setFileUrl(null)
    }, [])



    useEffect(() => {
        getXaridProductType(mainBranchId ? mainBranchId:users.branchId)
    }, [mainBranchId])

    function onChangeSearch(e) {
        setSearchContent(e.target.value)
        let a = []
        if (XaridReducer.xaridMahsulot){
            XaridReducer.xaridMahsulot?.filter(val => {
                if (e.target.value === '') {
                    setComboArray([])
                } else if (val.name.toUpperCase().includes(e.target.value.toUpperCase())) {
                    a.push(val)
                } else if (val.barcode.includes(e.target.value)) {
                    a.push(val)
                }

            })

        }
        else{
            toast.error('Bu Bazada Mahsulot yoq')
        }
        setComboArray(a)
    }

    const [arr2, setarr2] = useState([])

    function pushesh(val) {

        let order = false
        arr2.map(item => {
            if (item.productTypePriceId === null) {
                if (item.productId === val.productId) {
                    order = true
                }
            } else if (item.productId === null) {
                if (item.productTypePriceId === val.productTypePriceId) {
                    order = true
                }
            }
        })
        if (order === true) {
            toast.info('Mahsulot jadvalda bor')
        } else {
            arr2.push({
                productId: val.productId ? val.productId : null,
                productTypePriceId: val.productTypePriceId ? val.productTypePriceId : null,
                contentProductIdForEditOrNull: null,
                quantity: 1,
                totalPrice: 1 * val.salePrice,
                name: val.name,
                buyPrice: val.buyPrice,
                salePrice: val.salePrice,
                measurementName: val.measurementName,
                disabled: false,
                active: false,
                delete: false,
                byProduct:false,
            })
            order = false
        }
        let b = [...arr2]
        setarr2(b)
        setSearchContent('')
        setComboArray([])
        xisobkitob()
    }
    function changeCountNumber(e, id) {
        arr2.map((item, index) => {
            if (index === id) {

                item.quantity = parseFloat(e.target.value)
                item.totalPrice = item.quantity * Math.round((item.salePrice + Number.EPSILON) * 100) / 100
            }

        })
        let a = [...arr2]
        setarr2(a)
        xisobkitob()
    }
    function xisobkitob() {
        let b = 0
        arr2.map(item=>{
            b += parseInt((item.quantity * Math.round((item.salePrice + Number.EPSILON) * 100)) / 100)
        })
        setTotalTable2(b)
    }




    return (
        <>
            <Box
                display="flex"
                alignItems="center"
                flexDirection={{xs: 'column', sm: 'row'}}
                justifyContent={{xs: 'center', sm: 'space-between'}}
                pb={3}
            >
                <Grid container alignItems={'center'} xs={12}>
                    <Grid item display={'flex'} xs={8}>

                        {
                            users.addTaskChecked ?
                                <Button
                                    sx={{
                                        mt: {xs: 2, sm: 0}
                                    }}
                                    onClick={handleCreateUserOpen}
                                    variant="contained"
                                    startIcon={<AddTwoToneIcon fontSize="small"/>}
                                >
                                    {t('newChanges.task_add')}
                                </Button> : ''
                        }
                        {
                            users.addTaskStatusChecked ?
                                <Button
                                    sx={{
                                        mt: {xs: 2, sm: 0}
                                    }}
                                    onClick={handleTaskStatusOpen}
                                    variant="contained"
                                    startIcon={<AddTwoToneIcon fontSize="small"/>}
                                >
                                    {t('newChanges.task_status_add')}
                                </Button> : ''
                        }

                        <Button className={'ms-2'}
                                sx={{
                                    mt: {xs: 2, sm: 0}
                                }}
                                onClick={() => {
                                    setActiveFilter(prevState => !prevState)
                                    setQuery('')
                                }}
                                variant="contained"
                                startIcon={<FilterAltIcon fontSize="small"/>}
                        >
                            {t('Buttons.16')}
                        </Button>
                        {
                            users.branches.length > 1 ?
                                users.branches ?
                                    <FormControl>
                                        <InputLabel id="demo-simple-select-label">{t('CustomAll.5')}</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={mainBranchId ? mainBranchId : users.branchId}
                                            label={t('CustomAll.5')}
                                            onChange={(e) => {
                                                setMainBranchId(e.target.value)
                                                getClear()
                                            }}
                                            name={'branchId'}
                                        >
                                            {
                                                users?.branches.map((item) =>
                                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                                )}
                                        </Select>
                                    </FormControl> : ""
                                : ''
                        }
                    </Grid>
                    <Grid item display={'flex'} justifyContent={'end'} xs={4}>
                        <ToggleButtonGroup
                            sx={{
                                mt: {xs: 2, sm: 0}
                            }}
                            value={toggleView}
                            exclusive
                            onChange={handleViewOrientation}
                        >
                            <ToggleButton disableRipple value="table_view">
                                <TableRowsTwoToneIcon/>
                            </ToggleButton>
                            <ToggleButton disableRipple value="grid_view">
                                <GridViewTwoToneIcon/>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                </Grid>

            </Box>
            {
                activeFilter ?
                    <Box
                        display="flex"
                        alignItems="center"
                        flexDirection={{xs: 'column', sm: 'row'}}
                        justifyContent={{xs: 'center', sm: 'space-between'}}
                        pb={3}
                    >

                        <Grid item xs={12} md={3} lg={3}>
                            {
                                ProjectReducer.project ?
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">{t('Lids.lidManba')}</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={projectIdFilter}
                                            label={t('Lids.lidManba')}
                                            onChange={(e) => {
                                                setProjectIdFilter(e.target.value)
                                            }}
                                        >
                                            {
                                                ProjectReducer.project ?
                                                    ProjectReducer.project.map(item =>
                                                        <MenuItem className={'mb-1 mt-1'}
                                                                  value={item.id}>{item.name}</MenuItem>
                                                    )
                                                    : <MenuItem value={'--'}>---</MenuItem>
                                            }
                                        </Select>
                                    </FormControl>
                                    : ''
                            }
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            {
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">{t('Lids.lidManba')}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={projectTypeFilter}
                                        label={t('Lids.lidManba')}
                                        onChange={(e) => {
                                            setProjectTypeFilter(e.target.value)
                                        }}
                                    >
                                        {
                                            taskTypeReducer.taskTypes ?
                                                taskTypeReducer.taskTypes.map(item =>
                                                    <MenuItem className={'mb-1 mt-1'}
                                                              value={item.id}>{item.name}</MenuItem>
                                                )
                                                : <MenuItem value={'--'}>---</MenuItem>
                                        }
                                    </Select>
                                </FormControl>
                            }
                        </Grid>
                        {

                            toggleView === 'table_view' ?
                                taskStatusReducer.taskStatus ?
                                    <Grid item xs={12} md={3} lg={3}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">{t('lidForma.Status')}</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={statusIdFilter}
                                                label="Status"
                                                onChange={(e) => {
                                                    setStatusIdFilter(e.target.value)
                                                }}
                                                name={'dependTask'}
                                            >
                                                {
                                                    taskStatusReducer.taskStatus ?
                                                        taskStatusReducer.taskStatus.map(item =>
                                                            <MenuItem className={'mb-1 mt-1'}
                                                                      value={item.id}>{item.name}</MenuItem>
                                                        )
                                                        : <MenuItem value={'--'}>---</MenuItem>
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    : '' : ''


                        }
                        <Grid item xs={12} md={3} lg={3} display={'flex'} justifyContent={'center'}
                              alignItems={'center'}>
                            <Checkbox
                                checked={deadlineFilter}
                                onClick={(e) => setDeadlineFilter(e.target.checked)}
                                sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                            />
                            <Typography variant='h3'>
                                {t('ol.58')}
                            </Typography>
                        </Grid>


                        <Button className={'ms-2'}
                                sx={{
                                    mt: {xs: 2, sm: 0}
                                }}
                                onClick={getOk}
                                variant="contained"
                                startIcon={<PublishedWithChangesIcon fontSize="small"/>}
                        >
                            {t('OK')}
                        </Button>
                        <Button className={'ms-2'}
                                sx={{
                                    mt: {xs: 2, sm: 0}
                                }}
                                onClick={getClear}
                                variant="contained"
                                startIcon={<HighlightOffIcon fontSize="small"/>}
                        >
                            {t('Clear')}
                        </Button>
                    </Box> : ''
            }
            {toggleView === 'table_view' && (
                <Card>
                    <Box p={2}>
                        <TextField
                            sx={{
                                m: 0,
                                outline: 'none'
                            }}
                            label={'Qidirish'}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchTwoToneIcon/>
                                    </InputAdornment>
                                )
                            }}
                            onChange={handleQueryChange}
                            placeholder={t('Izlash')}
                            value={query}
                            size="small"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                        />
                    </Box>


                    <>
                        {TaskReducer.tasks ?
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {/*<TableCell padding="checkbox">*/}
                                            {/*    <Checkbox*/}
                                            {/*        onChange={handleSelectAllUsers}*/}
                                            {/*    />*/}
                                            {/*</TableCell>*/}
                                            <TableCell>Tr</TableCell>
                                            <TableCell>Vazifa</TableCell>
                                            <TableCell>Loyiha</TableCell>
                                            <TableCell>Tugash sanasi</TableCell>
                                            <TableCell>Hodimlar</TableCell>
                                            <TableCell>Mahsulot</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell align="center">{t('Amal')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {TaskReducer.tasks.map((task, index) => {
                                            const isUserSelected = selectedItems.includes(task.id);
                                            return (
                                                <TableRow hover key={task.id} selected={isUserSelected}>
                                                    <TableCell> <Typography variant="h5">
                                                        {index + 1}
                                                    </Typography></TableCell>
                                                    <TableCell>
                                                        <Typography variant="h5">
                                                            {task.name}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="h5">
                                                            {task?.project?.name}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography>{formatDate(task.deadLine)}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography display={'flex'}>
                                                            {renderUserList(task?.taskPriceList)}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {
                                                            task.productions ?
                                                            <Typography >
                                                                <Typography variant={'h4'} fontWeight="bold">
                                                                    {task.production.product ? task.production?.product?.name:task.production?.productTypePrice?.name}
                                                                </Typography>
                                                                <Typography variant={'h5'} fontWeight="ligth">
                                                                    (  {task.production.totalQuantity}
                                                                    {task.production.product ? task.production?.product?.measurement?.name:task.production?.productTypePrice?.product?.measurement?.name})
                                                                </Typography>
                                                            </Typography>:'----'
                                                        }

                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography>
                                                            <FormControl fullWidth>
                                                                <InputLabel
                                                                    id="demo-simple-select-label">Status</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    value={task?.taskStatus?.id}
                                                                    label="Status"
                                                                    onChange={(e) => changeTaskStatusContent(e, task)}
                                                                    name={'status'}
                                                                >
                                                                    {
                                                                        taskStatusReducer.taskStatus ?
                                                                            taskStatusReducer.taskStatus.map(item =>
                                                                                <MenuItem
                                                                                    className={'d-flex align-item-center'}
                                                                                    value={item.id}><CircleIcon style={{
                                                                                    color: item.color,
                                                                                    marginRight: '8px'
                                                                                }}/> {item.name}</MenuItem>
                                                                            ) : <MenuItem>---</MenuItem>
                                                                    }
                                                                </Select>
                                                            </FormControl>
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Typography noWrap>
                                                            <Tooltip title={t('View Task')} arrow>
                                                                <IconButton
                                                                    onClick={() => viewFunc(task.id)}
                                                                    color="primary"
                                                                >
                                                                    <LaunchTwoToneIcon fontSize="small"/>
                                                                </IconButton>
                                                            </Tooltip>
                                                            {
                                                                users.editTaskChecked ?
                                                                    task?.taskStatus?.orginalName === 'Completed' ? ''
                                                                        : <Tooltip title={t('Edit')} arrow>
                                                                            <IconButton color="primary">
                                                                                <EditTwoToneIcon
                                                                                    onClick={() => handleConfirmUpdateTask(task.id)}
                                                                                    fontSize="small"/>
                                                                            </IconButton>
                                                                        </Tooltip> : ''
                                                            }
                                                            {
                                                                users.addTaskChecked ?
                                                                    <Tooltip arrow title={t('Taskdan nusxa yaratish')}>
                                                                        <IconButton color="primary">
                                                                            <ControlPointDuplicateIcon
                                                                                onClick={() => saveTaskDuplicate(task.id)}
                                                                                fontSize="small"/>
                                                                        </IconButton>
                                                                    </Tooltip> : ''
                                                            }
                                                            {
                                                                users.deleteTaskChecked ?
                                                                    <Tooltip arrow title={t('Delete')}>
                                                                        <IconButton color="primary">
                                                                            <DeleteTwoToneIcon
                                                                                onClick={() => handleConfirmDeleteTask(task.id)}
                                                                                fontSize="small"/>
                                                                        </IconButton>
                                                                    </Tooltip> : ''
                                                            }
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            : <Typography
                                variant="h3"
                                textAlign={'center'}
                            >
                                {TaskReducer.message}
                            </Typography>}
                        <Box p={2} alignItems={'center'}>
                            <TablePagination
                                component="div"
                                count={TaskReducer.taskPage ? TaskReducer.taskPage.totalElements : 0}
                                onPageChange={handlePageChange}
                                onRowsPerPageChange={handleLimitChange}
                                page={page}
                                rowsPerPage={limit}
                                rowsPerPageOptions={[5, 10, 15]}
                            />
                        </Box>
                    </>

                </Card>
            )}
            {toggleView === 'grid_view' && (
                <Grid item xs={12}>
                    <TasksWrapperContent>
                        <DragDropContext onDragEnd={handleDragEnd}>
                            <TasksWrapper>
                                {taskStatusReducer.taskStatus ?
                                    taskStatusReducer.taskStatus.map((listId) => (
                                        <Tasks key={listId} listId={listId} tasks={taskPageList}
                                               deleteFunc={deleteStatus}
                                               editFunc={editStatus}
                                               showMore={showMore}
                                               onMore={onMore}
                                               onLess={onLess}
                                        />
                                    )) : <div>
                                        <h4 className={'text-center'}>{taskStatusReducer.message}</h4>
                                    </div>
                                }
                                {
                                    users.editTaskStatusChecked ?
                                        <Tooltip arrow title={t('Add a new lid Status')}>
                                            <CardAddAction>
                                                <CardActionArea sx={{px: 1}}>
                                                    <CardContent>
                                                        <AvatarAddWrapper>
                                                            <AddTwoToneIcon fontSize="large"
                                                                            onClick={handleTaskStatusOpen}/>
                                                        </AvatarAddWrapper>
                                                    </CardContent>
                                                </CardActionArea>
                                            </CardAddAction>
                                        </Tooltip> : ''
                                }

                            </TasksWrapper>
                        </DragDropContext>
                    </TasksWrapperContent>


                </Grid>
            )}
            {!toggleView && (
                <Card
                    sx={{
                        textAlign: 'center',
                        p: 3
                    }}
                >
                    <Typography
                        align="center"
                        variant="h4"
                        fontWeight="normal"
                        color="text.secondary"
                        sx={{
                            my: 5
                        }}
                        gutterBottom
                    >
                        {t(
                            'Choose between table or grid views for displaying the users list.'
                        )}
                    </Typography>
                </Card>
            )}


            <Dialog
                fullWidth
                maxWidth="md"
                open={open}
                onClose={handleCreateUserClose}
            >
                <DialogTitle
                    sx={{
                        p: 3
                    }}
                >
                    <Typography variant="h4" gutterBottom>
                        {t('Vazifa qo\'shish')}
                    </Typography>
                </DialogTitle>
                <Formik
                    initialValues={taskFormik}
                    validationSchema={Yup.object().shape({
                        name: Yup.string()
                            .max(255)
                            .required(t('Vazifa nomini Kiriting')),
                        description: Yup.string()
                            .max(255)
                            .required(t('Eslatmani kiriting')),
                        taskTypeId:Yup.string()
                            .required('Vazifa turini tanlang'),
                        importance:Yup.string()
                            .required('Required')
                    })}
                    onSubmit={async (
                        _values
                    ) => {
                        let a = []
                        userPayForm.map(value => {
                            let IdList = []
                            value?.userList.map(item => {
                                IdList.push(item.id)
                            })
                            a.push({
                                each: value.each,
                                price: value.paySum,
                                userList: IdList,
                                id: value.id ? value.id : null,
                            })
                        })

                        if (editTaskId) {
                            editTask({
                                ..._values, startDate, deadLine,file,
                                users: a,
                                id: editTaskId,
                                branchId: mainBranchId ? mainBranchId : users.branchId,
                                taskPriceDtos: a,
                                taskPrice: taskTotalPrice,
                                fileDataList: fileIds

                            })
                        } else {
                            saveTask({
                                ..._values, startDate, deadLine, file,
                                branchId: mainBranchId ? mainBranchId : users.branchId,
                                taskPriceDtos: a,
                                taskPrice: taskTotalPrice,
                                fileDataList: fileIds,
                                cost:addSum,
                                costEachOne:costEach,
                                costDtoList:costForm,
                                contentId:_values.productions ? _values.contentId : '',
                                goalAmount:goalAmounContent,
                                contentProductDtoList:_values.productions ? contentArray : []

                            })
                        }

                    }}

                >
                    {({
                          errors,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          touched,
                          values
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <DialogContent
                                dividers
                                sx={{
                                    p: 3
                                }}
                            >
                                <Grid container spacing={3}>
                                    <Grid item xs={12} lg={12}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    error={Boolean(touched.name && errors.name)}
                                                    fullWidth
                                                    helperText={touched.name && errors.name}
                                                    label={t('Sarlavha')}
                                                    name="name"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.name}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            {
                                                users.branches.length > 1 ?
                                                    <Grid item xs={12} md={6}>
                                                        <FormControl fullWidth>
                                                            <InputLabel
                                                                id="demo-simple-select-label">Filial</InputLabel>
                                                            <Select
                                                                error={Boolean(touched.branchId && errors.branchId)}
                                                                helperText={touched.branchId && errors.branchId}
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={values.branchId}
                                                                label="Filial"
                                                                onChange={(e) => {
                                                                    handleChange(e)
                                                                    changeSelectBranch(e)
                                                                }}
                                                                name={'branchId'}
                                                            >
                                                                {
                                                                    users.branches ?
                                                                        users.branches.map(item =>
                                                                            <MenuItem className={'mb-1 mt-1'}
                                                                                      value={item.id}>{item.name}</MenuItem>
                                                                        )
                                                                        : <MenuItem>---</MenuItem>
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    : ''
                                            }
                                            <Grid display={'flex'} item xs={12} md={6}>
                                                <Grid item xs={9} md={9}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">
                                                          Vazifa turi
                                                        </InputLabel>
                                                        <Select
                                                            error={Boolean(touched.taskTypeId && errors.taskTypeId)}
                                                            helperText={touched.taskTypeId && errors.taskTypeId}
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={values.taskTypeId}
                                                            label="Task Type"
                                                            onChange={handleChange}
                                                            name={'taskTypeId'}
                                                        >
                                                            {
                                                                taskTypeReducer.taskTypes ?
                                                                    taskTypeReducer.taskTypes.map(item =>
                                                                        <MenuItem value={item.id}>{item.name}</MenuItem>
                                                                    ) : <MenuItem value={''}>---</MenuItem>
                                                            }

                                                        </Select>
                                                        <Typography fontWeight={'700'} color={'error'} sx={{margin:'3px 7px'}}>
                                                            {
                                                                errors.taskTypeId && errors.taskTypeId
                                                            }
                                                        </Typography>
                                                    </FormControl>

                                                </Grid>
                                                <Grid item xs={3} md={3}>
                                                    <Button onClick={handleTaskTypeOpen} fullWidth
                                                            variant="contained">Qo'shish</Button>
                                                </Grid>

                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Loyiha</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={values.projectId}
                                                        label="Filial"
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            selectStage(e)
                                                            getTaskByProject(e.target.value)
                                                        }}
                                                        name={'projectId'}
                                                    >
                                                        {
                                                            ProjectReducer.project ?
                                                                ProjectReducer.project.map(item =>
                                                                    <MenuItem className={'mb-1 mt-1'}
                                                                              value={item.id}>{item.name}</MenuItem>
                                                                )
                                                                : <MenuItem>---</MenuItem>
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            {
                                                projectStages ?

                                                    <Grid item xs={12} md={6}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id="demo-simple-select-label">Loyiha
                                                                Bosqichi</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={values.stageId}
                                                                label="Loyiha bosqichi"
                                                                onChange={(e) => {
                                                                    handleChange(e)
                                                                }}
                                                                name={'stageId'}
                                                            >
                                                                {
                                                                    projectStages.map(item =>
                                                                        <MenuItem className={'mb-1 mt-1'}
                                                                                  value={item.id}>{item.name}</MenuItem>
                                                                    )
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </Grid> : ''
                                            }

                                            <Grid item xs={12} md={6}>
                                                {
                                                    TaskReducer.taskAll ?
                                                        <FormControl fullWidth>
                                                            <InputLabel id="demo-simple-select-label">Taskga
                                                                biriktirish</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={values.dependTask}
                                                                label="Filial"
                                                                onChange={(e) => {
                                                                    handleChange(e)
                                                                }}
                                                                name={'dependTask'}
                                                            >
                                                                {
                                                                    TaskReducer?.taskAll?.map(item =>
                                                                        <MenuItem className={'mb-1 mt-1'}
                                                                                  value={item.id}>{item.name}</MenuItem>
                                                                    )
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                        : ''
                                                }
                                            </Grid>
                                            <Grid item xs={12} md={6}>

                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Qiyinlik
                                                        darajasi</InputLabel>
                                                    <Select
                                                        error={Boolean(touched.importance && errors.importance)}
                                                        helperText={touched.importance && errors.importance}
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={values.importance}
                                                        label="Filial"
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                        }}
                                                        name={'importance'}
                                                    >
                                                        <MenuItem className={'mb-1 mt-1'}
                                                                  value={'HIGH'}>HIGH</MenuItem>
                                                        <MenuItem className={'mb-1 mt-1'}
                                                                  value={'MEDIUM'}>MEDIUM</MenuItem>
                                                        <MenuItem className={'mb-1 mt-1'}
                                                                  value={'LOW'}>LOW</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <DatePicker
                                                    label={'startDate'}
                                                    name={'startDate'}
                                                    inputFormat="dd/MM/yyyy"
                                                    value={startDate}
                                                    onChange={
                                                        (newValue) => {
                                                            getStartDate(newValue)
                                                        }
                                                    } renderInput={(params) => (
                                                    <TextField
                                                        fullWidth
                                                        format="dd/MM/yyyy"
                                                        placeholder={t('Select due date...')}
                                                        {...params}
                                                    />
                                                )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <DatePicker
                                                    inputFormat="dd/MM/yyyy"
                                                    label={'Deadline'}
                                                    name={'deadline'}
                                                    value={deadLine}
                                                    onChange={
                                                        (newValue) => setDeadLine(newValue)
                                                    } renderInput={(params) => (
                                                    <TextField
                                                        fullWidth
                                                        placeholder={t('Select due date...')}
                                                        {...params}
                                                    />
                                                )}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    error={Boolean(touched.description && errors.description)}
                                                    fullWidth
                                                    helperText={touched.description && errors.description}
                                                    label={t('Eslatma')}
                                                    name="description"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.description}
                                                    variant="outlined"
                                                />
                                            </Grid>

                                            <Grid display={'flex'} alignItems={'center'} item xs={12} md={12}>
                                                <Checkbox
                                                    onChange={(e)=>{
                                                        handleChange(e)
                                                        setContentArray(null)
                                                        setAddSum(0)
                                                        setBackTotalPrice(0)
                                                        setGoalAmountContent(0)
                                                        setTotalPrice(0)
                                                        setCostFrom([])
                                                    }}
                                                    checked={values.productions}
                                                    disabled={!!editTaskId}
                                                    name={'productions'}
                                                    sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                                />
                                                <Typography
                                                    variant="h3"
                                                >
                                                    {t('Production')}
                                                </Typography>

                                            </Grid>
                                            <Grid
                                                sx={{
                                                    mb: `${theme.spacing(3)}`,
                                                    display: 'flex',
                                                    flexWrap: 'wrap',
                                                }}
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                            >

                                                {
                                                    values.productions ?
                                                        contentReducer.content ?
                                                            <Grid
                                                                sx={{
                                                                    mb: `${theme.spacing(2)}`
                                                                }}
                                                                item
                                                                xs={12}
                                                                sm={8}
                                                                md={6}
                                                                lg={6}
                                                                display={'flex'}
                                                            >
                                                                <FormControl fullWidth>
                                                                    <InputLabel id="demo-simple-select-label">Tarkib
                                                                        Tanlash</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={values.contentId}
                                                                        disabled={!!editTaskId}
                                                                        label="Tarkib Tanlash"
                                                                        onChange={(e) => {
                                                                            handleChange(e)
                                                                            if (e.target.value === 'not'){
                                                                                setContentArray(null)
                                                                            }
                                                                            else{
                                                                                getContentById(e.target.value)
                                                                            }
                                                                        }
                                                                        }
                                                                        name={'contentId'}
                                                                    >
                                                                        <MenuItem value={'not'}>---</MenuItem>
                                                                        {
                                                                            contentReducer.content.map((item) =>
                                                                                <MenuItem
                                                                                    value={item.id}>{item.product ? item.product?.name
                                                                                    : item.productTypePrice?.name}</MenuItem>
                                                                            )
                                                                        }
                                                                    </Select>
                                                                </FormControl>
                                                                {/*{*/}
                                                                {/*    editTaskId ? '' : <Link to={'/headerthird/tarkib'}>*/}
                                                                {/*        <Button variant="contained"*/}
                                                                {/*                sx={{height: 'max-content'}}>*/}
                                                                {/*            <AddTwoToneIcon fontSize={'medium'}/>*/}
                                                                {/*        </Button>*/}
                                                                {/*    </Link>*/}
                                                                {/*}*/}

                                                            </Grid>
                                                            :
                                                            <Grid
                                                                sx={{
                                                                    mb: `${theme.spacing(3)}`
                                                                }}
                                                                item
                                                                xs={12}
                                                                sm={8}
                                                                md={6}
                                                                lg={4}
                                                            >
                                                                {/*<Link to={'/headerthird/tarkib'}>*/}
                                                                {/*    <Button variant="contained">*/}
                                                                {/*        <Typography> Tarkib*/}
                                                                {/*            Qo'shish</Typography>*/}
                                                                {/*        <AddTwoToneIcon fontSize={'medium'}/>*/}
                                                                {/*    </Button>*/}
                                                                {/*</Link>*/}
                                                                <Typography textAlign={'center'}>
                                                                    Tarkib yo'q
                                                                </Typography>
                                                            </Grid> : ''
                                                }
                                                {
                                                    values.productions ?
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                error={Boolean(touched.goalAmount && errors.goalAmount)}
                                                                fullWidth
                                                                helperText={touched.goalAmount && errors.goalAmount}
                                                                label={t('Miqdor kiriting')}
                                                                name="goalAmount"
                                                                onBlur={handleBlur}
                                                                disabled={!!editTaskId}
                                                                onChange={(e)=>{
                                                                    handleChange(e)
                                                                    setGoalAmountContent(e.target.value)
                                                                    changeOrder(e.target.value)
                                                                }
                                                                }
                                                                type="number"
                                                                value={goalAmounContent}
                                                                variant="outlined"
                                                            />
                                                        </Grid> : ''
                                                }
                                                {
                                                    values.productions ?
                                                        <Grid
                                                            sx={{
                                                                mb: `${theme.spacing(3)}`
                                                            }}
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            lg={12}
                                                        >
                                                            <TableContainer>
                                                                <Table>
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell>Nomi</TableCell>
                                                                            <TableCell>Ishlatilgan miqdor</TableCell>
                                                                            <TableCell>Jarayonda</TableCell>
                                                                            <TableCell>Narxi</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {contentArray ?
                                                                            contentArray.map((item, index) =>
                                                                                <TableRow hover key={item.id}>
                                                                                    <TableCell>
                                                                                        <Typography variant="h5">
                                                                                            {item.name}
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        <Typography display={'flex'}
                                                                                                    alignItems={'center'}
                                                                                                    variant="h5">
                                                                                            <TextField
                                                                                                margin={'dense'}
                                                                                                label={t('Miqdor')}
                                                                                                type={'number'}
                                                                                                onChange={(e) => {
                                                                                                    changeCount(e, index)
                                                                                                }}
                                                                                                disabled={!!editTaskId}
                                                                                                value={item.quantity}
                                                                                                variant="outlined"
                                                                                            />
                                                                                            <Typography variant={'h3'}>
                                                                                                {item.measurementName}

                                                                                            </Typography>
                                                                                        </Typography>

                                                                                        {
                                                                                            !item.byProduct ?
                                                                                            item.quantity > item.amount ?
                                                                                                <span
                                                                                                    className={'text-danger'}>Only {item.amount} available</span> : '':''
                                                                                        }
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        {
                                                                                            item.byProduct ?
                                                                                                'Mahsulot bolib chiqadi' :
                                                                                                'Xomashyo sifatida ishlatiladi'
                                                                                        }
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        <Typography variant="h5">
                                                                                            {item.totalPrice} UZB
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            ) : <Typography variant="h5">
                                                                                {taskTypeReducer.message}
                                                                            </Typography>
                                                                        }
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                            <Grid xs={12} mt={2} display={'flex'}  alignItems={'center'}
                                                                  justifyContent={'end'}>
                                                                <Typography variant={'h4'}>Jami  Summasi : {totalPrice} UZB</Typography>
                                                            </Grid>
                                                            <Grid item xs={12} md={12} marginTop={'5px'} >
                                                                <Typography variant={'h3'}>Xarajatlar</Typography>
                                                                <Grid item xs={12} md={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                                    <Grid  item xs={12} md={6}>
                                                                        {
                                                                            costForm.map((item,index)=>
                                                                                <Grid item xs={12} md={12} marginTop={'10px'} display={'flex'}  alignItems={'center'}
                                                                                      justifyContent={'space-between'}>
                                                                                    <TextField
                                                                                        fullWidth
                                                                                        label={"Qo'shimcha narx"}
                                                                                        name="sum"
                                                                                        onBlur={handleBlur}
                                                                                        disabled={!!editTaskId}
                                                                                        onChange={(e)=>{
                                                                                            changeAddSum(e,index)
                                                                                        }
                                                                                        }
                                                                                        type="number"
                                                                                        value={item.sum}
                                                                                        variant="outlined"
                                                                                    />
                                                                                    <FormControl fullWidth>
                                                                                        <InputLabel id="demo-simple-select-label">Xarajat</InputLabel>
                                                                                        <Select
                                                                                            labelId="demo-simple-select-label"
                                                                                            id="demo-simple-select"
                                                                                            value={item.costTypeName}
                                                                                            disabled={true}
                                                                                            label="Xarajat"
                                                                                            name={'outlayProduction'}
                                                                                        >
                                                                                            <MenuItem value={item.costTypeName}>{item.costTypeName}</MenuItem>
                                                                                        </Select>
                                                                                    </FormControl>
                                                                                </Grid>
                                                                            )
                                                                        }
                                                                    </Grid>
                                                                    <Grid item xs={12} md={6} display={'flex'} alignItems={'center'}>
                                                                        <Grid item xs={12} md={12} margin={'5px'} display={'flex'}  alignItems={'center'}
                                                                              justifyContent={'end'}>
                                                                            <Typography
                                                                                variant="h4"
                                                                            >
                                                                                Jami  Summasi : {addSum} So'm
                                                                            </Typography>
                                                                            <Typography
                                                                                variant="h4"
                                                                            >
                                                                                ({  costEach ?
                                                                                    'Har biriga' : 'Narxga'})
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid xs={12} mt={2} display={'flex'}  alignItems={'center'}
                                                                      justifyContent={'end'}>
                                                                    <Typography variant={'h4'}>Jami  Summasi : {backTotalPrice} UZB</Typography>
                                                                </Grid>

                                                            </Grid>
                                                        </Grid> : ''
                                                }
                                            </Grid>

                                            {
                                                userPayForm.map((item, index) =>
                                                    <Grid item xs={12} md={12} display={'flex'} alignItems={'center'}>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            md={4}
                                                        >
                                                            {
                                                                XodimReducer.xodimlar ?
                                                                    <Autocomplete
                                                                        multiple
                                                                        sx={{m: 0}}
                                                                        limitTags={2}
                                                                        name={'userList'}
                                                                        value={item.userList}
                                                                        onChange={(event, value) =>
                                                                            onChangeUser(value, index)
                                                                        }
                                                                        options={projectUser ? projectUser : XodimReducer.xodimlar}
                                                                        renderOption={(props, option) => (
                                                                            <li {...props}>
                                                                                <Avatar
                                                                                    sx={{
                                                                                        mr: 1
                                                                                    }}
                                                                                    src={option.avatar}
                                                                                />
                                                                                {option.firstName}
                                                                            </li>
                                                                        )}
                                                                        getOptionLabel={(option) => option.firstName}
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                label={"Xodimlar bo'yicha"}
                                                                                {...params}
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                InputLabelProps={{
                                                                                    shrink: true
                                                                                }}
                                                                                placeholder={t('Vazifa qatnashchilarini tanlash...')}
                                                                            />
                                                                        )}
                                                                        renderTags={(members, getTagProps) =>
                                                                            members.map((ev, index) => (
                                                                                <Chip
                                                                                    key={index}
                                                                                    label={ev.firstName}
                                                                                    {...getTagProps({index})}
                                                                                    // avatar={<Avatar src={ev.avatar} />}
                                                                                />
                                                                            ))
                                                                        }

                                                                    /> : <Typography
                                                                        variant="h3"
                                                                    >
                                                                        {XodimReducer.message}
                                                                    </Typography>
                                                            }

                                                        </Grid>
                                                        {
                                                            projectUser ? null : (<Grid
                                                                item
                                                                xs={12}
                                                                md={4}
                                                            >
                                                                {
                                                                    LavozimReducer.lavozimlar ?
                                                                        <Autocomplete
                                                                            multiple
                                                                            sx={{m: 0}}
                                                                            limitTags={2}
                                                                            name={'userListRole'}
                                                                            value={item.userListRole}
                                                                            onChange={(event, value) =>
                                                                                onChangeRoleUser(value, index)
                                                                            }
                                                                            options={[{name:"Hammani belgilash", id: "ALL"},...LavozimReducer.lavozimlar]}
                                                                            renderOption={(props, option) => (
                                                                                <li {...props}>
                                                                                    <Avatar
                                                                                        sx={{
                                                                                            mr: 1
                                                                                        }}
                                                                                        src={option?.avatar}
                                                                                    />
                                                                                    {option?.name}
                                                                                </li>
                                                                            )}
                                                                            getOptionLabel={(option) => option?.name}
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    label={"Lavozimlar bo'yicha"}
                                                                                    {...params}
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    InputLabelProps={{
                                                                                        shrink: true
                                                                                    }}
                                                                                    placeholder={t('Vazifa qatnashchilarini tanlash...')}
                                                                                />
                                                                            )}
                                                                            renderTags={(members, getTagProps) =>
                                                                                members.map((ev, index) => (
                                                                                    <Chip
                                                                                        key={index}
                                                                                        label={ev?.name}
                                                                                        {...getTagProps({index})}
                                                                                        // avatar={<Avatar src={ev.avatar} />}
                                                                                    />
                                                                                ))
                                                                            }

                                                                        /> : <Typography
                                                                            variant="h3"
                                                                        >
                                                                            {LavozimReducer.message}
                                                                        </Typography>
                                                                }

                                                            </Grid>
                                                            )
                                                        }
                                                        <Grid item xs={4} md={3}>
                                                            <TextField
                                                                fullWidth
                                                                label={t('Task price')}
                                                                name="taskPrice"
                                                                onChange={(e) => onChangeUserPay(e.target.value, index)}
                                                                type="number"
                                                                value={item.paySum}
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid display={'flex'} alignItems={'center'} item xs={4} md={3}>
                                                            <Checkbox
                                                                onChange={(e) => onChangeUserEach(e.target.checked, index)}
                                                                checked={item.each}
                                                                name={'each'}
                                                                sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                                            />
                                                            <Typography
                                                                variant="h3"
                                                            >
                                                                {t('Har biriga')}
                                                            </Typography>

                                                        </Grid>
                                                        <Grid item xs={2} md={2}>
                                                            <Button onClick={() => deleteUserPayForm(index)}
                                                                    variant="contained">
                                                                <DeleteTwoToneIcon fontSize={'medium'}/>
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            }
                                            <Grid item xs={12} md={12}>
                                                <FormControl fullWidth>
                                                    <Button variant="contained" onClick={addNewUserPayForm}>
                                                        <Typography>Form Qo'shish</Typography>
                                                        <AddTwoToneIcon fontSize={'medium'}/>
                                                    </Button>
                                                </FormControl>
                                            </Grid>
                                            <Grid xs={12} mt={2} display={'flex'} alignItems={'center'}
                                                  justifyContent={'space-around'}>
                                                <Typography variant={'h3'}>Jami Vazifa Summasi : </Typography>
                                                <Typography variant={'h2'}>{taskTotalPrice} UZB</Typography>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid mt={4}
                                      sx={{
                                          mb: `${theme.spacing(3)}`
                                      }}
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={12}
                                >
                                    <input type="file" id={'projectFiles'} value={''} className={'d-none'}
                                           onChange={(e) => {
                                               let form = new FormData()
                                               form.append('file', e.target.files[0])
                                               setFile(e.target.files[0])
                                               saveFiles(form)
                                           }
                                           }/>
                                    <label style={{width: '100%'}} htmlFor="projectFiles">
                                        <BoxUploadWrapper sx={{width: '100%'}}>
                                            <AvatarWrapper variant="rounded">
                                                <CloudUploadTwoToneIcon/>
                                            </AvatarWrapper>
                                            <Typography
                                                sx={{
                                                    mt: 2
                                                }}
                                            >
                                                {t('Upload files here')}
                                            </Typography>
                                        </BoxUploadWrapper>
                                    </label>
                                    {onFiles.length > 0 && (
                                        <>
                                            <Alert
                                                sx={{
                                                    py: 0,
                                                    mt: 2
                                                }}
                                                severity="success"
                                            >
                                                {t('You have uploaded')} <b>{onFiles.length}</b>{' '}
                                                {t('files')}!
                                            </Alert>
                                            <Divider
                                                sx={{
                                                    mt: 2
                                                }}
                                            />
                                            <List disablePadding component="div">
                                                {
                                                    onFiles.map((file, index) => (
                                                        <ListItem disableGutters component="div" key={index}>
                                                            <ListItemText
                                                                primary={file?.name ? file?.name : file?.fileName}/>
                                                            <b>{file?.size} bytes</b>
                                                            <Divider/>
                                                            <CancelPresentationIcon onClick={() => {
                                                                let a = onFiles?.filter((item, ind) => ind !== index)
                                                                let c = fileIds?.filter((item, ind) => ind === index)
                                                                let b = fileIds?.filter((item, ind) => ind !== index)
                                                                deleteFiles(c[0])
                                                                setOnFiles(a)
                                                                setFileIds(b)
                                                            }} sx={{marginLeft: '10px'}} color={'error'}/>
                                                        </ListItem>
                                                    ))
                                                }
                                            </List>
                                        </>
                                    )}
                                </Grid>

                            </DialogContent>
                            <DialogActions
                                sx={{
                                    p: 3
                                }}
                            >
                                <Button color="secondary" onClick={handleCreateUserClose}>
                                    {t('Cancel')}
                                </Button>
                                <Button
                                    type="submit"
                                    startIcon={
                                        isSubmitting ? <CircularProgress size="1rem"/> : null
                                    }
                                    disabled={Boolean(errors.submit) || isSubmitting}
                                    variant="contained"
                                >
                                    {
                                        editTaskId ?
                                            'Edit Task' : 'Add New Task'
                                    }
                                </Button>
                            </DialogActions>
                        </form>
                    )}
                </Formik>
            </Dialog>
            <Dialog
                fullWidth
                maxWidth="md"
                open={openStatus}
                onClose={handleTaskStatusClose}
            >
                <DialogTitle
                    sx={{
                        p: 3
                    }}
                >
                    <Typography variant="h4" gutterBottom>
                        {t('Yangi status qo\'shish')}
                    </Typography>
                </DialogTitle>
                <Formik
                    initialValues={statusFormik}
                    validationSchema={Yup.object().shape({
                        name: Yup.string()
                            .max(255)
                            .required(t('The Column Name field is required')),
                        color: Yup.string()
                            .max(255)
                            .required(t('The Color field is required'))
                    })}
                    onSubmit={async (
                        _values
                    ) => {
                        if (editID) {
                            editTaskStatus(
                                {
                                    ..._values,
                                    id: editID,
                                    branchId: mainBranchId ? mainBranchId : users.branchId
                                }
                            )
                        } else {
                            saveTaskStatus(
                                {
                                    ..._values,
                                    branchId: mainBranchId ? mainBranchId : users.branchId
                                }
                            )
                        }

                    }}
                >
                    {({
                          errors,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          touched,
                          values
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <DialogContent
                                dividers
                                sx={{
                                    p: 3
                                }}
                            >
                                <Grid container spacing={3}>
                                    <Grid item xs={12} lg={editID ? 12 : 6}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    error={Boolean(touched.name && errors.name)}
                                                    fullWidth
                                                    helperText={touched.name && errors.name}
                                                    label={t('Column Name')}
                                                    name="name"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.name}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {
                                        editID ?
                                            <Grid item xs={12} lg={6}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} lg={6}>
                                                        <FormControl fullWidth>
                                                            <InputLabel
                                                                id="demo-simple-select-label">Number</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={values.rowNumber}
                                                                label="Number"
                                                                onChange={handleChange}
                                                                name={'rowNumber'}
                                                            >
                                                                {
                                                                    taskStatusReducer.taskStatus ?
                                                                        taskStatusReducer.taskStatus.map((item, index) =>
                                                                            <MenuItem
                                                                                value={index + 1}>{index + 1}</MenuItem>
                                                                        ) : ''
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </Grid> </Grid>
                                            </Grid>
                                            : ''
                                    }
                                    <Grid item xs={12} lg={6}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={10}>
                                                <TextField
                                                    error={Boolean(touched.color && errors.color)}
                                                    fullWidth
                                                    helperText={touched.color && errors.color}
                                                    label={t('Color')}
                                                    name="color"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.color}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <TextField
                                                    error={Boolean(touched.color && errors.color)}
                                                    fullWidth
                                                    helperText={touched.color && errors.color}
                                                    name="color"
                                                    label={t('Color')}
                                                    type={'color'}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.color}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </DialogContent>
                            <DialogActions
                                sx={{
                                    p: 3
                                }}
                            >
                                <Button color="secondary" onClick={handleTaskStatusClose}>
                                    {t('Cancel')}
                                </Button>
                                <Button
                                    type="submit"
                                    startIcon={
                                        isSubmitting ? <CircularProgress size="1rem"/> : null
                                    }
                                    disabled={Boolean(errors.submit) || isSubmitting}
                                    variant="contained"
                                >
                                    {
                                        editID ? 'Edit status'
                                            : 'Add new status'

                                    }
                                </Button>
                            </DialogActions>
                        </form>
                    )}
                </Formik>
            </Dialog>

            <Dialog
                fullWidth
                maxWidth="md"
                open={openType}
                onClose={handleTaskTypeClose}
            >
                <DialogTitle
                    sx={{
                        p: 3
                    }}
                >
                    <Typography variant="h4" gutterBottom>
                        {t('Vazifa turi')}
                    </Typography>
                </DialogTitle>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Tr</TableCell>
                                <TableCell>Nomi</TableCell>
                                <TableCell>Delete</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {taskTypeReducer.taskTypes ?
                                taskTypeReducer.taskTypes.map((item, index) =>
                                    <TableRow hover key={item.id}>
                                        <TableCell>
                                            <Typography variant="h5">
                                                {index + 1}
                                            </Typography></TableCell>
                                        <TableCell>
                                            <Typography variant="h5">
                                                {item.name}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography noWrap>
                                                <Tooltip title={t('Delete')} arrow>
                                                    <IconButton
                                                        onClick={() => handleConfirmDelete(item.id)}
                                                        color="primary"
                                                    >
                                                        <DeleteTwoToneIcon fontSize="small"/>
                                                    </IconButton>
                                                </Tooltip>
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ) : <Typography variant="h5">
                                    {taskTypeReducer.message}
                                </Typography>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box p={2}>
                    <TablePagination
                        component="div"
                        count={20}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleLimitChange}
                        page={page}
                        rowsPerPage={limit}
                        rowsPerPageOptions={[5, 10, 15]}
                    />
                </Box>
                <Formik
                    initialValues={{
                        name: '',
                        branchId: ''
                    }}
                    validationSchema={Yup.object().shape({
                        name: Yup.string()
                            .max(255)
                            .required(t('The Column Name field is required')),
                    })}
                    onSubmit={async (
                        _values
                    ) => {
                        saveTaskType(
                            {
                                ..._values,
                                branchId: mainBranchId ? mainBranchId : users.branchId
                            }
                        )
                    }}
                >
                    {({
                          errors,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          touched,
                          values
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <DialogContent
                                dividers
                                sx={{
                                    p: 3
                                }}
                            >
                                <Grid container spacing={3}>
                                    <Grid item xs={12} lg={12}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    error={Boolean(touched.name && errors.name)}
                                                    fullWidth
                                                    helperText={touched.name && errors.name}
                                                    label={t('Vazifa turi')}
                                                    name="name"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.name}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions
                                sx={{
                                    p: 3
                                }}
                            >
                                <Button color="secondary" onClick={handleTaskTypeClose}>
                                    {t('Cancel')}
                                </Button>
                                <Button
                                    type="submit"
                                    startIcon={
                                        isSubmitting ? <CircularProgress size="1rem"/> : null
                                    }
                                    disabled={Boolean(errors.submit) || isSubmitting}
                                    variant="contained"
                                >
                                    Saqlash
                                </Button>
                            </DialogActions>
                        </form>
                    )}
                </Formik>
            </Dialog>
            <Dialog
                open={openConfirmDelete}
                maxWidth="sm"
                fullWidth
                TransitionComponent={Transition}
                onClose={closeConfirmDelete}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    p={5}
                >
                    <AvatarError>
                        <CloseIcon/>
                    </AvatarError>

                    <Typography
                        align="center"
                        sx={{
                            py: 4,
                            px: 6
                        }}
                        variant="h3"
                    >
                        {t('Are you sure you want to delete this task type')}
                        ?
                    </Typography>

                    <Box>
                        <Button
                            variant="text"
                            size="large"
                            sx={{
                                mx: 1
                            }}
                            onClick={closeConfirmDelete}
                        >
                            {t('Cancel')}
                        </Button>
                        <ButtonError
                            onClick={handleDeleteCompleted}
                            size="large"
                            sx={{
                                mx: 1,
                                px: 3
                            }}
                            variant="contained"
                        >
                            {t('Delete')}
                        </ButtonError>
                    </Box>
                </Box>
            </Dialog>
            <Dialog
                open={openConfirmDeleteTask}
                maxWidth="sm"
                fullWidth
                TransitionComponent={Transition}
                onClose={closeConfirmDeleteTask}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    p={5}
                >
                    <AvatarError>
                        <CloseIcon/>
                    </AvatarError>

                    <Typography
                        align="center"
                        sx={{
                            py: 4,
                            px: 6
                        }}
                        variant="h3"
                    >
                        {t('Are you sure you want to delete this task ')}
                        ?
                    </Typography>

                    <Box>
                        <Button
                            variant="text"
                            size="large"
                            sx={{
                                mx: 1
                            }}
                            onClick={closeConfirmDeleteTask}
                        >
                            {t('Cancel')}
                        </Button>
                        <ButtonError
                            onClick={handleDeleteCompletedTask}
                            size="large"
                            sx={{
                                mx: 1,
                                px: 3
                            }}
                            variant="contained"
                        >
                            {t('Delete')}
                        </ButtonError>
                    </Box>
                </Box>
            </Dialog>
            <Dialog
                fullWidth
                maxWidth="md"
                open={productionModal}
                onClose={() => setProductionModal(false)}
            >
                <DialogTitle
                    sx={{
                        p: 3
                    }}
                >
                    <Typography variant="h4" gutterBottom>
                        {t('Ishlab chiqarish')}
                    </Typography>
                </DialogTitle>
                <Formik
                    initialValues={contentFormik}
                    validationSchema={Yup.object().shape({
                        totalQuantity: Yup.string()
                            .max(255)
                            .required(t('The Miqdor field is required')),
                        invalid: Yup.string()
                            .max(255)
                            .required(t('The Miqdor field is required')),
                    })}
                    onSubmit={async (
                        _values
                    ) => {
                        taskProduction({
                            ..._values,
                            lossContentProductDtoList:arr2,
                        })
                    }}
                >
                    {({
                          errors,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          touched,
                          values
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <DialogContent
                                dividers
                                sx={{
                                    p: 3
                                }}
                            >
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <TextField
                                            error={Boolean(touched.totalQuantity && errors.totalQuantity)}
                                            fullWidth
                                            helperText={touched.totalQuantity && errors.totalQuantity}
                                            label={t('Ishlab chiqarish miqdori')}
                                            name="totalQuantity"
                                            disabled={true}
                                            value={values.totalQuantity}
                                            variant="outlined"
                                        />

                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>

                                        <TextField
                                            error={Boolean(touched.invalid && errors.invalid)}
                                            fullWidth
                                            helperText={touched.invalid && errors.invalid}
                                            label={t("Yo'qotilgan miqdor")}
                                            name="invalid"
                                            onBlur={handleBlur}
                                            onChange={
                                                (e)=>{
                                                    handleChange(e)
                                                    if(e.target.value > 0){

                                                    }
                                                    else{
                                                        setarr2([])
                                                    }
                                                }
                                        }
                                            value={values.invalid}
                                            variant="outlined"
                                        />

                                    </Grid>
                                    <Grid item display={'flex'} justifyContent={'center'} xs={12} lg={12}>
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Nomi</TableCell>
                                                        <TableCell>Ishlatilgan miqdor</TableCell>
                                                        <TableCell>Jarayonda</TableCell>
                                                        <TableCell>Narxi</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {contentArray ?
                                                        contentArray.map((item, index) =>
                                                            <TableRow hover key={item.id}>
                                                                <TableCell>
                                                                    <Typography variant="h5">
                                                                        {item.name}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography display={'flex'} alignItems={'center'}
                                                                                variant="h5">
                                                                        <TextField
                                                                            margin={'dense'}
                                                                            label={t('Miqdor')}
                                                                            type={'number'}
                                                                            disabled={true}
                                                                            value={item.quantity}
                                                                            variant="outlined"
                                                                        />
                                                                        <Typography variant={'h3'}>
                                                                            {item.measurementName}

                                                                        </Typography>
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    {
                                                                        item.byProduct ?
                                                                            'Mahsulot bolib chiqadi' :
                                                                            'Xomashyo sifatida ishlatiladi'
                                                                    }
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography variant="h5">
                                                                        {item.totalPrice} UZB
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        ) : <Typography variant="h5">
                                                            {taskTypeReducer.message}
                                                        </Typography>
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>

                                    <Grid xs={12} mt={2} display={'flex'}  alignItems={'center'}
                                          justifyContent={'end'}>
                                        <Typography variant={'h4'}>Jami  Summasi : {totalPrice} UZB</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={12} marginTop={'5px'} >
                                        <Typography variant={'h3'}>Xarajatlar</Typography>
                                        <Grid item xs={12} md={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                            <Grid  item xs={12} md={6}>
                                                {
                                                    costForm.map((item,index)=>
                                                        <Grid item xs={12} md={12} marginTop={'10px'} display={'flex'}  alignItems={'center'}
                                                              justifyContent={'space-between'}>
                                                            <TextField
                                                                fullWidth
                                                                label={"Qo'shimcha narx"}
                                                                name="sum"
                                                                onBlur={handleBlur}
                                                                disabled={true}
                                                                type="number"
                                                                value={item.sum}
                                                                variant="outlined"
                                                            />
                                                            <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label">Xarajat</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    value={item.costTypeName}
                                                                    disabled={true}
                                                                    label="Xarajat"
                                                                    name={'outlayProduction'}
                                                                >
                                                                    <MenuItem value={item.costTypeName}>{item.costTypeName}</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                    )
                                                }
                                            </Grid>
                                            <Grid item xs={12} md={6} display={'flex'} alignItems={'center'}>
                                                <Grid item xs={12} md={12} margin={'5px'} display={'flex'}  alignItems={'center'}
                                                      justifyContent={'end'}>
                                                    <Typography
                                                        variant="h4"
                                                    >
                                                        Jami  Summasi : {addSum} So'm
                                                    </Typography>
                                                    <Typography
                                                        variant="h4"
                                                    >
                                                        ({  costEach ?
                                                        'Har biriga' : 'Narxga'})
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid xs={12} mt={2} display={'flex'}  alignItems={'center'}
                                              justifyContent={'end'}>
                                            <Typography variant={'h4'}>Jami  Summasi : {backTotalPrice} UZB</Typography>
                                        </Grid>

                                    </Grid>

                                    {
                                        values.invalid > 0 ?
                                            <Grid item xs={12} lg={12}>
                                                <Box p={2}>
                                                    <div className="col-md-12 position-relative p-0">
                                                        <input type="text"
                                                               value={searchContent}
                                                               onChange={onChangeSearch}
                                                               className={'form-control p-2 po'}
                                                               style={{width: '100%'}}
                                                               placeholder={'Mahsulot shtrix kodi / nomi'}/>

                                                        {
                                                            ComboArray.length > 0 ? <div className={'Combo-array  position-absolute z-index'}>
                                                                {
                                                                    ComboArray.map(item =>
                                                                        <p onClick={() => pushesh(item)}>
                                                                            {item.name}
                                                                        </p>
                                                                    )

                                                                }
                                                            </div> : ''
                                                        }

                                                    </div>
                                                </Box>
                                                <TableContainer>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Nomi</TableCell>
                                                                <TableCell>Ishlatilgan miqdor</TableCell>
                                                                <TableCell>Jarayonda</TableCell>
                                                                <TableCell>Narxi</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {arr2 ?
                                                                arr2.map((item, index) =>
                                                                    <TableRow hover key={item.id}>
                                                                        <TableCell>
                                                                            <Typography variant="h5">
                                                                                {item.name}
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Typography display={'flex'} alignItems={'center'}
                                                                                        variant="h5">
                                                                                <TextField
                                                                                    margin={'dense'}
                                                                                    label={t('Miqdor')}
                                                                                    type={'number'}
                                                                                    onChange={(e)=>changeCountNumber(e,index)}
                                                                                    value={item.quantity}
                                                                                    variant="outlined"
                                                                                />
                                                                                <Typography variant={'h3'}>
                                                                                    {item.measurementName}

                                                                                </Typography>
                                                                            </Typography>

                                                                            {
                                                                                !item.byProduct ?
                                                                                    item.quantity > item.amount ?
                                                                                        <span
                                                                                            className={'text-danger'}>Only {item.amount} available</span> : '':''
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {
                                                                                item.byProduct ?
                                                                                    'Mahsulot bolib chiqadi' :
                                                                                    'Xomashyo sifatida ishlatiladi'
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Typography variant="h5">
                                                                                {item.totalPrice} UZB
                                                                            </Typography>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ) : <Typography variant="h5">
                                                                    {taskTypeReducer.message}
                                                                </Typography>
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Grid>
                                            :''
                                    }
                                </Grid>
                            </DialogContent>
                            <DialogActions
                                sx={{
                                    p: 3
                                }}
                            >
                                <Button color="secondary" onClick={cancelProduction}>
                                    {t('Cancel')}
                                </Button>
                                <Button
                                    type="submit"
                                    startIcon={
                                        isSubmitting ? <CircularProgress size="1rem"/> : null
                                    }
                                    disabled={Boolean(errors.submit) || isSubmitting}
                                    variant="contained"
                                >
                                    Saqlash
                                </Button>
                            </DialogActions>
                        </form>
                    )}
                </Formik>
            </Dialog>

            <Modal size={'xl'} isOpen={viewTask} toggle={viewTaskToggle}>
                <ModalHeader>
                    <h5>VIEW TASK</h5>
                </ModalHeader>
                <ModalBody>
                    {
                        TaskReducer.taskOne ?
                            <div>
                                <div className={'table-responsive'}>
                                    <table className={'table table-striped'}>
                                        <thead>
                                        <tr>
                                            <th>Nomi</th>
                                            <th>Xodim</th>
                                            <th>Boshlash vaqti</th>
                                            <th>Tugash vaqti</th>
                                            <th>DeadLine</th>
                                            <th>Loyiha</th>
                                            {/*<th>Bosqich</th>*/}
                                            {/*<th>Status</th>*/}
                                            <th>Task narxi</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>{TaskReducer.taskOne.name}</td>
                                            <td>
                                                <Typography display={'flex'}>
                                                    {
                                                        renderUserList(TaskReducer.taskOne?.taskPriceList)
                                                    }
                                                    {/*{TaskReducer.taskOne?.users.length > 0 && (*/}
                                                    {/*    <AvatarGroup max={4}>*/}
                                                    {/*        {TaskReducer.taskOne.users.map((member) => (*/}
                                                    {/*            <Tooltip arrow*/}
                                                    {/*                     placement="top"*/}
                                                    {/*                     key={member?.firstName}*/}
                                                    {/*                     title={member?.firstName + " " + member?.lastName}*/}
                                                    {/*            >*/}
                                                    {/*                <Avatar*/}
                                                    {/*                    sx={{*/}
                                                    {/*                        width: 30,*/}
                                                    {/*                        height: 30*/}
                                                    {/*                    }}*/}
                                                    {/*                    key={member?.id}*/}
                                                    {/*                    src={member?.photo?.id ? `${BaseUrl}/attachment/download/${member?.photo?.id}` : person}*/}
                                                    {/*                />*/}
                                                    {/*            </Tooltip>*/}
                                                    {/*        ))}*/}
                                                    {/*    </AvatarGroup>*/}
                                                    {/*)}*/}
                                                </Typography>
                                            </td>
                                            <td>{formatDate(TaskReducer.taskOne.startDate)}</td>
                                            <td>{TaskReducer.taskOne.endDate ? formatDate(TaskReducer.taskOne.endDate) :
                                                <span style={{color: 'green'}}>YAKUNLANMADI</span>}</td>
                                            <td>{formatDate(TaskReducer.taskOne.deadLine)}</td>
                                            <td>{TaskReducer.taskOne?.projectName}</td>
                                            {/*<td>{TaskReducer.taskOne?.stage?.name}</td>*/}
                                            {/*<td>{TaskReducer.taskOne?.taskStatus?.name}</td>*/}
                                            <td>{TaskReducer.taskOne.taskPrice}</td>
                                        </tr>
                                        </tbody>
                                    </table>

                                </div>
                                <div className={'mt-2 text-center'}>
                                    <h4>Vazifa Tasnifi</h4>
                                    <p>{TaskReducer.taskOne.description}</p>
                                </div>
                                <div className={'mt-2 text-center'}>
                                    <h3>Vazifaga biriktirilgan faylar</h3>
                                    <div className={'d-flex justify-content-center'}>
                                        <AvatarGroup max={4}>
                                            {TaskReducer.taskOne.fileDataIdList.map((member) => (
                                                <div
                                                    style={{backgroundColor: 'transparent'}}>
                                                    <Tooltip
                                                        arrow
                                                        placement="top"
                                                        key={member?.firstName}
                                                        title={member?.fileName}
                                                        sx={{margin: '0'}}
                                                    >
                                                        <Avatar
                                                            sx={{
                                                                width: 50,
                                                                height: 50,
                                                            }}
                                                            key={member?.id}
                                                            src={filePicture}
                                                        />
                                                    </Tooltip>
                                                    <Button color={'success'} size={'small'}
                                                            onClick={() => getFilesById(member.id)}>Download</Button>
                                                </div>
                                            ))}
                                        </AvatarGroup>

                                    </div>

                                </div>
                            </div> : ''
                    }


                    {fileUrl ?
                        <embed src={fileUrl} onUnload={handleEmbedUnload} type="application/pdf" width="100%"
                               height="0"/> : ''
                    }
                </ModalBody>
                <ModalFooter>
                    <button onClick={viewTaskToggle} className={'btn btn-primary'}>Chiqish</button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default connect((taskStatusReducer, XodimReducer, LavozimReducer, users, taskTypeReducer, ProjectReducer, TaskReducer, contentReducer, filesReducer,XaridReducer), {
    saveTaskStatus,
    getTaskStatus,
    deleteTaskStatus,
    editTaskStatus,
    saveTaskType,
    getTaskType,
    deleteTaskType,
    getProject,
    getTask,
    deleteFiles,
    clearFiles,
    saveTask, saveFiles,
    getXodimByBranch,
    getLavozim,
    deleteTask,
    getTaskPage,
    editTask,
    changeTaskStatus,
    changeTaskStatusId,
    getContent,
    getContentById,
    taskProduction,
    getTaskAllByBranch,
    getOneTask,
    getTaskByProject,
    getTaskByName,
    getXaridProductType,
    saveTaskDuplicate,
})(Results);
