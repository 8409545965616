import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../../../../../api";
import {toast} from "react-toastify";

const slice = createSlice({
    name: 'Test',
    initialState: {
        test: null,
        current: false,
        addRole: false,
        message: '',
        getBoolean: false,
        saveRoleBool: false,
    },
    reducers: {
        getFrom: (state, action) => {
            if (action.payload.success) {
                state.test = action.payload.object
            } else {
                state.test = null
                state.message = action.payload.message
            }
            state.getBoolean = !state.getBoolean
            state.saveRoleBool = false
        },
        savefrom: (state, action) => {
            if (action.payload.success) {
                toast.success('Test qo`shildi')
                state.addRole = true
                state.saveRoleBool = true

            } else {
                toast.warning(action.payload.message)
                state.addRole = false
            }
            state.current = !state.current

        },
        deletefrom: (state, action) => {
            if (action.payload.success) {
                toast.success('Test o\'chirildi')
            } else {
                toast.warning(action.payload.message)
            }
            state.current = !state.current

        }
    }
});

export const getViewByTest = (data) => apiCall({
    url: '/test/all-by-lesson/' + data,
    method: 'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type,
});

export const saveTest = (data) => apiCall({
    url: '/test/' + data.lessonId,
    method: 'post',
    data: data.attachmentId,
    onSuccess: slice.actions.savefrom.type,
    onFail: slice.actions.savefrom.type
});

export const deleteTest = (data) => apiCall({
    url: '/test/' + data,
    method: 'delete',
    onSuccess: slice.actions.deletefrom.type,
    onFail: slice.actions.deletefrom.type
})
export const deleteTestAll = (data) => apiCall({
    url: '/test',
    method: 'delete',
    data,
    onSuccess: slice.actions.deletefrom.type,
    onFail: slice.actions.deletefrom.type
})

export default slice.reducer