import {useEffect, useState} from "react";
import img from '../../../../../img/Jami2.svg'
import './mahsulotimporti.css'
import {connect} from "react-redux";
import branchreducer,{getbranch} from "../../../../../reducer/branchreducer";
import users from "../../../../../reducer/users";
import MaxsulotlarRoyxariReducer,{saveProductByExcelFile} from "../reducer/MaxsulotlarRoyxariReducer";
import Example from "../../../../../file/example-excel.xlsx"
import ModalLoading from "../../../../ModalLoading";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

function MahsulotImport({getbranch,branchreducer,users,saveProductByExcelFile,MaxsulotlarRoyxariReducer}) {


    const {t} = useTranslation()


    const [branch,setbranch] = useState('')
    const [saveBoolean,setSaveBoolean] = useState(false)
    const [file,setfile] = useState(null)
    const [fileName,setFilename] = useState('')
    const [fileSize,setFileSize] = useState('')

    useEffect(()=>{
        getbranch(users.businessId)
    },[])

    function importfile(e){
        setfile(e.target.files[0])
        setFilename(e.target.files[0].name)
        setFileSize(e.target.files[0].size)
    }


    useEffect(()=>{
        if (saveBoolean){
            setfile(null)
            setFilename('')
            setFileSize('')
            setSaveBoolean(false)
        }
    },[MaxsulotlarRoyxariReducer.current])

    function saveProductByExcel(){
        if (file){
            if (branch){
                const formData =  new FormData();
                formData.append('file',file)
                saveProductByExcelFile({
                    file:formData,
                    params:{
                        branchId:branch
                    }
                })
                setSaveBoolean(true)
            }else{
                toast.warning('Filial Tanlang')
            }
        }else{
            toast.warning('Excel fayl tanlang')
        }

    }





    return <div className={'col-md-12 mt-2 pt-4 pb-4'}>
        <div className={'col-md-12 rowStyleMIG '}>
            <div className={'mt-2 d-flex justify-content-around align-items-center'}>
                <div >
                    <label className={'choose'} htmlFor="choose">{t('pro.importq')}</label>
                    <input  onChange={importfile} hidden={'1'} value={''}  id={'choose'} className={'mt-4'} type="file"/>
                </div>
                <div>
                    <strong>{fileName}   ({parseFloat(fileSize/1024).toFixed(2)}.KB)  </strong>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3">
                    <select value={branch} className={'form-control'} onChange={(e)=>setbranch(e.target.value === 'all'?null:e.target.value)}>
                        <option value="all">{t('pro.filialt')}</option>
                        {
                            branchreducer.branch ?
                            branchreducer.branch.map(item=>
                                <option value={item.id}>{item.name}</option>):''
                        }
                    </select>
                </div>
                {/*<div className="col-md-3">*/}
                {/*    <select value={measurement} onChange={(e)=>setMeasurement(e.target.value)} className={'form-control'}>*/}
                {/*        <option value="All">{t('pro.ulchovb')}</option>*/}
                {/*        {*/}
                {/*            kgreducer.kg.map(item=>*/}
                {/*                <option value={item.id}>{item.name}</option>)*/}
                {/*        }*/}
                {/*    </select>*/}
                {/*</div>*/}
                {/*<div className="col-md-3">*/}
                {/*    <select value={bolim} onChange={(e)=>setBolim(e.target.value)} className={'form-control'}>*/}
                {/*        <option value="All">{t('pto.bulimt')}</option>*/}
                {/*        {*/}
                {/*            BolimReducer.bolimlar?*/}
                {/*            BolimReducer.bolimlar.map(item=>*/}
                {/*                <option value={item.id}>{item.name}</option>):''*/}
                {/*        }*/}
                {/*    </select>*/}
                {/*</div>*/}
                {/*<div className="col-md-3">*/}
                {/*    <select value={brand} onChange={(e)=>setBrand(e.target.value)} className={'form-control'}>*/}
                {/*        <option value="All">{t('pro.firmat')}</option>*/}
                {/*        {*/}
                {/*            FirmaReducer.firmalar?.map(item=>*/}
                {/*                <option value={item.id}>{item.name}</option>)*/}
                {/*        }*/}
                {/*    </select>*/}
                {/*</div>*/}
            </div>

            <span className={'shablon'}>
                <a href={Example} download> <button className={'btn btn-outline-primary'}><img src={img} alt=""/> {t('pro.tayyorsh')}</button></a>

                    <button onClick={saveProductByExcel} className={'btn btn-outline-primary'}>{t('Buttons.6')}</button>
                </span>
        </div>
        <ModalLoading isOpen={saveBoolean} toggle={()=>setSaveBoolean(prev=>!prev)}/>
    </div>
}

export default connect((branchreducer,users,MaxsulotlarRoyxariReducer),
    {getbranch,saveProductByExcelFile}) (MahsulotImport)
