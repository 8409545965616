import {lazy, useEffect, useState} from "react";
import {Route, Switch, Redirect, useLocation, useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import users,{saveusers} from "./reducer/users"
import './App.css'
// import Handle from "./components/SotibOlish/Sidebar/Lidlar/LidForms/Handle";
import Sidebar from './components/SotibOlish/Sidebar'
import {useTranslation} from "react-i18next";
const Home = lazy(() => import("./components/Hbody/Home"))
const SavdoOynasi = lazy(() => import("./components/SotibOlish/Sidebar/Savdo/SavdoOynasi/SavdoOynasi"))
const Error500 = lazy(() => import("./dashboard/jsx/pages/Error500"))


function App({users,saveusers}) {
    const {i18n} = useTranslation();
    const [auth,setAuth] = useState(false)
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const history = useHistory()
    const  location = useLocation();

    useEffect(()=>{
        setAuth(users.authEnter)
        let navLink= localStorage.getItem('navLik') || sessionStorage.getItem('navLik')
        if(location.pathname === '/main/dashboard'|| location.pathname === '/login' || location.pathname === '/'){
            if (location.pathname === '/login'){
              history.push('/main/dashboard')
            }
            else{
                if (users.rememberme){
                    localStorage.setItem("navLik",'')
                }
                else{
                    sessionStorage.setItem('navLik','')
                }
            }
            }
        else {
            if (!users.logout){
                if (users.rememberme){
                    localStorage.setItem("navLik",location.pathname)
                }
                else{
                    sessionStorage.setItem('navLik',location.pathname)
                }
            } else {
                if (users.rememberme){
                    localStorage.setItem("navLik","/main/dashboard")
                }
                else{
                    sessionStorage.setItem('navLik',"/main/dashboard")
                }
            }

        }
        users.viewDashboardChecked ? navLink && history.push(navLink) : navLink === "/main/dashboard" ? history.push("/main/profil") : navLink && history.push(navLink)
        const handleStatusChange = () => {
            setIsOnline(navigator.onLine);
            toast.info(navigator.onLine ? 'Become Online':'Become Offline')
        };
        window.addEventListener('online', handleStatusChange);
        window.addEventListener('offline', handleStatusChange);
        return () => {
            window.removeEventListener('online', handleStatusChange);
            window.removeEventListener('offline', handleStatusChange);
        };
    },[users.authEnter, isOnline])


    useEffect(() => {
        const storageLanguage = localStorage.getItem("appLang")
        i18n.changeLanguage(storageLanguage)
        let user = JSON.parse(localStorage.getItem('user') || sessionStorage.getItem('user'))
        let tokenname = localStorage.getItem('tokenname') || sessionStorage.getItem('tokenname')
        if (user && tokenname){
            saveusers({
                object:user,
                message: tokenname,
                success: true
            })
            history.push( '/main/dashboard')
        }
    }, [])

    return (
        <div>
            <div>
                <Switch>
                    <Route path={'/login'} component={Home}/>
                    <Route path={'/success'} component={Error500}/>
                    {
                        auth ? <Route path={'/'}>
                                <Route path={'/main'} component={Sidebar}/>
                                {/*<Route path={'/sidebar'} component={Sidebar}/>*/}
                            <Route path={'/turliTavar/:id'} exact component={SavdoOynasi}/>
                            <Route path={'/mahsulotQaytarish/:id/:remainId'} exact component={SavdoOynasi}/>
                            <Route path={'/shopping'} exact component={SavdoOynasi}/>
                        </Route> :<Redirect to={'/login'}/>
                    }
                </Switch>
            </div>
        </div>

    );
}

export default connect((users), {saveusers}) (App);
