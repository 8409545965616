import "./taminotchilar.css"
import Edit from '../../../../../img/Edit.png'
import Delete from '../../../../../img/Delete.png'
import React, {useEffect} from "react";
import {connect} from "react-redux";
import {Checkbox, Upload} from "antd";
import TaminotReducer, {
    getTaminot,
    saveTaminot,
    editTaminot,
    deleteTaminot,
    qarzuzishTaminot,
    getSupplierOne,
    saveSupplierByExcelFile,
    getSupplierPageable
} from "../reducer/TaminotReducer";
import users from "../../../../../reducer/users";
import {useState} from 'react'
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import QarzuzishTaminotReducer from "../reducer/QarzuzishTaminotReducer";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import Loading from "../../../../Loading";
import ModalLoading from "../../../../ModalLoading";
import branchreducer, {getbranch} from "../../../../../reducer/branchreducer";
import PayReducer, {getPay} from "../../../../../reducer/PayReducer";
import Example from "../../../../../file/supplier.xlsx";
import img from "../../../../../img/Jami2.svg";
import ExcelButtons from "../../../../Components/ExcelButtons";
import {DefaultButton, QushishButton} from "../../../../Components/Buttons";
import {SearchAnt} from "../../../../Components/SelectAnt";
import CommonTable from "../../../../Components/CommonTable";
import {BaseUrl} from "../../../../../middleware";
import person from "../../../../../assets/image 8.svg";
import {Link} from "react-router-dom";
import money from "../../../../../assets/money bag-dollar.svg";
import edit from "../../../../../assets/editPencil.svg";
import trash from "../../../../../assets/deleteTrash.svg";
import importExcel from "../../../../../assets/download folder v.svg";
import skripka from "../../../../../assets/attach.svg";

function Taminotchilar({
                           getTaminot,
                           saveTaminot,
                           getSupplierOne,
                           qarzuzishTaminot,
                           editTaminot, PayReducer, getPay,
                           deleteTaminot,
                           taminot,
                           users,
                           TaminotReducer,
                           saveSupplierByExcelFile,
                           getSupplierPageable
                       }) {

    const [openCheckList, setOpenCheckList] = useState(false)
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(5);
    const [search, setSearch] = useState('')



    const handlePageChange = (newPage) => {
        setPage(newPage - 1);
    };
    const handleLimitChange = (event, size) => {
        setPage(0)
        setSize(size);
    };


    useEffect(() => {
        getSupplierPageable({
            businessId: users.businessId,
            params: {
                size, page, search
            }
        })
    }, [TaminotReducer.current, size, page, search])


    const [active, setActive] = useState(false);


    const [isDollar, setIsDollar] = useState('')

    function toggle() {
        setActive(!active)
        setEditId('')
        toggleEmpty()
    }

    function toggleEmpty() {
        setActive(!active)
        resetField('name', '')
        resetField('inn', '')
        resetField('telegram', '')
        resetField('phoneNumber', '')
        resetField('companyName', '')
        resetField('debt', '')
        resetField('debtDollar', '')
    }

    const [input, setInput] = useState(
        {
            inputsearch: '',
            qarzuzish: '',
            branchId: [],
            selectSupplier: '',
            false: false,
            true: true
        },
    );

    function changeizlash(e) {
        input.inputsearch = e.target.value
        let a = {...input}
        setInput(a)
    }

    function qarzuzish(e) {
        input.qarzuzish = e.target.value
        let a = {...input}
        setInput(a)
    }

    const [editId,


        setEditId] = useState('')
    const [changedtype, setChangedType] = useState('false')


    function editt(id) {
        toggle()
        setEditId(id)
        let a = TaminotReducer.taminot.filter(item => item.id === id)
        setValue('name', a[0].name)
        setValue('phoneNumber', a[0].phoneNumber)
        setValue('telegram', a[0].telegram)
        setValue('debt', a[0].debt)
        setValue('debtDollar', a[0]?.debtDollar)
        if (a[0].juridical) {
            setChangedType('true')
            setValue('inn', a[0].inn)

        } else {
            setChangedType('false')
        }
    }


    const [qarz, setqarz] = useState(false)
    const [qarzID, setqarzID] = useState('')
    const {t} = useTranslation()

    function debt2(id) {
        getSupplierOne(id)
        setqarzID(id)
        toggle3()
    }

    function toggle3() {
        setqarz(!qarz)
    }

    const [paymethod, setpaymethod] = useState(null)
    const [branch, setbranch] = useState(null)

    function saqlaQarz() {
        if (isDollar) {
            if ((isDollar === 'som' ? TaminotReducer.oneSupplier?.debt : TaminotReducer.oneSupplier?.debtDollar) >= input.qarzuzish) {
                qarzuzishTaminot({
                    repayment: isDollar === 'som' ? input.qarzuzish : 0,
                    repaymentDollar: isDollar === 'dollar' ? input.qarzuzish : 0,
                    branchId: branch ? branch : users.branchId,
                    paymentMethodId: paymethod,
                    id: qarzID
                })
                input.qarzuzish = ''
                let a = {...input}
                setInput(a)
                setIsDollar('')
                setqarzID('')
                toggle3()
            } else {
                toast.error(t('tes.150'))
            }
        } else {
            console.log(isDollar)
            toast.error('Pul birligini tanlang !')
        }

    }

    const {register, setValue, reset, handleSubmit, resetField, formState: {errors}} = useForm()


    const [deletemodal, setdeletemodal] = useState(false)
    const [deleteID, setdeletID] = useState('')

    function changeType(e) {
        setChangedType(e.target.value)
    }

    const [saveModal, setSaveModal] = useState(false)

    function deleteFunc() {
        setLoading(false)
        deleteTaminot(deleteID)
        deleteModaltoggle('')
        setSaveModal(true)
    }

    function deleteModaltoggle(item) {
        setdeletemodal(!deletemodal)
        setdeletID(item)
    }

    function onSubmit(data) {
        if (editId === '') {
            save(data)
        } else {
            editTaminot({
                ...data, businessId: users.businessId, id: editId,
            })
        }
        setSaveModal(true)
    }

    useEffect(() => {
        if (TaminotReducer.saveBoolean) {
            setActive(false)
            reset('')
            setEditId('')
        }
        setSaveModal(false)

    }, [TaminotReducer.current])

    function save(data) {
        saveTaminot(
            {
                ...data, juridical: changedtype === 'true' ? true : false, businessId: users.businessId
            }
        )
    }


    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
    }, [TaminotReducer.getBoolean])

    useEffect(() => {
        setLoading(false)
        getPay(users.businessId)

    }, [])

    useEffect(() => {
        if (PayReducer.paymethod) {
            PayReducer.paymethod.filter(val => {
                    if (val.type === 'Naqd') {
                        setpaymethod(val.id)
                    }
                }
            )
        }
    }, [])



    const [files, setfile] = useState(null)
    const [fileName, setFilename] = useState('')
    const [fileSize, setFileSize] = useState('')
    function importfile(e) {
        setfile(e.target.files[0])
        setFilename(e.target.files[0].name)
        setFileSize(e.target.files[0].size)
    }

    function saveProductByExcel() {
        const formData = new FormData();
        formData.append('file', files)
        saveSupplierByExcelFile({
            businessId: users.businessId,
            file: formData
        })
        setSaveModal(true)
    }
    const columns = [
        {
            title: 'ID',
            dataIndex: 'index',
            key: 'index',
            rowScope: 'row',
            width: '2%',
        },
        {
            title: 'Qo\'shgan hodim',
            dataIndex: 'createdByName',
            key: 'createdByName',
            render: (item, values) =>
                <div onClick={() => {
                }} className={'d-flex justify-content-start gap-3 align-items-center'}>
                    <img src={values.photoId
                        ? `${BaseUrl}/attachment/download/${values.photoId
                        }` : person} style={{borderRadius: '50%'}} width={40} height={40} alt={item}/>
                    <p className={`customer-name`}>{item}</p>
                </div>,
            width: '15%',
        },
        {
            title: 'Nomi',
            dataIndex: 'name',
            key: 'name',
            render: (item, values) =>
                <Link style={{textDecoration: 'none'}} to={'/main/supplierProfile/' + values.id}>
                    <div onClick={() => {
                    }} className={'d-flex justify-content-start gap-3 align-items-center'} style={{cursor: 'pointer'}}>
                        <img src={person} style={{borderRadius: '50%'}} width={40} height={40} alt={item}/>
                        <p className={`customer-name`}>{item}</p>
                    </div>
                </Link>,
            width: '15%',
        },
        {
            title: 'INN',
            dataIndex: 'inn',
            key: 'inn',
            width: '10%'
        },
        {
            title: 'Tel nomer',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            width: '10%'
        },
        {
            title: 'Ta\'minotchi turi',
            dataIndex: 'juridical',
            key: 'juridical',
            width: '15%',
            render: (item) => <p> {item ? 'Yuridik' : 'Jismoniy'}</p>
        },
        {
            title: 'Balans',
            dataIndex: 'debt',
            key: 'debt',
            render: (item, values) =>
                <span className={'customer-balance'} style={item > 0 ? {background: '#FFEFEF', color: '#E02323'} : {
                    background: '#EEFAE8',
                    color: '#57CA22'
                }}>
                   {item.toString().replace('-', "")} so'm
                </span>,
            width: '13%'
        },

        {
            title: 'Amallar',
            dataIndex: 'id',
            key: 'id',
            render: (item, values) => <div className={'d-flex gap-3'}>

                {
                    values.debtDollar > 0 || values.debt > 0 ?
                        <DefaultButton onClick={() => debt2(item)} width={34} bgColor={'#EEFAE8'} color={'#57ca22'}
                                       icon={money}/> : ''
                }
                {/*<DefaultButton onClick={() => sms(item)} width={34} bgColor={'#FFEEE0'} color={'#ff851f'}  icon={smsPicture}/>*/}
                {
                    users.editSupplier &&
                    <DefaultButton onClick={() => editt(item)} width={34} bgColor={'#EEF2FC'} color={'#0077ff'}
                                   icon={edit}/>
                }
                {
                    users.deleteSupplier &&
                    <DefaultButton onClick={() => deleteModaltoggle(item)} width={34} bgColor={'#FFEFEF'}
                                   color={'#e02323'} icon={trash}/>
                }
            </div>,
            width: '15%'
        },
    ];


    const defaultCheckedList = columns.map((item) => item.key);
    const [checkedList, setCheckedList] = useState(defaultCheckedList);

    const options = columns.map(({ key, title }) => ({
        label: title,
        value: key,
    }));
    const newColumns = columns.map((item) => ({
        ...item,
        hidden: !checkedList.includes(item.key),
    }));

    return (
        <div className='col-md-12 mt-2 pt-4 pb-4'>
            <div className="row px-4 d-flex justify-content-between align-items-center">
                <div className="col-md-8">
                    <ExcelButtons setOpenCheckList={setOpenCheckList}/>
                </div>
                <div className="col-md-4 d-flex justify-content-end">
                    {
                        users.addSupplier &&
                        <QushishButton onClick={toggle} buttonText={'Ta\'minotchi qoshish'}/>
                    }
                </div>
            </div>
            <div className="row px-4 mt-4">
                <div className="col-md-12">
                    <SearchAnt onChange={(e) => setSearch(e)}/>
                </div>
            </div>
            <div className="row px-4 mt-4">
                <div className={`${openCheckList ? "animatsiyaBlock mb-2" : "hiddenBlock"}`}>
                    <Checkbox.Group
                        value={checkedList}
                        options={options}
                        onChange={(value) => {
                            setCheckedList(value);
                        }}
                    />
                </div>
                <div className="col-md-12">
                    {
                        users.viewSupplier || users.viewSupplierAdmin ?
                            loading ?
                                TaminotReducer.supplierPageable ?
                                    <div className="mt-4 table-responsive">
                                        <CommonTable size={size} page={page} handleLimitChange={handleLimitChange}
                                                     handlePageChange={handlePageChange}
                                                     total={TaminotReducer.supplierPageable.totalItems}
                                                     scroll={{x: 1400, y: 400}}
                                                     pagination={true}
                                                     data={TaminotReducer.supplierPageable?.supplier_list}
                                                     columns={newColumns}
                                        />
                                    </div>
                                    : <div>
                                        <h4 className={'text-center'}>{TaminotReducer.message}</h4>
                                    </div> : <Loading/> : ''
                    }
                </div>
            </div>


            <Modal isOpen={active} toggle={toggle}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ModalHeader>
                        {t('Supplier.4')}
                    </ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="in col-md-12 justify-content-between d-flex col-sm-12 mb-2">
                                        <span style={{width: '100%'}}>
                                            <label htmlFor="">{t('Supplier.9')}</label>
                                            <select value={changedtype} className={'form-control'}
                                                    onChange={changeType}>
                                                <option value={'false'}>{t("Supplier.10")}</option>
                                                <option value={'true'}>{t("Supplier.11")}</option>
                                            </select>
                                        </span>
                                {
                                    changedtype === 'true' ?
                                        <div className="col-md-6 col-sm-12 mb-2">
                                            <label htmlFor={'log1'}>INN</label>
                                            <input type="text"
                                                   {...register('inn', {required: true})}
                                                   placeholder={errors.inn ? errors.inn?.type === "required" && "INN is required" : 'inn'}
                                                   defaultValue={''}
                                                   className={'form-control plcolor'}
                                                   id={'log1'}/>
                                        </div> : ''
                                }


                            </div>
                            <div className={'col-md-12'}>
                                {
                                    changedtype === 'true' ?
                                        <div style={{width: "100%"}}>
                                            <label htmlFor={'ism'}>{t("Supplier.12")}</label>
                                            <input type="text" id={'ism'}
                                                   {...register('name', {required: true})}
                                                   placeholder={errors.name ? errors.name?.type === "required" && "Name is required" : 'name'}
                                                   defaultValue={''}
                                                   className={'form-control'}
                                                   required="required"/>
                                        </div> :
                                        <div>
                                            <label htmlFor={'ism'}>{t('Employ.8')}</label>
                                            <input type="text" id={'ism'}
                                                   {...register('name', {required: true})}
                                                   placeholder={errors.name ? errors.name?.type === "required" && "Name is required" : 'name'}
                                                   defaultValue={''}
                                                   className={'form-control'}
                                                   required="required"/>
                                        </div>
                                }
                            </div>
                        </div>


                        <div className="row mt-2">
                            <div className={'col-md-6 col-sm-12 mb-3'}>
                                <label htmlFor={'ot'}>{t('Supplier.6')}</label>
                                <input
                                    {...register('telegram',)}
                                    placeholder={'telegram'}
                                    id={'telegram'} type="text"
                                    className={'form-control'}/>
                            </div>
                            <div className={'col-md-6 col-sm-12 mb-3'}>
                                <label htmlFor={'ot'}>{t('Supplier.7')}</label>
                                <input type="number"
                                       {...register('phoneNumber', {required: true})}
                                       placeholder={errors.phoneNumber ? errors.phoneNumber?.type === "required" && "Phone Num is required" : 'phoneNumber'}
                                       className={'form-control'} required={"required"}/>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className={'col-md-6 col-sm-12 mb-3'}>
                                <label htmlFor={'debt'}>Qarz (So'mda)</label>
                                <input
                                    {...register('debt')}
                                    defaultValue={'0'}
                                    placeholder={'qarz (so\'mda)'}
                                    id={'debt'} type="number"
                                    className={'form-control'}/>
                            </div>
                            <div className={'col-md-6 col-sm-12 mb-3'}>
                                <label htmlFor={'debtDollar'}>Qarz (Dollarda)</label>
                                <input type="number" id={'debtDollar'}
                                       defaultValue={'0'}
                                       {...register('debtDollar',)}
                                       placeholder={'qarz (dollar)'}
                                       className={'form-control'}/>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className={'btn btn-outline-primary'}
                                type={"submit"}
                        > {t('tes.157')}
                        </button>
                        <button type={"button"} className={'btn btn-outline-primary'}
                                onClick={toggleEmpty}>{t('Buttons.7')}</button>
                    </ModalFooter>
                </form>
            </Modal>

            <ModalLoading isOpen={saveModal}/>
            <div class="row px-4 mt-4">
                <span className={'d-flex align-items-center gap-3'}>
                    {
                        users.addSupplier &&
                        <a href={Example} download style={{textDecoration: 'none'}}>
                            <button
                                className={'customer-shablon'}>
                                {t('Hamkorlar.tsy')}<img src={importExcel} alt={'excel'} width={24}
                                                         height={24}/></button>
                        </a>
                    }

                    <label className={'customer-shablon'} htmlFor="choose">{t('Hamkorlar.iqfnt')}<img
                        src={skripka} alt={'skripka'} width={24} height={24}/></label>
                    <input onChange={importfile} hidden={'1'} id={'choose'} className={'mt-4'} type="file"/>
                    <strong
                        className={'ms-1 customer-shablon-text'}>{fileName} ({parseFloat(fileSize / 1024).toFixed(2)}.KB)  </strong>
                    <button onClick={saveProductByExcel}
                            className={'customer-shablon-save'}>{t('Darslik.s')}</button>
                    </span>
            </div>
        </div>
    )
}

export default connect((TaminotReducer, branchreducer, PayReducer, QarzuzishTaminotReducer, users), {
    getTaminot,
    getbranch,
    qarzuzishTaminot,
    getPay,
    getSupplierOne,
    saveTaminot,
    editTaminot,
    deleteTaminot,
    saveSupplierByExcelFile,
    getSupplierPageable
})(Taminotchilar)
