import '../Hodimlar/hodimlar.css'
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import users from "../../../../reducer/users";
import {Tabs} from "antd";
import Projects from "./Projects";
import Tasks from "./Tasks";
import Calendar from "./Calendar";

function Vazifalar({sidebaractive2, changeLink, link, users}) {
    const {t} = useTranslation()
    function sidebaractive() {
        const windowWidth = window.innerWidth;
        if (windowWidth <= 1023.9) {
            sidebaractive2()
        }
    }

    const items = [
        {
            label: t('Sidebar1.loyixalar'),
            key: '1',
            children: <Projects/>,
        },
        {
            label: t('Sidebar1.vazifalar'),
            key: '2',
            children: <Tasks/>,
        },
        {
            label: t('Sidebar1.vazifachart'),
            key: '3',
            children: <Calendar/>,
        },
    ]

    const onChange = (key) => {
        console.log(key);
    };
    return (
        <div>
            <h4 className={'p-3 mainText'}>{t('Sidebar1.vazifalar')}</h4>
            <div className={'px-3'}>
                <Tabs
                    onChange={onChange}
                    type="card"
                    items={items}
                />
            </div>
            {/*<div className={'hodimul'}>*/}
            {/*    <ul>*/}
            {/*        {*/}
            {/*            users.getProjectChecked || users.editProjectChecked || users.addProjectChecked || users.deleteProjectChecked || users.getOwnProjectChecked ?*/}
            {/*                <li onClick={sidebaractive}><NavLink to={'/headerthird/projects'}*/}
            {/*                                                     className={isActive => isActive ? 'active-enter-link lll li-text' : 'lll li-text'}>{t('Sidebar1.loyixalar')}</NavLink>*/}
            {/*                </li>:''*/}
            {/*        }*/}

            {/*        {*/}
            {/*            users.addTaskChecked || users.deleteTaskChecked || users.editTaskChecked || users.getTaskChecked || users.getOwnTaskChecked ?*/}
            {/*                <li onClick={sidebaractive}><NavLink to={'/headerthird/tasks'}*/}
            {/*                                                     className={isActive => isActive ? 'active-enter-link lll li-text' : 'lll li-text'}>{t('Sidebar1.vazifalar')}</NavLink>*/}
            {/*                </li>:''*/}
            {/*        }*/}
            {/*        {*/}
            {/*            users.addTaskChecked || users.deleteTaskChecked || users.editTaskChecked || users.getTaskChecked || users.getOwnTaskChecked ?*/}
            {/*                <li onClick={sidebaractive}><NavLink to={'/headerthird/task-calendar'}*/}
            {/*                                                     className={isActive => isActive ? 'active-enter-link lll li-text' : 'lll li-text'}>{t('Sidebar1.vazifachart')}</NavLink>*/}
            {/*                </li>:''*/}
            {/*        }*/}


            {/*        /!*<li onClick={sidebaractive}><Link  to={'/headerthird/hodimulush'} className={"lll li-text"}>Xodimlar ulushi</Link>   </li>*!/*/}
            {/*    </ul>*/}

            {/*</div>*/}


        </div>
    )
}

export default connect((users), {})(Vazifalar)
