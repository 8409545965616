import {connect} from "react-redux";
import {Link, useLocation} from 'react-router-dom'
import React, {useState, useEffect} from "react";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {useTranslation} from "react-i18next";
import productionReducer, {getProduction, getProductionById} from "../../../../../reducer/productionReducer";
import formatDate from "../../../../../util";
import Loading from "../../../../Loading";
import users from "../../../../../reducer/users";
import Korish from "../../../../../img/Korish.png";
import CSV from '../../../../../img/CSV.png'
import Excel from '../../../../../img/Excel.png'
import Print from '../../../../../img/Print.png'
import Pdf from '../../../../../img/PDF.png'
import "./tableProductin.css"
import {Pagination} from "antd";

function TableProduction({productionReducer, getProduction, users, getProductionById}) {
    const {t} = useTranslation()
    const [active, setActive] = useState(false)
    const [loading, setLoading] = useState(false)
    const [mainBranchId, setMainBranchId] = useState(null)
    const [pageData, setPageData] = useState({
        page: 1,
        size: 10,
    })

    const [query, setQuery] = useState(null);


    const handleQueryChange = (event) => {
        event.persist();
        setQuery(event.target.value === '' ? null : event.target.value);
    };


    useEffect(() => {
        setMainBranchId(users.branchId)
    }, [])
    const location = useLocation()

    useEffect(() => {
        getProduction({
            branchId: mainBranchId ? mainBranchId : users.branchId,
            params:{
                page: pageData.page - 1,
                size: pageData.size,
                name:query
            }
        })
    }, [productionReducer.current, mainBranchId,pageData,query])

    function changeBranchId(e) {
        setMainBranchId(e.target.value)
    }

    useEffect(() => {
        setLoading(true)
    }, [productionReducer.getBoolean])

    useEffect(() => {
        setLoading(false)
    }, [])

    useEffect(() => {
        localStorage.setItem('link', location.pathname)
    }, [])


    function toggle() {
        setActive(prevState => !prevState)
    }

    function viewOneProduction(id) {
        toggle()
        getProductionById(id)
    }

    window.addEventListener('beforeunload', () => {
        window.location.reload();
    })

    const onShowSizeChange = (current, pageSize) => {
        setPageData({page: current, size: pageSize});
    };

    return (
        <div className="col-md-12 mt-4 mb-4">
            <div className="textHeaderBL">
                <h2>{t('num.53')}</h2>
            </div>
            <div className="rowStyleBL">
                <div className="qoshish">
                    <h5></h5>
                    {
                        users.addProduction ?
                            <Link to={'/main/production/add'}>
                                <button className={'btn btn-primary'}>+ {t('num.54')}</button>
                            </Link> : ''
                    }
                </div>
                <div className="izlashBL mb-4">
                    <div className="izlashBox1">
                        <p></p>
                        <select id="">
                            <option value="">25</option>
                        </select>
                        <button><img src={CSV} alt=""/> Export CSV</button>
                        <button><img src={Excel} alt=""/> Export Excel</button>
                        <button><img src={Print} alt=""/> Print</button>
                        <button><img src={Pdf} alt=""/>Export PDF</button>

                    </div>
                    {
                        users.branches.length > 1 ?
                            <div className="izlashBox2">
                                <select name="" id="" onChange={changeBranchId} value={mainBranchId}>
                                    {
                                        users.branches.map(item =>
                                            <option value={item.id}>{item.name}</option>
                                        )
                                    }
                                </select>
                            </div> : ''
                    }
                    <div className="izlashBox2">
                            <input type="text" placeholder={t('num.37')}
                                   onChange={handleQueryChange} value={query}
                            />
                    </div>

                </div>

                {
                    users.viewProduction ?
                        loading ?
                            productionReducer.production ?
                                <div>
                                    <div className="table-responsive table-wrapper-scroll-y my-custom-scrollbar mb-4">
                                        <table className='table  table-bordered mt-4'>
                                            <thead>
                                            <tr>
                                                <th>{t('num.55')}</th>
                                                <th>{t('num.38')}</th>
                                                <th>{t('num.56')}</th>
                                                <th>{t('num.57')}</th>
                                                <th>{t('num.11')}</th>
                                                <th>{t('num.39')}</th>
                                                <th>{t('num.40')}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                productionReducer.production
                                                    .map((item, index) =>
                                                        <tr key={item.id}>
                                                            <td>{formatDate(item.date)}</td>
                                                            <td>{item?.product?.category?.name}</td>
                                                            <td>{item?.product ? item?.product?.name : item?.productTypePrice?.name}</td>
                                                            <td>{item?.quantity}</td>
                                                            <td>{item?.totalPrice}</td>
                                                            <td>{(item?.totalPrice / item?.quantity).toFixed(3)}</td>
                                                            <td className={'gap-3'}>
                                                                <button onClick={() => viewOneProduction(item.id)}
                                                                        className='btn btn-primary'>
                                                                    <img
                                                                        src={Korish}
                                                                        alt=""/> {t('num.58')}
                                                                </button>
                                                            </td>
                                                        </tr>)
                                            }
                                            </tbody>
                                        </table>
                                        <br/>
                                        <Pagination
                                            showSizeChanger
                                            onShowSizeChange={onShowSizeChange}
                                            defaultCurrent={pageData.page}
                                            total={productionReducer?.productionTotal}
                                        />
                                    </div>
                                </div> : <div className={'border border-2'}>
                                    <h4 className={'text-center'}>{productionReducer.message || t('Darslik.mtt')}</h4>
                                </div> : <Loading/> : ''
                }


                <Modal size={'xl'} isOpen={active} toggle={toggle}>
                    <ModalHeader>
                        {t('num.59')}
                    </ModalHeader>
                    <ModalBody>
                        {
                            productionReducer.oneProduction ?
                                productionReducer.oneProduction.map(item =>
                                    <div className={'d-flex justify-content-between align-items-center mb-4'}>
                                        <div>
                                            <div className={'d-flex justify-content-between align-items-center'}>
                                                <h5 className={'p-0 m-0 me-2'}>{t('num.2')}</h5>
                                                <p className={'p-0 m-0'}>{item.product ? item.product?.name : item.productTypePrice?.name}</p>
                                            </div>
                                            <div className={'d-flex justify-content-between align-items-center'}>
                                                <h5 className={'p-0 m-0 me-2'}>{t('num.60')}</h5>
                                                <p className={'p-0 m-0'}>
                                                    {item.totalQuantity} {item.product ? item.product?.measurement?.name : item.productTypePrice?.measurement?.name}
                                                </p>
                                            </div>
                                        </div>
                                        <div>
                                            <div className={'d-flex justify-content-between align-items-center'}>
                                                <h5 className={'p-0 m-0 me-2'}>{t('num.61')}</h5>
                                                <p className={'p-0 m-0'}>
                                                    {item.quantity} {item.product ? item.product?.measurement?.name : item.productTypePrice?.measurement?.name}
                                                </p>
                                            </div>
                                            <div className={'d-flex justify-content-between align-items-center'}>
                                                <h5 className={'p-0 m-0 me-2'}>Yo'qotilgan miqdor:</h5>
                                                <p className={'p-0 m-0'}>
                                                    {item.invalid} {item.product ? item.product?.measurement?.name : item.productTypePrice?.measurement?.name}
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                ) : ''
                        }
                        <h4>{t('num.63')}</h4>
                        <div className={'table-responsive'}>
                            <table className={'table'}>
                                <thead className={'bg-success text-white fw-bold'}>
                                <tr>
                                    <th>{t('num.5')}</th>
                                    <th>{t('num.64')}</th>
                                    {/*<th>{t('num.65')}</th>*/}
                                    <th>{t('num.66')}</th>
                                    <th>{t('num.67')}</th>
                                    <th>{t('num.68')}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    productionReducer.contentProductList ?
                                        productionReducer.contentProductList.map(item =>
                                            <tr>
                                                <td>{item.product ? item.product?.name : item.productTypePrice?.name}</td>
                                                <td>{item.quantity} {item.product ? item.product?.measurement?.name : item.productTypePrice?.measurement?.name}</td>
                                                <td>{
                                                    item.byProduct ?
                                                        'Mahsulot bolib chiqgan' :
                                                        item.lossProduct ? 'Yoqotilgan miqdordan chiqgan mahsulot' :
                                                            'Xomashyo sifati ishlatilgan'
                                                }</td>
                                                <td>{item.quantity} {item.product ? item.product?.measurement?.name : item.productTypePrice?.measurement?.name}</td>
                                                <td>{item.totalPrice} {t('num.12')}</td>
                                            </tr>
                                        ) : ''
                                }
                                </tbody>
                            </table>
                        </div>

                        {
                            productionReducer.oneProduction ?
                                productionReducer.oneProduction.map(item =>
                                    <div>
                                        <div className={'d-flex justify-content-end align-items-center'}>
                                            <div className={'d-flex justify-content-between align-items-center'}>
                                                <h5 className={'p-0 m-0 me-2'}>{t('num.69')}</h5>
                                                <p className={'p-0 m-0'}>{item.contentPrice} {t('num.12')}</p>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-end">
                                            <div className={'d-flex justify-content-center  align-items-center'}>
                                            <h5 className={"p-0 m-0 me-1"}>{t('num.70')}</h5>
                                            </div>
                                        </div>
                                        {
                                            productionReducer.costGetDtoList ?
                                                productionReducer.costGetDtoList.map(item =>
                                                    <div className="d-flex align-items-center justify-content-end">
                                                        <div
                                                            className={'d-flex justify-content-between align-items-center'}>
                                                            <h5 className={"p-0 m-0 me-1"}>{item.costTypeName}:</h5>

                                                            <p className={"p-0 m-0"}>{item.sum} {t('num.12')}</p>
                                                        </div>
                                                    </div>)

                                                : ''
                                        }
                                        <div className={'d-flex justify-content-end align-items-center'}>
                                            <div className={'d-flex justify-content-between align-items-center'}>
                                                <h5 className={'p-0 m-0 me-2'}>{t('num.71')}</h5>
                                                <p className={'p-0 m-0'}>
                                                    {item.cost}  {t('num.12')} ({item.costEachOne ? 'Har biriga':'Narxga'})
                                                </p>
                                            </div>
                                        </div>
                                        <div className={'d-flex justify-content-end align-items-center'}>
                                            {
                                                item.taskPrice ?
                                                    <div className={'d-flex justify-content-between align-items-center'}>
                                                        <h5 className={'p-0 m-0 me-2'}>{t('num.72')}</h5>
                                                        <p className={'p-0 m-0'}>
                                                            {item.taskPrice}
                                                        </p>
                                                    </div> :''
                                            }

                                        </div>
                                        <div className={'d-flex justify-content-end align-items-center'}>
                                            <div className={'d-flex justify-content-between align-items-center'}>
                                                <h5 className={'p-0 m-0 me-2'}>{t('num.68')}</h5>
                                                <p className={'p-0 m-0'}>{item.totalPrice} {t('num.12')}</p>
                                            </div>
                                        </div>
                                    </div>
                                ) : ''
                        }
                    </ModalBody>
                    <ModalFooter>
                        <button className={'btn btn-primary'} onClick={toggle}>{t('num.48')}</button>
                    </ModalFooter>
                </Modal>
            </div>
        </div>)
}

export default connect((productionReducer, users), {getProduction, getProductionById})(TableProduction)
