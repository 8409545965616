import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../api";
import {toast} from "react-toastify";

const slice = createSlice({
    name: 'photo',
    initialState: {
        photo: null,
        titlePhoto:null,
        photoManyProduct:null,
        ManyCurrent:false,
        current:false,
        titlePhotoCurrent:false
    },
    reducers: {
        savefrom: (state,action) => {
            state.current=!state.current
            let a  = action.payload.object.id
            state.photo = a
            toast.success('Saqlandi')
        },
        savefromMany: (state,action) => {
            state.ManyCurrent=!state.ManyCurrent
            let a  = action.payload.object.id
            state.photoManyProduct = a
            toast.success('Saqlandi')
        },
        saveTitle: (state,action) => {
            state.titlePhotoCurrent=!state.titlePhotoCurrent
            let a  = action.payload.object.id
            state.titlePhoto = a
            toast.success('Saqlandi')
        },
        clearPhotoId:(state,action)=>{
            let a  = null
            state.photo = a
            state. titlePhoto = a
        }
    }
});


export const savephoto=(data)=>apiCall({
    url: '/attachment/upload',
    method:'post',
    data:data,
    onSuccess: slice.actions.savefrom.type
});

export const savePhotoProduct=(data)=>apiCall({
    url: '/attachment/upload',
    method:'post',
    data:data,
    onSuccess: slice.actions.savefromMany.type
});

export const savePhotoTitle=(data)=>apiCall({
    url: '/attachment/upload',
    method:'post',
    data:data,
    onSuccess: slice.actions.saveTitle.type
});


export const {clearPhotoId} = slice.actions
export default slice.reducer