import React, {forwardRef, useState, useRef, useEffect} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Avatar,
    Box,
    LinearProgress,
    Card,
    Tooltip,
    List as ListWrapper,
    ListItem,
    ListItemText,
    Menu,
    Button,
    IconButton,
    Typography,
    AvatarGroup,
    styled,
    Dialog,
    DialogTitle,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TablePagination,
    DialogContent, Grid, TextField, DialogActions, CircularProgress, FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import {addDays, formatDistance} from 'date-fns';
import { useTranslation } from 'react-i18next';
import CommentTwoToneIcon from '@mui/icons-material/CommentTwoTone';
import MoreHorizTwoToneIcon from '@mui/icons-material/MoreHorizTwoTone';
import AttachFileTwoToneIcon from '@mui/icons-material/AttachFileTwoTone';
import FormatListBulletedTwoToneIcon from '@mui/icons-material/FormatListBulletedTwoTone';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import ArchiveTwoToneIcon from '@mui/icons-material/ArchiveTwoTone';
import StorefrontIcon from '@mui/icons-material/Storefront';
import CloseIcon from "@mui/icons-material/Close";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import {Formik} from "formik";
import * as Yup from "yup";
import {connect} from "react-redux";
import WbIncandescentIcon from '@mui/icons-material/WbIncandescent';

const LinearProgressWrapper = styled(LinearProgress)(
    ({ theme }) => `
      flex-grow: 1;
      height: 8px;
      background-color: ${theme.colors.alpha.black[10]};

      .MuiLinearProgress-barColorPrimary {
        background-color: ${theme.colors.primary.main};
        border-top-right-radius: ${theme.general.borderRadius};
        border-bottom-right-radius: ${theme.general.borderRadius};
      }
`
);

const ButtonWrapper = styled(Button)(
    ({ theme }) => `
      padding: ${theme.spacing(0.5)} ${theme.spacing(1)};
      min-width: 32px;

      .MuiSvgIcon-root {
        color: ${theme.colors.alpha.black[30]};
        margin-right: ${theme.spacing(1)};
      }

      &:hover {
        .MuiSvgIcon-root {
          color: ${theme.colors.primary.main};
        }
      }
`
);

const Task = forwardRef(({trade,handleDelete,openIzoh,
                             task,addCustomer,viewLidInfo, dragging, index, list, ...rest }, ref) => {
    const { t } = useTranslation();



    const [onMenuOpen, menuOpen] = useState(false);


    const moreRef = useRef(null);

    const openMenu = () => {
        menuOpen(true);
    };

    const closeMenu = () => {
        menuOpen(false);
    };

    function shop(){
        closeMenu()
        trade()
    }

    return (
        <>
            <Box key={task.id} ref={ref} {...rest}>
                            <Card
                                sx={{
                                    m: 2,
                                    p: 2
                                }}
                                className={clsx({ dragging })}
                                raised={dragging}
                                variant={dragging ? 'elevation' : 'outlined'}
                            >


                                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Typography variant="h4" gutterBottom noWrap>
                                        {task.values?.FIO}
                                    </Typography>
                                    {/*<Typography variant="subtitle1" noWrap>*/}
                                    {/*    {task.values?.Address}*/}
                                    {/*</Typography>*/}
                                    <IconButton
                                        color="inherit"
                                        size="small"
                                        onClick={openMenu}
                                        ref={moreRef}
                                    >
                                        <MoreHorizTwoToneIcon fontSize="small" />
                                    </IconButton>
                                </Box>
                            </Card>
            </Box>
            <Menu
                disableScrollLock
                keepMounted
                anchorEl={moreRef.current}
                open={onMenuOpen}
                onClose={closeMenu}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center'
                }}
            >
                <ListWrapper sx={{p: 1}}
                    component="nav">
                    <ListItem button>
                        <VisibilityTwoToneIcon />
                        <ListItemText onClick={()=>viewLidInfo(task.id)} primary={t('Lids.korish')} />
                    </ListItem>
                    <ListItem button onClick={()=>addCustomer(task.values?.FIO, task.values?.PhoneNumber)}>
                        <ArchiveTwoToneIcon />
                        <ListItemText primary={t('Lids.mijozQoshish')} />
                    </ListItem>
                    <ListItem button onClick={trade}>
                        <StorefrontIcon/>
                        <ListItemText primary={t('Lids.savdoQilish')} />
                    </ListItem>
                    <ListItem button onClick={()=>openIzoh(task.id)}>
                        <WbIncandescentIcon/>
                        <ListItemText primary={t('Lids.izoh')} />
                    </ListItem>
                    <ListItem button>
                        <DeleteForeverTwoToneIcon />
                        <ListItemText onClick={()=>handleDelete(task.id)} primary={t('Lids.ocirish')} />
                    </ListItem>
                        {/*<IconButton*/}
                        {/*    // onClick={() => handleConfirmDelete(item.id)}*/}
                        {/*    color="primary"*/}
                        {/*>*/}
                        {/*    <DeleteTwoToneIcon fontSize="small"/>*/}
                        {/*</IconButton>*/}


                </ListWrapper>
            </Menu>


        </>
    );
});

Task.propTypes = {
    task: PropTypes.object.isRequired,
    dragging: PropTypes.bool.isRequired,
    index: PropTypes.number,
    list: PropTypes.object.isRequired
};

Task.defaultProps = {
    dragging: false
};

export default Task
