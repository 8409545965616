import {connect} from "react-redux";
import {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Form, Input, Modal, Popconfirm} from "antd";

import CommonTable from "../../../../Components/CommonTable";
import {DeleteButton, EditButton, QushishButton} from "../../../../Components/Buttons";
import {setLocalStorage, tablePageSize} from "../../../../../util";
import FirmaReducer, {deleteFirma, editFirma, getFirma, saveFirma,} from "../reducer/FirmaReducer";
import users from "../../../../../reducer/users";

import './firmalar.css'

function Firmalar({getFirma, users, saveFirma, editFirma, deleteFirma, FirmaReducer}) {
    const {t} = useTranslation()
    const [form] = Form.useForm();
    const [pageData, setPageData] = useState({
        page: 0,
        pageSize: tablePageSize ? tablePageSize : 10,
        loading: false,
        openModal: false,
        editId: null,
        searchValue: ""
    });

    const columns = [
        {
            key: "id",
            title: 'Id',
            dataIndex: 'index',
            width: '3%',
            fixed: 'left',
        },
        {
            title: t('Firms.1'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('Hamkorlar.amal'),
            dataIndex: 'count',
            key: 'count',
            render: (item, record) => <div className="d-flex align-items-center gap-2">
                {
                    users.editBrand ? <EditButton onClick={() => {
                          setPageData( (prev) => ({...prev, editId: record.id, openModal: true}))
                          form.setFieldValue("name", record.name)
                      }} buttonText={t('Buttons.1')} />
                      : null
                }
                {
                    users.deleteBrand ?
                      <Popconfirm
                        title={`${t('Buttons.12')}?`}
                        onConfirm={() => deleteFirma(record.id)}
                        okText={t('Buttons.3')}
                        cancelText={t('Buttons.7')}
                      >
                          <DeleteButton buttonText={t('Buttons.3')} />
                      </Popconfirm>
                      : null
                }
            </div>,
        },
    ];


    useEffect(() => {
        getFirma(users.businessId)
    }, [FirmaReducer.current])

    useEffect(()=>{
        setPageData( (prev) => ({...prev, loading: false}))
    },[FirmaReducer.getBoolean])

    useEffect(()=>{
        setPageData( (prev) => ({...prev, loading: true}))
    },[])

    const newChange = (pageNumber, page) => {
        setPageData((prev) => ({ ...prev, pageSize: page, page: pageNumber - 1 }));
        setLocalStorage("pageSize", page)
    };

    function searchBrand(e) {
        setPageData( (prev) => ({...prev, searchValue: e?.target?.value}))
    }

    const onCreate = (values) => {
        pageData.editId ? editFirma({
            ...values,
            businessId: users.businessId,
            id: pageData.editId
        }): saveFirma({
            ...values,
            businessId: users.businessId,
        })
        setPageData( (prev) => ({...prev, loading: true, editId: null, page: 0, searchValue: "", openModal: false}))
    };

    return (
        <div className="col-md-12 mt-2 mt-2 mb-4">
            <div className="rowStyleFR">
                <div className="qoshishFR mb-3">
                    <h3>{t('Firms.2')}</h3>
                    {
                        users.addBrand ? <QushishButton onClick={() => setPageData( (prev) => ({...prev, openModal: true}))} buttonText={t('Buttons.2')}/> : null
                    }
                </div>
                <div>
                    <div className="mb-3">
                        <Input allowClear rootClassName="w-full searchBrand" onChange={searchBrand}
                                   placeholder={t("num.37")}/>
                    </div>
                    <CommonTable
                      loading={pageData.loading}
                      scroll={{x: 1200}}
                      total={FirmaReducer.firmalar?.filter((val) => pageData.searchValue === '' ? val : val.name?.toUpperCase().includes(pageData.searchValue.toUpperCase()) && val)?.length}
                      handlePageChange={newChange}
                      pagination={true}
                      page={pageData.page}
                      size={pageData.pageSize}
                      data={FirmaReducer.firmalar?.filter((val) => pageData.searchValue === '' ? val : val.name?.toUpperCase().includes(pageData.searchValue.toUpperCase()) && val)}
                      columns={columns}
                    />
                </div>
                <Modal
                  open={pageData.openModal}
                  title={t('Sections.8')}
                  okText={t('Buttons.6')}
                  cancelText={t('Buttons.7')}
                  onCancel={() => {
                      setPageData( (prev) => ({...prev, openModal: false, editId: null}))
                      form.resetFields();
                  }}
                  onOk={() => {
                      form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreate(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                  }}
                >
                    <Form
                      form={form}
                      layout="vertical"
                      name="form_in_modal"
                    >
                        <Form.Item
                          name="name"
                          label={t('Firms.4')}
                          rules={[
                              {
                                  required: true,
                              }
                          ]}
                        >
                            <Input placeholder={`${t('Expenses.11')}...`} />
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        </div>
    )
}

export default connect((FirmaReducer, users), {
    getFirma,
    saveFirma,
    editFirma,
    deleteFirma
})(Firmalar)
