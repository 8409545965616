import "./maxsulotlarRoyxati.css"
import {Link, useHistory} from "react-router-dom"
import {connect} from 'react-redux'
import React, {useEffect, useState} from 'react'
import users from "../../../../../reducer/users";
import MaxsulotlarRoyxariReducer, {
    deleteMaxsulotRuyxati,
    getProductFilter, getMaxsulotById, deleteMaxsulotRuyxatiByIds,
    getProductTableSearch,getOneProductAbout
} from '../reducer/MaxsulotlarRoyxariReducer'
import FirmaReducer, {getFirma} from "../reducer/FirmaReducer";
import kgreducer, {getkg} from "../../../../../reducer/kgreducer";
import BolimReducer, {getBolim} from "../reducer/BolimReducer";
import branchreducer, {getbranch} from "../../../../../reducer/branchreducer";
import {useTranslation} from "react-i18next";
import Select from "react-select";
import {
    alpha, Avatar, Box, Checkbox,
    Divider, IconButton,
    InputAdornment, styled, Table,
    TableBody, TableCell,
    TableContainer, TableHead, TablePagination,
    TableRow, TextField, Tooltip,
    Typography, useTheme
} from "@mui/material";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import Loading from "../../../../Loading";
import LaunchTwoToneIcon from "@mui/icons-material/LaunchTwoTone";
import EditIcon from "@mui/icons-material/Edit";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import {Modal, ModalBody, ModalFooter} from "reactstrap";
import CSV from "../../../../../img/CSV.png";
import {BaseUrl} from "../../../../../middleware";
import Excel from "../../../../../img/Excel.png";
import Print from "../../../../../img/Print.png";
import Pdf from "../../../../../img/PDF.png";
import KorishM from "./Taxrirlash/Korish";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ModalLoading from "../../../../ModalLoading";

const TableWrapper = styled(Table)(
    ({theme}) => `

    thead tr th {
        border: 0;
    }

    tbody tr td {
        position: relative;
        border: 0;

        & > div {
            position: relative;
            z-index: 5;
        }

        &::before {
            position: absolute;
            left: 0;
            top: 0;
            transition: ${theme.transitions.create(['all'])};
            height: 100%;
            width: 100%;
            content: "";
            background: ${theme.colors.alpha.white[100]};
            border-top: 1px solid ${theme.colors.alpha.black[10]};
            border-bottom: 1px solid ${theme.colors.alpha.black[10]};
            pointer-events: none;
            z-index: 4;
        }

        &:first-of-type:before {
            border-top-left-radius: ${theme.general.borderRadius};
            border-bottom-left-radius: ${theme.general.borderRadius};
            border-left: 1px solid ${theme.colors.alpha.black[10]};
        }
        

        &:last-child:before {
            border-top-right-radius: ${theme.general.borderRadius};
            border-bottom-right-radius: ${theme.general.borderRadius};
            border-right: 1px solid ${theme.colors.alpha.black[10]};
        }
    }

    tbody tr:hover td::before {
        background: ${alpha(theme.colors.primary.main, 0.02)};
        border-color: ${alpha(theme.colors.alpha.black[100], 0.25)} !important;
    }

  `
);

const TableRowDivider = styled(TableRow)(
    ({theme}) => `
    height: ${theme.spacing(2)};
  `
);

const LabelSuccess = styled(Box)(
    ({theme}) => `
    display: inline-block;
    background: ${theme.colors.success.lighter};
    color: ${theme.colors.success.main};
    text-transform: uppercase;
    font-size: ${theme.typography.pxToRem(11)};
    font-weight: bold;
    padding: ${theme.spacing(1, 2)};
    border-radius: ${theme.general.borderRadiusSm};
  `
);

const LabelError = styled(Box)(
    ({theme}) => `
    display: inline-block;
    background: ${theme.colors.error.lighter};
    color: ${theme.colors.error.main};
    text-transform: uppercase;
    font-size: ${theme.typography.pxToRem(11)};
    font-weight: bold;
    padding: ${theme.spacing(1, 2)};
    border-radius: ${theme.general.borderRadiusSm};
  `
);

const LabelWarning = styled(Box)(
    ({theme}) => `
    display: inline-block;
    background: ${theme.colors.warning.lighter};
    color: ${theme.colors.warning.main};
    text-transform: uppercase;
    font-size: ${theme.typography.pxToRem(11)};
    font-weight: bold;
    padding: ${theme.spacing(1, 2)};
    border-radius: ${theme.general.borderRadiusSm};
  `
);

const IconButtonWrapper = styled(IconButton)(
    ({theme}) => `
    transition: ${theme.transitions.create(['transform', 'background'])};
    transform: scale(1);
    transform-origin: center;

    &:hover {
        transform: scale(1.1);
    }
  `
);

const TableHeadWrapper = styled(TableHead)(
    ({theme}) => `
      .MuiTableCell-root {
          text-transform: none;
          font-weight: normal;
          color: ${theme.colors.alpha.black[100]};
          font-size: ${theme.typography.pxToRem(16)};
          padding: ${theme.spacing(2)};
      }

      .MuiTableRow-root {
          background: transparent;
      }
  `
);

const SearchInputWrapper = styled(TextField)(
    ({theme}) => `
    background: ${theme.colors.alpha.white[100]};
    border-radius: ${theme.general.borderRadius};

    .MuiInputBase-input {
        font-size: ${theme.typography.pxToRem(16)};
    }
  `
);

function MaxsulotlarRoyxati({
                                getProductFilter,
                                getBolim,
                                BolimReducer,
                                getbranch,
                                users,
                                getFirma,
                                FirmaReducer,
                                getMaxsulotById,
                                MaxsulotlarRoyxariReducer,
                                deleteMaxsulotRuyxati,
                                deleteMaxsulotRuyxatiByIds,
                                getProductTableSearch,
                                getOneProductAbout
                            }) {



    const theme = useTheme();

    const [loading, setLoading] = useState(false)
    const {t} = useTranslation()
    const [deletemodal, setdeletemodal] = useState(false)
    const [deleteID, setdeletID] = useState('')
    const [active, setActive] = useState(false)


    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [mainBranchId, setMainBranchId] = useState(null)
    const [branId, setbranId] = useState(null)
    const [categoryId, setCategoryId] = useState(null)
    const [search, setSearch] = useState('')

    const history = useHistory()


    useEffect(() => {
        getFirma(users.businessId)
        getbranch(users.businessId)
        getBolim(users.businessId)
    }, [])

    useEffect(()=>{
        getProductTableSearch({
            businessId: users.businessId,
            params: {
                brand_id: branId,
                branch_id: mainBranchId,
                categoryId: categoryId,
                page:page,
                size:rowsPerPage,
                search:search
            }
        })
    },[branId,mainBranchId,categoryId,search,rowsPerPage,page,MaxsulotlarRoyxariReducer.current])


    const [productId,setProductId] = useState(null)

    function korishsh(id) {
        setProductId(id)
        toggle()
    }



    function toggle() {
        setActive(!active)
    }

    function deleteFunc() {
        deleteMaxsulotRuyxati(deleteID)
        deleteModaltoggle('')
    }

    function deleteModaltoggle(item) {
        setdeletemodal(!deletemodal)
        setdeletID(item)
    }

    useEffect(() => {
        setLoading(true)
    }, [MaxsulotlarRoyxariReducer.getBoolean])

    useEffect(() => {
        setLoading(false)
    }, [])
    const handleChangePage = (_event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const germanCurrencyFormatter = new Intl.NumberFormat("uz", { notation: "standard"});


    const togglePush = () => {
        history.push('/main/addProduct')
    }

    let firm = FirmaReducer.firmalar ? FirmaReducer.firmalar?.map(item => ({value: item.id, label: item.name})) : ''
    const firmOptions = [{value: "ALL", label: 'Barchasi'}, ...firm]
    let baza = users.branches ? users.branches?.map(item => ({value: item.id, label: item.name})) : ''
    const bazaOptions = [{value: "ALL", label: 'Barchasi'}, ...baza]
    let category = BolimReducer.bolimlar ? BolimReducer.bolimlar?.map(item => ({value: item.id, label: item.name})) : ''
    const categoryOptions = [{value: "ALL", label: 'Barchasi'}, ...category]

    const [selectedItems, setSelectedUsers] = useState([]);
    const selectedSomeUsers =
        selectedItems.length > 0 && selectedItems.length < MaxsulotlarRoyxariReducer.productTableSearch?.product_list.length;
    const selectedAllUsers = selectedItems.length === MaxsulotlarRoyxariReducer.productTableSearch?.product_list?.length;

    const handleSelectAllUsers = (event) => {
        setSelectedUsers(event.target.checked ? MaxsulotlarRoyxariReducer.productTableSearch?.product_list.map((user) => user.productId) : []);
    };

    const handleSelectOneInvoice = (event, invoiceId) => {
        if (!selectedItems.includes(invoiceId)) {
            setSelectedUsers((prevSelected) => [...prevSelected, invoiceId]);
        } else {
            setSelectedUsers((prevSelected) =>
                prevSelected.filter((id) => id !== invoiceId)
            );
        }
    };

    const [saveModal, setSaveModal] = useState(false)

    useEffect(() => {
        if (MaxsulotlarRoyxariReducer.deleteBoolean) {
            setSelectedUsers([])
        }
        setSaveModal(false)

    }, [MaxsulotlarRoyxariReducer.deleteCurrent])

    const [visible, setvisible] = useState(5)

    return (
        <div className="col-md-12 mt-4 mb-4">
            <div className="textHeaderMax">
                <h2>{t('ProductList.1')}</h2>
                <p className="ms-2">{t('ProductList.2')}</p>
            </div>
            <div className="rowStyleMax colorback">
                <div className="qoshish">
                    <h5>{t('Buttons.16')}</h5>
                </div>
                <div className="block">
                    <div className="box">
                        <div className="minBox">
                            <h6>{t('ProductList.7')}:</h6>
                            <Select
                                maxMenuHeight={'150px'}
                                defaultValue={firmOptions[0]}
                                onChange={(e)=>setbranId(e.value === "ALL" ? null : e.value)}
                                options={firmOptions}
                            />
                        </div>

                    </div>
                    <div className="box">
                        <div className="minBox">
                            <h6>{t('ProductList.8')}:</h6>
                            <Select
                                maxMenuHeight={'150px'}
                                defaultValue={bazaOptions[0]}
                                onChange={(e)=>
                                {
                                    setPage(0)
                                    setRowsPerPage(10)
                                    setMainBranchId(e.value === "ALL" ? null : e.value)
                                }}
                                options={bazaOptions}
                            />
                        </div>
                    </div>
                    <div className="box">
                        <div className="minBox">
                            <h6>Kategoriya</h6>
                            <Select
                                maxMenuHeight={'150px'}
                                defaultValue={categoryOptions[0]}
                                onChange={(e)=>setCategoryId(e.value === "ALL" ? null : e.value)}
                                options={categoryOptions}
                            />
                        </div>
                    </div>

                </div>
                <>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '20px 0 30px'
                    }}>
                        <h5>{t('ProductList.1')}</h5>
                        {
                            users.addProduct ?
                                <button onClick={togglePush} className='btn btn-primary'>+{t('Buttons.2')}</button> : ''
                        }
                    </div>
                    <Box
                        sx={{
                            background: `${theme.colors.alpha.black[5]}`
                        }}
                    >
                        <SearchInputWrapper
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchTwoToneIcon/>
                                    </InputAdornment>
                                )
                            }}
                            placeholder={t('pro.mahsulotnbq')}
                            fullWidth
                            value={search}
                            onChange={(e)=>setSearch(e.target.value)}
                        />
                    </Box>
                    <Divider/>
                    {
                        users.viewProductAdmin || users.viewProduct ?
                            loading ?
                                MaxsulotlarRoyxariReducer.productTableSearch ?
                                    <Box pb={3}>
                                        {
                                            selectedItems.length <= 0 ? '' :
                                                <button onClick={() => {
                                                    deleteMaxsulotRuyxatiByIds({
                                                        ids: selectedItems
                                                    })
                                                    setSaveModal(true)
                                                }}
                                                        className={'btn btn-danger mt-4 d-flex justify-content-end'}>{t('pro.belginiuch')}</button>
                                        }
                                        <TableContainer>
                                            {/*<TableWrapper>*/}
                                            <TableHeadWrapper>
                                                <TableRow>
                                                    <TableCell>T/R</TableCell>
                                                    <TableCell>
                                                        <Tooltip
                                                            arrow
                                                            placement="top"
                                                            title={t('All')}
                                                        >
                                                            <Checkbox
                                                                checked={selectedAllUsers}
                                                                indeterminate={selectedSomeUsers}
                                                                onChange={handleSelectAllUsers}
                                                            />
                                                        </Tooltip>
                                                    </TableCell>

                                                    <TableCell align="left">{t('Org.m')}</TableCell>
                                                    <TableCell align="left">{t('Salary.f')}</TableCell>
                                                    <TableCell align="center">{t('Status')}</TableCell>
                                                    <TableCell align="center">{t('block.son')}</TableCell>
                                                    <TableCell align="center">{t('block.sn')}</TableCell>
                                                    <TableCell align="center">{t('pro.optomsn')}</TableCell>
                                                    <TableCell align="center">{t('Org.f')}</TableCell>
                                                    <TableCell align="center">{t('Bo`limi')}</TableCell>
                                                    <TableCell align="center">{t('Actions')}</TableCell>
                                                </TableRow>
                                            </TableHeadWrapper>
                                            <TableBody>
                                                {
                                                    MaxsulotlarRoyxariReducer.productTableSearch?.product_list.map((item, index) => {
                                                        const isInvoiceSelected = selectedItems.includes(
                                                            item.productId
                                                        );
                                                        return (
                                                            <TableRow key={index}>
                                                                <TableCell>
                                                                    <Box>
                                                                        <Typography
                                                                            variant="h4">#{(page*rowsPerPage)+index + 1}</Typography>
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Checkbox
                                                                        checked={isInvoiceSelected}
                                                                        // indeterminate={selectedSomeUsers}
                                                                        onChange={(e) =>
                                                                            handleSelectOneInvoice(e, item.productId)
                                                                        }
                                                                        value={isInvoiceSelected}
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Box display="flex" alignItems="center">
                                                                        <Avatar
                                                                            variant="square"
                                                                            sx={{
                                                                                height: 'auto',
                                                                                width: 80
                                                                            }}
                                                                            src={item.photoId ? `${BaseUrl}/attachment/download/${item.photoId}` : ''}
                                                                        />
                                                                        <Box pl={1}>
                                                                            <Typography
                                                                                color="text.primary"
                                                                                underline="none"
                                                                                maxWidth={350}
                                                                                variant="h5"
                                                                                sx={{
                                                                                    '&:hover': {
                                                                                        color: `${theme.colors.primary.main}`

                                                                                    }
                                                                                }}
                                                                            >
                                                                                {item.productName}
                                                                            </Typography>
                                                                            <Typography variant="subtitle2" display={'flex'}
                                                                                        justifyContent={'space-between'}
                                                                                        noWrap>
                                                                                <Typography mr={1}>
                                                                                    {t('block.mi')}:
                                                                                </Typography>
                                                                                <Typography>
                                                                                    <Typography><b>{germanCurrencyFormatter.format(parseFloat(item.amount).toFixed(2))}</b> {item.measurementId}
                                                                                    </Typography>
                                                                                    {
                                                                                        item.subMeasurementName ?
                                                                                            <Typography><b>{item.amount * item.subMeasurementValue}</b> {item.subMeasurementName}
                                                                                            </Typography>
                                                                                            : ''
                                                                                    }

                                                                                </Typography>

                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell align={'start'}>{
                                                                    item.branch?item.branch.map(i=>
                                                                        <p className={'p-0 m-0'} style={{maxWidth:'150px',minWidth:'80px'}}>{i.name}</p>
                                                                    ):''
                                                                }</TableCell>
                                                                <TableCell align="center">
                                                                    <div>
                                                                        {
                                                                            item.amount > item.minQuantity ?
                                                                                <LabelSuccess>{t('pro.Yetarli')}</LabelSuccess> :
                                                                                item.minQuantity >= item.amount && item.amount > 0 ?
                                                                                    <LabelWarning>{t('pro.Ozqolgan')}</LabelWarning> :
                                                                                    <LabelError>{t('pro.Tugagan')}</LabelError>
                                                                        }

                                                                    </div>
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <div>
                                                                        <Typography
                                                                            sx={{
                                                                                pr: 0.5
                                                                            }}
                                                                            component="span"
                                                                            variant="h4"
                                                                            color="text.primary"
                                                                        >
                                                                            {item.buyPrice.toFixed(0)} {t('Hamkorlar.sum')}
                                                                        </Typography>
                                                                    </div>
                                                                    <div>
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: 12,
                                                                                color: "#9a9d99",
                                                                                pr: 0.5
                                                                            }}
                                                                            component="span"
                                                                            variant="h6"
                                                                            color="text.primary"
                                                                        >
                                                                            {item.buyPriceDollar} $
                                                                        </Typography>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <div>
                                                                        <Typography
                                                                            sx={{
                                                                                pr: 0.5
                                                                            }}
                                                                            component="span"
                                                                            variant="h4"
                                                                            color="text.primary"
                                                                        >
                                                                            {item.salePrice.toFixed(0)} {t('Hamkorlar.sum')}
                                                                        </Typography>
                                                                    </div>
                                                                    <div>
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: 12,
                                                                                color: "#9a9d99",
                                                                                pr: 0.5
                                                                            }}
                                                                            component="span"
                                                                            variant="h6"
                                                                            color="text.primary"
                                                                        >
                                                                            {item.salePriceDollar} $
                                                                        </Typography>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <div>
                                                                        <Typography
                                                                            sx={{
                                                                                pr: 0.5
                                                                            }}
                                                                            component="span"
                                                                            variant="h4"
                                                                            color="text.primary"
                                                                        >
                                                                            {item.grossPrice.toFixed(0)} {t('Hamkorlar.sum')}
                                                                        </Typography>
                                                                    </div>
                                                                    <div>
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: 12,
                                                                                color: "#9a9d99",
                                                                                pr: 0.5
                                                                            }}
                                                                            component="span"
                                                                            variant="h6"
                                                                            color="text.primary"
                                                                        >
                                                                            {item.grossPriceDollar} $
                                                                        </Typography>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <div>
                                                                        <Typography
                                                                            sx={{
                                                                                pr: 0.5
                                                                            }}
                                                                            component="span"
                                                                            variant="h4"
                                                                            color="text.primary"
                                                                        >
                                                                            {item.brandName}
                                                                        </Typography>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <div>
                                                                        <Typography
                                                                            sx={{
                                                                                pr: 0.5
                                                                            }}
                                                                            component="span"
                                                                            variant="h4"
                                                                            color="text.primary"
                                                                        >
                                                                            {item.category}
                                                                        </Typography>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell
                                                                    sx={{
                                                                        whiteSpace: 'nowrap'
                                                                    }}
                                                                    align="right"
                                                                >
                                                                    <Box>
                                                                        {
                                                                            users.viewProductAdmin || users.viewProduct ? (
                                                                                <Tooltip title={t('Salary.k')} arrow>
                                                                                    <IconButtonWrapper
                                                                                        onClick={() => korishsh(item.productId)}
                                                                                        sx={{
                                                                                            backgroundColor: `${theme.colors.primary.lighter}`,
                                                                                            color: `${theme.colors.primary.main}`,
                                                                                            transition: `${theme.transitions.create(['all'])}`,
                                                                                            '&:hover': {
                                                                                                backgroundColor: `${theme.colors.primary.main}`,
                                                                                                color: `${theme.palette.getContrastText(
                                                                                                    theme.colors.primary.main
                                                                                                )}`
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <LaunchTwoToneIcon
                                                                                            fontSize="small"/>
                                                                                    </IconButtonWrapper>
                                                                                </Tooltip>) : ''
                                                                        }
                                                                        {
                                                                            users.editProduct ? (
                                                                                <Tooltip title={t('Salarytsh')} arrow>
                                                                                    <IconButtonWrapper
                                                                                        onClick={() => history.push(`/main/addProduct/${item.productId}`)}
                                                                                        sx={{
                                                                                            ml: 1,
                                                                                            backgroundColor: `${theme.colors.primary.lighter}`,
                                                                                            color: `${theme.colors.primary.main}`,
                                                                                            transition: `${theme.transitions.create(['all'])}`,
                                                                                            '&:hover': {
                                                                                                backgroundColor: `${theme.colors.primary.main}`,
                                                                                                color: `${theme.palette.getContrastText(
                                                                                                    theme.colors.primary.main
                                                                                                )}`
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <EditIcon fontSize="small"/>
                                                                                    </IconButtonWrapper>
                                                                                </Tooltip>) : ''
                                                                        }
                                                                        {
                                                                            users.deleteProduct ? (
                                                                                <Tooltip title={t('Buttons.3')} arrow>
                                                                                    <IconButtonWrapper
                                                                                        onClick={() => deleteModaltoggle(item.productId)}
                                                                                        sx={{
                                                                                            ml: 1,
                                                                                            backgroundColor: `${theme.colors.error.lighter}`,
                                                                                            color: `${theme.colors.error.main}`,
                                                                                            transition: `${theme.transitions.create(['all'])}`,
                                                                                            '&:hover': {
                                                                                                backgroundColor: `${theme.colors.error.main}`,
                                                                                                color: `${theme.palette.getContrastText(
                                                                                                    theme.colors.error.main
                                                                                                )}`
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <DeleteTwoToneIcon
                                                                                            fontSize="small"/>
                                                                                    </IconButtonWrapper>
                                                                                </Tooltip>) : ''
                                                                        }
                                                                        <Tooltip title={t('pro.Tarihi')}>
                                                                            <IconButtonWrapper sx={{
                                                                                ml: 1,
                                                                                backgroundColor: `${theme.colors.error.lighter}`,
                                                                                color: `${theme.colors.error.main}`,
                                                                                transition: `${theme.transitions.create(['all'])}`,
                                                                                '&:hover': {
                                                                                    backgroundColor: `${theme.colors.error.main}`,
                                                                                    color: `${theme.palette.getContrastText(
                                                                                        theme.colors.error.main
                                                                                    )}`
                                                                                }
                                                                            }}>
                                                                                <Link
                                                                                    to={'/headerthird/productHistory/' + item.productId}>
                                                                                    <ManageSearchIcon/>
                                                                                </Link>
                                                                            </IconButtonWrapper>
                                                                        </Tooltip>
                                                                    </Box>
                                                                </TableCell>
                                                                <Modal isOpen={deletemodal} toggle={deleteModaltoggle}>
                                                                    <ModalBody>
                                                                        <h5>( X ) {t('Buttons.12')} ?</h5>
                                                                    </ModalBody>
                                                                    <ModalFooter>
                                                                        <button
                                                                            onClick={() => deleteFunc(item.productId)}
                                                                            className={'btn btn-outline-primary'}>{t('Buttons.3')}
                                                                        </button>
                                                                        <button onClick={() => deleteModaltoggle('')}
                                                                                className={'btn btn-outline-primary'}>{t('Buttons.7')}
                                                                        </button>
                                                                    </ModalFooter>
                                                                </Modal>
                                                            </TableRow>
                                                        )
                                                    })
                                                }
                                                <TableRowDivider/>
                                            </TableBody>
                                            {/*</TableWrapper>*/}
                                        </TableContainer>
                                        <Box pt={1} display="flex" justifyContent="space-between">
                                            <div className="print">
                                                <button><img src={CSV} alt=""/> Export CSV</button>
                                                {
                                                    users.getExcel ?
                                                        <a href={`${BaseUrl}/excel/export-to-excel/` + (mainBranchId ? mainBranchId : users.businessId)}
                                                           download>
                                                            <button><img src={Excel} alt="excel"/>Export Excel</button>
                                                        </a> : ''}
                                                <button><img src={Print} alt=""/> Print</button>
                                                <button><img src={Pdf} alt=""/>Export PDF</button>
                                            </div>
                                            <TablePagination
                                                component="div"
                                                count={MaxsulotlarRoyxariReducer?.productTableSearch?.totalItems}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                rowsPerPage={rowsPerPage}
                                                rowsPerPageOptions={[10, 50, 100, 200]}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                        </Box>
                                        {
                                            active ? <KorishM active={active} toggle={toggle} branchId={mainBranchId} productId={productId}/> : ''
                                        }
                                    </Box>
                                    :<div>
                                        <h4 className={'fw-bold text-center'}>{MaxsulotlarRoyxariReducer?.message}</h4>
                                    </div>: <Loading/>
                             : ""
                    }
                </>
            </div>
            <ModalLoading isOpen={saveModal}/>
        </div>
    )
}

export default connect((MaxsulotlarRoyxariReducer, users, FirmaReducer, kgreducer, BolimReducer, branchreducer), {
    getBolim,
    getkg,
    getProductFilter,
    getbranch,
    getFirma,
    deleteMaxsulotRuyxati,
    getMaxsulotById,
    deleteMaxsulotRuyxatiByIds,
    getProductTableSearch,
    getOneProductAbout
})(MaxsulotlarRoyxati)
