import {connect} from "react-redux";
import {useHistory, useLocation} from 'react-router-dom'
import {useTranslation} from "react-i18next";
import {Tabs} from "antd";

import users from "../../../../reducer/users";
import XarajatlarRoyxati from "./xarajatlarRoyxati/XarajatlarRoyxati";
import XarajatTurlari from "./xarajatTurlari/XarajatTurlari";

function Xarajatlar({users}) {
    const {t} = useTranslation()
    const history = useHistory()
    const location = useLocation()

    const items = [
        {
            label: t('Sidebar.26'),
            key: '/main/outlays',
            children: <XarajatlarRoyxati/>,
        },
        {
            label: t('Sidebar.28'),
            key: '/main/outlays/outlayCategoryList',
            children: <XarajatTurlari/>,
        },
    ]

    const onChange = (key) => {
        history.push(key)
    };

    return (
        <div className={'list'} id={'xarajat'}>
            <h4 className={'p-3 mainText'}>{t('Sidebar.25')}</h4>
            <div className={'px-3 pb-5'}>
                <Tabs
                    activeKey={location.pathname}
                    onChange={onChange}
                    type="card"
                    items={items}
                />
            </div>
        </div>
    )
}

export default connect((users),{})(Xarajatlar)
