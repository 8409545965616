import './headerBody.css'
import menu from '../../../assets/text-wrap.svg'
import Logo from '../../../assets/Group 1000004950.svg'
import profile from "../../../assets/Profile.svg"
import notification from "../../../assets/notification.svg"
import Arrow from '../../../img/Arrow.png'
import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {active} from "../../../reducer/functionreducer";
import users, {logOutUser} from "../../../reducer/users";
import {Link} from "react-router-dom";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import ValyutaReducer, {
    getValyuta,
    changeActivecur
} from "../Sidebar/Settings/DukonSozlamalari/reducers/ValyutaReducer";
import {useTranslation} from "react-i18next";
import {BaseUrl} from "../../../middleware";
import notificationReducer, {
    getNotification,
    getNotificationById,
    deleteNotification
} from "../../../reducer/notificationReducer";
import {formatDateMinus, formatDateWithTime} from "../../../util";
import {MdOutlineFiberNew} from "react-icons/md"
import {BsCheckAll} from 'react-icons/bs'
import {ReactCalculator} from "simple-react-calculator";
import OtkazmaReducer, {saveOtkazma} from "../Sidebar/Baza/reducer/OtkazmaReducer";
import ExchangeReducer, {
    editExchanges,
    editExchangesConfirm,
    getExchanges,
    getOneExchanges
} from "../Sidebar/Baza/reducer/ExchangeReducer";
import ModalLoading from "../../ModalLoading";
import { Select, Dropdown } from 'antd';
import uzLang from "../../../assets/uzLang.svg"
import ruLang from "../../../assets/ruLang.svg"
import calculator from "../../../assets/calculator.png"
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import useWindowWidth from "../../Components/useWindowWidth";

function HeaderBody({
                        setCollapsed,
                        deleteNotification,
                        logOutUser,
                        getNotificationById,
                        active,
                        sidebarfunc,
                        notificationReducer,
                        users,
                        getValyuta,
                        ValyutaReducer,
                        changeActivecur,
                        getNotification, editExchangesConfirm,
                        OtkazmaReducer, saveOtkazma, getOneExchanges, ExchangeReducer, editExchanges, getExchanges
                    }) {
    const widthWidth = useWindowWidth()

    useEffect(() => {
        setCalactive(false)
        getValyuta(users.businessId)
        getNotification()
    }, [ValyutaReducer.current])


    const [calactive, setCalactive] = useState(false)

    function calchange() {
        setCalactive(!calactive)
    }

    const [activeN, setactiveN] = useState(false)
    const [activeN2, setactiveN2] = useState(false)

    function toggle2() {
        setactiveN(!activeN)
    }

    function toggle3() {
        setactiveN2(!activeN2)
    }

    function sidebar() {
        active(false)
        // sidebarfunc()
    }


    function valyutactiver(e) {
        changeActivecur({
            businessId: users.businessId,
            id: e.target.value
        })
        getValyuta(users.businessId)
    }

    const {t, i18n} = useTranslation()

    function ChangeLanguage(e) {
        setLang(e)
        localStorage.setItem("appLang", e)
        i18n.changeLanguage(e)
    }

    const [lang, setLang] = useState()

    function isRead(id) {
        getNotificationById(id)
        toggle3()
    }

    useEffect(() => {
        const storageLanguage = localStorage.getItem("appLang")
        setLang(storageLanguage || "uz")
        getExchanges(users.businessId)
    }, [])


    useEffect(() => {
        getNotification()
    }, [notificationReducer.current])

    function DeleteAll() {
        deleteNotification()
    }

    function closeModal() {
        toggle3()
        toggle2()
    }

    function logOut() {
        localStorage.clear();
        sessionStorage.clear();
        logOutUser()
        window.location.reload();
        // history.push('/login')
    }


    const [exchangeArray, setExchangeArray] = useState([])

    useEffect(() => {
        if (ExchangeReducer.exchangeOne) {
            setExchangeArray(ExchangeReducer.exchangeOne?.exchangeProductBranchDTO?.exchangeProductDTOS)
        }
    }, [ExchangeReducer.getBoolean])

    const [saveModal, setSaveModal] = useState(false)
    const [isOpenExchange, setOpenExchange] = useState(false)
    const [message, setMessage] = useState(null)
    const [isRad, setIsRad] = useState(false)
    const [saveExchangeId, setSaveExchangeId] = useState(null)

    function tasdiqlash() {

        let a = ExchangeReducer.exchangeOne
        // saveOtkazma({
        //     shippedBranchId: a?.exchangeProductBranchDTO.shippedBranchId,
        //     receivedBranchId: a?.exchangeProductBranchDTO.receivedBranchId,
        //     exchangeDate: formatDateMinus(a?.exchangeProductBranchDTO.exchangeDate),
        //     description: a?.exchangeProductBranchDTO.description,
        //     exchangeStatusId: a?.exchangeProductBranchDTO.exchangeStatusId,
        //     exchangeProductDTOS: exchangeArray,
        //     businessId: users.businessId
        // })

        editExchangesConfirm({
            confirmation: true,
            message: '',
            userId: users.id,
            id: saveExchangeId
        })

        setSaveModal(true)
    }

    function radEtish() {
        // ExchangeReducer.exchanges.map(item => {
        //     if (item.id === saveExchangeId) {
        //         editExchanges({
        //             carId: item.carId,
        //             confirmation: false,
        //             exchangeProductBranchDTO: {
        //                 businessId: users.businessId,
        //                 description: item.exchangeProductBranchDTO.description,
        //                 exchangeDate: formatDateMinus(item.exchangeProductBranchDTO.exchangeDate),
        //                 exchangeProductDTOS: exchangeArray,
        //                 exchangeStatusId: item.exchangeProductBranchDTO.exchangeStatusId,
        //                 receivedBranchId: item.exchangeProductBranchDTO.receivedBranchId,
        //                 shippedBranchId: item.exchangeProductBranchDTO.shippedBranchId,
        //             },
        //             id: saveExchangeId,
        //             message,
        //             receivedBranchName: item.receivedBranchName,
        //             shippedBranchName: item.shippedBranchName,
        //             userBranchId: item.exchangeProductBranchDTO.shippedBranchId
        //         })
        //     }
        // })

        editExchangesConfirm({
            confirmation: false,
            message,
            userId: users.id,
            id: saveExchangeId
        })
    }


    useEffect(() => {
        if (ExchangeReducer.saveBoolean) {
            setIsRad(false)
            setMessage(null)
            // getExchanges(users.businessId)
            setOpenExchange(false)
            setSaveExchangeId(null)
            setactiveN(false)
            setactiveN2(false)
        }
        setSaveModal(false)
    }, [ExchangeReducer.current])

    useEffect(() => {
        if (OtkazmaReducer.saveExchangeBool) {
            setOpenExchange(false)
            setactiveN(false)
            setactiveN2(false)
        }
        setSaveModal(false)
    }, [OtkazmaReducer.counter])

    const items = [
        {
            label: (
                <Link to={`/main/profil`}>
                    <div>{t('tes.55')}</div>
                </Link>
            ),
            key: '0',
        },
        {
            label: (
                <Link to={`/main/profil/edit`}>
                    <div>{t('tes.56')}</div>
                </Link>
            ),
            key: '1',
        },
        {
            type: 'divider',
        },
        {
            label: <div onClick={logOut}>{t('tes.46')}</div>,
            key: '3',
            danger: true
        },
    ];

    return (
        <div className={'headerBody'}>
            <div className={'d-flex gap-lg-5 gap-3 justify-content-between align-items-center'} onClick={sidebar}>
                <div className="d-flex gap-2 justify-content-between align-items-center">
                    <img src={Logo} className={'headerBodyLogo'} alt="logo"/>
                    {
                      widthWidth >= 768 && <h4 className={'headerBodyBrandName'}>SOF Finance</h4>
                    }
                </div>
                <div className="d-flex gap-2 justify-content-between align-items-center">
                    <img src={menu} width={20} height={20} style={{cursor: 'pointer'}} onClick={setCollapsed}
                         alt="logo"/>
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-center gap-2 gap-md-4">
                <Select
                    style={{width: widthWidth >= 768 ? 150 : 70}}
                    size={"large"}
                    onChange={ChangeLanguage}
                    value={lang}
                    options={[
                        {
                            value: 'uz',
                            label: <div className="d-flex align-items-center gap-2">
                                {
                                    widthWidth >= 768 ?  <>
                                        <img src={uzLang} alt="uz"/>
                                        <span>O'zbekcha</span>
                                    </> : <span>O'z</span>
                                }
                            </div>,
                        },
                        {
                            value: 'ki',
                            label: <div className="d-flex align-items-center gap-2">
                                {
                                    widthWidth >= 768 ? <>
                                        <img src={uzLang} alt="kr"/>
                                        <span>Ўзбекча</span>
                                    </> : <span>Ўз</span>
                                }

                            </div>,
                        },
                        {
                            value: 'ru',
                            label: <div className="d-flex align-items-center gap-2">
                                {
                                    widthWidth >= 768 ? <>
                                        <img src={ruLang} alt="ru"/>
                                        <span>Русский</span>
                                    </> : <span>Ру</span>
                                }
                            </div>,
                        },
                        {
                            value: 'en',
                            label: <div className="d-flex align-items-center gap-2">
                                {
                                    widthWidth >= 768 ? <>
                                        <img src={ruLang} alt="ru"/>
                                        <span>English</span>
                                    </> : <span>En</span>
                                }
                            </div>,
                        },
                    ]}
                />
                {
                  widthWidth >= 768 && <div>
                      <img src={calculator} width={32} height={32} style={{cursor: 'pointer'}} onClick={calchange}
                           alt="calculator"/>
                      <div>
                          {
                              calactive ?
                                <div className={'c'}>
                                    <ReactCalculator toggle={calchange}/>
                                </div>
                                : null
                          }
                      </div>
                  </div>
                }
                <div onClick={toggle2} className="d-flex justify-content-center align-items-center gap-1 gap-md-2">
                    <img src={notification} alt="notification"/>
                    <div className="notificationBox">
                        {
                            notificationReducer.notification ?
                              notificationReducer.notification?.filter(item => item.read === false).length > 0 ?
                                <p className={'notificationNumber'}>{notificationReducer.notification.filter(item => item.read === false)?.length}</p>
                                : '' : <p className={'notificationNumber'}>0</p>
                    }
                    </div>
                </div>
                <div className="userDrapdown">
                    <Dropdown
                        menu={{
                            items,
                            style: {width: "140px"}
                        }}
                        trigger={['click']}
                        className="cursor-pointer"
                    >
                        <div className="d-flex gap-2 align-items-center justify-content-center">
                            <div width={34} height={34}>
                                <img width={34} height={34} style={{cursor:"pointer"}} className={'img-fluid rounded-3'}
                                     src={users.users.photo ? `${BaseUrl}/attachment/download/${users.users?.photo?.id}` : profile}
                                     alt={users.users?.photo?.id}/>
                            </div>
                            {
                              widthWidth >= 768 && <div>
                                  <p
                                    className={'header-profile cursor-pointer'}>{`${users.users?.lastName} ${users.users?.firstName}`}</p>
                                  <p className={'header-role cursor-pointer'}>{users.users?.role?.name}</p>
                              </div>
                            }
                        </div>
                    </Dropdown>
                </div>
            </div>
            <Modal toggle={toggle2} isOpen={activeN}>
                <ModalHeader>
                    <h2>{t('tes.45')}</h2>
                </ModalHeader>
                <ModalBody style={{maxHeight: '300px', minHeight: '300px', overflowY: 'scroll'}}>
                    {
                        notificationReducer.notification ?
                            notificationReducer.notification.map(item =>
                                <button onClick={() => isRead(item.id)} style={{width: '100%'}}
                                        className={'btn notification-btn mb-2 d-flex justify-content-between align-items-center'}>
                                    <p className={'p-0 m-0 notification-text'}>{item.name}</p>
                                    <p className={'p-0 m-0'}>{formatDateWithTime(item?.createdAt)}</p>
                                    {
                                        !item.read ?
                                            <MdOutlineFiberNew className={'notification-icon'}/> :
                                            <BsCheckAll className={'notification-icon2'}/>
                                    }
                                </button>
                            ) : <div className={'text-center'}>
                                <h4>{notificationReducer.message}</h4>
                            </div>

                    }
                </ModalBody>
                <ModalFooter>
                    <button className={'btn btn-danger'} onClick={deleteNotification}>Clear</button>
                    <button className={'btn btn-outline-primary'}
                            onClick={toggle2}>{t('tes.46')}</button>
                </ModalFooter>
            </Modal>
            <Modal toggle={toggle3} isOpen={activeN2}>
                <ModalHeader>
                    {
                        notificationReducer.OneNotification ?
                            notificationReducer.OneNotification.name : ''
                    }
                    {}                                    </ModalHeader>
                <ModalBody>
                    {
                        notificationReducer.OneNotification ?
                            <div>
                                <p className={'text-dark fw-bold '}>
                                    {notificationReducer.OneNotification?.message}
                                </p>
                                <div className={'d-flex justify-content-center'}>
                                    {
                                        notificationReducer.OneNotification?.type === 'NEW_TASK' ?
                                            <Link to={'/headerthird/tasks'}>
                                                <button onClick={closeModal}
                                                        className={"btn btn-primary"}> {t('tes.47')}
                                                </button>
                                            </Link> :
                                            notificationReducer.OneNotification?.type === 'NEW_PROJECT' ?
                                                <Link to={'/headerthird/projects'}>
                                                    <button onClick={closeModal}
                                                            className={"btn btn-primary"}> {t('tes.48')}
                                                    </button>
                                                </Link> :
                                                notificationReducer.OneNotification?.type === 'NEW_LID' ?
                                                    <Link to={'/headerthird/lidlar'}>
                                                        <button onClick={closeModal}
                                                                className={"btn btn-primary"}> {t('tes.49')}
                                                        </button>
                                                    </Link> :
                                                    notificationReducer.OneNotification?.type === 'NEW_EXCHANGE_PRODUCT' ?
                                                        users.branches.map(item => item.id !== notificationReducer.OneNotification?.branchId) ?
                                                            // notificationReducer.OneNotification?.branchId !== users.branchId?
                                                            <Link
                                                                to={'/headerthird/exchangeReport/' + notificationReducer.OneNotification?.objectId}>
                                                                <button
                                                                    className={'btn btn-primary'}
                                                                    onClick={closeModal}> {t('tes.50')}
                                                                </button>
                                                            </Link> : '' :
                                                        notificationReducer.OneNotification?.type === 'REJECTION' ?
                                                            <Link
                                                                to={'/headerthird/exchangeReport/' + notificationReducer.OneNotification?.objectId}>
                                                                <button
                                                                    className={'btn btn-primary'}
                                                                    onClick={closeModal}> {t('tes.51')}
                                                                </button>
                                                            </Link> :
                                                            notificationReducer.OneNotification?.type === 'CONFIRMED' ?
                                                                <button
                                                                    className={'btn btn-primary'}
                                                                    onClick={() => {
                                                                        setOpenExchange(!isOpenExchange)
                                                                        getOneExchanges(notificationReducer.OneNotification?.type === 'CONFIRMED' ?
                                                                            notificationReducer.OneNotification?.objectId : ''
                                                                        )
                                                                        setSaveExchangeId(
                                                                            notificationReducer.OneNotification?.type === 'CONFIRMED' ?
                                                                                notificationReducer.OneNotification?.objectId : null
                                                                        )
                                                                    }}>{t('tes.52')}</button>
                                                                :
                                                                // <Link to={'/headerthird/exchangeReport'}>
                                                                //     <button className={'btn btn-primary'} onClick={closeModal}>Filialdan filialga</button>
                                                                // </Link>:
                                                                notificationReducer.OneNotification?.type === 'NOTIFICATION' ?
                                                                    <Modal>
                                                                        <ModalHeader>
                                                                            <h4>{t('tes.52')}</h4>
                                                                        </ModalHeader>
                                                                        <ModalBody>
                                                                            <div className="row">
                                                                                <div
                                                                                    className="col-md-8 offse-2"></div>
                                                                            </div>
                                                                        </ModalBody>
                                                                        <ModalFooter>
                                                                            <button
                                                                                className={'btn btn-primary'}>{t('tes.46')}
                                                                            </button>
                                                                        </ModalFooter>
                                                                    </Modal> :
                                                                    notificationReducer.OneNotification?.type === 'LESS_PRODUCT' ?
                                                                        <Link
                                                                            to={'/headerthird/mahsulotRuyxati'}>
                                                                            <button
                                                                                onClick={closeModal}
                                                                                className={'btn btn-primary'}> {t('tes.53')}
                                                                            </button>
                                                                        </Link> :
                                                                        <Link
                                                                            to={'/headerthird/superadmin/packagesubscription'}>
                                                                            <button
                                                                                onClick={closeModal}
                                                                                className={"btn btn-primary"}> {t('tes.54')}
                                                                            </button>
                                                                        </Link>
                                    }

                                </div>

                            </div> : notificationReducer.message
                    }

                </ModalBody>
                <ModalFooter>
                    <button className={'btn btn-outline-primary'}
                            onClick={toggle3}>{t('tes.46')}</button>
                </ModalFooter>
            </Modal>

            <Modal size={'xl'} isOpen={isOpenExchange} toggle={() => setOpenExchange(!isOpenExchange)}>
                <ModalHeader>
                    <h5>{t('tes.57')}</h5>
                </ModalHeader>
                <ModalBody>
                    <table className={'table table-hover table-striped'}>
                        <thead>
                        <tr>
                            <th>{t('tes.57')}</th>
                            <th>{t('tes.58')} <b>{t('tes.59')}</b></th>
                            <th>{t('tes.60')}</th>
                            <th>{t('tes.61')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            exchangeArray ?
                                <tr>
                                    <td>{exchangeArray.map((item, index) =>
                                        <div key={index}>
                                            <td>{item.productTypePriceName ? item.productTypePriceName : item.productName}</td>
                                        </div>)}</td>
                                    <td>{exchangeArray.map((item, index) =>
                                        <div key={index}>
                                            <td><input disabled={true}
                                                       type="number" className={'form-control'}
                                                       value={item.exchangeProductQuantity}
                                                // onChange={(e)=>changeExchangeQuantity(e.target.value,index)}
                                            />
                                            </td>
                                            <td>{item.measurementProductTypePriceName ? item.measurementProductTypePriceName : item?.measurementProductName}</td>
                                        </div>)}</td>
                                    <td>{ExchangeReducer.exchangeOne?.exchangeProductBranchDTO.description}</td>
                                    <td>{formatDateWithTime(ExchangeReducer.exchangeOne?.exchangeProductBranchDTO.exchangeDate)}</td>

                                </tr> : ''

                        }
                        </tbody>
                    </table>
                </ModalBody>
                <ModalFooter>
                    <button onClick={tasdiqlash} className={'btn btn-primary'}>{t('tes.62')}</button>
                    <button onClick={() => setIsRad(!isRad)} className={'btn btn-primary'}>{t('tes.63')}</button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={isRad} toggle={() => setIsRad(!isRad)}>
                <ModalHeader>
                    <h4>{t('tes.63')}</h4>
                </ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-md-12">
                            <label htmlFor="">{t('tes.64')}</label>
                            <input type="text" className={'form-control'} value={message}
                                   onChange={(e) => setMessage(e.target.value)}/>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button onClick={radEtish} disabled={message ? false : true}
                            className={'btn btn-primary'}>{t('tes.65')}</button>
                    <button onClick={() => setIsRad(!isRad)} className={'btn btn-primary'}>{t('tes.46')}</button>
                </ModalFooter>
            </Modal>

            <ModalLoading isOpen={saveModal}/>
        </div>
    )
}

export default connect((users, ValyutaReducer, notificationReducer, OtkazmaReducer, ExchangeReducer), {
    logOutUser,
    deleteNotification,
    active,
    getValyuta,
    changeActivecur,
    getNotification,
    getNotificationById,
    saveOtkazma, getOneExchanges, editExchanges, getExchanges, editExchangesConfirm
})(HeaderBody)
