import React, {useEffect, useState} from 'react'
import './packagesubscription.css'
import Excel from '../../../../../../img/Excel.png'
import {connect} from "react-redux";
import subscripreducer, {getByBusinessSubscription} from "../../reducers/subscripreducer";
import formatDate from "../../../../../../util";
import {useTranslation} from "react-i18next";
import CommonTable from "../../../../../Components/CommonTable";
import moment from "moment/moment";


function PackageSubscripton({subscripreducer,users,getByBusinessSubscription,match}) {
    const {t} = useTranslation()



    const [page, setPage] = useState(0);
    const [size, setSize] = useState(5);
    const handlePageChange = (newPage) => {
        setPage(newPage - 1);
    };
    const handleLimitChange = (event, size) => {
        setPage(0)
        setSize(size);
    };


    const columns = [
        {
            title: 'Id',
            dataIndex: 'index',
            rowScope: 'row',
            width: '2%',
        },
        {
            title: 'Biznes nomi',
            dataIndex: 'businessName',
            key: 'businessName',
            width: '15%'
        },
        {
            title: 'Tariff',
            dataIndex: 'tariffName',
            key: 'tariffName',
            width:'10%'
        },
        {
            title: 'Boshlangan sana',
            dataIndex: 'startDay',
            key: 'startDay',
            render: (item) => <p className={'m-0'}>{moment(new Date(item)).format('lll')}</p>,
            width:'10%'
        },
        {
            title: 'Tugagan sana',
            dataIndex: 'endDay',
            key: 'endDay',
            render: (item) => <p className={'m-0'}>{moment(new Date(item)).format('lll')}</p>,
            width:'10%'
        },
        {
            title: 'Tariff Narxi',
            dataIndex: 'tariffPrice',
            key: 'tariffPrice',
            render: (item) => <p className={`p-0 mt-1`}>{item} so'm</p>,
            width:'10%',
        },
    ];

    useEffect(()=>{
        getByBusinessSubscription({
            id:match.params.id,
            params:{
                size,page
            }
        })
    },[subscripreducer.current,page,size])

  return (

    <div className="rowStylePageS">

                <div className="izlashPageS">
                        <button><img src={Excel} alt="" /> Export Excel</button>
                </div>
                <div className="table-responsive table-wrapper-scroll-y my-custom-scrollbar mt-3">
                    <CommonTable size={size} page={page} handleLimitChange={handleLimitChange}
                                 handlePageChange={handlePageChange}
                                 total={subscripreducer.subscription?.totalItems}
                                 pagination={true} data={subscripreducer.subscription?.allBalanceHistory}
                                 columns={columns}
                    />
                </div>

            </div>
  )
}
export default connect((subscripreducer), {getByBusinessSubscription})(PackageSubscripton)
