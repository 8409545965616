import {connect} from "react-redux";
import {useEffect} from "react";
import FoydaZararReducer from "../../reducer/FoydaZararReducer";
import users from "../../../../../../reducer/users";
import branchreducer, {getbranch} from "../../../../../../reducer/branchreducer";
import Reducer, {getFoydaFirma, getFoydaFirma2} from "./reducer/Reducer";
import {useTranslation} from "react-i18next";
import CommonTable from "../../../../../Components/CommonTable";
import {prettify} from "../../../../../../util";

function Firma({users, Reducer, getFoydaFirma, branchId, data, dates, getFoydaFirma2,course,courseName}) {
    const {t} = useTranslation();

    const columns = [
        {
            title: 'Id',
            dataIndex: 'index',
            width: '3%',
        },
        {
            title: t('block.m'),
            dataIndex: 'categoryName',
            key: 'categoryName',
            width: '5%',

        },
        {
            title: t('xisob.Foyda'),
            dataIndex: 'profit',
            key: 'profit',
            render: (item) => <p className={'m-0'}>{prettify(item)} so'm</p>,
            width: '10%',

        },
    ];


    useEffect(() => {
        if (data) {
            getFoydaFirma({
                branchId: branchId ? branchId : users.branchId,
                date: data
            })
        } else if (dates) {
            getFoydaFirma2({
                branchId: branchId ? branchId : users.branchId,
                startDate: dates[0],
                endDate: dates[1]
            })
        } else {
            getFoydaFirma({
                branchId: branchId ? branchId : users.branchId,
                date: data
            })
        }

    }, [])

    return (
        <>
            {
                Reducer.foydaFirma ?
                        <CommonTable size={0} page={Reducer.foydaFirma?.length}
                                     pagination={false} data={Reducer.foydaFirma}
                                     columns={columns}
                        />
                    : <div className={'mt-2'}>
                    <h4 className={'text-center'}>{Reducer.message}</h4>
                    </div>
            }
        </>
    )
}

export default connect((FoydaZararReducer, Reducer, users, branchreducer), {
    getbranch, getFoydaFirma, getFoydaFirma2
})(Firma)
