import {lazy} from 'react';
import './headerthird.css'
import Lavozimlar from "./Sidebar/Hodimlar/Lavozimlar/Lavozimlar";
import Taxrirlash from "./Sidebar/Hodimlar/hodimlarRoyxati/Taxrirlash/Taxrirlash";
import Taminotchilar from "./Sidebar/Hamkorlar/taminotchilar/Taminotchilar";
import MahsulotImport from "./Sidebar/Maxsulotlar/MahsulotlarImporti/MahsulotImport";
import MaxsulotlarRoyxati from "./Sidebar/Maxsulotlar/MahsulotlarRuyxati/MaxsulotlarRoyxati";
import MahsulotTurlari from "./Sidebar/Maxsulotlar/MahsulotTurlari/MahsulotTurlari";
import Bolimlar from "./Sidebar/Maxsulotlar/bolimlar/Bolimlar";
import Firmalar from "./Sidebar/Maxsulotlar/firmalar/Firmalar";
import Xarid from "./Sidebar/Haridlar/Xarid qilish/Xarid";
import HaridlarRoyxati from "./Sidebar/Haridlar/haridlarRoyxati/HaridlarRoyxati";
import BarchaSavdolar from "./Sidebar/Savdo/BarcaSavdolar/BarchaSavdolar";
import SavdoQoshish from "./Sidebar/Savdo/SavdoQoshish/SavdoQoshish";
import XarajatQoshish from "./Sidebar/Xarajatlar/Qoshish/XarajatQoshish";
import XarajatTurlari from "./Sidebar/Xarajatlar/xarajatTurlari/XarajatTurlari";
import XaridlarXisoboti from "./Sidebar/Xisobotlar/XaridlarXisoboti/XaridlarXisoboti";
import MijozlarXisoboti from "./Sidebar/Xisobotlar/MijozlarXisoboti/MijozlarXisoboti";
import MaxsulotXisoboti from "./Sidebar/Xisobotlar/MaxsulotlarXisoboti/MaxsulotXisoboti";
import SavdodaTulov from "./Sidebar/Xisobotlar/SavdodaQilinganTulov/SavdodaTulov";
import QoldiqlarXisoboti from "./Sidebar/Xisobotlar/qoldiqlarXisoboti/QoldiqlarXisoboti";
import Dukon from "./Sidebar/Settings/DukonSozlamalari/Dukon";
import Bazalar from "./Sidebar/Settings/bazalar/Bazalar";
import SuperAdmin from "./Sidebar/SUPERADMIN/SuperAdmin";
import Balance from "./Sidebar/Savdo/Balance/Balance";
import BalanceHistory from "./Sidebar/Savdo/Balance/BalanceHistory";
import MaxsulotMiqdoriQoldigi from "./Sidebar/Xisobotlar/MahsulotMiqdoriQoldigi/MaxsulotMiqdoriQoldigi";
import LossProducts from "./Sidebar/Savdo/Yoqotilgan Mahsulotlar/LossProducts";
import XodimlarNazorati from "./Sidebar/Xisobotlar/XodimlarNazorati/XodimlarNazorati";
import Maxsulotlar from "./Sidebar/Maxsulotlar/Maxsulotlar";
import HR from "./Sidebar/HR/HR";
import Vazifalar from "./Sidebar/Vazifalar/Vazifalar";
import Production from "./Sidebar/Production/Production";
import Haridlar from "./Sidebar/Haridlar/Haridlar";
import Savdo from "./Sidebar/Savdo/Savdo";
import Baza from "./Sidebar/Baza/Baza";
import Xirsobotlar from "./Sidebar/Xisobotlar/Xirsobotlar";
import AddContent from "./Sidebar/Production/AddContent/AddContent";
import AddProduction from "./Sidebar/Production/AddProduction/AddProduction";
import Sozlamalar from "./Sidebar/Settings/Sozlamalar";
import MijozProfili from "./Sidebar/Hamkorlar/MijozProfili/MijozProfili";
import RemainProductMore from "./Sidebar/Xisobotlar/MahsulotMiqdoriQoldigi/RemainProductMore";
import Xarajatlar from "./Sidebar/Xarajatlar/Xarajatlar";
import Darsliklar from "./Sidebar/Darsliklar/Darsliklar";
import QuizStart from "./Sidebar/Darsliklar/QuizStart/QuizStart";
import PackageSubscripton from "./Sidebar/SUPERADMIN/pages/package-subscription/PackageSubscripton";
import SupplierProfile from "./Sidebar/Hamkorlar/taminotchilar/Korish/SupplierProfile";
import MijozGuruhlari from "./Sidebar/Hamkorlar/MijozGuruhlari/MijozGuruhlari";
import MijozlarGuruxi from "./Sidebar/Hamkorlar/MijozGurux/MijozlarGuruxi";
import Lidlar from "./Sidebar/Lidlar/Lidlar";

const Taxrirlash2 = lazy(() => import('./Sidebar/Hodimlar/Lavozimlar/Taxrirlash/Taxrirlash'))
const Taxrirlash3 = lazy(() => import('./Sidebar/Maxsulotlar/MahsulotlarRuyxati/Taxrirlash/Taxrirlash'))
const ShtrixCode = lazy(() => import("./Sidebar/Maxsulotlar/ShtrixCode/ShrtixCode"))


export const routes = [
    {path: "superadmin", component: SuperAdmin, permissions: ['ADD_BUSINESS']},
    {path: "business/:id", component: PackageSubscripton, permissions: ['ADD_BUSINESS']},
    // {path: "user",component:Hodimlar,permissions:['ADD_USER','GET_USER','GET_USER_ADMIN']},
    {path: "role", component: Lavozimlar, permissions: ['ADD_ROLE', 'GET_ROLE']},
    // {path: "partner",component:Hamkorlar,permissions:['ADD_SUPPLIER','GET_SUPPLIER']},
    {
        path: "hr",
        component: HR,
        permissions: ['ADD_SUPPLIER', 'GET_SUPPLIER', 'ADD_USER', 'GET_USER', 'GET_USER_ADMIN']
    },
    {
        path: "lid",
        component: Lidlar,
        permissions: ['ADD_SUPPLIER', 'GET_SUPPLIER', 'ADD_USER', 'GET_USER', 'GET_USER_ADMIN']
    },
    {
        path: "lid/",
        component: Lidlar,
        permissions: ['ADD_SUPPLIER', 'GET_SUPPLIER', 'ADD_USER', 'GET_USER', 'GET_USER_ADMIN']
    },
    // {path: "addHrRole",component:AddRole,permissions:['ADD_SUPPLIER','GET_SUPPLIER']},
    {
        path: "tasks", component: Vazifalar, permissions: [
            'DELETE_TASK',
            'GET_TASK',
            'EDIT_TASK',
            'ADD_TASK']
    },
    {
        path: "production/add", component: AddProduction, permissions: [
            'CREATE_PRODUCTION',
            'GET_PRODUCTION',]
    },
    {
        path: "production/addContent/:productId?/:productTypePriceId?/:name/:measurement/:contentId?/:branchId",
        component: AddContent,
        permissions: ['CREATE_CONTENT',
            'EDIT_CONTENT',]
    },
    {path: "production/addContent/:id/:branchId", component: AddContent, permissions: ['CREATE_CONTENT',
            'EDIT_CONTENT',]},
    {path: "production", component: Production, permissions: [ 'CREATE_PRODUCTION',
            'GET_PRODUCTION',]},
    {path: "purchase/add/:id?", component: Xarid, permissions: ['ADD_PURCHASE', 'GET_PURCHASE', 'GET_PURCHASE_ADMIN']},
    // {path: "purchase/add",component:Xarid,permissions:['ADD_PURCHASE']},
    {
        path: "purchase",
        component: Haridlar,
        permissions: ['ADD_PURCHASE', 'GET_PURCHASE', 'GET_PURCHASE_ADMIN', 'ADD_SUPPLIER', 'GET_SUPPLIER']
    },
    {path: "trade", component: Savdo, permissions: ['ADD_TRADE', 'GET_TRADE', 'GET_TRADE_ADMIN']},
    {path: "trade/customerGroup", component: MijozGuruhlari, permissions: [ 'ADD_CUSTOMER_GROUP',
            'DELETE_CUSTOMER_GROUP',
            'EDIT_CUSTOMER_GROUP',
            'VIEW_CUSTOMER_GROUP',]},
    {
        path: "trade/addLossProducts/:id?",
        component: SavdoQoshish,
        permissions: ['ADD_LOSS', 'GET_LOSS', 'GET_LOSS_ADMIN']
    },
    {path: "trade/addLossProducts", component: SavdoQoshish, permissions: ['ADD_LOSS', 'GET_LOSS', 'GET_LOSS_ADMIN']},
    {path: "trade/lossProducts", component: LossProducts, permissions: ['GET_LOSS', 'GET_LOSS_ADMIN']},
    {
        path: "trade/customer",
        component: MijozlarGuruxi,
        permissions: ['ADD_CUSTOMER', 'GET_CUSTOMER', 'GET_CUSTOMER_ADMIN']
    },
    {path: "trade/balance", component: Balance, permissions: ['ADD_TRADE']},
    {path: "exchange", component: Baza, permissions: [ 'ADD_EXCHANGE',
            'EDIT_EXCHANGE',
            'VIEW_EXCHANGE',
            'VIEW_EXCHANGE_ADMIN',]},
    {path: "reports", component: Xirsobotlar, permissions: ['VIEW_REPORT']},
    {path: "settings", component: Sozlamalar, permissions: [ 'VIEW_INVOICE',
            'EDIT_INVOICE',
            'ADD_BRANCH',
            'EDIT_BRANCH',
            'VIEW_BRANCH_ADMIN',
            'VIEW_BRANCH',
            'DELETE_BRANCH',]},
    {path: "customerProfile/:id", component: MijozProfili, permissions: ['VIEW_CUSTOMER']},
    {path: "supplierProfile/:id", component: SupplierProfile, permissions: ['VIEW_SUPPLIER']},
    {path: "productRemains/:id/:name/:measurement", component: RemainProductMore, permissions: ['ADD_TRADE',
            'EDIT_TRADE',]},
    {path: "outlays", component: Xarajatlar, permissions: ['ADD_OUTLAY',
            'EDIT_OUTLAY',
            'VIEW_OUTLAY',
            'VIEW_OUTLAY_ADMIN',
            'DELETE_OUTLAY',]},
    {path: "outlaysAdd/:id?", component: XarajatQoshish, permissions: ['ADD_OUTLAY', 'GET_OUTLAY', 'GET_OUTLAY_ADMIN']},
    {path: "outlaysAdd", component: XarajatQoshish, permissions: ['ADD_OUTLAY']},
    {path: "outlays/outlayCategoryList", component: XarajatTurlari, permissions: ['ADD_OUTLAY', 'GET_OUTLAY']},
    {path: "lessons", component: Darsliklar, permissions: ['VIEW_LESSON',
            'VIEW_LESSON_ROLE']},
    {path: "lessons/quizstart/:testId", component: QuizStart, permissions: ['VIEW_LESSON',
            'VIEW_LESSON_ROLE',
            'EDIT_LESSON',
            'DELETE_LESSON']},


    {path: "addUser/:id?", component: Taxrirlash, permissions: ['ADD_USER', 'GET_USER', 'GET_USER_ADMIN']},
    {path: "addUser", component: Taxrirlash, permissions: ['ADD_USER']},

    {path: "addRole/:id?", component: Taxrirlash2, permissions: ['ADD_ROLE', 'GET_ROLE']},
    {path: "addRole", component: Taxrirlash2, permissions: ['ADD_ROLE']},
    // {path: "balanceTable",component:BalanceTableAt,permissions:['EDIT_BALANCE', 'GET_BALANCE','GET_BALANCE_ADMIN',]},
    {path: "balanceHistory", component: BalanceHistory, permissions: ['GET_BALANCE', 'GET_BALANCE_ADMIN',]},
    {path: "supplier", component: Taminotchilar, permissions: ['ADD_SUPPLIER', 'GET_SUPPLIER']},
    {path: "addProduct/:id", component: Taxrirlash3, permissions: ['ADD_PRODUCT', 'GET_PRODUCT', 'GET_PRODUCT_ADMIN']},
    {path: "product", component: Maxsulotlar, permissions: ['ADD_PRODUCT', 'GET_PRODUCT', 'GET_PRODUCT_ADMIN']},
    {path: "addProduct", component: Taxrirlash3, permissions: ['ADD_PRODUCT']},
    {path: "importProduct", component: MahsulotImport, permissions: ['ADD_PRODUCT']},
    {
        path: "productList",
        component: MaxsulotlarRoyxati,
        permissions: ['ADD_PRODUCT', 'GET_PRODUCT', 'GET_PRODUCT_ADMIN']
    },
    {path: "productType", component: MahsulotTurlari, permissions: ['ALL_TYPE']},
    {path: "category", component: Bolimlar, permissions: ['ALL_CATEGORY']},
    // {path: "measurements",component:Measurement,permissions:['ALL_MEASUREMENT']},
    {path: "brand", component: Firmalar, permissions: ['ALL_BRAND']},
    {path: "barcode", component: ShtrixCode, permissions: ['GET_PRODUCT']},
    {
        path: "addContent/:productId?/:productTypePriceId?/:name/:measurement/:contentId?/:branchId",
        component: AddContent,
        permissions: ['CREATE_CONTENT',
            'EDIT_CONTENT',
            'GET_CONTENT',
            'DELETE_CONTENT',]
    },
    {path: "addContent/:id/:branchId", component: AddContent, permissions: ['CREATE_CONTENT',
            'EDIT_CONTENT',
            'GET_CONTENT',
            'DELETE_CONTENT',]},
    // {path: "addContent",component:Xarid,permissions:['ADD_PURCHASE']},
    {
        path: "purchaseList",
        component: HaridlarRoyxati,
        permissions: ['ADD_PURCHASE', 'GET_PURCHASE', 'GET_PURCHASE_ADMIN']
    },
    {path: "tradeList", component: BarchaSavdolar, permissions: ['ADD_TRADE', 'GET_TRADE', 'GET_TRADE_ADMIN']},

    {path: "productsReport", component: MaxsulotXisoboti, permissions: ['VIEW_REPORT']},
    {path: "lostProductsReport", component: MaxsulotMiqdoriQoldigi, permissions: ['VIEW_REPORT']},
    {path: "tradesReport", component: SavdodaTulov, permissions: ['VIEW_REPORT']},
    {path: "purchasesReport", component: XaridlarXisoboti, permissions: ['VIEW_REPORT']},
    {path: "customersReport", component: MijozlarXisoboti, permissions: ['VIEW_REPORT']},
    {path: "usersReport", component: XodimlarNazorati, permissions: ['VIEW_REPORT']},
    // {path: "suppliersReport",component:SupplierReport,permissions:['GET_INFO_ADMIN','GET_INFO']},
    {path: "remainProductReport", component: QoldiqlarXisoboti, permissions: ['VIEW_REPORT']},
    {path: "shopSetting", component: Dukon, permissions: ['EDIT_INVOICE', 'EDIT_MY_BUSINESS']},
    {path: "branches", component: Bazalar, permissions: ['ADD_BRANCH', 'GET_BRANCH']},
]

