import { useState, useCallback } from 'react';
import { Grid } from '@mui/material';
import PageTitleWrapper from '../PageTitleWrapper';
import PageHeader from './PageHeader';
import Results from './Results';
function ManagementProjects() {
    const [mainBranchId,setMainBranchId] = useState(null)
    const [editId,setId] = useState(null)
    const [sent,setSent] = useState(false)
    const [open, setOpen] = useState(false)
    function add(){}
    function getId(id){
        setId(id)
        setSent(prevState => !prevState)
        setOpen(!open)
    }
    const changeEdit = useCallback(add,[sent])

  return (
    <>
        <title>Projects - Management</title>
      <PageTitleWrapper>
        <PageHeader updateId={editId} setId={setId} currentEdit={changeEdit} setOpen={setOpen} open={open}
                    mainBranchId={mainBranchId}
                    setMainBranchId={setMainBranchId}
        />
      </PageTitleWrapper>
      <Grid
        sx={{px: 4}} container direction="row" justifyContent="center" alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          <Results getId={getId}   mainBranchId={mainBranchId}
                   setMainBranchId={setMainBranchId}/>
        </Grid>
      </Grid>
    </>
  );
}

export default ManagementProjects;
