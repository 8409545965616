import '../Hodimlar/hodimlar.css'
import {Link, NavLink, useHistory, useLocation} from 'react-router-dom'
import {useState, useEffect} from 'react'
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import users from "../../../../reducer/users";
import Attendance from "../HR/Davomad/Attendance";
import Jobs from "../HR/Jobs/Jobs";
import Salary from "../HR/Salary/Salary";
import Reword from "../HR/Reword/Reword";
import {Tabs} from "antd";
import LIDS from "./Lids/LIDS";
import LidForms from "./LidForms/LidForms";
import LidMessengers from "./LidMessengers/LidMessengers";
function Lidlar({sidebaractive2, changeLink, link, users}) {


    const {t} = useTranslation()

    const history = useHistory()
    const location = useLocation()


    const items = [
        {
            label: 'Lidlar',
            key: '/main/lid/lids',
            children:<LIDS/>,
            check:[]
        },
        {
            label: 'LidForm',
            key: '/main/lid/lidForm',
            children:<LidForms/>,
        },
        {
            label: 'Kanallar',
            key: '/main/lid/',
            children: <LidMessengers/>,
        },
    ]

    useEffect(() => {
        !items.some(item=>item.key === location.pathname) && history.push('/main/lid/lids')
    }, []);
    const onChange = (key) => {
        history.push(key)
    };

    return (
        <div>
            <h4 className={'p-3 mainText'}>Lidlar</h4>
            <div className={'px-3'}>
                <Tabs
                    activeKey={location.pathname}
                    onChange={onChange}
                    type="card"
                    items={items}
                />
            </div>
            {/*<div className={'hodimul'}>*/}
            {/*    <ul>*/}
            {/*        {*/}
            {/*            users.addUser || users.editUser || users.viewUserAdmin || users.deleteUser || users.viewUser ?*/}
            {/*                <li onClick={sidebaractive}><NavLink to={'/headerthird/lidlar'}*/}
            {/*                                                     className={isActive => isActive ? 'active-enter-link lll li-text' : 'lll li-text'}>{t('Lids.lids')}</NavLink>*/}
            {/*                </li>*/}
            {/*                : ''*/}
            {/*        }*/}

            {/*        <li onClick={sidebaractive}><Link to={'/headerthird/lidlar/lidForms'}*/}
            {/*                                          className={"lll li-text"}>{t('Lids.lidForma')}</Link></li>*/}
            {/*        <li onClick={sidebaractive}><Link to={'/headerthird/lidlar/messengers'}*/}
            {/*                                          className={"lll li-text"}>{t('Lids.kanallar')}</Link></li>*/}

            {/*    </ul>*/}

            {/*</div>*/}

        </div>
    )
}

export default connect((users), {})(Lidlar)
