import React, {useState, useEffect} from 'react';
import {
    Box,
    Grid, InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Tabs, TextField, ToggleButton, ToggleButtonGroup, Tooltip,
    Typography
} from '@mui/material';
import {Link} from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import {styled} from "@mui/material/styles";
import TableRowsTwoToneIcon from "@mui/icons-material/TableRowsTwoTone";
import GridViewTwoToneIcon from "@mui/icons-material/GridViewTwoTone";
import RemoveIcon from '@mui/icons-material/Remove';
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import users from "../../../../../reducer/users";
import formatDate, {formatDateStampList, formatDateWithMonth, formatDateWithWeek} from "../../../../../util";
import workTimeReducer, {getTimeList, getWorkUser} from "../../../../../reducer/workTimeReducer";
import Loading from "../../../../Loading";
import {toast} from "react-toastify";
import './davomat.css'
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";

function Davomat({getWorkUser, workTimeReducer, users, getTimeList}) {
    const {t} = useTranslation();


    useEffect(() => {
        getWorkUser(users.branchId)
    }, [workTimeReducer.current])




    const [loading, setLoading] = useState(false)
    const [activeLidStatus, setActiveLidStatus] = useState(false)
    const [query, setQuery] = useState(null);

    const handleQueryChange = (e) => {
        setQuery(e.target.value)
    };

    useEffect(() => {
        setLoading(true)
    }, [workTimeReducer.getBoolean])

    function LidStatusToggle() {
        setActiveLidStatus(!activeLidStatus)
    }

    const [days, setDays] = useState([])


    function getDaysInMonth() {
        const year = new Date().getFullYear()
        const month = new Date().getMonth()
        let date = new Date(year, month, 1)
        let days = [];
        while (date.getMonth() === month) {
            days.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }
        setDays(days)
    }


    useEffect(() => {
        getDaysInMonth()
        getTimeList(users.branchId)
        setLoading(true)
    }, [])

    const [visible, setvisible] = useState(5)
    let [counter, setCounter] = useState(0)

    function koproq() {
        let a = workTimeReducer.workTime

        for (let i = 0; a; i++) {
            counter += visible
            if (counter < a) {
                setvisible(prev => prev + 5)
                return;
            } else {
                toast.error(t('Davomat.kmyuq'))
                return
            }
        }
    }

    return (
        <>
            <div className={'colorback mt-4 m-3 p-4'}>
                <Grid className={'mb-3'} container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h4" component="h4" gutterBottom>
                            <h4>{t('HR.davomat')}</h4>
                        </Typography>

                    </Grid>

                </Grid>

                <div className={'mb-2'}>
                    <Box p={2}>
                        <TextField
                            sx={{
                                m: 0,
                                outline:'none'
                            }}
                            label={t('ol.8')}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchTwoToneIcon/>
                                    </InputAdornment>
                                )
                            }}
                            onChange={handleQueryChange}
                            placeholder={t('ol.9')}
                            value={query}
                            size="small"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                        />
                    </Box>
                </div>
                {
                    users.viewAttendanceChecked ?
                    workTimeReducer.workTime ?
                        loading ?
                            <TableContainer>
                                <Table className={'mb-3'}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>#</TableCell>
                                            <TableCell>{t('Davomat.hodim')}</TableCell>
                                            <TableCell align={'center'}>{t('Davomat.kkun')}</TableCell>
                                            {
                                                days?.map(item =>
                                                    <TableCell style={{padding: '5px 0'}}>
                                                        <div className={'d-flex flex-column p-0 m-0 text-center'}>
                                                            <p className={'d-inline p-0 m-0 text-center'}
                                                               style={{fontSize: '10px'}}>{formatDateWithMonth(item)}
                                                            </p>

                                                            <p className={'d-inline p-0 m-0 text-center'}
                                                               style={{fontSize: '10px'}}>{formatDateWithWeek(item)}
                                                            </p>
                                                        </div>
                                                    </TableCell>
                                                )
                                            }

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            workTimeReducer.workTime?.filter(val=>{
                                                if (query===null){
                                                    return val
                                                }else if (val.firstName.toUpperCase().includes(query.toUpperCase())){
                                                    return val
                                                }
                                            })
                                            .map((item, index) => <TableRow key={index}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{item.firstName}</TableCell>
                                                <TableCell align={'center'}>{item?.timestampList.length}</TableCell>

                                                {
                                                    days?.map((day) =>
                                                        <TableCell>
                                                            {
                                                                item?.timestampList.some(item => formatDate(day) === formatDate(item)) ?
                                                                    <CheckCircleIcon color={'success'}/> :
                                                                    formatDateStampList(day) > formatDateStampList(new Date()) ?
                                                                        <RemoveIcon color={'secondary'}/> :
                                                                        <CancelIcon color={'error'}/>
                                                            }
                                                        </TableCell>
                                                    )
                                                }
                                            </TableRow>)
                                        }

                                    </TableBody>
                                </Table>
                                {
                                    workTimeReducer.workTime?
                                        workTimeReducer.workTime.length > 5 ? <button onClick={koproq}
                                                                                          className={'btn btn-outline-danger form-control'}>{t('Buttons.5')}</button> : '':''
                                }
                            </TableContainer> : <Loading/> : <div><h4 className={'text-center'}>{t('Darslik.mtt')}</h4></div>:''
                }

            </div>
            <Modal isOpen={activeLidStatus} toggle={LidStatusToggle}>
                <ModalHeader>
                    <h6>{t('Davomat.lsqushish')}</h6>
                </ModalHeader>
                <ModalBody className={'d-flex'}>
                    <div className="col-md-6">
                        <label htmlFor="mjs">{t('Davomat.mijozstatusi')}</label>
                        <input className={'form-control'} type="text"/>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="mjs">{t('Davomat.rb')}</label>
                        <input className={'form-control'} type="color"/>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button className={'btn btn-outline-primary'}>{t('Davomat.saqlash')}</button>
                    <button onClick={LidStatusToggle} className={'btn btn-outline-primary'}>{t('Davomat.chiqish')}</button>
                </ModalFooter>
            </Modal>
        </>
    );
}

// export default Lids;
export default connect((users, workTimeReducer), {getWorkUser, getTimeList})(Davomat)
