import React, {useState, useEffect} from 'react';
import {
    Box,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Tabs, ToggleButton, ToggleButtonGroup,
    Typography
} from '@mui/material';
import {Link} from "react-router-dom";
import {styled} from "@mui/material/styles";
import TableRowsTwoToneIcon from "@mui/icons-material/TableRowsTwoTone";
import GridViewTwoToneIcon from "@mui/icons-material/GridViewTwoTone";
import {connect} from "react-redux";
import users from "../../../../../../reducer/users";
import formatDate, {formatDateStampList, formatDateWithMonth, formatDateWithWeek} from "../../../../../../util";
import workTimeReducer, {getTimeList} from "../../../../../../reducer/workTimeReducer";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveIcon from "@mui/icons-material/Remove";
import CancelIcon from "@mui/icons-material/Cancel";
import "../davomat.css"
import { t } from 'i18next';

function ByHour({users,workTimeReducer,getTimeList}) {
    const [filters, setFilters] = useState({
        role: null
    });
    const [toggleView, setToggleView] = useState('table_view');
    const handleViewOrientation = (_event, newValue) => {
        setToggleView(newValue);
    };

    const TabsWrapper = styled(Tabs)(
        ({theme}) => `
    @media (max-width: ${theme.breakpoints.values.md}px) {
      .MuiTabs-scrollableX {
        overflow-x: auto !important;
      }

      .MuiTabs-indicator {
          box-shadow: none;
      }
    }
    `
    );
    const handleTabsChange = (_event, tabsValue) => {
        let value = null;

        if (tabsValue !== 'all') {
            value = tabsValue;
        }

        setFilters((prevFilters) => ({
            ...prevFilters,
            role: value
        }));

    };

    const [days, setDays] = useState([])

    function getDaysInMonth() {
        const year = new Date().getFullYear()
        const month = new Date().getMonth()
        let date = new Date(year, month, 1)
        let days = [];
        while (date.getMonth() === month) {
            days.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }
        setDays(days)
    }

    useEffect(() => {
        getDaysInMonth()
        // getWorkUser(users.branchId)
        getTimeList(users.branchId)
        // getTimeList(users.branchId)
    }, [])

    return (
        <>
            <div className={'colorback mt-4 m-3 p-4'}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h4" component="h4" gutterBottom>
                            <h4>{t('Davomat.dsoat')}</h4>
                        </Typography>

                    </Grid>

                </Grid>


                <div className={'mb-2'}>
                    <input type="search" placeholder={t('ol.1')} className={'form-control'}/>
                </div>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>{t('Davomat.hodim')}</TableCell>
                                <TableCell> {t('Davomat.soat')} </TableCell>
                                {
                                    days?.map(item=> <TableCell style={{padding:'5px 0'}}>
                                        <div className={'d-flex flex-column p-0 m-0 text-center'}>
                                            <p className={'d-inline p-0 m-0 text-center'}
                                               style={{fontSize: '10px'}}>{formatDateWithMonth(item)}
                                            </p>

                                            <p className={'d-inline p-0 m-0 text-center'}
                                               style={{fontSize: '10px'}}>{formatDateWithWeek(item)}
                                            </p>
                                        </div>
                                    </TableCell>)
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                workTimeReducer.workTime?.map((item, index) => <TableRow key={index}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{item.firstName}</TableCell>
                                    <TableCell>{item.hour}</TableCell>
                                    {
                                        days?.map((day) =>
                                            <TableCell>
                                                {
                                                    item?.timestampList.some(item => formatDate(day) === formatDate(item)) ?
                                                        <CheckCircleIcon color={'success'}/> :
                                                        formatDateStampList(day) > formatDateStampList(new Date()) ?
                                                            <RemoveIcon color={'secondary'}/> :
                                                            <CancelIcon color={'error'}/>
                                                }
                                            </TableCell>
                                        )
                                    }
                                </TableRow>)
                            }
                        </TableBody>
                    </Table>
                </TableContainer>

            </div>
        </>
    );
}
export default connect((users,workTimeReducer), {getTimeList})(ByHour)