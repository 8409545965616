import React, {useState, forwardRef, useEffect} from 'react';
import {Link, Link as RouterLink, useLocation} from 'react-router-dom';
import {DragDropContext} from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import {
    Avatar,
    Box,
    Card,
    Checkbox,
    CardActionArea,
    CardContent,
    Grid,
    Slide,
    Divider,
    Tooltip,
    IconButton,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableContainer,
    TableRow,
    ToggleButton,
    ToggleButtonGroup,
    Tab,
    Tabs,
    TextField,
    Button,
    Typography,
    Dialog,
    Zoom,
    styled, DialogTitle, DialogContent, Autocomplete, Switch, DialogActions, CircularProgress
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import Label from '../Label';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import GridViewTwoToneIcon from '@mui/icons-material/GridViewTwoTone';
import TableRowsTwoToneIcon from '@mui/icons-material/TableRowsTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import {useSnackbar} from 'notistack';

import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import users from "../../../../../reducer/users";
import {connect} from "react-redux";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {useForm} from "react-hook-form";
import LidStatusReducer, {
    deleteLidStatus,
    editLidStatus,
    getLidStatus,
    getLidStatusOne,
    saveLidStatus
} from "../Lids/reducer/LidStatusReducer";
import Loading from "../../../../Loading";
import ModalLoading from "../../../../ModalLoading";
import LidMessengersReducer, {
    deleteLidMessenger,
    editLidMessenger,
    getLidMessenger,
    getOneMessenger,
    saveLidMessenger
} from "../Lids/reducer/LidMessengersReducer";
import EditIcon from "@mui/icons-material/Edit";


const TasksWrapper = styled(Box)(
    ({theme}) => `
      display: flex;
      overflow-y: hidden;
      overflow-x: auto;
      flex-direction: row;
      padding: ${theme.spacing(2)};
  `
);

const TasksWrapperContent = styled(Box)(
    ({theme}) => `
      margin: 0 -${theme.spacing(2)};
  `
);
const CardAddAction = styled(Card)(
    ({theme}) => `
        border: ${theme.colors.primary.main} dashed 1px;
        height: 100px;
        flex: 1;
        min-width: 120px;
        color: ${theme.colors.primary.main};
        
        .MuiCardActionArea-root {
          height: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
        }
        
        .MuiTouchRipple-root {
          opacity: .2;
        }
        
        &:hover {
          border-color: ${theme.colors.alpha.black[100]};
        }
`
);


const AvatarAddWrapper = styled(Avatar)(
    ({theme}) => `
        background: ${theme.colors.alpha.black[5]};
        color: ${theme.colors.primary.main};
        width: ${theme.spacing(8)};
        height: ${theme.spacing(8)};
`
);
const AvatarWrapper = styled(Box)(
    ({theme}) => `

    position: relative;

    .MuiAvatar-root {
      width: ${theme.spacing(16)};
      height: ${theme.spacing(16)};
    }
`
);

const ButtonUploadWrapper = styled(Box)(
    ({theme}) => `
    position: absolute;
    width: ${theme.spacing(6)};
    height: ${theme.spacing(6)};
    bottom: -${theme.spacing(2)};
    right: -${theme.spacing(2)};

    .MuiIconButton-root {
      border-radius: 100%;
      background: ${theme.colors.primary.main};
      color: ${theme.palette.primary.contrastText};
      box-shadow: ${theme.colors.shadows.primary};
      width: ${theme.spacing(6)};
      height: ${theme.spacing(6)};
      padding: 0;
  
      &:hover {
        background: ${theme.colors.primary.dark};
      }
    }
`
);

const AvatarError = styled(Avatar)(
    ({theme}) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const CardWrapper = styled(Card)(
    ({theme}) => `

  position: relative;
  overflow: visible;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: inherit;
    z-index: 1;
    transition: ${theme.transitions.create(['box-shadow'])};
  }
      
    &.Mui-selected::after {
      box-shadow: 0 0 0 3px ${theme.colors.primary.main};
    }
  `
);
const Input = styled('input')({
    display: 'none'
});

const ButtonError = styled(Button)(
    ({theme}) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

const TabsWrapper = styled(Tabs)(
    ({theme}) => `
    @media (max-width: ${theme.breakpoints.values.md}px) {
      .MuiTabs-scrollableX {
        overflow-x: auto !important;
      }

      .MuiTabs-indicator {
          box-shadow: none;
      }
    }
    `
);

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const LidMessengers = ({users,getLids, deleteLidMessenger,editLidMessenger,saveLidMessenger,getOneMessenger,
                     LidStatusReducer, deleteLidStatus,editLidStatus,getLidMessenger,LidMessengersReducer,
                     saveLidStatus,getLidStatusOne,getLidStatus}) => {
    const [selectedItems, setSelectedUsers] = useState([]);

    const {register, setValue, resetField, reset, handleSubmit, formState: {errors}} = useForm()
    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();
    const location = useLocation();

    const [lidMessengerActive, setLidMessengerActive] = useState(false)

    function LidMessengerToggle() {
        setLidMessengerActive(!lidMessengerActive)
    }


    const [openLidAdd,setOpenLidAdd] = useState(false)
    const [open, setOpen] = useState(false);




    const handleCreateUserClose = () => {
        setOpen(false);
    };

    const handleCreateUserSuccess = () => {
        enqueueSnackbar(t('ol.77'), {
            variant: 'success',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            },
            TransitionComponent: Zoom
        });

        setOpen(false);
    };



    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);

    const handleSelectAllUsers = (event) => {
        setSelectedUsers(event.target.checked ? users.map((user) => user.id) : []);
    };

    const handlePageChange = (_event, newPage) => {
        setPage(newPage);
    };

    const handleLimitChange = (event) => {
        setLimit(parseInt(event.target.value));
    };
    const selectedSomeUsers =
        selectedItems.length > 0 && selectedItems.length < users.length;
    const selectedAllUsers = selectedItems.length === users.length;

    const [toggleView, setToggleView] = useState('table_view');

    const handleViewOrientation = (_event, newValue) => {
        setToggleView(newValue);
    };


    const [editId, setEditId] = useState(null)

    function editStatus(id){
        LidMessengerToggle()
        setEditId(id)

        let a = LidMessengersReducer.lidMessenger.filter(item=>item.id===id)
        if (a[0].id===id){
            setMessengerName(a[0].name)
            // setIcon(a[0].icon)
        }
    }


    const [deletemodal, setdeletemodal] = useState(false)
    const [deleteID, setdeletID] = useState('')
    const [messengerName,setMessengerName] = useState('')
    const [icon,setIcon] = useState('')
    function deleteModaltoggle() {
        setdeletemodal(!deletemodal)
    }
    function deleteStatus(id){
        setdeletID(id)
        deleteModaltoggle()
    }

    function deleteFunc() {
        deleteLidMessenger(deleteID)
        setdeletID('')
        deleteModaltoggle()
        getLidMessenger(users.businessId)
    }

    const [saveModal,setSaveModal] =  useState(false)
    function saqlaLidStatus(){
        if (editId){
            editLidMessenger({
                businessId: users.businessId,
                name: messengerName,
                icon,
                id:editId
            })
        }else {
            saveLidMessenger({
                businessId: users.businessId,
                name: messengerName,
                icon
            })
        }
        setSaveModal(true)
    }

    useEffect(()=>{
        if (LidMessengersReducer?.saveBoolean){
            LidMessengerToggle()
            setIcon('')
            setMessengerName('')
            setSaveModal(false)
            setEditId(null)
        }
        getLidMessenger(users.businessId)
    },[LidMessengersReducer?.current])

    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        getLidMessenger(users.businessId)

    },[])

    useEffect(()=>{
        setLoading(false)
    },[])

    useEffect(() => {
        setLoading(true)
    }, [LidMessengersReducer?.getBoolean])


    return (
        <>
            <div className={'col-md-12 p-3'}>
                <Box className={'mt-4 colorback mb-4'}
                     display="flex"
                     alignItems="center"
                     flexDirection={{xs: 'column', sm: 'row'}}
                     justifyContent={{xs: 'center', sm: 'space-between'}}
                     pb={3}
                >
                    <TabsWrapper
                        textColor="secondary"
                        variant="scrollable"
                    >
                        <Link to={'/headerthird/lidlar/lidForms'}>
                            <button className={'btn btn-outline-primary'}>{t('lidForma.lidForma')} / {t('lidForma.Ortga')}</button>
                        </Link>

                        <button onClick={LidMessengerToggle} className={'btn btn-outline-dark ms-2'}>+ {t('lidForma.add')}</button>

                    </TabsWrapper>

                </Box>

                {
                    // toggleView === 'table_view' && (
                    loading?
                    LidMessengersReducer?.lidMessenger ?
                    <Card>
                        <Divider/>

                        {
                            <>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>#</TableCell>
                                                <TableCell>{t('Lids.Ismi')}</TableCell>
                                                {/*<TableCell>Icon</TableCell>*/}
                                                <TableCell align="center">{t('ol.64')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                LidMessengersReducer.lidMessenger.map((item,index)=><tr key={index}>
                                                    <TableCell>{index+1}</TableCell>
                                                    <TableCell>{item.name}</TableCell>
                                                    {/*<TableCell>{item.icon}</TableCell>*/}
                                                    <TableCell >
                                                        <div className={'text-center'}>
                                                                <IconButton onClick={()=>editStatus(item.id)} color={'primary'}>
                                                                    <EditIcon/>
                                                                </IconButton>
                                                            <IconButton
                                                                onClick={() => deleteStatus(item.id)} color="primary">
                                                                <DeleteTwoToneIcon fontSize="small"/>
                                                            </IconButton>
                                                            {/*<button onClick={()=>editStatus(item.id)} className={'btn btn-primary'}>* /</button>*/}
                                                            {/*<button onClick={()=>deleteStatus(item.id)} className={'btn ms-2 btn-danger'}>-</button>*/}
                                                        </div>
                                                    </TableCell>
                                                </tr>)
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Box p={2}>
                                    <TablePagination
                                        component="div"
                                        // count={filteredUsers.length}
                                        onPageChange={handlePageChange}
                                        onRowsPerPageChange={handleLimitChange}
                                        page={page}
                                        rowsPerPage={limit}
                                        rowsPerPageOptions={[5, 10, 15]}
                                    />
                                </Box>
                            </>
                        }
                    </Card>
                        :<div>
                            <h4 className={'text-center'}>{LidMessengersReducer?.message}</h4>
                        </div>: <Loading/>
                }


                <Modal isOpen={lidMessengerActive} toggle={LidMessengerToggle}>
                    <ModalHeader>
                        <h5>{t('lidForma.Messengers')} {t('lidForma.add')}</h5>
                    </ModalHeader>
                    <ModalBody>
                        <div className="col-md-12 d-flex justify-content-between">
                            <div className={'col-md-12'}>
                                <label htmlFor="name">{t('Lids.Ismi')}</label>
                                <input value={messengerName} onChange={(e)=>setMessengerName(e.target.value)} type="text" className={'form-control'}/>
                            </div>

                            {/*<div className={'col-md-6'}>*/}
                            {/*    <label htmlFor="name">Icon</label>*/}
                            {/*    <input value={icon} onChange={(e)=>setIcon(e.target.value)} type="file" className={'form-control'}/>*/}
                            {/*</div>*/}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button onClick={saqlaLidStatus} className={'btn btn-outline-primary'}>{t('Buttons.6')}</button>
                        <button onClick={LidMessengerToggle} className={'btn btn-outline-primary'}>{t('Buttons.7')}</button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={deletemodal} toggle={deleteModaltoggle}>
                    <ModalBody>
                        <h5>{t('Buttons.12')} ?</h5>
                    </ModalBody>
                    <ModalFooter>
                        <button onClick={() => deleteFunc(deleteID)}
                                className={'btn btn-outline-primary'}>{t('Buttons.3')}</button>
                        <button onClick={() => deleteModaltoggle('')}
                                className={'btn btn-outline-primary'}>{t('Buttons.7')}</button>
                    </ModalFooter>
                </Modal>

                <ModalLoading isOpen={saveModal}/>
            </div>
        </>
    );
};

// export default Results;
export default connect((users,LidStatusReducer,LidMessengersReducer), {getLidMessenger,getOneMessenger,
    saveLidMessenger,editLidMessenger,deleteLidMessenger,getLidStatus,getLidStatusOne,saveLidStatus,editLidStatus,deleteLidStatus})(LidMessengers)
