import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../../../../../../api";
import {toast} from "react-toastify";
// import {toast} from "react-toastify";

const slice = createSlice({
    name: 'lids',
    initialState: {
        lidsField: null,
        current:false,
        message:'',
        lidFieldOne: null,
        getBoolean: false,
        saveBoolean: false,
        deleteBoolean: false,
    },
    reducers: {
        getFrom: (state, action) => {
            if(action.payload.success){
                state.lidsField = action.payload.object
            }
            else{
                state.message = action.payload.message
                state.lidsField = null
            }
            state.getBoolean = !state.getBoolean
            state.saveBoolean = false
        },
        getFromLidOne:(state,action)=>{
            if (action.payload.success){
                state.lidFieldOne=action.payload.object
            }else {
                state.message = action.payload.message
            }
            state.getBoolean = !state.getBoolean
        },

        savefrom: (state,action) => {
            if (action.payload.success){
                toast.success('ADDED')
                state.saveBoolean = true
            }else {
                toast.error(action.payload.message)
            }
            state.current=!state.current
        },

        editfrom: (state,action) => {

            if (action.payload.success){
                toast.success('EDITED')
                state.saveBoolean = true
            }else {
                toast.error(action.payload.message)
            }
            state.current=!state.current
        },
        deletefrom:(state,action)=>{
            if (action.payload.success){
                toast.success('DELETED')
                state.current=!state.current
            }else {
                toast.error(action.payload.message)
            }
            state.deleteBoolean = true
        }
    }
});

export const getLidField=(data)=>apiCall({
    url: '/lidField/getByBusinessId/'+data,
    method:'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type,
});

export const getOneField=(data)=>apiCall({
    url: '/lidField/'+data,
    method:'get',
    onSuccess: slice.actions.getFromLidOne.type,
    onFail: slice.actions.getFromLidOne.type,
});

export const saveLidField=(data)=>apiCall({
    url: '/lidField',
    method:'post',
    data,
    onSuccess: slice.actions.savefrom.type,
    onFail: slice.actions.savefrom.type
});

export const editLidField=(data)=>apiCall({
    url: '/lidField/'+data.id,
    method: 'put',
    data,
    onSuccess: slice.actions.editfrom.type,
    onFail: slice.actions.editfrom.type
});

export const deleteLidField=(data)=>apiCall({
    url: '/lidField/'+data,
    method:'delete',
    data,
    onSuccess: slice.actions.deletefrom.type,
    onFail: slice.actions.deletefrom.type,
});

export default slice.reducer
