import {Link, Switch, Route, NavLink, useHistory, useLocation} from 'react-router-dom'
import './baza.css'
import {useState,useEffect} from 'react'
import {connect} from "react-redux";
import {active} from "../../../../reducer/functionreducer";
import {useTranslation} from "react-i18next";
import users from "../../../../reducer/users";
import allbusinessreducer, {getOneBusiness} from "../SUPERADMIN/reducers/allbusinessreducer";
import {Tabs} from "antd";
import OtkazmalarRoyxati from "./otkazmalarRoyxati/OtkazmalarRoyxati";
import YangiOtkazma from "./yangiOtkazma/YangiOtkazma";
import OtkazmaSorash from "./OtkazmaSorash/OtkazmaSorash";
import Cars from "./Car/Cars";
import ExchangeReport from "./otkazmalarRoyxati/ExchangeReport";

function Baza({changeLink,link,sidebaractive2,users,getOneBusiness,allbusinessreducer}) {

    const {t}= useTranslation()
    const history = useHistory()
    const location = useLocation()


    useEffect(()=>{
        getOneBusiness(users.businessId)
    },[])


    function sidebaractive(){
        const windowWidth = window.innerWidth;
        if(windowWidth <= 1023.9){
            sidebaractive2()
        }
    }

    const items = [
        {
            label: t('ad.7'),
            key: '/main/exchange/table',
            children: <OtkazmalarRoyxati/>,
        },
        {
            label: t('ad.9'),
            key: '/main/exchange/addExchange',
            children: <YangiOtkazma/>,
        },
        {
            label: t('Sidebar1.otkazmasurash'),
            key: '/main/exchange/request',
            children: <OtkazmaSorash/>,
        },
        {
            label: t('Sidebar1.mashina'),
            key: '/main/exchange/cars',
            children: <Cars/>,
        },
        {
            label: t('Sidebar1.utkazmahisobot'),
            key: '/main/exchange/exchangeReport',
            children: <ExchangeReport/>,
        },
    ]

    useEffect(() => {
        !items.some(item=>item.key === location.pathname) && history.push('/main/exchange/table')
    }, []);
    const onChange = (key) => {
        history.push(key)
    };

    return(
        <div>
            <h4 className={'p-3 mainText'}>{t('ad.7')}</h4>
            <div className={'px-3'}>
                <Tabs
                    activeKey={location.pathname}
                    onChange={onChange}
                    type="card"
                    items={items}
                />
            </div>
                {/*<ul>*/}
                {/*    {*/}
                {/*        users.addExchange || users.editExchange || users.viewExchange || users.viewExchangeAdmin || users.deleteExchange ?*/}
                {/*            <li onClick={sidebaractive}><NavLink to={'/headerthird/utkazmaRuyxati'} className={isActive => isActive ? 'active-enter-link li-text' : 'li-text'} >{t('Sidebar.23')}</NavLink></li>*/}
                {/*            :''*/}
                {/*    }*/}
                {/*    {*/}
                {/*        users.addExchange ?*/}
                {/*            <li  onClick={sidebaractive}><NavLink to={'/headerthird/yangiOtkazma'} className={isActive => isActive ? 'active-enter-link li-text' : 'li-text'} >{t('Sidebar.24')}</NavLink></li>*/}
                {/*        :''*/}
                {/*    }*/}
                {/*    {*/}
                {/*        users.addExchange  ?*/}
                {/*        // || allbusinessreducer?.exchangeProductByConfirmation ?*/}
                {/*            <li  onClick={sidebaractive}><NavLink to={'/headerthird/otkazmaSorash'} className={isActive => isActive ? 'active-enter-link li-text' : 'li-text'} >{t('Sidebar1.otkazmasurash')}</NavLink></li>*/}
                {/*        :''*/}
                {/*    }*/}
                {/*    {*/}
                {/*        users.editExchange || users.viewExchange || allbusinessreducer?.exchangeProductByConfirmation ?*/}
                {/*            <li  onClick={sidebaractive}><NavLink to={'/headerthird/cars'} className={isActive => isActive ? 'active-enter-link li-text' : 'li-text'} >{t('Sidebar1.mashina')}</NavLink></li>*/}
                {/*        :''*/}
                {/*    }*/}
                {/*    {*/}
                {/*        users.addExchange || users.editExchange || users.viewExchange || allbusinessreducer?.exchangeProductByConfirmation ?*/}
                {/*            <li  onClick={sidebaractive}><NavLink to={'/headerthird/exchangeReport'} className={isActive => isActive ? 'active-enter-link li-text' : 'li-text'} >{t('Sidebar1.utkazmahisobot')}</NavLink></li>*/}
                {/*        :''*/}
                {/*    }*/}
                {/*</ul>*/}

        </div>
    )
}
export default connect((users,allbusinessreducer),{active,getOneBusiness}) (Baza)
