import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../../../../../api";
import {toast} from "react-toastify";

const slice = createSlice({
    name: 'mijozgurux',
    initialState: {
        customersPageable: null,
        mijozgurux:null,
        current: false,
        message: '',
        CustomerOne: null,
        getBoolean: false,
        saveBoolean: false,
        deleteBoolean: false,
        lidCustomers: null,
        deleteCurrent:false,
        deleteSuccess: false,
        customerInfo:{},
        customerTradeInfo:null,
        customerPay:null,
        konstruktorReports:null
    },
    reducers: {
        getFrom: (state, action) => {
            if (action.payload.success) {
                state.customersPageable = action.payload.object

            } else {
                state.message = action.payload.message
                state.customersPageable = null
            }
            state.getBoolean = !state.getBoolean
            state.saveBoolean = false
        },
        getFromAll: (state, action) => {
            if (action.payload.success) {
                state.mijozgurux = action.payload.object
            } else {
                state.message = action.payload.message
                state.mijozgurux = null
            }
            state.getBoolean = !state.getBoolean
            state.saveBoolean = false
        },
        getCustomerPay:(state,action)=>{
          if (action.payload.success){
              state.customerPay = action.payload.object
          }  else {
              state.customerPay=null
          }
        },
        getCustomerInfos:(state,action)=>{
          if (action.payload.success){
              state.customerInfo = action.payload.object
          }  else {
              state.customerInfo = {}
          }
        },
        getCustomerTradeInfos:(state,action)=>{
          if (action.payload.success){
              state.customerTradeInfo = action.payload.object
          }  else {
              state.customerTradeInfo = null
          }
        },
        getLidCustomer:(state,action)=>{
            if (action.payload.success){
                state.lidCustomers = action.payload.object
            }  else {
                state.lidCustomers = null
            }
        },
        getFromCustomerOne: (state, action) => {
            state.CustomerOne = action.payload.object
        },
        getkonstruktorReports: (state, action) => {
            if (action.payload.success) {
                state.konstruktorReports = action.payload.object

            } else {
                state.message = action.payload.message
                state.konstruktorReports = null
            }
            state.getBoolean = !state.getBoolean
            state.saveBoolean = false
        },
        savefrom: (state, action) => {
            if (action.payload.success) {
                toast.success('ADDED')
                state.saveBoolean = true
            } else {
                toast.error(action.payload.message)
            }
            state.current = !state.current

        },
        savefrom2: (state, action) => {
            if (action.payload.success) {
                state.current = !state.current
                toast.success(`Qarz o'chirildi!`)
            } else {
                toast.error(action.payload.message)
            }

        },
        saveExcel:(state,action)=>{
            if (action.payload.success){
                toast.success('EXCEL SAVED')
                state.saveBoolean = true
            }  else {
                toast.error(action.payload.message)
            }
            state.current = !state.current
        },
        editfrom: (state, action) => {

            if (action.payload.success) {
                toast.success('EDITED')
                state.saveBoolean = true
            } else {
                toast.error(action.payload.message)
            }
            state.current = !state.current

        },
        deletefrom: (state, action) => {
            if (action.payload.success) {
                toast.success('DELETED')
                state.deleteSuccess = true
            } else {
                toast.error(action.payload.message)
            }
            state.deleteCurrent = !state.deleteCurrent
        },
        deleteCheck:(state,action)=>{
            if (action.payload.success){
                toast.success(`Belgilangan mijozlar o'chirildi`)
            }else {
                toast.error(action.payload.message)
            }
            state.deleteBoolean = true
        }
    }
});

export const getMijozGurux = (data) => apiCall({
    url: '/customer/get-by-businessId/' + data.businessId,
    method: 'get',
    params:data.params,
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type,
});
export const getMijozGuruxByBranch = (data) => apiCall({
    url: '/customer/get-by-branchId/' + data,
    method: 'get',
    onSuccess: slice.actions.getFromAll.type,
    onFail: slice.actions.getFromAll.type
});

export const getCustomerByBrachPageable = (data) => apiCall({
    url: '/customer/get-by-branchIdBySearch/' + data.branchId,
    method: 'get',
    params:data.params,
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type
});
export const getCustomerPayType = (data) => apiCall({
    url: '/customer/get-customer-prevented-info/' + data,
    method: 'get',
    onSuccess: slice.actions.getCustomerPay.type,
    onFail: slice.actions.getCustomerPay.type,
});
export const getCustomerInfo = (data) => apiCall({
    url: '/customer/get-customer-info/' + data,
    method: 'get',
    onSuccess: slice.actions.getCustomerInfos.type,
    onFail: slice.actions.getCustomerInfos.type,
});
export const getCustomerTradeInfo = (data) => apiCall({
    url: '/customer/get-customer-trade-info/' + data,
    method: 'get',
    onSuccess: slice.actions.getCustomerTradeInfos.type,
    onFail: slice.actions.getCustomerTradeInfos.type,
});
export const getLidCustomers = (data) => apiCall({
    url: '/customer/get-by-lid-customer/' + data,
    method: 'get',
    onSuccess: slice.actions.getLidCustomer.type,
    onFail: slice.actions.getLidCustomer.type,
});
export const getMijozByGroup = (data) => apiCall({
    url: '/customer/get-by-group/' + data,
    method: 'get',
    onSuccess: slice.actions.getFromAll.type,
    onFail: slice.actions.getFromAll.type,
});
export const downloadCustomers = (data) => apiCall({
    url: '/customer/export/' + data,
    method: 'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type,
});


export const getMijozGuruxSearchByBranch = (data) => apiCall({
    url: `/customer/search/${data.branchId}`,
    params: {name: data.name},
    method: 'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type
});

export const getCustomerOne = (data) => apiCall({
    url: '/customer/' + data,
    method: 'get',
    onSuccess: slice.actions.getFromCustomerOne.type,
    onFail: slice.actions.getFromCustomerOne.type
});

export const saveMijozGurux = (data) => apiCall({
    url: '/customer',
    method: 'post',
    data,
    onSuccess: slice.actions.savefrom.type,
    onFail: slice.actions.savefrom.type
});

export const saveExcelCustomer = (data) => apiCall({
    url: '/customer/import/'+data.branchId,
    method: 'post',
    contentType:'"multipart/form-data"',
    data:data.file,
    onSuccess: slice.actions.saveExcel.type,
    onFail: slice.actions.saveExcel.type
});

export const editMijozGurux = (data) => apiCall({
    url: '/customer/' + data.id,
    method: 'put',
    data,
    onSuccess: slice.actions.editfrom.type,
    onFail: slice.actions.editfrom.type
});

export const deleteMijozGurux = (data) => apiCall({
    url: '/customer/' + data,
    method: 'delete',
    data,
    onSuccess: slice.actions.deletefrom.type,
    onFail: slice.actions.deletefrom.type,
});
export const deleteCustomers = (data) => apiCall({
    url: '/customer-few/' + data,
    method: 'delete',
    data,
    onSuccess: slice.actions.deleteCheck.type,
    onFail: slice.actions.deleteCheck.type,
});

export const repaymentcustomer = (data) => apiCall({
    url: '/customer/repayment/' + data.id,
    method: 'post',
    data,
    onSuccess: slice.actions.savefrom2.type
});

export const getKonstruktorReports = (data) => apiCall({
    url: '/customer/customerTradeReport',
    method: 'get',
    params: data,
    onSuccess: slice.actions?.getkonstruktorReports?.type,
    onFail: slice.actions.getkonstruktorReports?.type
});

export default slice.reducer
