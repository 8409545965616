import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../../../../../api";
import {toast} from "react-toastify";

const slice = createSlice({
    name: 'xodimlar',
    initialState: {
        xodimlar: null,
        agreement:[],
        agreeCurrent:false,
        oneXodim:{},
        current:true,
        message: '',
        getBoolean: false,
        saveUserBool:false,
        saveUserAgree:false,
        saveCurrent:false,
        people:null,
        peopleContent:null,
        chatUsers:null,
        chatMessages:null,
        onlineUsers:null
    },
    reducers: {
        getFrom: (state, action) => {
            if (action.payload.success){
                state.xodimlar = action.payload.object
            }else {
                state.xodimlar=null
                state.message=action.payload.message
            }
            state.getBoolean = !state.getBoolean
                state.saveUserBool = false
        },
        getChats: (state, action) => {
            if (action.payload.success){
                state.chatUsers = action.payload.object
            }else {
                state.chatUsers=null
                state.message=action.payload.message
            }
            // state.getBoolean = !state.getBoolean
            state.saveUserBool = false
        },
        getOnline: (state, action) => {
            if (action.payload.success){
                state.chatUsers = action.payload.object
            }else {
                state.chatUsers=null
                state.message=action.payload.message
            }
            // state.getBoolean = !state.getBoolean
            state.saveUserBool = false
        },
        getChatMessages: (state, action) => {
            if (action.payload.success){
                state.chatMessages = action.payload.object
            }else {
                state.chatMessages=null
                state.message=action.payload.message
            }
            // state.getBoolean = !state.getBoolean
            state.saveUserBool = false
        },
        getPeopl: (state, action) => {
            if (action.payload.success){
                state.people = action.payload.object
                state.xodimlar = action.payload.object.content
            }else {
                state.people=null
                state.message=action.payload.message
            }
            state.getBoolean = !state.getBoolean
            state.saveUserBool = false
        },
        getFromAgree: (state, action) => {
            if (action.payload.success){
                state.agreement = action.payload.object.agreementDtoList
            }else {
                state.agreement=[]
                state.message=action.payload.message
            }
            state.agreeCurrent = !state.agreeCurrent
        },
        getFromID: (state, action) => {
            state.oneXodim = action.payload.object
            state.current=!state.current
        },
        savefrom: (state, action) => {
            if (action.payload.success){
                state.saveUserBool = true
                toast.success('Xodim qoshildi')
            }else {
                toast.warning(action.payload.message)
            }
            state.saveCurrent=!state.saveCurrent
        },
        message:(state,action)=>{
          state.message = action.payload.message
          toast.error(state.message)
        },
        editfrom: (state, action) => {
            if (action.payload.success){
                toast.success('Xodim tahrirlandi')
                state.saveUserBool = true
            }else {
                toast.error(action.payload.message)
            }
            state.saveCurrent=!state.saveCurrent
        },
        editFromAgree: (state, action) => {
            if (action.payload.success){
                toast.success('Oylik tahrirlandi')
                state.saveUserAgree = true
            }else {
                toast.error(action.payload.message)
            }
            state.current=!state.current
        },
        deletefrom: (state, action) => {
            if (action.payload.success){
                state.current=!state.current
                toast.success('Xodim o\'chirildi')
            }else {
                toast.error(action.payload.message)
            }
        }
    }
});
export const  getXodim =(data) => apiCall({
    url: '/user/get-by-business/'+data,
    method: 'get',
    data,
    onSuccess: slice.actions.getFrom.type,
    onFail:slice.actions.getFrom.type
});
export const  getChatUsers =(data) => apiCall({
    url: '/getUsers/'+data,
    method: 'get',
    data,
    onSuccess: slice.actions.getChats.type,
    onFail:slice.actions.getChats.type
});
export const  getOnlineUsers =(data) => apiCall({
    url: '/getOnlineUsers',
    method: 'get',
    data,
    onSuccess: slice.actions.getOnline.type,
    onFail:slice.actions.getOnline.type
});
export const  getMessagesForChat =(data) => apiCall({
    url: `${data.senderId}/${data.receivedId}`,
    method: 'get',
    data,
    onSuccess: slice.actions.getChatMessages.type,
    onFail:slice.actions.getChatMessages.type
});
export const  getUserRole =(data) => apiCall({
    url: '/user/get-by-role/'+data,
    method: 'get',
    data,
    onSuccess: slice.actions.getFrom.type,
    onFail:slice.actions.getFrom.type
});
export const  getPeople =(data) => apiCall({
    url: `/user/get-user-by-name/${data.branchId}?name=${data.name}`,
    method: 'get',
    params:data.params,
    onSuccess: slice.actions.getPeopl.type,
    onFail:slice.actions.getPeopl.type
});
export const getXodimByBranch = (data) => apiCall({
    url: '/user/get-by-branchId/'+data,
    method: 'get',
    data,
    onSuccess: slice.actions.getFrom.type,
    onFail:slice.actions.getFrom.type
});
export const getXodimID = (data) => apiCall({
    url: '/user/'+data,
    method: 'get',
    data,
    onSuccess: slice.actions.getFromID.type
});

export const saveXodim = (data) => apiCall({
        url: '/user',
        method: 'post',
        data,
        onSuccess: slice.actions.savefrom.type,
        onFail: slice.actions.savefrom.type
    });

export const editXodim = (data) => apiCall({
    url: '/user/'+data.id,
    method: 'put',
    data,
    onSuccess: slice.actions.editfrom.type,
    onFail: slice.actions.editfrom.type
});

export const deleteXodim = (data) => apiCall({
    url: '/user/'+data,
    method: 'delete',
    data,
    onSuccess: slice.actions.deletefrom.type,
    onFail: slice.actions.deletefrom.type
})

export const getAgreement =(data) => apiCall({
    url: '/agreement/'+data,
    method: 'get',
    onSuccess: slice.actions.getFromAgree.type,
    onFail:slice.actions.getFromAgree.type
});

export const editAgreement =(data) => apiCall({
    url: '/agreement/'+data.id,
    method: 'put',
    data,
    onSuccess: slice.actions.editFromAgree.type,
    onFail:slice.actions.editFromAgree.type
});


export default slice.reducer
