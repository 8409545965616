import React, {useState, forwardRef, useEffect} from 'react';
import {
    Avatar,
    Box,
    Grid,
    Slide,
    Tooltip,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableContainer,
    TableRow,
    TextField,
    Button,
    Typography,
    Dialog,
    styled, DialogTitle, DialogContent, CircularProgress, useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {useTranslation} from 'react-i18next';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import users from "../../../../../reducer/users";
import {connect} from "react-redux";
import {Modal, ModalBody, ModalFooter} from "reactstrap";
import ModalLoading from "../../../../ModalLoading";
import StageReducer, {deleteStage, saveStage,getStagePage} from "../../../../../reducer/StageReducer";
import {Formik} from "formik";
import * as Yup from "yup";
import wait from "../wait";

const DialogWrapper = styled(Dialog)(
    () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AvatarError = styled(Avatar)(
    ({theme}) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);
const ButtonError = styled(Button)(
    ({theme}) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const Stage = ({users,StageReducer,saveStage,deleteStage,onClose,getStagePage,mainBranchId}) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(3);

    const handlePageChange = (_event, newPage) => {
        setPage(newPage);
        getStagePage({
            branchId:mainBranchId?mainBranchId :  users.branchId,
            params:{
                page:newPage,
                size: limit
            }
        })
    };

    const handleLimitChange = (event) => {
        setLimit(parseInt(event.target.value));
        getStagePage({
            branchId:mainBranchId?mainBranchId: users.branchId,
            params:{
                page:page,
                size: parseInt(event.target.value)
            }
        })
    };
    const [stageName,setStageName] = useState('')
    const [deletemodal, setdeletemodal] = useState(false)
    const [deleteID, setdeletID] = useState('')

    function deleteModaltoggle() {
        setdeletemodal(!deletemodal)
    }

    function deleteFunc() {
        deleteStage(deleteID)
        setdeletID('')
        deleteModaltoggle()
        closeConfirmDelete()
    }

    const [saveModal,setSaveModal] =  useState(false)
    const [formik,setFormik] = useState(
        {
            name:''
        }
    )

    useEffect(()=>{
        if (StageReducer.saveBoolean){
            setStageName('')
            onClose()
        }
        setSaveModal(false)
    },[StageReducer.current])

    useEffect(()=>{
        getStagePage({
            branchId:mainBranchId?mainBranchId : users.branchId,
            params:{
                page:page,
                size:limit,
            }
        })
    },[StageReducer.current,mainBranchId])

    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [saveDeleteId,setSaveDeleteId] = useState('')
    const handleConfirmDelete = (id) => {
        setOpenConfirmDelete(true);
        setSaveDeleteId(id)
    };

    const closeConfirmDelete = () => {
        setOpenConfirmDelete(false);
    };

    const handleDeleteCompleted = () => {

        deleteStage(saveDeleteId)
        setSaveModal(true)
        closeConfirmDelete()
    };

    useEffect(()=>{
        if (StageReducer.deleteBoolean){
            setOpenConfirmDelete(false);
            setSaveDeleteId('')
        }
        setSaveModal(false)
    },[StageReducer.current])

    return (
        <>
            <DialogTitle
                sx={{
                    p: 3
                }}
            >
                <Typography variant="h4" gutterBottom>
                    {t('Stages')}
                </Typography>
            </DialogTitle>
                <Box>
                    {
                        StageReducer.stagesPages?
                            <>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>

                                                <TableCell>#</TableCell>
                                                <TableCell>Name</TableCell>
                                                <TableCell align="center">{t('Actions')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                StageReducer.stagesPages?.content.map((item,index)=><TableRow key={index}>
                                                    <TableCell>{index+1}</TableCell>
                                                    <TableCell>{item.name}</TableCell>
                                                    <TableCell className={'text-center'}>
                                                        <Tooltip title={t('Delete')} arrow>
                                                            <IconButton
                                                                onClick={()=>handleConfirmDelete(item.id)}
                                                                color="primary"
                                                            >
                                                                <DeleteTwoToneIcon fontSize="small" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>)
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Box p={2}>
                                    <TablePagination
                                        component="div"
                                        count={StageReducer.stagesPages?.totalElements}
                                        onPageChange={handlePageChange}
                                        onRowsPerPageChange={handleLimitChange}
                                        page={page}
                                        rowsPerPage={limit}
                                        rowsPerPageOptions={[3, 5, 8]}
                                    />
                                </Box>
                            </>:<Typography align={'center'} variant={'h3'}>
                                {StageReducer.message}
                            </Typography>
                    }
                </Box>
            <Formik
                initialValues={
                    formik
                }

                validationSchema={Yup.object().shape({
                    name: Yup.string()
                        .max(255)
                        .required(t('The title field is required'))
                })}
                onSubmit={async (
                    _values,
                    {resetForm, setErrors, setStatus, setSubmitting}
                ) => {
                    try {
                        await wait(1000);
                        resetForm();
                        setStatus({success: true});
                        setSubmitting(false);
                        saveStage({
                            branchId:mainBranchId?mainBranchId : users.branchId,
                            name: _values.name
                        })
                        setSaveModal(true)
                    } catch (err) {
                        console.error(err);
                        setStatus({success: false});
                        setErrors({submit: err.message});
                        setSubmitting(false);
                    }
                }}
            >
                {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      touched,
                      values
                  }) =>
                    (
                        <form onSubmit={handleSubmit}>
                            <DialogContent
                                dividers
                                sx={{
                                    p: 3
                                }}
                            >
                                <Grid container spacing={0}>
                                    <Grid
                                        sx={{
                                            mb: `${theme.spacing(3)}`
                                        }}
                                        item
                                        xs={12}
                                        sm={8}
                                        md={9}
                                    >
                                        <TextField
                                            label={'New Stage'}
                                            error={Boolean(touched.name && errors.name)}
                                            fullWidth
                                            helperText={touched.name && errors.name}
                                            name="name"
                                            placeholder={t('State title here...')}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.name}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid
                                        sx={{
                                            mb: `${theme.spacing(3)}`
                                        }}
                                        item
                                        xs={12}
                                        sm={8}
                                        md={9}
                                    >
                                        <Button
                                            sx={{
                                                mr: 2
                                            }}
                                            type="submit"
                                            startIcon={
                                                isSubmitting ? <CircularProgress size="1rem"/> : null
                                            }
                                            disabled={Boolean(errors.submit) || isSubmitting}
                                            variant="contained"
                                            size="large"
                                        >
                                            {t('Create New Stage')}
                                        </Button>
                                        <Button
                                            color="secondary"
                                            size="large"
                                            variant="outlined"
                                            onClick={onClose}
                                        >
                                            {t('Cancel')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                        </form>
                    )}
            </Formik>
            <Modal isOpen={deletemodal} toggle={deleteModaltoggle}>
                <ModalBody>
                    <h5>{t('Buttons.12')} ?</h5>
                </ModalBody>
                <ModalFooter>
                    <button onClick={() => deleteFunc(deleteID)}
                            className={'btn btn-outline-primary'}>{t('Buttons.3')}</button>
                    <button onClick={() => deleteModaltoggle('')}
                            className={'btn btn-outline-primary'}>{t('Buttons.7')}</button>
                </ModalFooter>
            </Modal>
            <DialogWrapper
                open={openConfirmDelete}
                maxWidth="sm"
                fullWidth
                TransitionComponent={Transition}
                keepMounted
                onClose={closeConfirmDelete}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    p={5}
                >
                    <AvatarError>
                        <CloseIcon />
                    </AvatarError>
                    <Typography
                        align="center"
                        sx={{
                            py: 4,
                            px: 6
                        }}
                        variant="h3"
                    >
                        {t('Are you sure you want to permanently delete this stage')}
                        ?
                    </Typography>

                    <Box>
                        <Button
                            variant="text"
                            size="large"
                            sx={{
                                mx: 1
                            }}
                            onClick={closeConfirmDelete}
                        >
                            {t('Cancel')}
                        </Button>
                        <ButtonError
                            onClick={handleDeleteCompleted}
                            size="large"
                            sx={{
                                mx: 1,
                                px: 3
                            }}
                            variant="contained"
                        >
                            {t('Delete')}
                        </ButtonError>
                    </Box>
                </Box>
            </DialogWrapper>
            <ModalLoading isOpen={saveModal}/>
        </>
    );
};
export default connect((users,StageReducer), {saveStage,deleteStage,getStagePage})(Stage)
