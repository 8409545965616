import {useState, useEffect, forwardRef} from 'react';
import {
    Box,
    Card,
    Grid,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableContainer,
    TableRow,
    Typography, Tooltip, Button, styled, Dialog, Slide,
} from '@mui/material';
import {connect} from "react-redux";
import Loading from "../../../../Loading";
import users from "../../../../../reducer/users";
import DarsliklarReducer, {getBYRoleDarslik} from "../reducer/DarsliklarReducer";
import {axiosCreate, BaseUrl} from "../../../../../middleware";
import DownloadIcon from "@mui/icons-material/Download";
import NoPhotographyIcon from "@mui/icons-material/NoPhotography";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";
import {Progress} from "reactstrap";
import {useTranslation} from 'react-i18next';


const DialogWrapper = styled(Dialog)(
    () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const ButtonError = styled(Button)(
    ({theme}) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const DarslikniKorish = ({
                        users, DarsliklarReducer, getBYRoleDarslik
                    }) => {
    const {t} = useTranslation()
    const [loading, setLoading] = useState(false)
    const [opetTestStart, setOpetTestStart] = useState({isOpen: false, id:null});
    const [solvedTest,setSolvedTest] = useState({solved: false, notView: false})
    const history = useHistory()
    const [showResult, setShowResult] =
        useState({isOpen: false,view:null,finish: null, solveTest: null, testResult: null, lessonView: null })

    useEffect(() => {
        getBYRoleDarslik(users.roleID)
        setLoading(true)
    }, [])

    const closeConfirmDelete = () => {
        setOpetTestStart({isOpen: false, id:null});
        setSolvedTest({solved: false, notView: false})
    };
    const closeShowResult = () => {
        setShowResult({isOpen: false, view:null,finish: null, solveTest: null, testResult: null, lessonView: null});
    };

    const handleConfirmDelete = (id, test) => {
        if(test){
            axiosCreate.get(`/lessonUser/by-user-and-lesson/${id}?userId=${users.id}`).then((data) => {
                setSolvedTest({solved: data.data.object.solveTest, notView: !(data.data.object.view >= data.data.object.lessonView)})
                setOpetTestStart({isOpen: true, id: id});
            }).catch((error) => {
                console.error(error)
                toast.warning(t('tes.98'))
            })
        }else {
            toast.warning(t('tes.99'))
        }
    };

    const lessonView = (id) => {
        id && axiosCreate.put(`/lessonUser/${id}?userId=${users.id}`)
            .then((data) => console.log("ko'rildi", data))
            .catch((error) => console.error(error))
    }

    const openShowResult = (id) => {
        axiosCreate.get(`/lessonUser/by-user-and-lesson/${id}?userId=${users.id}`).then((data) => {
            const mal = data.data.object
            setShowResult({isOpen: true,view:mal.view,finish: mal.finish, solveTest: mal.solveTest, testResult: mal.testResult, lessonView: mal.lessonView })
        }).catch((error) => {
            console.error(error)
            toast.warning(t('tes.100'))
        })
    }

    const handleStartTest = () => {
        axiosCreate.get(`/test/generate/${opetTestStart.id}?userId=${users.id}`).then((data) => {
            let array = data.data.object?.map((item) => {
                const questions = [item.a1, item.a2, item.a3, item.a4]
                return({
                    id: item.id,
                    question: item.question,
                    trueAnswer: item.a1,
                    questions: questions.sort()
                })
            })
            data.data.object && sessionStorage.setItem('test',JSON.stringify(array))
            setOpetTestStart({isOpen: false, id:null});
            history.push(`/main/lessons/quizstart/${opetTestStart.id}`)
        }).catch((error) => {
            toast.warning(t('tes.101'))
            setOpetTestStart({isOpen: false, id:null});
            console.error(error)
        })
    }

    return (
        <>
            <Box className="MuiPageTitle-wrapper p-5">
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h3" component="h3" gutterBottom>
                            {t('Darslik.dksye')}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Grid
                sx={{
                    px: 4
                }}
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                spacing={4}
            >
                <Grid item xs={12}>
                    <>
                        <Card>
                            <Divider/>
                            {
                                DarsliklarReducer?.darsliklar ?
                                    loading ?
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>#</TableCell>
                                                        <TableCell>Name</TableCell>
                                                        <TableCell>Description</TableCell>
                                                        <TableCell>Link</TableCell>
                                                        <TableCell>Test</TableCell>
                                                        <TableCell>{t('Darslik.testi')}</TableCell>
                                                        <TableCell>{t('Darslik.qf')}</TableCell>
                                                        <TableCell>{t('Darslik.n')}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        DarsliklarReducer?.darsliklar?.map((item, index) => {
                                                            return (<TableRow key={index}>
                                                                <TableCell>{index + 1}</TableCell>
                                                                <TableCell>{item.name}</TableCell>
                                                                <TableCell>{item.description}</TableCell>
                                                                <TableCell>
                                                                    <a onClick={() => lessonView(item.id)}
                                                                       className='text-decoration-underline text-primary'
                                                                       href={`${item.link}`} target="_blank">
                                                                        {item.link}
                                                                    </a>
                                                                </TableCell>
                                                                <TableCell>{item.hasTest ? "Bor" : "Yo'q"}</TableCell>
                                                                <TableCell>
                                                                    <Button onClick={() => handleConfirmDelete(item.id, item.hasTest)}
                                                                            style={{
                                                                                backgroundColor: '#0A79D5',
                                                                                color: '#fff'
                                                                            }} color={'secondary'}
                                                                    >{t('tes.102')}</Button>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <div className='text-center'>
                                                                        {item.attachmentId ? (
                                                                            <Tooltip title={"Download"} arrow>
                                                                                <a href={`${BaseUrl}/attachment/download/${item.attachmentId}`}
                                                                                   target="_blank" rel="noreferrer"
                                                                                   download>
                                                                                    <DownloadIcon color='primary'/>
                                                                                </a>
                                                                            </Tooltip>
                                                                        ) : (
                                                                            <Tooltip title={t('Darslik.fy')} arrow>
                                                                                <NoPhotographyIcon color='error'/>
                                                                            </Tooltip>
                                                                        )}
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <div style={{textAlign:'center'}} onClick={() => openShowResult(item.id)}>
                                                                        <Tooltip title={t('Darslik.n')} arrow>
                                                                            <VisibilityIcon fontSize={'large'} cursor={'pointer'} />
                                                                        </Tooltip>
                                                                    </div>
                                                                </TableCell>
                                                            </TableRow>)
                                                        })
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        : <Loading/> : <h4 className={'text-center'}>{t('Darslik.mtt')}</h4>
                            }
                        </Card>
                    </>
                </Grid>
            </Grid>
            <DialogWrapper
                open={opetTestStart.isOpen}
                maxWidth="sm"
                fullWidth
                TransitionComponent={Transition}
                keepMounted
                onClose={closeConfirmDelete}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    p={5}
                >
                    <Typography
                        align="center"
                        sx={{
                            py: 4,
                            px: 6
                        }}
                        variant="h3"
                    >
                        {
                            solvedTest.solved ? (t(`tes.103`)) : solvedTest.notView ? (t('tes.104')) : (t('tes.105'))
                        }
                    </Typography>

                    <Box>
                        <Button
                            variant="text"
                            size="large"
                            sx={{
                                mx: 1
                            }}
                            style={{backgroundColor: solvedTest.solved || solvedTest.notView ? '#0A79D550' : '#0A79D5',
                                color: '#fff', cursor: solvedTest.solved || solvedTest.notView ? 'not-allowed' : "default"}}
                            color={'secondary'}
                            onClick={handleStartTest}
                            disabled={solvedTest.solved || solvedTest.notView}
                        >
                            {t('tes.106')}
                        </Button>
                        <ButtonError
                            onClick={closeConfirmDelete}
                            size="large"
                            sx={{
                                mx: 1,
                                px: 3
                            }}
                            variant="contained"
                        >
                            {t('tes.46')}
                        </ButtonError>
                    </Box>
                </Box>
            </DialogWrapper>
            <DialogWrapper
                open={showResult.isOpen}
                maxWidth="sm"
                fullWidth
                TransitionComponent={Transition}
                keepMounted
                onClose={closeShowResult}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    p={5}
                >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('tes.107')}</TableCell>
                                <TableCell>{t('tes.108')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{t('tes.109')}</TableCell>
                                <TableCell>{`${showResult.view}/${showResult.lessonView}`}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{t('tes.110')}</TableCell>
                                <TableCell>
                                    <Progress animated value={showResult.view} style={{fontSize:'12px',textAlign:'center',fontWeight:'700'}}  max={showResult.lessonView}>
                                        {(showResult.view/showResult.lessonView)*100 > 100 ? '100' : Math.round((showResult.view/showResult.lessonView)*100)} %
                                    </Progress>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{t('tes.111')}</TableCell>
                                <TableCell>{showResult.solveTest ? "Ishlangan" : "Ishlanmagan"}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{t('tes.112')}</TableCell>
                                <TableCell>{showResult.testResult}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{t('tes.113')}</TableCell>
                                <TableCell>{showResult.finish ? "Tugatilgan" : "Tugatilmagan"}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Box marginTop="20px">
                        <ButtonError
                            onClick={closeShowResult}
                            size="large"
                            sx={{
                                mx: 1,
                                px: 3
                            }}
                            variant="contained"
                        >
                            {t('tes.46')}
                        </ButtonError>
                    </Box>
                </Box>
            </DialogWrapper>
        </>
    );
};
export default connect((users,DarsliklarReducer), {
    getBYRoleDarslik
})(DarslikniKorish)
