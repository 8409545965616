import React from 'react'
import {useForm} from 'react-hook-form'
import './mijozlarGuruxi.css'
import {useState, useEffect} from 'react'
import {connect} from "react-redux";
import MijozGuruxReducer, {
    getMijozGurux,
    saveMijozGurux,
    editMijozGurux,
    deleteMijozGurux,
    getMijozGuruxByBranch,
    getCustomerByBrachPageable,
    repaymentcustomer,
    getCustomerOne,
    saveExcelCustomer,
    downloadCustomers,
    deleteCustomers
} from "../reducer/MijozGuruxReducer";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import users from "../../../../../reducer/users";
import QarzuzishReducer, {qarzuzishCustomer} from "../reducer/QarzuzishReducer";
import MijozlarGuruhReducer, {getMijozLarGuruh, saveMijozLarGurux} from "../reducer/MijozlarGuruhReducer";
import branchreducer, {getbranch} from "../../../../../reducer/branchreducer";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import Loading from "../../../../Loading";
import ModalLoading from "../../../../ModalLoading";
import PayReducer, {getPay} from "../../../../../reducer/PayReducer";
import Example from "../../../../../file/customers.xlsx";
import img from "../../../../../img/Jami2.svg";
import {BaseUrl} from "../../../../../middleware";
import {Link} from "react-router-dom";
import shablonReducer, {getShablon} from "../../../../../reducer/shablonReducer";
import notificationReducer, {sendSms} from "../../../../../reducer/notificationReducer";
import Select from "react-select";
import ExcelButtons from "../../../../Components/ExcelButtons";
import {DefaultButton, QushishButton} from "../../../../Components/Buttons";
import {SearchAnt} from "../../../../Components/SelectAnt";
import CommonTable from "../../../../Components/CommonTable";
import person from '../../../../../assets/image 8.svg'
import money from '../../../../../assets/money bag-dollar.svg'
import smsPicture from '../../../../../assets/hamkorlarMail.svg'
import edit from '../../../../../assets/editPencil.svg'
import trash from '../../../../../assets/deleteTrash.svg'
import importExcel from '../../../../../assets/download folder v.svg'
import skripka from '../../../../../assets/attach.svg'
import {Checkbox} from "antd";

function Mijozlarguruxi({
                            getMijozGurux,
                            saveMijozGurux,
                            editMijozGurux,
                            deleteMijozGurux,
                            getMijozGuruxByBranch,
                            saveExcelCustomer,
                            users,
                            getCustomerOne, sendSms,
                            getMijozLarGuruh, shablonReducer,
                            MijozlarGuruhReducer,
                            MijozGuruxReducer,
                            repaymentcustomer,
                            getPay, PayReducer,
                            getCustomerByBrachPageable,
                        }) {


    const [active, setActive] = useState(false);
    const {t} = useTranslation()
    const [mainBranchId, setMainBranchId] = useState(null)

    const [openCheckList, setOpenCheckList] = useState(false)
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(5);
    const [search, setSearch] = useState('')

    const handlePageChange = (newPage) => {
        setPage(newPage - 1);
    };
    const handleLimitChange = (event, size) => {
        setPage(0)
        setSize(size);
    };


    const columns = [
        {
            title: 'ID',
            dataIndex: 'index',
            key: 'index',
            rowScope: 'row',
            width: '2%',
        },
        {
            title: 'Hodim',
            dataIndex: 'createdByName',
            key: 'createdByName',
            render: (item, values) =>
                <div onClick={() => {
                }} className={'d-flex justify-content-start gap-3 align-items-center'}>
                    <img src={values.photoId
                        ? `${BaseUrl}/attachment/download/${values.photoId
                        }` : person} style={{borderRadius: '50%'}} width={40} height={40} alt={item}/>
                    <p className={`customer-name`}>{item}</p>
                </div>,
            width: '15%',
        },
        {
            title: 'Mijoz',
            dataIndex: 'name',
            key: 'name',
            render: (item, values) =>
                <Link style={{textDecoration: 'none'}} to={'/main/customerProfile/' + values.id}>
                    <div onClick={() => {
                    }} className={'d-flex justify-content-start gap-3 align-items-center'} style={{cursor: 'pointer'}}>
                        <img src={person} style={{borderRadius: '50%'}} width={40} height={40} alt={item}/>
                        <p className={`customer-name`}>{item}</p>
                    </div>
                </Link>,
            width: '15%',
        },
        {
            title: 'Tel nomer',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            width: '10%'
        },
        {
            title: 'Balans',
            dataIndex: 'debt',
            key: 'debt',
            render: (item, values) =>
                <span className={'customer-balance'} style={item > 0 ? {background: '#FFEFEF', color: '#E02323'} : {
                    background: '#EEFAE8',
                    color: '#57CA22'
                }}>
                   {item.toString().replace('-', "")} so'm
                </span>,
            width: '13%'
        },
        {
            title: 'Guruh nomi',
            dataIndex: 'customerGroupName',
            key: 'customerGroupName',
            width: '10%'
        },
        {
            title: 'Filial',
            dataIndex: 'branchesDto',
            key: 'branchesDto',
            render: (item) =>
                <p className={'p-0 m-0'}>
                    {
                        item.map((branch) => branch?.name)
                    }
                </p>,
            width: '10%'
        },
        {
            title: 'Amallar',
            dataIndex: 'id',
            key: 'id',
            render: (item, values) => <div className={'d-flex gap-3'}>
                <DefaultButton onClick={() => repayment(item)} width={34} bgColor={'#EEFAE8'} color={'#57ca22'}
                               icon={money}/>
                {/*<DefaultButton onClick={() => sms(item)} width={34} bgColor={'#FFEEE0'} color={'#ff851f'}  icon={smsPicture}/>*/}
                {
                    users.editCustomer &&
                    <DefaultButton onClick={() => editM(item)} width={34} bgColor={'#EEF2FC'} color={'#0077ff'}
                                   icon={edit}/>
                }
                {
                    users.deleteCustomer &&
                    <DefaultButton onClick={() => deleteModaltoggle(item)} width={34} bgColor={'#FFEFEF'}
                                   color={'#e02323'} icon={trash}/>
                }
            </div>,
            width: '25%'
        },
    ];


    useEffect(() => {
        if (users.viewCustomerAdmin && users.viewCustomer) {
            setMainBranchId(null)
        } else if (users.viewCustomer) {
            setMainBranchId(users.branchId)
        }
        // getShablon(users.businessId)
    }, [])


    const [input, setInput] = useState(
        {
            guruhnomi: '',
            guruhfoiz: '',
            foizda: '',
            phone: '',
            mId: '',
            qarzuzish: '',
            mijozguruhnomi: '',
            branchId: '',
            qarzsana: '',
            branchid: [],
            selectvalue: [],
        }
    )

    const {register, setValue, resetField, reset, handleSubmit, formState: {errors}} = useForm()

    function qarzuzish(e) {
        input.qarzuzish = e.target.value
        let a = {...input}
        setInput(a)
    }


    function qarzsana(e) {
        input.qarzsana = e.target.value
        let a = {...input}
        setInput(a)
    }

    const [editId, setEditId] = useState({id: '', customerGroupId: []})
    const [debt, setDebt] = useState(0)
    const [paymethod, setpaymethod] = useState('')

    function editM(id) {
        toggle()
        let a = MijozGuruxReducer.customersPageable?.customer_list?.filter(item => item.id === id)
        const mijozGroup = MijozlarGuruhReducer.customersPageable?.customer_list?.filter((item) => item.id === a[0].customerGroupId)?.map((qism) => ({
            label: qism.name,
            value: qism.id
        }))
        setValue('name', a[0].name)
        setValue('phoneNumber', a[0].phoneNumber)
        setValue('telegram', a[0].telegram)
        setValue('description', a[0].description)
        setEditId({id: id, customerGroupId: mijozGroup})
        setDebt(a[0].debt)
        input.selectvalue = users?.branchesValues.filter((item) => {
            for (const qism of a[0].branches) {
                if (qism === item.value) {
                    return item
                }
            }
        })
        let c = {...input}
        setInput(c)
    }


    useEffect(() => {
        if (users.viewCustomerAdmin && users.viewCustomer) {
            getMijozGurux({
                businessId: users.businessId,
                params: {
                    page,
                    size,
                    search
                }
            })
        } else {
            getCustomerByBrachPageable({
                businessId: users.branchId,
                params: {
                    page,
                    size,
                    search
                }
            })
        }
    }, [MijozGuruxReducer.current, mainBranchId, page, size, search])

    useEffect(() => {
        getMijozLarGuruh(users.businessId)
        getPay(users.businessId)
    }, [])


    function toggle() {
        setActive(!active)
        setEditId({id: "", customerGroupId: []})
        setInput((prev) => ({...prev, selectvalue: []}))
        resetField('name', '')
        resetField('phoneNumber', '')
        resetField('telegram', '')
    }


    const [qarzuz, setqarzuz] = useState(false)

    function toggle2() {
        setqarzuz(!qarzuz)
    }

    const [guruhToggle, setGuruhToggle] = useState(false)

    function toggle3() {
        setGuruhToggle(!guruhToggle)
    }

    const [deletemodal, setdeletemodal] = useState(false)
    const [deleteID, setdeletID] = useState('')

    function deleteFunc() {
        deleteMijozGurux(deleteID)
        deleteModaltoggle('')
        setSaveModal(true)
    }

    function deleteModaltoggle(item) {
        setdeletemodal(!deletemodal)
        setdeletID(item)
    }

    useEffect(() => {
        if (MijozGuruxReducer.deleteSuccess) {
            getMijozGurux(users.businessId)
        }
        setSaveModal(false)
    }, [MijozGuruxReducer.deleteCurrent])

    function save(data) {
        saveMijozGurux({
            ...data, lidCustomer: false, customerGroupId: editId?.customerGroupId[0]?.value,
            branches: input?.selectvalue?.map((item) => item.value)
        })
        reset('')
        setInput((prev) => ({...prev, selectvalue: []}))
        setEditId({id: '', customerGroupId: []})
    }

    const [repayID, setrepayID] = useState('')

    function repayment(id) {
        toggle2()
        getCustomerOne(id)
        setrepayID(id)
    }

    const dateObj = new Date();

    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObj.getDate()).slice(-2);
    const year = dateObj.getFullYear();

    const shortDate = `${year}-${month}-${day}`;

    useEffect(() => {
        input.qarzsana = shortDate
        let a = {...input}
        setInput(a)
    }, [])

    function saverepay() {
        repaymentcustomer({
            repayment: input.qarzuzish,
            id: repayID,
            paymentMethodId: paymethod,
            payDate: input.qarzsana
        })
        input.qarzuzish = ''
        let a = {...input}
        setInput(a)
        setrepayID('')
        toggle2()
    }


    const [saveModal, setSaveModal] = useState(false)

    function onSubmit(data) {
        if (editId.id === '') {
            save(data)
        } else {
            const customerGroupId = editId?.customerGroupId ?
                editId?.customerGroupId[0]?.value : null
            editMijozGurux({
                ...data, branches: input.selectvalue?.map((item) => item.value),
                id: editId.id, customerGroupId: customerGroupId,
                debt, lidCustomer: false
            })
        }
        setInput((prev) => ({...prev, selectvalue: []}))
        setEditId({id: '', customerGroupId: []})
        setLoading(false)
        setSaveModal(true)
    }

    useEffect(() => {
        if (MijozGuruxReducer.saveBoolean) {
            setEditId({id: '', customerGroupId: []})
            resetField('name', '')
            resetField('phoneNumber', '')
            resetField('telegram', '')
            resetField('description', '')
            setDebt(0)
            // toggle()
            if (active === true) {
                toggle()
            }
            setFilename('')
            setFileSize('')
            setfile(null)
            input.branchid = []
            let a = {...input}
            setInput(a)
        }
        setSaveModal(false)
    }, [MijozGuruxReducer.current])


    const [loading, setLoading] = useState(false)
    const [files, setfile] = useState(null)
    const [fileName, setFilename] = useState('')
    const [fileSize, setFileSize] = useState('')

    useEffect(() => {
        setLoading(true)
    }, [MijozGuruxReducer.getBoolean])
    useEffect(() => {
        setLoading(false)
    }, [])

    function importfile(e) {
        setfile(e.target.files[0])
        setFilename(e.target.files[0].name)
        setFileSize(e.target.files[0].size)
    }

    function saveProductByExcel() {
        const formData = new FormData();
        formData.append('file', files)
        saveExcelCustomer({
            branchId: mainBranchId ? mainBranchId : users.branchId,
            file: formData
        })
        setSaveModal(true)
    }


    const [fileUrl, setFileUrl] = useState(null);
    const [download, setDownload] = useState(false)
    const [openSms, setOpenSms] = useState(false)
    const [smsMessage, setSmsMessage] = useState(null)
    const [shablonId, setShablonId] = useState(null)


    function smsToggle() {
        setOpenSms(!openSms)
    }

    function getFilesById() {
        setFileUrl(`${BaseUrl}/customer/export/${mainBranchId ? mainBranchId : users.branchId}`)
        setDownload(!download)
    }

    useEffect(() => {
        setTimeout(() => {
            setFileUrl(null)
        }, 500)
    }, [download])


    function handleEmbedUnload() {
        setFileUrl(null);
    }

    const [selectedItems, setSelectedUsers] = useState([]);

    const [smsId, setSmsId] = useState([])
    const [filter, setFilter] = useState(false)
    const [customerGroup, setCustomerGroup] = useState(null)

    function sms(id) {
        smsToggle()
        smsId.push(id)
    }

    function changeselect(e) {
        input.selectvalue = e
        input.branchid = []
        e.map(item => {
            let b = input.branchid
            b.push(item.value)
        })
        let a = {...input}
        setInput(a)
    }

    function groupChange(e) {
        setEditId(((prev) => ({...prev, customerGroupId: [e]})))
    }

    const defaultCheckedList = columns.map((item) => item.key);
    const [checkedList, setCheckedList] = useState(defaultCheckedList);

    const options = columns.map(({ key, title }) => ({
        label: title,
        value: key,
    }));
    const newColumns = columns.map((item) => ({
        ...item,
        hidden: !checkedList.includes(item.key),
    }));

    return (
        <div className="col-md-12 mt-2 pt-4 pb-4">
            <div className="row px-4 d-flex justify-content-between align-items-center">
                <div className="col-md-5">
                    <ExcelButtons getExcel={getFilesById} setOpenCheckList={setOpenCheckList}/>
                </div>
                <div className="col-md-2 d-flex justify-content-end">
                    {
                        users.addCustomer &&
                        <QushishButton onClick={toggle} buttonText={'Mijozlar qoshish'}/>
                    }
                </div>
            </div>
            <div className="row px-4 mt-4">
                <div className="col-md-12">
                    <SearchAnt onChange={(e) => setSearch(e)}/>
                </div>
            </div>
            <div className="row px-4 mt-4">
                <div className={`${openCheckList ? "animatsiyaBlock mb-2" : "hiddenBlock"}`}>
                    <Checkbox.Group
                        value={checkedList}
                        options={options}
                        onChange={(value) => {
                            setCheckedList(value);
                        }}
                    />
                </div>
                <div className="col-md-12">
                    {
                        users.viewCustomer || users.viewCustomerAdmin ?
                            loading ?
                                MijozGuruxReducer.customersPageable ?
                                    <div className="mt-4 table-responsive">
                                        <CommonTable size={size} page={page} handleLimitChange={handleLimitChange}
                                                     handlePageChange={handlePageChange}
                                                     total={MijozGuruxReducer.customersPageable.totalItems}
                                                     scroll={{x: 1400, y: 400}}
                                                     pagination={true}
                                                     data={MijozGuruxReducer.customersPageable?.customer_list}
                                                     columns={newColumns}
                                        />
                                    </div>
                                    : <div>
                                        <h4 className={'text-center'}>{MijozGuruxReducer.message}</h4>
                                    </div> : <Loading/> : ''
                    }
                </div>
            </div>

            <div className="rowStyleMIG">
                <div className={'mt-3 mb-3'}>

                    <div className="izlashBox2">
                        {
                            users.branches.length > 1 ?
                                <select value={mainBranchId} onChange={(e) => {
                                    setMainBranchId(e.target.value)
                                }}>
                                    {
                                        users.viewCustomerAdmin && users.viewCustomer ?
                                            <option value={''}>{t('Hamkorlar.b')}</option> : ''
                                    }
                                    {
                                        users.branches.map(item =>
                                            <option value={item.id}>{item.name}</option>)
                                    }
                                </select> : ''
                        }
                    </div>
                </div>


                <span className={'d-flex align-items-center gap-3'}>
                            <a href={Example} download style={{textDecoration: 'none'}}> <button
                                className={'customer-shablon'}>
                                {t('Hamkorlar.tsy')}<img src={importExcel} alt={'excel'} width={24}
                                                         height={24}/> </button></a>

                            <label className={'customer-shablon'} htmlFor="choose">{t('Hamkorlar.iqfnt')}<img
                                src={skripka} alt={'skripka'} width={24} height={24}/></label>
                            <input onChange={importfile} hidden={'1'} id={'choose'} className={'mt-4'} type="file"/>
                            <strong
                                className={'ms-1 customer-shablon-text'}>{fileName} ({parseFloat(fileSize / 1024).toFixed(2)}.KB)  </strong>
                            <button onClick={saveProductByExcel}
                                    className={'customer-shablon-save'}>{t('Darslik.s')}</button>
                    </span>

                {/*<button onClick={smsToggle}*/}
                {/*        className={'btn btn-primary mt-4 ms-2'}>{t(`tes.136`)}</button>*/}

            </div>
            {/*CUSTOMER ADD */}
            <Modal size={'lg'} isOpen={active} toggle={toggle}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ModalHeader>
                        {t('CustomAll.4')}
                    </ModalHeader>
                    <ModalBody>
                        <div className="row d-flex flex-wrap">
                            <div className="col-md-6 p-2">
                                <label htmlFor={'nomi'}>{t('Buttons.13')}</label>
                                <input
                                    {...register('name', {required: true})}
                                    placeholder={errors.name ? errors.name?.type === "required" && "Name is required" : 'name'}
                                    defaultValue={''}
                                    id={'nomi'} type="text"
                                    className={'form-control'}/>
                            </div>
                            <div className="col-md-6 p-2">
                                <label htmlFor={'filial'}>{t('CustomAll.5')}</label>
                                <Select options={users?.branchesValues} isMulti={true}
                                        value={input.selectvalue}
                                        class={'form-control'} onChange={changeselect} required={true}/>
                            </div>
                            <div className="col-md-6 p-2">
                                <label htmlFor={'tel'}>{t('Buttons.14')} {t(`tes.138`)} (
                                    998901234567 )</label>
                                <input type="number" id='phoneNumberInput'
                                       {...register('phoneNumber', {required: true})}
                                       placeholder={errors.phoneNumber ? errors.phoneNumber?.type === "required" && "Phone num is required" : 'phoneNumber'}
                                       defaultValue={''}
                                       className={'form-control'}/>
                            </div>
                            {/*<div className="col-md-6">*/}
                            {/*    <label htmlFor={'foizda'}>{t('Buttons.15')}</label>*/}
                            {/*    <input type="text"*/}
                            {/*           {...register('telegram', {required: true})}*/}
                            {/*           placeholder={errors.telegram ? errors.telegram?.type === "required" && "Telegram is required" : 'telegram'}*/}
                            {/*           defaultValue={''}*/}
                            {/*           className={'form-control mb-3 mt-1'}*/}
                            {/*           id={'foizda'}/>*/}
                            {/*</div>*/}
                            <div className="col-md-6 p-2">
                                <label htmlFor={'m'}>{t('CustomAll.6')}</label>
                                <Select
                                    id={'m'}
                                    onChange={groupChange}
                                    placeholder={t('Hamkorlar.mgt')}
                                    isClearable={true}
                                    value={editId?.customerGroupId}
                                    options={MijozlarGuruhReducer.mijozGuruh?.map((item) => ({
                                        label: item.name,
                                        value: item.id
                                    }))}
                                />
                            </div>
                            <div className="col-md-6 p-2">
                                <label htmlFor="">{t('Hamkorlar.eslatma')}</label>
                                <input type="text" {...register('description', {required: false})}
                                       placeholder={errors.description ? errors.description?.type === "required" && "Description is not required" : 'description'}
                                       defaultValue={''}
                                       className={'form-control'}/>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className={'btn btn-danger'} type={"button"}
                                onClick={toggle}>{t('Buttons.7')}</button>
                        <button className={'btn btn-success'} type={"submit"}>{t('Buttons.6')}</button>
                    </ModalFooter>

                </form>

            </Modal>
            {/*SMS MODAL*/}
            <Modal isOpen={openSms} toggle={smsToggle}>
                <ModalHeader>
                    <h5>{t('tes.136')}</h5>
                </ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className=" col-md-12">
                            <label htmlFor="">{t('Hamkorlar.shablon')}</label>
                            <select value={shablonId} onChange={(e) => setShablonId(e.target.value)}
                                    className={'form-control'}>
                                <option value={null}>{t('Hamkorlar.tanlash')}</option>
                                {
                                    shablonReducer.shablon ?
                                        shablonReducer.shablon.map((i, d) => <option key={d}
                                                                                     value={i.id}>{i.name}</option>) :
                                        <option value={null}>- - -</option>
                                }
                            </select>
                        </div>
                        <div className="mt-2 col-md-12">
                            <label htmlFor="">{t('tes.139')}</label>
                            <textarea value={smsMessage} onChange={(e) => setSmsMessage(e.target.value)}
                                      className={'form-control'} cols="5" rows="5">

                            </textarea>
                        </div>

                    </div>
                </ModalBody>
                <ModalFooter>
                    <button onClick={() => {
                        sendSms({
                            key: 'CUSTOMERS',
                            message: smsMessage,
                            // mobilePhone: phone,
                            shablonId,
                            idList: selectedItems.length > 0 ? selectedItems : smsId
                        })
                        setSmsMessage('')
                        setSelectedUsers([])
                        setShablonId(null)
                        setSmsId([])
                        setSmsMessage(null)
                        smsToggle()
                    }} className={'btn btn-primary'}>{t('Hamkorlar.saqlash')}</button>
                    <button onClick={smsToggle}
                            className={'btn btn-outline-primary'}>{t('Hamkorlar.chiqish')}</button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={deletemodal}
                   toggle={deleteModaltoggle}>
                <ModalBody>
                    <h5>{t('Buttons.12')} ?</h5>
                </ModalBody>
                <ModalFooter>
                    <button
                        onClick={() => deleteFunc(deleteID)}
                        className={'btn btn-danger'}>Yo'q
                    </button>
                    <button
                        onClick={() => deleteModaltoggle('')}
                        className={'btn btn-success'}>Ha
                    </button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={qarzuz} toggle={toggle2}>
                <ModalHeader>
                    Balansni o'zgartirish
                </ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-md-6">
                            <label
                                htmlFor={'l'}>Miqdor</label>
                            <input type="number"
                                   className={'form-control'}
                                   value={input.qarzuzish}
                                   onChange={qarzuzish}/>
                        </div>
                        <div className="col-md-6">
                            <label
                                htmlFor="">{t('Hamkorlar.muddat')}</label>
                            <input type="date"
                                   value={input.qarzsana}
                                   onChange={qarzsana}
                                   className={'form-control'}/>
                        </div>
                        <div className="col-md-12">
                            <label
                                htmlFor=""> To'lov usuli
                            </label>
                            <select value={paymethod}
                                    onChange={(e) => setpaymethod(e.target.value)}
                                    className={'form-control'}>
                                <option
                                    value="">{t('Hamkorlar.tanlash')}
                                </option>
                                {
                                    PayReducer.paymethod ?
                                        PayReducer.paymethod.map((item) =>
                                            <option
                                                value={item.id}
                                                key={item.id}>{item.type}</option>) : 'not found'
                                }
                            </select>
                            {
                                !paymethod && <p className={'text-danger text-center m-0'}>To'lov usulini tanlang</p>
                            }
                        </div>
                    </div>

                </ModalBody>
                <ModalFooter>
                    <button
                        className={'btn btn-danger'}
                        onClick={toggle2}>{t('Buttons.7')}</button>
                    {
                         paymethod  &&
                            <button
                                className={'btn btn-success'}
                                onClick={saverepay}>{t('Buttons.6')}</button>
                    }

                </ModalFooter>
            </Modal>
            <ModalLoading isOpen={saveModal}/>
            {fileUrl ?
                <embed src={fileUrl} onUnload={handleEmbedUnload} type="application/pdf" width="100%"
                       height="0"/> : ''
            }

        </div>
    )
}

export default connect((MijozGuruxReducer, notificationReducer, PayReducer, QarzuzishReducer, shablonReducer, branchreducer, MijozlarGuruhReducer, users), {
    getMijozGurux, getShablon,
    qarzuzishCustomer, sendSms,
    getMijozLarGuruh,
    getMijozGuruxByBranch,
    saveMijozGurux,
    getPay,
    saveExcelCustomer,
    repaymentcustomer,
    downloadCustomers,
    getCustomerOne,
    saveMijozLarGurux,
    editMijozGurux,
    getbranch,
    deleteMijozGurux, deleteCustomers
})(Mijozlarguruxi)
