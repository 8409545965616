import { Typography, Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const PageHeader = () => {
  const { t } = useTranslation();

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          {t('Sidebar1.vazifalar')}
        </Typography>
        <Typography variant="subtitle2">
          {t('Check out the tasks in your calendar')}
        </Typography>
      </Grid>
    </Grid>
  );
};

PageHeader.propTypes = {
  handleCreateEvent: PropTypes.func
};
export default PageHeader;
