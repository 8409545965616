import React, { Component } from 'react';
import CanvasJSReact from './assets/canvasjs.react';
import {t} from "i18next";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class ColumnChart extends Component {
		render() {
		const options = {
			title: {
				text: "Ko'p sotilgan mahsulotlar"
			},
			animationEnabled: true,
			data: [
			{
				// Change type to "doughnut", "line", "splineArea", etc.
				type: "column",
				dataPoints: [
					{ label: "Apple",  y: 10  },
					{ label: "Orange", y: 15  },
					{ label: "Banana", y: 25  },
					{ label: "Mango",  y: 30  },
					{ label: "Grape",  y: 28  }
				]
			}
			]
		}

		return (
		<div>
			<h3>{t('Lids.Jadval')}</h3>
			<CanvasJSChart options = {options}
				/* onRef={ref => this.chart = ref} */
			/>
			{/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
		</div>
		);
	}
}

export default ColumnChart;
