import React from 'react';
import {useTranslation} from "react-i18next";

function Loading() {
  const {t} = useTranslation();

    return (
        <div  className={'d-flex justify-content-center align-items-center'}>
            <div className="spinner-border me-2" role="status">
            </div>
            <h4>{t('newChanges.loading_key')}</h4>
        </div>
    );
}

export default Loading;
