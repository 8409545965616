import './taxrir.css'
import {useForm} from 'react-hook-form'
import {Link, Route, Switch, useHistory} from "react-router-dom";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import LavozimReducer, {getLavozim, saveLavozim} from "../../reducer/LavozimReducer";
import XodimReducer, {saveXodim, getXodim, editXodim, getXodimID} from "../../reducer/XodimReducer";
import users from "../../../../../../reducer/users";
import branchreducer, {getbranch} from "../../../../../../reducer/branchreducer";
import {toast} from "react-toastify";
import {savephoto} from "../../../../../../reducer/photoreducer";
import Select from "react-select";
import {useTranslation} from "react-i18next";
import ModalLoading from "../../../../../ModalLoading";
import JobReducer, {getJob, saveJob} from "../../../HR/reducer/JobReducer";
import {IconButton, Tooltip} from "@mui/material";
import formatDate from "../../../../../../util";

function Taxrirlash({
                        getJob,
                        JobReducer,
                        getLavozim,
                        saveXodim,
                        LavozimReducer,
                        getXodim,
                        getXodimID,
                        XodimReducer,
                        users,
                        match, saveJob,
                        editXodim,
                        getbranch,
                        branchreducer
                    }) {

    useEffect(() => {
        getLavozim(users.businessId)
        getbranch(users.businessId)
        if (match.params.id !== undefined) {
            getXodimID(match.params.id)
        }
        getJob(users.businessId)
    }, [])

    const {t} = useTranslation()
    const [input, setInput] = useState(
        {
            photoid: '',
            branchid: [],
            selectvalue: [],
            enabled: true
        }
    );
    const history = useHistory()

    const {register, setValue, handleSubmit, resetField, formState: {errors}} = useForm()


    function editx() {
        setValue('username', XodimReducer.oneXodim?.username);
        setValue('firstName', XodimReducer.oneXodim?.firstName);
        setValue('lastName', XodimReducer.oneXodim?.lastName);
        setValue('roleId', XodimReducer.oneXodim?.roleId);
        setValue('phoneNumber', XodimReducer.oneXodim?.phoneNumber)
        setValue('jobId', XodimReducer.oneXodim?.jobId)
        setValue('arrivalTime', XodimReducer.oneXodim?.arrivalTime)
        setValue('leaveTime', XodimReducer.oneXodim?.leaveTime)
        input.photoid = XodimReducer.oneXodim?.photoId
        input.enabled = XodimReducer.oneXodim?.enabled
        setBirthday(XodimReducer.oneXodim?.birthday)
        input.selectvalue = XodimReducer.oneXodim.branches?.map(({
                                                                     name: label,
                                                                     id: value, ...rest
                                                                 }) => ({label, value, ...rest}));

        let ids = []
        XodimReducer.oneXodim?.branches?.map(item => {
            ids.push(item.id,)
        })
        input.branchid = ids
        let a = {...input}
        setInput(a)
    }

    function saqla(data) {
        const success = saveXodim(
            {
                ...data,
                jobId: data.jobId !== "NULL" ? data.jobId : null,
                branchId: input.branchid,
                businessId: users.businessId,
                enabled: true,
                photoId: null,
                active: true,
                address: null,
                birthday,
                description: null,
                email: null,
                probation: null,
                salary: null,
                sex: true,
                workingTime: null,

            }
        )
    }

    function saveEdit(data) {
        editXodim({
            ...data,
            jobId: data.jobId !== "NULL" ? data.jobId : null,
            branchId: input.branchid,
            id: match.params.id,
            businessId: users.businessId,
            photoId: input.photoid,
            enabled: input.enabled,
            active: true, address: null, birthday: birthday, description: null, email: null,
            probation: null, salary: null, sex: true, workingTime: null
        })
    }

    const [openSaveJob, setSaveOpenJob] = useState(false)
    const [jobName, setJobName] = useState('')
    const [jobDescription, setJobDescription] = useState('')
    const [birthday, setBirthday] = useState(null)

    function openSaveJobToggle() {
        setSaveOpenJob(!openSaveJob)
    }

    function changeselect(e) {
        input.selectvalue = e
        input.branchid = []
        e.map(item => {
            let b = input.branchid
            b.push(item.value)
        })
        let a = {...input}
        setInput(a)
    }

    useEffect(() => {
        getLavozim(users.businessId)
        if (match.params.id) {
            editx()
        }
    }, [XodimReducer.current])


    const [saveModal, setSaveModal] = useState(false)

    useEffect(() => {
        if (XodimReducer.saveUserBool) {
            history.push('/main/hr/userTable')
        }
        setSaveModal(false)
    }, [XodimReducer.saveCurrent])

    function onSubmit(data) {
        if (match.params.id) {
            saveEdit(data)
        } else {
            saqla(data)
        }
        setSaveModal(true)
    }

    function saqlaJob() {
        saveJob({
            businessId: users.businessId,
            name: jobName,
            description: jobDescription
        })
        setSaveModal(true)
    }

    useEffect(() => {
        if (JobReducer.saveBoolean) {
            setJobName('')
            setJobDescription('')
            setSaveOpenJob(false)
            getJob(users.businessId)
        }
        setSaveModal(false)
    }, [JobReducer.current])

    return (
        <div className={'ht'}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h5 className={'text-center mt-4'}>{t('Employ.13')}</h5>
                <div className="row">
                    <div className="col-sm-6 mb-2">
                        <label htmlFor={'login1'}>{t('Employ.7')}</label>
                        <input type="text" id={'login1'}
                               {...register('username', {required: true})}
                               placeholder={errors.username ? errors.username?.type === "required" && "Username is required" : 'Login'}

                               className={'form-control'}/>
                    </div>
                    <div className="col-sm-6 mb-2">
                        <label htmlFor={'ismi'}>{t('Employ.8')}</label>
                        <input type="text" id={'ismi'}
                               {...register('firstName', {required: true})}
                               placeholder={errors.firstName ? errors.firstName?.type === "required" && "FirstName is required" : 'Name'}
                               defaultValue={''}
                               className={'form-control'}/>
                    </div>
                    <div className="col-sm-6 mb-2">
                        <label htmlFor={'login2'}>{t('Employ.9')}</label>
                        <input type="text" id={'login2'}
                               required={"required"}
                               {...register('lastName', {required: true})}
                               placeholder={errors.lastName ? errors.lastName?.type === "required" && "LastName is required" : 'LastName'}
                               defaultValue={''}
                               className={'form-control'}/>
                    </div>
                    <div className="col-sm-6 mb-2">
                        <label htmlFor="">{t('as.18')}</label>
                        <input
                            {...register("phoneNumber", {required: false})}
                            placeholder={errors.phoneNumber ? errors.phoneNumber.type === "required" && "Phone is required" : "phoneNumber"}
                            type="number" className={'form-control'}/>
                    </div>
                    <div className="col-sm-6 mb-2">
                        <label htmlFor={'log3'} className={'mt-3'}>{t('Employ.16')}</label>
                        <input type="text"
                               {...register("password", {required: match.params.id ? false : true})}
                               placeholder={errors.password ? errors.password.type === "required" && "Password is required" : "password"}
                               defaultValue={''}
                               className={'form-control'} id={'log3'}/>
                    </div>
                    <div className="col-sm-6 mb-2">
                        <label className={'mt-3'} htmlFor="">{t('as.19')}</label>
                        {
                            JobReducer.jobs ?
                                <div className={'d-flex justify-content-between'}>
                                    <select
                                        {...register("jobId", {required: match.params.id ? false : true})}
                                        className={'form-control'}>
                                        <option value={"NULL"}>{t('as.20')}</option>
                                        {
                                            JobReducer.jobs.map(item => <option
                                                value={item.id}>{item.name}</option>)
                                        }
                                    </select>
                                    <h3 style={{cursor: "pointer"}}>
                                        <Tooltip title={'+KASB'} arrow>
                                            <IconButton onClick={openSaveJobToggle}>
                                                +
                                            </IconButton>
                                        </Tooltip>
                                    </h3>
                                </div>
                                :
                                <div><h4 className={'text-center'}>{t('as.21')}</h4></div>
                        }
                    </div>
                    <div className="col-sm-6 mb-2">
                        <label htmlFor={'lavozimi'} className={'mt-3'}>{t('Employ.18')}</label>
                        <select id={'lavozimi'}
                                {...register('roleId', {required: true})}
                                defaultValue={XodimReducer.one}
                                className={'form-control'}>
                            {
                                LavozimReducer.lavozimlar ?
                                    LavozimReducer.lavozimlar.filter(value => value.name !== 'Admin').map((item, index) =>
                                        <option value={item.id}>{item.name}</option>) : ''
                            }
                        </select>
                    </div>
                    <div className="col-sm-6 mb-2">
                        <label className={'mt-3 text-center'}>{t('Employ.19')}</label>
                        <Select options={branchreducer.branches} isMulti={true}
                                value={input.selectvalue}
                                class={'form-control'} onChange={changeselect}/>

                    </div>
                    <div className="col-sm-6 mb-2">
                        <label htmlFor={'arrivalTime'}>{t('as.22')}</label>
                        <input type={'time'} id={'arrivalTime'}
                               {...register('arrivalTime',
                                   {required: true,}
                               )
                               }
                               className={'form-control'}/>
                    </div>
                    <div className="col-sm-6 mb-2">
                        <label htmlFor={'leaveTime'}>{t('as.23')}</label>
                        <input type="time" id={'leaveTime'}
                               {...register('leaveTime',
                                   {required: true}
                               )
                               }
                               defaultValue={''}
                               className={'form-control'}/>
                    </div>
                    <div className="col-sm-6 mb-2">
                        <label htmlFor="">{t('as.24')}</label>
                        <input className={'form-control'} type="date" value={birthday}
                               onChange={(e) => setBirthday(e.target.value)}/>
                    </div>
                </div>
                <button type={"submit"}
                        className={'btn mt-3 form-control btn-primary btnSaqlash'}>{t('Buttons.6')}</button>
            </form>

            {/*ADD JOB*/}
            <Modal isOpen={openSaveJob} toggle={openSaveJobToggle} size={'xl'}>
                <ModalHeader>
                    {
                        <h5>{t('as.25')}</h5>
                    }
                </ModalHeader>
                <ModalBody>
                    {
                        <div className="row">
                            <div className="col-md-12 d-flex justify-content-between">
                                <div className="col-md-6">
                                    <label htmlFor="">{t('as.26')}</label>
                                    <input type="text" value={jobName} onChange={(e) => setJobName(e.target.value)}
                                           className={'form-control'}/>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="">{t('as.27')}</label>
                                    <input type="text" value={jobDescription}
                                           onChange={(e) => setJobDescription(e.target.value)}
                                           className={'form-control'}/>
                                </div>
                            </div>
                        </div>
                    }
                </ModalBody>
                <ModalFooter>
                    <button onClick={saqlaJob} className={'btn btn-outline-primary'}>{t('as.28')}</button>
                    <button onClick={openSaveJobToggle} className={'btn btn-primary'}>{t('as.29')}</button>
                </ModalFooter>
            </Modal>


            <ModalLoading isOpen={saveModal}/>
        </div>
    )
}

export default connect((LavozimReducer, XodimReducer, users, branchreducer, JobReducer), {
    getLavozim,
    saveLavozim,
    saveXodim,
    getXodim,
    saveJob,
    getJob,
    editXodim,
    getbranch,
    getXodimID
})(Taxrirlash)
