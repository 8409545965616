import React, {useState, useEffect} from 'react';
import {
    Box, Button,
    Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl,
    Grid, IconButton, InputLabel, MenuItem, Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead, TablePagination,
    TableRow, Tabs, TextField, ToggleButton, ToggleButtonGroup, Tooltip,
    Typography, FormLabel, RadioGroup, FormControlLabel, Radio
} from '@mui/material';
import {Link} from "react-router-dom";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import {styled} from "@mui/material/styles";
import TableRowsTwoToneIcon from "@mui/icons-material/TableRowsTwoTone";
import GridViewTwoToneIcon from "@mui/icons-material/GridViewTwoTone";
import {Formik} from "formik";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import users from "../../../../../reducer/users";
import {connect} from "react-redux";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import formatDate, {formatDateWithMonth, formatIcon} from "../../../../../util";
import bonusReducer, {getBonus} from "../../../../../reducer/bonusReducer";
import XodimReducer, {getXodimByBranch} from "../../Hodimlar/reducer/XodimReducer";
import prizeReducer, {savePrize, getPrize, viewPrize} from "../../../../../reducer/prizeReducer";
import LaunchTwoToneIcon from "@mui/icons-material/LaunchTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ModalLoading from "../../../../ModalLoading";
import "./Award/award.css"
import Award from "./Award/Award";

function Reword({bonusReducer,viewPrize, getBonus, users, getXodimByBranch, XodimReducer, prizeReducer, savePrize, getPrize}) {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [openComponent,setOpenComponent] = useState(1);


    useEffect(() => {
        getBonus(users.businessId)
        getXodimByBranch(users.branchId)
        getPrize(users.branchId)
    }, [])


    const handleCreateUserOpen = () => {
        setOpen(true);
    };

    const handleCreateUserClose = () => {
        setOpen(false);
    };

    const [statusFormik] = useState({
        bonusId: '',
        date: formatDateWithMonth(new Date().getTime()),
        branchId: users.branchId,
        description: '',
        count: 0,
        deadline: '',
        given: false,
        radioName: '',
        userId: ''
    })

    const [openView, setOpenView] = useState(false)

    function openViewToggle(){
        setOpenView(!openView)
    }


    const [filters, setFilters] = useState({
        role: null
    });



    const TabsWrapper = styled(Tabs)(
        ({theme}) => `
    @media (max-width: ${theme.breakpoints.values.md}px) {
      .MuiTabs-scrollableX {
        overflow-x: auto !important;
      }

      .MuiTabs-indicator {
          box-shadow: none;
      }
    }
    `
    );
    const handleTabsChange = (_event, tabsValue) => {
        let value = null;

        if (tabsValue !== 'all') {
            value = tabsValue;
        }

        setFilters((prevFilters) => ({
            ...prevFilters,
            role: value
        }));

    };

    const [saveModal,setSaveModal] =  useState(false)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (_event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function viewUserId(userId){
        openViewToggle()
        viewPrize({
            userId: userId,
            params:{
                branchId: users.branchId
            }
        })
    }

    useEffect(()=>{
        if(prizeReducer.saveBoolean){
            getPrize(users.branchId)
            handleCreateUserClose()
        }
        setSaveModal(false)
    },[prizeReducer.saveCurrent])

    return (
        <>
            <TabsWrapper
                onChange={handleTabsChange}
                scrollButtons="auto"
                textColor="secondary"
                value={filters.role || 'all'}
                variant="scrollable"
            >
                <ToggleButtonGroup
                    sx={{
                        mt: {xs: 2, sm: 0}
                    }}
                    exclusive
                >
                    <ToggleButton onClick={()=>setOpenComponent(1)} >
                        <TableRowsTwoToneIcon/>
                    </ToggleButton>
                    <ToggleButton onClick={()=>setOpenComponent(2)} >
                        <GridViewTwoToneIcon/>
                    </ToggleButton>
                </ToggleButtonGroup>
            </TabsWrapper>
            {
                openComponent == 1 ?
                    <div className={'colorback mt-4 m-3 p-4'}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <Typography variant="h4" component="h4" gutterBottom>
                                    {t('Salary.rsh')}
                                </Typography>

                            </Grid>

                        </Grid>

                        <Box
                            display="flex"
                            alignItems="center"
                            flexDirection={{xs: 'column', sm: 'row'}}
                            justifyContent={{xs: 'center', sm: 'space-between'}}
                            pb={3}
                        >
                            {
                                users.addPrizeChecked?
                                    <div className={'d-flex mt-4 mb-4'}>
                                        <Grid item>
                                            <Button
                                                sx={{
                                                    mt: {xs: 2, sm: 0}
                                                }}
                                                onClick={handleCreateUserOpen}
                                                variant="contained"
                                                startIcon={<AddTwoToneIcon fontSize="small"/>}
                                            >
                                                {t('Salary.rshq')}
                                            </Button>
                                        </Grid>
                                    </div>:''
                            }


                        </Box>


                        {/*<div className={'mb-2'}>*/}
                        {/*    <input type="search" placeholder={t('ol.1')} className={'form-control'}/>*/}
                        {/*</div>*/}
                        {
                            users.viewPrizeChecked?
                                prizeReducer.prize ?
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>#</TableCell>
                                                    <TableCell>{t('Salary.mn')}</TableCell>
                                                    <TableCell>{t('Salary.e')}</TableCell>
                                                    <TableCell align={'center'}>{t('Salary.bh')}</TableCell>
                                                    <TableCell align={'center'}>{t('Salary.b')}</TableCell>
                                                    <TableCell align={'center'}>{t('Davomat.amal')}</TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    prizeReducer.prize.map((item, index) => <TableRow key={index}>
                                                        <TableCell>{index + 1}</TableCell>
                                                        <TableCell>{item?.bonusName}</TableCell>
                                                        <TableCell>{item?.description}</TableCell>
                                                        <TableCell align={'center'}>{item.firstName}</TableCell>
                                                        <TableCell align={'center'}>
                                                            {item.given===true? <CheckCircleIcon color={'success'}/>:
                                                                <CancelIcon color={'error'}/>}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography noWrap>
                                                                <Tooltip title={t('ol.50')} arrow>
                                                                    <IconButton onClick={()=>viewUserId(item.userId)} color="primary">
                                                                        <LaunchTwoToneIcon fontSize="small"/>
                                                                    </IconButton>
                                                                </Tooltip>

                                                                <Tooltip title={t('ol.15')} arrow>
                                                                    <IconButton
                                                                        // onClick={handleConfirmDelete}
                                                                        color="primary"
                                                                    >
                                                                        <DeleteTwoToneIcon fontSize="small"/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>)
                                                }


                                            </TableBody>
                                        </Table>
                                    </TableContainer> : <div className={'mt-4'}><h4 className={'text-center'}>{t('Darslik.mtt')}</h4></div>:''
                        }
                        {
                            prizeReducer.prize ?
                                <Box className={'d-flex justify-content-end'} p={2}>
                                    <TablePagination
                                        component="div"
                                        count={prizeReducer.prize.length}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        page={page}
                                        rowsPerPage={rowsPerPage}
                                        rowsPerPageOptions={[5, 10, 15]}
                                    />
                                </Box> : ''
                        }
                    </div>
                : <Award/>
            }
            <Dialog
                fullWidth
                maxWidth="md"
                open={open}
                onClose={handleCreateUserClose}
            >
                <DialogTitle
                    sx={{
                        p: 3
                    }}
                >
                    <Typography variant="h4" gutterBottom>
                    {t('Salary.mq')}
                    </Typography>
                </DialogTitle>
                <Formik
                    initialValues={statusFormik}
                    validationSchema={Yup.object().shape({
                        date: Yup.string()
                            .max(255)
                            .required(t('ol.51')),
                        userId: Yup.string()
                            .max(255)
                            .required(t('ol.52')),
                        bonusId: Yup.string()
                            .max(255)
                            .required(t('ol.53')),
                        branchId: Yup.string()
                            .max(255)
                            .required(t('ol.54')),
                    })}
                    onSubmit={async (
                        _values,
                    ) => {
                        savePrize({
                            ..._values, given: !_values.given,
                            lid: !(_values.radioName === '' || _values.radioName === 'task'),
                            task: !(_values.radioName === '' || _values.radioName === 'lid'),
                        })
                        setSaveModal(true)
                    }}
                >
                    {({
                          errors,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          touched,
                          values
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <DialogContent dividers sx={{p: 3}}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} lg={12}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={12} lg={12}>
                                                <TextField
                                                    error={Boolean(touched.description && errors.description)}
                                                    fullWidth
                                                    type='text'
                                                    helperText={touched.description && errors.description}
                                                    label={t('ol.55')}
                                                    name="description"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.color}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">{t('Salary.bs')}</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={values.bonusId}
                                                        label={t('Salary.bs')}
                                                        onChange={handleChange}
                                                        name={'bonusId'}
                                                    >
                                                        {
                                                            bonusReducer.bonus ?
                                                                bonusReducer.bonus?.filter(item => item.active === true)?.map(item =>
                                                                    <MenuItem className={'mb-1 mt-1'}
                                                                              value={item.id}>{formatIcon(item.icon, item.color)} {item.name}</MenuItem>
                                                                )
                                                                : <MenuItem>---</MenuItem>
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">{t('Salary.f')}</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={values.branchId}
                                                        label={t('Salary.f')}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            getXodimByBranch(e.target.value)
                                                        }}
                                                        name={'branchId'}
                                                    >
                                                        {
                                                            users.branches ?
                                                                users.branches?.map(item =>
                                                                    <MenuItem className={'mb-1 mt-1'}
                                                                              value={item.id}>{item.name}</MenuItem>
                                                                )
                                                                : <MenuItem>---</MenuItem>
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}
                                            >
                                                <TextField
                                                    error={Boolean(touched.date && errors.date)}
                                                    fullWidth
                                                    type='date'
                                                    helperText={touched.date && errors.date}
                                                    label={t('ol.56')}
                                                    name="date"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    defaultValue={'2020-10-2'}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">{t('Davomat.hodim')}</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={values.icon}
                                                        label={t('Davomat.hodim')}
                                                        onChange={handleChange}
                                                        name={'userId'}
                                                    >
                                                        {
                                                            XodimReducer.xodimlar ?
                                                                XodimReducer.xodimlar?.map(item =>
                                                                    <MenuItem className={'mb-1 mt-1'}
                                                                              value={item.id}>{item.firstName} {item.lastName}</MenuItem>
                                                                )
                                                                : <MenuItem>---</MenuItem>
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid display={'flex'} alignItems={'center'} item xs={6} lg={6}>
                                                <Checkbox
                                                    checked={values.given}
                                                    onChange={handleChange}
                                                    name={'given'}
                                                    sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                                />
                                                <Typography variant="h4" gutterBottom>
                                                {t('Salary.lytb')}
                                                </Typography>
                                            </Grid>
                                            {
                                                values.given ?
                                                    <>
                                                        <Grid item xs={12} md={6} lg={6}>
                                                            <FormControl>
                                                                <FormLabel
                                                                    id="demo-radio-buttons-group-label">{t('Salary.t')}</FormLabel>
                                                                <RadioGroup
                                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                                    defaultValue="task"
                                                                    name="radioName"
                                                                    onChange={handleChange}
                                                                    value={values.radioName}
                                                                >
                                                                    <FormControlLabel value="lid" control={<Radio/>}
                                                                                      label="Lid"/>
                                                                    <FormControlLabel value="task" control={<Radio/>}
                                                                                      label="Task"/>
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} md={6} lg={6}>
                                                            <TextField
                                                                error={Boolean(touched.count && errors.count)}
                                                                fullWidth
                                                                margin="normal"
                                                                helperText={touched.count && errors.count}
                                                                label={t('ol.57')}
                                                                name="count"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                type="number"
                                                                value={values.count}
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6} lg={6}>
                                                            <TextField
                                                                error={Boolean(touched.deadline && errors.deadline)}
                                                                fullWidth
                                                                margin="normal"
                                                                helperText={touched.deadline && errors.deadline}
                                                                label={t('ol.58')}
                                                                name="deadline"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                type="date"
                                                                value={values.deadline}
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                    </> : ''
                                            }

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions
                                sx={{
                                    p: 3
                                }}
                            >
                                <Button color="secondary" onClick={handleCreateUserClose}>
                                    {t('ol.48')}
                                </Button>
                                <Button
                                    type="submit"
                                    startIcon={
                                        isSubmitting ? <CircularProgress size="1rem"/> : null
                                    }
                                    disabled={Boolean(errors.submit) || isSubmitting}
                                    variant="contained"
                                >
                                    {t('ol.49')}
                                </Button>
                            </DialogActions>
                        </form>
                    )}
                </Formik>
            </Dialog>

            <Modal size={'xl'} isOpen={openView} toggle={openViewToggle}>
                <ModalHeader><h5>{t('Salary.k')}</h5></ModalHeader>
                <ModalBody>
                    {
                        prizeReducer.viewPrize?
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('Salary.n')}</TableCell>
                                    <TableCell>{t('Salary.bn')}</TableCell>
                                    <TableCell>{t('Org.eslatma')}</TableCell>
                                    <TableCell>{t('Salary.s')}</TableCell>
                                    <TableCell>{t('Salary.d')}</TableCell>
                                    {/*<TableCell>Lid</TableCell>*/}
                                    {/*<TableCell>Vazifa</TableCell>*/}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    prizeReducer.viewPrize?.map((item,index)=><TableRow key={index}>
                                        <TableCell>{item.firstName}</TableCell>
                                        <TableCell>{item?.bonusName}</TableCell>
                                        <TableCell>{item?.description}</TableCell>
                                        <TableCell>{formatDate(item.date)}</TableCell>
                                        <TableCell>{formatDate(item.deadline)}</TableCell>
                                        {/*<TableCell>{item.lid?'+':''}</TableCell>*/}
                                        {/*<TableCell>{item.task?'+':''}</TableCell>*/}
                                    </TableRow>)
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>:<div><h4 className={'text-center'}>{t('Darslik.mtt')}</h4></div>
                    }
                </ModalBody>
                <ModalFooter>
                    <button onClick={openViewToggle} className={'btn btn-primary'}>{t('Davomat.chiqish')}</button>
                </ModalFooter>
            </Modal>

            <ModalLoading isOpen={saveModal}/>
        </>

    );
}

// export default Lids;
export default connect((users, bonusReducer, XodimReducer, prizeReducer), {
    getBonus,
    getXodimByBranch,
    savePrize,
    getPrize,
    viewPrize
})(Reword)
