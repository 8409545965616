import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../../../../../../api";
import {toast} from "react-toastify";


const slice = createSlice({
    name: 'valyuta',
    initialState: {
        currency: null,
        message:'',
        getBoolean:false,
        saveBoolean:false,
        saveCurrent:false,
    },
    reducers: {
        getFrom: (state, action) => {
            if (action.payload.success){
                state.currency=action.payload.object
            }
            else{
                state.currency = null
                state.message='not found'
            }
            state.saveBoolean = false
        },
        getFromcurrency: (state, action) => {
            state.valyuta = action.payload.object
        },
        savefrom: (state,action) => {
            state.valyuta2.unshift(action.payload)
            toast.success('Valyuta qo`shildi')
        },
        editfrom: (state,action) => {
            if (action.payload.success){
                state.saveBoolean = true
                toast.success('Valyuta tahrirlandi')

            }
            else{
                toast.success(action.payload.message)
            }
            state.saveCurrent = !state.saveCurrent
            state.getBoolean = !state.getBoolean
        },
        deletefrom:(state,action)=>{
            toast.success('Valyuta o`chirildi')
        },
        changeactive:(state)=>{
            toast.success("Valyuta o'zgardi")
        }
    }
});

export const getValyuta=(data)=>apiCall({
    url: '/currency/'+data,
    method:'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type,
});

export const getValyutacurrency=(data)=>apiCall({
    url: '/curreny/course/get-by-business/'+data,
    method:'get',
    onSuccess: slice.actions.getFromcurrency.type
});

export const saveValyuta=(data)=>apiCall({
    url: '/currency',
    method:'post',
    data,
    onSuccess: slice.actions.savefrom.type
});

export const editValyuta=(data)=>apiCall({
    url: '/currency/'+data.id,
    method: 'put',
    data,
    onSuccess: slice.actions.editfrom.type,
    onFail: slice.actions.editfrom.type
});
export const changeActivecur=(data)=>apiCall({
    url:'/currency/active-course',
    method:'put',
    data,
    onSuccess:slice.actions.changeactive.type
})

export const deleteValyuta=(data)=>apiCall({
    url: '/currency/'+data,
    method:'delete',
    data,
    onSuccess: slice.actions.deletefrom.type
})

export default slice.reducer