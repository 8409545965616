import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../../../../../api";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";

const slice = createSlice({
    name: 'taminot',
    initialState: {
        supplierPageable:null,
        taminot: null,
        current: false,
        taminotjami: 0,
        oneSupplier: {},
        supplierPurchaseInfo:[],
        supplierPay:[],
        message: '',
        getBoolean: false,
        saveBoolean:false,
        deleteBoolean: false
    },
    reducers: {
        getFromAll: (state, action) => {
            if (action.payload.success) {
                state.taminot = action.payload.object
                let amount = 0
                action.payload.object.map(item => {
                    amount += item.storeDebt
                })
                state.taminotjami = amount
            } else{
                state.message = action.payload.message
                state.taminot = null
            }
            state.getBoolean=!state.getBoolean
            state.saveBoolean = false
        },
        getFrom: (state, action) => {
            if (action.payload.success) {
                state.supplierPageable = action.payload.object
            } else{
                state.message = action.payload.message
                state.supplierPageable = null
            }
            state.getBoolean=!state.getBoolean
            state.saveBoolean = false
        },
        getPurchaseInfo: (state, action) => {
            if (action.payload.success) {
                state.supplierPurchaseInfo = action.payload.object
            } else{
                state.message = action.payload.message
                state.supplierPurchaseInfo = null
            }
            state.getBoolean=!state.getBoolean
            state.saveBoolean = false
        },
        getPreventedInfo: (state, action) => {
            if (action.payload.success) {
                state.supplierPay= action.payload.object
            } else{
                state.message = action.payload.message
                state.supplierPay = null
            }
            state.getBoolean=!state.getBoolean
            state.saveBoolean = false
        },
        getFromOne: (state, action) => {
            if (action.payload.success) {
                state.oneSupplier = action.payload.object
            } else {
                state.oneSupplier = null
                toast.error(action.payload.message)
            }
        },
        savefrom: (state, action) => {
            if (action.payload.success) {
                 state.saveBoolean = true
                toast.success(action.payload.message)
            } else {
                toast.error(action.payload.message)
            }
            state.current = !state.current

        },
        savefrom2: (state, action) => {
            if (action.payload.success) {
                state.current = !state.current
                toast.success('Qarz o`chdi !')
            } else {
                toast.error(action.payload.message)
            }

        },
        editfrom: (state, action) => {
            if (action.payload.success) {
                state.saveBoolean = true
                toast.success('Ta\'minotchi tahrirlandi')
            } else {
                toast.error(action.payload.message)
            }
            state.current = !state.current
        },
        deletefrom: (state, action) => {
            if (action.payload.success) {
                toast.success(action.payload.message)
            } else {
                toast.error(action.payload.message)
            }
            state.current = !state.current
            state.deleteBoolean=true
        },
        savefromByExcel: (state, action) => {
            if (action.payload.success){
                state.saveBoolean =true
                toast.success(action.payload.message)
            }else {
                toast.error(action.payload.message)
            }
            state.current = !state.current
        },
    }
});

export const getTaminot = (data) => apiCall({
    url: '/supplier/get-by-business/' + data,
    method: 'get',
    onSuccess: slice.actions.getFromAll.type,
    onFail:slice.actions.getFromAll.type
});
export const getSupplierPageable = (data) => apiCall({
    url: '/supplier/get-by-business-pageable/' + data.businessId,
    method: 'get',
    params:data.params,
    onSuccess: slice.actions.getFrom.type,
    onFail:slice.actions.getFrom.type
});
export const getSupplierOne = (data) => apiCall({
    url: '/supplier/' + data,
    method: 'get',
    onSuccess: slice.actions.getFromOne.type,
    onFail: slice.actions.getFromOne.type
});
export const getSupplierPurchaseInfo = (data) => apiCall({
    url: '/supplier/supplier-purchase-info/' + data,
    method: 'get',
    onSuccess: slice.actions.getPurchaseInfo.type,
    onFail:slice.actions.getPurchaseInfo.type
});
export const getSupplierPreventedInfo = (data) => apiCall({
    url: '/supplier/supplier-prevented-info/' + data,
    method: 'get',
    onSuccess: slice.actions.getPreventedInfo.type,
    onFail:slice.actions.getPreventedInfo.type
});
export const saveTaminot = (data) => apiCall({
    url: '/supplier',
    method: 'post',
    data,
    onSuccess: slice.actions.savefrom.type,
    onFail: slice.actions.savefrom.type
});
export const saveSupplierByExcelFile = (data)=>apiCall({
    url:`/supplier/postFromExcel/`+data.businessId,
    method:'post',
    data:data.file,
    contentType:'"multipart/form-data"',
    onSuccess:slice.actions.savefromByExcel.type,
    onFail:slice.actions.savefromByExcel.type
})
export const editTaminot = (data) => apiCall({
    url: '/supplier/' + data.id,
    method: 'put',
    data,
    onSuccess: slice.actions.editfrom.type,
    onFail: slice.actions.editfrom.type
});

export const deleteTaminot = (data) => apiCall({
    url: '/supplier/' + data,
    method: 'delete',
    data,
    onSuccess: slice.actions.deletefrom.type,
    onFail: slice.actions.deletefrom.type
})
export const qarzuzishTaminot = (data) => apiCall({
    url: '/supplier/repayment/' + data.id,
    method: 'post',
    data,
    onSuccess: slice.actions.savefrom2.type,
    onFail: slice.actions.savefrom2.type
});

export default slice.reducer