import { Fragment } from 'react';
import {
  Box,
  Card,
  Typography,
  IconButton,
  CircularProgress,
  Button,
  ListItemAvatar,
  ListItemText,
  Divider,
  List,
  ListItem,
  Avatar,
  Tooltip,
  circularProgressClasses,
  styled,
  useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Chart from 'react-apexcharts';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import FiberManualRecordTwoToneIcon from '@mui/icons-material/FiberManualRecordTwoTone';
import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone';
import KeyboardArrowUpTwoToneIcon from '@mui/icons-material/KeyboardArrowUpTwoTone';
import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone';
import Scrollbar from "../../../../Scrollbar";
import Text from "../../Vazifalar/Text";
import accountreducer from "../../../../../reducer/accountreducer";
import {connect} from "react-redux";
import QoldiqlarxisobotiReducer from "../reducer/QoldiqlarxisobotiReducer";
import XaridlarXisobotiReducer from "../reducer/XaridlarXisobotiReducer";
import {useHistory} from "react-router-dom";

const CardActions = styled(Box)(
  ({ theme }) => `
    position: absolute;
    right: ${theme.spacing(1.5)};
    top: ${theme.spacing(1.5)};
    z-index: 7;
  `
);

const ListWrapper = styled(List)(
  () => `
    .MuiDivider-root:first-of-type {
        display: none;
    }
  `
);

function Block3({accountreducer,XaridlarXisobotiReducer}) {
  const { t } = useTranslation();
  const history = useHistory()
  const theme = useTheme();
  const Box1Options = {
    chart: {
      background: 'transparent',
      toolbar: {
        show: false
      },
      sparkline: {
        enabled: true
      },
      zoom: {
        enabled: false
      }
    },
    colors: [theme.colors.warning.main],
    dataLabels: {
      enabled: false
    },
    theme: {
      mode: theme.palette.mode
    },
    stroke: {
      show: true,
      colors: [theme.colors.warning.main],
      width: 3
    },
    legend: {
      show: false
    },
    fill: {
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 0.9,
        opacityTo: 0,
        stops: [0, 100]
      }
    },
    labels: [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
      'Last Week',
      'Last Month',
      'Last Year',
      'Last 10 Years'
    ],
    xaxis: {
      labels: {
        show: false
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      show: false,
      min: 0
    }
  };
  const Box1Data = [
    {
      name: 'Top grossing max',
      data: [2.1, 2.1, 3.0, 2.8, 4.0, 3.8, 5.1, 3.6, 4.1, 2.6, 1.2]
    }
  ];

  return (
    <Card
      sx={{
        position: 'relative'
      }}
    >
      <CardActions>
        <Tooltip arrow placement="top" title={t('Add new product')}>
          <IconButton
            color="primary"
            sx={{
              backgroundColor: `${theme.colors.primary.main}`,
              color: `${theme.palette.getContrastText(
                theme.colors.primary.main
              )}`,
              '&:hover': {
                backgroundColor: `${theme.colors.primary.dark}`
              },
              '.MuiSvgIcon-root': {
                transition: `${theme.transitions.create(['color'])}`
              }
            }}
          >
            <AddCircleTwoToneIcon />
          </IconButton>
        </Tooltip>
      </CardActions>
      <Box pt={3} px={3}>
        <Typography
          variant="h3"
          sx={{
            pb: 4
          }}
        >
          {t('xisob.jamixar')}
        </Typography>
        <Typography
          fontWeight="bold"
          gutterBottom
          sx={{
            fontSize: `${theme.typography.pxToRem(14)}`
          }}
          component="h6"
          variant="caption"
        >
          {t('trade.us')}
        </Typography>
        <Box display="flex" alignItems="center">
          <Text flex color="warning">
            <FiberManualRecordTwoToneIcon />
          </Text>
          <Typography
            sx={{
              px: 0.5
            }}
            variant="h1"
          >
            {accountreducer.Purchases? accountreducer.Purchases?.myPurchase.toString().replace(/\B(?=(\d{3})+(?!\d))/g,","):''} so'm
          </Typography>
          <Text color="success">
            <b>+54</b>
          </Text>
        </Box>
      </Box>
      <Chart options={Box1Options} series={Box1Data} type="area" height={185} />
      <Typography
        fontWeight="bold"
        sx={{
          py: 1,
          px: 2,
          fontSize: `${theme.typography.pxToRem(13)}`
        }}
        component="h6"
        variant="caption"
      >
        {t('xisob.toph')}
      </Typography>
      <Box
        sx={{
          height: 300
        }}
      >
        <Scrollbar>
          <ListWrapper disablePadding>
            {XaridlarXisobotiReducer.topSuplier?.map((item) => (
              <Fragment key={item.id}>
                <Divider />
                <ListItem
                  sx={{
                    py: 2,
                    px: 2.5
                  }}
                >
                  <ListItemAvatar
                    sx={{
                      display: 'flex',
                      mr: 0.5
                    }}
                  >
                    <Box display="inline-flex" position="relative">
                      <CircularProgress
                        variant="determinate"
                        sx={{
                          color: (theme) => theme.colors.alpha.black[10]
                        }}
                        size={46}
                        thickness={2}
                        value={100}
                      />
                      <CircularProgress
                        size={48}
                        sx={{
                          animationDuration: '550ms',
                          position: 'absolute',
                          left: -1,
                          top: -1,
                          [`& .${circularProgressClasses.circle}`]: {
                            strokeLinecap: 'round'
                          }
                        }}
                        thickness={4}
                        variant="determinate"
                        value={75}
                      />
                    </Box>
                  </ListItemAvatar>
                  <ListItemText
                    primary={<Text color="black">{item.name}</Text>}
                    primaryTypographyProps={{
                      variant: 'h5',
                      noWrap: true
                    }}
                    secondary={item.number}
                    secondaryTypographyProps={{
                      variant: 'subtitle2',
                      noWrap: true
                    }}
                  />
                  <Typography variant="subtitle1">{item.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",")} so'm</Typography>
                    <Avatar
                      variant="rounded"
                      sx={{
                        ml: 1,
                        width: 28,
                        height: 28,
                        p: 0,
                        background: `${theme.colors.success.lighter}`,
                        color: `${theme.colors.success.main}`
                      }}
                    >
                      <KeyboardArrowUpTwoToneIcon />
                    </Avatar>
                </ListItem>
              </Fragment>
            ))}
          </ListWrapper>
        </Scrollbar>
      </Box>
      <Divider />
      <Box
        p={3}
        sx={{
          textAlign: 'center'
        }}
      >
        <Button
          variant="contained"
          endIcon={<ArrowForwardTwoToneIcon />}
          size="small"
          onClick={() => history.push('/headerthird/xaridlarRuyxati')}
        >
          {t('xisob.barcha')}
        </Button>
      </Box>
    </Card>
  );
}

export default connect((accountreducer,XaridlarXisobotiReducer,QoldiqlarxisobotiReducer),{}) (Block3);
