import React, {useEffect, useState} from 'react';
import './addContent.css'
import '../Content/content.css'
import {Link, useHistory} from "react-router-dom";
import {Editor} from '@tinymce/tinymce-react'
import {connect} from "react-redux";
import XaridReducer, {getXaridProductType} from "../../Haridlar/reducer/XaridReducer";
import users from "../../../../../reducer/users";
import {ImCancelCircle} from 'react-icons/im'
import {toast} from "react-toastify";
import contentReducer, {saveContent, getContentById, editContent} from "../../../../../reducer/contentReducer";
import ModalLoading from "../../../../ModalLoading";
import productionCostReducer, {getProductionCostAll} from "../../../../../reducer/productionCostReducer";
import {useTranslation} from "react-i18next";


function AddContent({
                        getContentById,
                        contentReducer,
                        match,
                        saveContent,
                        getXaridProductType,
                        XaridReducer,
                        users,
                        editContent,
                        productionCostReducer,
                        getProductionCostAll
                    }) {

    useEffect(() => {
        getXaridProductType(match.params.branchId)
        getProductionCostAll(match.params.branchId)
    }, [])

    useEffect(() => {
        if (match.params.id) {
            getContentById(match.params.id)
        }
    }, [])

    const history = useHistory()
    const {t} = useTranslation()




    const [costForm,setCostFrom] = useState([
        {
            costTypeId:'',
            sum:0,
        }
    ])

    function changeAddSum(e,index) {
        costForm[index][e.target.name] = e.target.value
        let a = [...costForm]
        setCostFrom(a)
        totalCost(a)
    }

    function totalCost(array){
        let a = 0
        array.map(item=>{
            a+=parseFloat(item.sum)
        })
        setAddSum(a)
    }

    function  addCostForm(){
        costForm.push({
            costTypeId:'',
            sum:0,
        })
        let a = [...costForm]
        setCostFrom(a)
    }

    function  removeCostForm(ind){
        let a = costForm.filter((item,index)=>ind !== index)
        setCostFrom(a)
        totalCost(a)
    }


    useEffect(() => {
        if (match.params.id) {
            editWrite()
        }
    }, [contentReducer.current])


    useEffect(() => {
        if (match.params.contentId !== "null") {
            editContentWrite()
        }
    }, [contentReducer.current])

    function editContentWrite() {
        if (contentReducer.contentProductList) {
            let a = []
            let c = 0
            contentReducer.contentProductList.map(val => {
                a.push({
                    productId: val.product ? val.product.id : null,
                    productTypePriceId: val.productTypePrice ? val.productTypePrice.id : null,
                    contentProductIdForEditOrNull: val.id,
                    quantity: val?.quantity,
                    totalPrice: val?.totalPrice,
                    name: val.product ? val.product?.name : val.productTypePrice?.name,
                    buyPrice: val.product ? val.product?.buyPrice : val.productTypePrice.buyPrice,
                    salePrice: val.product ? val.product?.salePrice : val.productTypePrice.salePrice,
                    measurementName: val.product ? val.product?.measurement?.name : val.productTypePrice?.product?.measurement?.name,
                    disabled: false,
                    active: false,
                    delete: false,
                    byProduct:val.byProduct,
                })
                if (val.byProduct){
                    c -= parseInt((val.quantity * Math.round(((val.product ? val.product?.salePrice : val.productTypePrice.salePrice) + Number.EPSILON) * 100)) / 100)

                }
                else{
                    c += parseInt((val.quantity * Math.round(((val.product ? val.product?.buyPrice : val.productTypePrice.buyPrice) + Number.EPSILON) * 100)) / 100)

                }
            })
            setarr1(a)
            setjamixisob(c)
        }
        else{
            setarr1([])
        }
    }

    const [editName, setEditName] = useState(null)
    const [editkg, setEditkg] = useState(null)
    const [searchContent, setSearchContent] = useState('')
    const [ComboArray, setComboArray] = useState([])
    const [jamixisob, setjamixisob] = useState(0)
    const [allOrder, setAllOrder] = useState(1)
    const [addSum, setAddSum] = useState(0)

    function editWrite() {
        if (contentReducer.oneContent) {
            const {
                contentPrice,
                cost,
                costEachOne,
                id,
                quantity,
                totalPrice,
                product,
                productTypePrice
            } = contentReducer.oneContent
            setjamixisob(contentPrice)
            setEditName(product ? product?.name : productTypePrice.name)
            setEditkg(product ? product?.measurement?.name : productTypePrice.product?.measurement?.name)
            setAllOrder(quantity)
            setAddSum(cost)
            setCostPrice(costEachOne)
        }
        if (contentReducer.contentProductList) {
            let a = []
            let c = 0
            contentReducer.contentProductList.map(val => {
                a.push({
                    productId: val.product ? val.product.id : null,
                    productTypePriceId: val.productTypePrice ? val.productTypePrice.id : null,
                    contentProductIdForEditOrNull: val.id,
                    barcode:val.product ? val.product?.barcode : val.productTypePrice?.barcode,
                    quantity: val?.quantity,
                    totalPrice: val?.totalPrice,
                    name: val.product ? val.product?.name : val.productTypePrice?.name,
                    buyPrice: val.product ? val.product?.buyPrice : val.productTypePrice.buyPrice,
                    salePrice: val.product ? val.product?.salePrice : val.productTypePrice.salePrice,
                    measurementName: val.product ? val.product?.measurement?.name : val.productTypePrice?.product?.measurement?.name,
                    disabled: false,
                    active: false,
                    delete: false,
                    byProduct:val.byProduct
                })
                if (val.byProduct){
                    c -= parseInt((val.quantity * Math.round(((val.product ? val.product?.salePrice : val.productTypePrice.salePrice) + Number.EPSILON) * 100)) / 100)

                }
                else{
                    c += parseInt((val.quantity * Math.round(((val.product ? val.product?.buyPrice : val.productTypePrice.buyPrice) + Number.EPSILON) * 100)) / 100)

                }
            })
            setarr1(a)
            setjamixisob(c)
        }
        if (contentReducer.costGetDtoList){
            let a =  [...contentReducer.costGetDtoList]
            setCostFrom(a)
        }

    }


    useEffect(() => {
        if (contentReducer.saveBoolean) {
            history.push('/main/production/content')
        }
        setSaveModal(false)
    }, [contentReducer.current])


    function changeOrder(e) {
        setAllOrder(e.target.value)
        arr1.map(item => {
            if (e.target.value * item.quantity > item.amount) {
                item.active = true
            } else {
                item.active = false
            }
        })
        let a = [...arr1]
        setarr1(a)
        xisobkitob()
    }

    function onChangeSearch(e) {
        setSearchContent(e.target.value)
        let a = []
        XaridReducer.xaridMahsulot.filter(val => {
            if (e.target.value === '') {
                setComboArray([])
            } else if (val.name.toUpperCase().includes(e.target.value.toUpperCase())) {
                a.push(val)
            } else if (val.barcode.includes(e.target.value)) {
                a.push(val)
            }

        })
        setComboArray(a)
    }

    const [arr1, setarr1] = useState([])

    function pushesh(val) {
        let order = false
        arr1.map(item => {
            if (item.productTypePriceId === null) {
                if (item.productId === val.productId) {
                    order = true
                }
            } else if (item.productId === null) {
                if (item.productTypePriceId === val.productTypePriceId) {
                    order = true
                }
            }
        })
        if (order === true) {
            toast.info('Mahsulot jadvalda bor')
        } else {
            arr1.push({
                productId: val.productId ? val.productId : null,
                productTypePriceId: val.productTypePriceId ? val.productTypePriceId : null,
                contentProductIdForEditOrNull: null,
                quantity: 1,
                totalPrice: 1 * val.buyPrice,
                name: val.name,
                barcode: val.barcode,
                buyPrice: val.buyPrice,
                salePrice: val.salePrice,
                measurementName: val.measurementName,
                disabled: false,
                active: false,
                delete: false,
                byProduct:false,
            })
            order = false
        }
        let b = [...arr1]
        setarr1(b)


        xisobkitob()
        setSearchContent('')
        setComboArray([])
    }

    function changeCount(e, id) {
        arr1.map((item, index) => {
            if (index === id) {
                if (parseFloat(e.target.value) >= item.amount) {
                    item.quantity = parseFloat(e.target.value)
                    if (item.byProduct){
                        item.totalPrice = item.quantity * Math.round((item.salePrice + Number.EPSILON) * 100) / 100
                    }
                    else{
                        item.totalPrice = item.quantity * Math.round((item.buyPrice + Number.EPSILON) * 100) / 100
                    }
                    item.active = true
                    item.disabled = false
                } else {
                    item.quantity = parseFloat(e.target.value)
                    if (item.byProduct){
                        item.totalPrice = item.quantity * Math.round((item.salePrice + Number.EPSILON) * 100) / 100
                    }
                    else{
                        item.totalPrice = item.quantity * Math.round((item.buyPrice + Number.EPSILON) * 100) / 100
                    }
                    item.disabled = false
                    item.active = false
                }
            }
        })
        let a = [...arr1]
        setarr1(a)
        xisobkitob()
    }

    function changeByProductCheck(e,id){
        arr1.map((item, index) => {
            if (index === id) {
                if(item.byProduct){
                    item.byProduct = !item.byProduct
                    item.totalPrice = item.quantity * item.buyPrice
                }
                else{
                    item.byProduct = !item.byProduct
                    item.totalPrice = item.quantity * item.salePrice
                }

            }
        })
        let a = [...arr1]
        setarr1(a)
        xisobkitob()
    }

    function saqlaContent() {
        let tot = !costPrice ?
            parseInt(jamixisob) + parseInt(addSum === "" ? 0 : addSum)

            : parseInt(jamixisob) + (parseInt(addSum === "" ? 0 : addSum) * allOrder)

        let a = []
        arr1.map(item => {
            a.push({
                contentProductIdForEditOrNull: item.contentProductIdForEditOrNull,
                delete: item.delete,
                productId: item.productId,
                productTypePriceId: item.productTypePriceId,
                quantity: parseFloat(item.quantity),
                totalPrice: parseFloat(item.totalPrice),
                byProduct:item.byProduct
            })
        })
        if (match.params.id) {
            const {product, productTypePrice} = contentReducer.oneContent
            editContent({
                branchId: match.params.branchId,
                contentPrice: parseFloat(jamixisob),
                contentProductDtoList: a,
                productId: product ? product?.id : null,
                productTypePriceId: productTypePrice ? productTypePrice?.id : null,
                quantity: parseFloat(allOrder),
                cost: parseFloat(addSum),
                costDtoList:costForm,
                costEachOne: costPrice,
                totalPrice: tot,
                id: match.params.id,
            })
        } else {
            saveContent({
                branchId: match.params.branchId,
                contentPrice: parseFloat(jamixisob),
                contentProductDtoList: a,
                productId: match.params.productId === "null" ? null : match.params.productId,
                productTypePriceId: match.params.productTypePriceId === "null" ? null : match.params.productTypePriceId,
                quantity: parseFloat(allOrder),
                cost: parseFloat(addSum),
                costDtoList:costForm,
                costEachOne: costPrice,
                totalPrice: tot
            })
        }
        setSaveModal(true)
    }

    function deleteM(ind) {
        arr1.map((item, index) => {
            if (index === ind) {
                arr1.splice(index, 1)
            }
        })
        let ad = [...arr1]
        setarr1(ad)
        let c = 0
        arr1.map(item => {
            c += parseInt((item.quantity * item.buyPrice))
        })
        setjamixisob(c)
    }

    const [costPrice, setCostPrice] = useState(true)

    function changeCostPrice(e) {
        if (e.target.value === 'true') {
            setCostPrice(true)
        } else if (e.target.value === 'false') {
            setCostPrice(false)
        }
    }



    function xisobkitob() {
        let c = 0
        arr1.map(item => {
            if(item.byProduct){
                c -= parseInt((item.quantity * Math.round((item.salePrice + Number.EPSILON) * 100)) / 100)

            }
            else{
                c += parseInt((item.quantity * Math.round((item.buyPrice + Number.EPSILON) * 100)) / 100)

            }
        })
        setjamixisob(c)

    }

    const [saveModal, setSaveModal] = useState(false)

    return (
        <div className="col-md-12 mt-2">
            <div className="textHeader">
                <h2>{t('num.1')} </h2>
            </div>
            <div className="rowStyle1">
                <div className="qoshish">
                    <h5>{t('num.2')} {
                        match.params.id ? editName : match.params.name
                    }</h5>
                </div>


                <div className={'col-md-10 mt-4 offset-1'}>
                    <div className="row">
                        <div className="col-md-12 position-relative p-0">
                            <label htmlFor="" className={'mb-2'}>{t('num.3')}</label>
                            <input type="text"
                                   value={searchContent}
                                   onChange={onChangeSearch}
                                   className={'form-control p-2 po'}
                                   style={{width: '100%'}}
                                   placeholder={t('num.4')}/>

                            {
                                ComboArray.length > 0 ? <div className={'Combo-array  position-absolute z-index'}>
                                    {
                                        ComboArray.map(item =>
                                            <p onClick={() => pushesh(item)}>
                                                {item.name} {item.barcode}
                                            </p>
                                        )

                                    }
                                </div> : ''
                            }

                        </div>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="table-responsive">
                        <table className={'table mt-3 border text-center'}>
                            <thead className={'bg-success text-white fw-bold'}>
                            <tr>
                                <th>{t('num.5')}</th>
                                <th>{t('num.6')}</th>
                                {/*<th>{t('num.7')}</th>*/}
                                <th>{t('num.8')}</th>
                                <th>{t('num.9')}</th>
                                <th>{t('num.10')}</th>
                                <th>{t('num.11')}</th>
                                <th></th>
                            </tr>
                            </thead>

                            <tbody>

                            {
                                arr1.map((item, index) =>
                                    <tr className={'text-center'}>
                                        <td>
                                            {item.name}
                                        </td>
                                        <td>
                                            <input disabled={true} type="text"
                                                   name={'barcode'}
                                                   value={item.barcode}

                                            />
                                        </td>
                                        {/*<td>*/}
                                        {/*    <input className={'form-control'}*/}
                                        {/*           name={'exchangeProductQuantity'}*/}
                                        {/*           value={item.percent}*/}
                                        {/*        // onChange={(e) => ComboChangeAmount(e, index)}*/}
                                        {/*           type="number"/>*/}
                                        {/*</td>*/}
                                        <td>
                                            <div className={'d-flex'}>
                                                <input className={'form-control'}
                                                       name={'quantity'}
                                                       value={item.quantity}
                                                       onChange={(e) => changeCount(e, index)}
                                                       type="number"/>
                                                <input className={'form-control'} type="text" disabled={true}
                                                       value={item.measurementName}/>
                                            </div>
                                        </td>
                                        <td>
                                            <input  onChange={(e)=>changeByProductCheck(e,index)} type="checkbox" checked={!item.byProduct}/>
                                        </td>
                                        <td>
                                            <input  onChange={(e)=>changeByProductCheck(e,index)} type="checkbox" checked={item.byProduct}/>
                                        </td>
                                        <td>
                                            <h6 className={"m-0 p-0 mt-2"}>{item.totalPrice} {t('num.12')}</h6></td>
                                        <td className={'text-danger'}><ImCancelCircle
                                            onClick={() => deleteM(index)}
                                            style={{width: '30px', height: '30px'}}/></td>
                                    </tr>
                                )
                            }
                            </tbody>
                        </table>
                    </div>

                </div>
                <div className="row ">
                    <div className="offset-10 col-md-2">
                        <h6>{t('num.13')} {jamixisob}</h6>
                    </div>
                </div>
                <div className="row cont">
                    {/*<div className="col-md-3 col-sm-12">*/}
                    {/*    <h6>{t('num.14')}</h6>*/}
                    {/*    <input type="text" className={'content-control'} placeholder={'zarar'}/>*/}
                    {/*</div>*/}
                    <div className="col-md-3 col-sm-12">
                        <h6>{(t('num.15'))}</h6>
                        <div className={'d-flex'}>
                            <input onChange={changeOrder} value={allOrder} className={'content-control'} type="text"
                                   placeholder=''/>
                            <input className={'content-control'} type="text" disabled={true} value={
                                match.params.id ? editkg : match.params.measurement}/>
                        </div>

                    </div>
                    <div className="col-md-5 col-sm-12">
                        <div className={'d-flex align-items-center'}>
                            <h6>{t('num.16')}</h6>
                            <button onClick={addCostForm} className={'btn btn-primary'}>+</button>
                        </div>
                        <div className={'d-flex  align-items-center flex-wrap'}>
                            {
                                costForm.map((item,index)=>
                                    <div className={'d-flex justify-content-between me-2 mb-2'}>
                                        <input className={'content-control'}
                                               value={item.sum}
                                               name={'sum'}
                                               onChange={(e)=>changeAddSum(e,index)}
                                               type="text" placeholder={t('num.11')}/>
                                        {
                                            productionCostReducer.productionCostAll ?
                                                <select value={item.costTypeId} onChange={(e)=>changeAddSum(e,index)} name={'costTypeId'} className={'content-control'}>
                                                    <option value="">{t('num.17')}</option>
                                                    {
                                                        productionCostReducer.productionCostAll.map(item=>
                                                            <option value={item.id}>{item.name}</option>)
                                                    }
                                                </select> :
                                                <div>
                                                    <h4 className={'text-center'}>{productionCostReducer.message}</h4>
                                                </div>
                                        }
                                        <button onClick={()=>removeCostForm(index)} className={'btn btn-danger'}>-</button>
                                    </div>
                                )
                            }
                            <div className={'d-flex  align-items-center flex-wrap'}>
                                <h4>{addSum} {t('num.12')}</h4>
                                <select className={'select-content-control'} value={costPrice} onChange={changeCostPrice}>
                                    <option value={'false'}>{t('num.18')}</option>
                                    <option value={'true'}>{t('num.19')}</option>
                                </select>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-3 col-sm-12">
                        <h6>{t('num.20')} :</h6>
                        <input className={'content-control'}
                               value={
                                   !costPrice ?
                                       parseInt(jamixisob) + parseInt(addSum === "" ? 0 : addSum)

                                       : parseInt(jamixisob) + (parseInt(addSum === "" ? 0 : addSum) * allOrder)

                               }
                               disabled={true} type="text" placeholder={t('num.20')}/>
                    </div>
                </div>
                {/*<div className="row mb-4 mt-4">*/}
                {/*    <div className="col-md-12">*/}
                {/*        <h4>{t('num.21')}</h4>*/}
                {/*        <Editor/>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className='saqlash'>
                    <button className='btn btn-primary' onClick={saqlaContent}>{t('num.22')}</button>
                </div>
            </div>
            <ModalLoading isOpen={saveModal}/>
        </div>
    );
}

export default connect((XaridReducer, users, contentReducer,productionCostReducer), {
    getXaridProductType,
    saveContent,
    getContentById,
    editContent,
    getProductionCostAll
})(AddContent)
