import {connect} from "react-redux";
import './content.css'
import {Link, useHistory} from 'react-router-dom'
import CSV from '../../../../../img/CSV.png'
import Excel from '../../../../../img/Excel.png'
import Print from '../../../../../img/Print.png'
import Data from '../../../../../img/Data.png'
import Pdf from '../../../../../img/PDF.png'
import Edit from '../../../../../img/Edit.png'
import Delete from '../../../../../img/Delete.png'
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {IoIosArrowDown} from 'react-icons/io'
import users from "../../../../../reducer/users";
import Korish from "../../../../../img/Korish.png";
import Loading from "../../../../Loading";
import ModalLoading from "../../../../ModalLoading";
import {Box, TablePagination} from "@mui/material";
import formatDate from "../../../../../util";
import productionCostReducer, {
    saveProductionCost,
    getProductionCost,
    editProductionCost,
    deleteProductionCost
} from "../../../../../reducer/productionCostReducer";

function ProductionCost({
                            users,
                            saveProductionCost,
                            getProductionCost,
                            editProductionCost,
                            deleteProductionCost,
                            productionCostReducer
                        }) {

    const {t} = useTranslation()

    const [mainBranchId, setMainBranchId] = useState(null)
    const [costName, setCostName] = useState('')


    const [addCostActive, setAddCostActive] = useState(false)

    function toggle() {
        setAddCostActive(prev => !prev)
        setCostName('')
        setEditId(null)
    }


    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(5);
    const [query, setQuery] = useState(null);


    useEffect(() => {
        getProductionCost(
            {
                branchId: mainBranchId ? mainBranchId : users.branchId,
                params: {
                    page: page,
                    size: limit
                }
            }
        )
    }, [productionCostReducer.current, mainBranchId, page, limit])

    useEffect(() => {
        if (productionCostReducer.saveBoolean) {
            toggle()
        }
    }, [productionCostReducer.current])


    const handleQueryChange = (event) => {
        event.persist();
        setQuery(event.target.value === '' ? null : event.target.value);
    };

    const handlePageChange = (_event, newPage) => {
        setPage(newPage);
    };

    const handleLimitChange = (event) => {
        setLimit(parseInt(event.target.value));
    };

    const [loading, setLoading] = useState(false)

    const [deleteCostActive, setDeleteCostActive] = useState(false)
    const [deleteId, setDeleteId] = useState(null)

    function deleteModalToggleOpen() {
        setDeleteCostActive(true)
    }

    function deleteModalToggle(id) {
        deleteModalToggleOpen()
        setDeleteId(id)
    }

    function deleteModalToggleClose() {
        setDeleteCostActive(false)
        setDeleteId(null)
    }

    function deleteCost() {
        deleteProductionCost(deleteId)
        deleteModalToggleClose()
        setLoading(false)
    }

    const [editID, setEditId] = useState(null)


    function handleSubmit() {
        if (editID) {
            editProductionCost(
                {
                    branchId: mainBranchId ? mainBranchId : users.branchId,
                    name: costName,
                    id:editID
                }
            )
        } else {
            saveProductionCost(
                {
                    branchId: mainBranchId ? mainBranchId : users.branchId,
                    name: costName,
                }
            )
        }

    }

    function editCost(id) {
        setEditId(id)
        if (productionCostReducer?.productionCost) {
            productionCostReducer.productionCost.getLessProduct.map(item => {
                if (item.id === id) {
                    setCostName(item.name)
                }
            })
        }
        setAddCostActive(true)
    }

    useEffect(() => {
        setLoading(true)
    }, [productionCostReducer.getBoolean])

    useEffect(() => {
        setLoading(false)
    }, [])

    return (
        <div className="col-md-12 mt-4 mb-4">
            <div className="textHeaderBL">
                <h2>{t('num.49')}</h2>
            </div>
            <div className="rowStyleBL">
                <div className="qoshish d-flex justify-content-end">
                    <button className={'btn btn-primary'} onClick={toggle}>+ {t('Buttons.2')}</button>
                </div>
                <div className="izlashBL">
                    <div className="izlashBox1">
                        <button><img src={CSV} alt=""/> Export CSV</button>
                        <button><img src={Excel} alt=""/> Export Excel</button>
                        <button><img src={Print} alt=""/> Print</button>
                        <button><img src={Pdf} alt=""/>Export PDF</button>
                    </div>
                    {
                        users.branches.length > 1 ?
                            <div className="izlashBox2">
                                <select name="" id="" onChange={(e) =>{
                                    setMainBranchId(e.target.value)
                                    setLimit(5)
                                    setPage(0)
                                } }
                                        value={mainBranchId}>
                                    {
                                        users.branches.map(item =>
                                            <option value={item.id}>{item.name}</option>
                                        )
                                    }
                                </select>
                            </div> : ''
                    }

                    {/*<div className="izlashBox2">*/}
                    {/*    <input type="text" placeholder={t('num.37')}*/}
                    {/*           onChange={handleQueryChange} value={query}*/}
                    {/*    />*/}
                    {/*</div>*/}
                </div>

                {
                    loading ?
                        productionCostReducer.productionCost ?
                            <div>
                                <div className="table-responsive  mb-4">
                                    <table className='table  table-bordered mt-4'>
                                        <thead>
                                        <tr>
                                            <th>T/R</th>
                                            <th>{t('num.50')}</th>
                                            <th>{t('num.40')}</th>
                                        </tr>
                                        </thead>

                                        <tbody>
                                        {
                                            productionCostReducer.productionCost?.getLessProduct
                                                .map((item, index) =>
                                                    <tr key={item.id}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.name}</td>
                                                        <td className={'gap-3'}>
                                                            <button className='btn btn-success'
                                                                    onClick={() => editCost(item.id)}>
                                                                <img
                                                                    src={Edit} alt=""/> {t('Buttons.1')}
                                                            </button>
                                                            <button className='btn btn-danger'
                                                                    onClick={() => deleteModalToggle(item.id)}>
                                                                <img src={Delete} alt=""/> {t('Buttons.3')}
                                                            </button>
                                                        </td>


                                                    </tr>)
                                        }

                                        </tbody>
                                    </table>
                                    <Box p={2}>
                                        <TablePagination
                                            component="div"
                                            count={productionCostReducer.productionCost?.totalItem}
                                            onPageChange={handlePageChange}
                                            onRowsPerPageChange={handleLimitChange}
                                            page={page}
                                            rowsPerPage={limit}
                                            rowsPerPageOptions={[5, 10, 15]}
                                        />
                                    </Box>
                                </div>
                            </div> : <div className={'border border-2'}>
                                <h4 className={'text-center'}>{productionCostReducer.message || t('Darslik.mtt')}</h4>
                            </div> : <Loading/>
                }

                <Modal isOpen={deleteCostActive} toggle={deleteModalToggleOpen}>
                    <ModalBody>
                        <h5>{t('Buttons.12')} ?</h5>
                    </ModalBody>
                    <ModalFooter>
                        <button onClick={deleteModalToggleClose}
                                className={'btn btn-danger'}>{t('Buttons.7')}</button>
                        <button onClick={deleteCost}
                                className={'btn btn-primary'}>{t('Buttons.3')}</button>

                    </ModalFooter>
                </Modal>

                <Modal isOpen={addCostActive} toggle={toggle}>
                    <ModalHeader>
                        {t('num.51')}
                    </ModalHeader>
                    <ModalBody>
                        <label htmlFor="costId">{t('num.52')}</label>
                        <input id="costId" value={costName} onChange={(e) => setCostName(e.target.value)} type="text"
                               className={'form-control'}/>
                    </ModalBody>
                    <ModalFooter>
                        <button className={'btn btn-primary'} onClick={handleSubmit}>{t('Buttons.6')}</button>
                        <button className={'btn btn-outline-primary'} onClick={toggle}>{t('Buttons.7')}</button>
                    </ModalFooter>
                </Modal>


            </div>

            {/*<Modal isOpen={active2} toggle={toggle2} style={{maxWidth: '600px', width: '100%'}}>*/}
            {/*    <ModalHeader>*/}
            {/*    </ModalHeader>*/}
            {/*    <ModalBody>*/}
            {/*    </ModalBody>*/}
            {/*    <ModalFooter>*/}
            {/*        /!*<button onClick={toggle2} className={'btn btn-primary'}>{t('num.48')}</button>*!/*/}
            {/*    </ModalFooter>*/}
            {/*</Modal>*/}

        </div>)
}

export default connect((users, productionCostReducer), {
    saveProductionCost, getProductionCost, editProductionCost, deleteProductionCost
})(ProductionCost)
