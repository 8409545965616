import React, {useState, useEffect} from 'react';
import {
    Box, Button,Dialog, DialogActions, DialogContent, DialogTitle,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead, TablePagination,
    TableRow, Tabs, ToggleButton, ToggleButtonGroup, Tooltip,
    Typography, Zoom
} from '@mui/material';
import {Link} from "react-router-dom";
import {useSnackbar} from "notistack";
import {styled} from "@mui/material/styles";
import TableRowsTwoToneIcon from "@mui/icons-material/TableRowsTwoTone";
import GridViewTwoToneIcon from "@mui/icons-material/GridViewTwoTone";
import {Formik} from "formik";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import users from "../../../../../../reducer/users";
import workTimeReducer, {
    getWorkUser,
    workUserArrive,
    workUserLeave,
    getWorkUserMonth
} from "../../../../../../reducer/workTimeReducer";
import {formatDateWithTime} from "../../../../../../util";
import {toast} from "react-toastify";
import "../davomat.css"

function ByMember({getWorkUser,workTimeReducer,users,workUserArrive,workUserLeave,getWorkUserMonth}) {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const {enqueueSnackbar} = useSnackbar();


    useEffect(() => {
        getWorkUser(users.branchId)
    }, [workTimeReducer.current])


    function ArriveUser(id){
        workUserArrive({
            branchID: users.branchId,
            userID: id
        })
    }
    function LeaveUser(id){
        workUserLeave({
            branchID: users.branchId,
            userID: id
        })
    }

    function getAllInfo(id){
        getWorkUserMonth({
            branchId: users.branchId,
            userId: id
        })
    }

    const handleCreateUserClose = () => {
        setOpen(false);
    };

    const handleCreateUserSuccess = () => {
        enqueueSnackbar('The user account was created successfully', {
            variant: 'success',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            },
            TransitionComponent: Zoom
        });

        setOpen(false);
    };
    const [filters, setFilters] = useState({
        role: null
    });
    const [toggleView, setToggleView] = useState('table_view');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (_event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleViewOrientation = (_event, newValue) => {
        setToggleView(newValue);
    };

    const TabsWrapper = styled(Tabs)(
        ({theme}) => `
    @media (max-width: ${theme.breakpoints.values.md}px) {
      .MuiTabs-scrollableX {
        overflow-x: auto !important;
      }

      .MuiTabs-indicator {
          box-shadow: none;
      }
    }
    `
    );
    const handleTabsChange = (_event, tabsValue) => {
        let value = null;

        if (tabsValue !== 'all') {
            value = tabsValue;
        }

        setFilters((prevFilters) => ({
            ...prevFilters,
            role: value
        }));

    };


    const [activeLidStatus,setActiveLidStatus] = useState(false)

    function LidStatusToggle(){
        setActiveLidStatus(!activeLidStatus)
    }

    const [visible, setvisible] = useState(5)
    let [counter, setCounter] = useState(0)

    function koproq() {
        let a = workTimeReducer.workUser.length

        for (let i = 0; a; i++) {
            counter += visible
            if (counter < a) {
                setvisible(prev => prev + 5)
                return;
            } else {
                toast.error(t('Davomat.kmyuq'))
                return
            }
        }
    }

    return (
        <>
            <div className={'colorback mt-4 m-3 p-4'}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h4" component="h4" gutterBottom>
                            <h4>{t('HR.davomat')} +</h4>
                        </Typography>

                    </Grid>

                </Grid>



                <Dialog
                    fullWidth
                    maxWidth="xl"
                    open={open}
                    onClose={handleCreateUserClose}
                >
                    <DialogTitle
                        sx={{
                            p: 3
                        }}
                    >
                        <Typography variant="h4" gutterBottom>
                            {t('Davomat.dqushish')}
                        </Typography>

                    </DialogTitle>
                    <Formik
                        initialValues={{
                            email: '',
                            username: '',
                            first_name: '',
                            last_name: '',
                            password: '',
                            submit: null
                        }}
                        validationSchema={Yup.object().shape({
                            username: Yup.string()
                                .max(255)
                                .required(t('ol.2')),
                            first_name: Yup.string()
                                .max(255)
                                .required(t('ol.3')),
                            last_name: Yup.string()
                                .max(255)
                                .required(t('ol.4')),
                            email: Yup.string()
                                .email(t('ol.5'))
                                .max(255)
                                .required(t('ol.6')),
                            password: Yup.string()
                                .max(255)
                                .required(t('ol.7'))
                        })}
                        onSubmit={async (
                            _values,
                            {resetForm, setErrors, setStatus, setSubmitting}
                        ) => {
                            try {
                                // await wait(1000);
                                resetForm();
                                setStatus({success: true});
                                setSubmitting(false);
                                handleCreateUserSuccess();
                            } catch (err) {
                                console.error(err);
                                setStatus({success: false});
                                setErrors({submit: err.message});
                                setSubmitting(false);
                            }
                        }}
                    >

                        <form>
                            <DialogContent
                                dividers
                                sx={{
                                    p: 3
                                }}
                            >
                                <div className={'col-md-12'}>
                                    <div className="col-md-4 p-0 m-0">
                                        <label htmlFor="mavqe"> {t('Davomat.mavqeyi')} </label>
                                        <select className={'form-control'} name="mavqe" id="mavqe">
                                            <option value="1">--</option>
                                        </select>
                                    </div>
                                </div>
                            </DialogContent>
                            <DialogActions
                                sx={{
                                    p: 3
                                }}
                            >
                                <Button onClick={handleCreateUserClose}>
                                    {t('Davomat.chiqish')}
                                </Button>
                                <Button  type="submit" >
                                    {t('Davomat.lqushish')}
                                </Button>
                            </DialogActions>
                        </form>

                    </Formik>
                </Dialog>
                <div className={'mb-2'}>
                    <input type="search" placeholder={t('ol.1')} className={'form-control'}/>
                </div>
                {
                    workTimeReducer.workUser ?
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('Davomat.hodim')}</TableCell>
                                <TableCell align={'center'}>{t('Davomat.kvaqt')}</TableCell>
                                <TableCell align={'center'}>{t('Davomat.amal')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                workTimeReducer.workUser?.map((item,index)=> <TableRow key={index}>
                                        <TableCell onClick={()=>getAllInfo(item.userID)}>
                                            {`${item.firstName} ${item.lastName}`}
                                        </TableCell>
                                        <TableCell align={'center'}>{formatDateWithTime(item?.arrivalTime)}</TableCell>
                                        <TableCell align={'center'}>
                                            {
                                                item.active ? <button onClick={()=>LeaveUser(item.userID)} className={'btn btn-success'}>- {t('Davomat.ketdi')}</button>
                                                    :
                                                    <button onClick={()=>ArriveUser(item.userID)} className={'btn btn-primary'} >+ {t('Davomat.keldi')}</button>
                                            }
                                        </TableCell>
                                </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>

                    {
                        workTimeReducer.workUser?
                            workTimeReducer.workUser.length > 5 ? <button onClick={koproq}
                                                                              className={'btn btn-outline-danger form-control'}>{t('Buttons.5')}</button> : '':''
                    }
                </TableContainer>: <div className={'mt-4'}><h4 className={'text-center'}>{t('Darslik.mtt')}</h4></div>
                }
                <Box className={'d-flex justify-content-end'} p={2}>
                    <TablePagination
                        component="div"
                        count={workTimeReducer?.workUser?.length}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[5, 10, 15]}
                    />
                </Box>
            </div>
            <Modal isOpen={activeLidStatus} toggle={LidStatusToggle}>
                <ModalHeader>
                    <h6>{t('Davomat.lsqushish')}</h6>
                </ModalHeader>
                <ModalBody className={'d-flex'}>
                    <div className="col-md-6">
                        <label htmlFor="mjs">{t('Davomat.mijozstatus')}</label>
                        <input className={'form-control'} type="text"/>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="mjs">{t('Davomat.rb')}</label>
                        <input className={'form-control'} type="color"/>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button className={'btn btn-outline-primary'}>{t('Davomat.saqlash')}</button>
                    <button onClick={LidStatusToggle} className={'btn btn-outline-primary'}>{t('Davomat.chiqish')}</button>
                </ModalFooter>
            </Modal>
        </>
    );
}

// export default Lids;
export default connect((users,workTimeReducer), {getWorkUser,workUserArrive,workUserLeave,getWorkUserMonth})(ByMember)
