import React from 'react';
import {BaseUrl} from "../middleware";
import './imageCom.css'
import defaultProduct from '../img/defaultProduct.png'
function Imagecom({id}) {
    return (
        <div>
            {
                id ?
                <img   className={'img-fluid picture-fluid'} src={`${BaseUrl}/attachment/download/${id}`} alt="###"/>
                    :  <img className={'img-fluid bg-danger picture-fluid'}
                            // src={`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3uAJqm9dM-DzEqpAyyUVfJ1JnRppFw2QtMcNVOIOBEKqkSzsWmK-5btcDekYzmawDWfg&usqp=CAU`}
                            src={defaultProduct}
                            alt="###"/>
            }
        </div>
    );
}

export default Imagecom;
