 import React, { useState } from 'react';

import {
    Box,
    Card,
    Typography,

    Button,

    Divider,
    ToggleButton,
    ToggleButtonGroup,

    styled,
    useTheme, TablePagination
} from '@mui/material';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

import { useTranslation } from 'react-i18next';
import Scrollbar from '../../../../../../components/Scrollbar';
import {connect} from "react-redux";
import MaxsulotlarRoyxariReducer from "../../reducer/MaxsulotlarRoyxariReducer";
import {dayAndMonth} from "../../../../../../util";

const LabelWrapper = styled(Box)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(10)};
    font-weight: bold;
    text-transform: uppercase;
    border-radius: ${theme.general.borderRadiusSm};
    padding: ${theme.spacing(0.5, 1)};
  `
);

function TarixiM({MaxsulotlarRoyxariReducer,row,page,changePage,changeRow}) {
  const { t } = useTranslation();
  const theme = useTheme();

    const germanCurrencyFormatter = new Intl.NumberFormat("uz", { notation: "standard"});



  return (
    <Card>
      <Box
        p={2.5}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <Typography gutterBottom variant="h4">
            {t('pro.Zaxiratarixi')}
          </Typography>

        </Box>
      </Box>
      <Divider />
      { MaxsulotlarRoyxariReducer.productOneWorkingQuantity ?
        <>
          <Box
            sx={{
              height: 345
            }}
          >
            <Scrollbar>
              <Timeline>
                  {
                      MaxsulotlarRoyxariReducer.productOneWorkingQuantity.aboutDtoList.map(item=>

                          <TimelineItem
                              sx={{
                                  p: 0
                              }}
                          >
                              <TimelineOppositeContent
                                  sx={{
                                      width: '85px',
                                      flex: 'none'
                                  }}
                                  color="text.secondary"
                              >
                                  {dayAndMonth(item.date)}
                              </TimelineOppositeContent>
                              <TimelineSeparator
                                  sx={{
                                      position: 'relative'
                                  }}
                              >
                                  <TimelineDot
                                      sx={{
                                          marginTop: 0,
                                          top: theme.spacing(1.2)
                                      }}
                                      variant="outlined"
                                      color="primary"
                                  />
                                  <TimelineConnector />
                              </TimelineSeparator>
                                      <TimelineContent
                                          sx={{
                                              pb: 4
                                          }}
                                      >
                                          <Typography
                                              sx={{
                                                  pt: 1
                                              }}
                                              variant="body2"
                                              color="text.primary"
                                          >
                                              <b>{item.name}</b>
                                          </Typography>
                                          <LabelWrapper
                                              component="span"
                                              sx={{
                                                  background: `${
                                                      item.amount >= 0 ?
                                                      theme.colors.success.main : theme.colors.error.main}`,
                                                  color: `${theme.palette.getContrastText(
                                                      theme.colors.success.dark
                                                  )}`
                                              }}
                                          >
                                              {item.description}
                                          </LabelWrapper>
                                          <Typography
                                              sx={{
                                                  pt: 1
                                              }}
                                              variant="body2"
                                              color="text.primary"
                                          >
                                              <b>{germanCurrencyFormatter.format(parseFloat(item.amount).toFixed(3))}</b> {item.measurement}
                                          </Typography>
                                      </TimelineContent>
                          </TimelineItem>
                      )
                  }
              </Timeline>
            </Scrollbar>
          </Box>
          <Divider />
          <Box
            p={2}
            sx={{
              textAlign: 'center'
            }}
          >
              <TablePagination
                  labelRowsPerPage={'Qatori'}
                  count={MaxsulotlarRoyxariReducer?.productOneWorkingQuantity?.totalItem}
                  page={page}
                  onPageChange={changePage}
                  rowsPerPage={row}
                  rowsPerPageOptions={[5,10,15]}
                  onRowsPerPageChange={changeRow}
              />
          </Box>
        </>:''
      }
    </Card>
  );
}

export default connect((MaxsulotlarRoyxariReducer),{}) (TarixiM);
