import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {connect} from "react-redux";
import XaridReducer, {getXaridProductType} from "../../Haridlar/reducer/XaridReducer";
import users from "../../../../../reducer/users";
import contentReducer, {getContent, getContentById} from "../../../../../reducer/contentReducer";
import productionReducer, {saveProduction} from "../../../../../reducer/productionReducer";
import ModalLoading from "../../../../ModalLoading";
import "./addProduction.css"
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import Select from "react-select";


function AddProduction({
                           contentReducer,
                           users,
                           getContent,
                           getContentById,
                           productionReducer,
                           saveProduction,
                           XaridReducer,
                           getXaridProductType
                       }) {
    const {t} = useTranslation()
    const history = useHistory()
    const [costPrice, setCostPrice] = useState(true)
    const [measure, setmesure] = useState(null)
    const [date, changedate] = useState('')
    const [branch, changebranch] = useState('')
    const [productId, setProductId] = useState(null)
    const [productTypePriceId, setProductTypePriceId] = useState(null)
    const [arr1, setArr1] = useState([])
    const [total, setTotal] = useState(0)
    const [totalTable2, setTotalTable2] = useState(0)
    const [allOrder, setAllOrder] = useState(1)
    const [noChangesOrderQuantity, setNoChangesQuantity] = useState(1)
    const [invalid, setInvaled] = useState(0)
    const [addSum, setAddSum] = useState(0)
    const [content, setContent] = useState(null)
    const [saveModal, setSaveModal] = useState(false)
    const [searchContent, setSearchContent] = useState('')
    const [ComboArray, setComboArray] = useState([])


    const [mainBranchId, setMainBranchId] = useState(null)

    useEffect(() => {
        setMainBranchId(users.branchId)
    }, [])


    useEffect(() => {
        getContent(mainBranchId ? mainBranchId : users.branchId)
        getXaridProductType(mainBranchId ? mainBranchId : users.branchId)
    }, [mainBranchId])


    const [costForm, setCostFrom] = useState([
        {
            costTypeId: '',
            sum: 0,
        }
    ])

    function changeAddSum(e, index) {
        let a = {...costForm[index]}
        a.sum = e.target.value
        costForm[index] = a
        let b = [...costForm]
        setCostFrom(b)
        totalCost(b)
    }

    function totalCost(array) {
        let a = 0
        array.map(item => {
            a += parseFloat(item.sum)
        })
        setAddSum(a)
    }

    function onChangeSearch(e) {
        setSearchContent(e.target.value)
        let a = []
        if (XaridReducer.xaridMahsulot) {
            XaridReducer.xaridMahsulot.filter(val => {
                if (e.target.value === '') {
                    setComboArray([])
                } else if (val.name.toUpperCase().includes(e.target.value.toUpperCase())) {
                    a.push(val)
                } else if (val.barcode.includes(e.target.value)) {
                    a.push(val)
                }

            })

        } else {
            toast.error('Bu Bazada Mahsulot yoq')
        }
        setComboArray(a)
    }

    const [arr2, setarr2] = useState([])

    function pushesh(val) {

        let order = false
        arr2.map(item => {
            if (item.productTypePriceId === null) {
                if (item.productId === val.productId) {
                    order = true
                }
            } else if (item.productId === null) {
                if (item.productTypePriceId === val.productTypePriceId) {
                    order = true
                }
            }
        })
        if (order === true) {
            toast.info('Mahsulot jadvalda bor')
        } else {
            arr2.push({
                productId: val.productId ? val.productId : null,
                productTypePriceId: val.productTypePriceId ? val.productTypePriceId : null,
                contentProductIdForEditOrNull: null,
                quantity: 1,
                totalPrice: 1 * val.salePrice,
                name: val.name,
                buyPrice: val.buyPrice,
                salePrice: val.salePrice,
                measurementName: val.measurementName,
                disabled: false,
                active: false,
                delete: false,
                byProduct: false,
            })
            order = false
        }
        let b = [...arr2]
        setarr2(b)
        setSearchContent('')
        setComboArray([])
        xisobkitob()
    }

    function changeCountNumber(e, id) {
        arr2.map((item, index) => {
            if (index === id) {

                item.quantity = parseFloat(e.target.value)
                item.totalPrice = item.quantity * Math.round((item.salePrice + Number.EPSILON) * 100) / 100
            }

        })
        let a = [...arr2]
        setarr2(a)
        xisobkitob()
    }


    function deleteM(ind) {
        arr2.map((item, index) => {
            if (index === ind) {
                arr2.splice(index, 1)
            }
        })

        let ad = [...arr2]
        setarr2(ad)
        xisobkitob()
    }

    function changeCostPrice(e) {
        if (e.target.value === 'true') {
            setCostPrice(true)
        } else if (e.target.value === 'false') {
            setCostPrice(false)
        }
    }

    function xisobkitob() {
        let c = 0
        arr1.map(item => {
            if (item.byProduct) {
                c -= parseInt((item.quantity * Math.round((item.salePrice + Number.EPSILON) * 100)) / 100)

            } else {
                c += parseInt((item.quantity * Math.round((item.buyPrice + Number.EPSILON) * 100)) / 100)

            }
        })
        let b = 0
        arr2.map(item => {
            b += parseInt((item.quantity * Math.round((item.salePrice + Number.EPSILON) * 100)) / 100)
        })
        setTotal(c)
        setTotalTable2(b)
    }

    useEffect(() => {
        if (content) {
            if (contentReducer.oneContent) {
                const {quantity, product, cost, costEachOne, productTypePrice} = contentReducer.oneContent
                setProductId(product ? product.id : null)
                setProductTypePriceId(productTypePrice ? productTypePrice.id : null)
                setAllOrder(quantity)
                setNoChangesQuantity(quantity)
                setCostPrice(costEachOne)
                setAddSum(cost)
                setmesure(product ? product?.measurement?.name : productTypePrice?.product?.measurement?.name)
            }
            let a = []
            let c = 0
            if (contentReducer.contentProductList) {
                contentReducer.contentProductList.map(item => {
                    a.push({
                        name: item.product ? item.product?.name : item.productTypePrice?.name,
                        measurementName: item.product ? item.product?.measurement.name : item.productTypePrice.product?.measurement.name,
                        amount: item.productWarehouseAmount,
                        contentProductIdForEditOrNull: item.contentProductIdForEditOrNull,
                        delete: item.delete,
                        productId: item.product ? item.product.id : null,
                        productTypePriceId: item.productTypePrice ? item.productTypePrice.id : null,
                        noChangesQuantity: item.quantity,
                        quantity: item.quantity,
                        totalPrice: item.totalPrice,
                        buyPrice: item.product ? item.product?.buyPrice : item.productTypePrice?.buyPrice,
                        salePrice: item.product ? item.product?.salePrice : item.productTypePrice?.salePrice,
                        disabled: false,
                        active: false,
                        byProduct: item.byProduct
                    })
                    if (item.byProduct) {
                        c -= parseInt((item.quantity * Math.round(((item.product ? item.product?.salePrice : item.productTypePrice?.salePrice) + Number.EPSILON) * 100)) / 100)

                    } else {
                        c += parseInt((item.quantity * Math.round(((item.product ? item.product?.buyPrice : item.productTypePrice?.buyPrice) + Number.EPSILON) * 100)) / 100)
                    }
                })
                setArr1(a)
                setTotal(c)
            }
            if (contentReducer.costGetDtoList) {
                let a = []
                contentReducer.costGetDtoList.map(item => {
                    a.push(
                        {
                            costTypeId:item.costTypeId,
                            costTypeName:item.costTypeName,
                            sum:item.sum,
                            noChangesSum: item.sum
                        }
                )
                })
                setCostFrom(a)
            }
        }
    }, [contentReducer.current])


    function changeOrder(e) {
        setAllOrder(e.target.value)
        arr1.map(item => {
            item.quantity = ((e.target.value * item.noChangesQuantity) / noChangesOrderQuantity)
            if (item.byProduct) {
                item.totalPrice = item.quantity * Math.round((item.salePrice + Number.EPSILON) * 100) / 100
            } else {
                item.totalPrice = item.quantity * Math.round((item.buyPrice + Number.EPSILON) * 100) / 100

            }
        })
        let a = [...arr1]
        setArr1(a)
        let c = 0
        arr1.map(item => {
            if (item.byProduct) {
                c -= parseInt((item.quantity * Math.round((item.salePrice + Number.EPSILON) * 100)) / 100)

            } else {
                c += parseInt((item.quantity * Math.round((item.buyPrice + Number.EPSILON) * 100)) / 100)

            }
        })
        costForm.map(item=>{
            if (!costPrice){
                item.sum = ((e.target.value * item.noChangesSum) / noChangesOrderQuantity)
            }
        })
        let f = [...costForm]
        totalCost(f)
        setCostFrom(f)
        setTotal(c)
    }

    function selectedContent(e) {
        setContent(e === '' ? null : e)
        getContentById(e.value)
    }

    function changeCount(e, id) {
        arr1.map((item, index) => {
            if (index === id) {
                if (parseFloat(e.target.value) >= item.amount) {
                    item.quantity = parseFloat(e.target.value)
                    if (item.byProduct) {
                        item.totalPrice = item.quantity * Math.round((item.salePrice + Number.EPSILON) * 100) / 100
                    } else {
                        item.totalPrice = item.quantity * Math.round((item.buyPrice + Number.EPSILON) * 100) / 100
                    }
                    item.active = true
                    item.disabled = false
                } else {
                    item.quantity = parseFloat(e.target.value)
                    if (item.byProduct) {
                        item.totalPrice = item.quantity * Math.round((item.salePrice + Number.EPSILON) * 100) / 100
                    } else {
                        item.totalPrice = item.quantity * Math.round((item.buyPrice + Number.EPSILON) * 100) / 100
                    }
                    item.disabled = false
                    item.active = false
                }
            }
        })
        let a = [...arr1]
        setArr1(a)
        xisobkitob()
    }


    useEffect(() => {
        if (productionReducer.saveBoolean) {
            history.push('/main/production/table')
        }
        setSaveModal(false)
    }, [productionReducer.current])



    function SaveProduction() {
        let a
        a = !costPrice ? parseInt(total - totalTable2) + parseInt(addSum === "" ? 0 : addSum)
            : parseInt(total - totalTable2) + (parseInt(addSum === "" ? 0 : addSum) * allOrder)
        saveProduction({
            branchId: mainBranchId ? mainBranchId : users.branchId,
            contentPrice: total,
            cost: addSum,
            costEachOne: costPrice,
            costDtoList: costForm,
            date: date,
            productId: productId,
            productTypePriceId: productTypePriceId,
            totalQuantity: parseFloat(allOrder),
            invalid: parseFloat(invalid),
            totalPrice: a,
            contentProductDtoList: arr1,
            lossContentProductDtoList: arr2
        })
        setSaveModal(true)
    }

    const dateObj = new Date();

    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObj.getDate()).slice(-2);
    const year = dateObj.getFullYear();

    const shortDate = `${year}-${month}-${day}`;

    useEffect(() => {
        changedate(shortDate)
    }, [])

    const tarkibOptions = [
        {label: t('num.25'), value: ''},
        ...contentReducer.content ?
            contentReducer.content?.map(item =>
                ({ value: item.id, label: item.product ? item.product?.name : item.productTypePrice?.name})
            ) : ''
    ]

    return (
        <div className="col-md-12 mt-2">
            <div className="textHeader">
                <h2>{t('num.23')} </h2>
            </div>
            <div className="rowStyle1">
                <div className="row">
                    <div className="col-md-3 col-sm-12 mb-2">
                        <h6>{t('num.24')}</h6>
                        <Select
                            value={content}
                            onChange={selectedContent}
                            options={tarkibOptions}
                        />
                    </div>
                    {
                        users.branches.length > 1 ?
                            <div className="col-md-3 col-sm-12 mb-2">
                                <h6>{t('num.26')}</h6>
                              <div style={{background:"#666"}}>  <select  value={branch} onChange={(e) => {
                                    changebranch(e.target.value)
                                    setContent(null)
                                    setMainBranchId(e.target.value)
                                    setArr1([])

                                }}
                                        className={'select-content-control form-control rounded-0'}>
                                    {
                                        users.branches.map(item =>
                                            <option  value={item.id}>{item.name}</option>
                                        )
                                    }</select></div>
                            </div> : ''
                    }

                    <div className="col-md-3 col-sm-12 mb-2">
                        <h6>{t('num.27')}</h6>
                        <input value={date} onChange={(e) => changedate(e.target.value)} type="date"
                               className={'content-control'}/>
                    </div>


                    <div className="col-md-3 col-sm-12 mb-2">
                        <h6>{t('num.28')}</h6>
                        <div className={'d-flex'}>
                            <input className={'content-control'} type="number"
                                   value={allOrder}
                                   onChange={changeOrder}
                                   placeholder=''/>
                            <input className={'content-control'} value={measure} type="text" disabled={true}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"rowStyle1"}>
                <h2>{t('num.29')}</h2>
                <div className="col-md-12">
                    <div className="table-responsive">
                        <table className={'table mt-3 border text-center'}>
                            <thead className={'bg-success text-white fw-bold'}>
                            <tr>
                                <th>{t('num.5')}</th>
                                <th>{t('num.30')}</th>
                                <th>{t('num.31')}</th>
                                <th>{t('num.32')}</th>
                                <th>{t('num.11')}</th>
                                <th></th>
                            </tr>
                            </thead>

                            <tbody>

                            {
                                arr1.length > 0 ?
                                    arr1.map((item, index) =>
                                        <tr className={'text-center'}>
                                            <td>{item.name}</td>

                                            <td>
                                                <div className={'d-flex'}>
                                                    <input className={'form-control'}
                                                           name={'quantity'}
                                                           value={item.quantity}
                                                           onChange={(e) => changeCount(e, index)}
                                                           type="number"/>
                                                    <input className={'form-control'} type="text" disabled={true}
                                                           value={item.measurementName}/>
                                                </div>
                                                {
                                                    !item.byProduct ?
                                                        item.quantity > item.amount ?
                                                            <span
                                                                className={'text-danger'}>Only {item.amount} available</span> : '' : ''
                                                }
                                            </td>
                                            <th>
                                                <input type="checkbox" disabled={true} checked={!item.byProduct}/>
                                            </th>
                                            <th>
                                                <input type="checkbox" disabled={true} checked={item.byProduct}/>
                                            </th>
                                            <td>

                                                <h6 className={"ms-2 mt-2 p-0"}>  {item.totalPrice} {t('num.12')}</h6></td>

                                        </tr>
                                    ) : ''
                            }
                            </tbody>
                        </table>
                    </div>

                </div>
                <div className="row ">
                    <div className="offset-9 col-md-2">
                        <h6>{t('num.13')} {total}</h6>
                    </div>
                </div>

                <div className="row d-flex align-items-center justify-content-between">
                    <div className="col-md-12 d-flex">
                        <div className="col-md-3 col-sm-12">
                            <h6>{t('num.33')}</h6>
                            <div className={'d-flex'}>
                                <input value={invalid} onChange={(e) => setInvaled(e.target.value)}
                                       className={'content-control'} type="text"
                                       placeholder=''/>
                                <input className={'content-control'} value={measure} type="text" disabled={true}/>
                            </div>
                        </div>
                        <div className="col-md-5 col-sm-12">
                            <div className={'d-flex align-items-center'}>
                                <h6>{t('num.3')}</h6>
                            </div>
                            <div className={'d-flex  align-items-center flex-wrap'}>
                                {
                                    costForm.map((item, index) =>
                                        <div className={'d-flex justify-content-between me-2 mb-2'}>
                                            <input className={'content-control'}
                                                   value={item.sum}
                                                   name={'sum'}
                                                   onChange={(e) => changeAddSum(e, index)}
                                                   type="text" placeholder='narxi'/>
                                            <select value={item.costTypeName} disabled={true} name={'costTypeId'}
                                                    className={'content-control'}>
                                                <option value={item.costTypeName}>{item.costTypeName}</option>
                                            </select>
                                        </div>
                                    )
                                }
                                <div className={'d-flex  align-items-center flex-wrap'}>
                                    <h4>{addSum} {t('num.12')}</h4>
                                    <select className={'content-control'} disabled={true} value={costPrice}
                                            onChange={changeCostPrice}>
                                        <option value={'false'}>{t('num.18')}</option>
                                        <option value={'true'}>Har biriga</option>
                                    </select>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-sm-12 my-2 mt-4">
                        {
                            invalid > 0 ?
                                <div>
                                    <h2>{t('num.35')}</h2>
                                    <div className="col-md-12 position-relative p-0">
                                        <input type="text"
                                               value={searchContent}
                                               onChange={onChangeSearch}
                                               className={'form-control p-2 po'}
                                               style={{width: '100%'}}
                                               placeholder={t('num.4')}/>

                                        {
                                            ComboArray.length > 0 ?
                                                <div className={'Combo-array  position-absolute z-index'}>
                                                    {
                                                        ComboArray.map(item =>
                                                            <p onClick={() => pushesh(item)}>
                                                                {item.name}
                                                            </p>
                                                        )

                                                    }
                                                </div> : ''
                                        }

                                    </div>
                                    <div className="col-md-12">
                                        <div className="table-responsive">
                                            <table className={'table mt-3 border text-center'}>
                                                <thead className={'bg-success text-white fw-bold'}>
                                                <tr>
                                                    <th>{t('num.5')}</th>
                                                    <th>{t('num.30')}</th>
                                                    <th>{t('num.11')}</th>
                                                    <th></th>
                                                </tr>
                                                </thead>

                                                <tbody>

                                                {
                                                    arr2.length > 0 ?
                                                        arr2.map((item, index) =>
                                                            <tr className={'text-center'}>
                                                                <td>{item.name}</td>
                                                                <td>
                                                                    <div className={'d-flex'}>
                                                                        <input className={'form-control'}
                                                                               name={'quantity'}
                                                                               value={item.quantity}
                                                                               onChange={(e) => changeCountNumber(e, index)}
                                                                               type="number"/>
                                                                        <input className={'form-control'} type="text"
                                                                               disabled={true}
                                                                               value={item.measurementName}/>
                                                                    </div>

                                                                </td>
                                                                <td>

                                                                    <h6 className={"ms-2 mt-2 p-0"}>  {item.totalPrice} {t('num.12')}</h6>
                                                                </td>
                                                                <td>
                                                                    <button onClick={() => deleteM(index)}
                                                                            className={'btn btn-danger'}>{t('num.36')}
                                                                    </button>
                                                                </td>

                                                            </tr>
                                                        ) : ''
                                                }
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div> : ''
                        }
                    </div>
                    <div className="col-md-12 col-sm-12 d-flex align-items-center mt-2">
                        <div className={'offset-9 col-md-3'}>
                            <h6>{t('num.20')} :
                                {!costPrice ?
                                    parseInt(total - totalTable2) + parseInt(addSum === "" ? 0 : addSum)

                                    : parseInt(total - totalTable2) + (parseInt(addSum === "" ? 0 : addSum) * allOrder)

                                }
                            </h6>
                        </div>

                    </div>
                </div>

                <div className='saqlash'>
                    <button onClick={SaveProduction} className='btn btn-primary'>{t('num.22')}</button>
                </div>
            </div>


            <ModalLoading isOpen={saveModal}/>
        </div>
    );
}

export default connect((XaridReducer, users, contentReducer, productionReducer), {
    getContent, getContentById, saveProduction, getXaridProductType
})(AddProduction)
