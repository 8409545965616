import './dukon.css'
import {Link, Route, Switch} from "react-router-dom";
import Dukon1 from "./dukon/Dukon1";
import Soliq from "./soliq/Soliq";
import Mahsulot2 from "./maxsulot/Mahsulot2";
import Chek from "./chek/Chek";
import Savdo2 from "./savdo/Savdo2";
import SavdoOyna from "./SavdoOynasi/SavdoOyna";
import XaridlarDukonSozlama from "./XaridlarDukonSozlama/XaridlarDukonSozlama";
import BoshSahifaDukonSozlama from "./BoshSahifaDukonSozlama/BoshSahifaDukonSozlama";
import TizimKorinishiDukonSozlama from "./TizimKorinishiDukonSozlama/TizimKorinishiDukonSozlama";
import QisqaQoshimcha from "./QisqaQoshimcha/QisqaQoshimcha";
import EmailSozlama from "./eMailSozlama/EmailSozlama";
import VALYUTA from './Valyuta/Valyuta';
import {connect} from "react-redux";
import users from "../../../../../reducer/users";
import {useTranslation} from "react-i18next";
import {Tabs} from 'antd';
import Bazalar from "../bazalar/Bazalar";
import {useState} from "react";


function Dukon({users}) {
    const {t} = useTranslation()

    const [openPage, setOpenPage] = useState(1)
    return (
        <div className={'containerSet'}>
            <div className="dashboardBox">
                <button value={1} onClick={(e) => setOpenPage(e.target.value)}
                        className={'btn btn-outline-primary form-control mt-2'}>{t('set.dukon')}</button>
                {
                    users.editMyBusiness || users.viewMyBusiness ?
                        <button value={3} onClick={(e) => setOpenPage(e.target.value)}
                                className={'btn btn-outline-primary form-control mt-2'}>{t('set.Mahsulot')}</button>
                        : ''
                }
                {
                    users.editInvoiceChecked ?
                        <button value={4} onClick={(e) => setOpenPage(e.target.value)}
                                className={'btn btn-outline-primary form-control mt-2'}>{t('set.Chek')}</button>
                        : ''
                }
                <button  value={5} onClick={(e) => setOpenPage(e.target.value)} className={'btn btn-outline-primary form-control mt-2'}>{t('set.VALYUTA')}</button>
            </div>

            <div className="blokSet">
                {
                    openPage == 1 && <Dukon1/>
                }
                {
                    openPage == 2 && <Soliq/>
                }
                {
                    openPage == 3 && <Mahsulot2/>
                } {
                openPage == 4 && <Chek/>
            } {
                openPage == 5 && <VALYUTA/>
            }
            </div>
        </div>
    )
}

export default connect((users), {})(Dukon)