import React, {useState, forwardRef, useEffect} from 'react';
import {Link, Link as RouterLink, useLocation} from 'react-router-dom';
import {DragDropContext} from 'react-beautiful-dnd';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {
    Avatar,
    Box,
    Card,
    Grid,
    Slide,
    Divider,
    Tooltip,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableContainer,
    TableRow,
    ToggleButton,
    ToggleButtonGroup,
    Tabs,
    TextField,
    Button,
    Typography,
    Dialog,
    styled,
    Select,
    FormControl, MenuItem, InputLabel

} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import VisibilityIcon from '@mui/icons-material/Visibility';

import users from "../../../../../reducer/users";
import Loading from "../../../../Loading";
import ModalLoading from "../../../../ModalLoading";
import DatePicker from "@mui/lab/DatePicker";
import XodimReducer, {getAgreement} from "../../Hodimlar/reducer/XodimReducer";
import SalaryCountReducer, {
    editSalaryCount, getOneSalaryCount,
    getSalaryCount,
    saveSalaryCount
} from "../../Hodimlar/reducer/SalaryCountReducer";
import SalaryReducer, {
    editAvans,
    editSalary,
    getSalaryByBranch,
    getSalaryByUser
} from "../../../../../reducer/SalaryReducer";
import formatDate, {formatDateMinus} from "../../../../../util";
import EditIcon from "@mui/icons-material/Edit";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {toast} from "react-toastify";
import eslatma from "../../Savdo/SavdoOynasi/Final/Eslatma";
import JobReducer, {deleteJob, editJob, getJob, saveJob} from "../reducer/JobReducer";

const DialogWrapper = styled(Dialog)(
    () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);



const AvatarError = styled(Avatar)(
    ({theme}) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const applyPagination = (tasks, page, limit) => {
    return tasks.slice(page * limit, page * limit + limit);
};

const Jobs = ({     saveJob,deleteJob,editJob,getJob,JobReducer,
                    users,
                }) => {
    const {t} = useTranslation();

    const [saveModal, setSaveModal] = useState(false)
    const [openSaveJob, setSaveOpenJob] = useState(false)
    const [openEditJob, setEditOpenJob] = useState(false)
    const [jobName,setJobName] = useState('')
    const [jobDescription,setJobDescription]= useState('')
    const [jobId,setJobId]= useState(null)

    function openSaveJobToggle(){
        setSaveOpenJob(!openSaveJob)
    }
    function openEditJobToggle(){
        setEditOpenJob(!openEditJob)
    }

    useEffect(() => {
        getJob(users.businessId)
    }, [])

    const [editTrue, setEditTrue] = useState(false)
    function edit(id){
        setEditTrue(!editTrue)
        setJobId(id)
        openEditJobToggle()

        JobReducer.jobs.map(item=>{
            if (item.id===id){
                setJobDescription(item.description)
                setJobName(item.name)
            }
        })

    }

    function exit(){
        setJobName('')
        setJobDescription('')
        setJobId(null)
        openSaveJobToggle()
    }
    function saqlaJob(){
        if (jobId){
            editJob({
                id:jobId,
                businessId: users.businessId,
                name: jobName,
                description: jobDescription
            })
        }else {
            saveJob({
                businessId: users.businessId,
                name: jobName,
                description: jobDescription
            })
        }

        setSaveModal(true)
    }

    useEffect(()=>{
        if (JobReducer.saveBoolean){
            setJobName('')
            setJobDescription('')
            setSaveOpenJob(false)
            setEditOpenJob(false)
            setJobId(null)
            getJob(users.businessId)
        }
        setSaveModal(false)
    },[JobReducer.current])

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
    }, [JobReducer.current])
    useEffect(() => {
        setLoading(false)
    }, [])

    const [deletemodal, setdeletemodal] = useState(false)
    const [deleteID, setdeletID] = useState('')

    function deleteFunc() {
        deleteJob(deleteID)
        setSaveModal(true)
    }

    useEffect(()=>{
        if (JobReducer.deleteBoolean){
            setdeletemodal(false)
        }
        getJob(users.businessId)
        setSaveModal(false)
    },[JobReducer.current])


    function deleteModaltoggle(item) {
        setdeletemodal(!deletemodal)
        setdeletID(item)
    }

    return (
        <>
            <div className={'col-md-12 mt-4 p-2'}>
                {
                    users.viewJobChecked?
                    // loading?
                        <Card className={'p-2'}>
                            <Divider/>
                            <>
                            {
                                users.addJobChecked?
                                    <button onClick={openSaveJobToggle} className={'btn mt-4 btn-primary mb-4'}>{t('ol.10')}</button>
                                    :''
                            }

                                {
                                    JobReducer.jobs?
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>#</TableCell>
                                                <TableCell>{t('ol.11')}</TableCell>
                                                <TableCell>{t('ol.12')}</TableCell>
                                                <TableCell align="center">{t('ol.13')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                JobReducer.jobs.map((item,index)=><TableRow key={index}>
                                                    <TableCell>{index+1}</TableCell>
                                                    <TableCell>{item.name}</TableCell>
                                                    <TableCell>{item.description}</TableCell>
                                                    <TableCell align="center">
                                                        <Typography noWrap>
                                                            {
                                                                users.editJobChecked?
                                                                    <Tooltip title={t('ol.14')} arrow>
                                                                        <IconButton color={'primary'}
                                                                                    onClick={()=>edit(item.id)} >
                                                                            <EditIcon/>
                                                                        </IconButton>
                                                                    </Tooltip>:''
                                                            }
                                                            {
                                                                users.deleteJobChecked?
                                                                    <Tooltip title={t('ol.15')} arrow>
                                                                        <IconButton
                                                                            onClick={() =>deleteModaltoggle(item.id)}
                                                                            color="primary"
                                                                        >
                                                                            <DeleteTwoToneIcon fontSize="small"/>
                                                                        </IconButton>
                                                                    </Tooltip>:''
                                                            }


                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>)
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>:<div><h4 className={'text-center'}>{t('Darslik.mtt')}</h4></div>
                                }
                            </>
                        </Card>:''
                    // :<Loading/>
                    // :<div> <h4 className={'text-center'}>NOT FOUND</h4></div>
                }

                {/*ADD JOB*/}
                <Modal isOpen={openSaveJob} toggle={openSaveJobToggle} size={'xl'} >
                    <ModalHeader>
                        {
                            <h5>{t('ol.16')}</h5>
                        }
                    </ModalHeader>
                    <ModalBody>
                        {
                                <div className="row">
                                    <div className="col-md-12 d-flex justify-content-between">
                                        <div className="col-md-6">
                                            <label htmlFor="">{t('ol.11')}</label>
                                            <input type="text" value={jobName} onChange={(e)=>setJobName(e.target.value)} className={'form-control'}/>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="">{t('ol.12')}</label>
                                            <input type="text" value={jobDescription} onChange={(e)=>setJobDescription(e.target.value)} className={'form-control'}/>
                                        </div>
                                    </div>
                                </div>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <button onClick={saqlaJob} className={'btn btn-outline-primary'}>{t('ol.17')}</button>
                        <button onClick={openSaveJobToggle} className={'btn btn-primary'}>{t('ol.18')}</button>
                    </ModalFooter>
                </Modal>

                {/*EDIT JOB*/}
                <Modal isOpen={openEditJob} toggle={openEditJobToggle} size={'xl'} >
                    <ModalHeader>
                        {
                            <h5>{t('ol.16')}</h5>
                        }
                    </ModalHeader>
                    <ModalBody>
                        {
                            <div className="row">
                                <div className="col-md-12 d-flex justify-content-between">
                                    <div className="col-md-6">
                                        <label htmlFor="">{t('ol.11')}</label>
                                        <input type="text" value={jobName} onChange={(e)=>setJobName(e.target.value)} className={'form-control'}/>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="">{t('ol.12')}</label>
                                        <input type="text" value={jobDescription} onChange={(e)=>setJobDescription(e.target.value)} className={'form-control'}/>
                                    </div>
                                </div>
                            </div>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <button onClick={saqlaJob} className={'btn btn-outline-primary'}>{t('ol.17')}</button>
                        <button onClick={exit} className={'btn btn-primary'}>{t('ol.18')}</button>
                    </ModalFooter>
                </Modal>

                {/*DELETE JOB*/}
                <Modal isOpen={deletemodal} toggle={deleteModaltoggle}>
                    <ModalBody>
                        <h5>{t('Buttons.12')} ?</h5>
                    </ModalBody>
                    <ModalFooter>
                        <button onClick={deleteFunc}
                                className={'btn btn-outline-primary'}>{t('Buttons.3')}</button>
                        <button onClick={() => deleteModaltoggle('')}
                                className={'btn btn-outline-primary'}>{t('Buttons.7')}</button>
                    </ModalFooter>
                </Modal>

                <ModalLoading isOpen={saveModal}/>
            </div>
        </>
    );
};

export default connect(( users,JobReducer), {
    getJob,saveJob,editJob,deleteJob
})(Jobs);
