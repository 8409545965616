import {
    Box,
    Typography,
    Card,
    CardHeader,
    Divider,
    Avatar,
    useTheme,
    styled,
    Grid,
    IconButton,
    alpha,
    Tooltip,
    CardActionArea,
    Button,
    List,
    ListItem,
    LinearProgress,
    linearProgressClasses,
    CardContent,
    CircularProgress,
    circularProgressClasses,
    ListItemAvatar,
    ListItemText,
    ListItemButton
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import users, {userInfo, userDoHistory} from "../../../../reducer/users";
import PersonTwoToneIcon from '@mui/icons-material/PersonTwoTone';
import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone';
import MonetizationOnTwoToneIcon from '@mui/icons-material/MonetizationOnTwoTone';
import AccountBalanceWalletTwoToneIcon from '@mui/icons-material/AccountBalanceWalletTwoTone';
import XodimReducer, {getAgreement, getXodim} from "../../Sidebar/Hodimlar/reducer/XodimReducer";
import formatDate, {formatDateMinus, formatIcon} from "../../../../util";
import SalaryCountReducer, {getSalaryCount} from "../../Sidebar/Hodimlar/reducer/SalaryCountReducer";
import prizeReducer, {getBonusSumm, getPrize, viewPrize} from "../../../../reducer/prizeReducer";
import LessonUserReducer, {getViewByUserId} from "../../Sidebar/Darsliklar/reducer/LessonUserReducer";
import {Link} from "react-router-dom";
import MoreHorizTwoToneIcon from "@mui/icons-material/MoreHorizTwoTone";
import Text from "./design/Text";
import AccountTreeTwoToneIcon from "@mui/icons-material/AccountTreeTwoTone";
import ContactSupportTwoToneIcon from "@mui/icons-material/ContactSupportTwoTone";
import DashboardCustomizeTwoToneIcon from "@mui/icons-material/DashboardCustomizeTwoTone";
import CloudDownloadTwoToneIcon from "@mui/icons-material/CloudDownloadTwoTone";
import ArrowForwardTwoToneIcon from "@mui/icons-material/ArrowForwardTwoTone";
import MoreVertTwoToneIcon from "@mui/icons-material/MoreVertTwoTone";
import FiberManualRecordTwoToneIcon from "@mui/icons-material/FiberManualRecordTwoTone";
import Chart from "react-apexcharts";
import {axiosCreate, BaseUrl} from "../../../../middleware";
import SalaryReducer, {getUserMonths} from "../../../../reducer/SalaryReducer";
import GaugeChart from 'react-gauge-chart';
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import KeyboardArrowUpTwoToneIcon from "@mui/icons-material/KeyboardArrowUpTwoTone";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import UserWorkTable from "./UserWorkTable";

const LinearProgressError = styled(LinearProgress)(
    ({theme}) => `
        height: 6px;
        border-radius: ${theme.general.borderRadiusLg};

        &.${linearProgressClasses.colorPrimary} {
            background-color: ${(theme.colors.red)};
        }
        
        & .${linearProgressClasses.bar} {
            border-radius: ${theme.general.borderRadiusLg};
            background-color: ${theme.colors.primary};
        }
    `
);

const ListItemButtonWrapper = styled(ListItemButton)(
    ({theme}) => `
    transition: ${theme.transitions.create(['transform', 'box-shadow'])};
    transform: scale(1);
    background: ${theme.colors.alpha.white[100]};
    position: relative;
    z-index: 5;

    &:hover {
        border-radius: ${theme.general.borderRadius};
        background: ${theme.colors.alpha.white[100]};
        z-index: 6;
        box-shadow: 
            0 0.56875rem 3.3rem ${alpha(theme.colors.alpha.black[100], 0.05)},
            0 0.9975rem 2.4rem ${alpha(theme.colors.alpha.black[100], 0.07)},
            0 0.35rem 1rem ${alpha(theme.colors.alpha.black[100], 0.1)},
            0 0.225rem 0.8rem ${alpha(theme.colors.alpha.black[100], 0.15)};
        transform: scale(1.08);
    }
  `
);

const LinearProgressSuccess = styled(LinearProgress)(
    ({theme}) => `
        height: 6px;
        border-radius: ${theme.general.borderRadiusLg};

        &.${linearProgressClasses.colorPrimary} {
            background-color: ${(theme.colors.green)};
        }
        
        & .${linearProgressClasses.bar} {
            border-radius: ${theme.general.borderRadiusLg};
            background-color: ${theme.colors.green};
        }
    `
);

const LinearProgressInfo = styled(LinearProgress)(
    ({theme}) => `
        height: 6px;
        border-radius: ${theme.general.borderRadiusLg};

        &.${linearProgressClasses.colorPrimary} {
            background-color: ${(theme.colors.green)};
        }
        
        & .${linearProgressClasses.bar} {
            border-radius: ${theme.general.borderRadiusLg};
            background-color: ${theme.colors.green};
        }
    `
);


const CardBorderBottom = styled(Card)(
    () => `
    border-bottom: transparent 5px solid;
  `
);

const LinearProgressPrimary = styled(LinearProgress)(
    ({theme}) => `
        height: 6px;
        border-radius: ${theme.general.borderRadiusLg};

        &.${linearProgressClasses.colorPrimary} {
            background-color: ${alpha(theme.colors.primary.main, 0.1)};
        }
        
        & .${linearProgressClasses.bar} {
            border-radius: ${theme.general.borderRadiusLg};
            background-color: ${theme.colors.primary.main};
        }
    `
);

const CardWrapper = styled(Card)(
    ({theme}) => `
      background: ${(theme.colors.black)};
  `
);

const CardActions = styled(Box)(
    ({theme}) => `
    position: absolute;
    right: ${theme.spacing(1.5)};
    top: ${theme.spacing(1.5)};
    z-index: 7;
  `
);

const LabelWrapper = styled(Box)(
    ({theme}) => `
    font-size: ${theme.typography.pxToRem(13)};
    font-weight: bold;
    text-transform: uppercase;
    border-radius: ${theme.general.borderRadiusSm};
    padding: ${theme.spacing(0.9, 1.5, 0.7)};
    line-height: 1;
  `
);

const CardWrapper1 = styled(Box)(
    ({theme}) => `
      background: ${alpha(theme.colors.primary.main, 0.05)};
  `
);

const CardActionAreaWrapper = styled(CardActionArea)(
    ({theme}) => `
        text-align: center;
        background: ${alpha(theme.colors.primary.main, 0.03)};

        .MuiTouchRipple-root {
          opacity: .2;
        }
  
        .MuiCardActionArea-focusHighlight {
          background: ${theme.colors.primary.main};
        }
  
        &:hover {
          .MuiCardActionArea-focusHighlight {
            opacity: .05;
          }
        }
  `
);
const IconButtonWrapper = styled(IconButton)(
    ({theme}) => `
        transform: translateY(0px);
        transition: ${theme.transitions.create(['color', 'transform'])};

        .MuiSvgIcon-root {
            transform: scale(1);
            transition: ${theme.transitions.create(['transform'])};
        }

        &:hover {
            transform: translateY(-2px);
    
            .MuiSvgIcon-root {
                transform: scale(1.3);
            }
        }
  `
);

function RecentActivity({
                            users,
                            match,
                            getXodim,
                            XodimReducer,
                            viewPrize,
                            prizeReducer,
                            getUserMonths,
                            SalaryReducer,
                            getSalaryCount,
                            SalaryCountReducer,
                            getBonusSumm,
                            userInfo,
                            getViewByUserId,
                            LessonUserReducer,
                            getAgreement,
                            userDoHistory
                        }) {
    const {t} = useTranslation();
    const theme = useTheme();
    const [latestHour, setLatestHour] = useState(0)
    const [latestHourUsers, setLatestHourUsers] = useState(null)
    const chart1Options = {
        chart: {
            background: 'transparent',
            toolbar: {
                show: false
            },
            sparkline: {
                enabled: true
            }
        },
        theme: {
            mode: theme.palette.mode === 'dark' ? 'light' : 'dark'
        },
        stroke: {
            colors: [theme.colors.warning.main],
            width: 3
        },
        colors: [theme.colors.warning.main],
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 5,
                columnWidth: '60%'
            }
        },
        markers: {
            size: 0
        },
        tooltip: {
            x: {
                show: false
            },
            y: {
                title: {
                    formatter() {
                        return '';
                    }
                }
            },
            marker: {
                show: false
            }
        },
        yaxis: {
            show: false
        },
        legend: {
            show: false
        }
    };
    const chart1Data = [
        {
            name: 'Foydali koyfitsenti',
            data: [47, 38, 56, 24, 56, 24, 65]
        }
    ];

    const Box1Options = {
        chart: {
            background: 'transparent',
            toolbar: {
                show: false
            },
            sparkline: {
                enabled: true
            },
            zoom: {
                enabled: false
            }
        },
        colors: [theme.colors.warning.main],
        dataLabels: {
            enabled: false
        },
        theme: {
            mode: theme.palette.mode
        },
        stroke: {
            show: true,
            colors: [theme.colors.warning.main],
            curve: 'smooth',
            width: 2
        },
        legend: {
            show: false
        },
        labels: [
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday',
            'Last Week',
            'Last Month',
            'Last Year'
        ],
        xaxis: {
            labels: {
                show: false
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            }
        },
        yaxis: {
            show: false,
            min: 0
        }
    };
    const Box1Data = [
        {
            name: 'Sales',
            data: [32, 52, 45, 32, 54, 56, 28, 25, 36, 62]
        }
    ];
    const [userId, setUserId] = useState(null)
    const [saveSalary, setSaveSalary] = useState(null)
    const [mainBranch, setMainBranch] = useState(null)

    useEffect(() => {
        if (match.params.id) {
            setUserId(match.params.id)
        }
        getAgreement(match.params.id ? match.params.id : users.id)
        userInfo(match.params.id ? match.params.id : users.id)
        getSalaryCount({
            branchId: users.branchId,
            userId: match.params.id ? match.params.id : users.id
        })
        viewPrize({
            userId: match.params.id ? match.params.id : users.id,
            params: {
                branchId: users.branchId
            }
        })
        getViewByUserId(match.params.id ? match.params.id : users.id)
        getXodim(users.businessId)
        getUserMonths({
            userId: match.params.id ? match.params.id : users.id,
            branchId: users.branchId
        })
        getBonusSumm({
            userId: match.params.id ? match.params.id : users.id,
            branchId: users.branchId
        })
    }, [])

    useEffect(() => {
        if (SalaryReducer.userMonth) {
            setSaveSalary(SalaryReducer.userMonth.salary)
        }
        axiosCreate(`/workTimeLate/by-user/${match.params.id ? match.params.id : users.id}?branchId=${mainBranch ? mainBranch : users.branchId}`).then((data) => {
            data.data.success && setLatestHour(data.data.object.hour)
        }).catch((error) => {
            console.error(error)
        })
        axiosCreate(`/workTimeLate/by-branch/${mainBranch ? mainBranch : users.branchId}`).then((data) => {
            data.data.success && setLatestHourUsers(data.data.object)
        }).catch((error) => {
            console.error(error)
        })
    }, [])

    const LinearProgressWarning = styled(LinearProgress)(
        ({theme}) => `
        height: 6px;
        border-radius: ${theme.general.borderRadiusLg};

        &.${linearProgressClasses.colorPrimary} {
            background: ${alpha(theme.colors.alpha.black[100], 0.1)};
        }
        
        & .${linearProgressClasses.bar} {
            border-radius: ${theme.general.borderRadiusLg};
            background: ${theme.colors.gradients.orange1};
        }
    `
    );
    const germanCurrencyFormatter = new Intl.NumberFormat("uz", {notation: "standard"});


    return (
        <div className={' p-2 col-md-12 mb-3'}>

            <div className={'p-2 mb-3 mt-3 col-md-10 offset-1'}>
                <label htmlFor="">{t('tes.2')}</label>
                <select value={mainBranch} onChange={(e) => {
                    getSalaryCount({
                        branchId: e.target.value,
                        userId: match.params.id ? match.params.id : users.id
                    })
                    viewPrize({
                        userId: match.params.id ? match.params.id : users.id,
                        params: {
                            branchId: e.target.value
                        }
                    })
                    // getViewByUserId(match.params.id ? match.params.id : users.id)
                    // getXodim(users.businessId)

                    getUserMonths({
                        userId: match.params.id ? match.params.id : users.id,
                        branchId: e.target.value
                    })

                    getBonusSumm({
                        userId: match.params.id ? match.params.id : users.id,
                        branchId: e.target.value
                    })

                    axiosCreate(`/workTimeLate/by-user/${match.params.id ? match.params.id : users.id}?branchId=${e.target.value}`).then((data) => {
                        data.data.success && setLatestHour(data.data.object.hour)
                    }).catch((error) => {
                        console.error(error)
                    })
                    axiosCreate(`/workTimeLate/by-branch/${e.target.value}`).then((data) => {
                        data.data.success && setLatestHourUsers(data.data.object)
                    }).catch((error) => {
                        console.error(error)
                    })
                }} className={'form-control'}>
                    {
                        users.branches ?
                            users.branches.map(item => <option value={item.id}>{item.name}</option>)
                            : ''
                    }
                </select>
            </div>

            <div>
                <Grid
                    sx={{
                        px: 4
                    }}
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={4}
                >

                    <Grid item md={5} xs={12}>
                        <Card variant="outlined">
                            <CardWrapper1 style={{paddingBottom: "10px"}}
                                          sx={{

                                              background: `linear-gradient(90deg, rgba(2,0,36,0.23435311624649857) 0%, rgba(9,9,121,0.19793855042016806) 35%, rgba(0,212,255,0.22314863445378152) 100%);`,
                                              textAlign: 'center',
                                              display: 'flex',
                                              flexDirection: 'column',
                                              alignItems: 'center',
                                              justifyContent: 'center',
                                              position: 'relative',
                                              pt: 3,
                                              px: 3,
                                              pb: 7
                                          }}
                            >
                                <Link to={'/headerthird/profil/edit'}>

                                    <CardActions>
                                        <IconButton size="small" color="primary" to={'/headerthird/profil/edit'}>
                                            <MoreHorizTwoToneIcon/>
                                        </IconButton>
                                    </CardActions>


                                </Link>

                                {
                                    users.userInfo?.photosId ?
                                        <img style={{maxWidth: '200px', maxHeight: '200px', borderRadius: '50%'}}
                                             src={`${BaseUrl}/attachment/download/${users.userInfo?.photosId}`}
                                             alt=""/> :
                                        <Avatar
                                            sx={{
                                                width: `${theme.spacing(14)}`,
                                                height: `${theme.spacing(14)}`,
                                                mb: 1.5,
                                                border: `${theme.colors.alpha.white[100]} solid 4px`,
                                                boxShadow: `0 2rem 8rem 0 ${alpha(
                                                    theme.colors.alpha.black[100],
                                                    0.05
                                                )}, 
                                0 0.6rem 1.6rem ${alpha(
                                                    theme.colors.alpha.black[100],
                                                    0.15
                                                )}, 
                                0 0.2rem 0.2rem ${alpha(
                                                    theme.colors.alpha.black[100],
                                                    0.1
                                                )}`
                                            }}

                                            src={'/static/images/avatars/3.jpg'}
                                        />
                                }
                                <b style={{color: "#fff"}}>
                                    <Link

                                        href="#"
                                        // color="text.white"
                                        underline="none"
                                        sx={{
                                            transition: `${theme.transitions.create(['color'])}`,
                                            fontSize: `${theme.typography.pxToRem(17)}`,

                                            '&:hover': {
                                                color: `${theme.colors.primary.main}`
                                            }
                                        }}
                                        variant="h4"

                                    >
                                        {users.userInfo?.fio}
                                    </Link>
                                </b>
                                <Box style={{color: "#ffa31e"}} mt={1} mb={1.5}>
                                    {t('tes.3')}
                                    <LabelWrapper
                                        sx={{
                                            background: `${theme.colors.error.main}`,
                                            color: `${theme.palette.getContrastText(theme.colors.error.dark)}`
                                        }}
                                    >
                                        {users.userInfo?.role}


                                    </LabelWrapper>
                                </Box>
                                <Typography color={"#fff"} variant="subtitle2">
                                    {t(
                                        'tes.4'
                                    )}
                                    .
                                </Typography>
                                <Grid xs={12} md={12} lg={12} display={'flex'} justifyContent={'center'}>
                                    <div className={'mt-2'}>
                                        <div className={'d-flex'} style={{flexWrap: 'wrap'}}>
                                            {
                                                prizeReducer.viewPrize ?
                                                    prizeReducer.viewPrize.map(item => <div>
                                                        <Tooltip arrow placement="top" title={item?.bonusName}>
                                                            <IconButtonWrapper
                                                                sx={{
                                                                    boxShadow: `${theme.colors.shadows.primary}`,
                                                                    background: `rgba(255, 248, 255, 0.28)`,
                                                                    color: `${theme.palette.getContrastText(
                                                                        theme.colors.primary.main
                                                                    )}`,
                                                                    '&:hover': {
                                                                        background: `rgba(0, 0, 0, 0.28)`,
                                                                        color: `${theme.palette.getContrastText(
                                                                            theme.colors.primary.main
                                                                        )}`
                                                                    },
                                                                    borderRadius: 50,
                                                                    width: theme.spacing(6),
                                                                    height: theme.spacing(6)
                                                                }}
                                                                color="primary"
                                                            >
                                                                {formatIcon(item?.bonusIcon, item?.bonusColor)}
                                                            </IconButtonWrapper>
                                                        </Tooltip>
                                                    </div>) : ''
                                            }
                                        </div>
                                    </div>
                                </Grid>
                            </CardWrapper1>
                            <Box p={3}>
                                <Box
                                    sx={{
                                        paddingTop: '30px',
                                        textAlign: 'center',
                                        mt: `-${theme.spacing(7)}`,
                                        mb: 3
                                    }}
                                >
                                    {/*<Tooltip arrow placement="top" title="Facebook">*/}
                                    {/*    <IconButtonWrapper*/}
                                    {/*        sx={{*/}
                                    {/*            boxShadow: `${theme.colors.shadows.primary}`,*/}
                                    {/*            background: `${theme.colors.primary.main}`,*/}
                                    {/*            color: `${theme.palette.getContrastText(*/}
                                    {/*                theme.colors.primary.main*/}
                                    {/*            )}`,*/}

                                    {/*            '&:hover': {*/}
                                    {/*                background: `${theme.colors.primary.main}`,*/}
                                    {/*                color: `${theme.palette.getContrastText(*/}
                                    {/*                    theme.colors.primary.main*/}
                                    {/*                )}`*/}
                                    {/*            },*/}
                                    {/*            borderRadius: 50,*/}
                                    {/*            width: theme.spacing(8),*/}
                                    {/*            height: theme.spacing(8)*/}
                                    {/*        }}*/}
                                    {/*        color="primary"*/}
                                    {/*        size="large"*/}
                                    {/*    >*/}
                                    {/*        <FacebookIcon fontSize="small" />*/}
                                    {/*    </IconButtonWrapper>*/}
                                    {/*</Tooltip>*/}
                                </Box>
                                <Box p={2}>
                                    <Grid container spacing={4}>
                                        <Grid item xs={12} sm={6}>
                                            <Card variant="outlined"
                                                  style={{background: "rgba(255,255,255,0.3)"}}>
                                                <CardActionAreaWrapper
                                                    sx={{
                                                        p: 2
                                                    }}
                                                >
                                                    <Text color="warning">
                                                        <AccountTreeTwoToneIcon
                                                            sx={{
                                                                mb: 1
                                                            }}
                                                        />
                                                    </Text>
                                                    <Typography variant="subtitle2" noWrap>
                                                        {t('tes.5')}
                                                    </Typography>
                                                    <Typography variant="h4">{users.userInfo?.role}</Typography>

                                                </CardActionAreaWrapper>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Card style={{background: "rgba(255,255,255,0.3)"}} variant="outlined"
                                            >
                                                <CardActionAreaWrapper
                                                    sx={{
                                                        p: 2
                                                    }}

                                                >
                                                    <Text color="success">
                                                        <ContactSupportTwoToneIcon
                                                            sx={{
                                                                mb: 1
                                                            }}
                                                        />
                                                    </Text>

                                                    <Typography variant="subtitle2" noWrap>
                                                        {t('tes.6')}
                                                    </Typography>
                                                    <Typography variant="h4">{
                                                        XodimReducer.agreement ?
                                                            XodimReducer.agreement.filter(val => val.active === true).map((item, index) =>
                                                                <b
                                                                    key={index}>
                                                                    {item?.salaryStatus + ','}

                                                                </b>)
                                                            : '13'
                                                    }</Typography>

                                                </CardActionAreaWrapper>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} sm={6}
                                              style={{height: 130}}
                                        >
                                            <Card variant="outlined"
                                                  style={{height: 130, background: "rgba(255,255,255,0.3)"}}

                                            >
                                                <CardActionAreaWrapper
                                                    style={{height: 130}}

                                                    sx={{
                                                        p: 1
                                                    }}
                                                >
                                                    <Text color="primary">
                                                        <DashboardCustomizeTwoToneIcon
                                                            sx={{
                                                                mb: 1
                                                            }}
                                                        />
                                                    </Text>

                                                    <Typography variant="subtitle2" noWrap>
                                                        {t('tes.7')}
                                                    </Typography>
                                                    <Typography variant="h4">{
                                                        users.userInfo ?
                                                            users.userInfo?.projectInfoDto.total : ''}</Typography>

                                                </CardActionAreaWrapper>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} sm={6}
                                        >

                                            <Card variant="outlined"
                                                  sx={{
                                                      background: "rgba(255,255,255,0.3)",
                                                      textAlign: 'center',


                                                  }}
                                                  style={{height: 130}}
                                            >
                                                <Box
                                                    sx={{


                                                        mt: 0.3,
                                                        mb: 1,
                                                        mx: 'auto',
                                                        maxWidth: '380px'
                                                    }}
                                                >
                                                    <GaugeChart
                                                        sx={{
                                                            p: 2
                                                        }}
                                                        style={{width: '90%', margin: '0 auto'}}
                                                        nrOfLevels={16}
                                                        hideText
                                                        colors={[
                                                            theme.colors.error.main,
                                                            theme.colors.warning.main,
                                                            theme.colors.success.main
                                                        ]}
                                                        needleColor={theme.colors.alpha.black[100]}
                                                        needleBaseColor={theme.colors.alpha.black[100]}
                                                        arcWidth={0.25}
                                                        arcPadding={0.03}
                                                        cornerRadius={3}
                                                        percent={0.1}
                                                    />

                                                    <Typography  variant="subtitle3" noWrap>
                                                        {t('tes.8')}
                                                    </Typography>
                                                    <Typography  variant="h4">{
                                                        XodimReducer.agreement ?
                                                            XodimReducer.agreement.filter(val => val.salaryStatus === 'KPI').map((item, index) =>
                                                                <b
                                                                    key={index}>
                                                                    {item.price + ' %'}
                                                                </b>)
                                                            : ''
                                                    }
                                                    </Typography>


                                                </Box>
                                            </Card>


                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box p={2}>
                                    <Grid container spacing={4}>
                                        <Grid item xs={12} sm={6}>
                                            <Card variant="outlined"
                                                // style={{background:"rgba(255,255,255,0.3)"}}
                                            >
                                                <CardActionAreaWrapper
                                                    sx={{
                                                        p: 2
                                                    }}

                                                >
                                                    <Text color="success">
                                                        <ContactSupportTwoToneIcon
                                                            sx={{
                                                                mb: 1
                                                            }}
                                                        />
                                                    </Text>

                                                    <Typography variant="subtitle2" noWrap>
                                                        {t('tes.9')}
                                                    </Typography>
                                                    <Typography variant="h4">{
                                                        users.userInfo?.tradeResultDto ?
                                                            users.userInfo?.tradeResultDto?.totalTrade
                                                            : <div><h4 className={'text-center'}>{t('Darslik.mtt')}</h4></div>
                                                    } {t('tes.10')}</Typography>

                                                </CardActionAreaWrapper>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Card style={{background: "rgba(255,255,255,0.3)"}} variant="outlined"
                                                  sx={{
                                                      background: '#effaee',
                                                      textAlign: 'center',


                                                  }}>
                                                <CardActionAreaWrapper
                                                    sx={{
                                                        p: 2
                                                    }}
                                                >
                                                    <Text color="success">
                                                        <ContactSupportTwoToneIcon
                                                            sx={{
                                                                mb: 1
                                                            }}
                                                        />
                                                    </Text>

                                                    <Typography variant="subtitle2" noWrap>
                                                        {t('tes.11')}
                                                    </Typography>
                                                    <Typography style={{fontWeight: "bold"}}> {
                                                        users.userInfo?.tradeResultDto ?

                                                            germanCurrencyFormatter.format(parseFloat(users.userInfo?.tradeResultDto?.avarage).toFixed(0))
                                                            : <h4>0</h4>
                                                    } {t('tes.12')}</Typography>

                                                </CardActionAreaWrapper>
                                            </Card>
                                        </Grid>


                                    </Grid>
                                </Box>
                            </Box>
                        </Card>

                        <Card style={{marginTop: "30px", padding: "20px", background: "#ffffff"}}>

                            <Grid item xs={12} sm={12}>
                                <CardBorderBottom style={{background: "#ffffff", paddingTop: "20px"}}
                                                  sx={{
                                                      borderBottomColor: `${theme.colors.success.main}`,
                                                      boxShadow: `
                    0 .7rem 1rem ${alpha(theme.colors.success.main, 0.08)},
                    0 .25rem .7rem ${alpha(theme.colors.success.main, 0.15)}
                    `,
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                      p: 2
                                                  }}
                                >
                                    <Box style={{height: "120px"}} flexGrow={1} mr={2}>
                                        <Typography
                                            color={"#fff"}
                                            component="div"
                                            fontWeight="bold"
                                            sx={{
                                                pb: 1
                                            }}
                                            variant="caption"
                                        >
                                            {t('tes.13')}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                lineHeight: 1,
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                            variant="h2"
                                        >
                                            <AddTwoToneIcon
                                                sx={{
                                                    mr: 0.5,
                                                    color: `${theme.colors.success.main}`
                                                }}
                                            />
                                            <span>{
                                                users.userInfo?.tradeResultDto ?
                                                    germanCurrencyFormatter.format(parseFloat(users.userInfo?.tradeResultDto?.totalTradeSumma).toFixed(0))
                                                    : <h4>not found</h4>
                                            }</span>
                                            <Typography
                                                sx={{
                                                    alignSelf: 'self-end',
                                                    pl: 0.5
                                                }}
                                                variant="h4"
                                                fontWeight="normal"
                                                color={"#000000"}
                                            >
                                                {t('tes.12')}
                                            </Typography>
                                        </Typography>
                                    </Box>
                                    <Box display="inline-flex" position="relative">
                                        <Box
                                            sx={{
                                                animationDuration: '550ms',
                                                position: 'absolute',
                                                left: 0,
                                                top: 0,
                                                bottom: 0,
                                                right: 0,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: `${theme.colors.success.main}`
                                                }}
                                                variant="h5"
                                            >
                                                76%
                                            </Typography>
                                        </Box>
                                        <CircularProgress
                                            variant="determinate"
                                            sx={{
                                                color: theme.colors.success.lighter
                                            }}
                                            size={70}
                                            thickness={2}
                                            value={100}
                                        />
                                        <CircularProgress
                                            size={70}
                                            sx={{
                                                animationDuration: '550ms',
                                                position: 'absolute',
                                                left: 0,
                                                color: theme.colors.success.main,
                                                top: 0,
                                                [`& .${circularProgressClasses.circle}`]: {
                                                    strokeLinecap: 'round'
                                                }
                                            }}
                                            thickness={2}
                                            variant="determinate"
                                            value={70}
                                        />
                                    </Box>
                                </CardBorderBottom>


                                <Grid style={{paddingTop: "20px"}} item xs={12} md={12}>
                                    <Card
                                        sx={{
                                            background: '#ffffff',
                                            overflow: 'visible',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <Box py={1} px={2.5}>
                                            <Typography
                                                variant="caption"
                                                sx={{
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                {t('tes.14')}
                                            </Typography>
                                            <Typography
                                                variant="h2"
                                                sx={{
                                                    pt: 1
                                                }}
                                            >
                                                70 %
                                            </Typography>
                                            <Typography
                                                variant="h5"
                                                sx={{
                                                    lineHeight: 1,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    color: `${theme.colors.success.main}`
                                                }}
                                            >
                                                <span>52,12%</span>

                                                <KeyboardArrowUpTwoToneIcon fontSize="small"/>
                                            </Typography>
                                            <span>{t('tes.15')}</span>

                                        </Box>
                                        <Box ml={2} flexGrow={1} pt={3}>
                                            <Chart
                                                options={chart1Options}
                                                series={chart1Data}
                                                type="bar"
                                                height="55%"
                                            />
                                        </Box>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>


                    <Grid item md={7} xs={12}>


                        <Card style={{background: "#ffffff"}} variant="outlined">
                            <Box
                                display="flex"
                                alignItems="center"
                                p={3}
                                justifyContent="space-between"
                            >
                                <Box>
                                    <Typography
                                        component="div"
                                        sx={{
                                            fontSize: `${theme.typography.pxToRem(17)}`
                                        }}
                                        gutterBottom
                                        variant="h3"
                                    >
                                        {t('tes.16')}
                                    </Typography>

                                    <Typography
                                        component="div"
                                        fontWeight="normal"
                                        // color:{'#fff'}
                                        variant="h5"
                                    >
                                        {t('tes.17')}
                                    </Typography>
                                </Box>
                                {/*<Button*/}
                                {/*    size="small"*/}
                                {/*    color="secondary"*/}
                                {/*    variant="outlined"*/}
                                {/*    startIcon={<CloudDownloadTwoToneIcon/>}*/}
                                {/*>*/}
                                {/*    {t('tes.18')}
                                {/*</Button>*/}
                            </Box>
                            <Divider/>
                            <Box p={3}>
                                <Grid container spacing={6}>
                                    <Grid
                                        item
                                        xs={12}
                                        md={5}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <List
                                            sx={{
                                                width: '100%'
                                            }}
                                            disablePadding
                                        >
                                            <ListItem
                                                disableGutters
                                                sx={{
                                                    display: 'block'
                                                }}
                                            >
                                                <Box pb={0.5}>
                                                    <Typography

                                                        component="span"
                                                        variant="h3"
                                                        sx={{
                                                            pr: 1

                                                        }}
                                                    >
                                                        {
                                                            SalaryCountReducer.salaryCount ?
                                                                SalaryCountReducer.salaryCount.filter(val => val?.agreementName === 'MONTH')
                                                                    .map((item, index) => <b key={index}>
                                                                        <b>{item.salary} </b>
                                                                    </b>)
                                                                : '0'
                                                        }

                                                    </Typography>
                                                    <Typography component="span" variant="subtitle2">
                                                        {t('tes.19')}
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            width: '100%',
                                                            mr: 1.5
                                                        }}
                                                    >
                                                        <LinearProgressError variant="determinate" value={
                                                            SalaryCountReducer.salaryCount ?
                                                                SalaryCountReducer.salaryCount.filter(val => val?.agreementName === 'MONTH')
                                                                    .map(item => item.salary !== 0 ? (item.salary * 100 / SalaryReducer.userMonth?.salary).toFixed(0) : 0
                                                                    )
                                                                : 0
                                                        }/>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            minWidth: 35
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="h5"
                                                            sx={{
                                                                color: `${theme.colors.red}`
                                                            }}
                                                        >

                                                            {/*FOIZ CIQAZISH */}
                                                            {
                                                                SalaryCountReducer.salaryCount ?
                                                                    SalaryCountReducer.salaryCount.filter(val => val?.agreementName === 'MONTH')
                                                                        .map((item, index) => <h5 key={index}>
                                                                            <b style={{color: "#45ec0e"}}>{item.salary !== 0 ? (item.salary * 100 / SalaryReducer.userMonth?.salary).toFixed(0) : 0}%</b>
                                                                        </h5>)
                                                                    : ''
                                                            }
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </ListItem>
                                            <ListItem
                                                disableGutters
                                                sx={{
                                                    display: 'block'
                                                }}
                                            >
                                                <Box pb={0.5}>
                                                    <Typography
                                                        // component="span"
                                                        variant="h3" sx={{pr: 1}}
                                                    >
                                                        <div className={'d-flex'}>
                                                            {
                                                                SalaryCountReducer.salaryCount ?
                                                                    SalaryCountReducer.salaryCount.filter(val => val?.agreementName === 'KPI')
                                                                        .map((item, index) => <b key={index}>
                                                                            <b>{item.salary} </b>
                                                                        </b>)
                                                                    : ''
                                                            }
                                                            <Typography className={'ms-2 mt-2'} component="span"
                                                                        variant="subtitle2">
                                                                {t('tes.20')}
                                                            </Typography>
                                                        </div>
                                                    </Typography>
                                                    {/*<Typography component="span" variant="subtitle2">*/}
                                                    {/*    KPI*/}
                                                    {/*</Typography>*/}
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            width: '100%',
                                                            mr: 1.5
                                                        }}
                                                    >
                                                        <LinearProgressSuccess variant="determinate" value={
                                                            SalaryCountReducer.salaryCount ?
                                                                SalaryCountReducer.salaryCount.filter(val => val?.agreementName === 'KPI')
                                                                    .map(item => item.salary !== 0 ? (item.salary * 100 / SalaryReducer.userMonth?.salary).toFixed(0) : 0
                                                                    )
                                                                : 0
                                                        }/>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            minWidth: 35
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="h5"
                                                            sx={{
                                                                color: `${theme.colors.green}`
                                                            }}
                                                        >
                                                            {/*    FOIZ */}
                                                            {
                                                                SalaryCountReducer.salaryCount ?
                                                                    SalaryCountReducer.salaryCount.filter(val => val?.agreementName === 'KPI')
                                                                        .map((item, index) => <h5 key={index}>
                                                                            <b style={{color: "#45ec0e"}}>{item.salary !== 0 ? (item.salary * 100 / SalaryReducer.userMonth?.salary).toFixed(0) : 0}%</b>
                                                                        </h5>)
                                                                    : ''
                                                            }
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </ListItem>

                                            <ListItem
                                                disableGutters
                                                sx={{
                                                    display: 'block'
                                                }}
                                            >
                                                <Box pb={0.5}>
                                                    <Typography
                                                        // component="span"
                                                        variant="h3" sx={{pr: 1}}
                                                    >
                                                        <div className={'d-flex '}>
                                                            {
                                                                SalaryCountReducer.salaryCount ?
                                                                    SalaryCountReducer.salaryCount.filter(val => val?.agreementName === 'DAY')
                                                                        .map((item, index) => <b key={index}>
                                                                            <b>{item.salary} </b>
                                                                        </b>)
                                                                    : ''
                                                            }
                                                            <Typography className={'ms-2 mt-2'} component="span"
                                                                        variant="subtitle2">
                                                                {t('tes.21')}
                                                            </Typography>
                                                        </div>
                                                    </Typography>
                                                    {/*<Typography component="span" variant="subtitle2">*/}
                                                    {/*    KPI*/}
                                                    {/*</Typography>*/}
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            width: '100%',
                                                            mr: 1.5
                                                        }}
                                                    >
                                                        <LinearProgressSuccess variant="determinate" value={
                                                            SalaryCountReducer.salaryCount ?
                                                                SalaryCountReducer.salaryCount.filter(val => val?.agreementName === 'DAY')
                                                                    .map(item => item.salary !== 0 ? (item.salary * 100 / SalaryReducer.userMonth?.salary).toFixed(0) : 0
                                                                    )
                                                                : 0
                                                        }/>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            minWidth: 35
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="h5"
                                                            sx={{
                                                                color: `${theme.colors.green}`
                                                            }}
                                                        >
                                                            {/*foiz*/}

                                                            {
                                                                SalaryCountReducer.salaryCount ?
                                                                    SalaryCountReducer.salaryCount.filter(val => val?.agreementName === 'DAY')
                                                                        .map((item, index) => <h5 key={index}>
                                                                            <b style={{color: "#45ec0e"}}>{item.salary !== 0 ? (item.salary * 100 / SalaryReducer.userMonth?.salary).toFixed(0) : 0}%</b>
                                                                        </h5>)
                                                                    : ''
                                                            }
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </ListItem>

                                            <ListItem
                                                disableGutters
                                                sx={{
                                                    display: 'block'
                                                }}
                                            >
                                                <Box pb={0.5}>
                                                    <Typography
                                                        // component="span"
                                                        variant="h3"
                                                        sx={{
                                                            pr: 1
                                                        }}
                                                    >
                                                        <div className={'d-flex '}>
                                                            {
                                                                SalaryCountReducer.salaryCount ?
                                                                    SalaryCountReducer.salaryCount.filter(val => val?.agreementName === 'HOUR')
                                                                        .map((item, index) => <b key={index}>
                                                                            <b>{item.salary} </b>
                                                                        </b>)
                                                                    : ''
                                                            }
                                                            <Typography className={'ms-2 mt-2'} component="span"
                                                                        variant="subtitle2">
                                                                {t('tes.22')}
                                                            </Typography>
                                                        </div>
                                                    </Typography>
                                                    {/*<Typography component="span" variant="subtitle2">*/}
                                                    {/*    Ish bay*/}
                                                    {/*</Typography>*/}
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <Box
                                                        sx={{width: '100%', mr: 1.5}}
                                                    >
                                                        <LinearProgressInfo variant="determinate" value={
                                                            SalaryCountReducer.salaryCount ?
                                                                SalaryCountReducer.salaryCount.filter(val => val?.agreementName === 'HOUR')
                                                                    .map(item => {
                                                                            return item.salary !== 0 ? (item.salary * 100 / SalaryReducer.userMonth?.salary).toFixed(0) : 0
                                                                        }
                                                                    )
                                                                : 0
                                                        }/>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            minWidth: 35
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="h5"
                                                            sx={{
                                                                color: `${theme.colors.green}`
                                                            }}
                                                        >
                                                            {/*foiz*/}
                                                            {
                                                                SalaryCountReducer.salaryCount ?
                                                                    SalaryCountReducer.salaryCount.filter(val => val?.agreementName === 'HOUR')
                                                                        .map((item, index) => <h5 key={index}>
                                                                            <b style={{color: "#45ec0e"}}>{item.salary !== 0 ? (item.salary * 100 / SalaryReducer.userMonth?.salary).toFixed(0) : 0}%</b>
                                                                        </h5>)
                                                                    : ''
                                                            }
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </ListItem>

                                            <ListItem
                                                disableGutters
                                                sx={{
                                                    display: 'block'
                                                }}
                                            >
                                                <Box pb={0.5}>
                                                    <Typography
                                                        component="span"
                                                        variant="h3"
                                                        sx={{
                                                            pr: 1
                                                        }}
                                                    >

                                                        {
                                                            prizeReducer.bonus ?
                                                                prizeReducer.bonus.totalSum : 0
                                                        }

                                                    </Typography>
                                                    <Typography component="span" variant="subtitle2">
                                                        {t('tes.23')}
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            width: '100%',
                                                            mr: 1.5
                                                        }}
                                                    >
                                                        <LinearProgressPrimary variant="determinate" value={

                                                            prizeReducer.bonus ?
                                                                (prizeReducer.bonus.totalSum * 100 / SalaryReducer.userMonth?.salary).toFixed(0)
                                                                : 0
                                                        }/>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            minWidth: 35
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="h4"
                                                            sx={{
                                                                color: `${theme.colors.primary}`
                                                            }}
                                                        >
                                                            {/*foiz bonus*/}
                                                            {
                                                                prizeReducer.bonus ?
                                                                    <b>{(prizeReducer.bonus.totalSum * 100 / SalaryReducer.userMonth?.salary).toFixed(0)}%</b>
                                                                    : 0
                                                            }
                                                            {/*{*/}
                                                            {/*    SalaryCountReducer.salaryCount ?*/}
                                                            {/*        SalaryCountReducer.salaryCount.filter(val => val?.agreementName === 'KPI')*/}
                                                            {/*            .map((item, index) => <h5 key={index}>*/}
                                                            {/*                <b>{(item.salary * 100 / SalaryReducer.userMonth?.salary).toFixed(0)}%</b>*/}
                                                            {/*            </h5>)*/}
                                                            {/*        : ''*/}
                                                            {/*}*/}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </ListItem>
                                        </List>
                                    </Grid>
                                    <Grid item xs={12} md={7}>
                                        <CardWrapper
                                            sx={{
                                                background: 'rgba(201,20,20,0)',
                                            }}

                                        >
                                            <Box
                                                sx={{
                                                    background: 'rgba(201,20,20,0)',
                                                    height: 267
                                                }}
                                            >
                                                <Grid item xs={12} style={{background: "rgba(255,255,255,0.2)"}}>

                                                    <CardHeader

                                                        titleTypographyProps={{
                                                            component: 'h5',
                                                            variant: 'caption',
                                                            fontWeight: 'bold'
                                                        }}
                                                        action={
                                                            <IconButton size="small" color="secondary">
                                                                <MoreVertTwoToneIcon/>
                                                            </IconButton>
                                                        }
                                                        title={t('Bu oy maoshi')}
                                                    />
                                                    <CardContent
                                                        sx={{
                                                            pt: 0,
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <Text flex color="warning">
                                                            <FiberManualRecordTwoToneIcon/>
                                                        </Text>
                                                        <Typography style={{color: "#fff"}}
                                                                    sx={{
                                                                        px: 1
                                                                    }}
                                                                    variant="h1"
                                                        >

                                                            <div className={'d-flex '}>
                                                                {
                                                                    (Math.trunc(
                                                                            SalaryReducer.userMonth ?
                                                                                SalaryReducer.userMonth.salary * 100 : '0'
                                                                        ) / 100
                                                                    )

                                                                }
                                                            </div>
                                                        </Typography>
                                                        <Typography fontWeight="bold" variant="h4">
                                                            <Text color="success">+54</Text>
                                                        </Typography>
                                                    </CardContent>
                                                    <Chart
                                                        options={Box1Options}
                                                        series={Box1Data}
                                                        type="area"
                                                        height={160}
                                                    />
                                                </Grid>
                                            </Box>
                                        </CardWrapper>

                                        <div>
                                            <Box p={2}>
                                                <Grid container spacing={4}>


                                                    <Grid item xs={12} sm={6}
                                                          style={{height: 150}}
                                                    >

                                                        <Card variant="outlined"
                                                              sx={{
                                                                  background: 'rgba(255,255,255,0.22)',
                                                                  textAlign: 'center',


                                                              }}
                                                              style={{height: 150}}
                                                        >


                                                            <Box
                                                                sx={{


                                                                    mt: 0.3,
                                                                    mb: 1,
                                                                    mx: 'auto',
                                                                    maxWidth: '380px'
                                                                }}
                                                            >
                                                                <GaugeChart
                                                                    sx={{
                                                                        p: 2
                                                                    }}
                                                                    style={{width: '90%', margin: '0 auto'}}
                                                                    nrOfLevels={16}
                                                                    hideText
                                                                    colors={[
                                                                        "rgba(255,255,255,0.07)",
                                                                        theme.colors.warning.main,
                                                                        "#ff0000"
                                                                    ]}
                                                                    needleColor={theme.colors.alpha.white[100]}
                                                                    needleBaseColor={theme.colors.alpha.white[100]}
                                                                    arcWidth={0.45}
                                                                    arcPadding={0.01}
                                                                    cornerRadius={1}
                                                                    percent={0.01}
                                                                />

                                                                <Typography style={{color: "#fff"}} variant="subtitle3"
                                                                            noWrap>
                                                                    {t('tes.24')}
                                                                </Typography>
                                                                <Typography style={{color: "#fff"}} variant="h4">{
                                                                    XodimReducer.agreement ?
                                                                        XodimReducer.agreement.filter(val => val.salaryStatus === 'KPI').map((item, index) =>
                                                                            <b
                                                                                key={index}>
                                                                                {latestHour <= 0 ? latestHour : 0} {t('tes.25')}
                                                                            </b>)
                                                                        : ''
                                                                }
                                                                </Typography>


                                                            </Box>
                                                        </Card>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}
                                                    >

                                                        <Card variant="outlined"
                                                              sx={{
                                                                  background: 'rgba(239,250,238,0.2)',
                                                                  textAlign: 'center',


                                                              }}
                                                              style={{height: 150}}
                                                        >
                                                            <Box
                                                                sx={{


                                                                    mt: 0.3,
                                                                    mb: 1,
                                                                    mx: 'auto',
                                                                    maxWidth: '400px'
                                                                }}
                                                            >
                                                                <GaugeChart
                                                                    sx={{
                                                                        p: 2
                                                                    }}
                                                                    style={{width: '90%', margin: '0 auto'}}
                                                                    nrOfLevels={16}
                                                                    hideText
                                                                    colors={[
                                                                        "rgba(255,255,255,0.07)",
                                                                        "#2fc923"
                                                                    ]}
                                                                    needleColor={theme.colors.alpha.white[100]}
                                                                    needleBaseColor={theme.colors.alpha.white[100]}
                                                                    arcWidth={0.45}
                                                                    arcPadding={0.01}
                                                                    cornerRadius={1}
                                                                    percent={0.01}
                                                                />

                                                                <Typography style={{color: "#fff"}} variant="subtitle3"
                                                                            noWrap>
                                                                    {t('tes.26')}
                                                                </Typography>
                                                                <Typography style={{color: "#fff"}} variant="h4">{
                                                                    XodimReducer.agreement ?
                                                                        XodimReducer.agreement.filter(val => val.salaryStatus === 'KPI').map((item, index) =>
                                                                            <b
                                                                                key={index}>
                                                                                {latestHour > 0 ? latestHour : 0} {t('tes.25')}
                                                                            </b>)
                                                                        : ''
                                                                }
                                                                </Typography>


                                                            </Box>
                                                        </Card>


                                                    </Grid>
                                                </Grid>
                                            </Box>

                                        </div>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Divider/>
                            <Box textAlign="center" p={3}>
                                <Button variant="contained" endIcon={<ArrowForwardTwoToneIcon/>}>
                                    {t('tes.27')}
                                </Button>
                            </Box>
                        </Card>

                        <Card style={{marginTop: "30px", background: "#ffffff"}}
                              sx={{

                                  overflow: 'visible'
                              }}
                        >
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                p={2}
                            >
                                <Box>
                                    <Typography
                                        variant="caption"
                                        fontWeight="bold"
                                        sx={{
                                            fontSize: `${theme.typography.pxToRem(12)}`,
                                        }}
                                    >
                                        {t('tes.28')}
                                    </Typography>
                                    <Typography variant="h4">{t('tes.29')}</Typography>
                                </Box>
                                {/*<IconButton*/}
                                {/*    size="small"*/}
                                {/*    color="primary"*/}
                                {/*    sx={{*/}
                                {/*        alignSelf: 'center',*/}
                                {/*        backgroundColor: `${theme.colors.primary.lighter}`,*/}
                                {/*        transition: `${theme.transitions.create(['all'])}`,*/}

                                {/*        '&:hover': {*/}
                                {/*            backgroundColor: `${theme.colors.primary.main}`,*/}
                                {/*            color: `${theme.palette.getContrastText(*/}
                                {/*                theme.colors.primary.main*/}
                                {/*            )}`*/}
                                {/*        }*/}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    <MoreVertTwoToneIcon fontSize="small"/>*/}
                                {/*</IconButton>*/}
                            </Box>
                            <List disablePadding>
                                <Divider/>
                                <ListItemButtonWrapper

                                    sx={{
                                        display: {xs: 'block', sm: 'flex'},
                                        py: 2,
                                        px: 2.5,
                                        background: 'rgba(255,255,255,0.2)',
                                        margin: 1

                                    }}
                                >
                                    <ListItemAvatar
                                        sx={{
                                            minWidth: 'auto',
                                            mr: 2,
                                            mb: {xs: 2, sm: 0}
                                        }}
                                    >
                                        <Tooltip
                                            arrow
                                            placement="top"
                                            title={`${t('Task assigned to')} Gary Albright`}
                                        >
                                            <Avatar
                                                sx={{
                                                    fontSize: `${theme.typography.pxToRem(15)}`,
                                                    background: `${theme.colors.warning.main}`,
                                                    color: `${theme.palette.getContrastText(
                                                        theme.colors.warning.dark
                                                    )}`,
                                                    width: 56,
                                                    height: 56
                                                }}
                                            >
                                                {t('tes.30')}
                                            </Avatar>
                                        </Tooltip>
                                    </ListItemAvatar>
                                    <ListItemText
                                        disableTypography
                                        primary={
                                            <Typography color="text.primary" variant="h5">
                                                {t('tes.31')}
                                            </Typography>
                                        }
                                        secondary={
                                            <>
                                                <LinearProgressSuccess
                                                    sx={{
                                                        mt: 1,
                                                        mb: 0.5
                                                    }}
                                                    variant="determinate"
                                                    value={73}
                                                />
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: `${theme.typography.pxToRem(18)}`
                                                        }}
                                                        variant="h5"
                                                    >
                                                        <Text
                                                            color="success">{users.userInfo?.taskInfoGetDto?.taskAmount} ta</Text>
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: `${theme.typography.pxToRem(12)}`
                                                        }}
                                                        variant="subtitle2"
                                                    >
                                                        15<sup>th</sup> Sep
                                                    </Typography>
                                                </Box>
                                            </>
                                        }
                                    />
                                </ListItemButtonWrapper>
                                <ListItemButtonWrapper
                                    sx={{
                                        display: {xs: 'block', sm: 'flex'},
                                        py: 2,
                                        px: 2.5,
                                        background: 'rgba(255,255,255,0.2)',
                                        margin: 1
                                    }}
                                >
                                    <ListItemAvatar
                                        sx={{
                                            minWidth: 'auto',
                                            mr: 2,
                                            mb: {xs: 2, sm: 0}
                                        }}
                                    >
                                        <Tooltip
                                            arrow
                                            placement="top"
                                            title={`${t('Task assigned to')} Therry Hefaistos`}
                                        >
                                            <Avatar
                                                sx={{
                                                    fontSize: `${theme.typography.pxToRem(15)}`,
                                                    background: `${theme.colors.gradients.blue1}`,
                                                    color: `${theme.palette.getContrastText(
                                                        theme.colors.warning.dark
                                                    )}`,
                                                    width: 56,
                                                    height: 56
                                                }}
                                            >
                                                TH
                                            </Avatar>
                                        </Tooltip>
                                    </ListItemAvatar>
                                    <ListItemText
                                        disableTypography
                                        primary={
                                            <Typography color="text.primary" variant="h5">
                                                {t('tes.32')}
                                            </Typography>
                                        }
                                        secondary={
                                            <>
                                                <LinearProgressSuccess
                                                    sx={{
                                                        mt: 1,
                                                        mb: 0.5
                                                    }}
                                                    variant="determinate"
                                                    value={29}
                                                />
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: `${theme.typography.pxToRem(18)}`
                                                        }}
                                                        variant="subtitle2"
                                                    >
                                                        <Text
                                                            color="info">{users.userInfo?.taskInfoGetDto?.taskAmount} ta</Text>
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: `${theme.typography.pxToRem(12)}`
                                                        }}
                                                        variant="subtitle2"
                                                    >
                                                        12<sup>th</sup> Aug
                                                    </Typography>
                                                </Box>
                                            </>
                                        }
                                    />
                                </ListItemButtonWrapper>
                                <ListItemButtonWrapper
                                    sx={{
                                        display: {xs: 'block', sm: 'flex'},
                                        py: 2,
                                        px: 2.5,
                                        background: 'rgba(255,255,255,0.2)',
                                        margin: 1
                                    }}
                                >
                                    <ListItemAvatar
                                        sx={{
                                            minWidth: 'auto',
                                            mr: 2,
                                            mb: {xs: 2, sm: 0}
                                        }}
                                    >
                                        <Tooltip
                                            arrow
                                            placement="top"
                                            title={`${t('Task assigned to')} EA Department`}
                                        >
                                            <Avatar
                                                sx={{
                                                    fontSize: `${theme.typography.pxToRem(15)}`,
                                                    background: `${theme.colors.error.main}`,
                                                    color: `${theme.palette.getContrastText(
                                                        theme.colors.error.main
                                                    )}`,
                                                    width: 56,
                                                    height: 56
                                                }}
                                            >
                                                EA
                                            </Avatar>
                                        </Tooltip>
                                    </ListItemAvatar>
                                    <ListItemText
                                        disableTypography
                                        primary={
                                            <Typography color="text.primary" variant="h5">
                                                {t('tes.33')}
                                            </Typography>
                                        }
                                        secondary={
                                            <>
                                                <LinearProgressError
                                                    sx={{
                                                        mt: 1,
                                                        mb: 0.5
                                                    }}
                                                    variant="determinate"
                                                    value={87}
                                                />
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: `${theme.typography.pxToRem(18)}`
                                                        }}
                                                        variant="h4"
                                                    >
                                                        <Text
                                                            color="warning">{users.userInfo?.taskInfoGetDto?.doneTaskAmount} ta</Text>
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: `${theme.typography.pxToRem(12)}`
                                                        }}
                                                        variant="subtitle2"
                                                    >
                                                        22<sup>th</sup> Sep
                                                    </Typography>
                                                </Box>
                                            </>
                                        }
                                    />
                                </ListItemButtonWrapper>
                                <ListItemButtonWrapper
                                    sx={{
                                        display: {xs: 'block', sm: 'flex'},
                                        py: 2,
                                        px: 2.5,
                                        background: 'rgba(255,255,255,0.2)',
                                        margin: 1
                                    }}
                                >
                                    <ListItemAvatar
                                        sx={{
                                            minWidth: 'auto',
                                            mr: 2,
                                            mb: {xs: 2, sm: 0}
                                        }}
                                    >
                                        <Tooltip
                                            arrow
                                            placement="top"
                                            title={`${t('Task assigned to')} Herocle Patrocle`}
                                        >
                                            <Avatar
                                                sx={{
                                                    fontSize: `${theme.typography.pxToRem(15)}`,
                                                    background: `${theme.colors.gradients.black2}`,
                                                    color: `${theme.colors.alpha.trueWhite[100]}`,
                                                    width: 56,
                                                    height: 56
                                                }}
                                            >
                                                HS
                                            </Avatar>
                                        </Tooltip>
                                    </ListItemAvatar>
                                    <ListItemText
                                        disableTypography
                                        primary={
                                            <Typography color="text.primary" variant="h5">
                                                {t('tes.34')}
                                            </Typography>
                                        }
                                        secondary={
                                            <>
                                                <LinearProgressWarning
                                                    sx={{
                                                        mt: 1,
                                                        mb: 0.5
                                                    }}
                                                    variant="determinate"
                                                    value={54}
                                                />
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: `${theme.typography.pxToRem(18)}`
                                                        }}
                                                        variant="h4"
                                                    >
                                                        <Text
                                                            color="error">{users.userInfo?.taskInfoGetDto?.notDoneDeadlineAmount} ta</Text>
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: `${theme.typography.pxToRem(12)}`
                                                        }}
                                                        variant="subtitle2"
                                                    >
                                                        16<sup>th</sup> Oct
                                                    </Typography>
                                                </Box>
                                            </>
                                        }
                                    />
                                </ListItemButtonWrapper>
                            </List>
                        </Card>
                    </Grid>

                </Grid>

                <Card style={{margin: "30px"}}

                      sx={{
                          p: 5,
                          background: `#ffffff`
                      }}
                >
                    <Grid container spacing={4}>

                        <Grid item xs={12} md={9}>
                            <Typography variant="h4" style={{paddingBottom: "34px"}}>
                                {t('tes.35')}
                            </Typography>

                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography variant="h4"
                                        style={{paddingBottom: "34px", color: "#05e110", textAlign: "center"}}>
                                {t('tes.36')}
                            </Typography>

                        </Grid>

                    </Grid>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={3}>
                            <CardBorderBottom
                                sx={{
                                    background: '#ffffff',
                                    boxShadow: `${theme.colors.shadows.success}`,
                                    borderBottomColor: `${theme.colors.success.main}`
                                }}
                            >
                                <CardActionAreaWrapper>
                                    <Typography component="span" variant="h5">
                                        {t('tes.37')}
                                    </Typography>
                                    <Box>


                                        <Text color="success">
                                            <PersonTwoToneIcon fontSize="large"/>
                                        </Text>
                                        <Box mt={0.5}>

                                            <Typography component="span" variant="h4">
                                                {
                                                    LessonUserReducer.lessonView ?
                                                        LessonUserReducer.lessonView.map(item => item?.view + '/' + item.lessonView)
                                                        : <h5>not found</h5>
                                                }
                                            </Typography>{' '}

                                        </Box>
                                    </Box>
                                    <Typography
                                        component="span"
                                        color="text.secondary"
                                        sx={{
                                            opacity: 0.5,
                                            display: 'flex'
                                        }}
                                    >
                                        <ChevronRightTwoToneIcon/>
                                    </Typography>
                                </CardActionAreaWrapper>
                            </CardBorderBottom>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <CardBorderBottom
                                sx={{
                                    boxShadow: `${theme.colors.shadows.warning}`,
                                    borderBottomColor: `${theme.colors.warning.main}`,
                                    background: '#ffffff'
                                }}
                            >
                                <CardActionAreaWrapper>
                                    <Typography component="span" variant="h5">
                                        {t('tes.38')}
                                    </Typography>
                                    <Box>
                                        <Text color="warning">
                                            <MonetizationOnTwoToneIcon fontSize="large"/>
                                        </Text>
                                        <Box mt={0.5}>
                                            <Typography component="span" variant="h4">
                                                {
                                                    LessonUserReducer.lessonView ?
                                                        LessonUserReducer.lessonView.map(item => item?.solveTest ?
                                                            <CheckIcon/> : <CloseIcon/>)
                                                        : <h5>not found</h5>
                                                }
                                            </Typography>{' '}

                                        </Box>
                                    </Box>
                                    <Typography
                                        component="span"
                                        color="text.secondary"
                                        sx={{
                                            opacity: 0.5,
                                            display: 'flex'
                                        }}
                                    >
                                        <ChevronRightTwoToneIcon/>
                                    </Typography>
                                </CardActionAreaWrapper>
                            </CardBorderBottom>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <CardBorderBottom
                                sx={{
                                    boxShadow: `${theme.colors.shadows.primary}`,
                                    borderBottomColor: `${theme.colors.primary.main}`,
                                    background: '#ffffff'
                                }}
                            >
                                <CardActionAreaWrapper>
                                    <Typography component="span" variant="h5">
                                        {t('tes.39')}
                                    </Typography>
                                    <Box>
                                        <Text color="primary">
                                            <AccountBalanceWalletTwoToneIcon fontSize="large"/>
                                        </Text>
                                        <Box mt={0.5}>
                                            <Typography component="span" variant="h4">
                                                {
                                                    LessonUserReducer.lessonView ?
                                                        LessonUserReducer.lessonView.map(item => item?.testResult)
                                                        : <h5>not found</h5>
                                                }
                                            </Typography>{' '}

                                        </Box>
                                    </Box>
                                    <Typography
                                        component="span"
                                        color="text.secondary"
                                        sx={{
                                            opacity: 0.5,
                                            display: 'flex'
                                        }}
                                    >
                                        <ChevronRightTwoToneIcon/>
                                    </Typography>
                                </CardActionAreaWrapper>
                            </CardBorderBottom>
                        </Grid>
                        <Grid item xs={12} md={3}>

                            <CardActionAreaWrapper>
                                <Box mt={0.8}>


                                </Box>

                                <Box>

                                    <Box display="inline-flex" position="relative">
                                        <Box
                                            sx={{
                                                animationDuration: '550ms',
                                                position: 'absolute',
                                                left: 0,
                                                top: 0,
                                                bottom: 0,
                                                right: 0,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: `${theme.colors.success.main}`
                                                }}
                                                variant="h5"
                                            >
                                                76%
                                            </Typography>
                                        </Box>
                                        <CircularProgress
                                            variant="determinate"
                                            sx={{
                                                color: theme.colors.success.lighter
                                            }}
                                            size={90}
                                            thickness={3}
                                            value={100}
                                        />
                                        <CircularProgress
                                            size={90}
                                            sx={{
                                                animationDuration: '750ms',
                                                position: 'absolute',
                                                left: 0,
                                                color: theme.colors.success.main,
                                                top: 0,
                                                [`& .${circularProgressClasses.circle}`]: {
                                                    strokeLinecap: 'round'
                                                }
                                            }}
                                            thickness={3}
                                            variant="determinate"
                                            value={70}
                                        />
                                    </Box>

                                </Box>
                                <Typography
                                    component="span"
                                    color="text.secondary"
                                    sx={{
                                        opacity: 0.5,
                                        display: 'flex'
                                    }}
                                >
                                </Typography>
                            </CardActionAreaWrapper>
                        </Grid>
                    </Grid>
                </Card>
                {/*<Grid style={{paddingTop:"20px"}} item xs={12} md={12}>*/}
                {/*    <Card*/}
                {/*        sx={{*/}
                {/*            background: '#ffecd3',*/}
                {/*            overflow: 'visible',*/}
                {/*            display: 'flex',*/}
                {/*            alignItems: 'center',*/}
                {/*            justifyContent: 'space-between',*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        <h2>Shu oy davomida kechikgan soati</h2>*/}
                {/*        <h2>{latestHour} soat</h2>*/}
                {/*    </Card>*/}
                {/*</Grid>*/}
                {/*{*/}
                {/*    latestHourUsers ?*/}
                {/*        <div className={'border border-2 mt-2'}>*/}
                {/*            <div><h4 className={'text-center'}>Oy davomida kechikgan hodimlar soatlari</h4></div>*/}
                {/*            <table className={'table table-striped'}>*/}
                {/*                <thead>*/}
                {/*                <tr>*/}
                {/*                    <th>#</th>*/}
                {/*                    <th>FIO</th>*/}
                {/*                    <th>Kechikgan soati</th>*/}
                {/*                </tr>*/}
                {/*                </thead>*/}
                {/*                <tbody>*/}
                {/*                {*/}
                {/*                    latestHourUsers?.map((item, index) => <tr key={index}>*/}
                {/*                        <td>{index + 1}</td>*/}
                {/*                        <td>{item.lastName + " " + item.firstName}</td>*/}
                {/*                        <td>{item?.hour}</td>*/}
                {/*                    </tr>)*/}
                {/*                }*/}
                {/*                </tbody>*/}
                {/*            </table>*/}
                {/*        </div>*/}
                {/*        : <div className={'mt-3'}><h4 className={'text-center'}>Malumot topi;madi</h4></div>*/}
                {/*}*/}
            </div>

            {/*<label htmlFor="">Xodim tanlash</label>*/}
            {/*<select value={userFirstName} onChange={userChange} className={'form-control mb-3'}>*/}
            {/*    <option value="tanlash">TANLASH</option>*/}
            {/*    {XodimReducer.xodimlar?*/}
            {/*    XodimReducer.xodimlar.map((item,index)=> <option value={item.id}>{item.firstName}</option>):<div><h4 className={'text-center'}>USER NOT FOUND</h4></div>*/}
            {/*    }*/}
            {/*</select>*/}


            {/*<div className="colorback col-md-6 mb-3 offset-3 border border-2 text-center p-4 mt-2">*/}
            {/*    <Link to={`/headerthird/profil`}>*/}
            {/*        <button className={'btn btn-primary'}>PROFILNI KO'RISH</button>*/}
            {/*    </Link>*/}
            {/*    <Link to={'/headerthird/profil/edit'}>*/}
            {/*        <button className={'btn ms-2 btn-outline-primary'}>PROFILNI TAHRIRLASH</button>*/}
            {/*    </Link>*/}
            {/*</div>*/}

            {/*{*/}
            {/*    users.userInfo ?*/}
            {/*        <div className={'border p-2 border-2'}>*/}
            {/*            <div className={'d-flex justify-content-between'}>*/}
            {/*                <label htmlFor="">Ismi</label>*/}
            {/*                {users.userInfo?.fio}*/}
            {/*            </div>*/}
            {/*            <div className={'d-flex justify-content-between'}>*/}
            {/*                <label htmlFor="">Lavozimi</label>*/}
            {/*                {users.userInfo.role}*/}
            {/*            </div>*/}
            {/*            <div className={'d-flex justify-content-between'}>*/}
            {/*                <label htmlFor="">bitirgan Vazifa</label>*/}
            {/*                {users.userInfo?.taskInfoGetDto?.doneTaskAmount}*/}
            {/*            </div>*/}
            {/*            <div className={'d-flex justify-content-between'}>*/}
            {/*                <label htmlFor="">Vaqtida bitirmagan Vazifa</label>*/}
            {/*                {users.userInfo?.taskInfoGetDto?.notDoneDeadlineAmount}*/}
            {/*            </div>*/}
            {/*            <div className={'d-flex justify-content-between'}>*/}
            {/*                <label htmlFor="">Vazifalar soni</label>*/}
            {/*                {users.userInfo?.taskInfoGetDto?.taskAmount}*/}
            {/*            </div>*/}

            {/*            <div className={'d-flex justify-content-between'}>*/}
            {/*                <label htmlFor="">Maosh boshlash sana</label>*/}
            {/*                {*/}
            {/*                    XodimReducer.agreement ?*/}
            {/*                        formatDateMinus(XodimReducer.agreement.startDate) : '13'*/}
            {/*                }*/}
            {/*            </div>*/}
            {/*            <div className={'d-flex justify-content-between'}>*/}
            {/*                <label htmlFor="">Maosh berish sana</label>*/}
            {/*                {*/}
            {/*                    XodimReducer.agreement ?*/}
            {/*                        formatDateMinus(XodimReducer.agreement.endDate) : '13'*/}
            {/*                }*/}
            {/*            </div>*/}

            {/*            <div className={'d-flex justify-content-between'}>*/}
            {/*                <label htmlFor="">MAOSH</label>*/}

            {/*                {*/}
            {/*                    XodimReducer.agreement ?*/}
            {/*                        XodimReducer.agreement.filter(val => val.active === true).map((item, index) => <ul*/}
            {/*                            key={index}>*/}
            {/*                            <li> {item?.salaryStatus}</li>*/}
            {/*                            <li>{*/}
            {/*                                (Math.trunc(*/}
            {/*                                    item?.salaryStatus === 'KPI' ?*/}
            {/*                                        item.price + ' %' : item.price * 100*/}
            {/*                                ) / 100)*/}


            {/*                            }</li>*/}
            {/*                        </ul>)*/}
            {/*                        : '13'*/}
            {/*                }*/}
            {/*            </div>*/}

            {/*            <div className={'d-flex justify-content-between'}>*/}
            {/*                <label htmlFor="">Bonus</label>*/}
            {/*                {*/}
            {/*                    users.userInfo?.bonusGetMetDtoList ?*/}
            {/*                        users.userInfo?.bonusGetMetDtoList.map((item, index) => <ul key={index}>*/}
            {/*                            <li>hi</li>*/}
            {/*                        </ul>) : <h4>not found</h4>*/}
            {/*                }*/}
            {/*            </div>*/}

            {/*            <div className={'d-flex justify-content-between'}>*/}
            {/*                <label htmlFor="">LOYIHA BAJARILGAN</label>*/}
            {/*                {users.userInfo?.projectInfoDto.completed}*/}
            {/*            </div>*/}
            {/*            <div className={'d-flex justify-content-between'}>*/}
            {/*                <label htmlFor="">LOYIHA MUDDATI UTGAN</label>*/}
            {/*                {users.userInfo?.projectInfoDto.expired}*/}
            {/*            </div>*/}
            {/*            <div className={'d-flex justify-content-between'}>*/}
            {/*                <label htmlFor="">LOYIHA JARAYONDA</label>*/}
            {/*                {users.userInfo?.projectInfoDto.process}*/}
            {/*            </div>*/}
            {/*            <div className={'d-flex justify-content-between'}>*/}
            {/*                <label htmlFor="">LOYIHA JAMI</label>*/}
            {/*                {users.userInfo?.projectInfoDto.total}*/}
            {/*            </div>*/}


            {/*            <div className={'d-flex justify-content-between'}>*/}

            {/*                <label htmlFor="">Savdo</label>*/}
            {/*                {*/}
            {/*                    users.userInfo?.tradeResultDto ?*/}
            {/*                        users.userInfo?.tradeResultDto?.totalTrade*/}
            {/*                        : <div><h4 className={'text-center'}>not found</h4></div>*/}
            {/*                }*/}

            {/*            </div>*/}


            {/*            <div className={'d-flex justify-content-between'}>*/}
            {/*                <label htmlFor="">Savdo summa</label>*/}
            {/*                {*/}
            {/*                    users.userInfo?.tradeResultDto ?*/}
            {/*                        users.userInfo?.tradeResultDto?.totalTradeSumma*/}
            {/*                        : <h4>not found</h4>*/}
            {/*                }*/}
            {/*            </div>*/}

            {/*            <div className={'d-flex justify-content-between'}>*/}
            {/*                <label htmlFor="">O'rtacha</label>*/}
            {/*                {*/}
            {/*                    users.userInfo?.tradeResultDto ?*/}
            {/*                        users.userInfo?.tradeResultDto?.avarage*/}
            {/*                        : <h4>not found</h4>*/}
            {/*                }*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        : <div><h4 className={'text-center'}>NOT FOUND</h4></div>*/}
            {/*}*/}
            {/*{*/}
            {/*    LessonUserReducer.lessonView ?*/}
            {/*        <div className={'border border-2 mt-2'}>*/}
            {/*            <div><h4 className={'text-center'}>DARSLIKLAR</h4></div>*/}
            {/*            <table className={'table table-striped'}>*/}
            {/*                <thead>*/}
            {/*                <tr>*/}
            {/*                    <th>#</th>*/}
            {/*                    <th>Nomi</th>*/}
            {/*                    <th>Dars nomi</th>*/}
            {/*                    <th>Dars ko'rilgan</th>*/}
            {/*                    <th>Test ishlaganmi</th>*/}
            {/*                    <th>Test natija</th>*/}
            {/*                    <th>Video Darslik</th>*/}
            {/*                </tr>*/}
            {/*                </thead>*/}
            {/*                <tbody>*/}
            {/*                {*/}
            {/*                    LessonUserReducer.lessonView.map((item, index) => <tr key={index}>*/}
            {/*                        <td>{index + 1}</td>*/}
            {/*                        <td>{item.firstName}</td>*/}
            {/*                        <td>{item?.lessonName}</td>*/}
            {/*                        <td>{item?.view + '/' + item.lessonView}</td>*/}
            {/*                        <td>{item?.solveTest ? '+' : '-'}</td>*/}
            {/*                        <td>{item.testResult}</td>*/}
            {/*                        <td>{item.finish ? '+' : '-'}</td>*/}
            {/*                    </tr>)*/}
            {/*                }*/}
            {/*                </tbody>*/}
            {/*            </table>*/}
            {/*        </div>*/}
            {/*        : <div className={'mt-3'}><h4 className={'text-center'}>LESSON NOT FOUND</h4></div>*/}
            {/*}*/}
            <UserWorkTable userId={match.params.id}/>
        </div>
    );
}

export default connect((users, XodimReducer, SalaryReducer, SalaryCountReducer, prizeReducer, LessonUserReducer), {
    getAgreement,
    getUserMonths,
    userInfo,
    getXodim,
    getViewByUserId,
    viewPrize,
    getSalaryCount,
    getBonusSumm,
    userDoHistory
})(RecentActivity);
