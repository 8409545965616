import {connect} from "react-redux";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import {Checkbox, DatePicker} from "antd";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {TextField} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import CommonTable from "../../../../Components/CommonTable";
import ExcelButtons from "../../../../Components/ExcelButtons";
import users from '../../../../../reducer/users'
import XodimReducer, {getXodimByBranch} from "../../Hodimlar/reducer/XodimReducer";
import MijozlarGuruhReducer, {getMijozLarGuruh} from "../../Hamkorlar/reducer/MijozlarGuruhReducer";
import MijozGuruxReducer, {
  getKonstruktorReports,
  getMijozByGroup,
  getMijozGurux,
  getMijozGuruxByBranch,
} from "../../Hamkorlar/reducer/MijozGuruxReducer";
import formatDate, {setLocalStorage, tablePageSize} from "../../../../../util";

import './konstruktorXisobot.css'

const {RangePicker} = DatePicker

function KonstruktorXisobot({
                             users,
                              XodimReducer,
                              getXodimByBranch,
                              MijozlarGuruhReducer,
                              getMijozLarGuruh,
                              MijozGuruxReducer,
                              getKonstruktorReports,
                              getMijozGuruxByBranch,
                              getMijozByGroup,
                              getMijozGurux
                           }) {

  const {t} = useTranslation()
  const [pageData, setPageData] = useState({
    page: 0,
    pageSize: tablePageSize ? tablePageSize : 10,
    branch: "ALL",
    dates: null,
    dateValue: null,
    customerGroupId: "ALL",
    customerId: "ALL",
    sellerId: "ALL",
    maxSum: null,
    minSum: null,
    loading: false,
  });
  const [openCheckList, setOpenCheckList] = useState(false)
  const columns = [
    {
      key: "id",
      title: 'Id',
      dataIndex: 'index',
      width: '3%',
      fixed: 'left',
    },
    {
      title: t('Davomat.hodim'),
      dataIndex: 'sellerName',
      key: 'sellerName',
    },
    {
      title: t('PurchaseList.6'),
      dataIndex: 'customerName',
      key: 'customerName',
    },
    {
      title: t('CustomAll.7'),
      dataIndex: 'customerGroupName',
      key: 'customerGroupName',
    },
    {
      title: t('block.um'),
      dataIndex: 'totalSumma',
      key: 'totalSumma',
    },
    {
      title: t('ExpenseReport.12'),
      dataIndex: 'productTypeCount',
      key: 'productTypeCount',
    },
    {
      title: t('ExpenseReport.11'),
      dataIndex: 'productCount',
      key: 'productCount',
    },
    {
      title: t("Mijoz qo'shilgan vaqt"),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => dayjs(date).format("DD-MM-YYYY")
    },
  ];
  const defaultCheckedList = columns.map((item) => item.key);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const options = columns.map(({ key, title }) => ({
    label: title,
    value: key,
  }));
  const newColumns = columns.map((item) => ({
    ...item,
    hidden: !checkedList.includes(item.key),
  }));

  useEffect(()=>{
    setPageData( (prev) => ({...prev, loading: false}))
  },[MijozGuruxReducer.getBoolean])

  useEffect(()=>{
    setPageData( (prev) => ({...prev, loading: true}))
    getMijozLarGuruh(users.businessId)
    getXodimByBranch(pageData?.branch === "ALL" ? users?.branchId : pageData?.branch)
    getMijozGuruxByBranch(pageData?.branch === "ALL" ? users?.branchId : pageData?.branch)
  },[])

  useEffect(() => {
    pageData?.branch === "ALL" ? getKonstruktorReports({
      page: pageData?.page,
      size: pageData?.pageSize,
      businessId: users?.businessId,
      customerGroupId: pageData?.customerGroupId === "ALL" ? null : pageData?.customerGroupId,
      customerId: pageData?.customerId === "ALL" ? null : pageData?.customerId,
      sellerId: pageData?.sellerId === "ALL" ? null : pageData?.sellerId,
      startDate:  pageData.dates ? pageData.dates[0] : null,
      endDate: pageData.dates ? pageData.dates[1] : null,
      maxSum: pageData?.maxSum,
      minSum: pageData?.minSum,
    }) : getKonstruktorReports({
      page: pageData?.page,
      size: pageData?.pageSize,
      branchId: pageData?.branch,
      businessId: users?.businessId,
      customerGroupId: pageData?.customerGroupId === "ALL" ? null : pageData?.customerGroupId,
      customerId: pageData?.customerId === "ALL" ? null : pageData?.customerId,
      sellerId: pageData?.sellerId === "ALL" ? null : pageData?.sellerId,
      startDate:  pageData.dates ? pageData.dates[0] : null,
      endDate: pageData.dates ? pageData.dates[1] : null,
      maxSum: pageData?.maxSum,
      minSum: pageData?.minSum,
    })
  }, [pageData.branch, pageData.dateValue, pageData.sellerId, pageData.customerId, pageData.customerGroupId, pageData.maxSum, pageData.minSum])

  const newChange = (pageNumber, page) => {
    setPageData((prev) => ({ ...prev, pageSize: page, page: pageNumber - 1 }));
    setLocalStorage("pageSize", page)
  };

  function selectBranch(e) {
    const branchId = e.target.value
    setPageData((prev) => ({...prev,branch: branchId, page: 0, loading: true, sellerId: "ALL", customerId: "ALL", customerGroupId: "ALL", maxSum: null, minSum: null, dateValue: null, dates: null}))
    getXodimByBranch(branchId === "ALL" ? users?.branchId : branchId)
    getMijozGuruxByBranch(branchId === "ALL" ? users?.branchId : branchId)
  }

  return (
    <div className="col-md-12 mb-4">
      {
        users.viewOutlayAdmin || users.viewOutlay ?
          <div className="rowStyleKX">
            <div className="row align-items-center pt-4">
              <div className="col-lg-4 col-md-6 pb-3 pb-md-4">
                <FormControl sx={{minWidth: '100%'}}>
                  <InputLabel className="selectLabelXarajat" id="demo-select-small-label">{t('cost.baza')}</InputLabel>
                  <Select
                    className="selectXarajat"
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={pageData.branch}
                    label={t('cost.baza')}
                    IconComponent={KeyboardArrowDownIcon}
                    onChange={selectBranch}
                  >
                    <MenuItem value="ALL">{t('Hamkorlar.b')}</MenuItem>
                    {
                      users.branches?.map(item =>
                        <MenuItem value={item.id}>{item.name}</MenuItem>
                      )
                    }
                  </Select>
                </FormControl>
              </div>
              <div className="col-lg-4 col-md-6 pb-3 pb-md-4">
                <FormControl sx={{minWidth: '100%'}}>
                  <InputLabel className="selectLabelXarajat"
                              id="demo-select-small-label">{t('Davomat.hodim')}</InputLabel>
                  <Select
                    className="selectXarajat"
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={pageData.sellerId}
                    label={t('Davomat.hodim')}
                    IconComponent={KeyboardArrowDownIcon}
                    onChange={(e) =>
                      setPageData((prev) => ({...prev, sellerId: e.target.value, page: 0, loading: true}))
                    }
                  >
                    <MenuItem value="ALL">{t('Hamkorlar.b')}</MenuItem>
                    {
                      XodimReducer.xodimlar?.map(item =>
                        <MenuItem value={item.id}>{item.firstName} {item.lastName}</MenuItem>
                      )
                    }
                  </Select>
                </FormControl>
              </div>
              <div className="col-lg-4 col-md-6 pb-3 pb-md-4">
                <FormControl sx={{minWidth: '100%'}}>
                  <InputLabel className="selectLabelXarajat"
                              id="demo-select-small-label">{t('CustomGroup.1')}</InputLabel>
                  <Select
                    className="selectXarajat"
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={pageData.customerGroupId}
                    label={t('CustomGroup.1')}
                    IconComponent={KeyboardArrowDownIcon}
                    onChange={(e) => {
                      setPageData((prev) => ({
                        ...prev,
                        customerGroupId: e.target.value,
                        customerId: "ALL",
                        page: 0,
                        loading: true
                      }))
                      e.target.value !== "ALL" ? getMijozByGroup(e.target.value) :
                        getMijozGuruxByBranch(pageData?.branch === "ALL" ? users?.branchId : pageData?.branch)
                    }}
                  >
                    <MenuItem value="ALL">{t('Hamkorlar.b')}</MenuItem>
                    {
                      MijozlarGuruhReducer.mijozGuruh?.map(item =>
                        <MenuItem value={item.id}>{item.name}</MenuItem>
                      )
                    }
                  </Select>
                </FormControl>
              </div>
              <div className="col-lg-4 col-md-6 pb-3 pb-md-4">
                <FormControl sx={{minWidth: '100%'}}>
                  <InputLabel className="selectLabelXarajat"
                              id="demo-select-small-label">{t('Roles.40')}</InputLabel>
                  <Select
                    className="selectXarajat"
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={pageData.customerId}
                    label={t('Roles.40')}
                    IconComponent={KeyboardArrowDownIcon}
                    onChange={(e) =>
                      setPageData((prev) => ({...prev, customerId: e.target.value, page: 0, loading: true}))
                    }
                  >
                    <MenuItem value="ALL">{t('Hamkorlar.b')}</MenuItem>
                    {
                      MijozGuruxReducer.mijozgurux?.map(item =>
                        <MenuItem value={item.id}>{item.name}</MenuItem>
                      )
                    }
                  </Select>
                </FormControl>
              </div>
              <div className="col-lg-4 col-md-6 pb-3 pb-md-4">
                <FormControl sx={{minWidth: '100%'}}>
                  <TextField onChange={(e) =>
                    setPageData((prev) => ({...prev, minSum: e.target.value === "" ? null : e.target.value, page: 0, loading: true}))
                  }  size="small" value={pageData.minSum} label="Minimal summa" variant="outlined"/>
                </FormControl>
              </div>
              <div className="col-lg-4 col-md-6 pb-3 pb-md-4">
                <FormControl sx={{minWidth: '100%'}}>
                  <TextField
                    onChange={(e) =>
                      setPageData((prev) => ({...prev, maxSum: e.target.value === "" ? null : e.target.value, page: 0, loading: true}))
                    }
                    size="small"
                    value={pageData.maxSum}
                    label="Maksimal summa"
                    variant="outlined"
                  />
                </FormControl>
              </div>
              <div className="col-lg-4 col-md-6 pb-3 pb-md-4">
                <RangePicker
                  className="dateXarajat"
                  placeholder={[t('baza.bs'), t('Salary.tsa')]}
                  value={pageData.dateValue}
                  onChange={(values) => {
                    let a = []
                    if (values !== null) {
                      values.map(item => a.push(dayjs(item).format("YYYY-MM-DD HH:mm:ss")))
                      setPageData((prev) => ({...prev, dates: a, dateValue: values, page: 0}))
                    } else {
                      setPageData((prev) => ({...prev, dates: null, dateValue: null, page: 0}))
                    }
                  }
                  }/>
              </div>
            </div>
          </div> : ''
      }
      <div className="rowStyleKX2">
        <div>
          <div className="qoshish mb-3 gap-3">
            <ExcelButtons setOpenCheckList={setOpenCheckList}/>
          </div>
          <div className={`${openCheckList ? "animatsiyaBlock mb-2" : "hiddenBlock"}`}>
            <Checkbox.Group
              style={{rowGap: 8}}
              value={checkedList}
              options={options}
              onChange={(value) => {
                setCheckedList(value);
              }}
            />
          </div>
          <CommonTable
            loading={pageData.loading}
            scroll={{x: 1200}}
            total={MijozGuruxReducer.konstruktorReports?.totalItem}
            handlePageChange={newChange}
            pagination={true}
            page={pageData.page}
            size={pageData.pageSize}
            data={MijozGuruxReducer.konstruktorReports?.data}
            columns={newColumns}
          />
        </div>
      </div>
    </div>
  )
}

export default connect((users, XodimReducer, MijozlarGuruhReducer, MijozGuruxReducer), {
  getXodimByBranch,
  getMijozLarGuruh,
  getMijozGuruxByBranch,
  getMijozGurux,
  getMijozByGroup,
  getKonstruktorReports
})(KonstruktorXisobot)
