     import {useState, forwardRef, useEffect} from 'react';
import {
    Avatar,
    Box,
    Card,
    Grid,
    Slide,
    Divider,
    Tooltip,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableContainer,
    TableRow,
    Button,
    Typography,
    Dialog,
    styled,
} from '@mui/material';
import {useForm} from "react-hook-form";
import {useTranslation} from 'react-i18next';
import {connect} from "react-redux";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    FormGroup,
    Label,
    Button as ButtonBootstarp,
} from "reactstrap";
import Loading from "../../../../Loading";
import ModalLoading from "../../../../ModalLoading";
import users from "../../../../../reducer/users";
import LavozimReducer, {getLavozim} from "../../Hodimlar/reducer/LavozimReducer";
import DarsliklarReducer, {
    deleteDarslik,
    editDarslik,
    getBYRoleDarslik,
    getDarslik,
    saveDarslik
} from "../reducer/DarsliklarReducer";
import photoreducer, {savephoto} from "../../../../../reducer/photoreducer";
import CloseIcon from '@mui/icons-material/Close';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import DownloadIcon from '@mui/icons-material/Download';
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import EditIcon from "@mui/icons-material/Edit";
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import {BaseUrl} from "../../../../../middleware";
import Select from "react-select";


const DialogWrapper = styled(Dialog)(
    () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AvatarError = styled(Avatar)(
    ({theme}) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const ButtonError = styled(Button)(
    ({theme}) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const DarsliklarRuyxati = ({
                     users, LavozimReducer, getLavozim, DarsliklarReducer, getDarslik, saveDarslik,
                               editDarslik, deleteDarslik, savephoto, photoreducer,getBYRoleDarslik
                 }) => {
    const {t} = useTranslation()
    const [openLesson, setOpenLesson] = useState({isOpen:false, open: null,id: null})
    const [saveModal, setSaveModal] = useState(false)
    const [loading, setLoading] = useState(true)
    const [openConfirmDelete, setOpenConfirmDelete] = useState({isOpen:false,id: null});
    const [selectValue,setSelectValue] = useState(null)
    const {register, setValue, handleSubmit, reset, formState: {errors}} = useForm()

    const tanla = LavozimReducer?.lavozimlar?.map((item) => {
        return ({value: item.id, label: item.name})
    })
    const options = tanla? [{value: 'all', label: 'All'}, ...tanla] : [{value: 'all', label: 'All'}]

    useEffect(() => {
        getLavozim(users.businessId)
        getDarslik(users.businessId)
        setSelectValue(options[0])
        setLoading(true)
    }, [])

    function changeSelect(data) {
        if(data.value === 'all'){
            getDarslik(users.businessId)
            setSelectValue(data)
        }else {
            setSelectValue(data)
            getBYRoleDarslik(data.value)
        }
    }

    function lessonAddToggle() {
        openLesson.isOpen ? setOpenLesson({isOpen: false, id:null, open: null}) : setOpenLesson({isOpen: true, id:null, open: 'create'})
        openLesson.isOpen && reset()
    }

    openLesson.id && openLesson.isOpen && function (){
        const element = DarsliklarReducer.darsliklar.find((item) => item.id === openLesson.id )
        if(element){
            setValue('name', element.name)
            setValue('description', element.description)
            setValue('link', element.link)
            setValue('view', element.view)
            setValue('roleId', element.roleId)
        }
    }()
    const handleConfirmDelete = (id) => {
        setOpenConfirmDelete({isOpen: true, id: id});
    };

    const closeConfirmDelete = () => {
        setOpenConfirmDelete({isOpen:false, id: null});
    };

    const handleDeleteCompleted = () => {
        setSaveModal(true)
        openConfirmDelete.id && deleteDarslik(openConfirmDelete.id);
        setOpenConfirmDelete({isOpen: false, id: null});
        setTimeout(() => {
            getDarslik(users.businessId)
        },1000)
        setSaveModal(false)
    };

    function photo(e){
        const data = new FormData();
        data.append('file', e.target.files[0]);
        savephoto(data)
    }

    const addLesson = (data) => {
        if(openLesson.open === 'edit'){
            if(Object.keys(data.attachmentId).length > 0){
                openLesson.id && editDarslik({...data,attachmentId :photoreducer.photo, id: openLesson.id})
                setTimeout(() => {
                    getDarslik(users.businessId)
                },1000)
                lessonAddToggle()
            }else{
                openLesson.id && editDarslik({...data, attachmentId: null, id: openLesson.id})
                setTimeout(() => {
                    getDarslik(users.businessId)
                },1000)
                lessonAddToggle()
            }
        }else{
            if(Object.keys(data.attachmentId).length > 0){
                saveDarslik({...data,attachmentId :photoreducer.photo})
                setTimeout(() => {
                    getDarslik(users.businessId)
                },1000)
                lessonAddToggle()
            }else {
                if(DarsliklarReducer?.darsliklar?.find((item) => item.id === openLesson.id )){
                    const element = DarsliklarReducer.darsliklar.find((item) => item.id === openLesson.id )
                    saveDarslik({...data, attachmentId: element.attachmentId})
                    setTimeout(() => {
                        getDarslik(users.businessId)
                    }, 1000)
                    lessonAddToggle()
                }else{
                    saveDarslik({...data, attachmentId: null})
                    setTimeout(() => {
                        getDarslik(users.businessId)
                    }, 1000)
                    lessonAddToggle()
                }
            }
        }
    }

    return (
        <>
            <Box className="MuiPageTitle-wrapper p-5">
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h3" component="h3" gutterBottom>
                            {t('Darslik.dlr')}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Grid
                sx={{
                    px: 4
                }}
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                spacing={4}
            >
                <Grid item xs={12}>
            <>
                <Box
                    display="flex"
                    alignItems="center"
                    flexDirection={{xs: 'column', sm: 'row'}}
                    justifyContent={{xs: 'center', sm: 'space-between'}}
                    pb={3}
                >
                    {
                        users.addLessonChecked && (
                            <Button
                                sx={{
                                    mt: {xs: 2, sm: 0}
                                }}
                                onClick={() => setOpenLesson({isOpen: true, open: 'create', id: null })}
                                variant="contained"
                                startIcon={<AddTwoToneIcon fontSize="small"/>}
                            >
                                {t('Darslik.drq')}
                            </Button>
                        )
                    }
                    <div style={{width: 130}}>
                        <Select value={selectValue} defaultValue={options[0]} options={options} onChange={changeSelect}/>
                    </div>
                </Box>
                <Card>
                        <Divider/>
                        {
                            DarsliklarReducer?.darsliklar ?
                                loading ?
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>#</TableCell>
                                                        <TableCell>{t('tes.85')}</TableCell>
                                                        <TableCell>{t('tes.86')}</TableCell>
                                                        <TableCell>{t('tes.87')}</TableCell>
                                                        <TableCell>{t('tes.88')}</TableCell>
                                                        <TableCell>{t('tes.89')}</TableCell>
                                                        <TableCell>{t('tes.90')}</TableCell>
                                                        <TableCell>{t('tes.91')}</TableCell>
                                                        {
                                                            (users.deleteLessonChecked || users.editLessonChecked) && (
                                                                <TableCell align="center">{t('Actions')}</TableCell>
                                                            )
                                                        }
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        DarsliklarReducer?.darsliklar.map((item, index) => <TableRow key={index}>
                                                            <TableCell>{index + 1}</TableCell>
                                                            <TableCell>{item.name}</TableCell>
                                                            <TableCell>{item.description}</TableCell>
                                                            <TableCell>{item.link}</TableCell>
                                                            <TableCell>{item.view}</TableCell>
                                                            <TableCell>{item.roleName}</TableCell>
                                                            <TableCell>{item.hasTest ? "Bor" : "Yo'q"}</TableCell>
                                                            <TableCell>
                                                                <div className='text-center'>
                                                                    {item.attachmentId ? (
                                                                        <Tooltip title={"Download"} arrow>
                                                                            <a href={`${BaseUrl}/attachment/download/${item.attachmentId}`} target="_blank" rel="noreferrer" download>
                                                                                <DownloadIcon color='primary'/>
                                                                            </a>
                                                                        </Tooltip>
                                                                        ) : (
                                                                        <Tooltip title={t('Darslik.fy')} arrow>
                                                                            <NoPhotographyIcon color='error'/>
                                                                        </Tooltip>
                                                                    )}
                                                                </div>
                                                            </TableCell>
                                                            {
                                                                (users.deleteLessonChecked || users.editLessonChecked) && (
                                                                    <TableCell align="center">
                                                                        <Typography noWrap>
                                                                            {
                                                                                users.editLessonChecked && (
                                                                                    <Tooltip title={t('Edit')} arrow>
                                                                                        <IconButton onClick={() => setOpenLesson({isOpen: true, open: 'edit', id: item.id })} color={'primary'}>
                                                                                            <EditIcon/>
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                )
                                                                            }
                                                                            {
                                                                                users.deleteLessonChecked && (
                                                                                    <Tooltip title={t('Delete')} arrow>
                                                                                        <IconButton
                                                                                            onClick={() => handleConfirmDelete(item.id)}
                                                                                            color="primary"
                                                                                        >
                                                                                            <DeleteTwoToneIcon color={'error'} fontSize="small"/>
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                )
                                                                            }
                                                                        </Typography>
                                                                    </TableCell>
                                                                )
                                                            }
                                                        </TableRow>)
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    : <Loading/> : <h4 className={'text-center'}>{t('Darslik.mtt')}</h4>
                        }
                </Card>
                <Modal isOpen={openLesson.isOpen} toggle={lessonAddToggle}>
                    <form onSubmit={handleSubmit(addLesson)}>
                        <ModalHeader>
                            <h5>{openLesson.open === 'edit' ? 'Darslikni Taxrirlash' : 'Darslik Qo\'shish'}</h5>
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label
                                    for="exampleName"
                                >
                                    {t('tes.85')}
                                </Label>
                                <input
                                    id="exampleName"
                                    type="text"
                                    className={errors.name ? errors.name?.type === "required" && 'form-control is-invalid' : 'form-control'}
                                    {...register('name', {required: true})}
                                    placeholder={errors.name ? errors.name?.type === "required" && "Name is required" : 'Name'}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label
                                    for="exampleDescription"
                                >
                                    {t('tes.86')}
                                </Label>
                                <input
                                    id="exampleDescription"
                                    type="text"
                                    className={'form-control'}
                                    {...register('description', {required: false})}
                                    placeholder={'Description'}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label
                                    for="exampleLink"
                                >
                                    {t('tes.87')}
                                </Label>
                                <input
                                    id="exampleLink"
                                    type="text"
                                    className={errors.link ? errors.link?.type === "required" && 'form-control is-invalid' : 'form-control'}
                                    {...register('link', {required: true})}
                                    placeholder={errors.link ? errors.link?.type === "required" && "Link is required" : 'Link'}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label
                                    for="exampleView"
                                >
                                    {t('tes.88')}
                                </Label>
                                <input
                                    id="exampleView"
                                    type="number"
                                    min={0}
                                    className={errors.view ? errors.view?.type === "required" && 'form-control is-invalid' : 'form-control'}
                                    {...register('view', {required: true})}
                                    placeholder={errors.view ? errors.view?.type === "required" && "View is required" : 'View'}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="exampleAttachmentId" >
                                    {t('tes.92')}
                                </Label>
                                <input
                                    id="exampleAttachmentId"
                                    type="file"
                                    className={'form-control'}
                                    {...register('attachmentId', {required: false})}
                                    placeholder={'Attachment'}
                                    onChange={photo}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="exampleRoleId" >
                                    {t('tes.93')}
                                </Label>
                                <select id="exampleRoleId"
                                        className={errors.roleId ? errors.roleId?.type === "required" && 'form-control is-invalid' : 'form-control'}
                                        {...register('roleId', {required: true})}
                                        placeholder={errors.roleId ? errors.roleId?.type === "required" && "Role is required" : 'Role'}
                                >
                                    {
                                        LavozimReducer.lavozimlar ?
                                            LavozimReducer.lavozimlar.map((item) =>
                                                <option value={item.id}>{item.name}</option>):''
                                    }
                                </select>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <ButtonBootstarp type={'submit'} color={'primary'}>
                                {t('tes.94')}
                            </ButtonBootstarp>
                            <ButtonBootstarp color="primary" outline onClick={lessonAddToggle}>Chqish</ButtonBootstarp>
                        </ModalFooter>
                    </form>
                </Modal>

                <DialogWrapper
                    open={openConfirmDelete.isOpen}
                    maxWidth="sm"
                    fullWidth
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={closeConfirmDelete}
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                        p={5}
                    >
                        <AvatarError>
                            <CloseIcon/>
                        </AvatarError>

                        <Typography
                            align="center"
                            sx={{
                                py: 4,
                                px: 6
                            }}
                            variant="h3"
                        >
                            {t('tes.95')}
                            ?
                        </Typography>

                        <Box>
                            <Button
                                variant="text"
                                size="large"
                                sx={{
                                    mx: 1
                                }}
                                onClick={closeConfirmDelete}
                            >
                                {t('tes.96')}
                            </Button>
                            <ButtonError
                                onClick={handleDeleteCompleted}
                                size="large"
                                sx={{
                                    mx: 1,
                                    px: 3
                                }}
                                variant="contained"
                            >
                                {t('tes.97')}
                            </ButtonError>
                        </Box>
                    </Box>
                </DialogWrapper>

                <ModalLoading isOpen={saveModal}/>
            </>
                </Grid>
            </Grid>
        </>
    );
};
export default connect((users, LavozimReducer,DarsliklarReducer, photoreducer), {
    getLavozim,
    getDarslik,
    getBYRoleDarslik,
    saveDarslik,
    editDarslik,
    deleteDarslik,
    savephoto
})(DarsliklarRuyxati)
