import { Fragment } from 'react';

import {
    Box,
    CardHeader,
    Avatar,
    Card,
    Link,
    ListItemAvatar,
    ListItemText,
    Divider,
    List,
    ListItem,
    Typography,
    IconButton,
    alpha,
    styled,
    useTheme, Grid, Stack
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import Scrollbar from '../../../../../Scrollbar';
import Block44 from "./Block44";
import Text from "../../../../../Text";
import MonetizationOnTwoToneIcon from "@mui/icons-material/MonetizationOnTwoTone";
import PersonTwoToneIcon from "@mui/icons-material/PersonTwoTone";
import {Label} from "reactstrap";
import {connect} from "react-redux";
import users from "../../../../../../reducer/users";
import MaxsulotlarRoyxariReducer, {getProductRemainHistory} from "../../reducer/MaxsulotlarRoyxariReducer";
import SavdoQoshishReducer, {getBackingProduct} from "../../../Savdo/reducer/SavdoQoshishReducer";
import {BaseUrl} from "../../../../../../middleware";


const CardWrapper = styled(Card)(
  ({ theme }) => `
      background: ${alpha(theme.colors.alpha.black[10], 0.05)};
      border-radius: 0;
  `
);

const LinkHover = styled('a')(
  ({ theme }) => `
    transition: ${theme.transitions.create([
      'transform',
      'opacity',
      'box-shadow'
    ])};
    transform: translateY(0px);
    display: block;
    opacity: 1;

    &:hover {
        opacity: .9;
        transform: translateY(-4px);
    }
  `
);
const germanCurrencyFormatter = new Intl.NumberFormat("uz", { notation: "standard"});


const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
    transition: ${theme.transitions.create(['transform', 'background'])};
    transform: scale(1);
    transform-origin: center;

    &:hover {
        transform: scale(1.1);
    }
  `
);

const ListWrapper = styled(List)(
  () => `
      .MuiListItem-root:last-of-type + .MuiDivider-root {
          display: none;
      }
  `
);

function Block6({
                    users,
                    MaxsulotlarRoyxariReducer,
                    getProductRemainHistory,
                    SavdoQoshishReducer,
                    getBackingProduct
                }) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Card>
        <Block44></Block44>

      <CardHeader
        subheader={
            <Typography variant="subtitle2" textAlign="center">
              {t('pro.Ushbumaxsulotturlari')}
            </Typography>
        }
      />
      <Divider />
      <Box
        sx={{
          height: 385
        }}
      >
        <Scrollbar>
          <ListWrapper disablePadding>
            { MaxsulotlarRoyxariReducer.productOneOtherInfo  && MaxsulotlarRoyxariReducer.productOneOtherInfo?.typeList?.length > 0  ?
                MaxsulotlarRoyxariReducer.productOneOtherInfo?.typeList.map((item) => (
              <Fragment key={item.id}>
                <ListItem
                  sx={{
                    display: { xs: 'block', md: 'flex' },
                    py: 1.5,
                    px: 2
                  }}
                >
                  <ListItemAvatar
                    sx={{
                      mr: 2,
                      mb: { xs: 2, md: 0 }
                    }}
                  >
                    <LinkHover href="#">
                      <Avatar
                        variant="rounded"
                        sx={{
                          width: 100,
                          height: 'auto'
                        }}
                        alt={item.name}
                        src={`${BaseUrl}/attachment/download/${item.photo}`}
                      />
                    </LinkHover>
                  </ListItemAvatar>
                  <ListItemText
                    disableTypography
                    primary={
                      <Link
                        sx={{
                          display: 'block',
                          mb: 1
                        }}

                        color="text.primary"
                        // variant="h4"
                        href="#"
                      >
                          <b> {item.name}</b>
                      </Link>
                    }
                    secondary={
                        <Label color="primary">{t('Narxi')} {item.price} {t('Hamkorlar.sum')}</Label>

                    }
                  />
                  <Box
                    component="span"
                    sx={{
                      display: { xs: 'none', md: 'inline-block' }
                    }}
                  >
                    <Box ml={3} textAlign="right">
                      <IconButtonWrapper
                        sx={{
                          backgroundColor: `${theme.colors.primary.main}`,
                          color: `${theme.palette.getContrastText(
                            theme.colors.primary.main
                          )}`,
                          transition: `${theme.transitions.create(['all'])}`,

                          'Qolgan miqdor' : {
                            backgroundColor: `${theme.colors.primary.main}`,
                            color: `${theme.palette.getContrastText(
                              theme.colors.primary.main
                            )}`
                          }
                        }}
                        size="small"
                      >
                          <Typography>
                              {t('pro.Qolganmiqdor:')}
                          </Typography>
                          <Typography>
                              <b>{germanCurrencyFormatter.format(parseFloat(item.amount).toFixed(3))} {item.measurement}</b>
                          </Typography>

                      </IconButtonWrapper>
                      <IconButtonWrapper
                        sx={{
                          ml: 1,
                          backgroundColor: `${theme.colors.error.lighter}`,
                          color: `${theme.colors.error.main}`,
                          transition: `${theme.transitions.create(['all'])}`,

                          '&:hover': {
                            backgroundColor: `${theme.colors.error.main}`,
                            color: `${theme.palette.getContrastText(
                              theme.colors.error.main
                            )}`
                          }
                        }}
                        size="small"
                      >
                          <Typography>
                              {t('pro.Sotilganmiqdor:')}
                          </Typography>
                        <Typography>
                            <b>{germanCurrencyFormatter.format(parseFloat(item.soldAmount).toFixed(3))} {item.measurement}</b>
                        </Typography>
                      </IconButtonWrapper>

                        <IconButtonWrapper
                            sx={{
                                ml: 1,
                                // backgroundColor: `#d5ffc8`,
                                color: `#2bb000`,
                                transition: `${theme.transitions.create(['all'])}`,

                                '&:hover': {
                                    backgroundColor: `#34b908`,
                                    color: `#ffffff`
                                }
                            }}
                            size="small"
                        >
                            <Typography>
                                {t('pro.Daromad:')}
                            </Typography>
                            <Typography>
                                <b>{item.profit} {t('Hamkorlar.sum')}</b>
                            </Typography>
                        </IconButtonWrapper>
                    </Box>
                  </Box>
                </ListItem>
                <Divider />
              </Fragment>
            )) :<Typography  textAlign={'center'}>
                    <b>{t('pro.Mahsulotbirturli')}</b>
                </Typography>}
          </ListWrapper>
        </Scrollbar>
      </Box>

    </Card>
  );
}

export default connect((users, MaxsulotlarRoyxariReducer, SavdoQoshishReducer), {
    getProductRemainHistory, getBackingProduct
})(Block6)
