import {useEffect, useState} from 'react'
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {Button, Col, Form, Input, Modal, Popconfirm, Row, Select as SelectAnt} from "antd";

import {DeleteButton, EditButton, QushishButton} from "../../../../Components/Buttons";
import CommonTable from "../../../../Components/CommonTable";
import users from "../../../../../reducer/users";
import branchreducer, {getbranch, savebranch, editbranchs, deletebranchs} from "../../../../../reducer/branchreducer";
import addressReducer, {getaddress, saveaddress} from "../../../../../reducer/addressReducer";
import {setLocalStorage, tablePageSize} from "../../../../../util";

import './bazalar.css'

function Bazalar({
                   branchreducer,
                   getbranch,
                   users,
                   savebranch,
                   editbranchs,
                   deletebranchs,
                   addressReducer,
                   getaddress,
                   saveaddress
                 }) {
    const {t} = useTranslation()
    const [form] = Form.useForm();
    const [formAddress] = Form.useForm();
    const [pageData, setPageData] = useState({
        page: 0,
        pageSize: tablePageSize ? tablePageSize : 10,
        openModal: false,
        openAddressModal: false,
        loading: false,
        editId: null,
        searchValue: ""
    });

    const columns = [
        {
            key: "id",
            title: 'Id',
            dataIndex: 'index',
            width: '3%',
            fixed: 'left',
        },
        {
            title: t('Buttons.13'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('set.bazaid'),
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: t('set.hudud'),
            dataIndex: 'address',
            key: 'address',
            render: (item) => item?.city
        },
        {
            title: t('lidForma.Amallar'),
            dataIndex: 'profit',
            key: 'profit',
            render: (item, record) => <div className="d-flex align-items-center gap-2">
                {
                    users.editBranch ? <EditButton onClick={() => {
                          setPageData( (prev) => ({...prev, editId: record.id, openModal: true}))
                          form.setFieldValue("name", record.name)
                          form.setFieldValue("addressId", record.address?.id)
                      }} buttonText={t('Buttons.1')} />
                      : null
                }
                {/*{*/}
                {/*    users.deleteBranch ?*/}
                {/*      <Popconfirm*/}
                {/*        title={`${t('Buttons.12')}?`}*/}
                {/*        onConfirm={() => deletebranchs(record.id)}*/}
                {/*        okText={t('Buttons.3')}*/}
                {/*        cancelText={t('Buttons.7')}*/}
                {/*      >*/}
                {/*          <DeleteButton buttonText={t('Buttons.3')} />*/}
                {/*      </Popconfirm>*/}
                {/*      : null*/}
                {/*}*/}
            </div>,
        },
    ];

    useEffect(() => {
        getbranch(users.businessId)
        getaddress()
    }, [branchreducer.current, addressReducer.current])

    useEffect(()=>{
      setPageData( (prev) => ({...prev, loading: false}))
    },[branchreducer.getBranchBool])

    useEffect(()=>{
      setPageData( (prev) => ({...prev, loading: true}))
    },[])

    function searchBaza(e) {
        setPageData( (prev) => ({...prev, searchValue: e?.target?.value}))
    }

    const newChange = (pageNumber, page) => {
        setPageData((prev) => ({ ...prev, pageSize: page, page: pageNumber - 1 }));
        setLocalStorage("pageSize", page)
    };

    const onCreate = (values) => {
        pageData.editId ? editbranchs({
            ...values,
            id: pageData.editId,
            businessId:users.businessId
        }): savebranch({...values,businessId:users.businessId})
        setPageData( (prev) => ({...prev, loading: true, editId: null, page: 0, searchValue: "", openModal: false}))
    };

    const onCreateAddress = (values) => {
      saveaddress(values)
      setPageData( (prev) => ({...prev, openAddressModal: false}))
    };

    return (
        <div>
            <div className="col-md-12 mt-4 mb-4">
                <div className="rowStyleBaza">
                    <div className="qoshish">
                        <h3>{t('set.sb')}</h3>
                        {
                            users.addBranch ?
                              <QushishButton
                                onClick={() => setPageData( (prev) => ({...prev, openModal: true}))}
                                buttonText={t('Buttons.2')}/> : null
                        }
                    </div>
                    <div>
                        <div className="mb-2 mt-2 mt-md-3">
                            <Input allowClear rootClassName="w-full searchBaza" onChange={searchBaza}
                                   placeholder={t("num.37")}/>
                        </div>
                        {
                          users.viewBranch || users.viewBranchAdmin ?
                            <CommonTable
                              loading={pageData.loading}
                              scroll={{x: 1200}}
                              total={branchreducer.branch?.filter((val) => pageData.searchValue === '' ? val : val.name.toUpperCase().includes(pageData.searchValue.toUpperCase()) && val)?.length} handlePageChange={newChange}
                              pagination={true}
                              page={pageData.page}
                              size={pageData.pageSize}
                              data={branchreducer.branch?.filter((val) => pageData.searchValue === '' ? val : val.name.toUpperCase().includes(pageData.searchValue.toUpperCase()) && val)}
                              columns={columns}
                            /> : null
                        }
                    </div>
                </div>
            </div>
            <div className="col-md-12">
                <Modal
                  open={pageData.openModal}
                  title={t('Sections.8')}
                  okText={t('Buttons.6')}
                  cancelText={t('Buttons.7')}
                  onCancel={() => {
                      setPageData( (prev) => ({...prev, openModal: false, editId: null}))
                      form.resetFields();
                  }}
                  onOk={() => {
                      form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreate(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                  }}
                >
                    <Form
                      form={form}
                      layout="vertical"
                      name="form_in_modal_baza"
                    >
                        <Form.Item
                          name="name"
                          label={t('pro.Nomi')}
                          rules={[
                              {
                                  required: true,
                                  message: 'Name is required'
                              }
                          ]}
                        >
                            <Input placeholder={`${t('pro.Nomi')}...`} />
                        </Form.Item>
                        <Row align="bottom">
                          <Col span={21}>
                            <Form.Item
                              name="addressId"
                              label={t('set.manzil')}
                              rules={[
                                {
                                  required: true,
                                  message: 'Address is required'
                                }
                              ]}
                            >
                              <SelectAnt
                                className="w-100"
                                placeholder={`${t('set.manzil')}...`}
                                size="middle">
                                {
                                  addressReducer.address?.map((item, index) => <SelectAnt.Option key={index} value={item.id}>{item.city} {t('set.shahar')} , {item.district} {t('set.tuman')}</SelectAnt.Option>)
                                }
                              </SelectAnt>
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item>
                              <Button onClick={() => setPageData( (prev) => ({...prev, openAddressModal: true}))} htmlType="button" className="w-100" title={t('Buttons.2')}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5} stroke="currentColor" style={{height: 16, width: 16, color: "#000"}}>
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15"/>
                                </svg>
                              </Button>
                            </Form.Item>
                          </Col>
                        </Row>
                    </Form>
                </Modal>
            </div>
            <div className="col-md-12">
              <Modal
                open={pageData.openAddressModal}
                title={t('set.addmanzil')}
                okText={t('Buttons.6')}
                cancelText={t('Buttons.7')}
                onCancel={() => {
                  setPageData( (prev) => ({...prev, openAddressModal: false}))
                  formAddress.resetFields();
                }}
                onOk={() => {
                  formAddress
                    .validateFields()
                    .then((values) => {
                      formAddress.resetFields();
                      onCreateAddress(values);
                    })
                    .catch((info) => {
                      console.log('Validate Failed:', info);
                    });
                }}
              >
                <Form
                  form={form}
                  layout="vertical"
                  name="form_in_modal_address"
                >
                  <Form.Item
                    name="city"
                    label={t('set.shahar')}
                    rules={[
                      {
                        required: true,
                        message: 'City is required'
                      }
                    ]}
                  >
                    <Input placeholder={`${t('set.shahar')}...`} />
                  </Form.Item>
                  <Form.Item
                    name="district"
                    label={t('set.tuman')}
                    rules={[
                      {
                        required: true,
                        message: 'District is required'
                      }
                    ]}
                  >
                    <Input placeholder={`${t('set.tuman')}...`} />
                  </Form.Item>
                  <Form.Item
                    name="street"
                    label={t('set.kucha')}
                    rules={[
                      {
                        required: true,
                        message: 'Street is required'
                      }
                    ]}
                  >
                    <Input placeholder={`${t('set.kucha')}...`} />
                  </Form.Item>
                  <Form.Item
                    name="street"
                    label={t('set.uy')}
                    rules={[
                      {
                        required: true,
                        message: 'Home is required'
                      }
                    ]}
                  >
                    <Input placeholder={`${t('set.uy')}...`} />
                  </Form.Item>
                </Form>
              </Modal>
            </div>
        </div>
    )
}

 export default connect((branchreducer, users, addressReducer), {
     getbranch,
     savebranch,
     editbranchs,
     deletebranchs,
     getaddress,
     saveaddress
 })(Bazalar)
