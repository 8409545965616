import React from 'react'
import {Link} from 'react-router-dom'
import CSV from '../../../../../img/CSV.png'
import Excel from '../../../../../img/Excel.png'
import Print from '../../../../../img/Print.png'
import Data from '../../../../../img/Data.png'
import Pdf from '../../../../../img/PDF.png'
import Edit from '../../../../../img/Edit.png'
import Korish from '../../../../../img/Korish.png'
import Delete from '../../../../../img/Delete.png'
import Arrow from '../../../../../img/arrowIcon.png'
import './otkazmalarRoyxati.css'
import {connect} from "react-redux";
import {useEffect, useState} from "react";
import OtkazmaReducer, {
    deleteOtkazma,
    editOtkazma,
    saveOtkazma,
    getOtkazma,
    getOtkazmaByBranch, getOneExchange
} from "../reducer/OtkazmaReducer";
import users from "../../../../../reducer/users";
import {useTranslation} from "react-i18next";
import formatDate, {formatDateMinus, formatDateWithTime} from "../../../../../util";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Loading from "../../../../Loading";
import ExchangeReducer, {editExchanges, getExchanges, getOneExchanges} from "../reducer/ExchangeReducer";
import {IconButton, Tooltip} from "@mui/material";
import LaunchTwoToneIcon from "@mui/icons-material/LaunchTwoTone";
import {toast} from "react-toastify";
import ModalLoading from "../../../../ModalLoading";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from '@mui/icons-material/Check';
import CarReducer, {getCars} from "../reducer/CarReducer";

function OtkazmalarRoyxati({
                               getOtkazma,
                               getOneExchange,
                               otkazmalar,
                               getOtkazmaByBranch,
                               deleteOtkazma,
                               saveOtkazma,
                               match,
                               users,
                               OtkazmaReducer, editExchanges,
                               ExchangeReducer, getOneExchanges, getExchanges
                               , getCars, CarReducer
                           }) {

    const {t} = useTranslation()
    const [input, setInput] = useState(
        {
            view: '',
            search: '',
        }
    )

    function view(e) {
        input.view = e.target.value
        let a = {...input}
        setInput(a)
    }

    function search(e) {
        input.search = e.target.value
        let a = {...input}
        setInput(a)
    }

    // useEffect(() => {
    //     if (users.viewExchangeAdmin && users.viewExchange) {
    //         getExchanges(users.businessId)
    //     } else if (users.viewExchange) {
    //         getExchanges(users.businessId)
    //     }
    // }, [ExchangeReducer.current])

    function deleteOt(item) {
        deleteOtkazma(item.id)
    }

    const [headlist, setheadlist] = useState([
        {
            sana: t('Trade.4'),
            baza: t('ProductList.8'),
            baza2: t('BaseList.3'),
            status: t('Trade.9'),
            yulkira: t('BaseList.4'),
            jamisumma: t('Purchase.22'),
            qisqaeslatma: t('Buttons.17'),
            amallar: 'Amallar'
        }
    ])
    const [sana, setsana] = useState(true)
    const [baza, setbaza] = useState(true)
    const [baza2, setbaza2] = useState(true)
    const [amallar, setamallar] = useState(true)

    const [malkamay, setmalkamay] = useState(false)

    const [activeinfo, setactiveinfo] = useState(false)


    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
    }, [ExchangeReducer.getBoolean])



    function toggleinfo() {
        setactiveinfo(!activeinfo)
    }

    function info(id) {
        toggleinfo()
        getOneExchanges(id)
    }

    const [visible, setvisible] = useState(5)
    let [counter, setCounter] = useState(0)

    function koproq() {
        let a = ExchangeReducer.exchanges.length
        for (let i = 0; a; i++) {
            counter += visible
            if (counter < a) {
                setvisible(prev => prev + 5)
                return;
            } else {
                toast.error(t('tes.75'))
                return
            }
        }
    }

    const [saveModal, setSaveModal] = useState(false)


    const [exchangeArray,setExchangeArray] = useState([])

    useEffect(()=>{
        if (ExchangeReducer.exchangeOne){
            setExchangeArray(ExchangeReducer.exchangeOne?.exchangeProductBranchDTO?.exchangeProductDTOS)
        }
    },[ExchangeReducer.getBoolean])

    useEffect(() => {
        if (match?.params.objectId) {
            setactiveinfo(true)
            getOneExchanges(match.params.objectId)
        }else if (match?.params.objectId==={}){
            setactiveinfo(false)
        }else if (match?.params.objectId===null){
            setactiveinfo(false)
        }
        getCars(users.businessId)
    }, [])

    const [isDisable, setIsDisable] = useState(false)
    const [saveEditId, setSaveEditId] = useState(null)
    const [carId, setCarId] = useState(null)
    const [message, setMessage] = useState(null)
    const [openMessage,setOpenMessage] = useState(false)


    function  changeExchangeQuantity(value,index){
        if (exchangeArray.length > 0){
            const newState = exchangeArray.map((obj,ind) =>
                ind === index ? { ...obj, exchangeProductQuantity: value } : obj
            );

            setExchangeArray(newState)
        }

    }


    function editM(id) {
        setIsDisable(!isDisable)
        setSaveEditId(id)
    }

    // const [editTrue, setEditTrue] = useState(false)

    // function saveEdit() {
    //     ExchangeReducer.exchanges.map(item=>{
    //         if (item.id===match.params.objectId){
    //             editExchanges({
    //                 carId,
    //                 confirmation: false,
    //                 exchangeProductBranchDTO: {
    //                     businessId: users.businessId,
    //                     description: item.exchangeProductBranchDTO.description,
    //                     exchangeDate: formatDateMinus(item.exchangeProductBranchDTO.exchangeDate),
    //                     exchangeProductDTOS: exchangeArray,
    //                     exchangeStatusId: item.exchangeProductBranchDTO.exchangeStatusId,
    //                     receivedBranchId: item.exchangeProductBranchDTO.receivedBranchId,
    //                     shippedBranchId: item.exchangeProductBranchDTO.shippedBranchId,
    //                 },
    //                 id: match.params.objectId,
    //                 message,
    //                 receivedBranchName: item.receivedBranchName,
    //                 shippedBranchName: item.shippedBranchName,
    //                 userBranchId:item.shippedBranchId
    //             })
    //         }
    //     })
    //
    //     setEditTrue(true)
    //
    // }

    function tasdiqlash() {
        if (carId===null || carId==='tanlash') {

            toast.warning(t('tes.76'))
        } else {
            ExchangeReducer.exchanges.map(item=>{
                if (item.id===match?.params.objectId){
                    editExchanges({
                        carId,
                        confirmation: true,
                        exchangeProductBranchDTO: {
                            businessId: users.businessId,
                            description: item.exchangeProductBranchDTO.description,
                            exchangeDate: formatDateMinus(item.exchangeProductBranchDTO.exchangeDate),
                            exchangeProductDTOS:exchangeArray,
                            exchangeStatusId: item.exchangeProductBranchDTO.exchangeStatusId,
                            receivedBranchId: item.exchangeProductBranchDTO.receivedBranchId,
                            shippedBranchId: item.exchangeProductBranchDTO.shippedBranchId,
                        },
                        id: match.params.objectId,
                        message,
                        receivedBranchName: item.receivedBranchName,
                        shippedBranchName: item.shippedBranchName,
                        userBranchId: item.exchangeProductBranchDTO.shippedBranchId
                    })
                }
            })

            // let a = ExchangeReducer.exchangeOne
            // saveOtkazma({
            //     shippedBranchId: a?.exchangeProductBranchDTO.shippedBranchId,
            //     receivedBranchId: a?.exchangeProductBranchDTO.receivedBranchId,
            //     exchangeDate: formatDateMinus(a?.exchangeProductBranchDTO.exchangeDate),
            //     description: a?.exchangeProductBranchDTO.description,
            //     exchangeStatusId: a?.exchangeProductBranchDTO.exchangeStatusId,
            //     exchangeProductDTOS: exchangeArray,
            //     businessId: users.businessId
            // })

            // setEditTrue(false)
            setSaveModal(true)
        }

    }

    function radEtish() {
        setOpenMessage(!openMessage)
    }

    function sendMessage(){
        if (message) {
            ExchangeReducer.exchanges.map(item=>{
                if (item.id===match.params.objectId){
                    editExchanges({
                        carId:null,
                        confirmation: false,
                        exchangeProductBranchDTO: {
                            businessId: users.businessId,
                            description: item.exchangeProductBranchDTO.description,
                            exchangeDate: formatDateMinus(item.exchangeProductBranchDTO.exchangeDate),
                            exchangeProductDTOS: item.exchangeProductBranchDTO.exchangeProductDTOS,
                            exchangeStatusId: item.exchangeProductBranchDTO.exchangeStatusId,
                            receivedBranchId: item.exchangeProductBranchDTO.receivedBranchId,
                            shippedBranchId: item.exchangeProductBranchDTO.shippedBranchId,
                        },
                        id: match.params.objectId,
                        message,
                        receivedBranchName: item.receivedBranchName,
                        shippedBranchName: item.shippedBranchName
                    })
                }
            })
            setSaveModal(true)

        } else {
            toast.warning(t('tes.77'))
        }
    }

    useEffect(()=>{
        if (ExchangeReducer.saveBoolean){
            setOpenMessage(false)
            setMessage(null)
            getExchanges(users.businessId)
            setactiveinfo(false)
        }
        setSaveModal(false)
    },[ExchangeReducer.current])

    useEffect(() => {
        setSaveModal(false)
        // setactiveinfo(false)
    }, [OtkazmaReducer.counter])

    useEffect(() => {
        setLoading(false)
        getExchanges(users.businessId)
    }, [])

    return (
        <div className="col-md-12 mt-2 mt-4 mb-4 ">
            <div className="textHeader">
                <h2>{t('baza.utkazmah')}</h2>
            </div>
            <div className="rowStyleN">
                <div className="qoshish">
                    <h5>{t('baza.Hisobot')}</h5>
                    {
                        users.addExchange ? <Link to={'/main/exchange/request'}>
                            <button className='btn btn-primary'>{t('baza.mahss')}</button>
                        </Link> : ''
                    }

                </div>
                {
                    users.viewExchange || users.viewExchangeAdmin ?
                        loading ?
                            ExchangeReducer.exchanges ?
                                <div>
                                    <div className="izlashOR">
                                        <div className="izlashBox1">
                                            <p>{t('Buttons.8')}</p>
                                            <select name="" value={input.view} onChange={view} id="">
                                                <option value="">25</option>
                                                <option value="">1,000</option>
                                                <option value="">All</option>
                                            </select>
                                            <button><img src={CSV} alt=""/> Export CSV</button>
                                            <button><img src={Excel} alt=""/> Export Excel</button>
                                            <button><img src={Print} alt=""/> Print</button>
                                            <button><img src={Pdf} alt=""/>Export PDF</button>
                                            <button onClick={() => setmalkamay(!malkamay)}><img src={Data}
                                                                                                alt=""/>{t('Buttons.18')}
                                            </button>


                                        </div>
                                        <div className="izlashBox2">
                                            <input type="text" placeholder={t('tes.73')} value={input.search}
                                                   onChange={search}/>
                                        </div>
                                    </div>
                                    <div className="table-responsive table-wrapper-scroll-y my-custom-scrollbar">
                                        <table className='table table-striped table-bordered mt-4'>
                                            <thead>

                                            {
                                                headlist.map(item => <tr key={item.id}>
                                                    <th>T/R</th>
                                                    <th>{t('Hamkorlar.eslatma')}</th>
                                                    {sana ? <th>{item.sana}</th> : ''}
                                                    <th>{t('baza.Xabar')}</th>
                                                    {baza ? <th>{item.baza} {t('tes.78')}</th> : ''}
                                                    {baza2 ? <th>{item.baza2}</th> : ''}
                                                    {amallar ? <th>{item.amallar}</th> : ''}
                                                </tr>)
                                            }

                                            </thead>
                                            <tbody>

                                            {
                                                ExchangeReducer.exchanges.map((item, index) => <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item?.exchangeProductBranchDTO?.description}</td>
                                                    <td>{formatDateWithTime(item.exchangeProductBranchDTO.exchangeDate)}</td>
                                                    <td>{item.message}</td>
                                                    <td>{item?.shippedBranchName}</td>
                                                    <td>{item?.receivedBranchName}</td>
                                                    <td>
                                                        <Tooltip title={t('View')} arrow>
                                                            <IconButton
                                                                onClick={() => info(item.id)}
                                                                color="primary"
                                                            >
                                                                <LaunchTwoToneIcon fontSize="small"/>
                                                            </IconButton>
                                                        </Tooltip>


                                                    </td>
                                                </tr>)
                                            }

                                            <Modal size={'xl'} isOpen={activeinfo} toggle={toggleinfo}>
                                                <ModalHeader>
                                                    <h4>{t('baza.BATAFSIL')}</h4>
                                                </ModalHeader>
                                                <ModalBody>
                                                    <table
                                                        className={'table-hover table table-bordered mt-3 table-striped-columns'}>
                                                        <thead>
                                                        <tr>
                                                            <th>{t("block.m")}</th>
                                                            <th>{t("block.mi")}</th>
                                                            <th>{t('Hamkorlar.eslatma')}</th>
                                                            <th>{t("Salary.s")}</th>
                                                            <th>{t("baza.Tahrirlash")}</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            exchangeArray ?
                                                                <tr>
                                                                    <td>{exchangeArray.map((item, index) =>
                                                                        <div key={index}>
                                                                            <td>{item.productTypePriceName ? item.productTypePriceName : item.productName}</td>
                                                                        </div>)}</td>
                                                                    <td>{exchangeArray.map((item, index) =>
                                                                        <div key={index}>
                                                                            <td><input disabled={isDisable ? false : true}
                                                                                       type="number" className={'form-control'}
                                                                                       value={item.exchangeProductQuantity} onChange={(e)=>changeExchangeQuantity(e.target.value,index)}/>
                                                                            </td>
                                                                            <td>{item.measurementProductTypePriceName ? item.measurementProductTypePriceName : item?.measurementProductName}</td>
                                                                        </div>)}</td>
                                                                    <td>{ExchangeReducer.exchangeOne?.exchangeProductBranchDTO.description}</td>
                                                                    <td>{formatDateWithTime(ExchangeReducer.exchangeOne?.exchangeProductBranchDTO.exchangeDate)}</td>
                                                                    <td>
                                                                        <Tooltip title={t("baza.Tahrirlash")}
                                                                                 arrow>
                                                                            <IconButton
                                                                                onClick={() => editM(ExchangeReducer.exchangeOne.id)}
                                                                                color="primary"
                                                                            >
                                                                                <EditIcon fontSize="small"/>
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        {/*{*/}
                                                                        {/*    isDisable ?*/}
                                                                        {/*        <Tooltip title={t("Saqlash")}*/}
                                                                        {/*                 arrow>*/}
                                                                        {/*            <IconButton*/}
                                                                        {/*                onClick={saveEdit}*/}
                                                                        {/*                color="primary"*/}
                                                                        {/*            >*/}
                                                                        {/*                <CheckIcon fontSize="small"/>*/}
                                                                        {/*            </IconButton>*/}
                                                                        {/*        </Tooltip> : ''*/}
                                                                        {/*}*/}

                                                                    </td>
                                                                </tr>:''

                                                        }
                                                        </tbody>
                                                    </table>

                                                    {
                                                        match.params.objectId ?
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <select value={carId}
                                                                            onChange={(e) => setCarId(e.target.value)}
                                                                            className={'form-control'}>
                                                                        <option value="tanlash">{t("Hamkorlar.tanlash")}</option>
                                                                        {
                                                                            CarReducer.cars ?
                                                                                CarReducer.cars.map(item => <option
                                                                                    value={item.id}>{item.model}</option>) : ''
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div> : ''
                                                    }
                                                </ModalBody>
                                                <ModalFooter>
                                                    {
                                                        match.params.objectId ?
                                                            <button onClick={tasdiqlash}
                                                                    className={'btn btn-primary'}>{t('baza.Tasdiqlash')}
                                                            </button> : ''
                                                    }

                                                    {/*{*/}
                                                    {/*    match.params.objectId ?*/}
                                                    {/*        <button onClick={radEtish} className={'btn btn-primary'}>*/}
                                                    {/*            Rad etish*/}
                                                    {/*        </button> : ''*/}
                                                    {/*}*/}

                                                    <button className={'btn btn-outline-primary'}
                                                            onClick={toggleinfo}>{t('Buttons.7')}
                                                    </button>
                                                </ModalFooter>
                                            </Modal>
                                            </tbody>
                                        </table>

                                        {
                                            ExchangeReducer.exchanges ?
                                                ExchangeReducer.exchanges.length > 5 ? <button onClick={koproq}
                                                                                               className={'btn btn-outline-danger form-control'}>{t('Buttons.5')}</button> : '' : ''
                                        }
                                    </div>
                                </div> : <div>
                                    <h4 className={'text-center'}>{t('Darslik.mtt')}</h4>
                                </div> : <Loading/> : ''
                }

            </div>

            <Modal isOpen={openMessage} toggle={()=>setOpenMessage(!openMessage)}>
                <ModalHeader>
                    <h5>{t('Salary.X')}</h5>
                </ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-md-12">
                            <label htmlFor="">{t('Salary.X')}</label>
                            <input type="text" className={'form-control'} value={message} onChange={(e)=>setMessage(e.target.value)}/>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button onClick={sendMessage} className={'btn btn-primary'}>{t('Buttons.6')}</button>
                    <button onClick={()=>setOpenMessage(!openMessage)} className={'btn btn-primary'}>{t('Buttons.7')}</button>
                </ModalFooter>
            </Modal>
            <ModalLoading isOpen={saveModal}/>
        </div>
    )
}

export default connect((OtkazmaReducer, users, ExchangeReducer, CarReducer), {
    getOtkazma,
    saveOtkazma,
    getOneExchange,
    getOtkazmaByBranch,
    editOtkazma,
    deleteOtkazma,
    getExchanges, getOneExchanges, editExchanges, getCars
})(OtkazmalarRoyxati)
