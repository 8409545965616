import {lazy, useEffect} from "react";
import {Route, Switch, Link, NavLink, useHistory} from 'react-router-dom'
import './superadmin.css'
import ArchiveBusiness from "./pages/archive-business/ArchiveBusiness";
const SuperAdminPage = lazy(() => import('./pages/superadmin/SuperAdminPage'))
const AllBusenesses = lazy(() => import('./pages/all-buseness/AllBusenesses'))
const PackageSubscription = lazy(() => import('./pages/package-subscription/PackageSubscripton'))
const Packages = lazy(() => import('./pages/packages/Packages'))
const SuperAdminSettings = lazy(() => import('./pages/super-admin-settings/SuperAdminSettings'))
const Communicator = lazy(() => import('./pages/communicator/Communicator'))
const AddBusiness = lazy(() => import('./pages/all-buseness/addBusiness/AddBusiness'))
const PackageAdd = lazy(() => import('./pages/packages/PackageAdd/PackageAdd'))
const MainPageTitles = lazy(() => import("./pages/main-pages-titles/MainPageTitles"))

function SuperAdmin() {


    const history = useHistory()

    useEffect(()=>{
        // history.push('/main/superadmin/main')
    },[])


    return (
        <div className="col-md-12 pb-4 pt-4">
        <div className="superadminNavbar">
            <NavLink exact className={(isActive) => isActive ? "activeSuperadmin" : "noactiveSuperadmin"} to='/main/superadmin'> Super Admin</NavLink>
            <NavLink exact className={(isActive) => isActive ? "activeSuperadmin" : "noactiveSuperadmin"} to='/main/superadmin/allbusenesses'>All Businesses</NavLink>
            <NavLink  className={(isActive) => isActive ? "activeSuperadmin" : "noactiveSuperadmin"} to='/main/superadmin/archiveBusiness'>Archive Businesses</NavLink>
            <NavLink  className={(isActive) => isActive ? "activeSuperadmin" : "noactiveSuperadmin"} to='/main/superadmin/paskages'>Paskages</NavLink>
            {/*<Link to='/main/superadmin/titles'> <button>Titles</button></Link>*/}
            {/*<Link to='/main/superadmin/superadminsettings'> <button>Super Admin Settings</button></Link>*/}
            {/*<Link to='/main/superadmin/changer'> <button>Change Users Password</button></Link>*/}
        </div>
        <div className='mt-2'>
            <Switch>
                <Route path={'/main/superadmin/allbusenesses/addbusiness/:id?'} component={AddBusiness} />
                <Route path={'/main/superadmin/allbusenesses/addbusiness'} component={AddBusiness} />
                <Route path={'/main/superadmin/allbusenesses'} component={AllBusenesses}/>
                <Route path={'/main/superadmin/archiveBusiness'} component={ArchiveBusiness}/>
                <Route path={'/main/superadmin/paskages/addpackage/:id'} component={PackageAdd}/>
                <Route path={'/main/superadmin/paskages/addpackage'} component={PackageAdd}/>
                <Route path={'/main/superadmin/paskages'} component={Packages}/>
                <Route path={'/main/superadmin'} component={SuperAdminPage}/>
                {/*<Route path={'/main/superadmin/titles'} component={MainPageTitles}/>*/}
                {/*<Route path={'/main/superadmin/superadminsettings'} component={SuperAdminSettings}/>*/}
                {/*<Route path={'/main/superadmin/changer'} component={Communicator}/>*/}
            </Switch>
        </div>
    </div>)


}

export default SuperAdmin
