import React from 'react'
import './customerProfile.css'
import {useState, useEffect} from 'react'
import {connect} from "react-redux";
import MijozGuruxReducer, {
    getCustomerOne,
    getCustomerTradeInfo,
    getCustomerPayType,
    getCustomerInfo
} from "../reducer/MijozGuruxReducer";
import users from "../../../../../reducer/users";
import {useTranslation} from "react-i18next";
import img from "../../../../../img/Jami2.svg";
import CardBody from "../../../../Components/CardBody";
import profile from '../../../../../assets/Frame 1000005568.svg'
import totalTrade from '../../../../../assets/fund accounting (1).svg'
import profileProfit from '../../../../../assets/money transfer.svg'
import view from '../../../../../assets/Subtract.svg'
import CommonTable from "../../../../Components/CommonTable";
import {prettify} from "../../../../../util";
import moment from "moment";
import ExcelButtons from "../../../../Components/ExcelButtons";
import {Modal, ModalBody, ModalHeader} from "reactstrap";


function MijozProfil({

                         users, match,
                         getCustomerOne,
                         getCustomerTradeInfo,
                         getCustomerPayType,
                         getCustomerInfo,
                         MijozGuruxReducer
                     }) {


    const {t} = useTranslation()
    const [mainBranchId, setMainBranchId] = useState(null)
    const [viewCheck,setViewCheck] = useState(false)
    const [viewTrade,setViewTrade] = useState({})




    function isOpenViewCheck(id){
        const resultFilter = MijozGuruxReducer.customerTradeInfo.find(item=>id === item.trade.id)
        setViewTrade(resultFilter)
    }

    useEffect(() => {
        if (users.viewCustomerAdmin && users.viewCustomer) {
            setMainBranchId(null)
        } else if (users.viewCustomer) {
            setMainBranchId(users.branchId)
        }
        getCustomerPayType(match.params.id)
        getCustomerInfo(match.params.id)
        getCustomerTradeInfo(match.params.id)
        getCustomerOne(match.params.id)
    }, [])

    const [page, setPage] = useState(0);
    const [size, setSize] = useState(5);
    const handlePageChange = (newPage) => {
        setPage(newPage - 1);
    };
    const handleLimitChange = (event, size) => {
        setPage(0)
        setSize(size);
    };


    const columns = [
        {
            title: 'Sana',
            dataIndex: 'createAt',
            key: 'createAt',
            width: '13%',
            render: (item) =>
                <p className={'m-0 p-0'}>
                    {(moment(new Date(item)).format('L')).replace(/\//g, "-")}
                </p>
        },
        {
            title: 'Savdo chekini ko’rish',
            dataIndex: 'id',
            key: 'id',
            width: '22%',
            render: (item,value) =>
                <button className={'profile-check-button'} onClick={()=>{
                    setViewCheck(true)
                    isOpenViewCheck(value.trade?.id)
                }}>
                    Chekni ko’rish
                    <img src={view} width={14} height={14} alt={item}/>
                </button>

        },
        {
            title: 'Narxi',
            dataIndex: 'totalSumma',
            key: 'totalSumma',
            width: '20%',
            render: (item,value) =>
                <p className={'m-0 p-0'}>
                    {prettify(value?.trade?.totalSum)} so'm
                </p>
        },
        {
            title: 'To’langan summasi',
            dataIndex: 'totalSumma',
            key: 'totalSumma',
            render: (item,value) =>
                <p style={{
                    color: value?.trade?.paidSum === 0 ? '#E02323' : value?.trade?.paidSum > 0 && value?.trade?.paidSum < value?.trade?.totalSum? '#FF851F' : '#57CA22'
                }} className={'m-0 p-0'}>
                    {prettify(value?.trade?.paidSum)} so'm
                </p>,
            width: '20%'
        },
        {
            title: 'Holati',
            dataIndex: 'totalSum',
            key: 'totalSum',
            render: (item,value) => <span className={`profile-status 
            profile-status-${value?.trade?.paidSum === 0 ? 'red' : value?.trade?.paidSum > 0 && value?.trade?.paidSum < value?.trade?.totalSum?  'warning' : 'green'}`}>
                {value?.trade?.paidSum === 0 ? 'Qarzga savdo' : value?.trade?.paidSum > 0 && value?.trade?.paidSum < value?.trade?.totalSum?  'Qisman tolangan' : 'Savdo tolandi'}
            </span>,
            width: '25%'
        },
    ];

    const [pageTwo, setPageTwo] = useState(0);
    const [sizeTwo, setSizeTwo] = useState(5);
    const handlePageChangeTwo = (newPage) => {
        setPageTwo(newPage - 1);
    };
    const handleLimitChangeTwo = (event, size) => {
        setPageTwo(0)
        setSizeTwo(size);
    };


    const columnsTwo = [
        {
            title: 'Sana',
            dataIndex: 'createAt',
            key: 'createAt',
            width: '12%',
            render: (item) =>
                <p className={'m-0 p-0'}>
                    {(moment(new Date(item)).format('L')).replace(/\//g, "-")}
                </p>
        },
        {
            title: 'Turi',
            dataIndex: 'paidSum',
            key: 'paidSum',
            width: '20%',
            render: (item) =>
                <p className={'m-0 p-0'}>
                    {item > 0 ? "To'landi" : 'Buyurtma'}
                </p>
        },
        {
            title: 'Qarz',
            dataIndex: 'debtSum',
            key: 'debtSum',
            render: (item) =>
                <span className={`profile-status 
            profile-status-${item > 0 ? 'red' : 'green'}`}>
                {prettify(item)} so'm
            </span>,
            width: '22%'
        },
        {
            title: 'To\'lov',
            dataIndex: 'paidSum',
            key: 'paidSum',
            render: (item) => <span className={`profile-status 
            profile-status-${item > 0 ? 'green' :  'red'}`}>
                {prettify(item)} so'm
            </span>,
            width: '18%'
        },
    ];

    const columnsTradeProduct = [
        {
            title: 'Mahsulot',
            dataIndex: 'id',
            key: 'id',
            width: '20%',
            render: (item,value) =>
                <p className={'m-0 p-0'}>
                    {value?.product?.name}
                </p>
        },
        {
            title: 'Miqdori',
            dataIndex: 'tradedQuantity',
            key: 'tradedQuantity',
            width: '15%',
            render: (item,value) =>
                <p className={'m-0 p-0'}>
                    {item} {value?.product?.measurement?.name}
                </p>
        },
        {
            title: 'Qoldi',
            dataIndex: 'remainQuantity',
            key: 'remainQuantity',
            render: (item,value) =>
                <p className={'m-0 p-0'}>
                    {item ? item : 0} {value?.product?.measurement?.name}
                </p>,
            width: '15%'
        },
        {
            title: 'Foyda',
            dataIndex: 'profit',
            key: 'profit',
            render: (item) => <span className={`profile-status 
            profile-status-${item > 0 ? 'green' :  'red'}`}>
                {prettify(item)} so'm
            </span>,
            width: '25%'
        },
        {
            title: 'Jami Summa',
            dataIndex: 'totalSalePrice',
            key: 'totalSalePrice',
            render: (item) => <p className={'m-0 p-0'}>{prettify(item)} so'm</p>,
            width: '25%'
        },
    ];


    const {totalProfitSum, totalTradeSum, customerDto} = MijozGuruxReducer.customerInfo

    return (
        <div className="row p-3">
            <div class="col-md-4">
                <CardBody>
                    <div className='d-flex justify-content-start align-items-top gap-3'>
                        <div>
                            <img width={64} height={64} src={profile} alt="profile"/>
                        </div>
                        <div className={'d-flex justify-content-between align-items-start flex-column'}>
                            <p className={'profile-name'}>
                               Mijoz:<strong>{customerDto?.name}</strong> 👋🏻
                            </p>
                            <span class={'profile-active'}>
                            Faol
                        </span>
                        </div>
                    </div>
                    <div className='mt-4 row d-flex flex-column gap-3'>
                        <div className='col-md-9 d-flex justify-content-between align-items-center'>
                            <p className={'profile-detail'}>Telefon</p>
                            <strong className={'profile-detail'}>+{customerDto?.phoneNumber}</strong>
                        </div>
                        <div className='col-md-9 d-flex justify-content-between align-items-center'>
                            <p className={'profile-detail'}>Qo'shgan Hodim</p>
                            <strong className={'profile-detail'}>{customerDto?.createdByName}</strong>
                        </div>
                        <div className='col-md-9 d-flex justify-content-between align-items-center'>
                            <p className={'profile-detail'}>Qo'shilgan sana</p>
                            <strong
                                className={'profile-detail'}>{(moment(new Date(customerDto?.createdAt)).format('L')).replace(/\//g, ".")}</strong>
                        </div>
                    </div>
                    <div>
                        <div style={{background: '#EEF2FC'}} className={'profile-trade'}>
                            <div className={'d-flex justify-content-start align-items-end gap-2'}>
                                <img src={totalTrade} width={32} height={32} alt="totalTrade"/>
                                <p className={'profile-trade-text'}>Umumiy Savdo</p>
                            </div>
                            <div className={'mt-3'}>
                                <p className={'profile-trade-sum'}>{prettify(totalTradeSum)} so’m</p>
                            </div>
                        </div>
                        <div style={{background: '#DEF8DF'}} className={'profile-trade'}>
                            <div className={'d-flex justify-content-start align-items-end gap-2'}>
                                <img src={profileProfit} width={32} height={32} alt="profileProfit"/>
                                <p className={'profile-trade-text'}>Mijozdan olingan daromad</p>
                            </div>
                            <div className={'mt-3'}>
                                <p style={{color: '#05B06C'}}
                                   className={'profile-trade-sum'}>{prettify(totalProfitSum)} so’m</p>
                            </div>
                        </div>
                    </div>
                </CardBody></div>
            <div class="col-md-8">
                <CardBody>
                    <div className={'row'}>
                        <div class="col-md-5 profile-report-texts">
                            <strong>Savdolar va oldi-berdilar</strong>
                            <p>Mijozga qilingan savdolar va qarzlar pul oldi berdilari</p>
                        </div>
                        <div class="col-md-4 d-flex justify-content-start align-items-center gap-4">
                            <div>
                                <p className={'profile-balance'}>Balance</p>
                            </div>
                            <div className={'d-flex justify-content-start align-items-center gap-3'}>
                                {/*<div>*/}
                                {/*    <p className={'profile-currency'}>Dollarda</p>*/}
                                {/*    <span className={'profile-currency-total'}>0.04 $</span>*/}
                                {/*</div>*/}
                                <div>
                                    <p className={'profile-currency'}>So’mda</p>
                                    <span style={customerDto?.debt > 0 ? {background: '#FFEFEF', color: '#E02323'} : {
                                        background: '#EEFAE8',
                                        color: '#57CA22'
                                    }}
                                          className={'profile-currency-total'}> {customerDto?.debt.toString().replace('-', "")} so'm</span>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4 table-responsive">
                            <CommonTable size={size} page={page} handleLimitChange={handleLimitChange}
                                         handlePageChange={handlePageChange}
                                         total={MijozGuruxReducer.customerTradeInfo?.length}
                                         pagination={true} data={MijozGuruxReducer.customerTradeInfo}
                                         columns={columns}
                                         scroll={{x: 1000, y: 330}}
                            />
                        </div>
                    </div>
                </CardBody>
            </div>
            <div class="col-md-12 mt-4">
                <CardBody>
                    <div className={'row'}>
                        <div class="col-md-5 profile-report-texts">
                            {/*<ExcelButtons isActive={false}/>*/}
                        </div>
                        <div className="mt-4 table-responsive">
                            <CommonTable size={sizeTwo} page={pageTwo} handleLimitChange={handleLimitChangeTwo}
                                         handlePageChange={handlePageChangeTwo}
                                         total={MijozGuruxReducer.customerPay?.customerPreventedInfoDtoList.length}
                                         pagination={true} data={MijozGuruxReducer.customerPay?.customerPreventedInfoDtoList}
                                         columns={columnsTwo}
                                         scroll={{x: 1000, y: 330}}/>
                        </div>
                    </div>
                </CardBody>
            </div>
            <div class="col-md-12 mt-4">
                <CardBody>
                    <div className={'d-flex justify-content-between align-items-start flex-column'}>
                        <p className={'profile-name'}>
                            <strong>Yakuniy hisobot</strong>
                        </p>
                    </div>
                    <div className='col-md-9 d-flex justify-content-between align-items-center mt-2'>
                        <p className={'profile-detail'} style={{fontSize: '24px'}}>Balans summa</p>
                        <strong
                            className={'profile-trade-sum'} style={{color: '#E02323'}}>{prettify(customerDto?.debt)} so'm</strong>
                    </div>
                    <div className='col-md-9 d-flex justify-content-between align-items-center'>
                        <p className={'profile-detail'} style={{fontSize: '24px'}}>To'langan Summa</p>
                        <strong
                            className={'profile-trade-sum'}>{prettify(MijozGuruxReducer.customerPay?.totalPaidSum)} so'm</strong>
                    </div>
                    <div className='col-md-9 d-flex justify-content-between align-items-center'>
                        <p className={'profile-detail'} style={{fontSize: '24px'}}>Jami Summa</p>
                        <strong
                            className={'profile-trade-sum'}
                            style={{color: '#05B06C'}}>{prettify(MijozGuruxReducer.customerPay?.totalSum)} so'm</strong>
                    </div>
                </CardBody>
            </div>

            <Modal isOpen={viewCheck} size={'lg'} toggle={() => setViewCheck(!viewCheck)}>
                <ModalHeader>
                    <div className="col-md-12 px-3 profile-report-texts d-flex gap-2 align-items-center flex-row">
                        <p>Savdo raqami:</p>
                        <strong className={'p-0 m-0'}>{viewTrade?.trade?.invoice}</strong>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-md-6 profile-report-texts d-flex align-items-center flex-row">
                            <p>Savdo sanasi:</p>
                            <strong>{(moment(new Date(viewTrade.createAt)).format('L')).replace(/\//g, ".")}</strong>
                        </div>
                        <div className="col-md-6 profile-report-texts d-flex align-items-center flex-row">
                            <p>To'lov turi:</p>
                            <strong>{viewTrade.trade?.payMethod?.type}</strong>
                        </div>
                        <div className="col-md-6 profile-report-texts d-flex align-items-center flex-row">
                            <p>To'lov Holati:</p>
                            <strong>{viewTrade.trade?.paymentStatus?.status}</strong>
                        </div>
                        <div className="col-md-6 profile-report-texts d-flex align-items-center flex-row">
                            <p>Jami summa:</p>
                            <strong>{viewTrade.trade?.totalSum} so'm</strong>
                        </div>
                        <div className="col-md-6 profile-report-texts d-flex align-items-center flex-row">
                            <p>To'langan summa:</p>
                            <strong>{viewTrade.trade?.paidSum} so'm</strong>
                        </div>
                        <div className="col-md-6 profile-report-texts d-flex align-items-center flex-row">
                            <p>Qarz summa:</p>
                            <strong>{viewTrade.trade?.debtSum} so'm</strong>
                        </div>
                        <div className="col-md-6 profile-report-texts d-flex align-items-center flex-row">
                            <p>Foyda</p>
                            <strong>{viewTrade.trade?.totalProfit} so'm</strong>
                        </div>
                    </div>
                    <div class="row">
                        <CommonTable total={viewTrade?.productCutomerDtoList?.length}
                                     pagination={false} data={viewTrade?.productCutomerDtoList}
                                     columns={columnsTradeProduct}
                                     scroll={{x: 1000, y: 330}}
                        />
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default connect((MijozGuruxReducer, users), {
    getCustomerOne,
    getCustomerTradeInfo,
    getCustomerPayType,
    getCustomerInfo
})(MijozProfil)
