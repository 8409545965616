import {useState, useRef, useEffect} from 'react';
import PageTitleWrapper from '../PageTitleWrapper';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import {connect} from "react-redux";
import {
    Grid,
    Drawer,
    Box,
    Card,
    Divider,
    useMediaQuery,
    styled,
    useTheme
} from '@mui/material';
import PageHeader from './PageHeader';
import Actions from './Actions';
import EventDrawer from './EventDrawer';
import users from "../../../../../reducer/users";
import TaskReducer, {getTaskAllByBranch} from "../../../../../reducer/TaskReducer";

const FullCalendarWrapper = styled(Box)(
    ({theme}) => `
    padding: ${theme.spacing(3)};
    & .fc-license-message {
      display: none;
    }
    .fc {

      .fc-col-header-cell {
        padding: ${theme.spacing(1)};
        background: ${theme.colors.alpha.black[5]};
      }

      .fc-scrollgrid {
        border: 2px solid ${theme.colors.alpha.black[10]};
        border-right-width: 1px;
        border-bottom-width: 1px;
      }

      .fc-cell-shaded,
      .fc-list-day-cushion {
        background: ${theme.colors.alpha.black[5]};
      }

      .fc-list-event-graphic {
        padding-right: ${theme.spacing(1)};
      }

      .fc-theme-standard td, .fc-theme-standard th,
      .fc-col-header-cell {
        border: 1px solid ${theme.colors.alpha.black[10]};
      }

      .fc-event {
        padding: ${theme.spacing(0.1)} ${theme.spacing(0.3)};
      }

      .fc-list-day-side-text {
        font-weight: normal;
        color: ${theme.colors.alpha.black[70]};
      }

      .fc-list-event:hover td,
      td.fc-daygrid-day.fc-day-today {
        background-color: ${theme.colors.primary.lighter};
      }

      td.fc-daygrid-day:hover,
      .fc-highlight {
        background: ${theme.colors.alpha.black[10]};
      }

      .fc-daygrid-dot-event:hover, 
      .fc-daygrid-dot-event.fc-event-mirror {
        background: ${theme.colors.primary.lighter};
      }

      .fc-daygrid-day-number {
        padding: ${theme.spacing(1)};
        font-weight: bold;
      }

      .fc-list-sticky .fc-list-day > * {
        background: ${theme.colors.alpha.black[5]} !important;
      }

      .fc-cell-shaded, 
      .fc-list-day-cushion {
        background: ${theme.colors.alpha.black[10]} !important;
        color: ${theme.colors.alpha.black[70]} !important;
      }

      &.fc-theme-standard td, 
      &.fc-theme-standard th,
      &.fc-theme-standard .fc-list {
        border-color: ${theme.colors.alpha.black[30]};
      }
    }
`
);

function ApplicationsCalendar({getTaskAllByBranch, users, TaskReducer}) {
    const theme = useTheme();
    const calendarRef = useRef(null);
    const mobile = useMediaQuery(theme.breakpoints.down('md'));
    const [date, setDate] = useState(new Date());
    const [view, setView] = useState(mobile ? 'listWeek' : 'dayGridMonth');

    const handleDateToday = () => {
        const calItem = calendarRef.current;
        if (calItem) {
            const calApi = calItem.getApi();
            calApi.today();
            setDate(calApi.getDate());
        }
    };

    const changeView = (changedView) => {
        const calItem = calendarRef.current;
        if (calItem) {
            const calApi = calItem.getApi();
            calApi.changeView(changedView);
            setView(changedView);
        }
    };

    const handleDatePrev = () => {
        const calItem = calendarRef.current;
        if (calItem) {
            const calApi = calItem.getApi();
            calApi.prev();
            setDate(calApi.getDate());
        }
    };

    const handleDateNext = () => {
        const calItem = calendarRef.current;
        if (calItem) {
            const calApi = calItem.getApi();
            calApi.next();
            setDate(calApi.getDate());
        }
    };

    const [viewTask, setViewTask] = useState(null)
    const handleEventSelect = (arg) => {
        setIsDrawerOpen(true)
        let a = TaskReducer.taskAll.filter(item => item.id ===arg.event.id)
        setViewTask({...a[0]})
    };
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [task, setTask] = useState([])
    const closeDrawer = () => {
        setIsDrawerOpen(false)
    };

    useEffect(() => {
        if (TaskReducer.taskAll) {
            let a = []
            TaskReducer.taskAll?.map(item => {
                a.push(
                    {
                        id: item.id,
                        allDay: false,
                        color: item?.taskStatus?.color,
                        description: '',
                        end: new Date(item.deadLine),
                        start: new Date(item.startDate),
                        title: item.name
                    }
                )
            })
            setTask(a)
        }
    }, [TaskReducer.getBoolean])

    useEffect(() => {
        users.getTaskChecked ? getTaskAllByBranch({branchId: users.branchId,params: {userId: null}
    }) : getTaskAllByBranch({branchId: users.branchId,params: {userId: users.id}})
    }, []);

    useEffect(() => {
        const calItem = calendarRef.current;
        if (calItem) {
            const calApi = calItem.getApi();
            const changedView = mobile ? 'listWeek' : 'dayGridMonth';
            calApi.changeView(changedView);
            setView(changedView);
        }
    }, [mobile]);
    return (
        <>
            <title>Calendar - Applications</title>
            <PageTitleWrapper>
                <PageHeader/>
            </PageTitleWrapper>
            <Grid
                sx={{
                    px: 4
                }}
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                spacing={4}
            >
                <Grid item xs={12}>
                    <Card>
                        <Box p={3}>
                            <Actions
                                date={date}
                                onNext={handleDateNext}
                                onPrevious={handleDatePrev}
                                onToday={handleDateToday}
                                changeView={changeView}
                                view={view}
                            />
                        </Box>
                        <Divider/>
                        <FullCalendarWrapper sx={{padding:'10px',width:'100%'}}>
                            <FullCalendar
                                allDayMaintainDuration
                                initialDate={date}
                                initialView={view}
                                eventDisplay="block"
                                eventClick={handleEventSelect}
                                dayMaxEventRows={4}
                                eventResizableFromStart
                                events={task}
                                headerToolbar={false}
                                height={660}
                                ref={calendarRef}
                                rerenderDelay={10}
                                plugins={[
                                    dayGridPlugin,
                                    timeGridPlugin,
                                    interactionPlugin,
                                    listPlugin
                                ]}
                            />
                        </FullCalendarWrapper>
                    </Card>
                </Grid>
            </Grid>
            <Drawer
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'left' : 'right'}
                onClose={closeDrawer}
                open={isDrawerOpen}
                elevation={9}
            >
                {isDrawerOpen && (
                    <EventDrawer
                        event={viewTask}
                        onCancel={closeDrawer}
                    />
                )}
            </Drawer>
        </>
    );
}

export default connect((users, TaskReducer), {getTaskAllByBranch})(ApplicationsCalendar);
