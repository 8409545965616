import '../BarcaSavdolar/barcasavdolar.css'
import React, {useState, useEffect, useRef} from "react";
import {connect} from "react-redux";
import users from "../../../../../reducer/users";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {useTranslation} from "react-i18next";
import formatDate from "../../../../../util";
import Loading from "../../../../Loading";
import lossReducer,{getLossProduct,getLossProductOne} from "../../../../../reducer/lossReducer";
import {Box, TablePagination} from "@mui/material";
import Korish from "../../../../../img/Korish.png";

function LossProducts({ lossReducer,
                          getLossProduct,
                          getLossProductOne,
                          users}) {

    const {t} = useTranslation()
    const [pageData,setPageData] = useState(0)
    const [sizeData,setSizeData] = useState(5)
    const [mainBranch,setMainBranch] = useState(null)



    useEffect(() => {
        getLossProduct({
            branchId:mainBranch ? mainBranch : users.branchId,
            params:{
                page:pageData,
                size:sizeData
            }
        })
    }, [mainBranch,pageData,sizeData])

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
    }, [lossReducer.getBoolean])

    useEffect(() => {
        setLoading(false)
    }, [])

    const [deletemodal, setdeletemodal] = useState(false)
    const [deleteID, setdeletID] = useState('')

    function deleteFunc() {
        deleteModaltoggle('')
    }

    function deleteModaltoggle(item) {
        setdeletemodal(!deletemodal)
        setdeletID(item)
    }

    const onShowSizeChange = (event) => {
        setSizeData(parseInt(event.target.value))
    };
    const changePage = (_event, newPage) => {
        setPageData(newPage)
    };

    const [viewOneLoss,setViewOneLoss] = useState(false)


    function viewOneLossToggle(){
        setViewOneLoss(!viewOneLoss)
    }

    function getOneById(id){
        getLossProductOne(id)
        viewOneLossToggle()
    }





    return (
        <div className="col-md-12 mt-2 mb-4 mt-4 ">
            <div className="textHeader">
                <h2>{t('trade.yqtm')}</h2>
            </div>

            <div className="rowStyleH">
                <div className="row cont">
                    <div className="col-md-6">
                        <h6>{t('ProductList.8')}:</h6>
                        <select name="" className={'form-control'} value={mainBranch ? mainBranch : users.branchId}
                                onChange={(e)=>setMainBranch(e.target.value)} id="">
                            {
                                users.branches?.map(item => <option value={item.id}>{item.name}</option>)
                            }
                        </select>
                    </div>
                </div>
            </div>

            <div className="rowStyleH2">
                {

                    loading ?
                        lossReducer.lossProduct ?
                            <div>
                                <div className="table-responsive table-wrapper-scroll-y my-custom-scrollbar" style={{maxHeight:'300px'}}>
                                    <table className='table table-striped table-bordered mt-4 '>
                                        <thead>
                                        <tr>
                                            <th>T/R</th>
                                            <th>{t('trade.xodim')}</th>
                                            <th>{t('Salary.s')}</th>
                                            <th>{t('Hamkorlar.amal')}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            lossReducer.lossProduct?.list.map((item, index) => <tr key={item?.id}>
                                                <td>{index + 1}</td>
                                                <td>{item?.userName}</td>
                                                <td>{formatDate(item?.date)}</td>
                                                <td>
                                                    <button className='korish' onClick={()=>getOneById(item.id)}><img src={Korish} alt=""/> {t('Buttons.4')}
                                                    </button>
                                                </td>
                                                {/*<td>*/}

                                                {/*</td>*/}
                                            </tr>)
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="mt-3 pagination" >
                                    <Box p={2}>
                                        <TablePagination
                                            component="div"
                                            count={lossReducer.lossProduct.totalItems}
                                            onPageChange={changePage}
                                            onRowsPerPageChange={onShowSizeChange}
                                            page={pageData}
                                            rowsPerPage={sizeData}
                                            rowsPerPageOptions={[5, 8, 10]}
                                        />
                                    </Box>
                                </div>
                            </div> :
                            <div className={'border border-2'}>
                                <h4 className={'text-center'}>{lossReducer.message || t('Darslik.mtt')}</h4>
                            </div> : <Loading/>

                }
                <Modal isOpen={deletemodal} toggle={deleteModaltoggle} >
                    <ModalBody>
                        <h5>{t('Buttons.12')} ?</h5>
                    </ModalBody>
                    <ModalFooter>
                        <button onClick={deleteFunc}
                                className={'btn btn-outline-primary'}>{t('Buttons.3')}</button>
                        <button onClick={() => deleteModaltoggle('')}
                                className={'btn btn-outline-primary'}>{t('Buttons.7')}</button>
                    </ModalFooter>
                </Modal>

            </div>

            <Modal isOpen={viewOneLoss}  toggle={()=>setViewOneLoss(!viewOneLoss)}>
                <ModalHeader>
                    <h4>
                        {t('trade.mk')}
                    </h4>
                </ModalHeader>
                <ModalBody>

                    {
                        lossReducer.oneLossProduct ?
                            <table className={'table table-bordered'}>
                                <thead>
                                <tr>
                                    <th>Mahsulot</th>
                                    <th>Miqdori</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    lossReducer.oneLossProduct.map(item=>
                                        <tr>
                                            <th>{item.name}</th>
                                            <th>{item.quantity} {item.measurement}</th>
                                        </tr>
                                    )
                                }

                                </tbody>
                            </table>:<div><h4 className={'text-center'}>{t('Darslik.mtt')}</h4></div>
                    }
                </ModalBody>
                <ModalFooter>
                    <button className={'btn btn-danger'} onClick={()=>setViewOneLoss(!viewOneLoss)}>{t('Hamkorlar.chiqish')}</button>
                </ModalFooter>
            </Modal>

        </div>

    )
}

export default connect((users,lossReducer), {getLossProduct,getLossProductOne})(LossProducts)
