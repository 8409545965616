import './xaridxisobot.css'
import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import './xaridxisobot.css'
import XaridReducer from "../../Haridlar/reducer/XaridReducer";
import formatDate, {prettify} from "../../../../../util";
import branchreducer from "../../../../../reducer/branchreducer";
import users from "../../../../../reducer/users";
import TaminotReducer, {getTaminot} from "../../Hamkorlar/reducer/TaminotReducer";
import MaxsulotlarRoyxariReducer from "../../Maxsulotlar/reducer/MaxsulotlarRoyxariReducer";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {useTranslation} from "react-i18next";
import XaridlarXisobotiReducer, {
    getOneXaridXisobot, getTopSuplier,
    getXaridXisobot,
} from "../reducer/XaridlarXisobotiReducer";
import accountreducer, {getAccount, getAccountAll, getAccountDate} from "../../../../../reducer/accountreducer";
import {DatePicker} from "antd";
import formatDateYear from "../../../../../reducer/sana";
import Loading from "../../../../Loading";
import Block3 from "./Block3";
import {Grid} from "@mui/material";
import Block6 from "./Block6";
import Block5 from "./Block5";
import MainHeaderText from "../../../../Components/MainHeaderText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuItem from "@mui/material/MenuItem";
import CardBody from "../../../../Components/CardBody";
import CommonTable from "../../../../Components/CommonTable";
import moment from "moment";
import ExcelButtons from "../../../../Components/ExcelButtons";

const {RangePicker} = DatePicker

function XaridlarXisoboti({
                              getAccountAll, getAccount, getOneXaridXisobot, getAccountDate, getTopSuplier,
                              users, XaridlarXisobotiReducer, getXaridXisobot, TaminotReducer, getTaminot
                          }) {
    const {t} = useTranslation()
    const [input, setInput] = useState(
        {
            mahsulotizlash: '',
            view: '',
            izlash: '',
            eslatma: '',
            qarz: ''
        }
    )

    const columns = [
        {
            title: 'Id',
            dataIndex: 'index',
            width: '3%',
        },
        {
            title: t('block.m'),
            dataIndex: 'name',
            key: 'name',
            width: '10%',

        },
        {
            title: t('Purchase.2'),
            dataIndex: 'supplier',
            key: 'supplier',
            width: '10%',

        },
        {
            title: t('xisob.xaridsana'),
            dataIndex: 'purchasedDate',
            key: 'purchasedDate',
            render: (item) => <p className={'m-0'}>{moment(new Date(item)).format('lll')}</p>,
            width: '10%',
        },
        {
            title: t('xisob.ChECK'),
            dataIndex: 'purchaseId',
            key: 'purchaseId',
            render: (item) => <a className={'m-0'} onClick={() => checktoggle(item)}>Check</a>,
            width: '10%',
        },

        {
            title: t('xisob.xaridm'),
            dataIndex: 'purchasedAmount',
            key: 'purchasedAmount',
            width: '12%',

        },
        {
            title: t('Supplier.8'),
            dataIndex: 'debt',
            key: 'debt',
            render: (item) => <p className={'m-0'}>{prettify(item, 3)} so'm</p>,
            width: '10%',
        },
        {
            title: t('xisob.sotibon'),
            dataIndex: 'buyPrice',
            key: 'buyPrice',
            render: (item) => <p className={'m-0'}>{prettify(item, 3)} so'm</p>,
            width: '10%',
        },
    ];


    const [branch, setbranch] = useState(null)
    const [supplier, setSupplier] = useState(null)
    const [dates, setDates] = useState(null)


    function selectBranch(e) {
        setbranch(e.target.value)
        getAccountAll({
            branchId: e.target.value,
            date: "ALL"
        })
        getXaridXisobot({
            branchId: e.target.value,
            supplier: supplier === "ALL" ? null : supplier,
            startDate: dates ? dates[0] : null,
            endDate: dates ? dates[1] : null
        })
    }

    function selectSupplier(e) {
        setSupplier(e.target.value)
        getXaridXisobot({
            branchId: branch ? branch : users.branchId,
            supplier: e.target.value === "ALL" ? null : e.target.value,
            startDate: dates ? dates[0] : null,
            endDate: dates ? dates[1] : null
        })
    }


    function izlash(e) {
        input.izlash = e.target.value
        let a = {...input}
        setInput(a)
    }

    const [loading, setLoading] = useState(false)
    useEffect(() => {
        getAccount(users.branchId)
        getAccountAll({
            branchId: branch ? branch : users.branchId,
            date: "ALL"
        })
    }, [])

    useEffect(() => {
        getTaminot(users.businessId)
        getTopSuplier({
            branchId: users.branchId, startDate: null,
            endDate: null
        })
        getXaridXisobot({
            branchId: users.branchId,
            supplier: null,
            startDate: null,
            endDate: null
        })
    }, [])

    useEffect(() => {
        setLoading(true)
    }, [XaridlarXisobotiReducer.getBoolean])

    useEffect(() => {
        setLoading(false)
    }, [])

    const [check, setCheck] = useState(false)

    function checktoggle(id) {
        setCheck(!check)
        if (!check) {
            getOneXaridXisobot(id)
        }
    }

    return (
        <div className="col-md-12 mt-4 mb-4">
            <div class="row p-3">
            <MainHeaderText text={t('PurchaseList.1')}/>
            </div>
            <div className="row px-2 align-items-center my-4">
                <div className="col-md-4">
                    <FormControl sx={{m: 1, minWidth: '100%'}} size="small">
                        <InputLabel id="demo-select-small-label">{t('cost.baza')}</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={branch}
                            label={t('cost.baza')}
                            IconComponent={KeyboardArrowDownIcon}
                            onChange={selectBranch}
                        >
                            <MenuItem value="">
                                <em>{t('num.25')}</em>
                            </MenuItem>
                            {
                                users.branches.map(item =>
                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </div>
                <div className="col-md-4">
                    <FormControl sx={{m: 1, minWidth: '100%'}} size="small">
                        <InputLabel id="demo-select-small-label">{t('Purchase.2')}</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={supplier}
                            label={t('Purchase.2')}
                            IconComponent={KeyboardArrowDownIcon}
                            onChange={selectSupplier}
                        >
                            <MenuItem value="ALL">
                                <em>Barchasi</em>
                            </MenuItem>
                            {
                                TaminotReducer.taminot ?
                                    TaminotReducer.taminot.map(item =>
                                        <MenuItem value={item.id}>{item.name}</MenuItem>) : <MenuItem value="">
                                        <em>Barchasi</em>
                                    </MenuItem>
                            }
                        </Select>
                    </FormControl>
                </div>
                <div className="col-md-4">
                    <RangePicker style={{width: '100%'}} onChange={(values) => {
                        let a = []
                        if (values !== null) {
                            values.map(item => {
                                a.push(formatDateYear(item))
                            })
                            setDates(a)
                            getTopSuplier({
                                branchId: users.branchId, startDate: a[0],
                                endDate: a[1]
                            })
                            getAccountDate({
                                branchId: branch ? branch : users.branchId,
                                startDate: a[0],
                                endDate: a[1]
                            })
                            getXaridXisobot({
                                branchId: branch ? branch : users.branchId,
                                startDate: a ? a[0] : null,
                                endDate: a ? a[1] : null,
                                supplier: supplier === "ALL" ? null : supplier
                            })
                        } else {
                            setDates(null)
                            getAccountAll({
                                branchId: branch ? branch : users.branchId,
                                date: "ALL"
                            })
                            getTopSuplier({
                                branchId: users.branchId, startDate: null,
                                endDate: null
                            })
                            getXaridXisobot({
                                branchId: branch ? branch : users.branchId,
                                startDate: null,
                                endDate: null,
                                supplier: supplier === "ALL" ? null : supplier
                            })
                        }
                    }
                    }/>
                </div>
            </div>
            <div>
                {/*<Grid*/}
                {/*    style={{paddingTop:"30px"}}*/}
                {/*    sx={{*/}
                {/*        px: 4*/}
                {/*    }}*/}
                {/*    container*/}
                {/*    direction="row"*/}
                {/*    justifyContent="center"*/}
                {/*    alignItems="stretch"*/}
                {/*    spacing={4}*/}
                {/*>*/}
                {/*    <Grid item sm={6} xs={12}>*/}
                {/*        <Block3 />*/}
                {/*    </Grid>*/}
                {/*    <Grid item sm={6} xs={12}>*/}
                {/*        <Block6 />*/}
                {/*       <div style={{paddingTop:"36px"}}>  <Block5 /></div>*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}
            </div>
            <div class="row p-3">
                <div class="col-md-12">
                    <CardBody>
                        <div className={'col-md-12'}>
                            <ExcelButtons/>
                            <div className="table-responsive mb-4">
                                {XaridlarXisobotiReducer.xaridxisobot ?
                                    loading ?
                                        <>
                                            <CommonTable size={0} page={XaridlarXisobotiReducer.xaridxisobot?.length}
                                                         pagination={false} data={XaridlarXisobotiReducer.xaridxisobot}
                                                         columns={columns}
                                            />
                                        </>

                                        : <Loading/> : <div>
                                        <h4 className={'text-center'}>{XaridlarXisobotiReducer.message}</h4>
                                    </div>
                                }
                            </div>
                        </div>
                    </CardBody>
                </div>
            </div>
            <Modal isOpen={check} toggle={checktoggle} size={'xl'}>
                <ModalHeader>
                    {t('xisob.savdom')}
                </ModalHeader>
                <ModalBody>
                    <div className={'table-responsive'}>
                        <table className={'table table-striped table-primary table-hover border border-1'}>
                            <thead>
                            <tr>
                                <th>T/R</th>
                                <th>{t('Purchase.2')}</th>
                                <th>{t('Buttons.14')}</th>
                                <th>{t('Salary.f')}</th>
                                <th>{t('Trade.4')}</th>
                                <th>{t('xisob.Haridstatusi')}</th>
                                <th>{t('xisob.tulovh')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                XaridlarXisobotiReducer.xaridOne ?
                                    XaridlarXisobotiReducer.xaridOne.map((item, index) =>
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{item?.supplier?.name}</td>
                                            <td>{item.supplier?.phoneNumber}</td>
                                            <td>{item.branch?.name}</td>
                                            <td>{formatDate(item.date)}</td>
                                            <td>{item?.purchaseStatus?.status}</td>
                                            <td>{item?.paymentStatus?.status}</td>
                                        </tr>
                                    ) : ''
                            }
                            </tbody>
                        </table>

                    </div>

                    <div className={'table-responsive'}>
                        <table className={'table mt-2 border border-1 table-striped table-hover'}>
                            <thead>
                            <tr>
                                <th>T/R</th>
                                <th>{t('block.m')}</th>
                                <th>{t('ExpenseReport.7')}</th>
                                {/*<th>Filial</th>*/}
                                <th>{t('Purchase.8')}</th>
                                <th>{t('xiosb.Chegirma')}</th>
                                <th>{t('block.sn')}</th>
                                <th>{t('xisob.Avans')}</th>
                                <th>{t('block.qarz')}</th>
                                <th>{t('Trade.14')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                XaridlarXisobotiReducer.xaridTwo ?
                                    XaridlarXisobotiReducer.xaridTwo.map((item, index) => <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.product ? item.product?.name : item.productTypePrice?.name}</td>
                                        <td>{item.product ? item.product?.barcode : item.productTypePrice?.barcode}</td>
                                        <td>{item.buyPrice} {t('Hamkorlar.sum')}</td>
                                        <td>-</td>
                                        <td>{item.salePrice} {t('Hamkorlar.sum')}</td>
                                        <td>{item?.purchase.paidSum} {t('Hamkorlar.sum')}</td>
                                        <td>{item.purchase.debtSum} {t('Hamkorlar.sum')}</td>
                                        <td>{item?.purchase.totalSum} {t('Hamkorlar.sum')}</td>
                                    </tr>) : ''
                            }
                            </tbody>
                        </table>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button onClick={checktoggle} className={'btn btn-outline-primary'}>{t('Buttons.7')}</button>
                </ModalFooter>
            </Modal>

        </div>
    )
}

export default connect((XaridReducer, XaridlarXisobotiReducer, accountreducer, branchreducer, users, TaminotReducer, MaxsulotlarRoyxariReducer),
    {
        getXaridXisobot,
        getAccountDate,
        getTopSuplier,
        getOneXaridXisobot,
        getAccount,
        getTaminot,
        getAccountAll,
    })(XaridlarXisoboti)
