import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../../../../../api";
// import {toast} from "react-toastify";

const slice = createSlice({
    name: 'maxsulotxisobot',
    initialState: {
        maxsulotxisobot: null,
        productPage:null,
        message:''
    },
    reducers: {
        getFrom: (state, action) => {
            if (action.payload.success){
                state.maxsulotxisobot = action.payload.object
            }
            else{
                state.message = action.payload.message
                state.maxsulotxisobot = null
            }
        },
        getFromPage: (state, action) => {
            if (action.payload.success){
                state.productPage = action.payload.object
            }
            else{
                state.message = action.payload.message
                state.productPage = null
            }
        }
    }
});

export const getMaxsulotxisobot=(data)=>apiCall({
    url: '/reports/most-un-sale/'+data,
    method:'get',
    onSuccess: slice.actions.getFrom.type,
    onFail:slice.actions.getFrom.type
});


export const getMaxsulotxisobot2=(data)=>apiCall({
    url: `/reports/products-report/${data.branchId}/?${data.customer ? `customerId=${data.customer}`:''}&date=${data.date}${data.startDate ? `&startDate=${data.startDate}&endDate=${data.endDate}`:''}`,
    method:'get',
    onSuccess: slice.actions.getFrom.type,
    onFail:slice.actions.getFrom.type
});
export const getProductAboutSell=(data)=>apiCall({
    url: '/warehouse/less-sold/'+data.branchId,
    method:'get',
    params:data.params,
    onSuccess: slice.actions.getFromPage.type,
    onFail:slice.actions.getFromPage.type
});


export default slice.reducer