import {connect} from "react-redux";
import {Row, Space, Select, Checkbox} from "antd";
import {useTranslation} from "react-i18next";
import moment from "moment";
import 'moment/locale/uz-latn'
import {prettify} from "../../../../../../../util";
import CommonTable from "../../../../../../Components/CommonTable";
import ExcelButtons from "../../../../../../Components/ExcelButtons";
import accountreducer from "../../../../../../../reducer/accountreducer";
import MijozHisobotiReducer, {getMijozhisobotsana} from "../../../reducer/MijozHisobotiReducer";
import MijozGuruxReducer, {getMijozGuruxSearchByBranch} from "../../../../Hamkorlar/reducer/MijozGuruxReducer";
import './savdolar.css'
import {useState} from "react";

const { Option} = Select

function Savdolar1({
      accountreducer,
      MijozHisobotiReducer,
      MijozGuruxReducer,
      getMijozGuruxSearchByBranch,
      getMijozhisobotsana,
      pageData,
      setPageData,
      onChange,
      loading
  }) {
    const {t} = useTranslation();
    const [openCheckList, setOpenCheckList] = useState(false)
    const columns = [
        {
            key: "id",
            title: 'Id',
            dataIndex: 'index',
            width: '3%',
            fixed: 'left',
        },
        {
            title: t('Davomat.hodim'),
            dataIndex: 'sellerName',
            key: 'sellerName',
            width: '5%',
        },
        {
            title: t('PurchaseList.6'),
            dataIndex: 'customerName',
            key: 'customerName',
            width: '5%',
        },
        {
            title: t('xisob.tulovt'),
            dataIndex: 'paymentStatus',
            key: 'paymentStatus',
            width: '10%',
            render: (item, record) => <div className="d-flex align-items-center gap-2">
              {item === "TOLANGAN" ?
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd"
                        d="M8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.85786 12.1421 0.5 8 0.5C3.85786 0.5 0.5 3.85786 0.5 8C0.5 12.1421 3.85786 15.5 8 15.5ZM11.444 6.09537C11.6347 5.85015 11.5906 5.49674 11.3453 5.30602C11.1001 5.11529 10.7467 5.15946 10.556 5.40468L7.55074 9.26858C7.48392 9.3545 7.35822 9.36565 7.27731 9.29283L5.3763 7.58192C5.14539 7.3741 4.78972 7.39282 4.5819 7.62373C4.37408 7.85464 4.3928 8.21031 4.62371 8.41813L6.52472 10.129C7.09107 10.6388 7.97098 10.5607 8.43876 9.95926L11.444 6.09537Z"
                        fill="url(#paint0_linear_878_8045)"/>
                  <defs>
                    <linearGradient id="paint0_linear_878_8045" x1="8" y1="0.5" x2="8" y2="15.5"
                                    gradientUnits="userSpaceOnUse">
                      <stop stopColor="#21EB66"/>
                      <stop offset="1" stopColor="#12D583"/>
                    </linearGradient>
                  </defs>
                </svg> : item === "TOLANMAGAN" ?
                  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M6.47599 1.12444C7.14024 -0.0414791 8.85976 -0.0414812 9.52401 1.12444L15.2781 11.2243C15.9237 12.3575 15.0834 13.75 13.7541 13.75H2.24591C0.916594 13.75 0.0763281 12.3575 0.721903 11.2243L6.47599 1.12444ZM8.74995 10.75C8.74995 11.1642 8.41417 11.5 7.99995 11.5C7.58574 11.5 7.24995 11.1642 7.24995 10.75C7.24995 10.3358 7.58574 10 7.99995 10C8.41417 10 8.74995 10.3358 8.74995 10.75ZM8.56245 4.75C8.56245 4.43934 8.31061 4.1875 7.99995 4.1875C7.68929 4.1875 7.43745 4.43934 7.43745 4.75V8.5C7.43745 8.81066 7.68929 9.0625 7.99995 9.0625C8.31061 9.0625 8.56245 8.81066 8.56245 8.5V4.75Z"
                          fill="#E02323"/>
                  </svg> : <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M9.97708 0.82583C12.5301 1.52169 14.5408 3.53243 15.2367 6.08542C15.5271 7.15112 14.6046 8.0625 13.5 8.0625H10C8.89543 8.0625 8 7.16707 8 6.0625V2.5625C8 1.45793 8.91138 0.53536 9.97708 0.82583ZM0.5 8.81251C0.5 5.21043 3.3215 2.26734 6.875 2.07276V6.56254C6.875 8.01228 8.05025 9.18754 9.5 9.18754H13.9898C13.7952 12.741 10.8521 15.5625 7.25 15.5625C3.52208 15.5625 0.5 12.5404 0.5 8.81251Z"
                          fill="#28303F"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M9.97708 0.82583C12.5301 1.52169 14.5408 3.53243 15.2367 6.08542C15.5271 7.15112 14.6046 8.0625 13.5 8.0625H10C8.89543 8.0625 8 7.16707 8 6.0625V2.5625C8 1.45793 8.91138 0.53536 9.97708 0.82583ZM0.5 8.81251C0.5 5.21043 3.3215 2.26734 6.875 2.07276V6.56254C6.875 8.01228 8.05025 9.18754 9.5 9.18754H13.9898C13.7952 12.741 10.8521 15.5625 7.25 15.5625C3.52208 15.5625 0.5 12.5404 0.5 8.81251Z"
                          fill="url(#paint0_linear_901_2758)"/>
                    <defs>
                      <linearGradient id="paint0_linear_901_2758" x1="7.89538" y1="0.771744" x2="7.89538" y2="15.5625"
                                      gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FFBC0E"/>
                        <stop offset="1" stopColor="#FF7C1E"/>
                      </linearGradient>
                    </defs>
                  </svg>

              }
              <span>
                    {`${item} ${item !== "TOLANMAGAN" ? `(${record?.payMethod})` : ""}`}
                </span>
            </div>,
        },
      {
        title: t('cost.baza'),
        dataIndex: 'branchName',
        key: 'branchName',
        width: '7%',
      },
      {
        title: t('block.js'),
        dataIndex: 'totalSum',
        key: 'totalSum',
        render: (item) => <p className={'m-0'}>{prettify(item, 3)} so'm</p>,
        width: '10%',
      },
      {
        title: t('block.ts'),
        dataIndex: 'paidSum',
        key: 'paidSum',
        render: (item) => <p className={'m-0'}>{prettify(item, 3)} so'm</p>,
        width: '12%',
      },
      {
        title: t('xisob.qoldiqq'),
        dataIndex: 'debt',
        key: 'debt',
        render: (item) => <p className={'m-0'}>{prettify(item, 3)} so'm</p>,
        width: '10%',
      },
      {
        title: t('xisob.Foyda'),
        dataIndex: 'profit',
        key: 'profit',
        render: (item) => <p className={'m-0'}>{prettify(item, 3)} so'm</p>,
        width: '10%',
      },
      {
        title: t('Salary.s'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (item) => <p className={'m-0'}>{moment(new Date(item)).format('lll')}</p>,
            width: '8%',

        },
    ];
    const defaultCheckedList = columns.map((item) => item.key);
    const [checkedList, setCheckedList] = useState(defaultCheckedList);
    const options = columns.map(({ key, title }) => ({
      label: title,
      value: key,
    }));
    const newColumns = columns.map((item) => ({
      ...item,
      hidden: !checkedList.includes(item.key),
    }));

    const newChange = (pageNumber,page) => {
        onChange(pageNumber,page)
    }

    return (<div className={'p-3'}>
        <div className={'col-md-12'}>
          <div className="d-flex align-items-center mb-4">
            <h3 className={'customer-debt'}>{t('Mijozlarning jami qarzi')}: <span
              className={'customer-debt-sum'}>({accountreducer.Purchases?.tradersDebt} so'm)</span>
            </h3>
          </div>
          <Row gutter={[0, 24]}>
            <Space className="w-100 mb-3" size="large" direction="vertical">
              <ExcelButtons setOpenCheckList={setOpenCheckList}/>
              <Select
                showSearch
                allowClear
                className="w-100 searchCustomerSelect"
                placeholder="Mijoz bo'yicha qidirish..."
                optionFilterProp="children"
                onSearch={(input) => {
                  input && getMijozGuruxSearchByBranch({branchId: pageData.branch, name: input})
                }}
                onChange={(value) => {
                  setPageData((prev) => ({
                    ...prev,
                    page: 0,
                    customer: value ? value : null,
                    dates: null,
                    dateValue: null,
                  }))
                  getMijozhisobotsana({
                    branchId: pageData.branch,
                    page: 0,
                    size: pageData.pageSize,
                    customer: value ? value : null,
                    seller: pageData.seller,
                    startDate: null,
                    endDate: null
                  })
                }}
              >
                {
                  MijozGuruxReducer.mijozgurux?.map(item => <Option key={item.id}
                                                                    value={item.id}>{item.name}</Option>)
                }
              </Select>
            </Space>
          </Row>
          <div className={`${openCheckList ? "animatsiyaBlock mb-2" : "hiddenBlock"}`}>
            <Checkbox.Group
              value={checkedList}
              options={options}
              onChange={(value) => {
                setCheckedList(value);
              }}
            />
          </div>
          <div className="mb-4">
            <CommonTable loading={loading} scroll={{y: 'max-content', x: 1200}}
                         total={MijozHisobotiReducer?.mijozhisobot2?.totalItems} handlePageChange={newChange}
                         pagination={true} page={pageData.page} size={pageData.pageSize}
                         data={MijozHisobotiReducer?.mijozhisobot2?.all} columns={newColumns}/>
          </div>
        </div>
      </div>
    )
}

export default connect((accountreducer, MijozHisobotiReducer, MijozGuruxReducer), {
  getMijozGuruxSearchByBranch,
  getMijozhisobotsana,
})(Savdolar1)
