import './third.css'
import {useHistory} from 'react-router-dom'
import React, {useEffect} from 'react';
import {useState} from "react";
import {connect} from "react-redux";
import Chart from 'react-apexcharts'
import users, {savdooynasi} from "../../../reducer/users";
import SavdoQoshishReducer, {getSavdolar} from "../Sidebar/Savdo/reducer/SavdoQoshishReducer";
import XaridReducer, {getXaridCost} from "../Sidebar/Haridlar/reducer/XaridReducer";
import MaxsulotlarRoyxariReducer, {
    getMaxsulotRuyxati
} from "../Sidebar/Maxsulotlar/reducer/MaxsulotlarRoyxariReducer";
import TaminotReducer, {getTaminot} from "../Sidebar/Hamkorlar/reducer/TaminotReducer";
import ValyutaReducer from "../Sidebar/Settings/DukonSozlamalari/reducers/ValyutaReducer";
import {useTranslation} from "react-i18next";
import accountreducer, {
    getAccount,
    getAccountAll, getAccountAllBusiness,
    getAccountDate, getOutlayTradeBusiness,
    getOutlayTrade,
    getPay, getAccountDateBusiness
} from "../../../reducer/accountreducer";
import {DatePicker, Segmented, Progress} from 'antd'
import formatDateYear from "../../../reducer/sana";
import branchreducer, {getbranch} from "../../../reducer/branchreducer";
import formatDate, {prettify} from "../../../util";
import XarajatXisobotReducer, {getXarajatlarxisobot} from "../Sidebar/Xisobotlar/reducer/XarajatXisobotReducer";
import titleReducer, {getTitle} from "../../../reducer/titleReducer";
import Select from "react-select";
import axios from "axios";
import ReportsReducer, {getCashKassa, getStatics} from "../Sidebar/Xisobotlar/reducer/ReportsReducer";
import {useMediaQuery} from "react-responsive";
import banner2 from "../../../assets/banner 2.png"
import arrow from "../../../assets/chart-arrow-up.svg"
import promo_money from "../../../assets/promo_money_1__1_-removebg-preview.png"
import qopdollor from "../../../assets/qopdollor.png"
import dollor from "../../../assets/dollor.png"
import packagekarob from "../../../assets/package.png"


const {RangePicker} = DatePicker

function Third({
                   display,
                   users,
                   getXaridCost,
                   ValyutaReducer,
                   getAccountDate,
                   getbranch,
                   getXarajatlarxisobot,
                   getOutlayTradeBusiness,
                   accountreducer,
                   getOutlayTrade,
                   getPay,
                   getAccountAll,
                   getAccountAllBusiness,
                   getTaminot,
                   getMaxsulotRuyxati,
                   getSavdolar,
                   titleReducer,
                   getTitle,
                   getAccountDateBusiness,
                   getCashKassa,
                   ReportsReducer,
                   getStatics
               }) {
    const {t} = useTranslation()
    const history = useHistory()
    const [input, setinput] = useState({
        branchvalue: null
    })
    const filialSelect = users.viewInfoAdmin ? [{value: 'ALL', label: t('as.36')},
            ...users.branches?.map((item) => ({value: item.id, label: item.name}))] :
        users.branches?.map((item) => ({value: item.id, label: item.name}))

    useEffect(() => {
        // getSavdolar(users.businessId)
        getXaridCost(users.businessId)
        getTaminot(users.businessId)
    }, [ValyutaReducer.current])

    useEffect(() => {
        // getAccount(users.branchId)
        getOutlayTrade(users.branchId)
        getPay(users.branchId)
        getTitle()
    }, [])

    function Dates(value) {
        users.viewInfoAdmin ? (input.branchvalue === "ALL" ? getAccountAllBusiness({
            businessId: users.businessId,
            date: value
        }) : getAccountAll({
            branchId: input.branchvalue ? input.branchvalue : users.branchId,
            date: value
        })) : getAccountAll({
            branchId: input.branchvalue ? input.branchvalue : users.branchId,
            date: value
        })
        getStatics({
            businessId: users.businessId,
            params: {
                branchId: input.branchvalue ? input.branchvalue : users.branchId,
                date: value,
            }
        })
    }

    const germanCurrencyFormatter = new Intl.NumberFormat("uz-UZ", {notation: "standard"});

    const [usd, mainUsd] = useState({})

    const [saveBranchId, setSaveBranchId] = useState('ALL')

    useEffect(() => {
        axios.get('https://cbu.uz/uz/arkhiv-kursov-valyut/json/').then(
            (response) => {
                mainUsd(response.data[0])
            }
        )
    }, [])

    function branchonchange(e) {
        input.branchvalue = e.value
        let a = {...input}
        setinput(a)
        e.value === "ALL" ? getAccountAllBusiness({
            businessId: users.businessId,
            date: "ALL"
        }) : getAccountAll({
            branchId: e.value,
            date: "ALL"
        })
        e.value === "ALL" ? getStatics({
            businessId: users.businessId,
            params: {
                branchId: null,
                date: "ALL",
            }
        }) : getStatics({
            businessId: users.businessId,
            params: {
                branchId: e.value,
                date: "ALL",
            }
        })
        getCashKassa({
            branchId: e.value === 'ALL' ? users.branchId : e.value,
            params: {
                businessId: e.value === 'ALL' ? users.businessId : null
            }
        })
        e.value === "ALL" ? getOutlayTradeBusiness(users.businessId) : getOutlayTrade(e.value)
        setSaveBranchId(e.value)
    }

    useEffect(() => {
        users.viewInfoAdmin ? getAccountAllBusiness({
            businessId: users.businessId,
            date: "ALL"
        }) : getAccountAll({
            branchId: input.branchvalue ? input.branchvalue : users.branchId,
            date: "ALL"
        })
        users.viewInfoAdmin ? getStatics({
            businessId: users.businessId,
            params: {
                branchId: input.branchvalue ? input.branchvalue : null,
                date: "ALL",
            }
        }) : getStatics({
            businessId: users.businessId,
            params: {
                branchId: input.branchvalue ? input.branchvalue : users.branchId,
                date: "ALL",
            }
        })
        users.viewInfoAdmin ? getCashKassa({
            branchId: input.branchvalue === 'ALL' || input.branchvalue === null ? users.branchId : input.branchvalue,
            params: {
                businessId: input.branchvalue === 'ALL' || input.branchvalue === null ? users.businessId : null
            }
        }) : getCashKassa({
            branchId: input.branchvalue === 'ALL' || input.branchvalue === null ? users.branchId : input.branchvalue,
            params: {
                businessId: input.branchvalue === 'ALL' || input.branchvalue === null ? users.businessId : null
            }
        })
        getbranch(users.businessId)
        users.viewInfoAdmin ? getOutlayTradeBusiness(users.businessId) : getOutlayTrade(users.branchId)
        getXarajatlarxisobot({branchId: users.branchId})
    }, [])

    useEffect(() => {
        getMaxsulotRuyxati(users.businessId)
    }, [ValyutaReducer.current])

    const [sana, setSana] = useState([])
    const [totalTradeSum, setTotalTradeSum] = useState([])
    const [totalOutlay, setTotalOutlay] = useState([])
    const [totalCash, setTotalCash] = useState([])
    const [totalDebt, setTotalDebt] = useState([])

    useEffect(() => {

        if (ReportsReducer.kassa) {
            let timestamp = []
            let TradeSum = []
            let Outlay = []
            let Cash = []
            let Debt = []

            ReportsReducer.kassa.data.map(item => {
                timestamp.push(item.timestamp)
                TradeSum.push(item?.totalTradeSum)
                Outlay.push(item?.totalOutlay)
                Cash.push(item?.totalCash)
                Debt.push(item?.totalDebt)
            })
            setSana(timestamp)
            setTotalTradeSum(TradeSum)
            setTotalOutlay(Outlay)
            setTotalCash(Cash)
            setTotalDebt(Debt)
        }
    }, [ReportsReducer.getKassaBoolean])


    const chartOption = {
        chart: {
            background: 'transparent',
            sparkline: {
                enabled: false
            },
            id: 'apexchart-example'
        },
        stroke: {
            curve: 'smooth',
            width: [3, 3]
        },
        markers: {
            hover: {
                sizeOffset: 3
            },
            shape: 'circle',
            size: 6,
            strokeWidth: 3,
            strokeOpacity: 1,
            strokeColors: 'rgba(55,255,93,0.31)',
            colors: 'rgba(255,55,92,0)'
        },
        colors: ["#17B1F4", "#00F071", "#FFA760", "#FF64B0"],
        dataLabels: {
            enabled: true
        },
        labels: sana ? sana.map(item => formatDate(item)) : [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999],
        grid: {
            strokeDashArray: 5,
            padding: {
                right: 8,
                left: 8,
                bottom: 5,
                top: 5
            }
        },
        xaxis: {
            labels: {
                show: false
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            categories: sana ? sana.map(item => formatDate(item)) : [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]

        },
        yaxis: {
            show: false
        }
    }
    const series1 = [
        {
            name: t('Bosh1.ssummasi'),
            data: totalTradeSum ? totalTradeSum : 0
        },
        {
            name: t('Bosh1.xarajatlar'),
            data: totalOutlay ? totalOutlay : 0
        },
        {
            name: t('Davomat.kassa'),
            data: totalCash ? totalCash : 0
        },

        {
            name: t('Bosh1.tnasiya'),
            data: totalDebt ? totalDebt : 0
        }
    ]

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1024px)'
    })
    return (
        <div>
            <div className="row m-0 p-0 align-items-stretch">
                <div className="col-md-7 p-3">
                    <div className={'dashboard-card pb-0 d-flex justify-content-between'}>
                        <div className={'w-50'}>
                            <div>
                                <h2 className={'dashboard-sof-text'}>{t('newChanges.main_title')}</h2>
                                <p className={'dashboard-sof-info'}>{t('newChanges.main_desc')}</p>
                            </div>
                            <div className={'d-flex justify-content-start align-items-center gap-3'}>
                                <button className={'dashboard-btn2'}>{t('Sidebar.29')}</button>
                                <button onClick={() => history.push("/shopping")} className={'dashboard-btn'}>
                                    <p>{t('Sidebar.21')}</p></button>
                            </div>
                        </div>
                        <div className={'dashboard-banner2'}>
                            <img className={'img-fluid'} src={banner2} alt="banner"/>
                        </div>
                    </div>
                </div>
                <div className="col-md-5 p-3">
                    <div className={'dashboard-card pe-0 pb-0'}>
                        <h4 className={'benefit-text'}>{t('BOSH.bugungifoyda')}</h4>
                        <div className={'d-flex justify-content-between align-items-top'}>
                            <div>
                                <h5 className={'benefit-number'}>{
                                    ReportsReducer.kassa ?
                                        germanCurrencyFormatter.format(ReportsReducer.kassa?.totalProfit.toString()) : 0
                                } UZB</h5>
                                <div className={'d-flex align-items-center gap-2'}>
                                    <img src={arrow} alt="arrow"/>
                                    <p className={"benefit-percent"}><small className={"benefit-percent-small"}>24.08
                                        so'm</small> vs kechaga nisbatan</p>
                                </div>
                            </div>
                            <div className={'promoMoney'}>
                                <img className={'img-fluid'} src={promo_money} alt="promoMoney"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/*<div className="gap" style={{marginTop: "20px"}}>*/}
            <div className="row p-2 mb-0 border-box d-flex justify-content-between justify-content-sm-around jami">
                {isDesktopOrLaptop && <div className={'filter px-2 py-3 col-md-12 col-md-6 gap-2 col-md-4'}
                                           style={{width: "98%"}}>
                    <h4 style={{fontWeight: 700, color: "#484964"}}>{t('Sidebar.1')}</h4>
                    <div className="d-flex justify-content-between gap-2">
                        <Segmented style={{minHeight: 40, display: 'flex', alignItems: 'center'}}
                                   onChange={(value) => Dates(value)}
                                   options={[{label: t('BOSH.barchasi'), value: "ALL"}, {
                                       label: t('BOSH.7kun'),
                                       value: "LAST_WEEK"
                                   }, {label: t('BOSH.buoy'), value: "THIS_MONTH"}, {
                                       label: t('BOSH.buyil'),
                                       value: "THIS_YEAR"
                                   }]}/>
                        <RangePicker onChange={(values) => {
                            users.viewInfoAdmin ? (input.branchvalue ? (input.branchvalue === 'ALL' || input.branchvalue === null ? getAccountDateBusiness({
                                businessId: users.businessId,
                                startDate: formatDateYear(values[0]),
                                endDate: formatDateYear(values[1])
                            }) : getAccountDate({
                                branchId: input.branchvalue,
                                startDate: formatDateYear(values[0]),
                                endDate: formatDateYear(values[1])
                            })) : getAccountDateBusiness({
                                businessId: users.businessId,
                                startDate: formatDateYear(values[0]),
                                endDate: formatDateYear(values[1])
                            })) : getAccountDate({
                                branchId: users.branchId,
                                startDate: formatDateYear(values[0]),
                                endDate: formatDateYear(values[1])
                            })
                        }
                        }/>
                        <div className={'rangepicker'}>
                            <div className={'sbranch'}>
                                <Select
                                    options={filialSelect}
                                    defaultValue={filialSelect[0]}
                                    onChange={branchonchange}
                                />

                            </div>
                        </div>
                    </div>
                </div>}
            </div>
            <div className="osishAllCard mt-3 mb-3">
                <div className="kassaChart">
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <div>
                            <p className="kassaPul">{t('Davomat.kassa')}</p>
                            <h3 className="kassaSum">{ReportsReducer.kassa ?
                                germanCurrencyFormatter.format(ReportsReducer.kassa.totalSumma.toString()) : 0} UZB</h3>
                        </div>
                        <div className="d-flex align-items-center justify-content-between gap-5">
                            <div>
                                <img className='kassaPulImg' src={qopdollor} alt="qop dollor"/>
                                <p className='kassaPultext'>{t('BOSH.bugungifoyda')}</p>
                                <h4 className='kassaPulsum'>{
                                    ReportsReducer.kassa ?
                                        germanCurrencyFormatter.format(ReportsReducer.kassa?.totalProfit.toString()) : 0
                                } UZS</h4>
                            </div>
                            <div>
                                <img className='kassaPulImg' src={packagekarob} alt="pakage"/>
                                <p className='kassaPultext'>{t('Bosh1.bxarajat')}</p>
                                <h4 className='kassaPulsum'>{
                                    ReportsReducer.kassa ?
                                        germanCurrencyFormatter.format(ReportsReducer.kassa?.totalOutlay.toString()) : 0
                                } UZS</h4>
                            </div>
                            <div>
                                <img className='kassaPulImg' src={dollor} alt="dollor"/>
                                <p className='kassaPultext'>{t('newChanges.main_sof_foy')}</p>
                                <h4 className='kassaPulsum'>{
                                    ReportsReducer.kassa ?
                                        germanCurrencyFormatter.format(ReportsReducer.kassa?.totalProfit.toString() - ReportsReducer.kassa?.totalOutlay.toString()) : 0
                                } UZS</h4>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Chart options={chartOption} series={series1} type="area" height={400}/>
                    </div>
                </div>
                <div className="osishCard d-flex align-items-center flex-column justify-content-between gap-4 w-100">
                    <div className="osishChart green">
                        <div>
                            <p>{t('Bosh1.susish')}</p>
                            <h4>{ReportsReducer.statics ?
                                germanCurrencyFormatter.format(ReportsReducer.statics.totalSumDto.summa.toString()) : 0} UZB</h4>
                        </div>
                        <div>
                            <h5>{
                                ReportsReducer.statics ?
                                    parseFloat(ReportsReducer.statics.totalSumDto.percentage).toFixed(2) : 0.00
                            } %</h5>
                            <Progress size="small" strokeColor={"#63C51E"} percent={ReportsReducer.statics ?
                                parseFloat(ReportsReducer.statics.totalSumDto.percentage).toFixed(2) : 0} steps={20}/>
                        </div>
                    </div>
                    <div className="osishChart carrot">
                        <div>
                            <p>{t('Bosh1.dusish')}</p>
                            <h4>{ReportsReducer.statics && ReportsReducer.statics?.totalProfitSumDto?.summa !== 'NaN' ?
                                prettify(ReportsReducer.statics?.totalProfitSumDto?.summa) : 0} UZB</h4>
                        </div>
                        <div>
                            <h5>{
                                ReportsReducer.statics ?
                                    parseFloat(ReportsReducer.statics.totalProfitSumDto.percentage).toFixed(2) : 0.00
                            } %</h5>
                            <Progress strokeColor={"#FF851F"} percent={ReportsReducer.statics ?
                                parseFloat(ReportsReducer.statics.totalProfitSumDto.percentage).toFixed(2) : 0}
                                      steps={20} size={100}/>
                        </div>
                    </div>
                    <div className="osishChart blue">
                        <div>
                            <p>{t('Bosh1.musish')}</p>
                            <h4>{ReportsReducer.statics ?
                                germanCurrencyFormatter.format(ReportsReducer.statics.totalProductSumDto.summa.toFixed(2).toString()) : 0} UZB</h4>
                        </div>
                        <div>
                            <h5>{
                                ReportsReducer.statics ?
                                    parseFloat(ReportsReducer.statics.totalProductSumDto.percentage).toFixed(2) : 0.00
                            } %</h5>
                            <Progress strokeColor={"#07F"} percent={
                                ReportsReducer.statics ?
                                    parseFloat(ReportsReducer.statics.totalProductSumDto.percentage).toFixed(2) : 0
                            } steps={20} size={100}/>
                        </div>
                    </div>
                    <div className="osishChart red">
                        <div>
                            <p>{t('Bosh1.mjusish')}</p>
                            <h4>{
                                ReportsReducer.statics ?
                                    ReportsReducer.statics.totalCustomerDto.amount : 0
                            } TA</h4>
                        </div>
                        <div>
                            <h5>{
                                ReportsReducer.statics ?
                                    ReportsReducer.statics.totalCustomerDto.percentage : 0
                            } %</h5>
                            <Progress strokeColor={"#E02323"} percent={
                                ReportsReducer.statics ?
                                    ReportsReducer.statics.totalCustomerDto.percentage : 0
                            } steps={20} size={100}/>
                        </div>
                    </div>
                </div>
            </div>
            {/*    <Home saveBranchId={saveBranchId}/>*/}
            {/*    /!*{isDesktopOrLaptop &&   <div className="row p-2 border border-4 mx-2 mb-2 d-flex colorback  rounded-3 justify-content-between">*!/*/}
            {/*    /!*    <div className="col-6 col-sm-12 pt-md-4 pt-sm-4 ps-md-4 ps-sm-2">*!/*/}
            {/*    /!*        <h3 className={'diagramm-text'}>{t('Third.10')} </h3>*!/*/}
            {/*    /!*    </div>*!/*/}
            {/*    /!*    <div*!/*/}
            {/*    /!*        className="col-4 d-sm-flex  col-sm-12  pt-md-3 p-md-4 p-sm-1 offset-md-2 justify-content-md-end offset-sm-0">*!/*/}
            {/*    /!*        <div className="row p-0 align-items-center">*!/*/}
            {/*    /!*            <div className="col-12 pe-3 d-flex justify-content-md-end justify-content-center">*!/*/}
            {/*    /!*                <div className={'selects-style me-sm-4'}>*!/*/}
            {/*    /!*                    <select className={'selects'} form={'select'} id="select">*!/*/}
            {/*    /!*                        <option value="1">{t('Third.11')}</option>*!/*/}
            {/*    /!*                    </select>*!/*/}
            {/*    /!*                </div>*!/*/}
            {/*    /!*                <div className={'btn-nuqta'}>*!/*/}
            {/*    /!*                    <button className={'nuqta'}>...</button>*!/*/}
            {/*    /!*                </div>*!/*/}
            {/*    /!*            </div>*!/*/}
            {/*    /!*        </div>*!/*/}
            {/*    /!*    </div>*!/*/}
            {/*    /!*    <div className="row justify-content-between text-end px-0  ps-2 ms-2 table-responsive">*!/*/}
            {/*    /!*        <Chart*!/*/}
            {/*    /!*            options={chartOptions.options}*!/*/}
            {/*    /!*            series={chartOptions.series}*!/*/}
            {/*    /!*            type={'area'}*!/*/}
            {/*    /!*            width={'100%'}*!/*/}
            {/*    /!*            height={windowscrenn * 0.40}*!/*/}
            {/*    /!*        />*!/*/}
            {/*    /!*    </div>*!/*/}
            {/*    /!*</div>}*!/*/}
            {/*    <div className="row p-0 m-0  d-flex justify-content-between">*/}
            {/*        <div className="col-md-12 col-sm-12 col-lg-7 colorback2">*/}
            {/*            <div className={'pe-md-4'}>*/}
            {/*                <div className="row p-0  colorback" style={{minHeight: '400px', maxHeight: '400px'}}>*/}
            {/*                    <div className="col-6 col-sm-12 col-md-12 col-mdd-8 pt-3  mb-2 ps-3">*/}
            {/*                        <h3 className={'diagramm-text-one'}>{t('Third.18')}</h3>*/}
            {/*                    </div>*/}
            {/*                    <div className="col-5 col-sm-11 col-md-12 col-mdd-3 pt-3 ps-3 offset-md-1 offset-lg-0">*/}
            {/*                        <div className="row p-0 align-items-center">*/}
            {/*                            <div*/}
            {/*                                className="col-12 d-flex justify-content-end justify-content-center justify-content-md-end">*/}


            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                    <div className="col-12  mt-3">*/}
            {/*                        <Pagination branchValue={input.branchvalue}/>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="col-md-12 col-sm-12 col-lg-5 ">*/}
            {/*            <div>*/}
            {/*                <div className="row p-0 colorback " style={{minHeight: '400px', maxHeight: '400px'}}>*/}
            {/*                    <div className=" col-sm-10 col-md-10 col-mdd-8 col-lg-8 pt-md-3 ps-md-3 pt-sm-2">*/}
            {/*                        <h3 className={'diagramm-text-one text-one pt-sm-2'}>{t('Third.19')}</h3>*/}
            {/*                    </div>*/}
            {/*                    <div*/}
            {/*                        className="col-12 d-flex justify-content-end justify-content-center justify-content-md-end">*/}

            {/*                    </div>*/}
            {/*                    <div className="col-12 mt-3">*/}
            {/*                        <Pagination2 branchId={input.branchvalue}/>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="row p-2 mb-3 d-flex justify-content-between">*/}
            {/*        <div className="col-md-12 col-sm-12 col-md-12 col-lg-12">*/}
            {/*            /!*<div>*!/*/}
            {/*            /!*    <div className="row p-0 colorback" style={{minHeight: '400px', maxHeight: '400px'}}>*!/*/}
            {/*            /!*        <div className="col-6 col-sm-10 col-md-10 pt-3 ps-3">*!/*/}
            {/*            /!*            <h3 className={'diagramm-text-one diagramm-text-one-sm'}>{t('Third.22')}</h3>*!/*/}
            {/*            /!*        </div>*!/*/}
            {/*            /!*        <div className="col-5 col-sm-1 col-md-1 pt-3 ps-3 offset-md-1">*!/*/}
            {/*            /!*            <div className="row p-0 align-items-center">*!/*/}
            {/*            /!*                <div className="col-12 d-flex justify-content-end">*!/*/}
            {/*            /!*                    <div className={'btn-nuqta'}>*!/*/}
            {/*            /!*                        <button className={'nuqta'}>...</button>*!/*/}
            {/*            /!*                    </div>*!/*/}
            {/*            /!*                </div>*!/*/}
            {/*            /!*            </div>*!/*/}
            {/*            /!*        </div>*!/*/}
            {/*            /!*        <div className="col-12 mt-3">*!/*/}
            {/*            /!*            <Pagination4/>*!/*/}
            {/*            /!*        </div>*!/*/}
            {/*            /!*    </div>*!/*/}
            {/*            /!*</div>*!/*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
}


export default connect((titleReducer, SavdoQoshishReducer, branchreducer, XarajatXisobotReducer, TaminotReducer, users, accountreducer, XaridReducer, MaxsulotlarRoyxariReducer, ValyutaReducer, ReportsReducer),
    {
        getMaxsulotRuyxati,
        getTitle,
        getAccountAllBusiness,
        getOutlayTradeBusiness,
        getAccountDateBusiness,
        getCashKassa,
        getStatics,
        getXarajatlarxisobot,
        getOutlayTrade,
        getbranch,
        getXaridCost,
        getSavdolar,
        getAccount,
        getAccountDate,
        getPay,
        getAccountAll,
        savdooynasi,
        getTaminot
    })(Third);
