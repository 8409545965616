import {Button, Tooltip} from 'antd';
import './selectAnt.css'
import searchIcon from '../../assets/search 02.svg'
import {useTranslation} from "react-i18next";



export function SearchAnt({name, onChange, value}) {
    const {t} = useTranslation()

    return (
        <>
            {
                name &&   <h5 className={'selectLabel'}>{name}:</h5>
            }
            <div className={'search-box'}>
                <img className={'search-icon'} src={searchIcon} alt="searchIcon"/>
                <input placeholder={t('tes.73')} onChange={(e)=>onChange(e.target.value)} type="text"/>
            </div>
        </>
    );
}

export function InputAnt({name, type, items}) {


    return (
        <div className={'inputBox'}>
                <input  id={name} type={type} {...items} required/>
                <label htmlFor={name}  className={'input-label'}>{name}:</label>
        </div>
    );
}

export function ButtonAnt({onClick, icon, type, text, bgColor, danger, value}) {


    return (
        <>
            <Button onClick={onClick} className={'button-ant'} icon={icon} danger={danger} type={type}
                    style={{
                        backgroundColor: bgColor
                    }}
            >{text}</Button>
        </>
    );
}

export function TableButton({onClick, icon, type, danger, title, color}) {


    return (
        <>
            <Tooltip title={title} color={color} key={color}>
                <Button type={type} onClick={onClick} shape="round" icon={icon} size={'large'} danger={danger}/>
            </Tooltip>

        </>
    );
}
