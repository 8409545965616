import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../../../../../api";
import {toast} from "react-toastify";

const slice = createSlice({
    name: 'xarajatlar',
    initialState: {
        xarajatlar: null,
        counter:false,
        getOne:null,
        message:'',
        outlayType:null,
        getOutlaysBool:false,
        saveOutlaysBool:false,
        xarajatbranch:[]
    },
    reducers: {
        getFrom: (state, action) => {
            if (action.payload.success){
                state.xarajatlar = action.payload.object
            }
            else{
                state.xarajatlar = null
                state.message = action.payload.message
            }
            state.getOutlaysBool = !state.getOutlaysBool
                state.saveOutlaysBool = false
        },
        getFrombranch: (state, action) => {
            if (action.payload.success){
                state.xarajatlar = action.payload.object
            }
            else{
                state.xarajatlar = null
                state.message = action.payload.message
            }
            state.getOutlaysBool = !state.getOutlaysBool
            state.saveOutlaysBool = false
            state.xarajatbranch = action.payload.object
        },
        getFromOutlayType: (state, action) => {
            state.outlayType = action.payload.object
        },
        getFromOne: (state, action) => {
            state.getOne = action.payload.object
            state.counter=!state.counter
        },
        savefrom: (state,action) => {
            if (action.payload.success){
                state.counter=!state.counter
                state.saveOutlaysBool = true
                toast.success('Xarajat qo`shildi')
            }
            else{
                toast.warning(action.payload.message)
            }


        },
        editfrom: (state,action) => {
            if (action.payload.success){
                state.counter=!state.counter
                state.saveOutlaysBool = true
                toast.success('Xarajat Tahrirlandi')
            }
            else{
                toast.warning(action.payload.message)
            }
        },
        deletefrom:(state,action)=>{
            if (action.payload.success){
                state.counter=!state.counter
                toast.success('O\'chrildi')
            }
            else{
                toast.warning(action.payload.message)
            }
        }
    }
});

export const getXarajatlar=(data)=>apiCall({
    url: '/outlay/get-by-businessId/'+data,
    method:'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type
});
export const getXarajatlarbranch=(data)=>apiCall({
    url: '/outlay/get-by-branchId/'+data,
    method:'get',
    onSuccess: slice.actions.getFrombranch.type,
    onFail: slice.actions.getFrombranch.type
});
export const getOutlayType=(data)=>apiCall({
    url: '/outlayCategory/get-by-branchId/'+data,
    method:'get',
    onSuccess: slice.actions.getFromOutlayType.type,
    onFail: slice.actions.getFromOutlayType.type
});
export const getXarajatOne=(data)=>apiCall({
    url: '/outlay/'+data,
    method:'get',
    onSuccess: slice.actions.getFromOne.type,
    onFail: slice.actions.getFromOne.type
});
export const getXarajatlar2=(data)=>apiCall({
    url: '/outlay/get-by-branchId/'+data,
    method:'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type
});
export const getXarajatlar3=(data)=>apiCall({
    url: '/outlay/get-by-date/'+data.date+'/'+data.id,
    method:'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type
});

export const saveXarajatlar=(data)=>apiCall({
    url: '/outlay',
    method:'post',
    data,
    onSuccess: slice.actions.savefrom.type,
    onFail:slice.actions.savefrom.type
});

export const editXarajatlar=(data)=>apiCall({
    url: '/outlay/'+data.id,
    method: 'put',
    data,
    onSuccess: slice.actions.editfrom.type,
    onFail: slice.actions.editfrom.type
});

export const deleteXarajatlar=(data)=>apiCall({
    url: '/outlay/'+data,
    method:'delete',
    data,
    onSuccess: slice.actions.deletefrom.type,
    onFail: slice.actions.deletefrom.type
})

export default slice.reducer
