import React from 'react'
import {useForm} from 'react-hook-form'
import './lidforms.css'
import {useState, useEffect} from 'react'
import {connect} from "react-redux";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import users from "../../../../../reducer/users";
import Left from '../../../../../img/backward6.png'
import {Link, useLocation} from "react-router-dom";
import LidFieldReducer, {
    deleteLidField,
    editLidField,
    getLidField, getOneField,
    saveLidField
} from "../Lids/reducer/LidFieldReducer";
import {useTranslation} from "react-i18next";
import ModalLoading from "../../../../ModalLoading";
import Loading from "../../../../Loading";
import {IconButton, Switch, Tooltip} from "@mui/material";
import LidMessengersReducer, {getLidMessenger} from "../Lids/reducer/LidMessengersReducer";
import Select from "react-select";
import FormReducer, {getForm, saveForm} from "../Lids/reducer/FormReducer";
import selectLidReducer, {
    editLidSelect,
    getLidSelect,
    getLidSelectById,
    saveLidSelect
} from "../Lids/reducer/selectLidReducer";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from '@mui/icons-material/Clear';
import AddTaskIcon from '@mui/icons-material/AddTask';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import {toast} from "react-toastify";
import {saveLid} from "../Lids/reducer/LidReducer";
import button from "bootstrap/js/src/button";

function LidForms({
                      users,
                      saveLidForm,
                      FormReducer,
                      saveForm,
                      getForm,
                      selectLidReducer,
                      getLidSelectById,
                      getLidSelect,
                      saveLidSelect,
                      LidMessengersReducer,
                      getLidMessenger,
                      editLidSelect,
                      getLidField,
                      LidFieldReducer,
                      getOneField,
                      deleteLidField,
                      editLidField,
                      saveLidField
                  }) {

    const {t} = useTranslation();

    const location = useLocation()

    const [activeField, setActiveField] = useState(false)

    function fieldActiveToggle() {
        setActiveField(!activeField)

    }

    const [input, setInput] = useState({
        messengerValue: '',
        sourceId: null,
        val: [[]],
        typeName: ''
    })
    const [lidForm, setLidForm] = useState([])


    useEffect(() => {
        if (FormReducer?.formSource) {
            input.messengerValue = FormReducer.formSource
            let a = {...input}
            setInput(a)
        }
        if (LidFieldReducer?.lidsField) {
            if (LidFieldReducer.lidsField.length > 0) {
                let a = []
                LidFieldReducer.lidsField.map(item => {
                    let checked = FormReducer.formField.some(val => val.id === item.id)
                    a.push({...item, checked,})
                })

                setLidForm(a)
            }
        }

    }, [LidFieldReducer?.getBoolean, FormReducer?.getBoolean])

    const [fieldName, setFieldName] = useState('')
    const [fieldValueType, setValueType] = useState('')
    const [messengerValue, setMessengerValue] = useState('')
    const [lidFieldIds, setlidFieldIds] = useState([])
    const [check, setCheck] = useState(false)
    const [id, setId] = useState('')

    const [saveModal, setSaveModal] = useState(false)
    const [saveModalForm, setSaveModalForm] = useState(false)
    const [loading, setLoading] = useState(false)
    const [editId, setEditId] = useState('')
    const [deletemodal, setdeletemodal] = useState(false)
    const [deleteID, setdeletID] = useState('')
    const [fioActive, setActive] = useState(true)
    const [lidSumma, setLidSumma] = useState(null)

    function onChangeChecked(checked, index) {
        let a = [...lidForm]
        let b = {...a[index]}
        b.checked = checked
        a[index] = b
        let form = [...a]
        setLidForm(form)
    }


    useEffect(() => {
        setLoading(true)
    }, [LidFieldReducer?.getBoolean])

    function deleteModaltoggle(id) {
        setdeletemodal(!deletemodal)
        setdeletID(id)
    }

    function edit(id) {
        fieldActiveToggle()
        getOneField(id)
        setEditId(id)
        let a = LidFieldReducer.lidsField.filter(item => item.id === id)
        if (a[0].id === id) {
            setFieldName(a[0].name)
            setValueType(a[0].valueType)
        }
    }

    function deleteFunc() {
        deleteLidField(deleteID)
        setdeletID('')
        deleteModaltoggle()
        getLidField(users.businessId)
    }

    function saqlaField() {
        if (editId) {
            editLidField({
                businessId: users.businessId,
                name: fieldName,
                valueType: fieldValueType,
                id: editId
            })
        } else {
            saveLidField({
                businessId: users.businessId,
                name: fieldName,
                valueType: fieldValueType
            })
        }
        setSaveModal(true)
    }

    useEffect(() => {
        if (LidFieldReducer?.saveBoolean) {
            setEditId('')
            setValueType('')
            setFieldName('')
            fieldActiveToggle()
        }

        getLidField(users.businessId)
        setSaveModal(false)
    }, [LidFieldReducer?.current])


    useEffect(() => {
        getLidField(users.businessId)
        getLidMessenger(users.businessId)
        getForm(users.businessId)
    }, [])

    function changeselect(e) {
        input.messengerValue = e
        let b = []
        e.map(item => {
            b.push(item.value)
        })
        input.sourceId = b
        let a = {...input}
        setInput(a)

    }


    function saqlaForm() {
        let a = []
        lidForm.filter(item => item.checked === true)
            .map((item, index) => {
                a.push(item.id)
            })

        let b = []
        input.messengerValue.map(item => {
            b.push(item.value)
        })

        if (lidSumma != 0) {
            saveForm({
                businessId: users.businessId,
                totalSumma: lidSumma,
                lidFieldIds: a,
                sourceId: input.sourceId ? input.sourceId : b
            })

            setSaveModalForm(true)
        } else {
            toast.error(t('ol.71'))
        }

    }

    useEffect(() => {
        if (FormReducer?.saveBoolean) {
            getForm(users.businessId)
            setLidSumma(null)
        }
        setSaveModalForm(false)
    }, [FormReducer?.current])

    const [openSelect, setOpenSelect] = useState(false)
    const [lidId, setLidId] = useState(null)
    const [saveSelectId, setSaveSelectId] = useState(null)
    const [openEditSelect, setOpenEditSelect] = useState(false)
    const {register, setValue, resetField, reset, handleSubmit, formState: {errors}} = useForm()

    useEffect(() => {
        editSelect()
    }, [selectLidReducer?.currentGet])

    function editSelectToggle(id) {
        setOpenEditSelect(!openEditSelect)
        getLidSelectById(id)
        setSaveSelectId(id)
    }

    function exitEdit() {
        setOpenEditSelect(false)
        setSaveSelectId(null)
        input.val = [[]]
        let a = {...input}
        setInput(a)
    }

    function editSelect() {
        let values = []
        if (selectLidReducer?.oneList) {
            selectLidReducer.oneList.map(item => {
                values.push(item.name)
            })
        }
        input.val = values
        let a = {...input}
        setInput(a)
    }

    function openSelectToggle(id) {
        setOpenSelect(!openSelect)
        setLidId(id)
    }

    function addSelectInput() {
        input.val.push([])
        let a = {...input}
        setInput(a)
    }

    function handleDelete(i) {
        const deleteVal = input.val
        deleteVal.splice(i, 1)
        let a = {...input}
        setInput(a)
    }

    function handleChange(onChangevalue, i) {
        const inputdate = input.val
        inputdate[i] = onChangevalue.target.value
        let a = {...input}
        setInput(a)
    }

    function saveSelect() {
        if (saveSelectId) {
            editLidSelect({
                id: saveSelectId,
                lidFieldId: saveSelectId,
                names: input.val
            })
        } else {
            saveLidSelect({
                lidFieldId: lidId,
                names: input.val
            })
        }
        setSaveModal(true)
    }

    useEffect(() => {
        if (selectLidReducer?.saveSelect) {
            setLidId(null)
            input.val = [[]]
            setSaveSelectId(null)
            let a = {...input}
            setInput(a)
            // openSelectToggle()
            setOpenSelect(false)
            setOpenEditSelect(false)
            getLidSelect(users.businessId)
        }
        setSaveModal(false)
    }, [selectLidReducer?.current])

    useEffect(() => {
        getLidSelect(users.businessId)
    }, [])

    function handleAdd() {

    }

    function onSubmit(data) {
        saveLid({
            businessId: users.businessId, values: data, formId: '',
        })
        // setSaveModal(true)

    }

    return (
        <div className="col-md-12 mt-2 pt-4 pb-4">

            <div className="textHeaderMIG colorback mb-2 d-flex ">
                <h2 className={'text-center'}>{t('lidForma.lidForma')}</h2>

                <div>
                    <Link to={'/headerthird/lidlar'}>
                        <button className={'btn btn-outline-primary mb-2'}>{t('lidForma.Ortga')}</button>
                    </Link>
                    <button onClick={fieldActiveToggle} className={'btn btn-outline-primary mb-2 ms-2'}>{t('lidForma.lidFildQoshish')}
                    </button>
                    <Link to={'/headerthird/lidlar/messengers'}>
                        <button className={'btn btn-outline-primary mb-2 ms-2'}>
                            {t('lidForma.Messengers')}
                        </button>
                    </Link>
                </div>
            </div>

            {
                loading ?
                    LidFieldReducer?.lidsField ?
                        <div className="rowStyleMIG d-flex justify-content-between align-items-baseline">
                            <div className="col-md-6 ">
                                <table className={'table table-primary  table-hover border border-2'}>
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{t('lidForma.Field')}</th>
                                        <th className={'text-center'}>{t('lidForma.Status')}</th>
                                        <th className={'text-center'}>{t('lidForma.Amallar')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        LidFieldReducer.lidsField ?
                                            lidForm.map((item, index) => <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item?.name} </td>

                                                {/*<td className={'text-center'}><input id={'check'} type="checkbox"/></td>*/}

                                                <td className={'text-center'}>
                                                    {
                                                        item.name === 'FIO' ?
                                                            <Switch
                                                                checked={item.checked}
                                                                onChange={(e) => onChangeChecked(e.target.checked, index)}
                                                                // checked={check}
                                                                value="checkedB"
                                                                color="primary"
                                                            />
                                                            :
                                                            <Switch
                                                                checked={item.checked}
                                                                onChange={(e) => onChangeChecked(e.target.checked, index)}
                                                                // checked={check}
                                                                value="checkedB"
                                                                color="primary"
                                                            />
                                                    }

                                                </td>

                                                <td className={'text-center'}>
                                                    <Tooltip title={t('ol.14')} arrow>
                                                        <IconButton onClick={() => edit(item.id)} color={'success'}>
                                                            <EditIcon/>
                                                        </IconButton>
                                                    </Tooltip>

                                                    <Tooltip title={'ol.15'} arrow>
                                                        <IconButton color={'error'}
                                                                    onClick={() => deleteModaltoggle(item.id)}>
                                                            <ClearIcon/>
                                                        </IconButton>
                                                    </Tooltip>

                                                    {
                                                        item.valueType === 'SELECT' ?
                                                            // <button onClick={() => openSelectToggle(item.id)} className={'btn btn-success ms-2'}>
                                                            <Tooltip title={'Add select'} arrow>
                                                                <IconButton onClick={() => openSelectToggle(item.id)}
                                                                            color={'warning'}>
                                                                    <AddTaskIcon/>
                                                                </IconButton>
                                                            </Tooltip> : ''
                                                        // </button> : ''
                                                    }
                                                    {
                                                        item.valueType === 'SELECT' ?
                                                            // <button onClick={() => openSelectToggle(item.id)} className={'btn btn-success ms-2'}>
                                                            <Tooltip title={'edit select'} arrow>
                                                                <IconButton onClick={() => editSelectToggle(item.id)}
                                                                            color={'primary'}>
                                                                    <HistoryEduIcon/>
                                                                </IconButton>
                                                            </Tooltip> : ''
                                                        // </button> : ''
                                                    }
                                                </td>
                                            </tr>) : 'Malumot topilmadi'
                                    }
                                    </tbody>
                                </table>

                                <div className={'border border-2 mb-2 p-2'}>
                                    <label htmlFor="lidsumma">{t('lidForma.lidSumma')}</label>
                                    <input type="number" className={'mb-2 form-control'} value={lidSumma}
                                           onChange={(e) => setLidSumma(e.target.value)}/>

                                    <label htmlFor="messengers">{t('lidForma.Messengers')}</label>

                                    <Select value={input.messengerValue} onChange={changeselect} isMulti={true}
                                            options={LidMessengersReducer.lidMessengerSelect ? LidMessengersReducer.lidMessengerSelect : ''}/>
                                </div>
                                {/*{FormReducer.forms}*/}
                                <div className={'border border-2  p-2'}>
                                    <p className={'ms-2'}>{t('lidForma.info')}
                                    </p>

                                    {

                                        FormReducer.forms ?
                                            <div>
                                                {
                                                    // FormReducer.forms?
                                                    FormReducer.forms?.map((item, index) => <div key={index}>
                                                        <div className={'col-md-12 border border-1'}>
                                                            {
                                                                item.sourceDto?.name === 'HandleWrite' ?
                                                                    <div>
                                                                        <label htmlFor="">{t('lidForma.qoldaQoshish')}</label><br/>
                                                                        <Link to={'/add/' + item.id}>
                                                                            add/{item.id}
                                                                        </Link>
                                                                    </div>
                                                                    :
                                                                    <div>
                                                                        <label
                                                                            htmlFor="label">{item?.sourceDto?.name}</label><br/>
                                                                        <Link
                                                                            to={'/form-lid/get-form/' + users.businessId + '/' + item.id}>
                                                                            http://localhost:3000/form-lid/get-form/{users.businessId}/{item.id}
                                                                        </Link>
                                                                    </div>
                                                            }
                                                            {/*<label htmlFor="label">{item?.sourceDto?.name}</label><br/>*/}
                                                            {/*<Link to={'/form-lid/get-form/' + users.businessId + '/' + item.id}>*/}
                                                            {/*    http://localhost:3000/form-lid/get-form/{users.businessId}/{item.id}*/}
                                                            {/*</Link>*/}
                                                        </div>
                                                    </div>)
                                                    // :<h4 className={'text-center'}>NOT FOUND</h4>
                                                }
                                            </div> : <h4 className={'text-center'}>NOt FOUND</h4>
                                    }
                                </div>
                            </div>

                            <div className={'border-2 border p-3 mt-4 col-md-6'}>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <h5 className={'text-center'}>{t('lidForma.korinish')}</h5>
                                    {
                                        LidFieldReducer.lidsField ?
                                            lidForm.filter(item => item.checked === true).map((item, index) => <div
                                                key={index}>
                                                {/*<div>*/}
                                                {/*    <label className={'mt-1'} htmlFor="">{item.name}</label>*/}
                                                {/*    <input className={'form-control'} type={item.valueType}/>*/}
                                                {/*</div>*/}

                                                {item.valueType === 'SELECT' ?
                                                    <select {...register((item.id), {required: true})}
                                                        // value={selectName} onChange={(e)=>setSelectName(e.target.value)}
                                                            className={'form-control mt-3'}>
                                                        {
                                                            selectLidReducer.lidSelect ?
                                                                selectLidReducer.lidSelect.filter((val) => val.lidFieldId === item.id).map(i =>
                                                                    <option value={i.name}>{i.name}</option>) : ''
                                                        }
                                                    </select>
                                                    :
                                                    item.valueType === 'INTEGER' ?
                                                        <input
                                                            {...register((item.id), {required: true})}
                                                            placeholder={errors[item.id] ? errors[item.id].type === "required" && item.name + " is required" : item.name}

                                                            type={"number"}
                                                            className={'form-control mt-3'}/> :
                                                        <input
                                                            {...register((item.id), {required: true})}
                                                            placeholder={errors[item.id] ? errors[item.id].type === "required" && item.name + " is required" : item.name}
                                                            // placeholder={'STRING...'}
                                                            type={item.valueType}
                                                            className={'form-control'}/>
                                                }

                                            </div>) : ''
                                    }
                                    <div className={'mt-2 d-flex justify-content-end'}>
                                        <button onClick={saqlaForm} className={'btn me-2 btn-outline-dark'}>{t('Buttons.6')}
                                        </button>

                                        {
                                            FormReducer.forms ?
                                                FormReducer.forms.filter(val=>val.sourceDto?.name==='HandleWrite')
                                                    .map(item=><Link to={`/add/`+item.id}>
                                                        <button className={'btn btn-primary'}>{'add'?`Qo'lda qo'shish`:''+'/'+item.id}</button>
                                                    </Link>)
                                                : ''
                                        }
                                        {/*<button className={'btn btn-outline-dark'}>Yangilash</button>*/}
                                    </div>
                                </form>
                            </div>
                        </div> : <div>
                            <h4 className={'text-center'}>{LidFieldReducer?.message}</h4>
                        </div> : <Loading/>
            }

            {/* =  = = = EDIT SELECT - - - */}

            <Modal isOpen={openEditSelect} toggle={exitEdit}>
                <ModalHeader>
                    <h5>{t('lidForma.Tahrirlash')}</h5>
                </ModalHeader>
                <ModalBody>
                    {
                        input.val.map((data, i) => {
                            return (
                                <div className={'d-flex'}>
                                    {/*<input value={fieldName} onChange={(e)=>setFieldName(e.target.value)} type="text" className={'form-control'}/>*/}
                                    <input value={data} onChange={(e) => handleChange(e, i)} type="text"
                                           className={'form-control'}/>
                                    {
                                        i === 0 ? <h3 onClick={addSelectInput} className={'mt-2'}
                                                      style={{cursor: 'pointer'}}>+</h3>
                                            : saveSelectId === null ?
                                                <button className={'btn mt-2 btnLeft mr-1 btn-danger'}>X</button> :
                                                <button onClick={() => handleDelete(i)}
                                                        className={'btn btn-danger'}>X</button>
                                    }
                                </div>
                            )
                        })
                    }
                </ModalBody>
                <ModalFooter>
                    <button onClick={saveSelect} className={'btn btn-outline-primary'}>{t('lidForma.saqlaTahrirlash')}</button>
                    <button className={'btn btn-primary'} onClick={exitEdit}>{t('Buttons.7')}</button>
                </ModalFooter>
            </Modal>

            {/*ADD SELECT*/}
            <Modal isOpen={openSelect} toggle={openSelectToggle}>
                <ModalHeader>
                    <h5>{t('lidForma.add')}</h5>
                </ModalHeader>
                <ModalBody>
                    {
                        input.val.map((data, i) => {
                            return (
                                <div className={'d-flex'}>
                                    <input value={data} onChange={(e) => handleChange(e, i)} type="text"
                                           className={'form-control'}/>
                                    {
                                        i === 0 ? <h3 onClick={addSelectInput} className={'mt-2'}
                                                      style={{cursor: 'pointer'}}>+</h3>
                                            : <button onClick={() => handleDelete(i)}
                                                      className={'btn btn-danger'}>X</button>
                                    }
                                </div>
                            )
                        })
                    }
                </ModalBody>
                <ModalFooter>
                    <button onClick={saveSelect} className={'btn btn-outline-primary'}>{t('Buttons.6')}</button>
                    <button onClick={openSelectToggle} className={'btn btn-primary'}>{t('Buttons.7')}</button>
                </ModalFooter>
            </Modal>

            {/*FIELD QOSHISH*/}
            <Modal toggle={fieldActiveToggle} isOpen={activeField}>
                <ModalHeader>
                    <h5>{t('lidForma.fieldQoshish')}</h5>
                </ModalHeader>
                <ModalBody>
                    <div className="col-md-12 d-flex">
                        <div className={'col-md-6'}>
                            <label htmlFor="name">{t('Lids.Ismi')}</label>
                            <input value={fieldName} onChange={(e) => setFieldName(e.target.value)} type="text"
                                   className={'form-control'}/>

                        </div>

                        <div className={'col-md-6'}>
                            <label htmlFor="value">{t('lidForma.Type')}</label>
                            <select className={'form-control'}
                                    value={fieldValueType} onChange={(e) => setValueType(e.target.value)} id="value">
                                <option value="">{t('lidForma.Tanlash')}</option>
                                <option value="STRING">String</option>
                                <option value="INTEGER">Integer</option>
                                <option value="DOUBLE">Double</option>
                                <option value="EMAIL">Email</option>
                                <option value="SELECT">Select</option>
                                <option value="DATE">Date</option>
                            </select>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    {fieldName === '' || fieldValueType === "" ?
                        <button disabled={true} onClick={saqlaField}
                                className={'btn btn-outline-primary'}>{t('Buttons.6')}</button> :
                        <button onClick={saqlaField} className={'btn btn-outline-primary'}>{t('Buttons.6')}</button>}
                    {/*<button onClick={saqlaField} className={'btn btn-outline-primary'}>Saqlash</button>*/}
                    <button className={'btn btn-outline-primary'} onClick={fieldActiveToggle}>{t('Buttons.7')}</button>
                </ModalFooter>
            </Modal>

            {/*DELETE*/}
            <Modal isOpen={deletemodal} toggle={deleteModaltoggle}>
                <ModalBody>
                    <h5>{t('Buttons.12')} ?</h5>
                </ModalBody>
                <ModalFooter>
                    <button onClick={() => deleteFunc(deleteID)}
                            className={'btn btn-outline-primary'}>{t('Buttons.3')}</button>
                    <button onClick={() => deleteModaltoggle('')}
                            className={'btn btn-outline-primary'}>{t('Buttons.7')}</button>
                </ModalFooter>
            </Modal>


            <ModalLoading isOpen={saveModal}/>

            <ModalLoading isOpen={saveModalForm}/>
        </div>


    )
}

export default connect((users, LidFieldReducer, LidMessengersReducer, FormReducer, selectLidReducer), {
    getLidField,
    saveLidField,
    getLidSelectById,
    editLidField,
    deleteLidField,
    getOneField, saveLid,
    editLidSelect,
    getLidMessenger,
    getForm,
    saveForm,
    saveLidSelect,
    getLidSelect,
})(LidForms)
