import './profil.css'
import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import photoreducer ,{savephoto,clearPhotoId} from "../../../reducer/photoreducer";
import users, {editUser, getUser} from "../../../reducer/users";
import {useTranslation} from "react-i18next";
import {Link, useHistory} from "react-router-dom";
import Imagecom from "../../Imagecom";
import {BaseUrl} from "../../../middleware";
import profil from '../../../img/person-default.png'
import {toast} from "react-toastify";
function Profil({image,savephoto,getUser,editUser,users,photoreducer,clearPhotoId}) {

    const [input,setInput] = useState(
        {
            name: '',
            lastName: '',
            photo: '',
            password:'',
            prepassword:'',
            username:''
        }
    )
    const {t} = useTranslation()
    const [viber,setviber] = useState({
        pic: {}
    })
    const history = useHistory()

    const [photoIMG,setPhotoIMG] = useState(null)

    useEffect(()=>{
        clearPhotoId()
        setPhotoIMG(users.users?.photo?.id)
    },[])

    useEffect(()=>{
        setPhotoIMG(photoreducer.photo)
    },[photoreducer.current])
    function name(e){
        input.name = e.target.value
        let a = {...input}
        setInput(a)
    }
    function lastname(e){
        input.lastName = e.target.value
        let a = {...input}
        setInput(a)
    }
    function photoSave(e){
        const data = new FormData();
        data.append('file', e.target.files[0]);
        savephoto(data)
    }

    function username(e){
            input.username = e.target.value
            let a = {...input}
            setInput(a)
    }

    function password(e){
        input.password = e.target.value
        let a = {...input}
        setInput(a)
    }
    function prepassword(e){
        input.prepassword = e.target.value
        let a = {...input}
        setInput(a)
    }
    function vib(e){
        const data = new FormData();
        data.append('file', e.target.files[0]);
        savephoto(data)
    }


    useEffect(()=>{
        getUser(users.id)
        edit()
    },[])

    function edit(){
        const {firstName,lastName,username} = users.users

        input.name = firstName
        input.lastName = lastName
        input.username = username
    }

    let user = JSON.parse(localStorage.getItem('user'))

    function logOut(){
        localStorage.removeItem('user',)
        localStorage.removeItem('tokenname',)
        history.push('/login')
    }

    function saqla(){
        if(input.username==='superadmin'){
            toast.warning('You cannot edit this user')
        }else {
            editUser({
                firstName: input.name,
                lastName: input.lastName,
                photoId: photoreducer.photo,
                username: input.username,
                password: input.password,
                prePassword: input.prepassword
            })
        }
        clearPhotoId()
    }

  return (

    <div className='containerProfil mt-4 mb-4'>
        <div className="row">
            <div className="col-md-6 offset-3 border border-2 text-center p-4 mt-2">
                <Link to={'/headerthird/profil'}>
                    <button className={'btn btn-outline-primary'}>{t('tes.66')}</button>
                </Link>
                <Link to={'/headerthird/profil/edit'}>
                    <button className={'btn ms-2 btn-primary'}>{t('tes.67')}</button>
                </Link>
            </div>
        </div>

        <div className="row">
            <div className=" mb-3">
                <label htmlFor="n1">{t('Welcome.13')}</label>
                <input type="text" value={input.password} onChange={password} className='form-control' placeholder={t('tes.68')} />
            </div>
            <div className="mb-3">
                <label htmlFor="n1">{t('Welcome.13')}</label>
                <input type="text" value={input.prepassword} onChange={prepassword} className='form-control' placeholder={t('tes.69')} />
            </div>
        </div>
        <div className={'row'}>
            <div className="d-flex justify-content-between">
                <div>
                    <label htmlFor="">{t('tes.70')}</label>
                    <input type="text" value={input.name} onChange={name} className={'form-control'}/>

                </div>
                <div>
                    <label htmlFor="">{t('tes.71')}</label>
                    <input type="text" value={input.username} onChange={input.username==='superadmin'?'':username} className={'form-control'}/>
                </div>
                <div>
                    <label htmlFor="">{t('tes.72')}</label>
                    <input type="text" className={'form-control'} value={input.lastName} onChange={lastname}/>

                </div>
            </div>
        </div>
        <div className="row mt-3">
            <div className="col-md-6 mb-2">
                {
                    photoIMG
                       ? <img style={{width:'200px',height:'200px'}} className={'img-fluid'} src={`${BaseUrl}/attachment/download/${photoIMG}`} alt="13"/>
                        :<img style={{width:'200px',height:'200px'}} className={'img-fluid'} src={profil} alt={"13"}/>
                }
            </div>
            <label htmlFor={'profilRasm'}>
                <p className={'btn form-control btn-primary'}>{t('Welcome.14')}</p>
            </label>
            <input type="file" className={'d-none form-control mt-2'} value={input.photo} id={'profilRasm'} name={'file[]'} multiple={true} onChange={photoSave}/>

        </div>
        <div className="row p-2 d-flex mt-3">

            <button onClick={saqla} className={'btn btn-primary'}>{t('Buttons.6')}</button>
            {/*<button onClick={logOut} className={'btn btn-danger mt-3'}>{t('Buttons.7')}</button>*/}
        </div>
    </div>
  )
}
export default connect((users,photoreducer),{savephoto,getUser,editUser,clearPhotoId}) (Profil)
