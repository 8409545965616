import {useState} from 'react';
import {Layout, Menu, theme} from 'antd';
import {connect} from "react-redux";
import HeaderBody from "./header/HeaderBody";
import './sidebar.css'
import home from '../../assets/home (1).svg'
import Hr from '../../assets/hard working.png'
import Task from '../../assets/test passed (1).png'
import products from '../../assets/Maxsulotlar.png'
import produce from '../../assets/Ishlab chiqarish.png'
import purchase from '../../assets/money transfer.png'
import trade from '../../assets/Savdo.png'
import exchange from '../../assets/Bazadan Bazaga.png'
import report from '../../assets/accounting.png'
import setting from '../../assets/gear.png'
import lessons from '../../assets/Vazifalar 1.png'
import outlays from '../../assets/point objects.png'
import {Route, Switch, useHistory} from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import {routes} from "./headerthird";
import Third from "./ThirdPage/Third";
import Profil from "./header/Profil";
import RecentActivity from "./header/ViewProfile/RecentActivity";
import users from "../../reducer/users";
import {useTranslation} from "react-i18next";
import useWindowWidth from "../Components/useWindowWidth";

const {Header, Content, Sider} = Layout;

const Sidebar = ({users}) => {
    const {t} = useTranslation()
    const widthWidth = useWindowWidth()
    const history = useHistory()
    const [collapsed, setCollapsed] = useState(true);
    const rootSubmenuKeys = ['/main/dashboard', '/main/superadmin', '/main/balance', '/main/user', 'customers', 'products', 'purchase', 'trades', 'outlay', 'reports', 'setting'];
    const [openKeys, setOpenKeys] = useState(['/main/dashboard']);
    const {
        token: {colorBgContainer},
    } = theme.useToken();

    const items = [
        {
            key: '/main/superadmin',
            // icon: <img src={home} width={32} height={32} alt={'home1'}/>,
            label: 'SuperAdmin',
            check: users.addBusiness
        },
        {
            key: '/main/dashboard',
            icon: <img src={home} width={32} height={32} alt={'home1'}/>,
            label: t("Sidebar.1"),
            check: true
        },
        // {
        //     key: '/main/user',
        //     icon: <img src={lid} width={32} height={32} alt={'lid'}/>,
        //     label: t("Sidebar.4"),
        //     check:users.addUser || users.editUser || users.viewUserAdmin || users.deleteUser || users.viewUser ||
        //         users.addRole || users.editRole || users.viewRoleAdmin || users.deleteRole || users.viewRole
        // },
        // {
        //     key: '/main/partner',
        //     icon: <img src={usersPic} width={32} height={32} alt={'users'}/>,
        //     label: t("Sidebar.5"),
        //     check:
        //
        // },
        {
            key: '/main/lid',
            icon: <img src={home} width={32} height={32} alt={'home1'}/>,
            label: 'Lidlar',
            check: true
        },
        {
            key: '/main/hr',
            icon: <img src={Hr} width={32} height={32} alt={'Hr'}/>,
            label: t("Sidebar.4"),
            check: users.addUser || users.editUser || users.viewUserAdmin || users.deleteUser || users.viewUser ||
                users.addRole || users.editRole || users.viewRoleAdmin || users.deleteRole || users.viewRole || users.addWorkTimeChecked || users.viewAttendanceChecked || users.viewSalaryChecked || users.editSalaryChecked ||
                users.viewOrgChecked || users.addJobChecked || users.viewJobChecked || users.editJobChecked || users.deleteJobChecked ||
                users.addPrizeChecked || users.viewPrizeChecked || users.addBonusChecked ||
                users.getBonusChecked ||
                users.editBonusChecked ||
                users.deleteBonusChecked
        },
        {
            key: '/main/tasks',
            icon: <img src={Task} width={32} height={32} alt={'task'}/>,
            label: t("Sidebar1.vazifalar"),
            check: users.getProjectChecked || users.editProjectChecked || users.addProjectChecked || users.deleteProjectChecked ||
                users.addTaskChecked || users.deleteTaskChecked || users.editTaskChecked || users.getTaskChecked || users.getOwnTaskChecked || users.getOwnProjectChecked ||
                users.addProjectTypeChecked ||
                users.editProjectTypeChecked ||
                users.getProjectTypeChecked ||
                users.deleteProjectTypeChecked ||
                users.addTaskStatusChecked ||
                users.getTaskStatusChecked ||
                users.editTaskStatusChecked ||
                users.deleteTaskStatusChecked ||
                users.addTaskTypeChecked ||
                users.getTaskTypeChecked ||
                users.editTaskTypeChecked ||
                users.deleteTaskTypeChecked
        },
        {
            key: '/main/lessons',
            icon: <img src={lessons} width={32} height={32} alt={'lessons'}/>,
            label: t("Sidebar1.darsliklar"),
            check: users.addLessonChecked ||
                users.viewLessonChecked ||
                users.viewLessonRoleChecked || users.editLessonChecked ||
                users.deleteLessonChecked,
        },
        {
            key: '/main/product',
            icon: <img src={products} width={32} height={32} alt={'products'}/>,
            label: t("Sidebar.9"),
            check:
                users.viewProductAdmin || users.viewProduct || users.addProduct || users.editProduct || users.deleteProduct ||
                users.addProductType || users.editProductType || users.deleteProductType || users.viewProductType || users.postExcel ||
                users.addCategory || users.editCategory || users.deleteCategory || users.viewCategory || users.addChildCategory ||
                users.addBrand || users.editBrand || users.deleteBrand || users.viewBrand
        }, {
            key: '/main/production',
            icon: <img src={produce} width={32} height={32} alt={'produce'}/>,
            label: t("Sidebar1.ishlabchiqarish"),
            check: users.addProduction || users.viewProduction || users.addContent || users.viewContent || users.deleteContent || users.editContent
        }, {
            key: '/main/purchase',
            icon: <img src={purchase} width={32} height={32} alt={'purchase'}/>,
            label: t("Sidebar.15"),
            check: users.addSupplier || users.editSupplier || users.deleteSupplier || users.viewSupplierAdmin || users.viewSupplier ||
                users.viewPurchase || users.viewPurchaseAdmin || users.addPurchase || users.editPurchase || users.deletePurchase
        },
        {
            key: '/main/trade',
            icon: <img src={trade} width={32} height={32} alt={'trade'}/>,
            label: t("Sidebar.18"),
            check: users.addCustomer || users.editCustomer || users.deleteCustomer || users.viewCustomerAdmin || users.viewCustomer ||
                users.addCustomerGroup || users.editCustomerGroup || users.deleteCustomerGroup || users.viewCustomerGroup ||
                users.addTrade || users.editTrade || users.viewTrade || users.viewMyTrade ||
                users.viewTradeAdmin || users.deleteTrade || users.deleteMyTrade || users.addLoss || users.viewLoss
        },
        {
            key: '/main/outlays',
            icon: <img src={outlays} width={32} height={32} alt={'outlays'}/>,
            label: t("Sidebar.25"),
            check: users.addOutlay ||
                users.editOutlay ||
                users.viewOutlay ||
                users.viewOutlayAdmin ||
                users.deleteOutlay,
        },
        {
            key: '/main/exchange',
            icon: <img src={exchange} width={32} height={32} alt={'exchange'}/>,
            label: t("Sidebar.22"),
            check: users.addExchange || users.editExchange || users.viewExchange || users.viewExchangeAdmin || users.deleteExchange
        },
        {
            key: '/main/reports',
            icon: <img src={report} width={32} height={32} alt={'report'}/>,
            label: t("Sidebar.29"),
            check: users.viewReport
        },
        {
            key: '/main/settings',
            icon: <img src={setting} width={32} height={32} alt={'setting'}/>,
            label: t("Sidebar.40"),
            check: users.editInvoiceChecked ||
                users.addBranch ||
                users.editBranch ||
                users.viewBranch ||
                users.viewBranchAdmin ||
                users.deleteBranch
        },
    ].filter(item => item.check === true)

    const onOpenChange = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };

    return (
        <Layout id="fullscreen">
            <Header>
                <HeaderBody setCollapsed={() => {
                    setCollapsed((prev) => !prev)
                }}/>
            </Header>
            <Layout>
                <Sider
                    width={278}
                    className={widthWidth >= 1024 ? "sidebar-scroll sidebarSof" : "sidebar-scroll-mobile sidebarSof"}
                    collapsedWidth={widthWidth >= 1024 ? 100 : 0}
                    trigger={null}
                    style={{
                        background: colorBgContainer,
                        marginTop: "-10px"
                    }}
                    collapsed={collapsed}
                >
                    <Menu
                        mode="inline"
                        onOpenChange={onOpenChange}
                        openKeys={openKeys} defaultSelectedKeys={['/main/dashboard']}
                        onClick={(e) => {
                            setCollapsed(true)
                            history.push(e.key)
                        }}
                        style={{
                            borderRight: 0,
                            gap: "10px",
                            display: "flex",
                            padding: "18px",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                        items={items}
                    />
                </Sider>
                <Layout
                    onClick={() => {
                        widthWidth <= 1024 && setCollapsed(true)
                    }}
                    className={`layout-content ${!collapsed && widthWidth <= 1024 ? "blurPage" : ""}`}
                >
                    <Content
                        style={{
                            padding: 0,
                            margin: 0,
                        }}
                    >
                        <Switch>
                            {
                                routes.map(item =>
                                    <ProtectedRoute path={"/main/" + item.path} component={item.component}
                                                    roles={item.permissions}/>
                                )
                            }
                            <Route path={'/main/profil/edit'} component={Profil}/>
                            <Route path={'/main/profil/:id'} component={RecentActivity}/>
                            <Route path={'/main/profil'} component={RecentActivity}/>
                            <Route path={'/main/dashboard'} component={Third}/>
                        </Switch>
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    );
};
export default connect((users), {})(Sidebar);
