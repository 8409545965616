import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../../../../../api";

const slice = createSlice({
    name: 'LessonUser',
    initialState: {
        lessonView: null,
        current: false,
        addRole: false,
        message: '',
        getBoolean: false,
        saveRoleBool: false,
    },
    reducers: {
        getFrom: (state, action) => {
            if (action.payload.success) {
                state.lessonView = action.payload.object
            } else {
                state.lessonView = null
                state.message = action.payload.message
            }
            state.getBoolean = !state.getBoolean
            state.saveRoleBool = false
        },
    }
});

export const getViewByLesson = (data) => apiCall({
    url: '/lessonUser/by-lesson/' + data,
    method: 'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type,
});

export const getViewByUserId = (data) => apiCall({
    url: '/lessonUser/by-user/' + data,
    method: 'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type,
});

export default slice.reducer