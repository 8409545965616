import React, {useState, forwardRef, useEffect} from 'react';
import {Link, Link as RouterLink, useLocation} from 'react-router-dom';
import {DragDropContext} from 'react-beautiful-dnd';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {
    Avatar,
    Box,
    Card,
    Grid,
    Slide,
    Divider,
    Tooltip,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableContainer,
    TableRow,
    ToggleButton,
    ToggleButtonGroup,
    Tabs,
    TextField,
    Button,
    Typography,
    Dialog,
    styled,
    Select,
    FormControl, MenuItem, InputLabel, InputAdornment

} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import VisibilityIcon from '@mui/icons-material/Visibility';

import users from "../../../../../reducer/users";
import Loading from "../../../../Loading";
import ModalLoading from "../../../../ModalLoading";
import DatePicker from "@mui/lab/DatePicker";
import XodimReducer, {getAgreement} from "../../Hodimlar/reducer/XodimReducer";
import SalaryCountReducer, {
    editSalaryCount, getOneSalaryCount,
    getSalaryCount,
    saveSalaryCount
} from "../../Hodimlar/reducer/SalaryCountReducer";
import SalaryReducer, {
    editAvans,
    editSalary,
    getSalaryByBranch,
    getSalaryByUser
} from "../../../../../reducer/SalaryReducer";
import formatDate, {formatDateMinus} from "../../../../../util";
import EditIcon from "@mui/icons-material/Edit";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {toast} from "react-toastify";
import eslatma from "../../Savdo/SavdoOynasi/Final/Eslatma";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import PayReducer, {getPay} from "../../../../../reducer/PayReducer";

const DialogWrapper = styled(Dialog)(
    () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);



const AvatarError = styled(Avatar)(
    ({theme}) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const applyPagination = (tasks, page, limit) => {
    return tasks.slice(page * limit, page * limit + limit);
};

const Salary = ({ getOneSalaryCount, getAgreement,PayReducer,getPay,
                    users, SalaryCountReducer, editSalaryCount, getSalaryCount,editAvans,
                    XodimReducer, SalaryReducer, editSalary, getSalaryByBranch, getSalaryByUser
                }) => {
    const {t} = useTranslation();

    const [saveModal, setSaveModal] = useState(false)
    const [editAvansID, setEditAvansId] = useState(null)
    const [editSalaryID, setEditSalaryId] = useState(null)
    const [avansDescription,setAvansDescription] = useState('')
    const [avansSalary,setAvansSalary] = useState('')

    const [salaryDescription,setSalaryDescription] = useState('')
    const [salary,setSalary] = useState('')
    const [openSalary, setOpenSalary] = useState(false)
    const [openView, setViewOpen] = useState(false)
    const [openViewSalaryControl, setOpenViewSalaryControl] = useState(false)
    const [salaryTrue, setSalaryTrue] = useState(false)
    const [editCount,setEditCount] = useState(false)

    const [branchInput, setBranchInput] = useState('')
    const [agreementInput, setAgreementInput] = useState('')
    const [countInput, setCountInput] = useState(1)
    const [dateInput, setDateInput] = useState('')
    const [agreementPrice, setAgreementPrice] = useState('')
    const [description, setDescription] = useState('')
    const [salaryControlId, setSalaryControlId] = useState(null)
    const [loading, setLoading] = useState(false)
    const [query, setQuery] = useState(null);
    const [paymethod, setpaymethod] = useState(null)

    const [mainBranchId,setMainBranchId] = useState(null)


    useEffect(()=>{
       setLoading(true)
    },[SalaryReducer.getBoolean])

    useEffect(()=>{
       setLoading(false)
    },[])
    function countInputChange(e){
        setCountInput(e.target.value)
        if (e.target.value<=0){
            toast.error(t('ol.58'))
        }
    }
    function agreementOnChange(e){
        setAgreementInput(e.target.value)

        XodimReducer.agreement.map(item=>{
            if (e.target.value===item.id){
                setAgreementPrice(item.price)
            }
        })
    }



    function viewToggle(){
        setViewOpen(!openView)
    }

    function editToggle(){
        setEditCount(!editCount)
    }

    function salaryToggle(){
        setOpenSalary(!openSalary)
        setSalaryTrue(false)
    }
    function salaryControlToggle(){
        setOpenViewSalaryControl(!openViewSalaryControl)
    }

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);

    const handlePageChange = (_event, newPage) => {
        setPage(newPage);
    };

    const handleLimitChange = (event) => {
        setLimit(parseInt(event.target.value));
    };

    useEffect(() => {
        getSalaryByBranch(mainBranchId ? mainBranchId : users.branchId)
    }, [SalaryReducer.current,mainBranchId])

    function editAvansss(id, boolean){
        // avansToggle()
        salaryToggle()
        setEditAvansId(id)
        setSalaryTrue(boolean)
        let a = SalaryReducer.salary.filter(item=>item.salaryId===id)
        setAvansDescription(a[0].description)
        setAvansSalary(a[0]?.payedSum)
    }

    function saqlaAvans(){
        if (paymethod===null || paymethod==='Tanlash'){
            toast.warning(t('ol.60'))
        }else {
            editAvans({
                description: avansDescription,
                salary: avansSalary,
                paymentMethodId: paymethod,
                id: editAvansID
            })
            setSaveModal(true)
        }
    }

    function editMaosh(id){
        salaryToggle()
        setEditSalaryId(id)

        let a = SalaryReducer.salary.filter(item=>item.salaryId===id)

        setSalaryDescription(a[0].description)
        setSalary(a[0].salary)
    }

    function saqlaSalary(){
        editSalary({
            description: salaryDescription,
            salary,
            // paymentMethodId: paymethod,
            id: editSalaryID
        })
        setSaveModal(true)
    }

    useEffect(()=>{
        if (SalaryReducer.saveBoolean){
            setSalary('')
            setSalaryDescription('')
        }
        setSaveModal(false)
        setEditSalaryId(null)
        setOpenSalary(false)
    },[SalaryReducer.current])

    function viewFunc(id){
        viewToggle()
        getSalaryByUser({
            branchId:mainBranchId ?mainBranchId : users.branchId,
            userId:id
        })
    }

    const [viewId,setViewId] = useState('')
    function viewSalaryControl(id){
        salaryControlToggle()
        setViewId(id)
        getSalaryCount({
            branchId:mainBranchId ? mainBranchId: users.branchId,
            userId:id
        })
    }

    function editSalaryCounttt(id,userId){
        editToggle()
        getOneSalaryCount(id)
        getAgreement(userId)
        setSalaryControlId(id)
    }

    useEffect(()=>{
        if (salaryControlId){
            let a = SalaryCountReducer.salaryOneCount
            setDateInput(formatDateMinus(a.date))
            setCountInput(a.count)
            setAgreementInput(a.agreementId)
            XodimReducer.agreement.map(item=>{
                if (a.agreementId===item.id){
                    setAgreementPrice(item.price)
                }
            })
            setDescription(a.description)
        }
    },[SalaryCountReducer.currentOne])

    function editSalaryCountFunc(){
        editSalaryCount({
            branchId:mainBranchId ? mainBranchId: users.branchId,
            agreementId: agreementInput,
            count: countInput,
            date: dateInput,
            description,
            salary: countInput*agreementPrice,
            id: salaryControlId
        })
        setSaveModal(true)
    }

    useEffect(()=>{
        if (SalaryCountReducer.editBoolean){
            setAgreementInput('')
            setAgreementPrice('')
            setDateInput('')
            setCountInput('')
            setDescription('')
            setSalaryControlId(null)
            editToggle()
            getSalaryCount({
                branchId:mainBranchId ? mainBranchId: users.branchId,
                userId:viewId
            })
        }
        setSaveModal(false)
    },[SalaryCountReducer.current])

    useEffect(()=>{
       getPay(users.businessId)
    },[])

    return (
        <>
            <div className={'col-md-12 mt-4'}>

                <div className={'mb-2 colorback'}>
                    <Box p={2}>
                        <TextField
                            sx={{
                                m: 0,
                                outline:'none'
                            }}
                            label={t('ol.59')}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchTwoToneIcon/>
                                    </InputAdornment>
                                )
                            }}
                            onChange={(e)=>setQuery(e.target.value)}
                            placeholder={t('ol.61')}
                            value={query}
                            size="small"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                        />
                    </Box>
                    <Box p={2}>
                        {
                            users.branches.length > 1 ?
                                users.branches ?
                                    <FormControl>
                                        <InputLabel id="demo-simple-select-label">{t('Salary.t')}</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={mainBranchId ? mainBranchId : users.branchId}
                                            label={t('ol.63')}
                                            onChange={(e) => {
                                                setMainBranchId(e.target.value)
                                            }}
                                            name={'branchId'}
                                        >
                                            {
                                                users?.branches.map((item, index) =>
                                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                                )}
                                        </Select>
                                    </FormControl> : ""
                                : ''
                        }
                    </Box>

                </div>

                {
                    users.viewSalaryChecked?
                    SalaryReducer.salary?
                    loading?
                    <Card>
                        <Divider/>

                        <>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>#</TableCell>
                                            <TableCell>{t('Salary.ismi')}</TableCell>
                                            <TableCell align={'center'}>{t('Salary.mbs')}</TableCell>
                                            <TableCell align="center">{t('Salary.ts')}</TableCell>
                                            <TableCell align={'center'}>{t('Salary.qs')}</TableCell>
                                            <TableCell>{t('Salary.msh')}</TableCell>
                                            <TableCell align={'center'}>{t('Salary.bk')}</TableCell>
                                            <TableCell align="center">{t('ol.64')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            SalaryReducer.salary.filter(val=>{
                                                if (query===null){
                                                    return val
                                                }else if (val.firstName.toUpperCase().includes(query.toUpperCase())){
                                                    return val
                                                }
                                            })
                                                .map((item,index)=><TableRow key={index}>
                                                <TableCell>{index+1}</TableCell>
                                                <TableCell>{item.firstName}</TableCell>
                                                <TableCell align={'center'}>{formatDate(item.startDate)}</TableCell>
                                                <TableCell align={'center'}>{item?.payedSum}</TableCell>
                                                <TableCell align={'center'}>{item?.remain}</TableCell>
                                                <TableCell align={'center'}>{item.salary}</TableCell>
                                                <TableCell align={'center'}>{item?.shouldPaySum}</TableCell>
                                                <TableCell align="center">
                                                    <Typography noWrap>
                                                        <Tooltip title={t('ol.65')} arrow>
                                                            <IconButton
                                                                onClick={() => viewFunc(item.userId)}
                                                                color="primary"
                                                            >
                                                                <LaunchTwoToneIcon fontSize="small"/>

                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title={t('ol.66')} arrow>
                                                            <IconButton
                                                                onClick={() => viewSalaryControl(item.userId)}
                                                                color="primary"
                                                            >
                                                                {/*<LaunchTwoToneIcon fontSize="small"/>*/}
                                                                <VisibilityIcon fontSize={"small"}/>
                                                            </IconButton>
                                                        </Tooltip>
                                                        {
                                                            users.editSalaryChecked?
                                                                <Tooltip title={t('ol.67')} arrow>
                                                                    <IconButton
                                                                        onClick={() => editAvansss(item?.salaryId, true)}
                                                                        color="primary"
                                                                    >
                                                                        <BorderColorIcon/>
                                                                    </IconButton>
                                                                </Tooltip>:''
                                                        }
                                                        {
                                                            users.editSalaryChecked?
                                                                <Tooltip title={t('ol.68')} arrow>
                                                                    <IconButton color={'primary'}
                                                                                onClick={()=>editMaosh(item?.salaryId)}>
                                                                        <EditIcon/>
                                                                    </IconButton>
                                                                </Tooltip>:''
                                                        }


                                                    </Typography>
                                                </TableCell>
                                            </TableRow>)
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box p={2}>
                                <TablePagination
                                    component="div"
                                    // count={filteredUsers.length}
                                    onPageChange={handlePageChange}
                                    onRowsPerPageChange={handleLimitChange}
                                    page={page}
                                    rowsPerPage={limit}
                                    rowsPerPageOptions={[5, 10, 15]}
                                />
                            </Box>
                        </>
                    </Card>:<Loading/>
                        :<div> <h4 className={'text-center'}>{t('Darslik.mtt')}</h4></div>:''
                }

                {/*Tahrirlash*/}
                <Modal isOpen={openSalary} toggle={salaryToggle}>
                    <ModalHeader>
                        <h5>{t('Salary.tsh')}</h5>
                    </ModalHeader>
                    <ModalBody>
                        {
                            // Pay AVANS EDIT
                            salaryTrue?
                                <div className={'row'}>
                                    <div className="col-md-6">
                                        <label htmlFor="">{t('Org.eslatma')}</label>
                                        <input value={avansDescription} onChange={(e)=>setAvansDescription(e.target.value)} type="text" className={'form-control'}/>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="">{t('Salary.as')}</label>
                                        <input value={avansSalary} onChange={(e)=>setAvansSalary(e.target.value)} type="number" className={'form-control'}/>
                                    </div>
                                    <div className="col-md-12">
                                        <label htmlFor="">{t('Salary.tu')}</label>
                                        <select value={paymethod} onChange={(e)=>setpaymethod(e.target.value)} className={'form-control'}>
                                            <option value="Tanlash">{t('Salary.t')}</option>
                                            {
                                                PayReducer.paymethod?
                                                    PayReducer.paymethod.map(item=> <option value={item.id}>{item.type}</option>)
                                                    :<h4 className={'text-center'}>NOT FOUND PAY METHOD</h4>
                                            }
                                        </select>
                                    </div>
                                </div>:
                                // SALARY EDIT
                                <div className="row">
                                    <div className="col-md-6">
                                        <label htmlFor="avans">{t('Org.eslatma')}</label>
                                        <input value={salaryDescription} onChange={(e)=>setSalaryDescription(e.target.value)} type="text" className={'form-control'}/>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="avans">{t('Salary.msh')}</label>
                                        <input value={salary} onChange={(e)=>setSalary(e.target.value)} type="number" className={'form-control'}/>
                                    </div>
                                </div>
                        }

                    </ModalBody>
                    <ModalFooter>
                        {
                            salaryTrue?
                               <button onClick={saqlaAvans} className={'btn btn-outline-primary'}>{t('Salary.sa')}</button>:
                                <button onClick={saqlaSalary} className={'btn btn-outline-primary'}>{t('Salary.sm')}</button>
                        }
                        {/*<button onClick={saqlaSalary} className={'btn btn-outline-primary'}>Saqlash</button>*/}
                        <button onClick={salaryToggle} className={'btn btn-primary'}>{t('Davomat.chiqish')}</button>
                    </ModalFooter>
                </Modal>

                {/*VIEW MODAL - - -*/}
                <Modal isOpen={openView} size={'xl'} toggle={viewToggle}>
                    <ModalHeader>
                        <h5>{t('Salary.k')}</h5>
                    </ModalHeader>
                    <ModalBody>
                        {
                            SalaryReducer.salaryUser?
                        <div className="row">
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>#</TableCell>
                                            <TableCell>{t('Salary.ismi')}</TableCell>
                                            <TableCell>{t('Salary.e')}</TableCell>
                                            <TableCell>{t('Salary.bsa')}</TableCell>
                                            <TableCell>{t('Salary.tsa')}</TableCell>
                                            <TableCell>{t('Salary.ts')}</TableCell>
                                            <TableCell>{t('Salary.qs')}</TableCell>
                                            <TableCell>{t('Salary.msh')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {

                                                SalaryReducer.salaryUser.map((item,index)=><TableRow key={index}>
                                                    <TableCell>{index+1}</TableCell>
                                                    <TableCell>{item.firstName}</TableCell>
                                                    <TableCell>{item.description}</TableCell>
                                                    <TableCell>{formatDate(item.startDate)}</TableCell>
                                                    <TableCell>{formatDate(item.endDate)}</TableCell>
                                                    <TableCell>{item?.payedSum}</TableCell>
                                                    <TableCell>{item?.remain}</TableCell>
                                                    <TableCell>{item.salary}</TableCell>
                                                </TableRow>)
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>: <h4 className={'text-center'}>{t('Darslik.mtt')}</h4>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <button onClick={viewToggle} className={'btn btn-primary'}>{t('ol.69')}</button>
                    </ModalFooter>
                </Modal>

                {/*VIEW SALARY CONTROL*/}
                <Modal isOpen={openViewSalaryControl} size={'xl'} toggle={salaryControlToggle}>
                    <ModalHeader>
                        <h5 >{t('Salary.kmt')}</h5>
                    </ModalHeader>
                    <ModalBody>
                        {
                            SalaryCountReducer.salaryCount?
                        <div className="row">
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>#</TableCell>
                                            <TableCell>{t('Salary.ismi')}</TableCell>
                                            <TableCell>{t('Salary.kl')}</TableCell>
                                            <TableCell>{t('Salary.ko')}</TableCell>
                                            <TableCell>{t('Salary.s')}</TableCell>
                                            <TableCell align={'center'}>{t('Salary.msh')}</TableCell>
                                            <TableCell align={'center'}>{t('Davomat.amal')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {

                                                SalaryCountReducer.salaryCount.map((item,index)=><TableRow key={index}>
                                                    <TableCell>{index+1}</TableCell>
                                                    <TableCell>{item.firstName}</TableCell>
                                                    <TableCell>{item?.agreementName}</TableCell>
                                                    <TableCell>{item?.count}</TableCell>
                                                    <TableCell>{formatDate(item.date)}</TableCell>
                                                    <TableCell align={'center'}>{item.salary}</TableCell>
                                                    <TableCell align={'center'}>
                                                        <Tooltip title={t('ol.68')} arrow>
                                                            <IconButton color={'primary'}
                                                                        onClick={()=>editSalaryCounttt(item.id, item.userId)}>
                                                                <EditIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>)

                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>: <h4 className={'text-center'}>{t('Darslik.mtt')}</h4>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <button onClick={salaryControlToggle} className={'btn btn-primary'}>{t('Davomat.chiqish')}</button>
                    </ModalFooter>
                </Modal>

                <Modal size={'xl'} isOpen={editCount} toggle={editToggle}>
                    <ModalHeader>
                        <h5>{t('Salary.tsh')}</h5>
                    </ModalHeader>
                    <ModalBody>
                        <div className={'row'}>

                            <div className="col-md-6 mt-2">
                                <b>{t('Salary.s')}</b>
                                <input value={dateInput} onChange={(e)=>setDateInput(e.target.value)} type="date" className={'form-control'}/>
                            </div>


                            <div className="col-md-6 mt-2">
                                <b>{t('Salary.iv')} </b>
                                <input value={countInput} onChange={countInputChange} type="number" className={'form-control'}/>
                            </div>
                            <div className="col-md-12 mt-2">

                                <Grid item xs={12} sm={4} md={3} textAlign={{sm: 'start'}}>
                                    <Box className={'mb-2'} style={{width: '200px'}}
                                         pr={3} sx={{pb: {xs: 1, md: 0}}}>
                                        <b>{t('agreement')}:</b>
                                    </Box>
                                </Grid>
                                {
                                    users.branches ?
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">{t('Salary.t')}</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label={'agreement'}
                                                value={agreementInput}
                                                onChange={agreementOnChange}
                                            >
                                                {
                                                    users?XodimReducer.agreement.filter(value=>value.active).
                                                    map((item,index)=>
                                                        <MenuItem value={item.id}>{item?.salaryStatus}</MenuItem>
                                                    ):''
                                                }

                                            </Select>

                                        </FormControl> : <Link to={'/headerthird/hodimlarruyxati/taxrirlash'}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">{t('Salary.xq')}</InputLabel>
                                                <select className={'form-control'} name=""
                                                        id="">{t('Salary.q')}</select>
                                            </FormControl>
                                        </Link>
                                }
                            </div>

                            <div className="col-md-12 mt-2">
                                <b>{t('Salary.e')}</b>
                                <input value={description} onChange={(e)=>setDescription(e.target.value)} type="text" className={'form-control'}/>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button onClick={editSalaryCountFunc} className={'btn btn-outline-primary'}>{t('Salary.tsh')}</button>
                        <button onClick={editToggle} className={'btn btn-primary'}>{t('Davomat.chiqish')}</button>
                    </ModalFooter>
                </Modal>

                <ModalLoading isOpen={saveModal}/>

            </div>
        </>
    );
};

export default connect((SalaryCountReducer,PayReducer, users, SalaryReducer, XodimReducer), {
    editSalaryCount,
    getSalaryCount,
    getAgreement,
    getPay,
    saveSalaryCount,
    getSalaryByBranch,
    getSalaryByUser,
    editSalary,
    getOneSalaryCount,
    editAvans
})(Salary);
