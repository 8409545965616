import {Button} from "antd";
import editPancil from "../../assets/editPencil.svg"
import deleteTrash from "../../assets/deleteTrash.svg"
import addPlus from "../../assets/addPlus.svg"
import "./buttons.css"
import {useState} from "react";
import useWindowWidth from "./useWindowWidth";

export function EditButton({buttonText, ...props}) {
  const widthWidth = useWindowWidth()
  return <Button title={buttonText} className="table_button edit_buttonTable" {...props}>
    {
      widthWidth >= 768 && buttonText && <span>{buttonText}</span>
    }
    <img style={{width: 16, height: 16}} src={editPancil} alt="edit table button pencil"/>
  </Button>
}

export function DeleteButton({buttonText, ...props}) {
  const widthWidth = useWindowWidth()
  return <Button title={buttonText} className="table_button delete_buttonTable" {...props}>
      {
        widthWidth >= 768 && buttonText && <span>{buttonText}</span>
      }
      <img style={{width: 16, height: 16}} src={deleteTrash} alt="delete table button trash"/>
    </Button>
}

export function DefaultButton({buttonText, color, bgColor, icon, width, ...props}) {
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };
  return <Button
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
    style={{color: color, background: bgColor, borderColor: isHover && color, width: width}} className="table_button"
    {...props}
  >
    {
      buttonText && <span>{buttonText}</span>
    }
    {
      icon && <img style={{width: 16, height: 16}} src={icon} alt="delete table button trash"/>
    }
  </Button>
}

export function QushishButton({buttonText, ...props}) {
  return <Button className="add_button" {...props}>
    <span>{buttonText}</span>
    <img src={addPlus} alt="add button plus"/>
  </Button>
}
