import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../../../../../api";


const slice = createSlice({
    name: 'subscrip',
    initialState: {
        subscription: {},
    },
    reducers: {
        getFrom: (state, action) => {
            if (action.payload.success) {
                state.subscription = action.payload.object
            } else {
                state.subscription = {}
            }
        },

    }

});

export const getByBusinessSubscription = (data) => apiCall({
    url: '/subscription/getByBusinessId/' + data.id,
    method: 'get',
    params:data.params,
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type
});


export default slice.reducer
