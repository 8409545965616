import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../../../../../api";
import {toast} from "react-toastify";

const slice = createSlice({
    name: 'savdolar',
    initialState: {
        savdolar: null,
        backingProduct:null,
        backingProductMore:null,
        savdolarPageSize:null,
        savdoTotal: null,
        amount: 0,
        trade: null,
        treadeId:null,
        tradeProductList: null,
        paymentDtoList:null,
        getTradeBool: false,
        current: false,
        success: false,
        deleteBoolean:false,
        message: '',
        saveBoolean: false,
        getOneBoolean:false,
        saveBooleanLid: false
    },
    reducers: {
        getFrom: (state, action) => {
            if (action.payload.success) {
                state.savdolar = action.payload.object
            } else {
                state.savdolar = null
                state.message = action.payload.message
            }
            state.getTradeBool = !state.getTradeBool
            state.success = false
            state.saveBooleanLid=false
        },
        getFromBacking: (state, action) => {
            if (action.payload.success) {
                state.backingProduct = action.payload.object
            } else {
                state.backingProduct = null
                state.message = action.payload.message
            }
            state.getTradeBool = !state.getTradeBool
            state.success = false
            state.saveBooleanLid=false
        },
        getFromBackingMore: (state, action) => {
            if (action.payload.success) {
                state.backingProductMore = action.payload.object
            } else {
                state.backingProductMore = null
                state.message = action.payload.message
            }
            state.getTradeBool = !state.getTradeBool
            state.success = false
            state.saveBooleanLid=false
        },
        getFromPageSize: (state, action) => {
            if (action.payload.success) {
                state.savdolarPageSize = action.payload?.object?.content
                state.savdoTotal = action.payload?.object?.totalElements
            } else {
                state.savdolarPageSize = null
                state.savdoTotal = null
                state.message = action.payload.message
            }
            state.getTradeBool = !state.getTradeBool
            state.success = false
            state.saveBooleanLid=false
        },
        getFromById: (state, action) => {
            if (action.payload.success){
                state.trade = action.payload.object?.trade
                state.tradeProductList = action.payload.object?.tradeProductList
                state.paymentDtoList = action.payload.object?.paymentDtoList
            }else {
                state.message = action.payload.message
                state.trade = null
                state.tradeProductList = null
                state.paymentDtoList = null
            }
            state.getOneBoolean = !state.getOneBoolean

        },
        savefrom: (state, action) => {
            if (action.payload.success === true) {
                toast.success('Savdo qo`shildi')
                // state.current = !state.current
                state.success = true
                state.treadeId = action.payload?.object
                state.saveBooleanLid=true
            } else if (action.payload.success === false) {
                toast.warning(action.payload.message)
                state.treadeId = null
                state.success = false
            }
            state.current = !state.current

        },
        editfrom: (state, action) => {
            if (action.payload.success === true) {
                toast.success('Success !')
                state.success = true
                state.treadeId = action.payload?.object
                state.saveBooleanLid=true
            } else if (action.payload.success === false) {
                toast.warning(action.payload.message)
                state.treadeId = null
                state.success = false
            }
            state.current = !state.current

        },
        deletefrom: (state, action) => {
            if (action.payload.success) {
                toast.success('Savdo o\'chdi')
                state.deleteBoolean = true
            } else if (action.payload.success === false) {
                toast.warning(action.payload.message)
            }
            state.deleteBoolean=!state.deleteBoolean
            state.current = !state.current


        },
        clearSuccess: (state, action) => {
            state.success = false
        }

    }
});

export const getSavdolar = (data) => apiCall({
    url: '/trade/get-by-business/' + data,
    method: 'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type
});
export const getSavdolarPageSize = (data) => apiCall({
    url: `/trade/get-by-filter/${data.id}`,
    method: 'get',
    params: data.params,
    onSuccess: slice.actions.getFromPageSize.type,
    onFail: slice.actions.getFromPageSize.type
});
export const getTradeById = (data) => apiCall({
    url: '/trade/' + data,
    method: 'get',
    onSuccess: slice.actions.getFromById.type,
    onFail: slice.actions.getFromById.type
});

export const getSavdolar2 = (data) => apiCall({
    url: 'trade/get-by-branchId/' + data,
    method: 'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type
});

export const getSavdolar3 = (data) => apiCall({
    url: 'trade/get-by-PayDate/' + data,
    method: 'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type
});

export const saveSavdolar = (data) => apiCall({
    url: '/trade',
    method: 'post',
    data,
    onSuccess: slice.actions.savefrom.type,
    onFail: slice.actions.savefrom.type
});

export const editSavdolar = (data) => apiCall({
    url: '/trade/' + data.id,
    method: 'put',
    data,
    onSuccess: slice.actions.editfrom.type,
    onFail: slice.actions.editfrom.type,
});

export const deleteSavdolar = (data) => apiCall({
    url: '/trade/' + data,
    method: 'delete',
    data,
    onSuccess: slice.actions.deletefrom.type,
    onFail: slice.actions.deletefrom.type
})

export const getBackingProduct = (data) => apiCall({
    url: '/trade/backing/' + data.branchId,
    method: 'get',
    onSuccess: slice.actions.getFromBacking.type,
    onFail: slice.actions.getFromBacking.type
});
export const getBackingProductMore = (data) => apiCall({
    url: '/trade/backing-by-product/' + data.branchId,
    method: 'get',
    params:data.params,
    onSuccess: slice.actions.getFromBackingMore.type,
    onFail: slice.actions.getFromBackingMore.type
});

export const {clearSuccess} = slice.actions
export default slice.reducer
