
import './savdoqilingantulov.css'
import React, {useState, useEffect, useRef} from "react";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import SavdodagiTulovReducer, {
    getSavdotulov,
    getOneSavdo, getSavdoReport
} from '../reducer/SavdodagiTulovReducer'
import {connect} from 'react-redux'
import SavdoQoshishReducer, {getSavdolar, saveSavdolar} from "../../Savdo/reducer/SavdoQoshishReducer";
import {useTranslation} from "react-i18next";
import users from "../../../../../reducer/users";
import accountreducer, {getAccount} from "../../../../../reducer/accountreducer";
import formatDate, {prettify} from "../../../../../util";
import PayReducer, {getPay} from "../../../../../reducer/PayReducer";
import MijozGuruxReducer, {getMijozGurux, getMijozGuruxByBranch} from "../../Hamkorlar/reducer/MijozGuruxReducer";
import {DatePicker} from "antd";
import formatDateYear from "../../../../../reducer/sana";
import MainHeaderText from "../../../../Components/MainHeaderText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuItem from "@mui/material/MenuItem";
import CardBody from "../../../../Components/CardBody";
import CommonTable from "../../../../Components/CommonTable";
import moment from "moment/moment";
import ExcelButtons from "../../../../Components/ExcelButtons";

const {RangePicker} = DatePicker


function SavdodaTulov({
                          mijoz,
                          dukon,
                          summa,
                          eslatma,
                          getOneSavdo,
                          accountreducer,
                          getAccount,
                          users,
                          getMijozGuruxByBranch,
                          getSavdolar,
                          getSavdotulov,
                          SavdodagiTulovReducer,
                          SavdoQoshishReducer,
                          getPay,
                          PayReducer,
                          MijozGuruxReducer,
                          getMijozGurux,
                          getSavdoReport
                      }) {

    const {t} = useTranslation()
    const [inputvalue, setInputvalue] = useState(
        {
            view: '',
            izlash: ''
        }
    )
    const columns = [
        {
            title: 'Id',
            dataIndex: 'index',
            width: '3%',
        },
        {
            title: t('block.m'),
            dataIndex: 'name',
            key: 'name',
            width: '10%',

        },
        {
            title: t('ExpenseReport.7'),
            dataIndex: 'barcode',
            key: 'barcode',
            width: '10%',

        },
        {
            title: t('Hamkorlar.mijoz'),
            dataIndex: 'customerName',
            key: 'customerName',
            width: '7%',

        },
        {
            title: t('Hamkorlar.sana'),
            dataIndex: 'tradedDate',
            key: 'tradedDate',
            render: (item) => <p className={'m-0'}>{moment(new Date(item)).format('lll')}</p>,
            width: '10%',
        },
        {
            title: t('xisob.ChECK'),
            dataIndex: 'tradeProductId',
            key: 'tradeProductId',
            render: (item) => <a className={'m-0'} onClick={() => checktoggle(item)}>Check</a>,
            width: '10%',
        },

        {
            title: t('block.mi'),
            dataIndex: 'amount',
            key: 'amount',
            width: '12%',

        },
        {
            title: t('Purchase.8'),
            dataIndex: 'salePrice',
            key: 'salePrice',
            render: (item) => <p className={'m-0'}>{prettify(item, 3)} so'm</p>,
            width: '10%',

        },
        {
            title: t('Trade.14'),
            dataIndex: 'totalSum',
            key: 'totalSum',
            width: '10%',
        },
    ];


    const [branch, setbranch] = useState(null)
    const [customer, setCustomer] = useState(null)
    const [payMethod, setPayMethod] = useState(null)
    const [dates, setDates] = useState(null)

    function selectBranch(e) {
        setbranch(e.target.value)
        getMijozGuruxByBranch(e.target.value)
        getAccount(e.target.value)
        setCustomer('ALL')
        setPayMethod("ALL")
        getSavdoReport({
            branchId: e.target.value,
            customer: customer === "ALL" ? null : customer,
            payMethod: payMethod === "ALL" ? null : payMethod,
            startDate: dates ? dates[0] : null,
            endDate: dates ? dates[1] : null
        })
    }

    function selectCustomer(e) {
        setCustomer(e.target.value)
        getSavdoReport({
            branchId: branch ? branch : users.branchId,
            customer: e.target.value === "ALL" ? null : e.target.value,
            payMethod: payMethod === "ALL" ? null : payMethod,
            startDate: dates ? dates[0] : null,
            endDate: dates ? dates[1] : null
        })
    }

    function selectPayMethod(e) {
        setPayMethod(e.target.value)
        getSavdoReport({
            branchId: branch ? branch : users.branchId,
            customer: customer === "ALL" ? null : customer,
            payMethod: e.target.value === "ALL" ? null : e.target.value,
            startDate: dates ? dates[0] : null,
            endDate: dates ? dates[1] : null
        })
    }


    function view(e) {
        inputvalue.view = e.target.value
        let a = {...inputvalue}
        setInputvalue(a)
    }

    function izlash(e) {
        inputvalue.izlash = e.target.value
        let a = {...inputvalue}
        setInputvalue(a)
    }

    useEffect(() => {
        getSavdoReport({
            branchId: users.branchId,
            customer: null,
            payMethod: null,
            startDate: null,
            endDate: null
        })
        getPay(users.businessId)
        getAccount(branch ? branch : users.branchId)
        getMijozGuruxByBranch(branch ? branch : users.branchId)
    }, [])


    const [active, setActive] = useState(false)

    const [check, setCheck] = useState(false)

    function toggle() {
        setActive(!active)
    }

    function checktoggle(id) {
        setCheck(!check)
        getOneSavdo(id)
    }

    const [printactive, setprintactive] = useState(false)

    function printtoggle() {
        setprintactive(!printactive)
    }

    function print() {
        printtoggle()
        setCheck(!check)
    }

    const componentRef = useRef();

    const [totalProfit, setTotalProfit] = useState(0)
    return (
        <div className="col-md-12 mt-4 mt-4">
            <div class="row p-3">
                <MainHeaderText text={t('ExpenseReport.3')}/>
            </div>
            <div className="row px-2 align-items-center my-4">
                <div className="col-md-3">
                    <FormControl sx={{m: 1, minWidth: '100%'}} size="small">
                        <InputLabel id="demo-select-small-label">Baza</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={branch}
                            label="Baza"
                            IconComponent={KeyboardArrowDownIcon}
                            onChange={selectBranch}
                        >
                            <MenuItem value="">
                                <em>Tanlang</em>
                            </MenuItem>
                            {
                                users.branches.map(item =>
                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </div>
                <div className="col-md-3">
                    <FormControl sx={{m: 1, minWidth: '100%'}} size="small">
                        <InputLabel id="demo-select-small-label">{t('PurchaseList.6')}</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={customer}
                            label={t('PurchaseList.6')}
                            IconComponent={KeyboardArrowDownIcon}
                            onChange={selectCustomer}
                        >
                            <MenuItem value="">
                                <em>Tanlang</em>
                            </MenuItem>
                            {
                                MijozGuruxReducer.mijozgurux ?
                                    MijozGuruxReducer.mijozgurux.map(item =>
                                        <MenuItem value={item.id}>{item.name}</MenuItem>) : <MenuItem value="">
                                        <em>Tanlang</em>
                                    </MenuItem>
                            }
                        </Select>
                    </FormControl>
                </div>
                <div className="col-md-3">
                    <FormControl sx={{m: 1, minWidth: '100%'}} size="small">
                        <InputLabel id="demo-select-small-label">{t('Purchase.26')}</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={payMethod}
                            label={t('Purchase.26')}
                            IconComponent={KeyboardArrowDownIcon}
                            onChange={selectPayMethod}
                        >
                            <MenuItem value="">
                                <em>Tanlang</em>
                            </MenuItem>
                            {
                                PayReducer.paymethod ?
                                    PayReducer.paymethod.map(item =>
                                        <MenuItem value={item.id}>{item.type}</MenuItem>) : <MenuItem value="">
                                        <em>Tanlang</em>
                                    </MenuItem>
                            }
                        </Select>
                    </FormControl>
                </div>
                <div className="col-md-3">
                    <RangePicker
                        onChange={(values) => {
                            let a = []
                            if (values !== null) {
                                values.map(item => {
                                    a.push(formatDateYear(item))
                                })
                                setDates(a)
                                getSavdoReport({
                                    branchId: branch ? branch : users.branchId,
                                    startDate: a ? a[0] : null,
                                    endDate: a ? a[1] : null,
                                    payMethod: payMethod === "ALL" ? null : payMethod,
                                    customer: customer === "ALL" ? null : customer
                                })
                            } else {
                                setDates(null)
                                getSavdoReport({
                                    branchId: branch ? branch : users.branchId,
                                    startDate: null,
                                    endDate: null,
                                    payMethod: payMethod === "ALL" ? null : payMethod,
                                    customer: customer === "ALL" ? null : customer
                                })
                            }

                        }}
                    />
                </div>
            </div>
            <div class="row p-3">
                <div class="col-md-12">
                    <CardBody>
                        <div className="col-md-12">
                            <h5>Summary</h5>
                            <h3 className={'customer-debt'}>{t('BOSH.jamisavdo')} : <span className={'customer-debt-sum'}>{prettify(accountreducer.Purchases?.myTrade,3)} so'm</span>
                            </h3>
                            <h3 className={'customer-debt'}>{t('xisob.jsbd')}
                                <span className={'customer-debt-sum'}>{prettify(accountreducer.Purchases?.totalProfit,3)} so'm</span></h3>
                        </div>
                    </CardBody>
                </div>
            </div>

            <div class="row p-3">
                <div class="col-md-12">
                    <CardBody>
                        <div class="col-md-12">
                            <div className="qoshish my-4">
                                <h5>{t('Trade.1')}</h5>
                            </div>

                            {
                                SavdodagiTulovReducer.savdotulov ?
                                    <div>
                                        <ExcelButtons/>
                                        <div className="table-responsive">
                                            <CommonTable size={0} page={SavdodagiTulovReducer.savdotulov?.length}
                                                         pagination={false} data={SavdodagiTulovReducer.savdotulov}
                                                         columns={columns}
                                            />
                                        </div>
                                    </div> : <div>
                                        <h4 className={'text-center'}>{SavdodagiTulovReducer.message}</h4>
                                    </div>
                            }
                        </div>
                    </CardBody>
                </div>
            </div>
            <Modal isOpen={check} toggle={checktoggle} size={'xl'}>
                <ModalHeader>
                    Savdo ma'lumotlari
                </ModalHeader>
                <ModalBody>
                    <div className={'table-responsive'}>
                        <table className={'table table-striped table-primary table-hover border border-1'}>
                            <thead>
                            <tr>
                                <th>T/R</th>
                                <th>{t('block.th')}</th>
                                <th>{t('Pagination.10')}</th>
                                <th>{t('xisob.mqi')}</th>
                                <th>{t('Supplier.8')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                SavdodagiTulovReducer.savdoOne ?
                                    SavdodagiTulovReducer.savdoOne.map((item, index) => <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td> {item.paymentStatus?.status}</td>
                                        <td>{item.customer?.name}</td>
                                        <td>{item.customer?.debt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                        <td>{item.customer?.phoneNumber}</td>
                                    </tr>) : ''
                            }
                            </tbody>
                        </table>

                    </div>
                    <div className={'table-responsive'}>
                        <table className={'table mt-2 border border-1 table-striped table-hover'}>
                            <thead>
                            <tr>
                                <th>T/R</th>
                                <th>{t('block.m')}</th>
                                <th>{t('Trade.12')}</th>
                                <th>{t('ProductList.12')}</th>
                                {/*<th>Chegirma</th>*/}
                                {/*<th>Soliq</th>*/}
                                <th>{t('Trade.14')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                SavdodagiTulovReducer.savdoTwo.map((item, index) => <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.product ? item.product?.name : item.productTypePrice?.name}</td>
                                    <td>{item.tradedQuantity}</td>
                                    <td>{item.product ? item.product?.salePrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : item.productTypePrice?.salePrice}</td>
                                    {/*<td>0</td>*/}
                                    {/*<td>{item.product?.tax}</td>*/}
                                    <td>{(item.tradedQuantity * (item.product ? item.product?.salePrice : item.productTypePrice?.salePrice)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                </tr>)

                            }
                            </tbody>

                        </table>
                    </div>

                    {
                        SavdodagiTulovReducer.savdoOne.map((item, index) => <div className={'text-end'} key={index}>
                            {t(('Buttons.10'))} {item.totalSum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {t('Hamkorlar.sum')}
                        </div>)
                    }

                </ModalBody>
                <ModalFooter>
                    {/*<button className={'btn btn-outline-primary'}>Print</button>*/}
                    <button onClick={print} className={'btn btn-outline-primary'}>
                        {/*<ReactToPrint*/}
                        {/*    trigger={() => <p style={{marginBottom: 0}}>Print (Chek)</p>*/}
                        {/*    }*/}
                        {/*    content={() => componentRef.current}*/}
                        {/*/>*/}
                    </button>
                    <button onClick={checktoggle} className={'btn btn-outline-primary'}>{t('buttons.7')}</button>
                </ModalFooter>
            </Modal>

        </div>
    )
}

export default connect((SavdoQoshishReducer, accountreducer, users, SavdodagiTulovReducer, PayReducer, MijozGuruxReducer), {
    getOneSavdo,
    getPay,
    getMijozGurux,
    getAccount,
    getMijozGuruxByBranch,
    getSavdolar,
    getSavdoReport,
    getSavdotulov,
    saveSavdolar
})(SavdodaTulov)
