import { connect } from "react-redux";
import { useEffect } from 'react'
import { active } from "../../../../reducer/functionreducer";
import {useHistory, useLocation} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import users from "../../../../reducer/users";
import "./darsliklar.css"
import {Tabs} from "antd";
import DarsliklarRuyxati from "./DarsliklarRuyxati/DarsliklarRuyxati";
import DarslikniKorish from "./DarslikniKorish/DarslikniKorish";
import LessonUser from "./LessonUser/LessonUser";
import Test from "./Test/Test";

function Darsliklar({ changeLink, link, sidebaractive2, users }) {
    const {t} = useTranslation()
    const history = useHistory()
    const location = useLocation()


    const items = [
        {
            label: t('Sidebar1.druyhat'),
            key: '/main/lessons/table',
            children: <DarsliklarRuyxati/>,
        },
        {
            label: t('Sidebar1.dkurish'),
            key: '/main/lessons/lessonQuantity',
            children: <LessonUser/>,
        },
        {
            label: t('Sidebar1.drkurish'),
            key: '/main/lessons/lessonByUser',
            children: <DarslikniKorish/>,
        },
        {
            label: t('Sidebar1.test'),
            key: '/main/lessons/test',
            children: <Test/>,
        },
    ]

    useEffect(() => {
        !items.some(item=>item.key === location.pathname) && history.push('/main/lessons/table')
    }, []);
    const onChange = (key) => {
        history.push(key)
    };

    return (
        <div className={'row list'} id={'darsliklar'}>
            <h4 className={'p-3 mainText'}>{t('Sidebar1.darsliklar')}</h4>
            <div className={'px-3'}>
                <Tabs
                    activeKey={location.pathname}
                    onChange={onChange}
                    type="card"
                    items={items}
                />
            </div>
            {/*<ul>*/}
            {/*    {*/}
            {/*        users.viewLessonChecked && (*/}
            {/*            <li onClick={sidebaractive}><NavLink to={'/headerthird/darsliklar/darsliklarRuyxati'}*/}
            {/*                className={isActive => isActive ? 'active-enter-link li-text' : 'li-text'}>{t('Sidebar1.druyhat')}</NavLink>*/}
            {/*            </li>*/}
            {/*        )*/}
            {/*    }*/}
            {/*    {*/}
            {/*        users.viewLessonRoleChecked && (*/}
            {/*            <li onClick={sidebaractive}><NavLink to={'/headerthird/darsliklar/korish'}*/}
            {/*                className={isActive => isActive ? 'active-enter-link li-text' : 'li-text'}>{t('Sidebar1.dkurish')}</NavLink>*/}
            {/*            </li>*/}
            {/*        )*/}
            {/*    }*/}
            {/*    {*/}
            {/*        users.viewLessonChecked && (*/}
            {/*            <li onClick={sidebaractive}><NavLink to={'/headerthird/darsliklar/lessonview'}*/}
            {/*                className={isActive => isActive ? 'active-enter-link li-text' : 'li-text'}>{t('Sidebar1.drkurish')}</NavLink>*/}
            {/*            </li>*/}
            {/*        )*/}
            {/*    }*/}
            {/*    {*/}
            {/*        (users.viewLessonChecked || users.addLessonChecked) && (*/}
            {/*            <li onClick={sidebaractive}><NavLink to={'/headerthird/darsliklar/test'}*/}
            {/*                className={isActive => isActive ? 'active-enter-link li-text' : 'li-text'}>{t('Sidebar1.test')}</NavLink>*/}
            {/*            </li>*/}
            {/*        )*/}
            {/*    }*/}
            {/*</ul>*/}
        </div>
    )
}

export default connect((users), { active })(Darsliklar)
