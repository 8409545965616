import React, {useState, forwardRef, useEffect} from 'react';
import person_default from '../../../../../img/person-default.png'
import {
    Avatar,
    Box,
    Card,
    Checkbox,
    Grid,
    Slide,
    Tooltip,
    IconButton,
    InputAdornment,
    MenuItem,
    AvatarGroup,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableContainer,
    TableRow,
    TextField,
    Button,
    Typography,
    Dialog,
    FormControl,
    Select,
    InputLabel,
    styled
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import {useTranslation} from 'react-i18next';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import ProjectReducer, {
    deleteProject,
    getOneProject,
    getProjectPageAble,
    getProjectStatus,
    changeProjectStatus,getProjectPageByName
} from "../../../../../reducer/ProjectReducer";
import users from "../../../../../reducer/users";
import {connect} from "react-redux";
import formatDate from "../../../../../util";
import {Modal, ModalBody, ModalFooter, ModalHeader, Progress} from "reactstrap";
import {BaseUrl} from "../../../../../middleware";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ProjectTypeReducer from "../../../../../reducer/ProjectTypeReducer";
import MijozGuruxReducer from "../../Hamkorlar/reducer/MijozGuruxReducer";
import CircleIcon from "@mui/icons-material/Circle";
import {Link} from "react-router-dom";
import Loading from "../../../../Loading";

const DialogWrapper = styled(Dialog)(
    () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AvatarError = styled(Avatar)(
    ({theme}) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const ButtonError = styled(Button)(
    ({theme}) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const Results = ({
                     ProjectReducer,
                     users,
                     getProjectPageAble,
                     deleteProject,
                     getId,
                     mainBranchId,
                     setMainBranchId,
                     ProjectTypeReducer,
                     MijozGuruxReducer,
                     getProjectStatus,
                     changeProjectStatus,
                     getProjectPageByName
                 }) => {
    const {t} = useTranslation();
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(5);
    const [query, setQuery] = useState(null);

    const handleQueryChange = (event) => {
        event.persist();
        setQuery(event.target.value);
        if (event.target.value ===''){
            if(users.getProjectChecked){
                getProjectPageAble({
                    branchId: mainBranchId ? mainBranchId : users.branchId,
                    params: {
                        page: page,
                        size: limit
                    }
                })
            }else if(users.getOwnProjectChecked){
                getProjectPageAble({
                    branchId: mainBranchId ? mainBranchId : users.branchId,
                    params: {
                        page: page,
                        size: limit,
                        userId: users.id
                    }
                })
            }
        }
        else{
            getProjectPageByName({
                branchId: mainBranchId ? mainBranchId : users.branchId,
                name:event.target.value,
                params: {
                    page: page,
                    size: limit,
                }
            })
        }
    };

    const handlePageChange = (_event, newPage) => {
        setPage(newPage);
        if (query){
            getProjectPageByName({
                branchId: mainBranchId ? mainBranchId : users.branchId,
                name:query,
                params: {
                    page: newPage,
                    size: limit,
                }
            })
        }
        else{
            if(users.getProjectChecked){
                getProjectPageAble({
                    branchId: mainBranchId ? mainBranchId : users.branchId,
                    params: {
                        page: newPage,
                        size: limit,
                        typeId: projectTypeIdFilter ? projectTypeIdFilter : null,
                        projectStatusId: projectStatusFilter ? projectStatusFilter : null,
                        customerId: customerIdFilter ? customerIdFilter : null,
                        expired: deadlineTimer ? formatDate(new Date()) : null
                    }
                })
            }else if(users.getOwnProjectChecked){
                getProjectPageAble({
                    branchId: mainBranchId ? mainBranchId : users.branchId,
                    params: {
                        page: newPage,
                        size: limit,
                        typeId: projectTypeIdFilter ? projectTypeIdFilter : null,
                        projectStatusId: projectStatusFilter ? projectStatusFilter : null,
                        customerId: customerIdFilter ? customerIdFilter : null,
                        expired: deadlineTimer ? formatDate(new Date()) : null,
                        userId: users.id
                    }
                })
            }
        }
    };

    const handleLimitChange = (event) => {
        setLimit(parseInt(event.target.value));
        if (query){
            getProjectPageByName({
                branchId: mainBranchId ? mainBranchId : users.branchId,
                name:event.target.value,
                params: {
                    page: page,
                    size: parseInt(event.target.value),
                }
            })
        }
        else{
            if(users.getProjectChecked){
                getProjectPageAble({
                    branchId: mainBranchId ? mainBranchId : users.branchId,
                    params: {
                        page: page,
                        size: parseInt(event.target.value),
                        typeId: projectTypeIdFilter ? projectTypeIdFilter : null,
                        projectStatusId: projectStatusFilter ? projectStatusFilter : null,
                        customerId: customerIdFilter ? customerIdFilter : null,
                        expired: deadlineTimer ? formatDate(new Date()) : null
                    }
                })
            }else if(users.getOwnProjectChecked){
                getProjectPageAble({
                    branchId: mainBranchId ? mainBranchId : users.branchId,
                    params: {
                        page: page,
                        size: parseInt(event.target.value),
                        typeId: projectTypeIdFilter ? projectTypeIdFilter : null,
                        projectStatusId: projectStatusFilter ? projectStatusFilter : null,
                        customerId: customerIdFilter ? customerIdFilter : null,
                        expired: deadlineTimer ? formatDate(new Date()) : null,
                        userId: users.id
                    }
                })
            }
        }

    };

    const changeProjectStatusById = (e, id) => {
        changeProjectStatus({
            statusId: e.target.value,
            projectId: id
        })
    }

    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [deleteId, setDeleteID] = useState('')

    const handleConfirmDelete = () => {
        setOpenConfirmDelete(true);
    };

    const closeConfirmDelete = () => {
        setOpenConfirmDelete(false);
    };

    function deleteFunc(id) {
        handleConfirmDelete()
        setDeleteID(id)
    }

    const handleDeleteCompleted = () => {
        deleteProject(deleteId)
        setDeleteID('')
        setOpenConfirmDelete(false);
    };

    useEffect(() => {
        if(users.getProjectChecked){
            getProjectPageAble({
                branchId: mainBranchId ? mainBranchId : users.branchId,
                params: {
                    page: page,
                    size: limit
                }
            })
        }else if(users.getOwnProjectChecked){
            getProjectPageAble({
                branchId: mainBranchId ? mainBranchId : users.branchId,
                params: {
                    page: page,
                    size: limit,
                    userId: users.id
                }
            })
        }
        getProjectStatus(mainBranchId ? mainBranchId : users.branchId)
    }, [ProjectReducer.current, mainBranchId])

    const [deadlineTimer, setDeadlineTimer] = useState(false)
    const [customerIdFilter, setCustomerIdFilter] = useState(null)
    const [projectTypeIdFilter, setProjectTypeIdFilter] = useState(null)
    const [projectStatusFilter, setProjectStatusFilter] = useState(null)
    const [activeFilter, setActiveFilter] = useState(false)

    function getOk() {
        if(users.getProjectChecked){
            getProjectPageAble({
                branchId: mainBranchId ? mainBranchId : users.branchId,
                params: {
                    page: page,
                    size: limit,
                    typeId: projectTypeIdFilter ? projectTypeIdFilter : null,
                    projectStatusId: projectStatusFilter ? projectStatusFilter : null,
                    customerId: customerIdFilter ? customerIdFilter : null,
                    expired: deadlineTimer ? formatDate(new Date()) : null
                }
            })
        }else if(users.getOwnProjectChecked){
            getProjectPageAble({
                branchId: mainBranchId ? mainBranchId : users.branchId,
                params: {
                    page: page,
                    size: limit,
                    typeId: projectTypeIdFilter ? projectTypeIdFilter : null,
                    projectStatusId: projectStatusFilter ? projectStatusFilter : null,
                    customerId: customerIdFilter ? customerIdFilter : null,
                    expired: deadlineTimer ? formatDate(new Date()) : null,
                    userId: users.id
                }
            })
        }
        setActiveFilter(false)
    }

    function getClear() {
        setCustomerIdFilter(null)
        setProjectTypeIdFilter(null)
        setProjectStatusFilter(null)
        if(users.getProjectChecked){
            getProjectPageAble({
                branchId: mainBranchId ? mainBranchId : users.branchId,
                params: {
                    page: page,
                    size: limit,
                    endDate: null,
                    startDate: null,
                    projectStatusId: null,
                    typeId: null
                }
            })
        }else if(users.getOwnProjectChecked){
            getProjectPageAble({
                branchId: mainBranchId ? mainBranchId : users.branchId,
                params: {
                    page: page,
                    size: limit,
                    endDate: null,
                    startDate: null,
                    projectStatusId: null,
                    typeId: null,
                    userId: users.id
                }
            })
        }
        setActiveFilter(false)
    }

    const [loading,setLoading] = useState(false)

    useEffect(()=>{
        setLoading(true)
    },[ProjectReducer.getBoolean])

    useEffect(()=>{
       setLoading(false)
    },[])

    return (
        <>
            <Card
                sx={{
                    p: 1,
                    mb: 3
                }}
            >
                <Grid container xs={12}>
                    <Grid item xs={7}>
                        <Box p={1}>
                            <TextField
                                sx={{
                                    m: 0
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchTwoToneIcon/>
                                        </InputAdornment>
                                    )
                                }}
                                label={'Search'}
                                onChange={handleQueryChange}
                                placeholder={t('Izlash...')}
                                value={query}
                                fullWidth
                                variant="outlined"
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box p={1}>
                            {
                                users.branches.length > 1 ?
                                    users.branches ?
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Tanlash</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={mainBranchId ? mainBranchId : users.branchId}
                                                label="Tanlash"
                                                onChange={(e) => {
                                                    setMainBranchId(e.target.value)
                                                    getClear()
                                                }}
                                                name={'branchId'}
                                            >
                                                {
                                                    users?.branches.map((item) =>
                                                        <MenuItem value={item.id}>{item.name}</MenuItem>
                                                    )}
                                            </Select>
                                        </FormControl> : ""
                                    : ''
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                        <Grid
                            item
                            xs={12}
                            md={12}
                            display="flex"
                            justifyContent={{xs: 'center', md: 'flex-end'}}
                        >
                            <Box p={1}>
                                <Button color={'primary'}
                                        variant="contained"
                                        className={'mt-2'}
                                        onClick={() => setActiveFilter(prevState => !prevState)}
                                >
                                    <FilterAltIcon/>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
            {
                activeFilter ?
                    <Box
                        display="flex"
                        alignItems="center"
                        flexDirection={{xs: 'column', sm: 'row'}}
                        justifyContent={{xs: 'center', sm: 'space-between'}}
                        pb={3}
                    >
                        <Grid item xs={12} md={3} lg={3}>
                            {
                                ProjectTypeReducer.projectType ?
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Project Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={projectTypeIdFilter}
                                            label="Project Type"
                                            onChange={(e) => {
                                                setProjectTypeIdFilter(e.target.value)
                                            }}
                                        >
                                            {
                                                ProjectTypeReducer.projectType ?
                                                    ProjectTypeReducer.projectType.map(item =>
                                                        <MenuItem className={'mb-1 mt-1'}
                                                                  value={item.id}>{item.name}</MenuItem>
                                                    )
                                                    : <MenuItem value={'--'}>---</MenuItem>
                                            }
                                        </Select>
                                    </FormControl>
                                    : ''
                            }
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            {
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Customer</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={customerIdFilter}
                                        label="Customer"
                                        onChange={(e) => {
                                            setCustomerIdFilter(e.target.value)
                                        }}
                                    >
                                        {
                                            MijozGuruxReducer.mijozgurux ?
                                                MijozGuruxReducer.mijozgurux.map(item =>
                                                    <MenuItem className={'mb-1 mt-1'}
                                                              value={item.id}>{item.name}</MenuItem>
                                                )
                                                : <MenuItem value={'--'}>---</MenuItem>
                                        }
                                    </Select>
                                </FormControl>
                            }
                        </Grid>
                        {
                            ProjectReducer.projectStatus ?
                                <Grid item xs={12} md={3} lg={3}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={projectStatusFilter}
                                            label="Status"
                                            onChange={(e) => {
                                                setProjectStatusFilter(e.target.value)
                                            }}
                                            name={'Status'}
                                        >
                                            {
                                                ProjectReducer.projectStatus ?
                                                    ProjectReducer.projectStatus.map(item =>
                                                        <MenuItem className={'mb-1 mt-1'}
                                                                  value={item.id}>{item.name}</MenuItem>
                                                    )
                                                    : <MenuItem value={'--'}>---</MenuItem>
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                : ''
                        }
                        <Grid item xs={12} md={3} lg={3} display={'flex'} justifyContent={'center'}
                              alignItems={'center'}>
                            <Checkbox
                                checked={deadlineTimer}
                                onClick={(e) => setDeadlineTimer(e.target.checked)}
                                sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                            />
                            <Typography variant={'h3'}>
                                Deadline
                            </Typography>
                        </Grid>


                        <Button className={'ms-2'}
                                sx={{
                                    mt: {xs: 2, sm: 0}
                                }}
                                onClick={getOk}
                                variant="contained"
                                startIcon={<PublishedWithChangesIcon fontSize="small"/>}
                        >
                            {t('OK')}
                        </Button>
                        <Button className={'ms-2'}
                                sx={{
                                    mt: {xs: 2, sm: 0}
                                }}
                                onClick={getClear}
                                variant="contained"
                                startIcon={<HighlightOffIcon fontSize="small"/>}
                        >
                            {t('Clear')}
                        </Button>
                    </Box> : ''
            }
            {
                users.getProjectChecked || users.getOwnProjectChecked ?
                    <Card>
                        <>
                            {
                                loading ?
                                ProjectReducer.projectPage ?
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        #
                                                    </TableCell>
                                                    <TableCell>{t('Loyiha nomi')}</TableCell>
                                                    <TableCell>{t('Ishtirokchilar')}</TableCell>
                                                    <TableCell>{t('Deadline')}</TableCell>
                                                    <TableCell>{t('Mijoz')}</TableCell>
                                                    <TableCell>{t('Jarayon')}</TableCell>
                                                    <TableCell className={'text-center'}>{t('Status')}</TableCell>
                                                    <TableCell align="center">{t('Amal')}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {

                                                    ProjectReducer.projectPage?.content.map((item, index) => <TableRow
                                                        key={index}>
                                                        <TableCell>{index + 1}</TableCell>
                                                        <TableCell>{item.name}</TableCell>
                                                        <TableCell>
                                                            <Typography display={'flex'}>
                                                                {item.users?.length > 0 && (
                                                                    <AvatarGroup max={4}>
                                                                        {item.users.map((member) => (
                                                                            <Tooltip
                                                                                arrow
                                                                                placement="top"
                                                                                key={member?.firstName}
                                                                                title={member?.firstName + " " + member?.lastName}
                                                                            >
                                                                                <Avatar
                                                                                    sx={{
                                                                                        width: 30,
                                                                                        height: 30
                                                                                    }}
                                                                                    key={member?.id}
                                                                                    src={member?.photo?.id ? `${BaseUrl}/attachment/download/${member?.photo?.id}` : person_default}
                                                                                />
                                                                            </Tooltip>
                                                                        ))}
                                                                    </AvatarGroup>
                                                                )}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>{formatDate(item.deadline)}</TableCell>
                                                        <TableCell>{item.customer?.name}</TableCell>
                                                        <TableCell className={'text-center'}>
                                                            <Progress animated value={item.process} style={{
                                                                fontSize: '12px',
                                                                textAlign: 'center',
                                                                fontWeight: '700'
                                                            }} max={100}>
                                                                {item.process} %
                                                            </Progress>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography>
                                                                <FormControl fullWidth>
                                                                    <InputLabel
                                                                        id="demo-simple-select-label">Status</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={item?.projectStatus?.id}
                                                                        label="Status"
                                                                        onChange={(e) => changeProjectStatusById(e, item.id)}
                                                                        name={'status'}
                                                                    >
                                                                        {
                                                                            ProjectReducer.projectStatus ?
                                                                                ProjectReducer.projectStatus.map(item =>
                                                                                    <MenuItem
                                                                                        className={'d-flex align-item-center'}
                                                                                        value={item.id}><CircleIcon style={{
                                                                                        color: item.color,
                                                                                        marginRight: '8px'
                                                                                    }}/> {item.name}</MenuItem>
                                                                                ) : <MenuItem>---</MenuItem>
                                                                        }
                                                                    </Select>
                                                                </FormControl>
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography noWrap>
                                                                <Link to={`/headerthird/projects/${item.id}`}>
                                                                    <Tooltip title={t('View')} arrow>
                                                                        <IconButton color="primary">
                                                                            <LaunchTwoToneIcon fontSize="small"/>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Link>
                                                                {
                                                                    users.editProjectChecked ?
                                                                        <Tooltip title={t('Edit')} arrow>
                                                                            <EditIcon style={{cursor: "pointer"}}
                                                                                      onClick={() => getId(item.id)}
                                                                                      color="primary"
                                                                            >
                                                                                <DeleteTwoToneIcon fontSize="small"/>
                                                                            </EditIcon>
                                                                        </Tooltip>:''
                                                                }
                                                                {
                                                                    users.deleteProjectChecked ?
                                                                        <Tooltip title={t('Delete')} arrow>
                                                                            <IconButton
                                                                                onClick={() => deleteFunc(item.id)}
                                                                                color="primary"
                                                                            >
                                                                                <DeleteTwoToneIcon fontSize="small"/>
                                                                            </IconButton>
                                                                        </Tooltip>:''
                                                                }
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>)
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    : <h4 className={'text-center'}>{t('Darslik.mtt')}</h4>
                                    : <Loading/>
                            }
                            <Box p={2}>
                                <TablePagination
                                    component="div"
                                    count={ProjectReducer.projectPage ? ProjectReducer.projectPage.totalElements : 0}
                                    onPageChange={handlePageChange}
                                    onRowsPerPageChange={handleLimitChange}
                                    page={page}
                                    rowsPerPage={limit}
                                    rowsPerPageOptions={[5, 10, 15]}
                                />
                            </Box>
                        </>
                    </Card>
                        :''
            }
            {/*VIEW STATIC*/}
            <Modal>
                <ModalHeader>
                    <h4>Ko'rish</h4>
                </ModalHeader>
                <ModalBody>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Ajratilgan muddat</TableCell>
                                    <TableCell>Sarflangan muddat</TableCell>
                                    <TableCell>Qolgan muddat</TableCell>
                                    <TableCell>Bajarilgan foizi</TableCell>
                                    <TableCell align={'center'}>Bajarilgan tasklar</TableCell>
                                    <TableCell>Boshlash sana</TableCell>
                                    <TableCell>Deadline</TableCell>
                                    <TableCell>Taxminiy tugashi</TableCell>
                                    <TableCell align={'center'}>Necha kun erta/kech bajarilishi</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    ProjectReducer.static?
                                        <TableRow>
                                            <TableCell>{ProjectReducer.static?.goalDay} kun</TableCell>
                                            <TableCell>{ProjectReducer.static?.leftDay} kun</TableCell>
                                            <TableCell>{ProjectReducer.static?.remainDay} kun</TableCell>
                                            <TableCell>{ProjectReducer.static?.percent} %</TableCell>
                                            <TableCell align={'center'}>
                                                {ProjectReducer.static?.completedTask+ '/'+ProjectReducer.static?.totalTask}
                                            </TableCell>
                                            <TableCell>{formatDate(ProjectReducer.static?.startDate)}</TableCell>
                                            <TableCell>{formatDate(ProjectReducer.static?.deadline)}</TableCell>
                                            <TableCell>{formatDate(ProjectReducer.static?.approximateDate)}</TableCell>
                                            <TableCell align={'center'}>{ProjectReducer.static?.lateDay}</TableCell>
                                        </TableRow>
                                        :<h4>{t('Darslik.mtt')}</h4>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
            </Modal>
            <DialogWrapper
                open={openConfirmDelete}
                maxWidth="sm"
                fullWidth
                TransitionComponent={Transition}
                keepMounted
                onClose={closeConfirmDelete}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    p={5}
                >
                    <AvatarError>
                        <CloseIcon/>
                    </AvatarError>
                    <Typography
                        align="center"
                        sx={{
                            pt: 4,
                            px: 6
                        }}
                        variant="h3"
                    >
                        {t('Do you really want to delete this project')}?
                    </Typography>
                    <Typography
                        align="center"
                        sx={{
                            pt: 2,
                            pb: 4,
                            px: 6
                        }}
                        fontWeight="normal"
                        color="text.secondary"
                        variant="h4"
                    >
                        {t("You won't be able to revert after deletion")}
                    </Typography>
                    <Box>
                        <Button
                            variant="text"
                            size="large"
                            sx={{
                                mx: 1
                            }}
                            onClick={closeConfirmDelete}
                        >
                            {t('Cancel')}
                        </Button>
                        <ButtonError
                            onClick={handleDeleteCompleted}
                            size="large"
                            sx={{
                                mx: 1,
                                px: 3
                            }}
                            variant="contained"
                        >
                            {t('Delete')}
                        </ButtonError>
                    </Box>
                </Box>
            </DialogWrapper>
        </>
    );
};

export default connect((ProjectReducer, ProjectTypeReducer, MijozGuruxReducer, users), {
    getProjectPageAble,
    getOneProject,
    deleteProject,
    getProjectStatus,
    changeProjectStatus,
    getProjectPageByName
})(Results);
