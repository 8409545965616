import {
  Grid,
  Box,
  Card,
  Typography,
  Divider,
  Tooltip,
  Stack,
  IconButton,
  Avatar,
  alpha,
  styled,
  useTheme
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';

import ThumbUpTwoToneIcon from '@mui/icons-material/ThumbUpTwoTone';
import AddAlertTwoToneIcon from '@mui/icons-material/AddAlertTwoTone';
// import Text from '/Users/mac/Documents/optimit frontend/src/components/Text';
import Text from '../../../../../../components/Text'
import PersonTwoToneIcon from '@mui/icons-material/PersonTwoTone';
import MonetizationOnTwoToneIcon from '@mui/icons-material/MonetizationOnTwoTone';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import AddLocationTwoToneIcon from '@mui/icons-material/AddLocationTwoTone';
import AddBusinessTwoToneIcon from '@mui/icons-material/AddBusinessTwoTone';
import {connect} from "react-redux";
import MaxsulotlarRoyxariReducer from "../../reducer/MaxsulotlarRoyxariReducer";
import users from "../../../../../../reducer/users";
import FirmaReducer, {getFirma} from "../../reducer/FirmaReducer";

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
    padding: ${theme.spacing(1.5)};
    color: ${theme.palette.primary.contrastText};
    transform: translateY(0px);
    transition: ${theme.transitions.create([
      'color',
      'transform',
      'background'
    ])};
    
    .MuiSvgIcon-root {
        transform: scale(1);
        transition: ${theme.transitions.create(['transform'])};
    }

    &:hover {
        background: initial;
        transform: translateY(-2px);

        .MuiSvgIcon-root {
            transform: scale(1.2);
        }
    }
  `
);
const germanCurrencyFormatter = new Intl.NumberFormat("uz", { notation: "standard"});

function Block1({active,toggle,MaxsulotlarRoyxariReducer}){
  const { t } = useTranslation();
  const theme = useTheme();
    const {measurement} = MaxsulotlarRoyxariReducer.product
  return (
    <Grid container spacing={4} style={{marginRight:"15px"}}>
      <Grid item xs={12} md={6}>
        <Card
          sx={{
            p: 2.5,
              background: `${theme.colors.gradients.purple3}`
          }}
        >
          <Box
            pb={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography
                gutterBottom
                component="div"
                variant="caption"
                sx={{
                  color: `${theme.colors.alpha.trueWhite[70]}`
                }}
              >
                {t('pro.bazaqm')}
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  color: `${theme.colors.alpha.trueWhite[100]}`
                }}
              >
                  {germanCurrencyFormatter.format(parseFloat(MaxsulotlarRoyxariReducer.productOneOtherInfo?.productPageDto?.amount).toFixed(2))} {measurement?.name}
              </Typography>
            </Box>
            <Avatar
              variant="rounded"
              sx={{
                width: `${theme.spacing(7)}`,
                height: `${theme.spacing(7)}`,
                background: `${theme.colors.alpha.trueWhite[100]}`,
                color: `${theme.colors.success.main}`
              }}
            >
              <AccountBoxTwoToneIcon />
            </Avatar>
          </Box>

        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card
          sx={{
            p: 2.5,
            background: `${theme.colors.gradients.orange1}`

          }}
        >
          <Box
            pb={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography
                gutterBottom
                component="div"
                variant="caption"
                sx={{
                  color: `${theme.colors.alpha.trueWhite[70]}`
                }}
              >
                {t('pro.Sotilganmiqdori')}
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  color: `${theme.colors.alpha.trueWhite[100]}`
                }}
              >
                  {germanCurrencyFormatter.format(parseFloat(MaxsulotlarRoyxariReducer.productOneOtherInfo?.productPageDto?.soldAmount).toFixed(2))} {measurement?.name}
              </Typography>
            </Box>
            <Avatar
              variant="rounded"
              sx={{
                width: `${theme.spacing(7)}`,
                height: `${theme.spacing(7)}`,
                background: `${theme.colors.alpha.trueWhite[100]}`,
                color: `${theme.colors.warning.main}`
              }}
            >
              <ThumbUpTwoToneIcon />
            </Avatar>
          </Box>

        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card
          sx={{
            p: 2.5,
            background: `${theme.colors.gradients.green2}`
          }}
        >
          <Box
            pb={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography
                gutterBottom
                component="div"
                variant="caption"
                sx={{
                  color: `${theme.colors.alpha.trueWhite[70]}`
                }}
              >
                {t('pro.haridyichm')}
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  color: `${theme.colors.alpha.trueWhite[100]}`
                }}
              >
                  {germanCurrencyFormatter.format(parseFloat(MaxsulotlarRoyxariReducer.productOneOtherInfo?.productPageDto?.purchaseAmount).toFixed(2))} {measurement?.name}
              </Typography>
            </Box>
            <Avatar
              variant="rounded"
              sx={{
                width: `${theme.spacing(7)}`,
                height: `${theme.spacing(7)}`,
                background: `${alpha(theme.colors.alpha.trueWhite[100], 0.2)}`,
                color: `${theme.colors.alpha.trueWhite[100]}`
              }}
            >
              <AddAlertTwoToneIcon />
            </Avatar>
          </Box>

        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card
          sx={{
            p: 2.5,
            background: `#ff4b4b`
          }}
        >
          <Box
            pb={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography
                gutterBottom
                component="div"
                variant="caption"
                sx={{
                  color: `${theme.colors.alpha.trueWhite[70]}`
                }}
              >
                {t('pro.mijozskqkm')}
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  color: `${theme.colors.alpha.trueWhite[100]}`
                }}
              >
                  {germanCurrencyFormatter.format(parseFloat(MaxsulotlarRoyxariReducer.productOneOtherInfo?.productPageDto?.returnAmount).toFixed(2))} {measurement?.name}
              </Typography>
            </Box>
            <Avatar
              variant="rounded"
              sx={{
                width: `${theme.spacing(7)}`,
                height: `${theme.spacing(7)}`,
                background: `${alpha(theme.colors.alpha.trueWhite[100], 0.2)}`,
                color: `${theme.colors.alpha.trueWhite[100]}`
              }}
            >
              <AddAlertTwoToneIcon />
            </Avatar>
          </Box>

        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent="center"
            alignItems="stretch"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={0}
          >
            <Box
              display="flex"
              flex={1}
              justifyContent="space-evenly"
              alignItems="stretch"
            >
              <Box
                p={3}
                sx={{
                  textAlign: 'center'
                }}
              >
                <Text color="warning">
                  <MonetizationOnTwoToneIcon />
                </Text>
                <Typography variant="subtitle2">{t('pro.qolganmon')}</Typography>
                  <Typography variant="h5">{germanCurrencyFormatter.format(parseFloat(MaxsulotlarRoyxariReducer.productOneOtherInfo?.productPageDto?.buyPrice).toFixed(2))} so'm</Typography>

              </Box>
              <Box
                p={3}
                sx={{
                  textAlign: 'center'
                }}
              >
                <Text color="success">
                  <PersonTwoToneIcon />
                </Text>
                <Typography variant="subtitle2">{t('Qolgan maxsulot sotish narxida')}</Typography>
                  <Typography variant="h5">{germanCurrencyFormatter.format(parseFloat(MaxsulotlarRoyxariReducer.productOneOtherInfo?.productPageDto?.salePrice).toFixed(2))} so'm</Typography>

              </Box>
            </Box>

              <Box
                  display="flex"
                  flex={1}
                  justifyContent="space-evenly"
                  alignItems="stretch"
              >
                  <Box
                      p={3}
                      sx={{
                          textAlign: 'center'
                      }}
                  >
                      <Text color="warning">
                          <MonetizationOnTwoToneIcon />
                      </Text>
                      <Typography variant="subtitle2">{t('pro.sotilganms')}</Typography>
                      <Typography variant="h5">{germanCurrencyFormatter.format(parseFloat(MaxsulotlarRoyxariReducer.productOneOtherInfo?.productPageDto?.soldPrice).toFixed(2))} so'm</Typography>

                  </Box>
                  <Box
                      p={3}
                      sx={{
                          textAlign: 'center'
                      }}
                  >
                      <Text color="success">
                          <PersonTwoToneIcon />
                      </Text>
                      <Typography variant="subtitle2">{t('pro.umdss')}</Typography>
                      <Typography variant="h5">{germanCurrencyFormatter.format(parseFloat(MaxsulotlarRoyxariReducer.productOneOtherInfo?.productPageDto?.profit).toFixed(2))} so'm</Typography>

                  </Box>
              </Box>
          </Stack>
        </Card>
      </Grid>
    </Grid>
  );
}

export default connect((MaxsulotlarRoyxariReducer, users, FirmaReducer), {
    getFirma
})(Block1)

