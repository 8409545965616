import {connect} from "react-redux";
import {useEffect, useState} from "react";
import {useHistory} from 'react-router-dom'
import {useTranslation} from "react-i18next";
import {Button, Col, DatePicker, Form, Input, InputNumber, Row, Select as SelectAnt} from "antd";
import dayjs from "dayjs";

import users from "../../../../../reducer/users";
import XarajatlarReducer, {
    editXarajatlar, getXarajatOne,
    saveXarajatlar
} from "../reducer/XarajatlarReducer";
import XodimReducer, {getXodimByBranch} from "../../Hodimlar/reducer/XodimReducer";
import XarajatTurlariReducer, {
  getXarajatlarTurlariByBranch
} from "../reducer/XarajatTurlariReducer";
import PayReducer, {getPay} from "../../../../../reducer/PayReducer";

import './xarajatQoshish.css'

function XarajatQoshish({
                          getXarajatlarTurlariByBranch,
                          editXarajatlar,
                          users,
                          getXarajatOne,
                          saveXarajatlar,
                          match,
                          getPay,
                          PayReducer,
                          XarajatlarReducer,
                          XodimReducer,
                          getXodimByBranch,
                          XarajatTurlariReducer
                        }) {
    const {t} = useTranslation()
    const [form] = Form.useForm();
    const history = useHistory()
    const [pageData, setPageData] = useState({
      branch: users.branchId,
    });

    useEffect(() => {
      getPay(users.businessId)
      form.setFieldsValue({
        branchId: users?.branchId,
        date: dayjs()
      })
      if (match.params.id) getXarajatOne(match.params.id)
    }, [])

    useEffect(() => {
        if (XarajatlarReducer.saveOutlaysBool) {
          history.push('/main/outlays/list')
          form.resetFields();
        }
    }, [XarajatlarReducer.counter])

    useEffect(() => {
        if (match.params.id) {
          const data = XarajatlarReducer.getOne
          form.setFieldsValue({
            description: data?.description,
            date: dayjs(data?.date),
            branchId: data?.branch?.id,
            spenderId: data?.spender?.id,
            outlayCategoryId: data?.outlayCategory?.id,
            paymentMethodId: data?.paymentMethod?.id,
            totalSum: data?.totalSum
          })
        }
    }, [XarajatlarReducer.counter])

    useEffect(() => {
        getXarajatlarTurlariByBranch(pageData.branch)
        getXodimByBranch(pageData.branch)
    }, [pageData.branch])

    const onFinish = (values) => {
      match.params.id ? editXarajatlar({...values, id: match.params.id}) : saveXarajatlar(values)
    };

    return (
      <div className="outlayCost">
        <div className="contenerX">
          <div className="d-flex justify-content-center mb-3">
            <h5>{t('Sections.8')}</h5>
          </div>

          <Form
            form={form}
            layout="vertical"
            name="outlayCost"
            onFinish={onFinish}
          >
            <Row gutter={12}>
              <Col span={24} md={12} lg={8}>
                <Form.Item
                  name="branchId"
                  label={t('cost.baza')}
                  rules={[
                    {
                      required: true,
                    }
                  ]}
                >
                  <SelectAnt
                    showSearch
                    optionFilterProp="children"
                    placeholder={`${t('cost.baza')}...`}
                    size="middle"
                    onChange={(e) => {
                      setPageData((prev) => ({...prev, branch: e}) )
                      form.setFieldsValue({
                        outlayCategoryId: null,
                        spenderId: null
                      })
                    }}
                  >
                    {
                      users.branches.map((item, index) => <SelectAnt.Option key={index} value={item.id}>{item.name}</SelectAnt.Option>)
                    }
                  </SelectAnt>
                </Form.Item>
              </Col>
              <Col span={24} md={12} lg={8}>
                <Form.Item
                  name="date"
                  label={t('Trade.4')}
                  rules={[
                    {
                      required: true,
                    }
                  ]}
                >
                  <DatePicker showNow={true} className="w-100" placeholder={`${t('Trade.4')}...`} />
                </Form.Item>
              </Col>
              <Col span={24} md={12} lg={8}>
                <Form.Item
                  name="outlayCategoryId"
                  label={t('Trade.24')}
                  rules={[
                    {
                      required: true,
                    }
                  ]}
                >
                  <SelectAnt
                    showSearch
                    optionFilterProp="children"
                    placeholder={`${t('Trade.24')}...`}
                    size="middle"
                  >
                    {
                      XarajatTurlariReducer.xarajatturlari?.map((item, index) => <SelectAnt.Option key={index} value={item.id}>{item.title}</SelectAnt.Option>)
                    }
                  </SelectAnt>
                </Form.Item>
              </Col>
              <Col span={24} md={12} lg={8}>
                <Form.Item
                  name="spenderId"
                  label={t('Trade.25')}
                  rules={[
                    {
                      required: true,
                    }
                  ]}
                >
                  <SelectAnt
                    showSearch
                    optionFilterProp="children"
                    placeholder={`${t('Trade.25')}...`}
                    size="middle"
                  >
                    {
                      XodimReducer.xodimlar?.map((item, index) => <SelectAnt.Option key={index} value={item.id}>{item.firstName} {item.lastName}</SelectAnt.Option>)
                    }
                  </SelectAnt>
                </Form.Item>
              </Col>
              <Col span={24} md={12} lg={8}>
                <Form.Item
                  name="paymentMethodId"
                  label={t('Hamkorlar.tu')}
                  rules={[
                    {
                      required: true,
                    }
                  ]}
                >
                  <SelectAnt
                    placeholder={`${t('Hamkorlar.tu')}...`}
                    size="middle">
                    {
                      PayReducer.paymethod?.map((item, index) => <SelectAnt.Option key={index} value={item.id}>{item.type}</SelectAnt.Option>)
                    }
                  </SelectAnt>
                </Form.Item>
              </Col>
              <Col span={24} md={12} lg={8}>
                <Form.Item
                  name="totalSum"
                  label={t('Expenses.6')}
                  rules={[
                    {
                      required: true,
                    }
                  ]}
                >
                  <InputNumber className="w-100" placeholder={`${t('Expenses.6')}...`} />
                </Form.Item>
              </Col>
              <Col span={24} md={12} lg={8}>
                <Form.Item
                  name="description"
                  label={t('Buttons.17')}
                >
                  <Input placeholder={`${t('Buttons.17')}...`} />
                </Form.Item>
              </Col>
              <Col span={24} className="mt-2">
                <Form.Item>
                  <Button className="buttonAddCost" type="primary" htmlType="submit">
                    {match.params.id ? t('Buttons.1') : t('Buttons.2')}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    )
}

export default connect((
  XarajatTurlariReducer, PayReducer, XodimReducer, users, XarajatlarReducer), {
    getXarajatlarTurlariByBranch,
    editXarajatlar,
    getPay,
    getXarajatOne,
    saveXarajatlar,
    getXodimByBranch
})(XarajatQoshish)

