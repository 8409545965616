import React, {useState, useEffect} from 'react';
import { Tree, TreeNode } from "react-organizational-chart";
import {
    Box,
    Button,
    Grid,
    Card,
    CardHeader,
    IconButton,
    Avatar,
    Tabs,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography,
    AvatarGroup
} from '@mui/material';
import _ from "lodash";
import {Link} from "react-router-dom";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import {styled} from "@mui/material/styles";
import TableRowsTwoToneIcon from "@mui/icons-material/TableRowsTwoTone";
import GridViewTwoToneIcon from "@mui/icons-material/GridViewTwoTone";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import users from "../../../../../../reducer/users";
import LavozimReducer, {getLavozim} from "../../../Hodimlar/reducer/LavozimReducer";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import {axiosCreate, BaseUrl} from "../../../../../../middleware";
import CheckboxTree from 'react-checkbox-tree';
import { FaUserTie, FaUser } from "react-icons/fa";
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import 'react-tree-graph/dist/style.css'
import "./orgChart.css"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import person from "../../../../../../img/person-default.png";

function OrgStruktura({LavozimReducer,getLavozim,users}) {
    const {t} = useTranslation();
    const [userStruc, setUserStruc] = useState({})
    const [usersData, setUsersData] = useState([])
    const [expanded, setExpanded] = useState()
    const [filters, setFilters] = useState({
        role: null
    });
    const [toggleView, setToggleView] = useState('table_view');

    function Organization({ org, onCollapse }) {
        let backgroundColor = "white";
        const son = usersData.filter((qism) => {
            return qism.roleName === org.name
        })
        return (
                <Card
                    variant="outlined"
                    style={{ backgroundColor, width:'max-content', margin: 'auto' }}
                >
                    <CardHeader
                        avatar={
                            <AvatarGroup max={4}>
                                {son[0]?.list?.map((member) => (
                                    <Tooltip
                                        arrow
                                        placement="top"
                                        key={member?.id}
                                        title={member?.fio}
                                    >
                                        <Avatar
                                            style={{backgroundColor: '#ccc'}}
                                            sx={{
                                                width: 30,
                                                height: 30
                                            }}
                                            key={member?.id}
                                            src={member?.attachmentId ? `${BaseUrl}/attachment/download/${member?.attachmentId}` : person}
                                        />
                                    </Tooltip>
                                ))}
                            </AvatarGroup>
                        }
                        title={org.name}
                        style={{padding:'10px 35px 5px'}}
                    />

                    {org.children ? (
                        <IconButton
                            size="small"
                            onClick={onCollapse}
                            style={{paddingBottom: 0}}
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    ) : null}
                </Card>
        );
    }

    function Node({ o, parent }) {
        const [collapsed, setCollapsed] = React.useState(o.collapsed);
        const handleCollapse = () => {
            setCollapsed(!collapsed);
        };
        React.useEffect(() => {
            o.collapsed = collapsed;
        });
        const T = parent
            ? TreeNode
            : (props) => (
                <Tree
                    {...props}
                    lineWidth={"2px"}
                    lineColor={"#bbc"}
                    lineBorderRadius={"12px"}
                >
                    {props.children}
                </Tree>
            );
        return collapsed ? (
            <T
                label={
                    <Organization
                        org={o}
                        onCollapse={handleCollapse}
                        collapsed={collapsed}
                    />
                }
            />
        ) : (
            <T
                label={
                    <Organization
                        org={o}
                        onCollapse={handleCollapse}
                        collapsed={collapsed}
                    />
                }
            >
                {_.map(o.children, (c) => (
                    <Node o={c} parent={o} />
                ))}
            </T>
        );
    }

    const handleViewOrientation = (_event, newValue) => {
        setToggleView(newValue);
    };

    const TabsWrapper = styled(Tabs)(
        ({theme}) => `
        @media (max-width: ${theme.breakpoints.values.md}px) {
          .MuiTabs-scrollableX {
            overflow-x: auto !important;
          }
    
          .MuiTabs-indicator {
              box-shadow: none;
          }
        }
    `
    );
    const handleTabsChange = (_event, tabsValue) => {
        let value = null;

        if (tabsValue !== 'all') {
            value = tabsValue;
        }

        setFilters((prevFilters) => ({
            ...prevFilters,
            role: value
        }));

    };

    function lavozimDaraxti(lavozimlar) {
        let lavozimlarMap = new Map();
        for (let lavozim of lavozimlar) {
            lavozimlarMap.set(lavozim.id, lavozim);
        }
        let rootLavozim = null;
        for (let lavozim of lavozimlar) {
            if (lavozim.name === "Admin") {
                rootLavozim = lavozim;
            } else if(lavozim.parentRole === null){
                console.error('qul')
            }else {
                let parentLavozim = lavozimlarMap.get(lavozim.parentRole);
                if (!parentLavozim.children) {
                    parentLavozim.children = [];
                }
                parentLavozim.children.push(lavozim);
            }
        }
        return rootLavozim;
    }
    useEffect(()=>{
       getLavozim(users.businessId)
        axiosCreate.get(`/role/get-by-business/${users.businessId}`).then((data) =>{
            const qism = data?.data?.object?.map((item) => {
                return({
                    id: item.id,
                    name: item.name,
                    value: item.name,
                    label: item.name,
                    parentRole: item.parentRole,
                    showCheckbox:false,
                })
            })
            setExpanded(qism.map((qism) => qism.value))
            const lavaozim = lavozimDaraxti(qism)
            setUserStruc(lavaozim)
        })
        axiosCreate.get(`/role/get-by-business-role/${users.businessId}`).then((data) =>{
            const qism = data?.data?.object
            setUsersData(qism)
        })
    },[])

    const [displayTrue, setDisplayTrue] = useState(false)
    function full(){
        setDisplayTrue(!displayTrue)
    }

    return (
        <>
            <div className={'colorback mt-4 m-3 p-4 row'}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h4" component="h4" gutterBottom>
                        {t('Org.org')}
                        </Typography>

                    </Grid>

                </Grid>

                <Box
                    display="flex"
                    alignItems="center"
                    flexDirection={{xs: 'column', sm: 'row'}}
                    justifyContent={{xs: 'center', sm: 'space-between'}}
                    pb={3}
                >
                    <div className={'d-flex mt-4 mb-4'}>
                        <Grid item>
                            <Link to={'/main/addRole'}>
                                <Button sx={{mt: {xs: 2, sm: 0}}}
                                        variant="contained"
                                        startIcon={<AddTwoToneIcon fontSize="small"/>}
                                >
                                    {t('Org.qlo')}
                                </Button>
                            </Link>
                        </Grid>

                    </div>
                </Box>
                {
                    LavozimReducer.lavozimlar?
                <div className={`tbodyY p-2 border border-2 ${displayTrue?'col-md-12':'col-md-6'}`} style={{minHeight:'400px'}}>
                    <div className={'d-flex justify-content-end p-1'} >
                        <OpenInFullIcon style={{cursor:'pointer',position:'absolute',zIndex:'100'}} onClick={full}/>
                    </div>
                    {userStruc && <CheckboxTree
                        iconsClass="fa5"
                        nodes={[userStruc]}
                        showExpandAll={true}
                        expanded={expanded}
                        onExpand={(expanded) => setExpanded(expanded)}
                        icons={{
                            parentClose: <FaUserTie fontSize={20}/>,
                            parentOpen: <FaUserTie fontSize={20}/>,
                            leaf: <FaUser fontSize={20} />
                        }}
                    />}
                </div>:'not'
                }
                <div className={`orgGraph tbodyY border border-2 ${displayTrue?'col-md-12 mt-2':'col-md-6'}`} style={{minHeight:'400px', overflow: 'scroll'}}>
                    <h5 className={'text-center'}>{t('Org.ok')}</h5>
                    <Box bgcolor="background" height={600} width={'100%'} >
                        <Node o={userStruc} />
                    </Box>
                </div>
            </div>
        </>
    );
}
export default connect((users,LavozimReducer), {getLavozim})(OrgStruktura)