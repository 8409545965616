import React, {useState, useEffect} from 'react';
import {
    Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl,
    Grid, IconButton, InputLabel, MenuItem, Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead, TablePagination,
    TableRow, Tabs, TextField, ToggleButton, ToggleButtonGroup, Tooltip,
    Typography
} from '@mui/material';
import {Link} from "react-router-dom";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import {styled} from "@mui/material/styles";
import TableRowsTwoToneIcon from "@mui/icons-material/TableRowsTwoTone";
import GridViewTwoToneIcon from "@mui/icons-material/GridViewTwoTone";
import {Formik} from "formik";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import users from "../../../../../../reducer/users";
import {formatIcon} from "../../../../../../util";
import bonusReducer,{saveBonus,getBonus,editBonus,changeBonusStatus,deleteBonus} from "../../../../../../reducer/bonusReducer";
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import WatchIcon from '@mui/icons-material/Watch';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import CoffeeIcon from '@mui/icons-material/Coffee';
import ExtensionIcon from '@mui/icons-material/Extension';
import PaidIcon from '@mui/icons-material/Paid';
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Loading from "../../../../../Loading";
import ModalLoading from "../../../../../ModalLoading";
import "./award.css"

function Award({saveBonus,users,getBonus,bonusReducer,editBonus,changeBonusStatus,deleteBonus}) {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);


    useEffect(()=>{
       getBonus(users.businessId)
    },[bonusReducer.current])

     function handleConfirmDelete(id){
            deleteBonus(id)
         setLoading(false)
    }

    const [editId,setEditId] = useState(null)

    const [statusFormik, setStatusFormik] = useState({
        name: '',
        color: '#000000',
        icon: '',
        summa:0
    })

    function handleConfirmEdit(id){
            handleCreateUserOpen()
        setEditId(id)
            if (bonusReducer.bonus){
                let a = bonusReducer.bonus.filter(item=>item.id === id)
                statusFormik.name = a[0].name
                statusFormik.color = a[0].color
                statusFormik.icon = a[0].icon
                statusFormik.summa = a[0].summa
            }
            let b = {...statusFormik}
            setStatusFormik(b)
    }


    function handleChangeStatus(id){
        changeBonusStatus(id)
    }

    const handleCreateUserOpen = () => {
        setOpen(true);
    };

    const handleCreateUserClose = () => {
        setOpen(false);
        setEditId(null)
        setStatusFormik({
            name: '',
            color: '#000000',
            icon: '',
            summa:0
        })
    };



    const [filters, setFilters] = useState({
        role: null
    });

    const [toggleView, setToggleView] = useState('table_view');
    const handleViewOrientation = (_event, newValue) => {
        setToggleView(newValue);
    };

    const TabsWrapper = styled(Tabs)(
        ({theme}) => `
    @media (max-width: ${theme.breakpoints.values.md}px) {
      .MuiTabs-scrollableX {
        overflow-x: auto !important;
      }

      .MuiTabs-indicator {
          box-shadow: none;
      }
    }
    `
    );
    const handleTabsChange = (_event, tabsValue) => {
        let value = null;

        if (tabsValue !== 'all') {
            value = tabsValue;
        }

        setFilters((prevFilters) => ({
            ...prevFilters,
            role: value
        }));

    };

    const [loading,setLoading] = useState(false)
    const [modal,setModal] =  useState(false)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(()=>{
       if (bonusReducer.saveBoolean){
           setLoading(false)
           handleCreateUserClose()
       }
       setModal(false)
    },[bonusReducer.saveBoolean])

    useEffect(()=>{
       setLoading(true)
    },[bonusReducer.getBoolean])

    useEffect(()=>{
       setLoading(false)
    },[])

    const handleChangePage = (_event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            <div className={'colorback mt-4 m-3 p-4'}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h4" component="h4" gutterBottom>
                            {t('ol.22')} 
                        </Typography>

                    </Grid>

                </Grid>

                <Box
                    display="flex"
                    alignItems="center"
                    flexDirection={{xs: 'column', sm: 'row'}}
                    justifyContent={{xs: 'center', sm: 'space-between'}}
                    pb={3}
                >
                    <div className={'d-flex mt-4 mb-4'}>
                        {
                            users.addBonusChecked?
                                <Grid item >
                                    <Button
                                        sx={{
                                            mt: {xs: 2, sm: 0}
                                        }}
                                        onClick={handleCreateUserOpen}
                                        variant="contained"
                                        startIcon={<AddTwoToneIcon fontSize="small"/>}
                                    >
                                        {t('ol.23')}
                                    </Button>
                                </Grid>:''
                        }


                    </div>
                </Box>
                <div className={'mb-2'}>
                    <input type="search" placeholder={t('ol.1')} className={'form-control'}/>
                </div>

                {
                    users.getBonusChecked ?
                    loading ?
                    bonusReducer.bonus ?
                        <>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow  className={'text-center'}>
                                            <TableCell>Tr</TableCell>
                                            <TableCell>{t('ol.24')}</TableCell>
                                            <TableCell>{t('ol.25')}</TableCell>
                                            <TableCell>{t('ol.26')}</TableCell>
                                            <TableCell>{t('ol.27')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {bonusReducer.bonus?.map((item,index) =>
                                            <TableRow  className={'text-center'}>
                                                <TableCell>{index+1}</TableCell>
                                                <TableCell>
                                                    <Typography variant="h5">
                                                        {formatIcon(item.icon,item.color)}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    {item.name}
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>
                                                        <FormControl fullWidth>
                                                            <InputLabel id="demo-simple-select-label">{t('ol.26')}</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={item.active}
                                                                label={t('ol.24')}
                                                                onChange={()=>handleChangeStatus(item.id)}
                                                                name={'icon'}
                                                            >
                                                                <MenuItem className={'d-flex align-item-center'}  value={true}><CheckCircleIcon style={{color:'green',marginRight:'20px'}}/>{t('ol.28')}</MenuItem>
                                                                <MenuItem className={'d-flex align-item-center'} value={false}><CancelIcon style={{color:'red',marginRight:'20px'}}/>{t('ol.29')}</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="center">
                                                    {
                                                        users.editBonusChecked?
                                                            <Tooltip title={t('ol.14')} arrow>
                                                                <IconButton
                                                                    onClick={()=>handleConfirmEdit(item.id)}
                                                                    color="primary"
                                                                >
                                                                    <EditTwoToneIcon fontSize="small" />
                                                                </IconButton>
                                                            </Tooltip>:''
                                                    }
                                                    {
                                                        users.deleteBonusChecked?
                                                            <Tooltip title={t('ol.15')} arrow>
                                                                <IconButton
                                                                    onClick={()=>handleConfirmDelete(item.id)}
                                                                    color="primary"
                                                                >
                                                                    <DeleteTwoToneIcon fontSize="small" />
                                                                </IconButton>
                                                            </Tooltip>:''
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        <Box className={'d-flex justify-content-end'} p={2}>
                            <TablePagination
                                component="div"
                                count={bonusReducer?.bonus?.length}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                rowsPerPageOptions={[5, 10, 15]}
                            />
                        </Box>
                        </>
                        : <div>
                        <h4 className={'text-center'}>{bonusReducer.message}</h4>
                        </div>: <Loading/> :''
                }

            </div>

            <Dialog
                fullWidth
                maxWidth="md"
                open={open}
                onClose={handleCreateUserClose}
            >
                <DialogTitle
                    sx={{
                        p: 3
                    }}
                >
                    <Typography variant="h4" gutterBottom>
                        {t('ol.30')}
                    </Typography>
                </DialogTitle>
                <Formik
                    initialValues={statusFormik}
                    validationSchema={Yup.object().shape({
                        name: Yup.string()
                            .max(255)
                            .required(t('ol.31')),
                        color: Yup.string()
                            .max(255)
                            .required(t('ol.32')),
                        icon: Yup.string()
                            .max(255)
                            .required(t('ol.33')),
                        summa: Yup.string()
                            .max(255)
                            .required(t('ol.34'))
                    })}
                    onSubmit={async (
                        _values,
                    ) => {
                        if (editId){
                            editBonus({
                                ..._values,
                                businessId:users.businessId,
                                id:editId
                            })
                        }
                        else{
                            saveBonus({
                                ..._values,
                                businessId:users.businessId,
                            })
                        }
                            setModal(true)
                    }}
                >
                    {({
                          errors,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          touched,
                          values
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <DialogContent
                                dividers
                                sx={{
                                    p: 3
                                }}
                            >
                                <Grid container spacing={3}>
                                    <Grid item xs={12} lg={12}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} lg={6}>
                                                <TextField
                                                    error={Boolean(touched.name && errors.name)}
                                                    fullWidth
                                                    helperText={touched.name && errors.name}
                                                    label={t('ol.35')}
                                                    name="name"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.name}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item xs={12} lg={6}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Icon</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={values.icon}
                                                        label="Icon"
                                                        onChange={handleChange}
                                                        name={'icon'}
                                                    >
                                                        <MenuItem value={'1'}> ---</MenuItem>
                                                        <MenuItem value={'2'}><EmojiEventsIcon className={'me-3'}/>{t('ol.36')} </MenuItem>
                                                        <MenuItem value={'3'}><ThumbUpIcon className={'me-3'}/>{t('ol.37')}</MenuItem>
                                                        <MenuItem value={'4'} ><MilitaryTechIcon className={'me-3'}/>{t('ol.38')} </MenuItem>
                                                        <MenuItem value={'5'} ><CardGiftcardIcon className={'me-3'}/>{t('ol.39')} </MenuItem>
                                                        <MenuItem value={'6'}><WatchIcon className={'me-3'}/>{t('ol.40')}</MenuItem>
                                                        <MenuItem value={'7'}><MenuBookIcon className={'me-3'}/>{t('ol.41')} </MenuItem>
                                                        <MenuItem value={'8'}><CoffeeIcon className={'me-3'}/>{t('ol.42')} </MenuItem>
                                                        <MenuItem value={'9'}><ExtensionIcon className={'me-3'}/>{t('ol.43')} </MenuItem>
                                                        <MenuItem value={'10'}><AirplanemodeActiveIcon className={'me-3'}/>{t('ol.44')} </MenuItem>
                                                        <MenuItem value={'11'}><PaidIcon className={'me-3'}/>{t('ol.45')} </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid display={'flex'} item xs={6} lg={6}>
                                                <Grid item xs={9} lg={9}>
                                                    <TextField
                                                        error={Boolean(touched.color && errors.color)}
                                                        fullWidth
                                                        helperText={touched.color && errors.color}
                                                        label={t('Color')}
                                                        name="color"
                                                        value={values.color}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={3} lg={3}
                                                >
                                                    <TextField
                                                        error={Boolean(touched.color && errors.color)}
                                                        fullWidth
                                                        type='color'
                                                        helperText={touched.color && errors.color}
                                                        label={t('ol.46')}
                                                        name="color"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.color}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item  xs={6} lg={6}  display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                {formatIcon(values.icon,values.color)}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    error={Boolean(touched.summa && errors.summa)}
                                                    fullWidth
                                                    margin="normal"
                                                    helperText={touched.summa && errors.summa}
                                                    label={t('ol.47')}
                                                    name="summa"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    type="number"
                                                    value={values.summa}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions
                                sx={{
                                    p: 3
                                }}
                            >
                                <Button color="secondary" onClick={handleCreateUserClose}>
                                    {t('ol.48')}
                                </Button>
                                <Button
                                    type="submit"
                                    startIcon={
                                        isSubmitting ? <CircularProgress size="1rem"/> : null
                                    }
                                    disabled={Boolean(errors.submit) || isSubmitting}
                                    variant="contained"
                                >
                                    {t('ol.49')}
                                </Button>
                            </DialogActions>
                        </form>
                    )}
                </Formik>
            </Dialog>
            <ModalLoading isOpen={modal}/>
        </>
    );
}

// export default Lids;
export default connect((users,bonusReducer), {saveBonus,getBonus,editBonus,changeBonusStatus,deleteBonus})(Award)
