import './hr.css'
import {useHistory, useLocation} from 'react-router-dom'
import {useEffect} from 'react'
import {connect} from "react-redux";
import users from "../../../../reducer/users";
import {Tabs} from "antd";
import Jobs from "./Jobs/Jobs";
import Salary from "./Salary/Salary";
import Reword from "./Reword/Reword";
import OrgStruktura from "./OrgStruktura/OrgStruktura";
import Attendance from "./Davomad/Attendance";
import {useTranslation} from "react-i18next";
import HodimlarRoyhati from "../Hodimlar/hodimlarRoyxati/HodimlarRoyhati";
import Lavozimlar from "../Hodimlar/Lavozimlar/Lavozimlar";

function HR({sidebaractive2, changeLink, link, users}) {
    const {t} = useTranslation()
    const history = useHistory()
    const location = useLocation()


    const items = [
        {
            label: t("HR.hodimlar"),
            key: '/main/hr/userTable',
            children:<HodimlarRoyhati/>,
            check:[]
        },
        {
            label: t("Sidebar.3"),
            key: '/main/hr/roleTable',
            children:<Lavozimlar/>,
        },
        {
            label: t('ad.109'),
            key: '/main/hr/attendance',
            children: <Attendance/>,
        },
        {
            label: t('HR.kasblar'),
            key: '/main/hr/jobs',
            children: <Jobs/>,
        },
        {
            label: t('HR.maoshlar'),
            key: '/main/hr/salary',
            children: <Salary/>,
        },
        {
            label: t('HR.org'),
            key: '/main/hr/org',
            children: <OrgStruktura/>,
        },
        {
            label: t('HR.mukofot'),
            key: '/main/hr/reword',
            children: <Reword/>,
        }
    ]

    useEffect(() => {
        !items.some(item=>item.key === location.pathname) && history.push('/main/hr/userTable')
    }, []);
    const onChange = (key) => {
        history.push(key)
    };
    return (
        <div>
            <h4 className={'p-3 mainText'}>Hr</h4>
            <div className={'px-3'}>
                <Tabs
                    activeKey={location.pathname}
                    onChange={onChange}
                    type="card"
                    items={items}
                />
            </div>
            {/*<div className={'hodimul'}>*/}
            {/*    <ul>*/}
            {/*        {*/}
            {/*            users.addUser || users.editUser || users.viewUserAdmin || users.deleteUser || users.viewUser ?*/}
            {/*                <li onClick={sidebaractive}><NavLink to={'/headerthird/hodimlarruyxati'}*/}
            {/*                                                     className={isActive => isActive ? 'active-enter-link lll li-text' : 'lll li-text'}>{t('HR.hodimlar')}</NavLink>*/}
            {/*                </li>*/}
            {/*                : ''*/}
            {/*        }*/}

            {/*        {*/}
            {/*            users.addRole || users.editRole || users.viewRoleAdmin || users.deleteRole || users.viewRole ?*/}
            {/*                <li onClick={sidebaractive}><NavLink to={'/headerthird/lavozimlar'}*/}
            {/*                                                     className={isActive => isActive ? 'active-enter-link lll li-text' : 'lll li-text'}>{t('Sidebar.3')}</NavLink>*/}
            {/*                </li>:''*/}
            {/*        }*/}

            {/*        {*/}
            {/*            users.addWorkTimeChecked || users.viewAttendanceChecked ?*/}
            {/*                <li onClick={sidebaractive}><NavLink to={'/headerthird/hr/attendance'}*/}
            {/*                                                     className={isActive => isActive ? 'active-enter-link lll li-text' : 'lll li-text'}>{t('HR.davomat')}</NavLink>*/}
            {/*                </li>:''*/}
            {/*        }*/}
            {/*        {*/}
            {/*            users.addJobChecked || users.editJobChecked || users.deleteJobChecked || users.viewJobChecked?*/}
            {/*                <li onClick={sidebaractive}><NavLink to={'/headerthird/hr/jobs'}*/}
            {/*                                                     className={isActive => isActive ? 'active-enter-link lll li-text' : 'lll li-text'}>{t('HR.kasblar')}</NavLink>*/}
            {/*                </li>:''*/}
            {/*        }*/}
            {/*        {*/}
            {/*            users.viewSalaryChecked || users.editSalaryChecked ?*/}
            {/*                <li onClick={sidebaractive}><NavLink to={'/headerthird/hr/salary'}*/}
            {/*                                                     className={isActive => isActive ? 'active-enter-link lll li-text' : 'lll li-text'}>{t('HR.maoshlar')}</NavLink>*/}
            {/*                </li>:''*/}
            {/*        }*/}
            {/*        {*/}
            {/*            users.viewOrgChecked ?*/}
            {/*                <li onClick={sidebaractive}><NavLink to={'/headerthird/hr/orgStructure'}*/}
            {/*                                                     className={isActive => isActive ? 'active-enter-link lll li-text' : 'lll li-text'}>{t('HR.org')}</NavLink>*/}
            {/*                </li>:''*/}
            {/*        }*/}
            {/*        {*/}
            {/*            users.viewPrizeChecked || users.addPrizeChecked || users.addBonusChecked || users.getBonusChecked || users.editBonusChecked || users.deleteBonusChecked ?*/}
            {/*                <li onClick={sidebaractive}><NavLink to={'/headerthird/hr/encourage'}*/}
            {/*                                                     className={isActive => isActive ? 'active-enter-link lll li-text' : 'lll li-text'}>{t('HR.mukofot')}</NavLink>*/}
            {/*                </li>:''*/}
            {/*        }*/}
            {/*        {*/}
            {/*            users.viewPrizeChecked || users.addPrizeChecked || users.addBonusChecked || users.getBonusChecked || users.editBonusChecked || users.deleteBonusChecked ?*/}
            {/*                <li onClick={sidebaractive}><NavLink to={'/headerthird/hr/shablon'}*/}
            {/*                                                     className={isActive => isActive ? 'active-enter-link lll li-text' : 'lll li-text'}>{t('HR.smslar')}</NavLink>*/}
            {/*                </li>:''*/}
            {/*        }*/}

            {/*        /!*<li onClick={sidebaractive}><Link  to={'/headerthird/hodimulush'} className={"lll li-text"}>Xodimlar ulushi</Link>   </li>*!/*/}
            {/*    </ul>*/}

            {/*</div>*/}


        </div>
    )
}

export default connect((users), {})(HR)
