import {connect} from "react-redux";
import {useEffect, useState} from "react";
import {useHistory} from 'react-router-dom'
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import {Checkbox, DatePicker, Input, Popconfirm} from "antd";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import {DeleteButton, EditButton, QushishButton} from "../../../../Components/Buttons";
import CommonTable from "../../../../Components/CommonTable";
import ExcelButtons from "../../../../Components/ExcelButtons";
import users from '../../../../../reducer/users'
import XarajatlarReducer, {
    deleteXarajatlar,
    getXarajatlar2,
    getXarajatlar3,
    getXarajatlar,
} from "../reducer/XarajatlarReducer";
import XarajatTurlariReducer, {
    getXarajatlarTurlari,
    getXarajatlarTurlariByBranch
} from "../reducer/XarajatTurlariReducer";
import formatDate, {setLocalStorage, tablePageSize} from "../../../../../util";

import './xarajatlarRoyxati.css'

function XarajatlarRoyxati({
                               getXarajatlar,
                               getXarajatlar2,
                               users,
                               deleteXarajatlar,
                               getXarajatlarTurlari,
                               getXarajatlarTurlariByBranch,
                               XarajatlarReducer,
                               XarajatTurlariReducer
                           }) {

    const {t} = useTranslation()
    const history = useHistory()
    const [pageData, setPageData] = useState({
        page: 0,
        pageSize: tablePageSize ? tablePageSize : 10,
        branch: users.viewOutlayAdmin ? "ALL" : users.branchId,
        outlayType: "ALL",
        loading: false,
        searchValue: ""
    });
    const [openCheckList, setOpenCheckList] = useState(false)
    const columns = [
        {
            key: "id",
            title: 'Id',
            dataIndex: 'index',
            width: '3%',
            fixed: 'left',
        },
        {
            title: t('cost.sh'),
            dataIndex: 'spender',
            key: 'spender',
            render: (item) => item?.firstName
        },
        {
            title: t('Trade.4'),
            dataIndex: 'date',
            key: 'date',
            render: (item) => formatDate(item)
        },
        {
            title: t('ProductList.8'),
            dataIndex: 'branch',
            key: 'branch',
            render: (item) => item?.name
        },
        {
            title: t('Expenses.6'),
            dataIndex: 'totalSum',
            key: 'totalSum',
            render: (item) => `${item} ${t('Hamkorlar.sum')}`
        },
        {
            title: t('Expenses.8'),
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: t('Davomat.amal'),
            dataIndex: 'profit',
            key: 'profit',
            render: (item, record) => <div className="d-flex align-items-center gap-2">
                {
                    users.editOutlay ? <EditButton onClick={() => history.push(`/main/outlaysAdd/${record.id}`)} buttonText={t('Buttons.1')} />
                      : null
                }
                {
                    users.deleteOutlay ?
                      <Popconfirm
                        title={`${t('Buttons.12')}?`}
                        onConfirm={() => deleteXarajatlar(record.id)}
                        okText={t('Buttons.3')}
                        cancelText={t('Buttons.7')}
                      >
                          <DeleteButton buttonText={t('Buttons.3')} />
                      </Popconfirm>
                      : null
                }
            </div>,
        },
    ];
    const defaultCheckedList = columns.map((item) => item.key);
    const [checkedList, setCheckedList] = useState(defaultCheckedList);
    const options = columns.map(({ key, title }) => ({
        label: title,
        value: key,
    }));
    const newColumns = columns.map((item) => ({
        ...item,
        hidden: !checkedList.includes(item.key),
    }));

    function changeDate(e) {
        setPageData((prev) => ({...prev,date: dayjs(e).format("YYYY-MM-DD"), page: 0, searchValue: "", loading: true}))
    }

    function searchOutlay(e) {
        setPageData( (prev) => ({...prev, searchValue: e?.target?.value}))
    }

    useEffect(()=>{
        setPageData( (prev) => ({...prev, loading: false}))
    },[XarajatlarReducer.getOutlaysBool])

    useEffect(()=>{
        setPageData( (prev) => ({...prev, loading: true}))
    },[])

    useEffect(() => {
        pageData.branch === "ALL" ? getXarajatlarTurlari(users.businessId) : getXarajatlarTurlariByBranch(pageData.branch)
        pageData.branch === "ALL" ? getXarajatlar(users.businessId) : getXarajatlar2(pageData.branch)
    }, [XarajatlarReducer.counter, pageData.branch])

    const newChange = (pageNumber, page) => {
        setPageData((prev) => ({ ...prev, pageSize: page, page: pageNumber - 1 }));
        setLocalStorage("pageSize", page)
    };

    function selectBranch(e) {
        setPageData((prev) => ({...prev,branch: e.target.value, page: 0, searchValue: "", loading: true}))
    }

    function selectOutlayType(e) {
        setPageData((prev) => ({...prev,outlayType: e.target.value, page: 0, searchValue: "", loading: true}))
    }

    return (
        <div className="col-md-12 mb-4">
            {
                users.viewOutlayAdmin || users.viewOutlay ?
                  <div className="rowStyleHRR">
                      <div className="qoshish">
                          <h3>{t('Buttons.16')}</h3>
                      </div>
                      <div className="row align-items-center pt-4">
                          <div className="col-lg-4 col-md-6 pb-3 pb-md-4">
                              <FormControl sx={{minWidth: '100%'}}>
                                  <InputLabel className="selectLabelXarajat" id="demo-select-small-label">{t('cost.baza')}</InputLabel>
                                  <Select
                                    className="selectXarajat"
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={pageData.branch}
                                    label={t('cost.baza')}
                                    IconComponent={KeyboardArrowDownIcon}
                                    onChange={selectBranch}
                                  >
                                      {
                                        users.viewOutlayAdmin && <MenuItem value="ALL">{t('Hamkorlar.b')}</MenuItem>
                                      }
                                      {
                                          users.branches?.map(item =>
                                            <MenuItem value={item.id}>{item.name}</MenuItem>
                                          )
                                      }
                                  </Select>
                              </FormControl>
                          </div>
                          <div className="col-lg-4 col-md-6 pb-3 pb-md-4">
                              <FormControl sx={{minWidth: '100%'}} size="small">
                                  <InputLabel className="selectLabelXarajat" id="demo-select-small-label">{t('Expenses.3')}</InputLabel>
                                  <Select
                                    className="selectXarajat"
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={pageData.outlayType}
                                    label={t('Expenses.3')}
                                    IconComponent={KeyboardArrowDownIcon}
                                    onChange={selectOutlayType}
                                  >
                                      <MenuItem value="ALL">{t('BOSH.barchasi')}</MenuItem>
                                      {
                                          XarajatTurlariReducer.xarajatturlari?.map(item =>
                                            <MenuItem value={item.id}>{item.title}</MenuItem>
                                          )
                                      }
                                  </Select>
                              </FormControl>
                          </div>
                          <div className="col-lg-4 col-md-6 pb-3 pb-md-4">
                              <DatePicker className="dateXarajat" placeholder={t('Trade.3')} style={{borderRadius: 10, width: "100%"}} onChange={changeDate} />
                          </div>
                      </div>
                  </div> : ''
            }
            <div className="rowStyleHRR2">
                {
                    users.viewOutlayAdmin || users.viewOutlay ?
                      <div>
                          <div className="qoshish mb-3 gap-3">
                              <ExcelButtons setOpenCheckList={setOpenCheckList}/>
                              {
                                  users.addOutlay ? <QushishButton onClick={() => history.push("/main/outlaysAdd")}
                                                                   buttonText={t('Buttons.2')}/> : null
                              }
                          </div>
                          <div className="mb-3">
                              <Input allowClear rootClassName="w-full searchOutlay" onChange={searchOutlay}
                                     placeholder={t("num.37")}/>
                          </div>
                          <div className={`${openCheckList ? "animatsiyaBlock mb-2" : "hiddenBlock"}`}>
                              <Checkbox.Group
                                style={{rowGap: 8}}
                                value={checkedList}
                                options={options}
                                onChange={(value) => {
                                    setCheckedList(value);
                                }}
                              />
                          </div>
                          <CommonTable
                            loading={pageData.loading}
                            scroll={{x: 1200}}
                            total={XarajatlarReducer.xarajatlar?.filter((val) => pageData.searchValue === '' ? val : val.spender?.firstName?.toUpperCase().includes(pageData.searchValue.toUpperCase()) && val)?.length}
                            handlePageChange={newChange}
                            pagination={true}
                            page={pageData.page}
                            size={pageData.pageSize}
                            data={XarajatlarReducer.xarajatlar?.filter((val) => pageData.searchValue === '' ? val : val.spender?.firstName?.toUpperCase().includes(pageData.searchValue.toUpperCase()) && val)}
                            columns={newColumns}
                          />
                      </div>
                      : null
                }
            </div>
        </div>
    )
}

export default connect((XarajatlarReducer, XarajatTurlariReducer, users), {
    getXarajatlar,
    getXarajatlar2,
    getXarajatlar3,
    deleteXarajatlar,
    getXarajatlarTurlari,
    getXarajatlarTurlariByBranch
})(XarajatlarRoyxati)
