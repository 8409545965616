import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../../../../../../api";
import {toast} from "react-toastify";
import {message} from "antd";
// import {toast} from "react-toastify";

const slice = createSlice({
    name: 'lidStatus',
    initialState: {
        lidStatus: null,
        current:false,
        message:'',
        lidOne: null,
        getBoolean: false,
        saveBoolean: false,
        changeBoolean: false,
        deleteBoolean: false,
        descriptionCurrent: false
    },
    reducers: {
        getFrom: (state, action) => {
            if(action.payload.success){
                state.lidStatus = action.payload.object
            }
            else{
                state.message = action.payload.message
                state.lidStatus = null
            }
            state.getBoolean = !state.getBoolean
            state.saveBoolean = false
            state.changeBoolean=false
        },
        getFromLidOne:(state,action)=>{
            if (action.payload.success){
                state.lidOne=action.payload.object
            }else {
                state.message = action.payload.message
            }
        },
        savefrom: (state,action) => {
            if (action.payload.success){
                toast.success('ADDED')
                state.saveBoolean = true
            }else {
                toast.error(action.payload.message)
            }
            state.current=!state.current
        },

        editfrom: (state,action) => {
            if (action.payload.success){
                toast.success('EDITED')
                state.saveBoolean = true
            }else {
                toast.error(action.payload.message)
            }
            state.current=!state.current
        },
        changefrom: (state,action) => {
            if (action.payload.success){
                toast.success('EDITED')
                state.changeBoolean = true
            }else {
                toast.error(action.payload.message)
            }
            state.current=!state.current
        },
        deletefrom:(state,action)=>{
            if (action.payload.success){
                toast.success('DELETED')
            }else {
                toast.error(action.payload.message)
            }
            state.deleteBoolean = true
            state.current=!state.current
        }
    }
});

export const getLidStatus=(data)=>apiCall({
    url: '/lid-status/getByBusinessId/'+data,
    method:'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type,
});
export const getLidStatusOne=(data)=>apiCall({
    url: '/lid-status/'+data,
    method:'get',
    onSuccess: slice.actions.getFromLidOne.type,
    onFail: slice.actions.getFromLidOne.type,
});

export const saveLidStatus=(data)=>apiCall({
    url: '/lid-status',
    method:'post',
    data,
    onSuccess: slice.actions.savefrom.type,
    onFail: slice.actions.savefrom.type
});

export const editLidStatus=(data)=>apiCall({
    url: '/lid-status/'+data.id,
    method: 'put',
    data,
    onSuccess: slice.actions.editfrom.type,
    onFail: slice.actions.editfrom.type
});
export const changeLidStatusView=(data)=>apiCall({
    url: '/lid-status/change-lid-status-big/'+data,
    method: 'put',
    onSuccess: slice.actions.changefrom.type,
    onFail: slice.actions.changefrom.type
});

export const deleteLidStatus=(data)=>apiCall({
    url: '/lid-status/'+data,
    method:'delete',
    data,
    onSuccess: slice.actions.deletefrom.type,
    onFail: slice.actions.deletefrom.type,
});

export default slice.reducer
