import { useState, forwardRef } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { DragDropContext } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import {
    Avatar,
    Box,
    Card,
    Checkbox,
    CardActionArea,
    CardContent,
    Grid,
    Slide,
    Divider,
    Tooltip,
    IconButton,
    InputAdornment,
    Link,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableContainer,
    TableRow,
    ToggleButton,
    ToggleButtonGroup,
    Tab,
    Tabs,
    TextField,
    Button,
    Typography,
    Dialog,
    Zoom,
    styled, DialogTitle, DialogContent, Autocomplete, Switch, DialogActions, CircularProgress
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import Label from '../Label';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import GridViewTwoToneIcon from '@mui/icons-material/GridViewTwoTone';
import TableRowsTwoToneIcon from '@mui/icons-material/TableRowsTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { useSnackbar } from 'notistack';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import BulkActions from './BulkActions';
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import {Formik} from "formik";
import * as Yup from "yup";
import wait from "../wait";
import CloudUploadTwoToneIcon from "@mui/icons-material/CloudUploadTwoTone";
import Tasks from "./Tasks";
import users from "../../../../../reducer/users";
import {connect} from "react-redux";

const DialogWrapper = styled(Dialog)(
    () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const TasksWrapper = styled(Box)(
    ({ theme }) => `
      display: flex;
      overflow-y: hidden;
      overflow-x: auto;
      flex-direction: row;
      padding: ${theme.spacing(2)};
  `
);

const TasksWrapperContent = styled(Box)(
    ({ theme }) => `
      margin: 0 -${theme.spacing(2)};
  `
);
const CardAddAction = styled(Card)(
    ({ theme }) => `
        border: ${theme.colors.primary.main} dashed 1px;
        height: 100px;
        flex: 1;
        min-width: 120px;
        color: ${theme.colors.primary.main};
        
        .MuiCardActionArea-root {
          height: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
        }
        
        .MuiTouchRipple-root {
          opacity: .2;
        }
        
        &:hover {
          border-color: ${theme.colors.alpha.black[100]};
        }
`
);


const AvatarAddWrapper = styled(Avatar)(
    ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        color: ${theme.colors.primary.main};
        width: ${theme.spacing(8)};
        height: ${theme.spacing(8)};
`
);
const AvatarWrapper = styled(Box)(
    ({ theme }) => `

    position: relative;

    .MuiAvatar-root {
      width: ${theme.spacing(16)};
      height: ${theme.spacing(16)};
    }
`
);

const ButtonUploadWrapper = styled(Box)(
    ({ theme }) => `
    position: absolute;
    width: ${theme.spacing(6)};
    height: ${theme.spacing(6)};
    bottom: -${theme.spacing(2)};
    right: -${theme.spacing(2)};

    .MuiIconButton-root {
      border-radius: 100%;
      background: ${theme.colors.primary.main};
      color: ${theme.palette.primary.contrastText};
      box-shadow: ${theme.colors.shadows.primary};
      width: ${theme.spacing(6)};
      height: ${theme.spacing(6)};
      padding: 0;
  
      &:hover {
        background: ${theme.colors.primary.dark};
      }
    }
`
);

const roles = [
    { label: 'Administrator', value: 'admin' },
    { label: 'Subscriber', value: 'subscriber' },
    { label: 'Customer', value: 'customer' }
];

const AvatarError = styled(Avatar)(
    ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const CardWrapper = styled(Card)(
    ({ theme }) => `

  position: relative;
  overflow: visible;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: inherit;
    z-index: 1;
    transition: ${theme.transitions.create(['box-shadow'])};
  }
      
    &.Mui-selected::after {
      box-shadow: 0 0 0 3px ${theme.colors.primary.main};
    }
  `
);
const Input = styled('input')({
    display: 'none'
});

const ButtonError = styled(Button)(
    ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

const TabsWrapper = styled(Tabs)(
    ({ theme }) => `
    @media (max-width: ${theme.breakpoints.values.md}px) {
      .MuiTabs-scrollableX {
        overflow-x: auto !important;
      }

      .MuiTabs-indicator {
          box-shadow: none;
      }
    }
    `
);

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const applyFilters = (users, query, filters) => {
    // return users.filter((user) => {
    //     let matches = true;
    //
    //     if (query) {
    //         const properties = ['email', 'name', 'username'];
    //         let containsQuery = false;
    //
    //         properties.forEach((property) => {
    //             if (user[property].toLowerCase().includes(query.toLowerCase())) {
    //                 containsQuery = true;
    //             }
    //         });
    //
    //         if (filters.role && user.role !== filters.role) {
    //             matches = false;
    //         }
    //
    //         if (!containsQuery) {
    //             matches = false;
    //         }
    //     }
    //
    //     Object.keys(filters).forEach((key) => {
    //         const value = filters[key];
    //
    //         if (value && user[key] !== value) {
    //             matches = false;
    //         }
    //     });
    //
    //     return matches;
    // });
};

const applyPagination = (users, page, limit) => {
    // return users.slice(page * limit, page * limit + limit);
};

const Results = ({ users }) => {
    const [selectedItems, setSelectedUsers] = useState([]);
    const [list,setList] = useState([
        {
            id: '1',
            name: 'In review',
            color: '#FF1A43',
            taskIds: ['1', '2', '3', '4']
        },
        {
            id: '2',
            name: 'In progress',
            color: '#33C2FF',
            taskIds: ['5', '6', '7']
        },
        {
            id: '3',
            name: 'Completed',
            color: '#57CA22',
            taskIds: ['8', '9', '10']
        }
    ])

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();

    const [lidStatusActive, setLidStatusActive] = useState(false)
    function LidStatusToggle(){
        console.log('isha')
        setLidStatusActive(!lidStatusActive)
    }
    const [open, setOpen] = useState(false);

    const handleCreateUserOpen = () => {
        setOpen(true);
    };

    const handleCreateUserClose = () => {
        setOpen(false);
    };

    const handleCreateUserSuccess = () => {
        enqueueSnackbar(t('The user account was created successfully'), {
            variant: 'success',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            },
            TransitionComponent: Zoom
        });

        setOpen(false);
    };

    const [publicProfile, setPublicProfile] = useState({
        public: true
    });

    const handlePublicProfile = (event) => {
        setPublicProfile({
            ...publicProfile,
            [event.target.name]: event.target.checked
        });
    };

    const handleDragEnd = async ({ source, destination, draggableId }) => {
        try {
            if (!destination) {
                return;
            }

            if (
                source.droppableId === destination.droppableId &&
                source.index === destination.index
            ) {
                return;
            }

            enqueueSnackbar(t('Task has been successfully moved'), {
                variant: 'info',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center'
                },
                TransitionComponent: Zoom
            });
        } catch (err) {
            console.error(err);
            enqueueSnackbar(t('There was an error, try again later'), {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center'
                },
                TransitionComponent: Zoom
            });
        }
    };


    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [query, setQuery] = useState('');
    const [filters, setFilters] = useState({
        role: null
    });

    const handleQueryChange = (event) => {
        event.persist();
        setQuery(event.target.value);
    };

    const handleSelectAllUsers = (event) => {
        setSelectedUsers(event.target.checked ? users.map((user) => user.id) : []);
    };

    const handleSelectOneUser = (_event, userId) => {
        if (!selectedItems.includes(userId)) {
            setSelectedUsers((prevSelected) => [...prevSelected, userId]);
        } else {
            setSelectedUsers((prevSelected) =>
                prevSelected.filter((id) => id !== userId)
            );
        }
    };

    const handlePageChange = (_event, newPage) => {
        setPage(newPage);
    };

    const handleLimitChange = (event) => {
        setLimit(parseInt(event.target.value));
    };

    const filteredUsers = applyFilters(users, query, filters);
    const paginatedUsers = applyPagination(filteredUsers, page, limit);
    const selectedBulkActions = selectedItems.length > 0;
    const selectedSomeUsers =
        selectedItems.length > 0 && selectedItems.length < users.length;
    const selectedAllUsers = selectedItems.length === users.length;

    const [toggleView, setToggleView] = useState('table_view');

    const handleViewOrientation = (_event, newValue) => {
        setToggleView(newValue);
    };

    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

    const handleConfirmDelete = () => {
        setOpenConfirmDelete(true);
    };

    const closeConfirmDelete = () => {
        setOpenConfirmDelete(false);
    };

    const handleDeleteCompleted = () => {
        setOpenConfirmDelete(false);

        enqueueSnackbar(t('The user account has been removed'), {
            variant: 'success',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            },
            TransitionComponent: Zoom
        });
    };

    return (
        <>
            <Box
                display="flex"
                alignItems="center"
                flexDirection={{ xs: 'column', sm: 'row' }}
                justifyContent={{ xs: 'center', sm: 'space-between' }}
                pb={3}
            >
                <TabsWrapper
                    textColor="secondary"
                    variant="scrollable"
                >
                    <Button
                        sx={{
                            mt: { xs: 2, sm: 0 }
                        }}
                        onClick={handleCreateUserOpen}
                        variant="contained"
                        startIcon={<AddTwoToneIcon fontSize="small" />}
                    >
                        {t('Lid Qo\'shish')}
                    </Button>
                    {/*<RouterLink to={'/main/lidForms'}>*/}
                    {/*    <Button className={'ms-2'}*/}
                    {/*            sx={{*/}
                    {/*                mt: { xs: 2, sm: 0 }*/}
                    {/*            }}*/}
                    {/*        // onClick={handleCreateUserOpen}*/}
                    {/*            variant="contained"*/}
                    {/*    >*/}
                    {/*        {t('Lid Forma')}*/}
                    {/*    </Button>*/}
                    {/*</RouterLink>*/}

                    <Button className={'ms-2'}
                            sx={{
                                mt: { xs: 2, sm: 0 }
                            }}
                            onClick={LidStatusToggle}
                            variant="contained"
                            startIcon={<AddTwoToneIcon fontSize="small" />}
                    >
                        {t('Yangi Lid Status Qo\'shish')}
                    </Button>
                </TabsWrapper>
                <ToggleButtonGroup
                    sx={{
                        mt: { xs: 2, sm: 0 }
                    }}
                    value={toggleView}
                    exclusive
                    onChange={handleViewOrientation}
                >
                    <ToggleButton disableRipple value="table_view">
                        <TableRowsTwoToneIcon />
                    </ToggleButton>
                    <ToggleButton disableRipple value="grid_view">
                        <GridViewTwoToneIcon />
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
            {toggleView === 'table_view' && (
                <Card>
                    <Box p={2}>
                        {!selectedBulkActions && (
                            <TextField
                                sx={{
                                    m: 0
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchTwoToneIcon />
                                        </InputAdornment>
                                    )
                                }}
                                onChange={handleQueryChange}
                                placeholder={t('Search by name, email or username...')}
                                value={query}
                                size="small"
                                fullWidth
                                margin="normal"
                                variant="outlined"
                            />
                        )}
                        {selectedBulkActions && <BulkActions />}
                    </Box>

                    <Divider />

                    {paginatedUsers?.length === 0 ? (
                            <>
                                <Typography
                                    sx={{
                                        py: 10
                                    }}
                                    variant="h3"
                                    fontWeight="normal"
                                    color="text.secondary"
                                    align="center"
                                >
                                    {t("We couldn't find any users matching your search criteria")}
                                </Typography>
                            </>
                        ) :
                        (
                            <>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={selectedAllUsers}
                                                        indeterminate={selectedSomeUsers}
                                                        onChange={handleSelectAllUsers}
                                                    />
                                                </TableCell>
                                                <TableCell>Vazifa</TableCell>
                                                <TableCell>Loyiha</TableCell>
                                                <TableCell>Tugash sanasi</TableCell>
                                                <TableCell align="center">Ish soati</TableCell>
                                                <TableCell>Vazifaga tayinlangan hodimlar</TableCell>
                                                <TableCell>Status</TableCell>
                                                <TableCell align="center">{t('Actions')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {paginatedUsers?.map((user) => {
                                                const isUserSelected = selectedItems.includes(user.id);
                                                return (
                                                    <TableRow hover key={user.id} selected={isUserSelected}>
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                checked={isUserSelected}
                                                                onChange={(event) =>
                                                                    handleSelectOneUser(event, user.id)
                                                                }
                                                                value={isUserSelected}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant="h5">
                                                                {user.username}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant="h5">
                                                                {user.username}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography>{user.due_date}</Typography>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography fontWeight="bold">

                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography></Typography>
                                                        </TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell align="center">
                                                            <Typography noWrap>
                                                                <Tooltip title={t('View')} arrow>
                                                                    <IconButton
                                                                        component={RouterLink}
                                                                        to={`/${
                                                                            location.pathname.split('/')[1]
                                                                        }/management/users/single/${user.id}`}
                                                                        color="primary"
                                                                    >
                                                                        <LaunchTwoToneIcon fontSize="small" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title={t('Delete')} arrow>
                                                                    <IconButton
                                                                        onClick={handleConfirmDelete}
                                                                        color="primary"
                                                                    >
                                                                        <DeleteTwoToneIcon fontSize="small" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Box p={2}>
                                    <TablePagination
                                        component="div"
                                        count={filteredUsers?.length}
                                        onPageChange={handlePageChange}
                                        onRowsPerPageChange={handleLimitChange}
                                        page={page}
                                        rowsPerPage={limit}
                                        rowsPerPageOptions={[5, 10, 15]}
                                    />
                                </Box>
                            </>
                        )}
                </Card>
            )}
            {toggleView === 'grid_view' && (
                <>
                    <Card
                        sx={{
                            p: 2,
                            mb: 3
                        }}
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            {paginatedUsers?.length !== 0 && (
                                <>
                                    <Box display="flex" alignItems="center">
                                        <Tooltip
                                            arrow
                                            placement="top"
                                            title={t('Select all users')}
                                        >
                                            <Checkbox
                                                checked={selectedAllUsers}
                                                indeterminate={selectedSomeUsers}
                                                onChange={handleSelectAllUsers}
                                            />
                                        </Tooltip>
                                    </Box>
                                    {selectedBulkActions && (
                                        <Box flex={1} pl={2}>
                                            <BulkActions />
                                        </Box>
                                    )}
                                </>
                            )}
                            {!selectedBulkActions && (
                                <TextField
                                    sx={{
                                        my: 0,
                                        ml: paginatedUsers?.length !== 0 ? 2 : 0
                                    }}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchTwoToneIcon />
                                            </InputAdornment>
                                        )
                                    }}
                                    onChange={handleQueryChange}
                                    placeholder={t('Search by name, email or username...')}
                                    value={query}
                                    size="small"
                                    margin="normal"
                                    variant="outlined"
                                />
                            )}
                        </Box>
                    </Card>
                    {paginatedUsers?.length === 0 ? (
                        <Typography
                            sx={{
                                py: 10
                            }}
                            variant="h3"
                            fontWeight="normal"
                            color="text.secondary"
                            align="center"
                        >
                            {t("We couldn't find any users matching your search criteria")}
                        </Typography>
                    ) : (
                        <Grid item xs={12}>
                            <TasksWrapperContent>
                                <DragDropContext onDragEnd={handleDragEnd}>
                                    <TasksWrapper>
                                        {list.map((listId) => (
                                            <Tasks key={listId} listId={listId} />
                                        ))}

                                        <Tooltip arrow title={t('Add a new project panel')}>
                                            <CardAddAction>
                                                <CardActionArea sx={{ px: 1 }}>
                                                    <CardContent>
                                                        <AvatarAddWrapper>
                                                            <AddTwoToneIcon fontSize="large" />
                                                        </AvatarAddWrapper>
                                                    </CardContent>
                                                </CardActionArea>
                                            </CardAddAction>
                                        </Tooltip>
                                    </TasksWrapper>
                                </DragDropContext>
                            </TasksWrapperContent>
                        </Grid>
                    )}
                </>
            )}
            {!toggleView && (
                <Card
                    sx={{
                        textAlign: 'center',
                        p: 3
                    }}
                >
                    <Typography
                        align="center"
                        variant="h4"
                        fontWeight="normal"
                        color="text.secondary"
                        sx={{
                            my: 5
                        }}
                        gutterBottom
                    >
                        {t(
                            'Choose between table or grid views for displaying the users list.'
                        )}
                    </Typography>
                </Card>
            )}

            <DialogWrapper
                open={openConfirmDelete}
                maxWidth="sm"
                fullWidth
                TransitionComponent={Transition}
                keepMounted
                onClose={closeConfirmDelete}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    p={5}
                >
                    <AvatarError>
                        <CloseIcon />
                    </AvatarError>

                    <Typography
                        align="center"
                        sx={{
                            py: 4,
                            px: 6
                        }}
                        variant="h3"
                    >
                        {t('Are you sure you want to permanently delete this user account')}
                        ?
                    </Typography>

                    <Box>
                        <Button
                            variant="text"
                            size="large"
                            sx={{
                                mx: 1
                            }}
                            onClick={closeConfirmDelete}
                        >
                            {t('Cancel')}
                        </Button>
                        <ButtonError
                            onClick={handleDeleteCompleted}
                            size="large"
                            sx={{
                                mx: 1,
                                px: 3
                            }}
                            variant="contained"
                        >
                            {t('Delete')}
                        </ButtonError>
                    </Box>
                </Box>
            </DialogWrapper>
            <Dialog
                fullWidth
                maxWidth="md"
                open={open}
                onClose={handleCreateUserClose}
            >
                <DialogTitle
                    sx={{
                        p: 3
                    }}
                >
                    <Typography variant="h4" gutterBottom>
                        Lid Qo'shish
                    </Typography>

                </DialogTitle>
                <Formik
                    initialValues={{
                        email: '',
                        username: '',
                        first_name: '',
                        last_name: '',
                        password: '',
                        submit: null
                    }}
                    validationSchema={Yup.object().shape({
                        username: Yup.string()
                            .max(255)
                            .required(t('The username field is required')),
                        first_name: Yup.string()
                            .max(255)
                            .required(t('The first name field is required')),
                        last_name: Yup.string()
                            .max(255)
                            .required(t('The last name field is required')),
                        email: Yup.string()
                            .email(t('The email provided should be a valid email address'))
                            .max(255)
                            .required(t('The email field is required')),
                        password: Yup.string()
                            .max(255)
                            .required(t('The password field is required'))
                    })}
                    onSubmit={async (
                        _values,
                        { resetForm, setErrors, setStatus, setSubmitting }
                    ) => {
                        try {
                            await wait(1000);
                            resetForm();
                            setStatus({ success: true });
                            setSubmitting(false);
                            handleCreateUserSuccess();
                        } catch (err) {
                            console.error(err);
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }}
                >
                    {({
                          errors,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          touched,
                          values
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <DialogContent
                                dividers
                                sx={{
                                    p: 3
                                }}
                            >

                            </DialogContent>
                            <DialogActions
                                sx={{
                                    p: 3
                                }}
                            >
                                <Button color="secondary" onClick={handleCreateUserClose}>
                                    Chiqish
                                </Button>
                                <Button
                                    type="submit"
                                    startIcon={
                                        isSubmitting ? <CircularProgress size="1rem" /> : null
                                    }
                                    disabled={Boolean(errors.submit) || isSubmitting}
                                    variant="contained"
                                >
                                    Qo'shish
                                </Button>
                            </DialogActions>
                        </form>
                    )}
                </Formik>
            </Dialog>
        </>
    );
};

Results.propTypes = {
    users: PropTypes.array.isRequired
};

Results.defaultProps = {
    users: []
};

// export default Results;
export default connect((users),{}) (Results)
