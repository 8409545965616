import {useState, useEffect} from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {Input, Popconfirm, Form, Modal, Select as SelectAnt} from "antd";
import Select from '@mui/material/Select';
import InputLabel from "@mui/material/InputLabel";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

import CommonTable from "../../../../Components/CommonTable";
import {DeleteButton, EditButton, QushishButton} from "../../../../Components/Buttons";
import XarajatTurlariReducer, {
    deleteXarajatlarTurlari,
    editXarajatlarTurlari,
    getXarajatlarTurlari, getXarajatlarTurlariByBranch,
    saveXarajatlarTurlari,
} from "../reducer/XarajatTurlariReducer";
import users from "../../../../../reducer/users";
import {setLocalStorage, tablePageSize} from "../../../../../util";

import './xarajatTurlari.css'

function XarajatTurlari({
                            getXarajatlarTurlari,
                            XarajatTurlariReducer,
                            editXarajatlarTurlari,
                            users,
                            getXarajatlarTurlariByBranch,
                            saveXarajatlarTurlari,
                            deleteXarajatlarTurlari
                        }) {
    const {t} = useTranslation()
    const [form] = Form.useForm();
    const [pageData, setPageData] = useState({
        page: 0,
        pageSize: tablePageSize ? tablePageSize : 10,
        branch: users.viewOutlayAdmin ? "ALL" : users.branchId,
        openModal: false,
        loading: false,
        editId: null,
        searchValue: ""
    });

    const deleteOutlay = (id) => {
        deleteXarajatlarTurlari(id)
        setPageData( (prev) => ({...prev, loading: true}))
    };

    const columns = [
        {
            key: "id",
            title: 'Id',
            dataIndex: 'index',
            width: '3%',
            fixed: 'left',
        },
        {
            title: t('ProductList.4'),
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: t('Davomat.amal'),
            dataIndex: 'profit',
            key: 'profit',
            render: (item, record) => <div className="d-flex align-items-center gap-2">
                {
                    users.editOutlay ? <EditButton onClick={() => {
                          setPageData( (prev) => ({...prev, editId: record.id, openModal: true}))
                          form.setFieldValue("title", record.title)
                          form.setFieldValue("branchId", record.branch?.id)
                      }} buttonText={t('Buttons.1')} />
                      : null
                }
                {
                    users.deleteOutlay ?
                      <Popconfirm
                        title={`${t('Buttons.12')}?`}
                        onConfirm={() => deleteOutlay(record.id)}
                        okText={t('Buttons.3')}
                        cancelText={t('Buttons.7')}
                      >
                          <DeleteButton buttonText={t('Buttons.3')} />
                      </Popconfirm>
                      : null
                }
            </div>,
            width: '40%',
        },
    ];


    useEffect(() => {
        pageData.branch === "ALL" ? getXarajatlarTurlari(users.businessId) : getXarajatlarTurlariByBranch(pageData.branch)
        setPageData( (prev) => ({...prev, loading: true}))
    }, [pageData.branch, XarajatTurlariReducer.current])

    useEffect(()=>{
        setPageData( (prev) => ({...prev, loading: false}))
    },[XarajatTurlariReducer.getOutlayBool])

    useEffect(()=>{
        setPageData( (prev) => ({...prev, loading: true}))
    },[])

    function searchOutlay(e) {
        setPageData( (prev) => ({...prev, searchValue: e?.target?.value}))
    }

    const newChange = (pageNumber, page) => {
        setPageData((prev) => ({ ...prev, pageSize: page, page: pageNumber - 1 }));
        setLocalStorage("pageSize", page)
    };

    const onCreate = (values) => {
        pageData.editId ? editXarajatlarTurlari({
            ...values,
            id: pageData.editId
        }): saveXarajatlarTurlari(values)
        setPageData( (prev) => ({...prev, loading: true, editId: null, page: 0, searchValue: "", openModal: false}))
    };

    function selectBranch(e) {
        setPageData((prev) => ({...prev,branch: e.target.value, page: 0, searchValue: "", loading: true, editId: null}))
    }

    return (
        <div className="col-md-12 mt-4 mb-4">
            <div className="rowStyleXRT">
                <div className="qoshish mb-3">
                    <div style={{width: 200}}>
                        <FormControl sx={{minWidth: '100%', height: "100%"}} size="small">
                            <InputLabel className="selectLabelXarajatTur" id="demo-select-small-label">{t('cost.baza')}</InputLabel>
                            <Select
                              className="selectXarajatTur"
                              labelId="demo-select-small-label"
                              id="demo-select-small"
                              value={pageData.branch}
                              label={t('cost.baza')}
                              IconComponent={KeyboardArrowDownIcon}
                              onChange={selectBranch}
                            >
                                {
                                    users.viewOutlayAdmin && <MenuItem value="ALL">{t('as.36')}</MenuItem>
                                }
                                {
                                    users.branches.map(item =>
                                      <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </div>
                    {
                        users.addOutlay ? <QushishButton onClick={() => setPageData( (prev) => ({...prev, openModal: true}))} buttonText={t('Buttons.2')}/> : null
                    }
                </div>
                {
                    users.viewOutlay || users.viewOutlayAdmin ?
                      <div>
                          <div className="mb-2">
                              <Input allowClear rootClassName="w-full searchOutlayType" onChange={searchOutlay}
                                     placeholder={t("num.37")}/>
                          </div>
                          <CommonTable
                            loading={pageData.loading}
                            scroll={{x: 1200}}
                            total={XarajatTurlariReducer.xarajatturlari?.filter((val) => pageData.searchValue === '' ? val : val.title.toUpperCase().includes(pageData.searchValue.toUpperCase()) && val)?.length} handlePageChange={newChange}
                            pagination={true}
                            page={pageData.page}
                            size={pageData.pageSize}
                            data={XarajatTurlariReducer.xarajatturlari?.filter((val) => pageData.searchValue === '' ? val : val.title.toUpperCase().includes(pageData.searchValue.toUpperCase()) && val)}
                            columns={columns}
                          />
                      </div> : null
                }
                <Modal
                  open={pageData.openModal}
                  title={t('Sections.8')}
                  okText={t('Buttons.6')}
                  cancelText={t('Buttons.7')}
                  onCancel={() => {
                      setPageData( (prev) => ({...prev, openModal: false, editId: null}))
                      form.resetFields();
                  }}
                  onOk={() => {
                      form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreate(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                  }}
                >
                    <Form
                      form={form}
                      layout="vertical"
                      name="form_in_modal"
                    >
                        <Form.Item
                          name="title"
                          label={t('Expenses.11')}
                          rules={[
                              {
                                  required: true,
                              }
                          ]}
                        >
                            <Input placeholder={`${t('Expenses.11')}...`} />
                        </Form.Item>
                        <Form.Item
                          name="branchId"
                          label={t('cost.baza')}
                          rules={[
                                {
                                    required: true,
                                }
                            ]}
                        >
                            <SelectAnt
                              placeholder={`${t('cost.baza')}...`}
                              size="middle">
                                {
                                  users.branches.map((item, index) => <SelectAnt.Option key={index} value={item.id}>{item.name}</SelectAnt.Option>)
                                }
                            </SelectAnt>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        </div>
    )
}

export default connect((XarajatTurlariReducer, users), {
    getXarajatlarTurlariByBranch,
    getXarajatlarTurlari,
    saveXarajatlarTurlari,
    editXarajatlarTurlari,
    deleteXarajatlarTurlari,
})(XarajatTurlari)
