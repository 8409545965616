import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useSnackbar} from 'notistack';
import {Droppable, Draggable} from 'react-beautiful-dnd';
import {
    Box,
    ClickAwayListener,
    Button,
    IconButton,
    Tooltip,
    Card,
    Zoom,
    TextField,
    Typography,
    styled
} from '@mui/material';
import Label from '../Label';
import {useTranslation} from 'react-i18next';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import VisibilityIcon from '@mui/icons-material/Visibility';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import {addDays} from "date-fns";
import Task from './/Task'
import {connect} from "react-redux";
import LidStatusReducer, {getLidStatus, getLidStatusOne} from "./reducer/LidStatusReducer";
import users from "../../../../../reducer/users";
import LidReducer, {getLid, getOneLid} from "./reducer/LidReducer";

const ListColumnWrapper = styled(Card)(
    ({theme}) => `
      width: 340px;
      min-width: 340px;
      margin-right: ${theme.spacing(3)};
      border-top-width: 8px;
      border-top-style: solid;
  `
);

const IconButtonEdit = styled(IconButton)(
    ({theme}) => `
      margin-right: ${theme.spacing(0.5)};
      padding: ${theme.spacing(0.4)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(17)};
        color: ${theme.colors.primary.main};
      }
  `
);

const ButtonAdd = styled(Button)(
    ({theme}) => `
      background-color: ${theme.colors.alpha.black[10]};
      padding: ${theme.spacing(1)};
  `
);


function Tasks({listId,handleDelete, deleteFunc,viewLidInfo,trade, openIzoh,
                   editFunc, LidReducer, getLid,getOneLid, tasks, showMore, onMore, onLess,addCustomer}) {

    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();


    function deleteLid(id) {
        deleteFunc(id)
    }

    function edit(id) {
        editFunc(id)
    }

    const [activeView, setActiveView] = useState(false)


    function viewLid(id) {
        setActiveView(!activeView)
        getOneLid(id)
    }

    return (
        listId.increase ?
        <ListColumnWrapper
            sx={{
                borderColor: listId.color
            }}
            style={{
                maxHeight: '700px',
                minHeight: '700px',
            }}
        >
            <Box
                px={2}
                pt={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >

                <Typography style={{cursor:'pointer'}} onClick={() => viewLid()} color="inherit" variant="h4">
                    {
                        listId.name
                    }
                </Typography>

                <Box display="flex" alignItems="center">
                    <Tooltip arrow placement="top" style={{transform: 'rotateZ(90deg) scale(1.4)'}}
                             title={t('Collapse')}>
                        <IconButtonEdit
                            onClick={() => onLess(listId.id)}
                        >
                            <UnfoldLessIcon/>
                        </IconButtonEdit>
                    </Tooltip>
                    <Tooltip arrow placement="top" title={t('Edit')}>
                        <IconButtonEdit>
                            <EditTwoToneIcon onClick={() => edit(listId.id)}/>
                        </IconButtonEdit>
                    </Tooltip>
                    {
                        listId.numberOfLids > 0 ?
                            ''
                            :
                            <Tooltip arrow placement="top" title={t('Delete')}>
                                <IconButtonEdit>
                                    <DeleteTwoToneIcon onClick={() => deleteLid(listId.id)}/>
                                </IconButtonEdit>
                            </Tooltip>
                    }
                    <Label color="primary">
                        <b>{listId.numberOfLids}</b>
                    </Label>
                </Box>
            </Box>
            <hr/>

            {listId.numberOfLids === 0 && (
                <Box p={4} textAlign="center">
                    <Typography variant="subtitle2">
                        {t('Statusni o`zgartirish uchun lidni boshqa statusga torting' )}
                    </Typography>
                </Box>
            )}
            <Droppable droppableId={listId.id} key={listId.id}>
                {(provided) => (
                    <Box
                        style={{
                            overflowY: 'scroll',
                            height: '90%'
                        }}
                        sx={{
                            minHeight: 260
                        }}
                        ref={provided.innerRef}
                    >
                        {tasks?.filter(item1 => item1.statusId === listId.id)[0]?.getAllLid.map((task, index) => {
                                return (
                                    <Draggable draggableId={task.id.toString()} index={index} key={task.id}>
                                        {(provided, snapshot) => (
                                            <Task
                                                addCustomer={addCustomer}
                                                task={task}
                                                handleDelete={handleDelete}
                                                openIzoh={openIzoh}
                                                viewLidInfo={viewLidInfo}
                                                trade={trade}
                                                dragging={snapshot.isDragging}
                                                index={index}
                                                key={task.id}
                                                list={listId.id}
                                                ref={provided.innerRef}
                                                style={{...provided.draggableProps.style}}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            />
                                        )}
                                    </Draggable>

                                )
                            }
                        )}
                        {provided.placeholder}
                        {
                            tasks?.filter(item1 => item1.statusId === listId.id)[0]?.totalItems > tasks?.filter(item1 => item1.statusId === listId.id)[0]?.getAllLid.length ?
                                <Box px={2} pb={2}>
                                    <ButtonAdd onClick={() => showMore(listId.id)} size="small" color="secondary" fullWidth>
                                        Show More
                                        <VisibilityIcon fontSize={'small'} style={{marginLeft: '5px'}}/>
                                    </ButtonAdd> </Box> : ' '
                        }
                    </Box>
                )}
            </Droppable>




        </ListColumnWrapper>:
            <ListColumnWrapper
                sx={{
                    borderColor: listId.color
                }}
                style={{
                    width: '50px',
                    minWidth: '50px',
                    maxHeight: '700px',
                    minHeight: '700px'
                }}
            >
                <Box
                    px={2}
                    pt={3}
                    width={'100%'}
                    height={"100%"}
                >
                    <Box style={{transform: 'rotateZ(90deg)'}} display="flex" justifyContent="space-between"
                         alignItems="center">
                        <Tooltip style={{transform: 'scale(1.4)'}} arrow placement="top" title={t('Expand')}>
                            <IconButtonEdit onClick={() => onMore(listId.id)}>
                                <UnfoldMoreIcon/>
                            </IconButtonEdit>
                        </Tooltip>
                        <Typography mx={2} style={{height: '70%', whiteSpace: 'nowrap'}} color="inherit" variant="h3">
                            {listId.name}
                        </Typography>
                        <Label color="primary">
                            <b>{listId?.numberOfLids}</b>
                        </Label>
                    </Box>
                </Box>
            </ListColumnWrapper>
    );
};

Tasks.propTypes = {
    listId: PropTypes.string.isRequired
};

// export default Tasks;
export default connect((LidStatusReducer, users, LidReducer), {getLid,getOneLid, getLidStatus, getLidStatusOne})(Tasks)
