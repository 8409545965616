import {createSlice} from "@reduxjs/toolkit";
import {toast} from "react-toastify";
import {apiCall} from "../../../../../api";

const slice = createSlice({
    name: 'darsliklar',
    initialState: {
        darsliklar: null,
        current: false,
        addRole: false,
        message: '',
        getBoolean: false,
        saveRoleBool: false,
    },
    reducers: {
        getFrom: (state, action) => {
            if (action.payload.success) {
                state.darsliklar = action.payload.object
            } else {
                state.darsliklar = null
                state.message = action.payload.message
            }
            state.getBoolean = !state.getBoolean
            state.saveRoleBool = false
        },
        savefrom: (state, action) => {
            if (action.payload.success) {
                toast.success('Darslik qo`shildi')
                state.addRole = true
                state.saveRoleBool = true

            } else {
                toast.warning(action.payload.message)
                state.addRole = false
            }
            state.current = !state.current

        },
        editfrom: (state, action) => {
            if (action.payload.success) {
                toast.success('Darslik tahrirlandi')
                state.saveRoleBool = true
            } else {
                toast.warning(action.payload.message)
            }
            state.current = !state.current
        },
        deletefrom: (state, action) => {
            if (action.payload.success) {
                toast.success('Darslik o\'chirildi')
            } else {
                toast.warning(action.payload.message)
            }
            state.current = !state.current

        }
    }
});

export const getDarslik = (data) => apiCall({
    url: '/lesson/by-business/' + data,
    method: 'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type,
});

export const getBYRoleDarslik = (data) => apiCall({
    url: '/lesson/by-role/' + data,
    method: 'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type,
});

export const saveDarslik = (data) => apiCall({
    url: '/lesson',
    method: 'post',
    data,
    onSuccess: slice.actions.savefrom.type,
    onFail: slice.actions.savefrom.type
});

export const editDarslik = (data) => apiCall({
    url: '/lesson/' + data.id,
    method: 'put',
    data,
    onSuccess: slice.actions.editfrom.type,
    onFail: slice.actions.editfrom.type
});

export const deleteDarslik = (data) => apiCall({
    url: '/lesson/' + data,
    method: 'delete',
    data,
    onSuccess: slice.actions.deletefrom.type,
    onFail: slice.actions.deletefrom.type
})

export default slice.reducer