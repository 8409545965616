import React from 'react'
import CSV from '../../../../../img/CSV.png'
import Excel from '../../../../../img/Excel.png'
import Print from '../../../../../img/Print.png'
import Data from '../../../../../img/Data.png'
import Pdf from '../../../../../img/PDF.png'
import './XodimlarNazorati.css'
import {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {useTranslation} from "react-i18next";
import users, {userDoHistory} from "../../../../../reducer/users";
import formatDate from "../../../../../util";
import formatDateYear from "../../../../../reducer/sana";
import {TablePagination} from "@mui/material";

function XodimlarNazorati({users, userDoHistory}) {

    const {t} = useTranslation()


    const [branch, setBranch] = useState(null)
    const [workType, setWorkType] = useState(null)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);


    function selectBranch(e) {
        setPage(0);
        setBranch(e.target.value === 'all' ? null: e.target.value)
    }

    function selectType(e) {
        setPage(0);
        setWorkType(e.target.value)
    }

    const handleChangePage = (_event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };


    useEffect(() => {
        userDoHistory({
            id: branch ? branch : users.businessId,
            params: {
                page: page,
                size: rowsPerPage,
                name:workType === 'all'?null:workType
            }
        })
    }, [page,rowsPerPage,workType,branch])


    return (
        <div className="col-md-12 mt-4 mb-4">
            <div className="textHeader">
                <h2>Xodimlar nazorati</h2>
            </div>
            <div className="rowStyleXX">
                <div className="qoshish">
                    <h5>{t('Buttons.16')}</h5>
                </div>
                <div className="row cont">
                    <div className="col-4 col-sm-12">
                        <h6>{t('ProductList.8')}:</h6>
                        <select value={branch} onChange={selectBranch} name="" id="">
                            <option value="all">Barchasi</option>
                            {
                                users.branches?.map(item =>
                                    <option key={item.id} value={item.id}>
                                        {item.name}
                                    </option>)
                            }
                        </select>

                    </div>
                    <div className="col-4 col-sm-12">
                        <h6>Ish turi bo'yicha:</h6>
                        <select value={workType} onChange={selectType} name="" id="">
                                <option  value={'all'}>Barchasi</option>
                                <option  value={'SAVDO'}>Savdo</option>
                                <option  value={'XARID'}>Xarid</option>
                                <option  value={'ISHLAB_CHIQARISH'}>Ishlab chiqarish</option>
                                <option  value={'XARAJAT'}>Xarajat</option>
                                <option  value={'ALMASHINUV'}>O'tkazma</option>
                        </select>

                    </div>
                </div>


            </div>
            {
                users?.userHistory?.getLessProduct.length > 0 ?
                    <div>
                        <div className="rowStyleXX2">
                            <div className="izlashXX2">
                                <div>
                                    <button><img src={CSV} alt=""/> Export CSV</button>
                                    <button><img src={Excel} alt=""/> Export Excel</button>
                                    <button><img src={Print} alt=""/> Print</button>
                                    <button><img src={Pdf} alt=""/>Export PDF</button>
                                </div>
                            </div>

                            <div className="table-responsive mb-4">
                                <table className='table table-striped table-bordered mt-4 '>
                                    <thead>
                                    <tr>
                                        <th>T/R</th>
                                        <th>Xodim ismi</th>
                                        <th>Filial nomi</th>
                                        <th>Qilgan ishi</th>
                                        <th>Ish tasnifi</th>
                                        <th>Sanasi</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        users.userHistory?.getLessProduct?.map((item, index) => <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item?.firstName} {item?.lastName}</td>
                                            <td>{item?.branchName}</td>
                                            <td>{item?.name}</td>
                                            <td>{item?.description}</td>
                                            <td>{formatDate(item.createdAt)}</td>
                                        </tr>)
                                    }
                                    </tbody>
                                </table>
                            </div>
                            <TablePagination
                                component="div"
                                count={users?.userHistory?.totalItem}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                rowsPerPageOptions={[5,10,15]}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </div>
                    </div> :

                    <div>
                        <h4 className={'text-center'}>{t('Darslik.mtt')}</h4>
                    </div>
            }
        </div>
    )
}

export default connect((users), {userDoHistory})(XodimlarNazorati)
