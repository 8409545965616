import React, {forwardRef, useState, useRef} from 'react';
import clsx from 'clsx';
import {
    Avatar,
    Box,
    Card,
    Tooltip,
    List as ListWrapper,
    ListItem,
    ListItemText,
    Menu,
    IconButton,
    Typography,
    AvatarGroup,
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    TextField,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell, TableBody, DialogActions, Button, CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import MoreHorizTwoToneIcon from '@mui/icons-material/MoreHorizTwoTone';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import ArchiveTwoToneIcon from '@mui/icons-material/ArchiveTwoTone';
import formatDate from "../../../../../util";
import {BaseUrl} from "../../../../../middleware";
import person from "../../../../../img/person-default.png";
import {Formik} from "formik";
import * as Yup from "yup";

const Task = forwardRef(({ task, dragging,taskInvalidAmountFunc, index, list, ...rest }, ref) => {
    const { t } = useTranslation();
    const [onMenuOpen, menuOpen] = useState(false);
    const moreRef = useRef(null);

    const openMenu = () => {
        menuOpen(true);
    };

    const closeMenu = () => {
        menuOpen(false);
    };

    const [pateria,setPateria] =  useState(false)
    const [invalidFormik,setInvalidFormik] = useState({
        invalid:0
    })



    function openPateriaModal(){
        setPateria(!pateria)
    }

    function renderUserList(list){
        let a = []
        list.map(item=>{
                item.userList.map(value=>{
                    let isHave = a.some(user=>user.id === value.id)
                    if (!isHave){
                        a.push(value)
                    }

                })
            }
        )
        return   (
            <AvatarGroup max={4}>
                {a.map((member) => (
                    <Tooltip
                        arrow
                        placement="top"
                        key={member?.firstName}
                        title={member?.firstName + " " + member?.lastName}
                    >
                        <Avatar
                            sx={{
                                width: 30,
                                height: 30
                            }}
                            key={member?.id}
                            src={member?.photo?.id ? `${BaseUrl}/attachment/download/${member?.photo?.id}` : person}
                        />
                    </Tooltip>
                ))}
            </AvatarGroup>
        )
    }

    return (
        <>
            <Box key={task.id} ref={ref} {...rest}>
                            <Card
                                sx={{
                                    m: 2,
                                    p: 2
                                }}
                                className={clsx({ dragging })}
                                raised={dragging}
                                variant={dragging ? 'elevation' : 'outlined'}
                            >
                                <Typography variant="h4" gutterBottom noWrap>
                                    {task.name}
                                </Typography>
                                <Typography variant="subtitle1" noWrap>
                                    {task.projectName}
                                </Typography>
                                <Box>
                                    <Typography variant="subtitle1">
                                        {t('Due')}{' '}
                                        {formatDate(task.deadLine)}
                                    </Typography>
                                </Box>
                                {
                                    task.productions ?
                                        <Typography display={'flex'}>
                                            <Typography variant={'h4'} fontWeight="bold">
                                                {task.production.product ? task.production?.product?.name:task.production?.productTypePrice?.name}
                                            </Typography>
                                            <Typography variant={'h5'} fontWeight="ligth">
                                                (  {task.production.totalQuantity}
                                                {task.production.product ? task.production?.product?.measurement?.name:task.production?.productTypePrice?.product?.measurement?.name})
                                            </Typography>
                                        </Typography>:'----'
                                }
                                <Box
                                    pt={2}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Box>
                                        {renderUserList(task.taskPriceList)}
                                    </Box>
                                    <IconButton
                                        color="inherit"
                                        size="small"
                                        onClick={openMenu}
                                        ref={moreRef}
                                    >
                                        <MoreHorizTwoToneIcon fontSize="small" />
                                    </IconButton>
                                </Box>
                            </Card>
            </Box>
            <Menu
                disableScrollLock
                keepMounted
                anchorEl={moreRef.current}
                open={onMenuOpen}
                onClose={closeMenu}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center'
                }}
            >
                <ListWrapper
                    sx={{
                        p: 1
                    }}
                    component="nav"
                >
                    {/*<ListItem>*/}
                    {/*    <VisibilityTwoToneIcon className={'me-2'}/>*/}
                    {/*    <ListItemText primary={t('View details')} />*/}
                    {/*</ListItem>*/}
                    <ListItem onClick={openPateriaModal} style={{cursor:'pointer'}}>
                        <ArchiveTwoToneIcon className={'me-2'}/>
                        <ListItemText primary={t("Yo'qotishni kiritish")} />
                    </ListItem>
                    {/*<ListItem>*/}
                    {/*    <DeleteForeverTwoToneIcon className={'me-2'} />*/}
                    {/*    <ListItemText primary={t('Delete')} />*/}
                    {/*</ListItem>*/}
                </ListWrapper>
            </Menu>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={pateria}
                onClose={() => setPateria(false)}
            >
                <DialogTitle
                    sx={{
                        p: 3
                    }}
                >
                    <Typography variant="h4" gutterBottom>
                        {t('Yoqotishni kiritish')}
                    </Typography>
                </DialogTitle>
                <Formik
                    initialValues={invalidFormik}
                    validationSchema={Yup.object().shape({
                        invalid: Yup.string()
                            .max(255)
                            .required(t('Miqdorni kiriting')),
                    })}
                    onSubmit={async (
                        _values
                    ) => {
                        taskInvalidAmountFunc({
                            taskId:task.id,
                            taskStatusId:task.taskStatusId,
                            quantity:_values.invalid
                        })
                        setTimeout(()=>{
                            openPateriaModal()
                        },1000)
                    }}
                >
                    {({
                          errors,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          touched,
                          values
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <DialogContent
                                dividers
                                sx={{
                                    p: 3
                                }}
                            >
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <TextField
                                            error={Boolean(touched.invalid && errors.invalid)}
                                            fullWidth
                                            helperText={touched.invalid && errors.invalid}
                                            label={t('Yo\'qotish miqdorini kiriting')}
                                            name="invalid"
                                            onChange={handleChange}
                                            value={values.invalid}
                                            variant="outlined"
                                        />

                                    </Grid>
                                </Grid>

                            </DialogContent>
                            <DialogActions
                                sx={{
                                    p: 3
                                }}
                            >
                                <Button color="secondary" onClick={openPateriaModal}>
                                    {t('Cancel')}
                                </Button>
                                <Button
                                    type="submit"
                                    startIcon={
                                        isSubmitting ? <CircularProgress size="1rem"/> : null
                                    }
                                    disabled={Boolean(errors.submit) || isSubmitting}
                                    variant="contained"
                                >
                                    Saqlash
                                </Button>
                            </DialogActions>
                        </form>
                    )}
                </Formik>
            </Dialog>
        </>
    );
});

export default Task;


