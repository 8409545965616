import './Valyuta.css'
import {useEffect, useState} from "react";
import {connect} from "react-redux";
import BolimReducer, {deleteBolim, editBolim, getBolim, saveBolim} from "../../../Maxsulotlar/reducer/BolimReducer";
import users from "../../../../../../reducer/users";
import ValyutaReducer,{getValyuta,editValyuta} from "../reducers/ValyutaReducer";
import {current} from "@reduxjs/toolkit";
import {useTranslation} from "react-i18next";





function Valyuta({getValyuta,users,ValyutaReducer,editValyuta}){


    useEffect(()=>{
        getValyuta(users.businessId)
    },[ValyutaReducer.getBoolean])

    useEffect(()=>{
        if (ValyutaReducer.saveBoolean){
            setActive(false)
            setChangeCurrency('')
        }
    },[ValyutaReducer.saveCurrent])

    const [active,setActive] = useState(false)
    const [changeCurrency,setChangeCurrency] = useState('')


    function handleChangeCurrency(e){
        setChangeCurrency(e.target.value)
    }

    function changeActive(){
        setActive(!active)
        setChangeCurrency(ValyutaReducer.currency.course)
    }

    function saveCurrencyChange(){
        editValyuta({
            id:users.businessId,
            course:changeCurrency
        })
    }

    const {t} = useTranslation()

    return(
        <div className="soliqCont">
            <div className="row">

                <h2 className={'text-center'}>{t('set.VALYUTA')}</h2>
                {
                    ValyutaReducer.currency ?
                        <table className={'table table-success'}>
                            <thead>
                            <tr>
                                <td>{t('Salary.nom')}</td>
                                <td>{t('set.qnm')}</td>
                                <td>{t('set.Kursi')}</td>
                                <td>{t('Hamkorlar.amal')}</td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Dollar</td>
                                <td>USD</td>
                                <td>
                                    { active ?
                                        <div className={'d-flex align-items-center justify-content-space'}>
                                            <input className={'form-control'} style={{
                                                width:'60%',
                                                marginRight:'10px'
                                            }} type="number" value={changeCurrency} onChange={handleChangeCurrency}/> USZ
                                        </div>
                                        :
                                        `${ValyutaReducer.currency.course} USZ`
                                    }
                                </td>
                                <td>
                                    {
                                        active ?
                                            <div>
                                                <button className={'btn btn-success'} onClick={saveCurrencyChange} >{t('Hamkorlar.saqlash')}</button>
                                                <button className={'btn btn-danger'} onClick={changeActive}>{t('Hamkorlar.chiqish')}</button>
                                            </div>:
                                            <button className={'btn btn-info'} onClick={changeActive}>{t('set.kui')}</button>
                                    }
                                </td>
                            </tr>
                            </tbody>
                        </table>:ValyutaReducer.message
                }


            </div>

        </div>
    )
}

export default connect((BolimReducer, ValyutaReducer,users),{
    getBolim,
    getValyuta,
    editValyuta
})(Valyuta)
