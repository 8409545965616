import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../../../../../api";
import {toast} from "react-toastify";
// import {toast} from "react-toastify";

const slice = createSlice({
    name: 'exchanges',
    initialState: {
        exchanges:null,
        exchangeOne:null,
        current:false,
        message: '',
        getBoolean: false,
        saveBoolean:false,
    },
    reducers: {
        getFrom: (state, action) => {
            if (action.payload.success) {
                state.exchanges = action.payload.object
            } else {
                state.exchanges = null
                state.message = action.payload.message

            }
            state.getBoolean = !state.getBoolean
            state.saveBoolean = false
        },

        getFromOne: (state, action) => {
            if (action.payload.success){
                state.exchangeOne = action.payload.object
            }else {
                state.exchangeOne = null
            }
            state.getBoolean = !state.getBoolean
            state.saveBoolean = false
        },
        savefrom: (state, action) => {
            if (action.payload.success) {
                toast.success(`So'rov yuborildi`)
                state.current = !state.current
                    state.saveBoolean = true
            } else {
                toast.error(action.payload.message)
            }
        },
        sendExchangeNotificat:(state,action)=>{
            if (action.payload.success){
                toast.success('Xabar jo`natildi')

            }else {
                toast.error('Xabar yuborilmadi')
            }
        },
        editfrom: (state, action) => {
            if (action.payload.success) {
                toast.success('Otkazma tahrirlandi')
                state.current = !state.current
                state.saveBoolean = true
            } else {
                toast.error(action.payload.message)
            }
        },
        editFromBranch:(state,action)=>{
          if (action.payload.success){
              toast.success('Tahrirlandi')
              state.current = !state.current
              state.saveBoolean = true
          }  else {
              toast.error(action.payload.message)
          }
        },
        editSetting: (state, action) => {
            if (action.payload.success) {
                toast.success('Tahrirlandi')
                // state.current = !state.current
            } else {
                toast.error(action.payload.message)
            }
        },
        deletefrom: (state, action) => {
            if (action.payload.success) {
                toast.success('Otkazma o\'chirildi')
                state.current = !state.current
            } else {
                toast.error(action.payload.message)
            }

        }
    }
});

export const getExchanges = (data) => apiCall({
    url: '/exchangeProductByConfirmation/get-by-business-id/' + data,
    method: 'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type,
});

export const getOneExchanges = (data) => apiCall({
    url: '/exchangeProductByConfirmation/get-by-id/' + data,
    method: 'get',
    onSuccess: slice.actions.getFromOne.type,
    onFail: slice.actions.getFromOne.type
})
export const saveExchanges = (data) => apiCall({
    url: '/exchangeProductByConfirmation',
    method: 'post',
    data,
    onSuccess: slice.actions.savefrom.type,
    onFail:slice.actions.savefrom.type
});
export const sendExchangeNotification = (data) => apiCall({
    url: '/exchange_new',
    method: 'post',
    data,
    onSuccess: slice.actions.savefrom.type,
    onFail:slice.actions.savefrom.type
});

export const editExchanges = (data) => apiCall({
    url: '/exchangeProductByConfirmation/'+data.id,
    method: 'put',
    data,
    onSuccess: slice.actions.editfrom.type,
    onFail: slice.actions.editfrom.type
});

export const editExchangesConfirm = (data) => apiCall({
    url: '/exchangeProductByConfirmation/confirmation/'+data.id,
    method: 'put',
    data,
    onSuccess: slice.actions.editFromBranch.type,
    onFail: slice.actions.editFromBranch.type
});


export const deleteExchanges = (data) => apiCall({
    url: '/exchangeProductByConfirmation/' + data,
    method: 'delete',
    data,
    onSuccess: slice.actions.deletefrom.type,
    onFail: slice.actions.deletefrom.type
})

export default slice.reducer
