import {styled} from '@mui/material/styles';
import {connect} from "react-redux";
import {
    Box,
    Card,
    TextField,
    Button,
    IconButton,
    Divider,
    FormControlLabel,
    Grid,
    Alert,
    Checkbox,
    CircularProgress,
    Tooltip,
    Typography,
    lighten,
    Zoom, AvatarGroup, Avatar
} from '@mui/material';
import {DateTimePicker} from '@mui/lab';

import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {BaseUrl} from "../../../../../middleware";
import person_default from "../../../../../img/person-default.png";
import DatePicker from "@mui/lab/DatePicker";

const IconButtonError = styled(IconButton)(
    ({theme}) => `
     background: ${theme.colors.error.lighter};
     color: ${theme.colors.error.main};

     &:hover {
      background: ${lighten(theme.colors.error.lighter, 0.4)};
     }
`
);

const CardActionsWrapper = styled(Card)(
    ({theme}) => `
     background: ${theme.colors.alpha.black[5]};
     box-shadow: none;
     margin: 0 ${theme.spacing(3)};
`
);


const EventDrawer = ({event, onCancel}) => {


    function renderUserList(list){
        let a = []
        list.map(item=>{
                item.userList.map(value=>{
                    let isHave = a.some(user=>user.id === value.id)
                    if (!isHave){
                        a.push(value)
                    }

                })
            }
        )
        return   (

                <AvatarGroup max={4}>
                    {a.map((member) => (
                        <Link to={`/headerthird/employee/${member.userId}`}
                              style={{backgroundColor: 'transparent'}}>
                            <Tooltip
                                arrow
                                placement="top"
                                key={member?.firstName}
                                title={member?.firstName + " " + member?.lastName}
                            >
                                <Avatar
                                    sx={{
                                        width: 50,
                                        height: 50,
                                    }}
                                    key={member?.id}
                                    src={member?.photo?.id ? `${BaseUrl}/attachment/download/${member?.photo?.id}` : person_default}
                                />
                            </Tooltip>
                        </Link>
                    ))}
                </AvatarGroup>
        )
    }


    return (
        <Box sx={{
            width: '600px'
        }} xs={12}>
            <Box p={3}>
                <Typography variant="h4">
                    Loyiha Ma'lumotlari
                </Typography>
            </Box>
            <Divider/>
            <Box px={3} py={2}>
                {
                    event?.project ?
                        <Grid xs={12} md={12} mb={2}
                              lg={12} display={'flex'} alignItems={'center'} justifyContent={'start'}>
                            <TextField
                                fullWidth
                                label={"Project Nomi"}
                                value={event?.project.name}
                                variant="standard"
                            />
                        </Grid>:''
                }

                <Grid xs={12} md={12} mb={2}
                      lg={12} display={'flex'} alignItems={'center'} justifyContent={'start'}>
                    <TextField
                        fullWidth
                        label={"Task Nomi"}
                        value={event?.name}
                        variant="standard"
                    />
                </Grid>
                <Grid container spacing={2}  md={12} mb={2}
                      xs={12}
                      lg={12} display={'flex'} alignItems={'center'} justifyContent={'start'}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label={"Muhimligi"}
                            value={event?.importance}
                            variant={'standard'}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label={"Vazifa turi"}
                            name="invalid"
                            value={event?.taskType?.name}
                            variant="standard"
                        />
                    </Grid>


                </Grid>
                <Grid xs={12} md={12}
                      lg={12} mb={2}>
                    <Typography variant={'h3'} textAlign={'center'}>Vazifaga biriktirilgan hodimlar</Typography>
                    <Typography display={'flex'} justifyContent={'center'}>
                        {
                            event?.taskPriceList ?
                                renderUserList(event.taskPriceList)
                               : ''
                        }
                    </Typography>

                </Grid>
                <Grid container spacing={2}  md={12} mb={2}
                      xs={12}
                      lg={12} display={'flex'} alignItems={'center'} justifyContent={'space-between '}>
                    <Grid item xs={12} md={6}>
                        <DatePicker
                            label={'startDate'}
                            name={'startDate'}
                            format="MM/dd/yyyy"
                            value={event?.startDate}
                            disabled={true}
                           renderInput={(params) => (
                            <TextField
                                fullWidth
                                variant={'standard'}
                                placeholder={'Select due date...'}
                                {...params}
                            />
                        )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DatePicker
                            label={'endDate'}
                            name={'endDate'}
                            format="MM/dd/yyyy"
                            value={event?.deadLine}
                            disabled={true}
                            renderInput={(params) => (
                                <TextField
                                    fullWidth
                                    variant={'standard'}
                                    placeholder={'Select due date...'}
                                    {...params}
                                />
                            )}
                        />
                    </Grid>


                </Grid>

            </Box>
            <CardActionsWrapper
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end',
                    p: 2
                }}
            >
                <Box display={'flex'} justifyContent={'end'}>
                    <Button
                        variant={'contained'}
                        sx={{
                            mr: 1
                        }}
                        color="primary"
                        onClick={onCancel}
                    >
                        Back
                    </Button>
                </Box>
            </CardActionsWrapper>

        </Box>
    );
};


export default EventDrawer;
