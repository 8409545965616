import React from 'react'
import {Link} from 'react-router-dom'
import CSV from '../../../../../img/CSV.png'
import Excel from '../../../../../img/Excel.png'
import Print from '../../../../../img/Print.png'
import Data from '../../../../../img/Data.png'
import Pdf from '../../../../../img/PDF.png'
import Edit from '../../../../../img/Edit.png'
import Korish from '../../../../../img/Korish.png'
import Delete from '../../../../../img/Delete.png'
import Arrow from '../../../../../img/arrowIcon.png'
import './car.css'
import {connect} from "react-redux";
import {useEffect, useState} from "react";
import OtkazmaReducer, {
    deleteOtkazma,
    editOtkazma,
    saveOtkazma,
    getOtkazma,
    getOtkazmaByBranch, getOneExchange
} from "../reducer/OtkazmaReducer";
import users from "../../../../../reducer/users";
import {useTranslation} from "react-i18next";
import formatDate from "../../../../../util";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Loading from "../../../../Loading";
import CarReducer, {editCar, getCars, getOneCar, saveCar} from "../reducer/CarReducer";
import ModalLoading from "../../../../ModalLoading";
import {IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import LaunchTwoToneIcon from "@mui/icons-material/LaunchTwoTone";
import {toast} from "react-toastify";

function OtkazmalarRoyxati({
                               deleteOtkazma,
                               saveOtkazma,
                               match,
                               users,
                               OtkazmaReducer, CarReducer, saveCar, editCar, getCars, getOneCar
                           }) {

    const {t} = useTranslation()
    const [input, setInput] = useState(
        {
            view: '',
            search: '',
        }
    )

    function view(e) {
        input.view = e.target.value
        let a = {...input}
        setInput(a)
    }

    function search(e) {
        input.search = e.target.value
        let a = {...input}
        setInput(a)
    }

    // useEffect(()=>{
    //     getCars(users.businessId)
    // },[])

    useEffect(() => {
        if (users.viewExchangeAdmin && users.viewExchange) {
            getCars(users.businessId)
        }
    }, [CarReducer.current])


    const [malkamay, setmalkamay] = useState(false)

    const [activeinfo, setactiveinfo] = useState(false)
    const [openAddModal, setOpenAddModal] = useState(false)
    const [carNumber, setCarNumber] = useState(null)
    // const [color,setColor] = useState(null)
    const [color, setColor] = useState('#000000')
    const [driver, setDriver] = useState(null)
    const [model, setModel] = useState(null)
    const [saveModal, setSaveModal] = useState(false)

    function openModalToggle() {
        setOpenAddModal(!openAddModal)
        setColor('#000000')
        setCarNumber(null)
        setDriver(null)
        setModel(null)
        setSaveModal(null)
        setSaveId(null)
    }

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
    }, [CarReducer.getBoolean])


    function toggleinfo() {
        setactiveinfo(!activeinfo)
    }

    function info(id) {
        toggleinfo()
        getOneCar(id)
    }

    const [deletemodal, setdeletemodal] = useState(false)
    const [deleteID, setdeletID] = useState('')

    function deleteFunc() {
        deleteOtkazma(deleteID)
        setLoading(false)
        deleteModaltoggle('')
    }


    function deleteModaltoggle(item) {
        setdeletemodal(!deletemodal)
        setdeletID(item)
    }

    const [saveId, setSaveId] = useState(null)

    function edit(id) {
        openModalToggle()
        setSaveId(id)
        CarReducer.cars.map(item => {
            if (item.id === id) {
                setColor(item.color)
                setDriver(item.driver)
                setCarNumber(item.carNumber)
                setModel(item.model)
            }
        })
    }

    function save() {
        if (saveId) {
            editCar({
                carNumber, color, driver, model, businessId: users.businessId, id: saveId
            })
        } else {
            saveCar({
                carNumber, color, driver, model, businessId: users.businessId
            })
        }

        setSaveModal(true)
    }

    useEffect(() => {
        if (CarReducer.saveBoolean) {
            setColor('#000000')
            setCarNumber(null)
            setDriver(null)
            setModel(null)
            getCars(users.businessId)
            setSaveModal(null)
        }
        setSaveModal(false)
        setOpenAddModal(false)
    }, [CarReducer.current])


    useEffect(() => {
        setLoading(false)
    }, [])

    return (
        <div className="col-md-12 mt-2 mt-4 mb-4 ">
            <div className="textHeader">
                <h2>{t('baza.Mashinalar')}</h2>
            </div>
            <div className="rowStyleN">
                <div className="qoshish">
                    <h5>{t('BaseList.2')}</h5>
                    {
                        users.addExchange ?
                            <button onClick={openModalToggle} className='btn btn-primary'>+{t('Buttons.2')}</button>
                            : ''
                    }

                </div>
                {
                    users.viewExchange || users.viewExchangeAdmin ?
                        loading ?
                            CarReducer.cars ?
                                <div>
                                    <div className="izlashOR">
                                        <div className="izlashBox1">
                                            <p>{t('Buttons.8')}</p>
                                            <select name="" value={input.view} onChange={view} id="">
                                                <option value="">25</option>
                                                <option value="">1,000</option>
                                                <option value="">All</option>
                                            </select>
                                            <button><img src={CSV} alt=""/> Export CSV</button>
                                            <button><img src={Excel} alt=""/> Export Excel</button>
                                            <button><img src={Print} alt=""/> Print</button>
                                            <button><img src={Pdf} alt=""/>Export PDF</button>
                                            <button onClick={() => setmalkamay(!malkamay)}><img src={Data}
                                                                                                alt=""/>{t('Buttons.18')}
                                            </button>


                                        </div>
                                        <div className="izlashBox2">
                                            <input type="text" placeholder={t('tes.73')} value={input.search}
                                                   onChange={search}/>
                                        </div>
                                    </div>
                                    <div className="table-responsive table-wrapper-scroll-y my-custom-scrollbar">
                                        <TableContainer className='table table-striped table-bordered mt-4'>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>#</TableCell>
                                                        <TableCell>{t('baza.Haydovchi')}</TableCell>
                                                        <TableCell align={'center'}>{t('baza.Mashina')}</TableCell>
                                                        <TableCell align={'center'}>{t('baza.Raqami')}</TableCell>
                                                        <TableCell align={'center'}>{t('baza.Rangi')}</TableCell>
                                                        <TableCell align={'center'}>{t('Hamkorlar.amal')}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        CarReducer.cars?.map((item, index) => <TableRow key={index}>
                                                            <TableCell>{index + 1}</TableCell>
                                                            <TableCell>{item.driver}</TableCell>
                                                            <TableCell align={'center'}>{item.model}</TableCell>
                                                            <TableCell align={'center'}>{item.carNumber}</TableCell>
                                                            <TableCell align={'center'}>
                                                                {/*<div className={'d-flex align-items-center col-md-12'}>*/}
                                                                        <input disabled={true} value={item.color}  type="color"
                                                                               className={'form-control'}/>
                                                                {/*</div>*/}
                                                            </TableCell>


                                                            <TableCell align={'center'}>
                                                                <Tooltip title={t('baza.kurish')} arrow>
                                                                    <IconButton
                                                                        onClick={() => info(item.id)}
                                                                        color="primary"
                                                                    >
                                                                        <LaunchTwoToneIcon fontSize="small"/>
                                                                    </IconButton>
                                                                </Tooltip>

                                                                <Tooltip title={t("baza.Tahrirlash")}
                                                                         arrow>
                                                                    <IconButton
                                                                        onClick={() => edit(item.id)}
                                                                        color="primary"
                                                                    >
                                                                        <EditIcon fontSize="small"/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCell>

                                                        </TableRow>)
                                                    }
                                                </TableBody>
                                            </Table>


                                            <Modal size={'xl'} isOpen={activeinfo} toggle={toggleinfo}>
                                                <ModalHeader>
                                                    <h4>{t('baza.BATAFSIL')}</h4>
                                                </ModalHeader>
                                                <ModalBody>
                                                    <table
                                                        className={'table-hover table-secondary table table-bordered mt-3 table-striped-columns'}>
                                                        <thead>
                                                        <tr>
                                                            <th>{t('baza.Haydovchi')}</th>
                                                            <th>{t('baza.Mashina')}</th>
                                                            <th>{t('baza.Raqami')}</th>
                                                            <th>{t('baza.Rangi')}</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td>{CarReducer.carOne?.driver}</td>
                                                            <td>{CarReducer.carOne?.model}</td>
                                                            <td>{CarReducer.carOne?.color}</td>
                                                            <td>{CarReducer.carOne?.carNumber}</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </ModalBody>
                                                <ModalFooter>
                                                    <button className={'btn btn-outline-primary'}
                                                            onClick={toggleinfo}>{t('Hamkorlar.chiqish')}
                                                    </button>
                                                </ModalFooter>
                                            </Modal>
                                        </TableContainer>
                                    </div>
                                </div> : <div>
                                    <h4 className={'text-center'}>{t('Darslik.mtt')}</h4>
                                </div> : <Loading/> : ''
                }

            </div>

            <Modal size={'xl'} isOpen={openAddModal} toggle={openModalToggle}>
                <ModalHeader>
                    <h4>{t('baza.mashq')}</h4>
                </ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-md-6">
                            <label htmlFor="">{t('baza.mashm')}</label>
                            <input type="text" className={'form-control'} value={model}
                                   onChange={(e) => setModel(e.target.value)}/>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="">{t('baza.mashr')}</label>
                            <input type="text" className={'form-control'} value={carNumber}
                                   onChange={(e) => setCarNumber(e.target.value)}/>
                        </div>
                        {/*<div className="col-md-6">*/}
                        {/*    <label htmlFor="">Mashina rangi</label>*/}
                        {/*    <input type="text" className={'form-control'} value={color} onChange={(e)=>setColor(e.target.value)}/>*/}
                        {/*</div>*/}
                        <div className={'d-flex mt-2 align-items-center col-md-12'}>
                            <div className={'col-md-6'}><label htmlFor="rang">{t('Lids.rangdaBelgilash')}</label>
                                <input value={color} onChange={(e) => setColor(e.target.value)} type="color"
                                       className={'form-control'}/>
                            </div>
                            <div className={'col-md-6 '}>
                                <label htmlFor="value">{t('tes.74')}</label>
                                <input type="text" className={'form-control'} value={color}
                                       onChange={(e) => setColor(e.target.value)}/>
                            </div>
                        </div>
                        <div className="col-md-12 mt-2">
                            <label htmlFor="">{t('baza.hayi')}</label>
                            <input type="text" className={'form-control'} value={driver}
                                   onChange={(e) => setDriver(e.target.value)}/>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button onClick={save} className={'btn btn-outline-primary'}>{t('Hamkorlar.saqlash')}</button>
                    <button onClick={openModalToggle} className={'btn btn-outline-primary'}>{t('Hamkorlar.chiqish')}</button>
                </ModalFooter>
            </Modal>


            <ModalLoading isOpen={saveModal}/>
        </div>
    )
}

export default connect((OtkazmaReducer, CarReducer, users), {
    getOtkazma,
    saveOtkazma,
    getOneExchange,
    getOtkazmaByBranch,
    editOtkazma,
    deleteOtkazma,
    getCars, saveCar, editCar, getOneCar
})(OtkazmalarRoyxati)
