import React, {useEffect, useState} from 'react';
import {
    Box,
    Card,
    FormControl, IconButton, InputLabel, MenuItem, Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead, TablePagination,
    TableRow, Tooltip,
    Typography
} from "@mui/material";
import formatDate from "../../../../util";
import CircleIcon from "@mui/icons-material/Circle";
import LaunchTwoToneIcon from "@mui/icons-material/LaunchTwoTone";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicate";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import {connect} from "react-redux";
import users, {userDoHistory} from "../../../../reducer/users";
import {useTranslation} from "react-i18next";

function UserWorkTable({users, userId, userDoHistory}) {

    const {t} = useTranslation();

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(5);

    useEffect(() => {
        userDoHistory({
            id: userId ? userId : users.id,
            params: {
                size: limit,
                page: page
            }
        })
    }, [limit, page])


    const handlePageChange = (_event, newPage) => {
        setPage(newPage);
    };

    const handleLimitChange = (event) => {
        setLimit(parseInt(event.target.value));
    };

    return (
        <Card style={{margin: "30px", background: "#ffffff"}}>
            {users.userHistory?.getLessProduct.length > 0 ?
                <>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Tr</TableCell>
                                    <TableCell>{t('tes.40')}</TableCell>
                                    <TableCell>{t('tes.41')}</TableCell>
                                    <TableCell>{t('tes.42')}</TableCell>
                                    <TableCell>Foydasi</TableCell>
                                    <TableCell>{t('tes.43')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody style={{background: 'rgba(255,255,255,0.2)'}}>
                                {users.userHistory?.getLessProduct.map((item, index) => {
                                    return (
                                        <TableRow hover key={item.id}>
                                            <TableCell>
                                                <Typography variant="h5">
                                                    {index + (limit * page) + 1}
                                                </Typography></TableCell>
                                            <TableCell >
                                                <Typography variant="h5">
                                                    {item?.branchName}
                                                </Typography>
                                            </TableCell>
                                            <TableCell >
                                                <Typography variant="h5">
                                                    {item?.name}
                                                </Typography>
                                            </TableCell>
                                            <TableCell >
                                                <Typography>{item?.description}</Typography>
                                            </TableCell>
                                            <TableCell >
                                                <Typography>{item?.profit ? item?.profit : 0}</Typography>
                                            </TableCell>
                                            <TableCell >
                                                <Typography>{formatDate(item?.createdAt)}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box p={2} alignItems={'center'}>
                        <TablePagination
                            component="div"
                            count={users.userHistory?.totalItem}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleLimitChange}
                            page={page}
                            rowsPerPage={limit}
                            rowsPerPageOptions={[5, 10, 15]}
                        />
                    </Box>
                </> : <Typography
                    variant="h3"
                    textAlign={'center'}
                >
                    {t('tes.44')}
                </Typography>}

        </Card>
    );
}

export default connect((users), {userDoHistory})(UserWorkTable);
