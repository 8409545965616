import {forwardRef, useState} from 'react';
import { Box, Dialog, Grid, Slide, styled, Typography} from '@mui/material';
import {connect} from "react-redux";
import users from "../../../../../reducer/users";
import {FormGroup, Label, Button as ButtonBootstarp} from "reactstrap";
import {useForm} from "react-hook-form";
import {axiosCreate} from "../../../../../middleware";
import {useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

const DialogWrapper = styled(Dialog)(
    () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const QuizStart = ({ users }) => {
    const {t} = useTranslation()
    const [modal,setModal] = useState(false)
    const {register, handleSubmit, reset, formState: {errors}} = useForm()
    const {testId} = useParams()
    const history = useHistory()
    const test =  JSON.parse(sessionStorage.getItem('test'))
    const [trueAnswer,setTrueAnswer] = useState(0)

    const onHandleSubmit = (data) => {
        let trueCount = 0
        test.map((item,index) => {
            item.trueAnswer === data[`question${index}`] && ++trueCount
        })
        setTrueAnswer(trueCount)
        testId && axiosCreate.post('/lessonUser/test-result',{
            "lessonId": testId,
            "testResult": trueCount,
            "userId": users.id
        }).then(() => {
            setModal(true)
        })
    }

    const closeConfirm = () => {
        setModal(false)
        sessionStorage.removeItem('test')
        history.push('/main/lessons/lessonByUser')
        reset()
    }

    return (
        <>
            <Box className="MuiPageTitle-wrapper p-5">
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h3" component="h3" gutterBottom>
                            {t('Darslik.testi')}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Grid
                sx={{
                    px: 4
                }}
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                spacing={4}
            >
                <Grid item xs={12}>
                    <form onSubmit={handleSubmit(onHandleSubmit)}>
                        {
                            test && test.map((item, index) => {
                                return(
                                    <FormGroup>
                                        <Label style={{marginBottom: 10, fontWeight: 700, fontSize: 18}} for="exampleCheckbox">{index+1 + ". " + item.question}</Label>
                                        <fieldset id={`question${index}`} style={{display: 'flex', flexDirection: 'column'}}>
                                            {
                                                item.questions.map((data) => {
                                                    return(<label style={{display: 'flex', gap: 10, alignItems:'center', marginBottom: 5}} className="form-control form-control-sm">
                                                            <input type="radio" {...register(`question${index}`)} name={`question${index}`} value={data} required/>
                                                            <p style={{marginBottom: 0}}>{data}</p>
                                                    </label>
                                                    )
                                                })
                                            }
                                        </fieldset>
                                    </FormGroup>
                                )
                            })
                        }
                        <FormGroup>
                            <ButtonBootstarp type={'submit'} color={'primary'}>
                                {t('Darslik.s')}
                            </ButtonBootstarp>
                        </FormGroup>
                    </form>
                </Grid>
            </Grid>
            <DialogWrapper
                open={modal}
                maxWidth="sm"
                fullWidth
                TransitionComponent={Transition}
                keepMounted
                onClose={closeConfirm}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    p={5}
                >
                    <Typography
                        align="center"
                        sx={{
                            py: 4,
                            px: 6
                        }}
                        variant="h3"
                    >
                        {
                            `{t('Darslik.siz')} ${trueAnswer} {t('Darslik.ttjb')}`
                        }
                    </Typography>
                </Box>
            </DialogWrapper>
        </>
    );
};
export default connect((users), {
})(QuizStart)
