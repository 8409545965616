import {createSlice} from '@reduxjs/toolkit'
import {apiCall} from "../../../../../../api";
import {toast} from 'react-toastify'

const slice = createSlice({
    name: 'lids',
    initialState:{
        lids: null,
        lidsTable:null,
        lidsPage:null,
        lidOne: null,
        formSource: null,
        current: false,
        pageCurrent:false,
        message: '',
        getBoolean: false,
        saveBoolean: false,
        deleteBoolean: false,
        oneList: null,
        izohCurrent:false
    },
    reducers:{
        getFrom: (state,action)=>{
            if (action.payload.success){
                state.lids = action.payload.object
                state.lidsTable =action.payload.object.getLessProduct

            }else {
                state.lids = null
                state.lidsTable = null
                state.message = action.payload.message
            }
            state.saveBoolean = false
            state.getBoolean = !state.getBoolean
            state.izohCurrent=false
        },
        getFromPage: (state,action)=>{
            if (action.payload.success){
                state.lidsPage = action.payload.object

            }else {
                state.message = action.payload.message
            }
            state.saveBoolean = false
            state.pageCurrent=!state.pageCurrent

        },
        getOneFrom: (state,action)=>{
            if (action.payload.success){
                state.lidOne = action.payload.object
            }else {
                state.message = action.payload.message
            }

        },
        saveFrom: (state,action)=>{
            if (action.payload.success){
                toast.success('ADDED')
                state.saveBoolean = true
            }else {
                toast.error(action.payload.message)
            }
            state.current=!state.current
        },
        editForm:(state,action)=>{
            if (action.payload.success){
                toast.success('EDITED')
                state.saveBoolean = true
            }else {
                toast.error(action.payload.message)
            }
            state.current=!state.current
            state.izohCurrent=true
        },
        deleteFrom: (state,action)=>{
            if (action.payload.success){
                toast.success('DELETED')
                state.current=!state.current
            }else {
                toast.error(action.payload.message)
            }
            state.deleteBoolean = true
        }
    }
})

export const getLid =(data)=>apiCall({
    url: `/lid/get-by-businessId/${data.businessId}`,
    method: 'get',
    params:data.params,
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type
})
export const getLidPage =(data)=>apiCall({
    url: `/lid/get-by-businessId-pageable/${data.businessId}?${data.sourceId ? `sourceId=${data.sourceId}` :''}`,
    params:data.params,
    method: 'get',
    onSuccess: slice.actions.getFromPage.type,
    onFail: slice.actions.getFromPage.type
})


export const getOneLid =(data)=>apiCall({
    url: '/lid/get-by-id/'+data,
    method: 'get',
    onSuccess: slice.actions.getOneFrom.type,
    onFail: slice.actions.getOneFrom.type
})
export const saveLid =(data)=>apiCall({
    url: '/lid',
    method: 'post',
    data,
    onSuccess: slice.actions.saveFrom.type,
    onFail: slice.actions.saveFrom.type
})
export const saveIzoh =(data)=>apiCall({
    url: `/lid/edit-description/${data.id}?description=${data.description}`,
    method: 'put',
    data,
    onSuccess: slice.actions.editForm.type,
    onFail: slice.actions.editForm.type
})

export const editLid =(data)=>apiCall({
    url: `/lid/edit-status/${data.id}?statusId=${data.statusId}&userId=${data.userId}`,
    method: 'put',
    data,
    onSuccess: slice.actions.editForm.type,
    onFail: slice.actions.editForm.type
})

export const deleteLid =(data)=>apiCall({
    url: '/lid/'+data,
    method: 'delete',
    data,
    onSuccess: slice.actions.deleteFrom.type,
    onFail: slice.actions.deleteFrom.type
})
export default slice.reducer
