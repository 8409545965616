import {useState, useEffect} from "react";
import {connect} from 'react-redux'
import {useTranslation} from "react-i18next";
import {DatePicker} from "antd";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import Savdolar1 from "./Savdolar/1/Savdolar1";
import users from "../../../../../reducer/users";
import MijozHisobotiReducer, {getMijozhisobotsana} from '../reducer/MijozHisobotiReducer'
import {getAccount} from "../../../../../reducer/accountreducer";
import MainHeaderText from "../../../../Components/MainHeaderText";
import XodimReducer, {getXodimByBranch} from "../../Hodimlar/reducer/XodimReducer";
import formatDateYear from "../../../../../reducer/sana";
import {tablePageSize} from "../../../../../util";

import './mijozlarxisoboti.css'

const {RangePicker} = DatePicker

function MijozlarXisoboti({
                              MijozHisobotiReducer,
                              getMijozhisobotsana,
                              XodimReducer,
                              getXodimByBranch,
                              getAccount,
                              users
                          }) {

    const {t} = useTranslation()
    const [pageData, setPageData] = useState({
        page: 0,
        pageSize: tablePageSize ? tablePageSize : 10,
        branch: users.branchId,
        customer: null,
        seller: "ALL",
        dates: null,
        dateValue: null,
        loading: false
    });

    function selectBranch(e) {
        setPageData((prev) => ({...prev,branch: e.target.value,seller: null, customer: null, dates: null, dateValue: null, page: 0}))
        getXodimByBranch(e.target.value)
        getAccount(e.target.value)
    }

    function selectSeller(e) {
        setPageData((prev) => ({...prev, seller: e.target.value , dates: null, dateValue: null, page: 0}))
    }

    useEffect(() => {
        setPageData((prev) => ({...prev,loading: false}))
    }, [MijozHisobotiReducer.loading])

    useEffect(() => {
        setPageData((prev) => ({...prev,loading: true}))
    }, [])

    useEffect(() => {
        getXodimByBranch(pageData.branch)
        getAccount(pageData.branch)
        getMijozhisobotsana({
            branchId: pageData.branch,
            page: pageData.page,
            size: pageData.pageSize,
            customer: pageData.customer,
            seller: pageData.seller === "ALL" ? null : pageData.seller,
            startDate:  pageData.dates ? pageData.dates[0] : null,
            endDate: pageData.dates ? pageData.dates[1] : null
        })
        setPageData((prev) => ({...prev,loading: true}))
    }, [pageData.page, pageData.pageSize, pageData.customer, pageData.seller, pageData.branch])

    const onChange = (pageNumber, page) => {
        setPageData((prev) => ({ ...prev, pageSize: page, page: pageNumber - 1 }));
        localStorage.setItem("pageSize", JSON.stringify(page))
    };

    return (
        <div className="mt-4 mb-4 reportsCus">
            <div class="row p-3">
                <MainHeaderText text={t('PurchaseList.5')}/>
            </div>
            <div className="row align-items-center pt-4">
                <div className="col-lg-4 col-md-6 pb-4">
                    <FormControl sx={{minWidth: '100%'}}>
                        <InputLabel style={{fontSize: 16, lineHeight: "24px"}} id="demo-select-small-label">{t('cost.baza')}</InputLabel>
                        <Select
                            className="selectCustomerRep"
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={pageData.branch}
                            label={t('cost.baza')}
                            IconComponent={KeyboardArrowDownIcon}
                            onChange={selectBranch}
                        >
                            {
                                users.branches.map(item =>
                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </div>
                <div className="col-lg-4 col-md-6 pb-4">
                    <FormControl sx={{minWidth: '100%'}} size="small">
                        <InputLabel className="selectLabelCustomerRep" id="demo-select-small-label">{t('Davomat.hodim')}</InputLabel>
                        <Select
                            className="selectCustomerRep"
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={pageData.seller}
                            label={t('Davomat.hodim')}
                            IconComponent={KeyboardArrowDownIcon}
                            onChange={selectSeller}
                        >
                            <MenuItem value="ALL">{t('Employ.3')}</MenuItem>
                            {
                                XodimReducer.xodimlar ?
                                  XodimReducer.xodimlar.map(item =>
                                        <MenuItem value={item.id}>{item.firstName} {item.lastName}</MenuItem>) : null
                            }
                        </Select>
                    </FormControl>
                </div>
                <div className="col-lg-4 col-md-6 pb-4">
                    <RangePicker className="dateCustomerRep" placeholder={[t('baza.bs'), t('Salary.tsa')]} value={pageData.dateValue} onChange={(values) => {
                        let a = []
                        if (values !== null) {
                            values.map(item => a.push(formatDateYear(item)))
                            setPageData((prev) => ({...prev, dates: a, dateValue: values, page: 0}))
                            getMijozhisobotsana({
                                branchId: pageData.branch,
                                page: 0,
                                size: pageData.pageSize,
                                customer: pageData.customer,
                                seller: pageData.seller,
                                startDate: a ? a[0] : null,
                                endDate: a ? a[1] : null
                            })
                        } else {
                            setPageData((prev) => ({...prev, dates: null, dateValue: null, page: 0}))
                            getMijozhisobotsana({
                                branchId: pageData.branch,
                                page: 0,
                                size: pageData.pageSize,
                                customer: pageData.customer,
                                seller: pageData.seller,
                                startDate: null,
                                endDate: null,
                            })
                        }
                    }
                    }/>
                </div>
            </div>
            <Savdolar1 loading={pageData.loading} setPageData={setPageData} pageData={pageData} onChange={(pageNumber,page) => onChange(pageNumber, page)}/>
        </div>
    )
}

export default connect((users, MijozHisobotiReducer, XodimReducer),
    {
        getMijozhisobotsana,
        getXodimByBranch,
        getAccount
    })(MijozlarXisoboti)
