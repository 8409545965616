import './xisobotlar.css'
import {useEffect} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {connect} from "react-redux";
import {active} from "../../../../reducer/functionreducer";
import {useTranslation} from "react-i18next";
import {Tabs} from "antd";
import FoydaZarar from "./foydaZarar/FoydaZarar";
import XaridlarXisoboti from "./XaridlarXisoboti/XaridlarXisoboti";
import MijozlarXisoboti from "./MijozlarXisoboti/MijozlarXisoboti";
import SavdodaTulov from "./SavdodaQilinganTulov/SavdodaTulov";
import MaxsulotXisoboti from "./MaxsulotlarXisoboti/MaxsulotXisoboti";
import KopSotilgan from "./KopSotilganTovarlar/KopSotilgan";
import QoldiqlarXisoboti from "./qoldiqlarXisoboti/QoldiqlarXisoboti";
import MaxsulotMiqdoriQoldigi from "./MahsulotMiqdoriQoldigi/MaxsulotMiqdoriQoldigi";
import XarajatlarXisoboti from "./xarajatlarXisoboti/XarajatlarXisoboti";
import KonstruktorXisobot from "./KonstruktorXisobot/KonstruktorXisobot";

function Xirsobotlar({sidebaractive2, changeLink, link}) {
    const history = useHistory()
    const location = useLocation()
    const {t} = useTranslation()

    const items = [
        {
            label: t('Sidebar.30'),
            key: '/main/reports/profit',
            children: <FoydaZarar/>,
        },
        {
            label: t('Sidebar.31'),
            key: '/main/reports/purchase',
            children: <XaridlarXisoboti/>,
        },
        {
            label: t('Sidebar.32'),
            key: '/main/reports/customer',
            children: <MijozlarXisoboti/>,
        },
        {
            label: "Konstruktor xisoboti",
            key: '/main/reports/constructorReports',
            children: <KonstruktorXisobot/>,
        },
        {
            label: t('Sidebar.33'),
            key: '/main/reports/outlay',
            children: <XarajatlarXisoboti/>,
        },
        {
            label: t('Sidebar.34'),
            key: '/main/reports/trade',
            children: <SavdodaTulov/>,
        },
        {
            label: t('Sidebar.35'),
            key: '/main/reports/products',
            children: <MaxsulotXisoboti/>,
        },
        {
            label: t('ExpenseReport.10'),
            key: '/main/reports/mostSaleProducts',
            children: <KopSotilgan/>,
        },
        // {
        //     label: `Loyihalar`,
        //     key: '/main/reports/project',
        //     children: <ProjectReport/>,
        // },
        {
            label: t('Sidebar.38'),
            key: '/main/reports/remainProducts',
            children: <QoldiqlarXisoboti/>,
        },
        {
            label: t('Sidebar1.mahsulotmiqdori'),
            key: '/main/reports/quantityProducts',
            children: <MaxsulotMiqdoriQoldigi/>,
        }
    ]

    useEffect(() => {
        !items.some(item => item.key === location.pathname) && history.push('/main/reports/profit')
    }, []);

    const onChange = (key) => {
        history.push(key)
    };

    return (
        <div>
            <h4 className={'p-3 mainText'}>{t('Sidebar.29')}</h4>
            <div className={'px-3 pb-5'}>
                <Tabs
                    activeKey={location.pathname}
                    onChange={onChange}
                    type="card"
                    items={items}
                />
            </div>
        </div>
    )
}

export default connect(({functionreducer: {func}}) => ({func}), {active})(Xirsobotlar)
