import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../api";
import {toast} from "react-toastify";

const slice = createSlice({
    name: 'prize',
    initialState: {
        prize:null,
        viewPrize:null,
        current:false,
        getBoolean:false,
        saveBoolean:false,
        message:'',
        saveCurrent:false,
        bonus: null
    },
    reducers: {
        getFrom: (state, action) => {
            if (action.payload.success){
                state.prize = action.payload.object
            }
            else{
                state.prize = null
                state.message = action.payload.message
            }
            state.getBoolean = !state.getBoolean
            state.saveBoolean = false
            state.saveCurrent = false
        },
        getSumm:(state,action)=>{
            if (action.payload.success){
                state.bonus=action.payload.object
            }
        },
        getView:(state,action)=>{
            if (action.payload.success){
                state.viewPrize = action.payload.object
            }
        },
        savefrom: (state,action) => {
            if (action.payload.success){
                toast.success('Success')
                state.saveBoolean = true
            }
            else{
                toast.error(action.payload.message)
            }
            state.saveCurrent=!state.saveCurrent
        },
        editfrom: (state,action) => {
            if (action.payload.success){
                toast.success('Success')
                state.saveBoolean = true
            }
            else{
                toast.error(action.payload.message)
            }
            state.current=!state.current

        },
        deletefrom:(state,action)=>{
            if(action.payload.success){
                toast.success('Ruyhatdan o`chirildi')
            }
            else {
                toast(action.payload.message)
            }
            state.current=!state.current
        }
    }
});

export const getPrize=(data)=>apiCall({
    url: '/prize/by-branch/'+data,
    method:'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type,
});

export const getBonusSumm=(data)=>apiCall({
    url: `/prize/by-user-last-month/${data.userId}?${data.branchId? `branchId=${data.branchId}`:''}`,
    method:'get',
    onSuccess: slice.actions.getSumm.type,
    onFail: slice.actions.getSumm.type,
});

export const viewPrize=(data)=>apiCall({
    url: `/prize/by-user-all/${data.userId}`,
    method:'get',
    params:data.params,
    onSuccess: slice.actions.getView.type,
    onFail: slice.actions.getView.type,
});
export const savePrize=(data)=>apiCall({
    url: '/prize',
    method:'post',
    data,
    onSuccess: slice.actions.savefrom.type,
    onFail: slice.actions.savefrom.type,
});
export const  editBonus=(data)=>apiCall({
    url: '/bonus/'+data.id,
    method:'put',
    data,
    onSuccess: slice.actions.editfrom.type,
    onFail: slice.actions.editfrom.type,
});
export const  changeBonusStatus=(data)=>apiCall({
    url: '/bonus/set-active/'+data,
    method:'put',
    data,
    onSuccess: slice.actions.editfrom.type,
    onFail: slice.actions.editfrom.type,
});
export const deleteBonus=(data)=>apiCall({
    url: `/bonus/`+data,
    method:'delete',
    onSuccess: slice.actions.deletefrom.type,
    onFail: slice.actions.deletefrom.type,
});

export default slice.reducer