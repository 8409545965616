import PropTypes from 'prop-types';
import {Droppable, Draggable} from 'react-beautiful-dnd';
import {
    Box,
    Button,
    IconButton,
    Tooltip,
    Card,
    Typography,
    styled
} from '@mui/material';
import Label from '../Label';
import {useTranslation} from 'react-i18next';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Task from '../Tasks/Task'
import VisibilityIcon from "@mui/icons-material/Visibility";
import users from "../../../../../reducer/users";
import {connect} from "react-redux";
import TaskReducer,{taskInvalidAmount} from "../../../../../reducer/TaskReducer";
import {useEffect, useState} from "react";

const ListColumnWrapper = styled(Card)(
    ({theme}) => `
      width: 340px;
      min-width: 340px;
      margin-right: ${theme.spacing(3)};
      border-top-width: 8px;
      border-top-style: solid;
  `
);

const IconButtonEdit = styled(IconButton)(
    ({theme}) => `
      margin-right: ${theme.spacing(0.5)};
      padding: ${theme.spacing(0.4)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(17)};
        color: ${theme.colors.primary.main};
      }
  `
);

const ButtonAdd = styled(Button)(
    ({theme}) => `
      background-color: ${theme.colors.alpha.black[10]};
      padding: ${theme.spacing(1)};
  `
);


const Tasks = ({listId, deleteFunc, editFunc, onMore, onLess, tasks, showMore, users,taskInvalidAmount,TaskReducer}) => {
    const {t} = useTranslation();

    function EnterInvalidAmount(data){
        taskInvalidAmount(data)
    }

    const [pateria,setPateria] =  useState(false)

    function openPateriaModal(){
        setPateria(!pateria)
    }

    useEffect(()=>{
        if (TaskReducer.saveBooleanInvalid){
            openPateriaModal()
        }
    },[TaskReducer.current])

    return (
        listId.aboolean ?
            <ListColumnWrapper
                sx={{
                    borderColor: listId.color
                }}
                style={{
                    width: '50px',
                    minWidth: '50px',
                    maxHeight: '700px',
                    minHeight: '700px'
                }}
            >
                <Box
                    px={2}
                    pt={3}
                    width={'100%'}
                    height={"100%"}
                >
                    <Box style={{transform: 'rotateZ(90deg)'}} display="flex" justifyContent="space-between"
                         alignItems="center">
                                <Tooltip style={{transform: 'scale(1.4)'}} arrow placement="top" title={t('Expand')}>
                                    <IconButtonEdit onClick={() => onMore(listId.id)}>
                                        <UnfoldMoreIcon/>
                                    </IconButtonEdit>
                                </Tooltip>
                        <Typography mx={2} style={{height: '70%', whiteSpace: 'nowrap'}} color="inherit" variant="h3">
                            {listId.orginalName ? listId.orginalName : listId.name}
                        </Typography>
                        <Label color="primary">
                            <b>{tasks?.filter(item => item.statusId === listId.id)[0]?.getLessProduct?.length}</b>
                        </Label>
                    </Box>
                </Box>
            </ListColumnWrapper>
            :
            <ListColumnWrapper
                sx={{
                    borderColor: listId.color
                }}
                style={{
                    maxHeight: '700px',
                    minHeight: '700px',
                }}
            >
                <Box
                    px={2}
                    py={2}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography color="inherit" variant="h4">
                        {listId.name}
                    </Typography>
                    <Box display="flex" alignItems="center">
                        <Tooltip arrow placement="top" style={{transform: 'rotateZ(90deg) scale(1.4)'}}
                                 title={t('Collapse')}>
                            <IconButtonEdit onClick={() => onLess(listId.id)}>
                                <UnfoldLessIcon/>
                            </IconButtonEdit>
                        </Tooltip>
                        {
                            users.editTaskStatusChecked && (
                                <Tooltip arrow placement="top" title={t('Edit')}>
                                    <IconButtonEdit onClick={() => editFunc(listId.id)}>
                                        <EditTwoToneIcon/>
                                    </IconButtonEdit>
                                </Tooltip>
                            )
                        }
                        {
                            listId.numberOfTask === 0 && (
                                <Tooltip arrow placement="top" title={t('Delete')}>
                                    <IconButtonEdit onClick={() => deleteFunc(listId.id)}>
                                        <DeleteTwoToneIcon/>
                                    </IconButtonEdit>
                                </Tooltip>
                            )
                        }
                        <Label color="primary">
                            <b>{tasks?.filter(item => item.statusId === listId.id)[0]?.getLessProduct?.length}</b>
                        </Label>
                    </Box>
                </Box>
                {tasks?.filter(item => item.statusId === listId.id)[0]?.getLessProduct?.length === 0 && (
                    <Box p={4} textAlign="center">
                        <Typography variant="subtitle2">
                            {t('Statusni o`zgartirish uchun vazifaga bosib, boshqa statusga qaratib torting')}
                        </Typography>
                    </Box>
                )}
                <Droppable droppableId={listId.id} key={listId.id}>
                    {(provided) => (
                        <Box
                            style={{
                                overflowY: 'scroll',
                                height: '90%'
                            }}
                            sx={{
                                minHeight: 260
                            }}
                            ref={provided.innerRef}
                        >
                            {tasks?.filter(item => item.statusId === listId.id)[0]?.getLessProduct?.map((task, index) => {
                                    return (
                                        <Draggable draggableId={task.id.toString()} index={index} key={task.id}>
                                            {(provided, snapshot) => (
                                                <Task
                                                    task={task}
                                                    dragging={snapshot.isDragging}
                                                    index={index}
                                                    taskInvalidAmountFunc={EnterInvalidAmount}
                                                    key={task.id}
                                                    list={listId.id}
                                                    ref={provided.innerRef}
                                                    style={{...provided.draggableProps.style}}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                />
                                            )}
                                        </Draggable>
                                    )
                                }
                            )}
                            {provided.placeholder}
                            {
                                tasks?.filter(item => item.statusId === listId.id)[0]?.totalItems > tasks?.filter(item => item.statusId === listId.id)[0]?.getLessProduct?.length ?
                                    <Box px={2} pb={2}>
                                        <ButtonAdd
                                            onClick={() => showMore(listId.id)}
                                            size="small" color="secondary" fullWidth>
                                            Show More
                                            <VisibilityIcon fontSize={'small'} style={{marginLeft: '5px'}}/>
                                        </ButtonAdd> </Box>
                                    : ' '
                            }

                        </Box>
                    )}

                </Droppable>

            </ListColumnWrapper>


    );
};

Tasks.propTypes = {
    listId: PropTypes.string.isRequired
};
export default connect((users,TaskReducer),{taskInvalidAmount})(Tasks)