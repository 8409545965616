import "../../Maxsulotlar/MahsulotlarRuyxati/maxsulotlarRoyxati.css"
import {connect} from 'react-redux'
import {useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import SavingsIcon from '@mui/icons-material/Savings';
import {
    Box,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer, TableHead,
    TableRow, Tooltip,
} from "@mui/material";
import Loading from "../../../../Loading";

import balanceReducer, {
    editBalance,
    getallbalance,
    getBalance,
    saveBalance
} from "../../../../../reducer/balanceReducer";
import users from "../../../../../reducer/users";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import ModalLoading from "../../../../ModalLoading";
import PayReducer, {getPay} from "../../../../../reducer/PayReducer";
import BalanceHistory from "./BalanceHistory";

function Balance({
                     getBalance,
                     balanceReducer,
                     editBalance,
                     getallbalance,
                     users,
                 }) {
    const [loading, setLoading] = useState(false)
    const {t} = useTranslation()
    const [mainBranchId, setMainBranchId] = useState(null)
    const [summa, setSumma] = useState(0)
    const [balance, setBalance] = useState(false)
    const [saveModal, setSaveModal] = useState(false)
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        // getBalance(mainBranchId ? mainBranchId : users.branchId)
        getallbalance(users.businessId)
    }, [])

    function balanceToggle() {
        setBalance(!balance)
    }

    const [saveId, setSaveId] = useState(null)
    const [paymethod, setpaymethod] = useState(null)

    function edit(id) {
        setSaveId(id)
        balanceToggle()
        setpaymethod(id)
        balanceReducer.trades.map(item => {
            if (item.paymentMethodId === id) {
                setSumma(item?.balanceSumma)
            }
        })
    }

    function addBalance() {
        editBalance({
            branchId: mainBranchId ? mainBranchId : users.branchId,
            summa: parseFloat(summa),
            // payMethodId: payList
            payMethodId: paymethod
        })

        setSaveModal(true)
    }

    useEffect(() => {
        if (balanceReducer.saveBoolean) {
            setBalance(0)
        }
        if (mainBranchId) {
            getBalance(mainBranchId)
        } else {
            getallbalance(users.businessId)
        }
        setSaveModal(false)
    }, [balanceReducer.current])

    return (
        <div className="col-md-12 mt-4 mb-4">
            <div className="d-flex justify-content-between align-items-center">
                <h2>{isOpen ? 'Kassa holati'  : "Kassadagi o'zgarishlar"}</h2>
                <div>
                    {
                        isOpen ? <button onClick={() => setIsOpen(false)} className={'btn btn-primary ms-2'}>
                            Kassadagi o'zgarishlar
                        </button> : <button onClick={() => setIsOpen(true)} className={'btn btn-primary'}>
                            Kassa holati
                        </button>
                    }
                    {/*<button onClick={balanceToggle} className={'btn ms-2 btn-primary'}>Qo'shish</button>*/}
                </div>
            </div>
            {
                isOpen ?
                    <div className="rowStyleMax colorback">
                        <div className="d-flex justify-content-between align-items-center">
                            {
                                users.branches ?
                                    <div className="minBox">
                                        <h5 className={'m-0'}>Filial</h5>
                                        <select value={mainBranchId} onChange={(e) => {
                                            if (e.target.value === 'all') {
                                                getallbalance(users.businessId)
                                                setMainBranchId(e.target.value)
                                            } else {
                                                setMainBranchId(e.target.value)
                                                getBalance(e.target.value)
                                            }
                                        }}
                                                className={'form-control'}>
                                            <option value="all">{t('block.barchasi')}</option>
                                            {
                                                users.branches.map((item, index) => <option
                                                    value={item.id}>{item.name}</option>)
                                            }
                                        </select>
                                    </div> : <div><h4 className={'text-center'}>NOT FOUND BRANCH</h4></div>
                            }
                        </div>
                        <>
                            {
                                // users.viewProductAdmin || users.viewProduct ?

                                loading ? (
                                    <Loading/>
                                ) : (
                                    balanceReducer.trades ?
                                        <Box pb={3}>
                                            <TableContainer>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>#</TableCell>
                                                            <TableCell>{t('Hamkorlar.tu')}</TableCell>
                                                            <TableCell align={'center'}>{t('Salary.f')}</TableCell>
                                                            <TableCell align={'center'}>{t('Hamkorlar.summasi')}</TableCell>
                                                            <TableCell align={'center'}>{t('Hamkorlar.amal')}</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            balanceReducer.trades.map((item, ix) => <TableRow key={ix}>
                                                                <TableCell>{ix + 1}</TableCell>
                                                                <TableCell>{item?.payMethodName}</TableCell>
                                                                <TableCell
                                                                    align={'center'}>{item?.branchName ? item?.branchName :
                                                                    <p style={{color: 'green'}}>BARCHA
                                                                        FILIAL</p>}</TableCell>
                                                                <TableCell align={'center'}>{item?.balanceSumma}</TableCell>
                                                                <TableCell align={'center'}>
                                                                    <Tooltip title={t('+ CASH')} arrow>
                                                                        <IconButton
                                                                            onClick={() => edit(item.paymentMethodId)}
                                                                            color="warning"
                                                                        >
                                                                            <SavingsIcon fontSize="small"/>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </TableCell>
                                                            </TableRow>)
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>


                                        </Box> : <div><h4 className={'text-center'}>{t('Darslik.mtt')}</h4></div>
                                )

                            }
                        </>
                    </div>
                    : <BalanceHistory/>

            }

            {/*EDIT BALANCE*/}
            <Modal isOpen={balance} toggle={balanceToggle}>
                <ModalHeader>
                    <h5>{t('Salary.tsh')}</h5>
                </ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-md-12">
                            <label htmlFor="">{t('trade.ks')}</label>
                            <input value={summa} onChange={(e) => setSumma(e.target.value)} type="number"
                                   className={'form-control'}/>
                        </div>
                        {/*<div className="col-md-12 mt-2">*/}
                        {/*    <label htmlFor="">Tulov usuli</label>*/}
                        {/*    <select className={'form-control'}>*/}
                        {/*        {*/}
                        {/*            PayReducer.paymethod?*/}
                        {/*            PayReducer.paymethod.map(item=> <option value={item.id}>{item.type}</option>):''*/}
                        {/*        }*/}
                        {/*    </select>*/}
                        {/*</div>*/}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button className={'btn btn-outline-primary'} onClick={addBalance}>{t('Davomat.saqlash')}</button>
                    <button className={'btn btn-outline-primary'}
                            onClick={balanceToggle}>{t('Davomat.chiqish')}</button>
                </ModalFooter>
            </Modal>

            <ModalLoading isOpen={saveModal}/>
        </div>
    )
}

export default connect((PayReducer, users, balanceReducer), {
    getPay,
    getBalance,
    getallbalance,
    saveBalance,
    editBalance,
})(Balance)
