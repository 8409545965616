import {connect} from "react-redux";
import './mahsulotlar.css'
import {active} from "../../../../reducer/functionreducer";
import {useTranslation} from "react-i18next";
import users from "../../../../reducer/users";
import {Tabs} from "antd";
import HodimlarRoyhati from "../Hodimlar/hodimlarRoyxati/HodimlarRoyhati";
import Lavozimlar from "../Hodimlar/Lavozimlar/Lavozimlar";
import MaxsulotlarRoyxati from "./MahsulotlarRuyxati/MaxsulotlarRoyxati";
import MahsulotTurlari from "./MahsulotTurlari/MahsulotTurlari";
import MahsulotImport from "./MahsulotlarImporti/MahsulotImport";
import Bolimlar from "./bolimlar/Bolimlar";
import Firmalar from "./firmalar/Firmalar";

function Maxsulotlar({changeLink, link, sidebaractive2, users}) {
    const {t} = useTranslation()


    function sidebaractive() {
        const windowWidth = window.innerWidth;
        if (windowWidth <= 1023.9) {
            sidebaractive2()
        }
    }
    const items = [
        {
            label: t('Sidebar.9'),
            key: '/product/productTable',
            children: <MaxsulotlarRoyxati/>,
        },
        {
            label: t('ProductList.3'),
            key: '2',
            children: <MahsulotTurlari/>,
        },
        {
            label: t('Sidebar.12'),
            key: '3',
            children: <MahsulotImport/>,
        },
        {
            label: t('Sidebar.13'),
            key: '4',
            children: <Bolimlar/>,
        },
        {
            label: t('Sidebar.14'),
            key: '5',
            children: <Firmalar/>,
        },
        // {
        //     label: 'Maxsulot turlari',
        //     key: '2',
        //     children: <MahsulotTurlari/>,
        // },
        // {
        //     label: 'Maxsulot turlari',
        //     key: '2',
        //     children: <MahsulotTurlari/>,
        // },
        // {
        //     label: 'Maxsulot turlari',
        //     key: '2',
        //     children: <MahsulotTurlari/>,
        // },
    ]


    const onChange = (key) => {
        console.log(key);
    };

    return (
        <div>
            <h4 className={'p-3 mainText'}>{t('Sidebar.9')}</h4>
            <div className={'px-3'}>
                <Tabs
                    onChange={onChange}
                    type="card"
                    items={items}
                />
            </div>
        </div>
    )
}

export default connect((users), {active})(Maxsulotlar)
