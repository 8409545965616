import {connect} from "react-redux";
import {useEffect} from "react";
import FoydaZararReducer from "../../reducer/FoydaZararReducer";
import users from "../../../../../../reducer/users";
import branchreducer, {getbranch} from "../../../../../../reducer/branchreducer";
import Reducer, {getFoydaBolim, getFoydaBolim2} from "./reducer/Reducer";
import {useTranslation} from "react-i18next";
import CommonTable from "../../../../../Components/CommonTable";
import {prettify} from "../../../../../../util";

function Bolim({Reducer, getFoydaBolim, users, branchId, data, dates, getFoydaBolim2,course,courseName}) {
    const {t} = useTranslation();

    const columns = [
        {
            title: 'Id',
            dataIndex: 'index',
            width: '3%',
        },
        {
            title: t('xisob.Bolim'),
            dataIndex: 'categoryName',
            key: 'categoryName',
            width: '5%',

        },
        {
            title: t('xisob.Foyda'),
            dataIndex: 'profit',
            key: 'profit',
            render: (item) => <p className={'m-0'}>{prettify(item)} so'm</p>,
            width: '10%',

        },
    ];


    useEffect(() => {
        if (data) {
            getFoydaBolim({
                branchId: branchId ? branchId : users.branchId,
                date: data

            })
        } else if (dates) {
            getFoydaBolim2({
                branchId: branchId ? branchId : users.branchId,
                startDate: dates[0],
                endDate: dates[1]
            })
        } else {
            getFoydaBolim({
                branchId: branchId ? branchId : users.branchId,
                date: data
            })
        }

    }, [])

    return (
        <>
            {
                Reducer.foydaBolim ?
                        <CommonTable size={0} page={Reducer.foydaBolim?.length}
                                     pagination={false} data={Reducer.foydaBolim}
                                     columns={columns}
                        />
                    : <div  className={'mt-2'}>
                        <h4 className={'text-center'}>{Reducer.message}</h4>
                    </div>
            }
        </>
    )
}

export default connect((FoydaZararReducer, users, branchreducer, Reducer), {
    getbranch, getFoydaBolim, getFoydaBolim2
})(Bolim)
