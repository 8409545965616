import React, {useCallback, useEffect, useState} from 'react';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import {styled} from '@mui/material/styles';
import wait from '../wait';
import 'react-quill/dist/quill.snow.css';
import {
    Grid,
    Dialog,
    DialogTitle,
    Divider,
    Alert,
    Chip,
    DialogContent,
    Box,
    ListItem,
    List,
    ListItemText,
    Typography,
    TextField,
    CircularProgress,
    Avatar,
    Autocomplete,
    Button,
    useTheme,
    FormControl,
    InputLabel,
    MenuItem,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Tooltip, IconButton, TablePagination
} from '@mui/material';
import DatePicker from '@mui/lab/DatePicker';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import {Select} from '@mui/material';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';
import users from "../../../../../reducer/users";
import {connect} from "react-redux";
import ProjectReducer, {
    deleteProject, editProject,
    getOneProject,
    getProject,
    saveProject
} from "../../../../../reducer/ProjectReducer";
import ModalLoading from "../../../../ModalLoading";
import branchreducer, {getbranch} from "../../../../../reducer/branchreducer";
import MijozGuruxReducer, {getMijozGuruxByBranch} from "../../Hamkorlar/reducer/MijozGuruxReducer";
import XodimReducer, {getXodim, getXodimByBranch} from "../../Hodimlar/reducer/XodimReducer";
import StageReducer, {getStage} from "../../../../../reducer/StageReducer";
import {Link} from "react-router-dom";
import ProjectTypeReducer, {
    deleteProjectType,
    getProjectType,
    saveProjectType, getProjectTypePage
} from "../../../../../reducer/ProjectTypeReducer";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import Stage from "./Stage";
import filesReducer, {saveFiles, clearFiles, deleteFiles} from "../../../../../reducer/filesReducer";


const BoxUploadWrapper = styled(Box)(
    ({theme}) => `
    border-radius: ${theme.general.borderRadius};
    padding: ${theme.spacing(3)};
    background: ${theme.colors.alpha.black[5]};
    border: 1px dashed ${theme.colors.alpha.black[30]};
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: ${theme.transitions.create(['border', 'background'])};

    &:hover {
      background: ${theme.colors.alpha.white[100]};
      border-color: ${theme.colors.primary.main};
    }
`
);

const AvatarWrapper = styled(Avatar)(
    ({theme}) => `
    background: ${theme.colors.primary.lighter};
    color: ${theme.colors.primary.main};
    width: ${theme.spacing(7)};
    height: ${theme.spacing(7)};
`
);

function PageHeader({
                        ProjectTypeReducer,
                        getProjectType,
                        saveProjectType,
                        editProject,
                        deleteProjectType,
                        ProjectReducer,
                        getProject,
                        saveProject,
                        getXodim,
                        XodimReducer,
                        getStage,
                        StageReducer,
                        getXodimByBranch,
                        users,
                        MijozGuruxReducer,
                        getMijozGuruxByBranch,
                        getProjectTypePage,
                        updateId,
                        open,
                        setOpen,
                        currentEdit,
                        setId, saveFiles, clearFiles, filesReducer, mainBranchId, setMainBranchId, deleteFiles
                    }) {
    const {t} = useTranslation();
    const theme = useTheme();
    const [onFiles, setOnFiles] = useState([])
    const [fileIds, setFileIds] = useState([])
    const [file, setFile] = useState(null)

    useEffect(() => {
        if (filesReducer.saveBoolean) {
            let a = [...onFiles, file]
            fileIds.push(filesReducer.files)
            let ids = [...fileIds]
            setFileIds(ids)
            setOnFiles(a)
            clearFiles()
        }
    }, [filesReducer.current])

    useEffect(() => {
        formik.branchId = mainBranchId ? mainBranchId : users.branchId
        let a = {...formik}
        setFormik(a)
    }, [mainBranchId])

    const [startDate, setStartDate] = useState('');
    const [deadline, setDeadLine] = useState('');
    const [openTypeProjectToggle, setOpenTypeProjectToggle] = useState(false)
    const [projectName, setProjectName] = useState('')
    const [userIds, setUserIds] = useState([])
    const [formik, setFormik] = useState({
        name: '',
        branchId: null,
        budget: 0,
        customerId: '',
        description: '',
        projectTypeId: '',
        stageId: '',
    })

    const callback = useCallback(currentEdit, [currentEdit])

    useEffect(() => {
        ProjectReducer.projectPage?.content.map(item => {
            if (item.id === updateId) {
                formik.name = item.name
                formik.branchId = item.branch.id
                formik.description = item.description
                formik.budget = item.budget
                formik.customerId = item.customer ? item.customer.id : ''
                formik.projectTypeId = item.projectType ? item.projectType?.id : ''
                let stagelist = []
                item?.stageList.map(item => {
                    stagelist.push(item.name)
                })
                setStartDate(item.startDate)
                setDeadLine(item.deadline)
                setUserIds(item.users)
                setStage(stagelist)
                setOnFiles(item.fileDataList ? item.fileDataList : [])
                let Ids = []
                if (item.fileDataList) {
                    item.fileDataList.map(item => {
                        Ids.push(item.id)
                    })
                }
                setFileIds(Ids)
                let a = {...formik}
                setFormik(a)
            }
        })
    }, [callback])

    function changeUser(value) {
        setUserIds(value)
    }

    const handleCreateProjectOpen = () => {
        setOpen(true);
        setOnFiles([])
        setFileIds([])
        setStage([])
        setFile('')
    };

    const handleCreateProjectClose = () => {
        setOpen(false);
        setId(null)
        setFormik({
                fileDateList: [],
                name: '',
                budget: 0,
                customerId: '',
                branchId: mainBranchId,
                description: '',
                projectTypeId: '',
                stageId: '',
            }
        )
        setStartDate('')
        setDeadLine('')
        setUserIds([])
        setOnFiles([])
        setFileIds([])
        setStage([])
        setFile('')
    };

    const handleCreateProjectSuccess = () => {
        setStartDate('')
        setDeadLine('')
        setUserIds([])
        setOnFiles([])
        setFileIds([])
        setFile('')
        setOpen(false);
    };

    const [stages, setStage] = useState([])

    function addStages() {
        stages.push([])
        let a = [...stages]
        setStage(a)
    }

    function handleChangeInput(onChangevalue, i) {
        const inputdate = [...stages]
        inputdate[i] = onChangevalue.target.value
        setStage(inputdate)
    }

    function removeDeleteInput() {
        let set = stages.length
        stages.splice(set - 1, 1)
        let a = [...stages]
        setStage(a)
    }

    useEffect(() => {
        if (ProjectReducer.saveBoolean) {
            handleCreateProjectSuccess()
        }
        if (users.getProjectChecked){
            getProject({
                branchId: mainBranchId ? mainBranchId : users.branchId,
                params: {
                    page: 0,
                    size: 10,
                    userId: null
                }
            })
        } else if(users.getOwnProjectChecked) {
            getProject({
                branchId: users.branchId,
                params: {
                    userId: users.id,
                    page: 0,
                    size: 10
                }
            })
        }
    }, [ProjectReducer.current, mainBranchId])

    function deleteProjectFunc(id) {
        deleteProjectType(id)
    }

    const [saveModal, setSaveModal] = useState(false)
    const [openToggleStage, setOpenToggleStage] = useState(false)

    function openToggleStageFunction() {
        setOpenToggleStage(!openToggleStage)
    }

    function openTypeToggle() {
        setOpenTypeProjectToggle(!openTypeProjectToggle)
    }

    useEffect(() => {
        if (ProjectTypeReducer.saveBoolean) {
            setProjectName('')
            setOpenTypeProjectToggle(false)
        }
        getProjectType(mainBranchId ? mainBranchId : users.branchId)
        getProjectTypePage({
            branchId: mainBranchId ? mainBranchId : users.branchId,
            params: {
                page: projectTypePage,
                limit: projectTypeLimit
            }
        })
        setSaveModal(false)
    }, [ProjectTypeReducer.current, mainBranchId])

    useEffect(() => {
        getProjectType(mainBranchId ? mainBranchId : users.branchId)
        getMijozGuruxByBranch(mainBranchId ? mainBranchId : users.branchId)
        getXodimByBranch(mainBranchId ? mainBranchId : users.branchId)
    }, [mainBranchId])

    const [projectTypePage, setProjectTypePage] = useState(0)
    const [projectTypeLimit, setProjectTypeLimit] = useState(3)

    const handleProjectTypePageChange = (_event, newPage) => {
        setProjectTypePage(newPage);
        getProjectTypePage({
            branchId: mainBranchId ? mainBranchId : users.branchId,
            params: {
                page: newPage,
                size: projectTypeLimit
            }
        })
    };

    const handleProjectTypeLimitChange = (event) => {
        setProjectTypeLimit(parseInt(event.target.value));
        getProjectTypePage({
            branchId: mainBranchId ? mainBranchId : users.branchId,
            params: {
                page: projectTypePage,
                size: parseInt(event.target.value)
            }
        })
    };
    useEffect(() => {
        getStage(mainBranchId ? mainBranchId : users.branchId)
    }, [StageReducer.current, mainBranchId])

    return (
        <>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h3" component="h3" gutterBottom>
                        {t('Sidebar1.loyixalar')}
                    </Typography>
                    <Typography variant="subtitle2">
                        {t('newChanges.project_desc')}
                    </Typography>
                </Grid>
                {
                    users.addProjectChecked ?
                        <Grid item>
                            <Button
                                sx={{
                                    mt: {xs: 2, sm: 0}
                                }}
                                onClick={handleCreateProjectOpen}
                                variant="contained"
                                startIcon={<AddTwoToneIcon fontSize="small"/>}
                            >
                                {t('newChanges.project_add')}
                            </Button>
                        </Grid>
                        : ''
                }
            </Grid>
            <Dialog
                fullWidth
                maxWidth="md"
                open={open}
                onClose={handleCreateProjectClose}
            >
                <DialogTitle
                    sx={{
                        p: 3
                    }}
                >
                    <Typography variant="h4" gutterBottom>
                        {updateId ? 'Loyihani taxrirlash' : 'Yangi loyiha qo`shish'}
                    </Typography>
                    <Typography variant="subtitle2">
                        {t('Yangi loyiha haqidagi malumotlarni to`ldiring')}
                    </Typography>
                </DialogTitle>
                <Formik
                    initialValues={
                        formik
                    }

                    validationSchema={Yup.object().shape({
                        name: Yup.string()
                            .max(255)
                            .required(t('Loyiha nomini kiriting')),
                        description: Yup.string()
                            .max(255)
                            .required(t('Eslatmani kiriting')),
                        customerId: Yup.string()
                            .max(255)
                            .required(t('Mijozni tanglang')),
                        projectTypeId: Yup.string()
                            .max(255)
                            .required(t('Proyekt turini tanglang'))
                    })}
                    onSubmit={async (
                        _values,
                        {resetForm, setErrors, setStatus, setSubmitting}
                    ) => {
                        try {
                            await wait(1000);
                            resetForm();
                            setStatus({success: true});
                            setSubmitting(false);
                            let a = []
                            userIds.map(item => {
                                a.push(item.id)
                            })

                            if (updateId) {
                                editProject({
                                    ..._values, startDate, deadline, id: updateId, fileDateList: fileIds,
                                    userList: a, branchId: mainBranchId ? mainBranchId : users.branchId,
                                    stages: stages
                                })
                            } else {
                                saveProject({
                                    ..._values, startDate, deadline, fileDateList: fileIds,
                                    userList: a, branchId: mainBranchId ? mainBranchId : users.branchId,
                                    stages: stages

                                })
                            }
                        } catch (err) {
                            console.error(err);
                            setStatus({success: false});
                            setErrors({submit: err.message});
                            setSubmitting(false);
                        }
                    }}
                >
                    {({
                          errors,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          touched,
                          values
                      }) =>
                        (
                            <form onSubmit={handleSubmit}>
                                <DialogContent
                                    dividers
                                    sx={{
                                        p: 3
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid
                                            sx={{
                                                mb: `${theme.spacing(3)}`
                                            }}
                                            item
                                            xs={12}
                                            sm={8}
                                            md={6}
                                            lg={4}
                                        ><TextField
                                            label={'Loyiha nomi'}
                                            error={Boolean(touched.name && errors.name)}
                                            fullWidth
                                            helperText={touched.name && errors.name}
                                            name="name"
                                            placeholder={t('Nomini yozing...')}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.name}
                                            variant="outlined"
                                        />
                                        </Grid>

                                        <Grid
                                            sx={{
                                                mb: `${theme.spacing(3)}`
                                            }}
                                            item
                                            xs={12}
                                            sm={8}
                                            md={6}
                                            lg={4}
                                        >
                                            <TextField
                                                label={'Qisqa eslatma'}
                                                error={Boolean(touched.description && errors.description)}
                                                fullWidth
                                                helperText={touched.description && errors.description}
                                                name="description"
                                                placeholder={t('Qisqa eslatma')}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.description}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        {
                                            users.branches.length > 1 ?
                                                <Grid
                                                    sx={{
                                                        mb: `${theme.spacing(3)}`
                                                    }}
                                                    item
                                                    xs={12}
                                                    sm={8}
                                                    md={6}
                                                    lg={4}
                                                >

                                                    {
                                                        users.branches ?
                                                            <FormControl fullWidth>
                                                                <InputLabel
                                                                    id="demo-simple-select-label">Tanlash</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    value={values.branchId}
                                                                    label="branchId"
                                                                    onChange={(e) => {
                                                                        handleChange(e)
                                                                        setMainBranchId(e.target.value)

                                                                    }}
                                                                    name={'branchId'}
                                                                >

                                                                    {
                                                                        users?.branches.map((item) =>
                                                                            <MenuItem
                                                                                value={item.id}>{item.name}</MenuItem>
                                                                        )}
                                                                </Select>

                                                            </FormControl> : <Link to={'/headerthird/bazalarSetting'}>
                                                                <FormControl fullWidth>
                                                                    <InputLabel id="demo-simple-select-label">Baza
                                                                        Qo'shish</InputLabel>
                                                                    <select className={'form-control'} name=""
                                                                            id="">Qoshish</select>
                                                                </FormControl>
                                                            </Link>
                                                    }
                                                </Grid> : ''
                                        }

                                        {
                                            MijozGuruxReducer.mijozgurux ?
                                                <Grid
                                                    sx={{
                                                        mb: `${theme.spacing(2)}`
                                                    }}
                                                    item
                                                    xs={12}
                                                    sm={8}
                                                    md={6}
                                                    lg={4}
                                                    display={'flex'}
                                                >
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">
                                                            Loyiha egasi
                                                        </InputLabel>
                                                        <Select
                                                            error={Boolean(touched.customerId && errors.customerId)}
                                                            helperText={touched.customerId && errors.customerId}
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={values.customerId}
                                                            label="Mijoz"
                                                            onChange={handleChange}
                                                            name={'customerId'}
                                                        >
                                                            {
                                                                MijozGuruxReducer.mijozgurux.map((item) =>
                                                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                                                )
                                                            }
                                                        </Select>
                                                        <Typography fontWeight={'700'} color={'error'} sx={{margin:'3px 7px'}}>
                                                            {
                                                                errors.customerId && errors.customerId
                                                            }
                                                        </Typography>
                                                    </FormControl>
                                                    <Link to={'/headerthird/mijozlarGuruhi'}>
                                                        <Button variant="contained" sx={{height: 'max-content'}}>
                                                            <AddTwoToneIcon fontSize={'medium'}/>
                                                        </Button>
                                                    </Link>
                                                </Grid>
                                                :
                                                <Grid
                                                    sx={{
                                                        mb: `${theme.spacing(3)}`
                                                    }}
                                                    item
                                                    xs={12}
                                                    sm={8}
                                                    md={6}
                                                    lg={4}
                                                >
                                                    <Link to={'/headerthird/mijozlarGuruhi'}>
                                                        <FormControl fullWidth>
                                                            <Button variant="contained">
                                                                <Typography> Mijoz
                                                                    Qo'shish</Typography>
                                                                <AddTwoToneIcon fontSize={'medium'}/>
                                                            </Button>
                                                        </FormControl>
                                                    </Link>
                                                </Grid>
                                        }
                                        <Grid
                                            sx={{
                                                mb: `${theme.spacing(3)}`
                                            }}
                                            item
                                            xs={12}
                                            md={6}
                                            lg={4}
                                        >
                                            <DatePicker
                                                label={'Boshlash sanasi'}
                                                name={'Boshlash sana'}
                                                value={startDate}
                                                onChange={
                                                    (newValue) => setStartDate(newValue)
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        fullWidth
                                                        placeholder={t('Sanani belgilang...')}
                                                        {...params}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            sx={{
                                                mb: `${theme.spacing(3)}`
                                            }}
                                            item
                                            xs={12}
                                            sm={8}
                                            md={6}
                                            lg={4}
                                        >
                                            <TextField
                                                label={'Budget'}
                                                error={Boolean(touched.budget && errors.budget)}
                                                fullWidth
                                                helperText={touched.budget && errors.budget}
                                                name="budget"
                                                placeholder={t('Budjet')}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.budget}
                                                variant="outlined"
                                            />
                                        </Grid>


                                        {
                                            ProjectTypeReducer.projectType ?
                                                <Grid
                                                      sx={{
                                                          mb: `${theme.spacing(3)}`
                                                      }}
                                                      item
                                                      xs={12}
                                                      sm={8}
                                                      md={6}
                                                      lg={4}
                                                      className={'d-flex'}
                                                >

                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Project
                                                            Type</InputLabel>
                                                        <Select
                                                            error={Boolean(touched.projectTypeId && errors.projectTypeId)}
                                                            helperText={touched.projectTypeId && errors.projectTypeId}
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={values.projectTypeId}
                                                            label="ProjectType"
                                                            onChange={handleChange}
                                                            name={'projectTypeId'}
                                                        >

                                                            {
                                                                ProjectTypeReducer.projectType.map((item) =>
                                                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                                                )
                                                            }
                                                        </Select>
                                                        <Typography fontWeight={'700'} color={'error'} sx={{margin:'3px 7px'}}>
                                                            {
                                                                errors.projectTypeId && errors.projectTypeId
                                                            }
                                                        </Typography>
                                                    </FormControl>
                                                    <Button variant="contained" onClick={openTypeToggle}>
                                                        <AddTwoToneIcon fontSize={'medium'}/>
                                                    </Button>
                                                </Grid>
                                                :
                                                <Grid
                                                    className={'d-flex'}
                                                    sx={{
                                                        mb: `${theme.spacing(3)}`
                                                    }}
                                                    item
                                                    xs={12}
                                                    sm={8}
                                                    md={6}
                                                    lg={4}
                                                >
                                                    <FormControl fullWidth>
                                                        <Button variant="contained" onClick={openTypeToggle}>
                                                            <Typography> Loyiha turini qo'shish</Typography>
                                                            <AddTwoToneIcon fontSize={'medium'}/>
                                                        </Button>
                                                    </FormControl>
                                                </Grid>

                                        }


                                        <Grid
                                            sx={{
                                                mb: `${theme.spacing(3)}`
                                            }}
                                            item
                                            xs={12}
                                            sm={8}
                                            md={6}
                                            lg={4}
                                        >
                                            <DatePicker
                                                label={'Tugash sanasi'}
                                                value={deadline}
                                                name={'deadline'}
                                                onChange={(newValue) => setDeadLine(newValue)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        fullWidth
                                                        placeholder={t('Deadline')}
                                                        {...params}
                                                    />
                                                )}
                                            />
                                        </Grid>


                                        <Grid
                                            sx={{
                                                mb: `${theme.spacing(3)}`
                                            }}
                                            item
                                            className={'d-flex'}
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                        >

                                            <Button variant="contained" onClick={addStages}>
                                                <Typography>Bosqich qo`shish</Typography>
                                                <AddTwoToneIcon fontSize={'medium'}/>
                                            </Button>
                                            {
                                                stages.length > 1 ?
                                                    <Button variant="contained" onClick={removeDeleteInput}>
                                                        <Typography>Bosqichni o`chirish</Typography>
                                                        <DeleteTwoToneIcon fontSize={'medium'}/>
                                                    </Button> : ''
                                            }

                                        </Grid>

                                        {

                                            stages.length > 0 ?


                                                stages.map((item, index) => {
                                                        return (
                                                            <Grid
                                                                item
                                                                sx={{
                                                                    mb: `${theme.spacing(3)}`,
                                                                }}
                                                                xs={12}
                                                                sm={12}
                                                                md={6}
                                                                lg={4}
                                                                display={'flex'}
                                                                flexWrap={'wrap'}
                                                            >
                                                                <TextField
                                                                    label={`Stage ${index + 1} Name`}
                                                                    fullWidth
                                                                    name="stage"
                                                                    value={item}
                                                                    placeholder={`${index + 1} - bosqich nomi`}
                                                                    onChange={(e) => handleChangeInput(e, index)}
                                                                    variant="outlined"
                                                                />
                                                            </Grid>
                                                        )
                                                    }
                                                )


                                                : ''

                                        }


                                        <Grid
                                            sx={{
                                                mb: `${theme.spacing(3)}`
                                            }}
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                        >
                                            {XodimReducer.xodimlar ?
                                                <Autocomplete
                                                    multiple
                                                    sx={{m: 0}}
                                                    limitTags={2}
                                                    name={'userList'}
                                                    value={userIds}
                                                    onChange={(event, value) =>
                                                        changeUser(value)
                                                    }
                                                    options={XodimReducer.xodimlar}
                                                    renderOption={(props, option) => (
                                                        <li {...props}>
                                                            <Avatar
                                                                sx={{
                                                                    mr: 1
                                                                }}
                                                                src={option.avatar}
                                                            />
                                                            {option.firstName}
                                                        </li>
                                                    )}
                                                    getOptionLabel={(option) => option.firstName}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            label={'Ishtirokchilar'}
                                                            {...params}
                                                            variant="outlined"
                                                            fullWidth
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            placeholder={t('Hodimlarni belgilang...')}
                                                        />
                                                    )}
                                                    renderTags={(members, getTagProps) =>
                                                        members.map((ev, index) => (
                                                            <Chip
                                                                key={index}
                                                                label={ev.firstName}
                                                                {...getTagProps({index})}
                                                                // avatar={<Avatar src={ev.avatar} />}
                                                            />
                                                        ))
                                                    }

                                                /> :
                                                <Link to={'/headerthird/hodimlarruyxati'}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Xodim
                                                            Qo'shish</InputLabel>
                                                        <select className={'form-control'} name=""
                                                                id="">Qoshish</select>
                                                    </FormControl>
                                                </Link>
                                            }
                                        </Grid>

                                        <Grid
                                            sx={{
                                                mb: `${theme.spacing(3)}`
                                            }}
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                        >
                                            <input type="file" id={'projectFiles'} className={'d-none'}
                                                   onChange={(e) => {
                                                       let form = new FormData()
                                                       form.append('file', e.target.files[0])
                                                       setFile(e.target.files[0])
                                                       saveFiles(form)
                                                   }
                                                   }/>
                                            <label style={{width: '100%'}} htmlFor="projectFiles">
                                                <BoxUploadWrapper sx={{width: '100%'}}>
                                                    <AvatarWrapper variant="rounded">
                                                        <CloudUploadTwoToneIcon/>
                                                    </AvatarWrapper>
                                                    <Typography
                                                        sx={{
                                                            mt: 2
                                                        }}
                                                    >
                                                        {t('Loyiha uchun fayllar bo`lsa qo`shing')}
                                                    </Typography>
                                                </BoxUploadWrapper>
                                            </label>
                                            {onFiles.length > 0 && (
                                                <>
                                                    <Alert
                                                        sx={{
                                                            py: 0,
                                                            mt: 2
                                                        }}
                                                        severity="success"
                                                    >
                                                        {t('You have uploaded')} <b>{onFiles.length}</b>{' '}
                                                        {t('files')}!
                                                    </Alert>
                                                    <Divider
                                                        sx={{
                                                            mt: 2
                                                        }}
                                                    />
                                                    <List disablePadding component="div">
                                                        {
                                                            onFiles.map((file, index) => (
                                                                <ListItem disableGutters component="div" key={index}>
                                                                    <ListItemText
                                                                        primary={file?.name ? file?.name : file?.fileName}/>
                                                                    <b>{file?.size} bytes</b>
                                                                    <Divider/>
                                                                    <CancelPresentationIcon onClick={() => {
                                                                        let a = onFiles.filter((item, ind) => ind !== index)
                                                                        let c = fileIds.filter((item, ind) => ind === index)
                                                                        let b = fileIds.filter((item, ind) => ind !== index)
                                                                        deleteFiles(c[0])
                                                                        setOnFiles(a)
                                                                        setFileIds(b)
                                                                    }} sx={{marginLeft: '10px'}} color={'error'}/>
                                                                </ListItem>
                                                            ))
                                                        }
                                                    </List>
                                                </>
                                            )}
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            textAlign={{sm: 'right'}}
                                        />
                                        <Grid
                                            sx={{
                                                mb: `${theme.spacing(2)}`
                                            }}
                                            item
                                            xs={12}
                                            sm={12}
                                            md={4}
                                            lg={4}
                                        >
                                            <Button
                                                sx={{
                                                    mr: 2
                                                }}
                                                type="submit"
                                                startIcon={
                                                    isSubmitting ? <CircularProgress size="1rem"/> : null
                                                }
                                                disabled={Boolean(errors.submit) || isSubmitting}
                                                variant="contained"
                                                size="large"
                                            >
                                                {t('Qo`shish')}
                                            </Button>
                                            <Button
                                                color="secondary"
                                                size="large"
                                                variant="outlined"
                                                onClick={handleCreateProjectClose}
                                            >
                                                {t('Qaytish')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                            </form>
                        )}
                </Formik>
            </Dialog>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={openTypeProjectToggle}
                onClose={openTypeToggle}
            >
                <DialogTitle
                    sx={{
                        p: 3
                    }}
                >
                    <Typography variant="h4" gutterBottom>
                        {t('Loyiha turi')}
                    </Typography>
                </DialogTitle>
                <Box

                >
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        #
                                    </TableCell>
                                    <TableCell>{t('Name')}</TableCell>
                                    <TableCell align="center">{t('Actions')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    ProjectTypeReducer.projectTypePage ?
                                        ProjectTypeReducer.projectTypePage?.content.map((item, index) => <TableRow
                                            key={index}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{item.name}</TableCell>
                                            <TableCell align="center">
                                                <Typography noWrap>
                                                    <Tooltip title={t('Delete')} arrow>
                                                        <IconButton
                                                            onClick={() => deleteProjectFunc(item.id)}
                                                            color="primary"
                                                        >
                                                            <DeleteTwoToneIcon fontSize="small"/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </Typography>
                                            </TableCell>
                                        </TableRow>) : <h4>{t('Darslik.mtt')}</h4>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Box p={2}>
                        <TablePagination
                            component="div"
                            count={ProjectTypeReducer.projectTypePage?.totalElements}
                            onPageChange={handleProjectTypePageChange}
                            onRowsPerPageChange={handleProjectTypeLimitChange}
                            page={projectTypePage}
                            rowsPerPage={projectTypeLimit}
                            rowsPerPageOptions={[3, 5, 8]}
                        />
                    </Box>
                </Box>


                <Formik
                    initialValues={
                        formik
                    }

                    validationSchema={Yup.object().shape({
                        name: Yup.string()
                            .max(255)
                            .required(t('Bu joyni yozish majburiy'))
                    })}
                    onSubmit={async (
                        _values,
                        {resetForm, setErrors, setStatus, setSubmitting}
                    ) => {
                        try {
                            await wait(1000);
                            resetForm();
                            setStatus({success: true});
                            setSubmitting(false);
                            saveProjectType({
                                branchId: mainBranchId ? mainBranchId : users.branchId,
                                name: _values.name
                            })
                            setSaveModal(true)
                        } catch (err) {
                            console.error(err);
                            setStatus({success: false});
                            setErrors({submit: err.message});
                            setSubmitting(false);
                        }
                    }}
                >
                    {({
                          errors,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          touched,
                          values
                      }) =>
                        (
                            <form onSubmit={handleSubmit}>
                                <DialogContent
                                    dividers
                                    sx={{
                                        p: 3
                                    }}
                                >
                                    <Grid container spacing={0}>
                                        <Grid
                                            sx={{
                                                mb: `${theme.spacing(3)}`
                                            }}
                                            item
                                            xs={12}
                                            sm={8}
                                            md={9}
                                        >
                                            <TextField
                                                label={'New Project Type'}
                                                error={Boolean(touched.name && errors.name)}
                                                fullWidth
                                                helperText={touched.name && errors.name}
                                                name="name"
                                                placeholder={t('Project Type title here...')}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.name}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid
                                            sx={{
                                                mb: `${theme.spacing(3)}`
                                            }}
                                            item
                                            xs={12}
                                            sm={8}
                                            md={9}
                                        >
                                            <Button
                                                sx={{
                                                    mr: 2
                                                }}
                                                type="submit"
                                                startIcon={
                                                    isSubmitting ? <CircularProgress size="1rem"/> : null
                                                }
                                                disabled={Boolean(errors.submit) || isSubmitting}
                                                variant="contained"
                                                size="large"
                                            >
                                                {t('Create project type')}
                                            </Button>
                                            <Button
                                                color="secondary"
                                                size="large"
                                                variant="outlined"
                                                onClick={openTypeToggle}
                                            >
                                                {t('Cancel')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                            </form>
                        )}
                </Formik>
            </Dialog>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={openToggleStage}
                onClose={openToggleStageFunction}
            >
                <Stage onClose={openToggleStageFunction} mainBranchId={mainBranchId}/>
            </Dialog>
            <ModalLoading isOpen={saveModal}/>
        </>
    );
}

export default connect((users, ProjectReducer, ProjectTypeReducer, branchreducer, MijozGuruxReducer, XodimReducer, StageReducer, filesReducer), {
    getStage,
    getXodim,
    getProjectType,
    saveProjectType,
    deleteProjectType,
    editProject,
    getMijozGuruxByBranch,
    getXodimByBranch,
    getbranch,
    getProject,
    getOneProject,
    saveProject,
    getProjectTypePage,
    deleteProject,
    saveFiles,
    clearFiles, deleteFiles
})(PageHeader);
