import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../api";
import {toast} from "react-toastify";

export const slice = createSlice({
    name: 'shablon',
    initialState: {
        shablon: null,
        shablonOne: null,
        current: false,
        message: '',
        saveBoolean: false,
        deleteBoolean: false
    },
    reducers: {
        get: (state, action) => {
            if (action.payload.success) {
                state.shablon = action.payload.object
            } else {
                state.shablon = null
                state.message = action.payload.message
            }
            state.saveBoolean = false
            state.deleteBoolean = false
        },
        getOne: (state, action) => {
            if (action.payload.success) {
                state.current = !state.current
                state.shablonOne = action.payload.object
            } else {
                state.current = !state.current
                state.shablonOne = null
                state.message = action.payload.message
            }
        },
        saveFrom:(state,action)=>{
            if (action.payload.success){
                toast.success('SAVED')
                state.saveBoolean = true
            }  else {
                toast.success(action.payload.message)
            }
            state.current=!state.current
        },
        editForm:(state,action)=>{
            if (action.payload.success){
                toast.success('Tahrirlandi')
                state.saveBoolean = true
            }  else {
                toast.success(action.payload.message)
            }
            state.current=!state.current
        },
        deleteAll: (state, action) => {
            if (action.payload.success) {
                toast.success('DELETED')
                state.deleteBoolean = true
            } else {
                toast.error(action.payload.message)
            }
            state.current = !state.current
        }
    }
})

export const getShablon = (data) => apiCall({
    url: '/shablon/getAll/'+data,
    method: 'get',
    onSuccess: slice.actions.get.type,
    onFail: slice.actions.get.type
})

export const getShablonId = (id) => apiCall({
    url: '/shablon/' + id,
    method: 'get',
    onSuccess: slice.actions.getOne.type,
    onFail: slice.actions.getOne.type
})
export const saveShablon = (data) => apiCall({
    url: '/shablon',
    method: 'post',
    data,
    onSuccess: slice.actions.saveFrom.type,
    onFail: slice.actions.saveFrom.type
})
export const editShablon = (data) => apiCall({
    url: '/shablon/'+data.id,
    method: 'put',
    data,
    onSuccess: slice.actions.editForm.type,
    onFail: slice.actions.editForm.type
})

export const deleteShablon = (id) => apiCall({
    url: '/shablon/'+id,
    method: 'delete',
    onSuccess: slice.actions.deleteAll.type,
    onFail: slice.actions.deleteAll.type
})

export default slice.reducer