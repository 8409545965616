import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../../../../../api";
import {toast} from "react-toastify";

const slice = createSlice({
    name: 'mijozgurux',
    initialState: {
        qarz: [],
        current:0,
        message:''
    },
    reducers: {
        getFrom: (state, action) => {
            if (action.payload.success){
                state.qarz = action.payload.object
            }else {
                state.message = action.payload.message
            }
            state.current=!state.current
        },
        savefrom: (state,action) => {

        },
    }
});

export const qarzuzishCustomer=(data)=>apiCall({
    url: '/customer/repayment/'+data,
    method:'post',
    data,
    onSuccess: slice.actions.savefrom.type,
    onFail: slice.actions.savefrom.type
});

export default slice.reducer
