import React from 'react';
import './production.css'
import {useEffect, useState} from 'react'
import {NavLink, useHistory, useLocation} from 'react-router-dom'
import {connect} from "react-redux";
import {active} from "../../../../reducer/functionreducer";
import {useTranslation} from "react-i18next";
import users from "../../../../reducer/users";
import {Tabs} from "antd";

import Content from "./Content/Content";
import TableProduction from "./TableProduction/TableProduction";
import ProductionCost from "./ProductionCost/ProductionCost";

function Production({changeLink, link, sidebaractive2,users}) {

    const history = useHistory()
    const location = useLocation()
    function sidebaractive() {
        const windowWidth = window.innerWidth;
        if (windowWidth <= 1023.9) {
            sidebaractive2()
        }
    }

    const {t} = useTranslation()

    const items = [
        {
            label: t('Sidebar1.tarkib'),
            key: '/main/production/content',
            children: <Content/>,
        },
        {
            label: t('Sidebar1.ishlabchiqarish'),
            key: '/main/production/table',
            children: <TableProduction/>,
        },
        {
            label: t('BOSH.xajar'),
            key: '/main/production/outlay',
            children: <ProductionCost/>,
        },
    ]

    useEffect(() => {
        !items.some(item=>item.key === location.pathname) && history.push('/main/production/content')
    }, []);
    const onChange = (key) => {
        history.push(key)
    };

    return (
        <div>
            <h4 className={'p-3 mainText'}>{t('Sidebar1.ishlabchiqarish')}</h4>
            <div className={'px-3'}>
                <Tabs
                    activeKey={location.pathname}
                    onChange={onChange}
                    type="card"
                    items={items}
                />
            </div>

            {/*<ul>*/}
            {/*    {*/}
            {/*        users.addContent || users.viewContent || users.deleteContent || users.editContent?*/}
            {/*            <li onClick={sidebaractive}><NavLink*/}
            {/*                className={isActive => isActive ? 'active-enter-link li-text' : 'li-text'}*/}
            {/*                to={'/headerthird/tarkib'}>{t('Sidebar1.tarkib')}</NavLink></li>:''*/}
            {/*    }*/}
            {/*    {*/}
            {/*        users.addProduction || users.viewProduction ?*/}
            {/*            <li onClick={sidebaractive}><NavLink*/}
            {/*                className={isActive => isActive ? 'active-enter-link  li-text' : 'li-text'}*/}
            {/*                to={'/headerthird/ishlabchiqarish'}>{t('Sidebar1.ishlabchiqarish')}</NavLink></li>:''*/}
            {/*    }*/}
            {/*    {*/}
            {/*        users.addProduction || users.viewProduction ?*/}
            {/*            <li onClick={sidebaractive}><NavLink*/}
            {/*                className={isActive => isActive ? 'active-enter-link  li-text' : 'li-text'}*/}
            {/*                to={'/headerthird/productionCost'}>Xarajatlar</NavLink></li>:''*/}
            {/*    }*/}
            {/*    /!*<li onClick={sidebaractive}><NavLink*!/*/}
            {/*    /!*    className={isActive => isActive ? 'active-enter-link li-text' : 'li-text'}*!/*/}
            {/*    /!*    to={'/headerthird/mijozGuruh'}>{t('Sidebar.8')}</NavLink></li>*!/*/}
            {/*    /!*<li><Link className={'hamkorlar'} to={'/malumotTiklash'}>Malumotlarni tiklash</Link></li>*!/*/}
            {/*</ul>*/}


        </div>
    )
}

export default connect((users), {active})(Production)
