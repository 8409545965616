import {connect} from "react-redux";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Form, Input, InputNumber, Modal, Popconfirm} from "antd";

import CommonTable from "../../../../Components/CommonTable";
import {DeleteButton, EditButton, QushishButton} from "../../../../Components/Buttons";
import {setLocalStorage, tablePageSize} from "../../../../../util";
import users from "../../../../../reducer/users";
import MijozlarGuruhReducer, {
    deleteMijozLarGuruh,
    editMijozLarGurux,
    getMijozLarGuruh,
    saveMijozLarGurux
} from "../reducer/MijozlarGuruhReducer";

import './MijozGuruhlari.css'

function MijozGuruhlariii({
                              getMijozLarGuruh,
                              users,
                              MijozlarGuruhReducer,
                              deleteMijozLarGuruh,
                              editMijozLarGurux,
                              saveMijozLarGurux
                          }) {
    const {t} = useTranslation()
    const [form] = Form.useForm();
    const [pageData, setPageData] = useState({
        page: 0,
        pageSize: tablePageSize ? tablePageSize : 10,
        loading: false,
        openModal: false,
        editId: null,
        searchValue: ""
    });
    const columns = [
        {
            key: "id",
            title: 'Id',
            dataIndex: 'index',
            width: '3%',
            fixed: 'left',
        },
        {
            title: t('CustomGroup.6'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('CustomGroup.5'),
            dataIndex: 'percent',
            key: 'percent',
        },
        {
            title: t('Hamkorlar.amal'),
            dataIndex: 'count',
            key: 'count',
            render: (item, record) => <div className="d-flex align-items-center gap-2">
                {
                    users.editBrand ? <EditButton onClick={() => {
                          setPageData( (prev) => ({...prev, editId: record.id, openModal: true}))
                          form.setFieldValue("name", record.name)
                          form.setFieldValue("percent", record.percent)
                      }} buttonText={t('Buttons.1')} />
                      : null
                }
                {
                    users.deleteBrand ?
                      <Popconfirm
                        title={`${t('Buttons.12')}?`}
                        onConfirm={() => deleteMijozLarGuruh(record.id)}
                        okText={t('Buttons.3')}
                        cancelText={t('Buttons.7')}
                      >
                          <DeleteButton buttonText={t('Buttons.3')} />
                      </Popconfirm>
                      : null
                }
            </div>,
        },
    ];

    useEffect(() => {
        getMijozLarGuruh(users.businessId)
    }, [MijozlarGuruhReducer.current])

    useEffect(()=>{
        setPageData( (prev) => ({...prev, loading: false}))
    },[MijozlarGuruhReducer.getBoolean])

    useEffect(()=>{
        setPageData( (prev) => ({...prev, loading: true}))
    },[])

    const newChange = (pageNumber, page) => {
        setPageData((prev) => ({ ...prev, pageSize: page, page: pageNumber - 1 }));
        setLocalStorage("pageSize", page)
    };

    function searchCustomerGroup(e) {
        setPageData( (prev) => ({...prev, searchValue: e?.target?.value}))
    }

    const onCreate = (values) => {
        pageData.editId ? editMijozLarGurux({
            ...values,
            businessId: users.businessId,
            id: pageData.editId
        }): saveMijozLarGurux({
            ...values,
            businessId: users.businessId,
        })
        setPageData( (prev) => ({...prev, loading: true, editId: null, page: 0, searchValue: "", openModal: false}))
    };

    return (
        <div className="col-md-12 mt-2 pt-2 pb-4">
            <div className="rowStyleMIG">
                <div className="qoshishMIG mb-3">
                    <h3>{t('CustomGroup.2')}</h3>
                    {
                        users.addCustomerGroup ?
                          <QushishButton onClick={() => setPageData((prev) => ({...prev, openModal: true}))}
                                         buttonText={t('Buttons.2')}/> : null
                    }
                </div>
                <div className="mb-3">
                    <Input allowClear rootClassName="w-full searchCustomerGroup" onChange={searchCustomerGroup}
                           placeholder={t("num.37")}/>
                </div>
                <CommonTable
                  loading={pageData.loading}
                  scroll={{x: 1200}}
                  total={MijozlarGuruhReducer.mijozGuruh?.filter((val) => pageData.searchValue === '' ? val : val.name?.toUpperCase().includes(pageData.searchValue.toUpperCase()) && val)?.length}
                  handlePageChange={newChange}
                  pagination={true}
                  page={pageData.page}
                  size={pageData.pageSize}
                  data={MijozlarGuruhReducer.mijozGuruh?.filter((val) => pageData.searchValue === '' ? val : val.name?.toUpperCase().includes(pageData.searchValue.toUpperCase()) && val)}
                  columns={columns}
                />
                <Modal
                  open={pageData.openModal}
                  title={t('CustomGroup.3')}
                  okText={t('Buttons.6')}
                  cancelText={t('Buttons.7')}
                  onCancel={() => {
                      setPageData((prev) => ({...prev, openModal: false, editId: null}))
                      form.resetFields();
                  }}
                  onOk={() => {
                      form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreate(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                  }}
                >
                    <Form
                      form={form}
                      layout="vertical"
                      name="form_in_modal"
                    >
                        <Form.Item
                          name="name"
                          label={t('CustomGroup.4')}
                          rules={[
                              {
                                  required: true,
                              }
                          ]}
                        >
                            <Input placeholder={`${t('CustomGroup.4')}...`}/>
                        </Form.Item>
                        <Form.Item
                          name="percent"
                          label={t('CustomGroup.5')}
                          rules={[
                              {
                                  required: true,
                              }
                          ]}
                        >
                            <InputNumber className="w-100" placeholder={`${t('CustomGroup.5')}...`}/>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        </div>
    )
}

export default connect((users, MijozlarGuruhReducer), {
    getMijozLarGuruh,
    saveMijozLarGurux,
    editMijozLarGurux,
    deleteMijozLarGuruh,

})(MijozGuruhlariii)
