import React from 'react'
import {Link} from 'react-router-dom'
import CSV from '../../../../../img/CSV.png'
import Excel from '../../../../../img/Excel.png'
import Print from '../../../../../img/Print.png'
import Data from '../../../../../img/Data.png'
import Pdf from '../../../../../img/PDF.png'
import Edit from '../../../../../img/Edit.png'
import Korish from '../../../../../img/Korish.png'
import Delete from '../../../../../img/Delete.png'
import Arrow from '../../../../../img/arrowIcon.png'
import './otkazmalarRoyxati.css'
import {connect} from "react-redux";
import {useEffect, useState} from "react";
import OtkazmaReducer, {
    deleteOtkazma,
    editOtkazma,
    saveOtkazma,
    getOtkazma,
    getOtkazmaByBranch, getOneExchange
} from "../reducer/OtkazmaReducer";
import users from "../../../../../reducer/users";
import {useTranslation} from "react-i18next";
import formatDate from "../../../../../util";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Loading from "../../../../Loading";

function OtkazmalarRoyxati({
                               getOtkazma,
                               getOneExchange,
                               otkazmalar,
                               getOtkazmaByBranch,
                               deleteOtkazma,
                               saveOtkazma,
                               match,
                               users,
                               OtkazmaReducer
                           }) {

    const {t} = useTranslation()
    const [input, setInput] = useState(
        {
            view: '',
            search: '',
        }
    )

    function view(e) {
        input.view = e.target.value
        let a = {...input}
        setInput(a)
    }

    function search(e) {
        input.search = e.target.value
        let a = {...input}
        setInput(a)
    }

    useEffect(() => {
        if (users.viewExchangeAdmin && users.viewExchange) {
            getOtkazma(users.businessId)
        } else if (users.viewExchange) {
            getOtkazmaByBranch(users.branchId)
        }
    }, [OtkazmaReducer.counter])

    function deleteOt(item) {
        deleteOtkazma(item.id)
    }

    const [headlist, setheadlist] = useState([
        {
            sana: t('Trade.4'),
            baza: t('ProductList.8'),
            baza2: t('BaseList.3'),
            status: t('Trade.9'),
            yulkira: t('BaseList.4'),
            jamisumma: t('Purchase.22'),
            qisqaeslatma: t('Buttons.17'),
            amallar: t('tes.79')
        }
    ])
    const [sana, setsana] = useState(true)
    const [baza, setbaza] = useState(true)
    const [baza2, setbaza2] = useState(true)
    const [status, setstatus] = useState(true)
    const [yulkira, setyulkira] = useState(true)
    const [jamisumma, setjamisumma] = useState(true)
    const [qisqaeslatma, setqisqaeslatma] = useState(true)
    const [amallar, setamallar] = useState(true)

    const [malkamay, setmalkamay] = useState(false)

    const [activeinfo, setactiveinfo] = useState(false)




    const [loading,setLoading] = useState(false)

    useEffect(()=>{
            setLoading(true)
    },[OtkazmaReducer.getExchangeBool])

    useEffect(()=>{
        setLoading(false)
    },[])

    function toggleinfo() {
        setactiveinfo(!activeinfo)
    }

    function info(id) {
        toggleinfo()
        getOneExchange(id)
    }

    const [deletemodal, setdeletemodal] = useState(false)
    const [deleteID, setdeletID] = useState('')

    function deleteFunc() {
        deleteOtkazma(deleteID)
        setLoading(false)
        deleteModaltoggle('')
    }


    function deleteModaltoggle(item) {
        setdeletemodal(!deletemodal)
        setdeletID(item)
    }

    return (
        <div className="col-md-12 mt-2 mt-4 mb-4 ">
            <div className="textHeader">
                <h2>{t('BaseList.1')}</h2>
            </div>
            <div className="rowStyleN">
                <div className="qoshish">
                    <h5>{t('BaseList.2')}</h5>
                    {
                        users.addExchange ? <Link to={'/main/exchange/addExchange'}>
                            <button className='btn btn-primary'>+{t('Buttons.2')}</button>
                        </Link> : ''
                    }

                </div>
                {
                    users.viewExchange || users.viewExchangeAdmin ?
                        loading ?
                        OtkazmaReducer.otkazmalar ?
                            <div>
                                <div className="izlashOR">
                                    <div className="izlashBox1">
                                        <p>{t('Buttons.8')}</p>
                                        <select name="" value={input.view} onChange={view} id="">
                                            <option value="">25</option>
                                            <option value="">1,000</option>
                                            <option value="">All</option>
                                        </select>
                                        <button><img src={CSV} alt=""/> Export CSV</button>
                                        <button><img src={Excel} alt=""/> Export Excel</button>
                                        <button><img src={Print} alt=""/> Print</button>
                                        <button><img src={Pdf} alt=""/>Export PDF</button>
                                        <button onClick={() => setmalkamay(!malkamay)}><img src={Data}
                                                                                            alt=""/>{t('Buttons.18')}
                                        </button>

                                        {
                                            malkamay ? headlist.map(item => <ul className={'ul2'} key={item.id}>
                                                <li onClick={() => setsana(!sana)}
                                                    className={'li2'}>{sana ? item.sana : item.sana + ' <-'}</li>
                                                <li onClick={() => setbaza(!baza)}
                                                    className={'li2'}>{baza ? item.baza : item.baza + ' <-'}</li>
                                                <li onClick={() => setbaza2(!baza2)}
                                                    className={'li2'}>{baza2 ? item.baza2 : item.baza2 + ' <-'}</li>
                                                <li onClick={() => setstatus(!status)}
                                                    className={'li2'}>{status ? item.status : item.status + ' <-'}</li>
                                                <li onClick={() => setyulkira(!yulkira)}
                                                    className={'li2'}>{yulkira ? item.yulkira : item.yulkira + ' <-'}</li>

                                                <li onClick={() => setjamisumma(!jamisumma)}
                                                    className={'li2'}>{jamisumma ? item.jamisumma : item.jamisumma + ' <-'}</li>

                                                <li onClick={() => setamallar(!amallar)}
                                                    className={'li2'}>{amallar ? item.amallar : item.amallar + ' <-'}</li>
                                            </ul>) : ''
                                        }


                                    </div>
                                    <div className="izlashBox2">
                                        <input type="text" placeholder={t('Hamkorlar.i')} value={input.search}
                                               onChange={search}/>
                                    </div>
                                </div>
                                <div className="table-responsive table-wrapper-scroll-y my-custom-scrollbar">
                                    <table className='table table-striped table-bordered mt-4'>
                                        <thead>

                                        {
                                            headlist.map(item => <tr key={item.id}>
                                                <th>T/R</th>
                                                {sana ? <th>{item.sana}</th> : ''}
                                                {baza ? <th>{item.baza}</th> : ''}
                                                {baza2 ? <th>{item.baza2}</th> : ''}
                                                <th>{t('baza.BATAFSIL')}</th>
                                                {status ? <th>{item.status}</th> : ''}
                                                {amallar ? <th>{item.amallar}</th> : ''}
                                            </tr>)
                                        }

                                        </thead>
                                        <tbody>
                                        {
                                            OtkazmaReducer.otkazmalar.filter(val => {
                                                if (input.search === '') {
                                                    return val
                                                } else if (val.name.toUpperCase().includes(input.search.toUpperCase())) {
                                                    return val
                                                }
                                            })
                                                .map((item, index) => <tr key={item.id}>
                                                    <td>{index + 1}</td>
                                                    {sana ? <td>{formatDate(item.exchangeDate)}</td> : ''}
                                                    {baza ? <td>{item.shippedBranch?.name}</td> : ''}
                                                    {baza2 ? <td>{item.receivedBranch?.name}</td> : ''}
                                                    <td onClick={() => info(item.id)}>{<p
                                                        className={'batafsil'}>{t('tes.80')}</p>}</td>
                                                    {status ? <td>{item.exchangeStatus?.status}</td> : ''}
                                                    {amallar ? <td>
                                                        {/*{ users.editExchange ?*/}
                                                        {/*<Link to={'/headerthird/utkazmaRuyxati/taxrirlash'}>*/}
                                                        {/*    <button className='taxrirlash'><img src={Edit} alt=""/> {t('Buttons.1')}</button>*/}
                                                        {/*</Link> :''}*/}
                                                        {users.deleteExchange ?
                                                            <button onClick={() => deleteModaltoggle(item.id)}
                                                                    className='ochirish'><img src={Delete}
                                                                                              alt=""/> {t('Buttons.3')}
                                                            </button> : ''}
                                                    </td> : ''}
                                                    <Modal isOpen={deletemodal} toggle={deleteModaltoggle}>
                                                        <ModalBody>
                                                            <h5>{t('Buttons.12')} ?</h5>
                                                        </ModalBody>
                                                        <ModalFooter>
                                                            <button onClick={() => deleteFunc(item.id)}
                                                                    className={'btn btn-outline-primary'}>{t('Buttons.3')}</button>
                                                            <button onClick={() => deleteModaltoggle('')}
                                                                    className={'btn btn-outline-primary'}>{t('Buttons.7')}</button>
                                                        </ModalFooter>
                                                    </Modal>
                                                </tr>)
                                        }


                                        <Modal isOpen={activeinfo} toggle={toggleinfo}>
                                            <ModalHeader>
                                                <h4>{t('baza.BATAFSIL')}</h4>
                                            </ModalHeader>
                                            <ModalBody>
                                                <table
                                                    className={'table-hover table table-bordered mt-3 table-striped-columns'}>
                                                    <thead>
                                                    <tr>
                                                        <th>T/R</th>
                                                        <th>{t('Hamkorlar.ism')}</th>
                                                        <th>{t("block.mi")}</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        OtkazmaReducer.otkazmaOne.map((item, index) => <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{item.productName}</td>
                                                            <td>{item.exchangeProductQuantity}</td>
                                                        </tr>)
                                                    }
                                                    </tbody>
                                                </table>
                                            </ModalBody>
                                            <ModalFooter>
                                                <button className={'btn btn-outline-primary'}
                                                        onClick={toggleinfo}>{t('Buttons.7')}
                                                </button>
                                            </ModalFooter>
                                        </Modal>
                                        </tbody>
                                    </table>
                                </div>
                            </div> : <div>
                                <h4 className={'text-center'}>{OtkazmaReducer.message}</h4>
                            </div>: <Loading/> : ''
                }

            </div>
        </div>
    )
}

export default connect((OtkazmaReducer, users), {
    getOtkazma,
    saveOtkazma,
    getOneExchange,
    getOtkazmaByBranch,
    editOtkazma,
    deleteOtkazma
})(OtkazmalarRoyxati)
