import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../../../../../api";
import {toast} from "react-toastify";
// import {toast} from "react-toastify";

const slice = createSlice({
    name: 'jobs',
    initialState: {
        jobs: null,
        current:false,
        message:'',
        getBoolean: false,
        saveBoolean: false,
        deleteBoolean: false
    },
    reducers: {
        getFrom: (state, action) => {
            if(action.payload.success){
                state.jobs = action.payload.object
            }
            else{
                state.message = action.payload.message
                state.jobs = null
            }
            state.getBoolean = !state.getBoolean
            state.saveBoolean = false
        },

        savefrom: (state,action) => {

            if (action.payload.success){
                toast.success('ADDED')
                state.saveBoolean = true
            }else {
                toast.error(action.payload.message)
            }
            state.current=!state.current
        },

        editfrom: (state,action) => {

            if (action.payload.success){
                toast.success('EDITED')
                state.saveBoolean = true
            }else {
                toast.error(action.payload.message)
            }
            state.current=!state.current

        },
        deletefrom:(state,action)=>{
            if (action.payload.success){
                toast.success('DELETED')
                state.current=!state.current
            }else {
                toast.error(action.payload.message)
            }
            state.deleteBoolean = true
        }
    }
});

export const getJob=(data)=>apiCall({
    url: '/job/getByBusinessId/'+data,
    method:'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type,
});

export const saveJob=(data)=>apiCall({
    url: '/job',
    method:'post',
    data,
    onSuccess: slice.actions.savefrom.type,
    onFail: slice.actions.savefrom.type
});

export const editJob=(data)=>apiCall({
    url: '/job/'+data.id,
    method: 'put',
    data,
    onSuccess: slice.actions.editfrom.type,
    onFail: slice.actions.editfrom.type
});

export const deleteJob=(data)=>apiCall({
    url: '/job/'+data,
    method:'delete',
    data,
    onSuccess: slice.actions.deletefrom.type,
    onFail: slice.actions.deletefrom.type,
});

export default slice.reducer
