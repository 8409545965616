import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../api";
import {toast} from "react-toastify";

export const slice = createSlice({
    name: 'branch',
    initialState: {
        branch:null,
        branches: null,
        current: false,
        getBranchBool: false,
        saveBranchBool:false,
        getMessage:'',
        saveBranch:null
    },
    reducers: {
        get: (state, action) => {
            if (action.payload.success) {
                state.branch = action.payload.object
                let a = action.payload.object.map(({
                                                       name: label,
                                                       id: value, ...rest
                                                   }) => ({label, value, ...rest}));
                state.branches = a
            } else {
                state.branches = null
                    state.branch = null
                    state.getMessage = action.payload.message
            }
            state.getBranchBool = !state.getBranchBool
        },
        getSaveBranch:(state,action)=>{
          if (action.payload.success){
              state.saveBranch = action.payload.object
          }  else {
              state.saveBranch = null
          }
        },
        save: (state, action) => {
                if (action.payload.success){
                    toast.success('Yangi filial qo`shildi')
                }
                else{
                    toast.warning(action.payload.message)
                }
                state.current = !state.current
        },
        editfrom: (state, action) => {
            if (action.payload.success){
                state.current = !state.current
                toast.success('Filial tahrirlandi')
            }
            else{
                toast.warning(action.payload.message)
            }
        },
        deletefrom: (state, action) => {
            if (action.payload.success){
                state.current = !state.current
                toast.success('Filial o\'chirildi')
            }
            else{
                toast.warning(action.payload.message)
            }
        }
    }
})

export const getbranch = (data) => apiCall({
    url: '/branch/get-all-by-business-id/' + data,
    method: 'get',
    data,
    onSuccess: slice.actions.get.type,
    onFail: slice.actions.get.type,
})
export const getbranchByBranch = (data) => apiCall({
    url: '/branch/' + data,
    method: 'get',
    data,
    onSuccess: slice.actions.getSaveBranch.type,
    onFail: slice.actions.getSaveBranch.type,
})

export const savebranch = (data) => apiCall({
    url: '/branch',
    method: 'post',
    data,
    onSuccess: slice.actions.save.type,
    onFail: slice.actions.save.type
})

export const editbranchs = (data) => apiCall({
    url: '/branch/' + data.id,
    method: 'put',
    data,
    onSuccess: slice.actions.editfrom.type,
    onFail: slice.actions.editfrom.type,
})

export const deletebranchs = (data) => apiCall({
    url: '/branch/' + data,
    method: 'delete',
    data,
    onSuccess: slice.actions.deletefrom.type,
    onFail: slice.actions.deletefrom.type,
})

export default slice.reducer
