import './HodimlarRoyhati.css';
import CSV from '../../../../../img/CSV.png'
import Excel from '../../../../../img/Excel.png';
import Print from '../../../../../img/Print.png';
import Pdf from '../../../../../img/PDF.png';
import {Link} from 'react-router-dom';
import {ModalBody, Modal, ModalFooter, ModalHeader} from "reactstrap";
import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import users from "../../../../../reducer/users";
import XodimReducer, {
    getXodim,
    saveXodim,
    editXodim,
    deleteXodim,
    getXodimByBranch,
    getAgreement,
    editAgreement, getPeople, getUserRole
} from "../reducer/XodimReducer";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import Loading from "../../../../Loading";
import {
    Avatar,
    Box, Card, Checkbox,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow,
    TextField, Tooltip, Typography
} from "@mui/material";
import SalaryCountReducer, {
    editSalaryCount,
    getSalaryCount,
    saveSalaryCount
} from "../reducer/SalaryCountReducer";
import ModalLoading from "../../../../ModalLoading";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import LaunchTwoToneIcon from "@mui/icons-material/LaunchTwoTone";
import EditIcon from '@mui/icons-material/Edit';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import Label from './Label'
import {BaseUrl} from "../../../../../middleware";
import notificationReducer, {
    getNotification,
    saveNotification,
    sendSms
} from "../../../../../reducer/notificationReducer";
import photoreducer, {savephoto} from "../../../../../reducer/photoreducer";
import Imagecom from "../../../../Imagecom";
import shablonReducer, {getShablon} from "../../../../../reducer/shablonReducer";
import LavozimReducer, {getLavozim} from "../reducer/LavozimReducer";

function HodimlarRoyhati({
                             getXodim,
                             deleteXodim, getLavozim, LavozimReducer,
                             XodimReducer, saveNotification,
                             getPeople, notificationReducer,
                             users, savephoto, photoreducer,
                             getXodimByBranch, getShablon,
                             getAgreement, shablonReducer,sendSms,
                             editAgreement, getNotification,getUserRole,
                             saveSalaryCount, SalaryCountReducer
                         }) {
    const {t} = useTranslation()
    const [branchInput, setBranchInput] = useState('')
    const [agreementInput, setAgreementInput] = useState('')
    const [countInput, setCountInput] = useState(0)
    const [dateInput, setDateInput] = useState('')
    const [agreementPrice, setAgreementPrice] = useState('')
    const [description, setDescription] = useState('')

    function countInputChange(e) {
        setCountInput(e.target.value)
        if (e.target.value <= 0) {
            toast('1 dan kichik bolmasin')
        }
    }

    function agreementOnChange(e) {
        setAgreementInput(e.target.value)

        XodimReducer.agreement.map(item => {
            if (e.target.value === item.id) {
                setAgreementPrice(item.price)
            }
        })
    }

    const dateObj = new Date();
    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObj.getDate()).slice(-2);
    const year = dateObj.getFullYear();
    const shortDate = `${year}-${month}-${day}`;

    useEffect(() => {
        setDateInput(shortDate)
        setStartDate(shortDate)
        setEndDate(shortDate)
    }, [])

    const [visible, setvisible] = useState(5)
    let [counter, setCounter] = useState(0)

    function koproq() {
        let a = XodimReducer.xodimlar.length

        for (let i = 0; a; i++) {
            counter += visible
            if (counter < a) {
                setvisible(prev => prev + 5)
                return;
            } else {
                toast.error(t('as.30'))
                return
            }
        }
    }

    const [loading, setLoading] = useState(false)
    const [salary, editSalary] = useState(false)
    const [salaryControl, setSalaryControl] = useState(false)
    const [saveModal, setSaveModal] = useState(false)

    function toggleSalary() {
        setSalaryForm([])
        setEditId('')
        editSalary(prev => !prev)
    }

    function salaryControlToggle() {
        setSalaryControl(!salaryControl)
    }

    const [editId, setEditId] = useState(null)
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(5);
    const [query, setQuery] = useState(null);


    function editSalaryType(id) {
        toggleSalary()
        setEditId(id)
        getAgreement(id)
    }

    function salaryControlFunc(id) {
        salaryControlToggle()
        getAgreement(id)
    }

    const [salaryForm, setSalaryForm] = useState([])


    useEffect(() => {
        setSalaryForm(XodimReducer.agreement)
    }, [XodimReducer.agreeCurrent])

    function changeSalaryStatus(e, index) {
        let a = [...salaryForm]
        let b = {...a[index]}
        if (e.target.name === 'price') {
            b.price = e.target.value
        }
        a = [...salaryForm]
        a[index] = b
        let form = [...a]
        setSalaryForm(form)
    }


    function clearStatus() {
        let a = [...salaryForm]
        salaryForm.map((item, indx) => {
            if (item.salaryStatus !== 'KPI') {
                let b = {...a[indx]}
                b.active = false
                a[indx] = b
            }
        })
        let form = [...a]
        setSalaryForm(form)
    }

    function changeSalaryStatusChecked(e, index) {
        let a = [...salaryForm]
        salaryForm.map((item, indx) => {
            if (item.salaryStatus !== 'KPI') {
                if (index === indx) {
                    let b = {...a[index]}
                    b.active = e.target.checked
                    a[index] = b
                } else {
                    let b = {...a[indx]}
                    b.active = !e.target.checked
                    a[indx] = b
                }
            }

        })
        let form = [...a]
        setSalaryForm(form)
    }

    function editSaveSalary() {
        editAgreement({
            agreementDtoList: salaryForm,
            id: editId,
            startDate,
            endDate
        })
        setModal(true)
    }

    const [modal, setModal] = useState(false)

    useEffect(() => {
        if (XodimReducer.saveUserAgree) {
            toggleSalary()
            setLoading(false)
            setStartDate(shortDate)
            setEndDate(shortDate)
        }
        setModal(false)
    }, [XodimReducer.current])

    useEffect(() => {
        setLoading(true)
    }, [XodimReducer.getBoolean])

    const [mainBranchId, setMainBranchId] = useState(null)


    useEffect(() => {
        setLoading(false)
        getShablon(users.businessId)
        getLavozim(users.businessId)
    }, [])

    useEffect(() => {
        if (users.viewUserAdmin && users.viewUser) {
            getXodim(users.businessId)
        } else if (users.viewUser) {
            getXodimByBranch(users.branchId)
        }

    }, [XodimReducer.current])

    function deletex(item) {
        setLoading(false)
        deleteXodim(item.id)
    }

    function saveSalaryControl() {
        if (branchInput || agreementInput || countInput || dateInput) {
            saveSalaryCount({
                branchId: branchInput,
                agreementId: agreementInput,
                count: countInput,
                date: dateInput,
                salary: countInput * agreementPrice,
                description
            })
            setSaveModal(true)
        } else {
            toast.warning(t('as.31'))
        }
    }

    useEffect(() => {
        if (SalaryCountReducer.saveBoolean) {
            setBranchInput('')
            setAgreementInput('')
            setCountInput('')
            setDescription('')
        }
        setSaveModal(false)
        setSalaryControl(false)
    }, [SalaryCountReducer.current])

    const handlePageChange = (_event, newPage) => {
        setPage(newPage);
        if (query) {
            getPeople(
                {
                    branchId: mainBranchId ? mainBranchId : users.branchId,
                    name: query,
                    params: {
                        page: newPage,
                        size: limit,
                    }

                }
            )
        } else {
            getXodimByBranch(users.branchId)
        }

    };

    const handleLimitChange = (event) => {
        setLimit(parseInt(event.target.value));
        if (query) {
            getPeople(
                {
                    branchId: mainBranchId ? mainBranchId : users.branchId,
                    name: query,
                    params: {
                        page: page,
                        size: event.target.value,
                    }

                }
            )
        } else {
            getXodimByBranch(users.branchId)
        }
    };

    const [content, setContent] = useState('')

    const handleQueryChange = (event) => {
        event.persist();
        setQuery(event.target.value);
        if (event.target.value !== '') {
            getPeople(
                {
                    branchId: mainBranchId ? mainBranchId : users.branchId,
                    name: event.target.value,
                    params: {
                        page: page,
                        size: limit,
                    }

                }
            )
        } else {
            getXodimByBranch(users.branchId)
        }
        setContent(event.target.value)
    };

    const onChangeFilial = (e) => {
        setMainBranchId(e.target.value)
        e.target.value === "ALL" ? getXodim(users.businessId) : getXodimByBranch(e.target.value)
    }

    const [xabarnoma, setXabarnoma] = useState(false)
    const [sms, setSms] = useState(false)
    const [notificationText, setNotificationText] = useState(null)
    const [all, setAll] = useState(null)
    const [shablonId, setShablonId] = useState(null)
    const [indexForPhoto, setIndexForPhoto] = useState(null)
    const [photo, setPhoto] = useState(null)
    const [selectBranch, setSelectBranch] = useState(null)
    const [smsMessage, setSmsMessage] = useState(null)
    const [phone, setPhone] = useState('998')
    const [selectedItems, setSelectedUsers] = useState([]);
    const [userRole, setUserRole] = useState(null)
    const [filter, setFilter] = useState(false)
    const selectedSomeUsers =
        selectedItems.length > 0 && selectedItems.length < XodimReducer.xodimlar.length;
    const selectedAllUsers = selectedItems.length === XodimReducer.xodimlar?.length;


    function xabarnomaToggle() {
        setXabarnoma(!xabarnoma)
    }

    function smsToggle() {
        setSms(!sms)
    }

    const handleSelectAllUsers = (event) => {
        setSelectedUsers(event.target.checked ? XodimReducer.xodimlar.map((user) => user.id) : []);
    };

    const handleSelectOneInvoice = (event, invoiceId) => {
        if (!selectedItems.includes(invoiceId)) {
            setSelectedUsers((prevSelected) => [...prevSelected, invoiceId]);
        } else {
            setSelectedUsers((prevSelected) =>
                prevSelected.filter((id) => id !== invoiceId)
            );
        }
    };

    function pic(e) {
        const data = new FormData();
        data.append('file', e.target.files[0]);
        savephoto(data)
    }


    useEffect(() => {
        setPhoto(photoreducer?.photo)
    }, [photoreducer.current])

    useEffect(() => {
        getNotification()
    }, [notificationReducer.current])

    return (
        <div className="col-md-12 pb-4 pt-4">

            <div className="rowStyleHR">
                <div className="qoshishHR">
                    <h5>{t('Employ.3')}</h5>
                    {
                        users.addUser ?
                            <Link to={'/main/addUser'}>
                                <button className={`btn btn-primary`}>+{t('Employ.4')}</button>
                            </Link> : ''
                    }
                </div>
                <div>
                    {
                        selectedItems ?
                            <div>
                                <button onClick={xabarnomaToggle} className={'btn btn-info'}>{t('as.32')}</button>
                                <button onClick={smsToggle} className={'btn ms-2 btn-info'}>{t('as.33')}</button>
                                <button onClick={() => setFilter(!filter)} className={'btn ms-2 btn-primary'}>{t('as.34')}
                                </button>
                            </div>
                            : ''
                    }
                </div>
                {
                    users.viewUserAdmin || users.viewUser ?
                        loading ?
                            XodimReducer.xodimlar ?
                                <div>
                                    <div className="izlashHR">
                                        <div className="izlashBox1">
                                            <p>{t('Employ.5')}</p>
                                            <select name="" id="">
                                                <option value="">25</option>
                                                <option value="">1,000</option>
                                                <option value="">All</option>
                                            </select>
                                            <button><img src={CSV} alt=""/> Export CSV</button>
                                            <button><img src={Excel} alt=""/>Export Excel</button>
                                            <button><img src={Print} alt=""/>Print</button>
                                            <button><img src={Pdf} alt=""/>Export PDF</button>
                                        </div>


                                    </div>

                                    {
                                        filter ?

                                            <div
                                                className={'border mb-2 border-1 p-2 justify-content-between align-items-center d-flex'}>
                                                <div className="col-md-6">
                                                    {
                                                        users.viewUserAdmin ? (
                                                            <div
                                                            >
                                                                <label htmlFor="">{t('as.35')}</label>
                                                                <select value={mainBranchId}
                                                                        onChange={onChangeFilial}
                                                                        className={'form-control'}>
                                                                    <option value="ALL">{t('as.36')}</option>
                                                                    {
                                                                        users.branches ?
                                                                            users.branches.map((item, index) => <option
                                                                                key={index}
                                                                                value={item.id}>
                                                                                {item.name}
                                                                            </option>) : 'not found'
                                                                    }
                                                                </select>
                                                            </div>
                                                        ) : null
                                                    }
                                                </div>

                                                <div className={'col-md-6'}>
                                                    <label htmlFor="">{t('as.37')}</label>
                                                    <select value={userRole} onChange={(e)=>{
                                                        e.target.value === 'all'? getXodim(users.businessId):
                                                        getUserRole(e.target.value)
                                                    }} className={'form-control'}>
                                                        <option value="all">{t('as.38')}</option>
                                                        {
                                                            LavozimReducer.lavozimlar ?
                                                                LavozimReducer.lavozimlar.map((item, index) => <option
                                                                    key={index}
                                                                    value={item.id}>{item.name}</option>) : ''
                                                        }
                                                    </select>
                                                </div>


                                            </div> : ''
                                    }

                                    <Card>
                                        <Divider/>
                                        <Box p={2}>
                                            <TextField
                                                sx={{m: 0, outline: 'none'}}
                                                label={t('as.39')}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchTwoToneIcon/>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                onChange={handleQueryChange}
                                                placeholder={t('as.40')}
                                                value={query}
                                                size="small"
                                                fullWidth
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        </Box>
                                        <>
                                            <TableContainer>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Tr</TableCell>
                                                            <TableCell>
                                                                <Tooltip
                                                                    arrow
                                                                    placement="top"
                                                                    title={t('as.38')}
                                                                >
                                                                    <Checkbox
                                                                        checked={selectedAllUsers}
                                                                        indeterminate={selectedSomeUsers}
                                                                        onChange={handleSelectAllUsers}
                                                                    />
                                                                </Tooltip>
                                                            </TableCell>
                                                            <TableCell>{t('as.41')}</TableCell>
                                                            <TableCell>{t('as.42')}</TableCell>
                                                            <TableCell>{t('Role')}</TableCell>
                                                            <TableCell align="center">{t('Actions')}</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {XodimReducer.xodimlar.map((user, index) => {
                                                            const isInvoiceSelected = selectedItems.includes(
                                                                user.id
                                                            );
                                                            return (
                                                                <TableRow selected={isInvoiceSelected} hover
                                                                          key={user.id}>
                                                                    <TableCell>
                                                                        <Typography variant="h5">
                                                                            {index + 1}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Checkbox
                                                                            checked={isInvoiceSelected}
                                                                            // indeterminate={selectedSomeUsers}
                                                                            onChange={(e) =>
                                                                                handleSelectOneInvoice(e, user.id)
                                                                            }
                                                                            value={isInvoiceSelected}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Typography variant="h5">
                                                                            {user.username}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Box display="flex" alignItems="center">
                                                                            <Avatar
                                                                                sx={{
                                                                                    mr: 1
                                                                                }}
                                                                                src={user?.photoId
                                                                                    ? `${BaseUrl}/attachment/download/${user?.photoId
                                                                                    }` : ''}
                                                                            />
                                                                            <Box>
                                                                                {user.firstName}
                                                                                <Typography noWrap variant="subtitle2">
                                                                                    {user.lastName}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Label
                                                                            color={user.roleName === 'Admin' ? 'error' : user.roleName === 'Manager' ? 'info' :
                                                                                user.roleName === 'Employee' ? 'warning' : 'secondary'
                                                                            }>{user.roleName}</Label>
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <Tooltip title={t("as.43")} arrow>
                                                                            <Link to={'/main/profil/' + user.id}>
                                                                                <IconButton
                                                                                    color="primary"
                                                                                >
                                                                                    <LaunchTwoToneIcon
                                                                                        fontSize="small"/>
                                                                                </IconButton>
                                                                            </Link>
                                                                        </Tooltip>
                                                                        <Tooltip title={t("as.44")} arrow>
                                                                            <IconButton
                                                                                onClick={() => salaryControlFunc(user.id)}
                                                                                color="primary"
                                                                            >
                                                                                <PriceChangeIcon fontSize="medium"/>
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        <Tooltip title={t("as.45")} arrow>
                                                                            <IconButton
                                                                                onClick={() => editSalaryType(user.id)}
                                                                                color="primary"
                                                                            >
                                                                                <MonetizationOnIcon fontSize="small"/>
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        {
                                                                            users.editUser ?
                                                                                <Tooltip title={t('as.46')} arrow>
                                                                                    <Link
                                                                                        to={'/main/addUser/' + user.id}>
                                                                                        <IconButton
                                                                                            color="primary"
                                                                                        >
                                                                                            <EditIcon fontSize="small"/>
                                                                                        </IconButton>
                                                                                    </Link>
                                                                                </Tooltip> : ''
                                                                        }
                                                                        {
                                                                            users.deleteUser ?
                                                                                <Tooltip title={t('as.47')} arrow>
                                                                                    <IconButton
                                                                                        onClick={() => deletex(user)}
                                                                                        color="primary"
                                                                                    >
                                                                                        <DeleteTwoToneIcon
                                                                                            fontSize="small"/>
                                                                                    </IconButton>
                                                                                </Tooltip> : ''
                                                                        }
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            {
                                                query ?
                                                    <Box p={2}>
                                                        <TablePagination
                                                            component="div"
                                                            count={XodimReducer.people?.totalElements}
                                                            onPageChange={handlePageChange}
                                                            onRowsPerPageChange={handleLimitChange}
                                                            page={page}
                                                            rowsPerPageOptions={[5, 10, 15]}
                                                            rowsPerPage={limit}
                                                        />
                                                    </Box> :
                                                    XodimReducer.xodimlar ?
                                                        XodimReducer.xodimlar.length > 5 ?
                                                            <button onClick={koproq}
                                                                    className={'btn btn-outline-danger form-control'}>{t('Buttons.5')}</button> : '' : ''
                                            }
                                        </>
                                    </Card>
                                </div> : <div className={'border border-2'}>
                                    <h4 className={'text-center'}>{XodimReducer.message}</h4>
                                </div>
                            : <Loading/>
                        : ''
                }
            </div>
            <Modal isOpen={salary} toggle={toggleSalary}>
                <ModalBody>
                    <h4>{t('as.48')}</h4>
                </ModalBody>
                <ModalBody>
                    <div className={'col-md-12 d-flex justify-content-end'}>
                        <div className={' d-flex justify-content-end'}>
                            <h4>{t('as.49')}</h4>
                            <input name={'active'} onClick={clearStatus} type="radio" className={'ms-3'}
                                   style={{transform: 'scale(2)'}}/>
                        </div>
                    </div>
                    {
                        salaryForm.map((item, index) =>
                            <div>
                                <label>{item?.salaryStatus}</label>
                                <div className={' d-flex justify-content-between align-items-center'}>
                                    <div className={'d-flex justify-content-between align-items-center'}>
                                        <input className={'form-control'}
                                               name={'price'}
                                               onChange={(e) => changeSalaryStatus(e, index)}
                                               required={true}
                                               type="number"
                                               placeholder={t('as.50')}
                                               value={item?.price}
                                        />
                                        <h4 className={'ms-2'}>
                                            {
                                                item?.salaryStatus === 'KPI' ?
                                                    <div className={'d-flex mt-2'}><h4>% {t('as.51')}</h4></div> : 'UZB'
                                            }
                                        </h4>
                                    </div>
                                    <Divider/>
                                    <div className={'d-flex justify-content-between align-items-center'}>
                                        <h4>
                                            {
                                                item.active ? (t('as.52')) :
                                                (t('as.53')) }
                                        </h4>
                                        {
                                            item.salaryStatus === 'KPI' ?
                                                <input type="radio"
                                                       className={'ms-3'}
                                                       style={{transform: 'scale(2)'}}
                                                       checked={item.active}
                                                       disabled={true}
                                                /> :
                                                <input
                                                    className={'ms-3'}
                                                    style={{transform: 'scale(2)'}}
                                                    onChange={(e) => changeSalaryStatusChecked(e, index, item.salaryStatus)}
                                                    name={'active'}
                                                    type="radio"
                                                    checked={item.active}
                                                />
                                        }
                                    </div>

                                </div>
                                {/*<hr/>*/}

                            </div>
                        )
                    }
                    <div>_________________________________________________________________________</div>
                    <div className="row mt-4">
                        <div className="col-md-6">
                            <label htmlFor=""><b>{t('as.54')}</b></label>
                            <input type="date" className={'form-control'} value={startDate}
                                   onChange={(e) => setStartDate(e.target.value)}/>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor=""><b>{t('as.55')}</b></label>
                            <input type="date" className={'form-control'} value={endDate}
                                   onChange={(e) => setEndDate(e.target.value)}/>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button onClick={toggleSalary} className={'btn btn-outline-primary'}>{t('as.29')}Chiqish</button>
                    <button onClick={editSaveSalary} className={'btn btn-primary'}>{t('as.28')}Save</button>
                </ModalFooter>
            </Modal>

            {/*Salary control ---------*/}
            <Modal isOpen={salaryControl} toggle={salaryControlToggle}>
                <ModalHeader>
                    <h5>{t('as.56')}</h5>
                </ModalHeader>
                <ModalBody>
                    <div className={'row'}>
                        <div className="col-md-6 ">

                            <Grid item xs={12} sm={4} md={3} textAlign={{sm: 'start'}}>
                                <Box className={'mb-2'} style={{width: '200px'}} pr={3}
                                     sx={{pb: {xs: 1, md: 0}}}>
                                    <b>{t('as.57')}:</b>
                                </Box>
                            </Grid>
                            {
                                users.branches ?
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">{t('as.58')}</InputLabel>
                                        <Select labelId="demo-simple-select-label" id="demo-simple-select"
                                                value={branchInput} label="branchId"
                                                onChange={(e) => setBranchInput(e.target.value)}
                                                name={'branchId'}>
                                            {
                                                users?.branches.map((item, index) =>
                                                    <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                                )}
                                        </Select>

                                    </FormControl> : <Link to={'/headerthird/bazalarSetting'}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">
                                                {t('as.59')}
                                            </InputLabel>
                                            <select className={'form-control'} name=""
                                                    id="">{t('as.60')}</select>
                                        </FormControl>
                                    </Link>
                            }
                        </div>
                        <div className="col-md-6 mt-2">
                            <b>{t('as.61')}</b>
                            <input value={dateInput} onChange={(e) => setDateInput(e.target.value)} type="date"
                                   className={'form-control'}/>
                        </div>


                        <div className="col-md-6 mt-2">
                            <b>{t('as.62')} </b>
                            <input value={countInput} onChange={countInputChange} type="number"
                                   className={'form-control'}/>
                        </div>
                        <div className="col-md-6 ">

                            <Grid item xs={12} sm={4} md={3} textAlign={{sm: 'start'}}>
                                <Box className={'mb-2'} style={{width: '200px'}}
                                     pr={3} sx={{pb: {xs: 1, md: 0}}}>
                                    <b>{t('as.63')}:</b>
                                </Box>
                            </Grid>
                            {
                                users.branches ?
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">{t('as.')}</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={agreementInput}
                                            label="branchId"
                                            onChange={agreementOnChange}
                                            name={'branchId'}
                                        >
                                            {
                                                users ? XodimReducer.agreement.filter(value => value?.salaryStatus !== 'KPI').
                                                    // filter(value => value.active).
                                                    map((item, index) =>
                                                        <MenuItem key={index}
                                                                  value={item.id}>{item?.salaryStatus}</MenuItem>
                                                    ) : ''
                                            }

                                        </Select>

                                    </FormControl> : <Link to={'/headerthird/hodimlarruyxati/taxrirlash'}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">{t('as.65')}</InputLabel>
                                            <select className={'form-control'} name=""
                                                    id="">{t('as.60')}</select>
                                        </FormControl>
                                    </Link>
                            }
                        </div>

                        <div className="col-md-12 mt-2">
                            <b>{t('as.27')}</b>
                            <input value={description} onChange={(e) => setDescription(e.target.value)} type="text"
                                   className={'form-control'}/>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>

                    <button onClick={salaryControlToggle} className={'btn btn-danger'}>{t('as.29')}</button>
                    {countInput <= 0 ? '' :
                        <button onClick={saveSalaryControl} className={'btn btn-primary'}>{t('as.28')}</button>}
                    {/*<button onClick={saveSalaryControl} className={'btn btn-outline-primary'}>Saqlash</button>*/}
                </ModalFooter>
            </Modal>

            {/*X A B A R N O M A --- */}
            <Modal size={'xl'} isOpen={xabarnoma} toggle={xabarnomaToggle}>
                <ModalHeader>
                    <h4>{t('as.66')}</h4>
                </ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-md-6">
                            <label htmlFor="">{t('as.67')}</label>
                            <select value={selectBranch} onChange={(e) => setSelectBranch(e.target.value)}
                                    className={'form-control'}>
                                {
                                    users.branches ?
                                        users.branches.map(item => <option value={item.id}>{item.name}</option>) : ''
                                }
                            </select>
                        </div>
                        <div className="col-md-6">
                            <label>{t('as.68')}</label>
                            <select value={shablonId} onChange={(e) => setShablonId(e.target.value)}
                                    className={'form-control'}>
                                <option value={null}>{t('as.69')}</option>
                                {
                                    shablonReducer.shablon ?
                                        shablonReducer.shablon.map((item, id) => <option key={id} value={item.id}>{item.name}</option>) :
                                        <option value={null}>- - -</option>
                                }
                            </select>
                        </div>

                        <div className="col-md-12 mt-2">
                            <label htmlFor="">{t('as.70')}</label>
                            {/*<input type="text" className={'form-control'}*/}
                            {/*       value={notificationText} onChange={(e) => setNotificationText(e.target.value)}/>*/}
                            <textarea className={'form-control'} value={notificationText} onChange={(e) => setNotificationText(e.target.value)}
                                      cols="5" rows="5"></textarea>
                        </div>
                        <div className="col-md-6 mt-3">
                            <label>{t('as.71')}</label>
                            <select value={all} onChange={(e) => setAll(e.target.value)} className={'form-control'}>
                                <option value={null}> - {t('as.72')} -</option>
                                <option value={'ALL'}>{t('as.73')}</option>
                            </select>
                        </div>

                        <div className="col-md-6 mt-3">
                            <label htmlFor="">{t('as.74')}</label>
                            <input onChange={pic} type="file" className={'form-control'}/>
                        </div>
                        <div className={'col-md-6 offset-3 mt-4 border border-2 p-4'}>
                            <div className="col-md-12 d-flex justify-content-center  align-items-center">
                                {photo === null ? <Imagecom/> : <Imagecom id={photo}/>}
                            </div>
                        </div>

                    </div>
                </ModalBody>
                <ModalFooter>
                    <button onClick={() => {
                        saveNotification({
                            attachmentId: photo,
                            businessOrBranchId: selectBranch ? selectBranch : users.businessId,
                            message: notificationText,
                            name: null,
                            notificationKay: 'ALL',
                            objectId: null,
                            shablonId: shablonId, type: null,
                            userFromId: users.id,
                            userToId: selectedItems
                        })
                        setPhoto(null)
                        setNotificationText('')
                        setShablonId(null)
                        setSelectBranch(null)
                        xabarnomaToggle()
                    }} className={'btn btn-primary'}>{t('Buttons.6')}</button>
                    <button onClick={xabarnomaToggle} className={'btn btn-primary'}>{t('Buttons.7')}</button>
                </ModalFooter>
            </Modal>

            {/*S M S*/}
            <Modal isOpen={sms} toggle={smsToggle}>
                <ModalHeader>
                    <h4>{t('as.75')}</h4>
                </ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="mt-2 col-md-12">
                            <label htmlFor="">{t('as.76')}</label>
                            <select value={shablonId} onChange={(e) => setShablonId(e.target.value)}
                                    className={'form-control'}>
                                <option value={null}>{t('as.77')}</option>
                                {
                                    shablonReducer.shablon ?
                                        shablonReducer.shablon.map((i, d) => <option key={d} value={i.id}>{i.name}</option>) :
                                        <option value={null}>- - -</option>
                                }
                            </select>
                        </div>
                        <div className="mt-2 col-md-12">
                            <label htmlFor="">{t('as.78')}</label>
                            <textarea value={smsMessage} onChange={(e)=>setSmsMessage(e.target.value)} className={'form-control'} cols="5" rows="5">

                            </textarea>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button onClick={()=>{
                        sendSms({
                            key: 'EMPLOYEE',
                            message: smsMessage,
                            // mobilePhone: phone,
                            shablonId,
                            idList: selectedItems
                        })
                        setPhone('998')
                        setSmsMessage('')
                        setSelectedUsers([])
                        smsToggle()
                    }} className={'btn btn-primary'}>{t('Buttons.6')}</button>
                    <button onClick={smsToggle} className={'btn btn-primary'}>{t('Buttons.7')}</button>
                </ModalFooter>
            </Modal>


            <ModalLoading isOpen={modal}/>

            <ModalLoading isOpen={saveModal}/>


        </div>
    )
}


export default connect((XodimReducer, LavozimReducer, users, SalaryCountReducer, shablonReducer, notificationReducer, photoreducer), {
    getXodim, getNotification,
    saveXodim, getLavozim,
    editXodim, getShablon,
    deleteXodim, saveNotification,
    getXodimByBranch, savephoto,
    getPeople,getUserRole,
    getAgreement,sendSms,
    editAgreement,
    saveSalaryCount,
    editSalaryCount,
    getSalaryCount
})
(HodimlarRoyhati)


