import {Link, Switch, Route} from 'react-router-dom'
import CSV from '../../../../../img/CSV.png'
import Excel from '../../../../../img/Excel.png'
import Print from '../../../../../img/Print.png'
import Data from '../../../../../img/Data.png'
import Pdf from '../../../../../img/PDF.png'
import './maxsulotxisoboti.css'
import React, {useState, useEffect} from "react";
import {Modal, ModalBody, ModalFooter, ModalHeader, Progress} from "reactstrap";
import MaxsulotxisobotReducer, {
    getMaxsulotxisobot,
    getMaxsulotxisobot2,
    getProductAboutSell
} from '../reducer/MaxsulotxisobotReducer'
import {connect} from 'react-redux'
import {useTranslation} from "react-i18next";
import ReportsReducer, {getReports} from "../reducer/ReportsReducer";
import users from "../../../../../reducer/users";
import MijozGuruxReducer, {getMijozGurux} from "../../Hamkorlar/reducer/MijozGuruxReducer";
import XaridlarXisobotiReducer, {getOneXaridXisobot} from "../reducer/XaridlarXisobotiReducer";
import ReactToPrint from "react-to-print";
import {DatePicker} from "antd";
import formatDateYear from "../../../../../reducer/sana";
import {
    Avatar,
    AvatarGroup, Box, Card, FormControl, IconButton, InputLabel, MenuItem, Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead, TablePagination,
    TableRow,
    Tooltip,
    Typography
} from "@mui/material";
import {BaseUrl} from "../../../../../middleware";
import person_default from "../../../../../img/person-default.png";
import formatDate, {prettify} from "../../../../../util";
import CircleIcon from "@mui/icons-material/Circle";
import LaunchTwoToneIcon from "@mui/icons-material/LaunchTwoTone";
import EditIcon from "@mui/icons-material/Edit";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import MainHeaderText from "../../../../Components/MainHeaderText";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CardBody from "../../../../Components/CardBody";
import CommonTable from "../../../../Components/CommonTable";
import moment from "moment";

const {RangePicker} = DatePicker

function MaxsulotXisoboti({
                              getMijozGurux,
                              getProductAboutSell,
                              getMaxsulotxisobot2,
                              MijozGuruxReducer,
                              getMaxsulotxisobot,
                              MaxsulotxisobotReducer,
                              XaridlarXisobotiReducer,
                              getOneXaridXisobot,
                              users,
                              ReportsReducer,
                              getReports,
                              dukon,
                              summa,
                              eslatma,
                          }) {

    const {t} = useTranslation()


    const columns = [
        {
            title: 'Id',
            dataIndex: 'index',
            width: '3%',
        },
        {
            title: t('block.m'),
            dataIndex: 'name',
            key: 'name',
            width: '10%',

        },
        {
            title: t('ExpenseReport.7'),
            dataIndex: 'barcode',
            key: 'barcode',
            width: '10%',

        },
        {
            title: t('ExpenseReport.9'),
            dataIndex: 'salePrice',
            key: 'salePrice',
            render: (item) => <p className={'m-0'}>{prettify(item, 3)} so'm</p>,
            width: '10%',
        },
    ];

    const columnsTwo = [
        {
            title: 'Id',
            dataIndex: 'index',
            width: '3%',
        },
        {
            title: t('block.m'),
            dataIndex: 'name',
            key: 'name',
            width: '10%',

        },
        {
            title: t('block.mi'),
            dataIndex: 'amount',
            key: 'amount',
            width: '10%',

        },
        {
            title: t('xisob.oss'),
            dataIndex: 'lastSoldDate',
            key: 'lastSoldDate',
            render: (item) => <p className={'m-0'}>{moment(new Date(item)).format('lll')}</p>,
            width: '10%',
        },
    ];


    const [inputvalue, setInputvalue] = useState(
        {
            baza: '',
            xaridsanasi: '',
            sananibelgilang: '',
            view: '',
            izlash: ''
        }
    )


    const [customer, setCustomer] = useState(null)
    const [searchDate, setSearchDate] = useState(null)
    const [searchDates, setSearchDates] = useState(null)

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(5);
    const [branch, setBranch] = useState(null)

    const handlePageChange = (newPage) => {
        setPage(newPage-1);
        getProductAboutSell({
            branchId: branch ? branch : users.branchId,
            params: {
                size: limit,
                page: newPage-1
            }
        })
    };

    const handleLimitChange = (event,size) => {
        setLimit(parseInt(size));
        getProductAboutSell({
            branchId: branch ? branch : users.branchId,
            params: {
                size: parseInt(size),
                page: page
            }
        })
    };

    function selectCustomer(e) {
        setCustomer(e.target.value)
        if (e.target.value === "ALL" && searchDates === null && searchDate === null) {
            getMaxsulotxisobot(users.branchId)
        } else {
            getMaxsulotxisobot2({
                branchId: users.branchId,
                date: searchDate ? searchDate : 'ALL',
                startDate: searchDates ? searchDates[0] : null,
                endDate: searchDates ? searchDates[1] : null,
                customer: e.target.value === 'ALL' ? null : e.target.value
            })
        }

    }

    function Dates(ca) {
        setSearchDates(null)
        setSearchDate(ca)
        getMaxsulotxisobot2({
            branchId: users.branchId,
            startDate: null,
            endDate: null,
            date: ca,
            customer: customer === 'ALL' ? null : customer
        })
    }


    function changeBranch(e) {
        setBranch(e.target.value)
        getProductAboutSell({
            branchId: e.target.value,
            params: {
                size: 5,
                page: 0
            }
        })
    }


    function izlash(e) {
        inputvalue.izlash = e.target.value
        let a = {...inputvalue}
        setInputvalue(a)
    }

    useEffect(() => {
        getReports(users.branchId)
        getMijozGurux(users.businessId)
        getProductAboutSell({
            branchId: users.branchId,
            params: {
                size: 5,
                page: 0
            }
        })
    }, [])

    const [active, setActive] = useState(false)

    useEffect(() => {
        getMaxsulotxisobot(users.branchId)
    }, [])

    const [sana, setSana] = useState(false)
    const [toggleSana, toggleSetSana] = useState(false)

    function toggle() {
        setSana(!sana)
    }

    function toggle2() {
        toggleSetSana(!toggleSana)
    }


    return (
        <div className="col-md-12 mt-4 mb-4">
            <div class="row p-3">
                <MainHeaderText text={t('Sidebar.35')}/>
            </div>
            <div className="row px-2 align-items-center my-4">
                <div className="col-md-4">
                    <FormControl sx={{m: 1, minWidth: '100%'}} size="small">
                        <InputLabel id="demo-select-small-label">Mijozlar</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={customer}
                            label="Mijozlar"
                            IconComponent={KeyboardArrowDownIcon}
                            onChange={selectCustomer}
                        >
                            <MenuItem value="">
                                <em>Tanlang</em>
                            </MenuItem>
                            {
                                MijozGuruxReducer.mijozgurux &&
                                MijozGuruxReducer.mijozgurux.map(item =>
                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </div>
                <div className="col-md-4">
                    <RangePicker style={{width: '100%'}} onChange={(values) => {
                        let a = []
                        values.map(item => {
                            a.push(formatDateYear(item))
                        })
                        setSearchDates(a)
                        setSearchDate(null)
                        getMaxsulotxisobot2({
                            branchId: users.branchId,
                            startDate: a[0],
                            endDate: a[1],
                            date: null,
                            customer: customer === 'ALL' ? null : customer
                        })
                    }
                    }/>
                </div>
            </div>

            {/*<div className="col-md-4 col-sm-12 position-relative">*/}
            {/*    <h4 isOpen={sana} style={{cursor: 'pointer'}} className={'hovFoyda'}*/}
            {/*        onClick={toggle}>{t('Profit.2')}:</h4>*/}
            {/*    {*/}
            {/*        sana ? <div className={'d-block sana1 z'}>*/}
            {/*            <button onClick={(e) => Dates(e.target.value)} value={'LAST_DAY'}*/}
            {/*                    className={'s'}>{t('Profit.17')}</button>*/}
            {/*            <button onClick={(e) => Dates(e.target.value)} value={'LAST_WEEK'}*/}
            {/*                    className={'s'}>{t('Profit.18')}</button>*/}
            {/*            <button onClick={(e) => Dates(e.target.value)} value={'LAST_THIRTY_DAY'}*/}
            {/*                    className={'s'}>{t('Profit.19')}</button>*/}
            {/*            <button onClick={(e) => Dates(e.target.value)} value={'THIS_MONTH'}*/}
            {/*                    className={'s'}>{t('Profit.20')}</button>*/}
            {/*            <button onClick={(e) => Dates(e.target.value)} value={'LAST_MONTH'}*/}
            {/*                    className={'s'}>{t('Profit.21')}</button>*/}
            {/*            <button onClick={(e) => Dates(e.target.value)} value={'THIS_YEAR'}*/}
            {/*                    className={'s'}>{t('Profit.22')}</button>*/}
            {/*            <button onClick={(e) => Dates(e.target.value)} value={'LAST_YEAR'}*/}
            {/*                    className={'s'}>O'tgan yil*/}
            {/*            </button>*/}
            {/*            <button onClick={toggleSana} className={'s'}>{t('Profit.23')}</button>*/}

            {/*            {*/}
            {/*                <RangePicker onChange={(values) => {*/}
            {/*                    let a = []*/}
            {/*                    values.map(item => {*/}
            {/*                        a.push(formatDateYear(item))*/}
            {/*                    })*/}
            {/*                    setSearchDates(a)*/}
            {/*                    setSearchDate(null)*/}
            {/*                    getMaxsulotxisobot2({*/}
            {/*                        branchId: users.branchId,*/}
            {/*                        startDate: a[0],*/}
            {/*                        endDate: a[1],*/}
            {/*                        date: null,*/}
            {/*                        customer: customer === 'ALL' ? null : customer*/}
            {/*                    })*/}


            {/*                }}/>*/}
            {/*            }*/}
            {/*        </div> : ''*/}
            {/*    }*/}
            {/*</div>*/}

            <div className="row p-3">
                <div class="col-md-12">
                    <CardBody>
                        <div class="col-md-12">
                            <div className="qoshish mt-4">
                                <h5>{t('PurchaseList.9')}</h5>
                            </div>
                            {
                                MaxsulotxisobotReducer.maxsulotxisobot ?
                                    <div>
                                        {/*<div className="izlashMax2">*/}
                                        {/*    <div className="izlashBox1">*/}
                                        {/*        <p>{t('Buttons.8')}</p>*/}
                                        {/*        <select name="">*/}
                                        {/*            <option value="">25</option>*/}
                                        {/*            <option value="">50</option>*/}
                                        {/*            <option value="">100</option>*/}
                                        {/*            <option value="">200</option>*/}
                                        {/*            <option value="">500</option>*/}
                                        {/*            <option value="">1,000</option>*/}
                                        {/*            <option value="">All</option>*/}
                                        {/*        </select>*/}
                                        {/*        <button><img src={CSV} alt=""/> Export CSV</button>*/}
                                        {/*        <button><img src={Excel} alt=""/> Export Excel</button>*/}
                                        {/*        <button><img src={Print} alt=""/> Print</button>*/}
                                        {/*        <button><img src={Pdf} alt=""/>Export PDF</button>*/}
                                        {/*        <button><img src={Data} alt=""/>{t('Buttons.18')} </button>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="izlashBox2">*/}
                                        {/*        <input type="text" placeholder='Izlash...' value={inputvalue.izlash}*/}
                                        {/*               onChange={izlash}/>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div className="table-responsive pb-4">
                                            <CommonTable size={0} page={MaxsulotxisobotReducer.maxsulotxisobot?.length}
                                                         pagination={false} data={MaxsulotxisobotReducer.maxsulotxisobot}
                                                         columns={columns}
                                            />
                                        </div>
                                    </div> :
                                    <div>
                                        <h4 className={'text-center'}>{MaxsulotxisobotReducer.message}</h4>
                                    </div>
                            }
                        </div>
                    </CardBody>
                </div>
            </div>
            <div className="row p-3">
                <div className="col-md-12">
                    <CardBody>
                        <div className="col-md-12">
                            <div className="row d-flex align-items-end  my-3">
                                <div className='col-md-3'>
                                    <h5>{t('xisob.osm')}</h5>
                                </div>
                                <div className="col-md-3">
                                    <FormControl sx={{m: 1, minWidth: '100%'}} size="small">
                                        <InputLabel id="demo-select-small-label">Baza</InputLabel>
                                        <Select
                                            labelId="demo-select-small-label"
                                            id="demo-select-small"
                                            value={branch}
                                            label="Baza"
                                            IconComponent={KeyboardArrowDownIcon}
                                            onChange={changeBranch}
                                        >
                                            {/*<MenuItem value=" ">*/}
                                            {/*    <em>Tanlang</em>*/}
                                            {/*</MenuItem>*/}
                                            {
                                                users.branches.map(item =>
                                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div>
                                <CommonTable size={limit} page={page}  total={MaxsulotxisobotReducer.productPage?.totalPage}
                                             pagination={true}
                                             handleLimitChange={handleLimitChange}
                                             handlePageChange={handlePageChange}
                                             data={MaxsulotxisobotReducer.productPage?.getLessProduct}
                                             columns={columnsTwo}
                                />
                            </div>
                        </div>
                    </CardBody>
                </div>
            </div>
        </div>
    )
}

export default connect((MaxsulotxisobotReducer, XaridlarXisobotiReducer, MijozGuruxReducer, ReportsReducer, users), {
    getProductAboutSell,
    getMaxsulotxisobot2,
    getMaxsulotxisobot,
    getOneXaridXisobot,
    getReports,
    getMijozGurux,
})(MaxsulotXisoboti)
