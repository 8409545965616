import {createSlice, current} from "@reduxjs/toolkit";
import {apiCall} from "../../../../../api";
import {toast} from "react-toastify";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {Link,useHistory} from "react-router-dom";

const slice = createSlice({
    name: 'salaryCount',
    initialState: {
        salaryCount: null,
        salaryOneCount:{},
        current:true,
        message: '',
        getBoolean: false,
        saveBoolean:false,
        editBoolean: false,
        currentOne: false
    },
    reducers: {
        getFrom: (state, action) => {
            if (action.payload.success){
                state.salaryCount = action.payload.object
            }else {
                state.salaryCount=null
                state.message=action.payload.message
            }
            state.getBoolean = !state.getBoolean
            state.saveBoolean = false
            state.editBoolean = false
        },
        getFromID: (state, action) => {
            state.salaryOneCount = action.payload.object
            state.currentOne=!state.currentOne
        },
        savefrom: (state, action) => {
            if (action.payload.success){
                state.saveBoolean = true
                toast.success('Qoshildi')
            }else {
                toast.warning(action.payload.message)
            }
            state.current=!state.current
        },

        message:(state,action)=>{
            state.message = action.payload.message
            toast.error(state.message)
        },
        editfrom: (state, action) => {
            if (action.payload.success){
                toast.success('tahrirlandi')
                state.editBoolean = true
            }else {
                toast.error(action.payload.message)
            }
            state.current=!state.current
        },
        deletefrom: (state, action) => {
            if (action.payload.success){
                state.current=!state.current
                toast.success('o\'chirildi')
            }else {
                toast.error(action.payload.message)
            }
        }
    }
});
export const  getSalaryCount =(data) => apiCall({
    url: `/salaryCount/by-user-last-month/${data.userId}?${data.branchId ? `branchId=${data.branchId}` : ''}`,
    method: 'get',
    data,
    onSuccess: slice.actions.getFrom.type,
    onFail:slice.actions.getFrom.type
});
export const getOneSalaryCount = (data) => apiCall({
    url: '/salaryCount/'+data,
    method: 'get',
    data,
    onSuccess: slice.actions.getFromID.type,
    onFail: slice.actions.getFromID.type
});

export const saveSalaryCount = (data) => apiCall({
    url: '/salaryCount',
    method: 'post',
    data,
    onSuccess: slice.actions.savefrom.type,
    onFail: slice.actions.savefrom.type
});

export const editSalaryCount = (data) => apiCall({
    url: '/salaryCount/'+data.id,
    method: 'put',
    data,
    onSuccess: slice.actions.editfrom.type,
    onFail: slice.actions.editfrom.type
});

export default slice.reducer
