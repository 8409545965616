import React from 'react';
import './cardbody.css'
function CardBody({children,style}) {
    return (
        <div className={'cardBody'} style={style}>
            {children}
        </div>
    );
}

export default CardBody;