import {connect} from "react-redux";
import {useEffect} from "react";
import FoydaZararReducer from "../../reducer/FoydaZararReducer";
import users from "../../../../../../reducer/users";
import branchreducer, {getbranch} from "../../../../../../reducer/branchreducer";
import Reducer, {getFoydaMijoz, getFoydaMijoz2} from "./reducer/Reducer";
import {useTranslation} from "react-i18next";
import CommonTable from "../../../../../Components/CommonTable";
import {prettify} from "../../../../../../util";

function Mijoz({Reducer, getFoydaMijoz, users, branchId, data, dates, getFoydaMijoz2, course, courseName}) {
    const {t} = useTranslation();

    const columns = [
        {
            title: 'Id',
            dataIndex: 'index',
            width: '3%',
        },
        {
            title: t('Lids.MIJOZ'),
            dataIndex: 'categoryName',
            key: 'categoryName',
            width: '5%',

        },
        {
            title: t('xisob.Foyda'),
            dataIndex: 'profit',
            key: 'profit',
            render: (item) => <p className={'m-0'}>{prettify(item)} so'm</p>,
            width: '10%',

        },
    ];


    useEffect(() => {

        if (data) {
            getFoydaMijoz({
                branchId: branchId ? branchId : users.branchId,
                date: data
            })
        } else if (dates) {
            getFoydaMijoz2({
                branchId: branchId ? branchId : users.branchId,
                startDate: dates[0],
                endDate: dates[1]
            })
        } else {
            getFoydaMijoz({
                branchId: branchId ? branchId : users.branchId,
                date: data
            })
        }
    }, [])

    return (
        <>
            {
                Reducer.foydaMijoz ?
                    <CommonTable size={0} page={Reducer.foydaMijoz?.length}
                                 pagination={false} data={Reducer.foydaMijoz}
                                 columns={columns}
                    /> :
                    <div className={'mt-2'}>
                        <h4 className={'text-center'}>{Reducer.message}</h4>
                    </div>
            }

        </>
    )
}

export default connect((FoydaZararReducer, Reducer, users, branchreducer), {
    getbranch, getFoydaMijoz, getFoydaMijoz2

})(Mijoz)
