import './kopsotilgan.css';
import {Link, Switch, Route} from 'react-router-dom'
import CSV from '../../../../../img/CSV.png'
import Excel from '../../../../../img/Excel.png'
import Chart from "react-apexcharts";
import {connect} from 'react-redux'
import KopsotilgantovarlarReducer, {getKopsotilgan,} from '../reducer/KopsotilgantovarlarReducer'
import React, {useEffect, useState} from 'react'
import branchreducer, {getbranch} from "../../../../../reducer/branchreducer";
import {useTranslation} from "react-i18next";
import ReportsReducer, {getReports} from "../reducer/ReportsReducer";
import users from "../../../../../reducer/users";
import Column from './Column Chart'
import CanvasJSReact from './assets/canvasjs.react';
import BolimReducer, {getBolim} from "../../Maxsulotlar/reducer/BolimReducer";
import FirmaReducer, {getFirma} from "../../Maxsulotlar/reducer/FirmaReducer";
import {DatePicker} from "antd";
import formatDateYear from "../../../../../reducer/sana";
import MainHeaderText from "../../../../Components/MainHeaderText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuItem from "@mui/material/MenuItem";
import CardBody from "../../../../Components/CardBody";
import CommonTable from "../../../../Components/CommonTable";
import moment from "moment/moment";
import {prettify} from "../../../../../util";

const {RangePicker} = DatePicker
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

function KopSotilgan({
                         BolimReducer,
                         getBolim,
                         FirmaReducer,
                         getFirma,
                         KopsotilgantovarlarReducer,
                         getKopsotilgan,
                         users,
                         getReports,
                         ReportsReducer
                     }) {

    const {t} = useTranslation()

    const columns = [
        {
            title: 'Id',
            dataIndex: 'index',
            width: '3%',
        },
        {
            title: t('block.m'),
            dataIndex: 'name',
            key: 'name',
            width: '10%',

        },
        {
            title: t('ExpenseReport.7'),
            dataIndex: 'barcode',
            key: 'barcode',
            width: '10%',

        },
        {
            title: t('block.mi'),
            dataIndex: 'amount',
            key: 'amount',
            render: (item, value) => <p className={'m-0'}>{item} {value?.measurement}</p>,
            width: '12%',

        },
        {
            title: t('block.sn'),
            dataIndex: 'salePrice',
            key: 'salePrice',
            render: (item) => <p className={'m-0'}>{prettify(item, 3)} so'm</p>,
            width: '10%',
        },
    ];


    const [branch, setbranch] = useState(null)
    const [category, setCategory] = useState(null)
    const [brand, setBrand] = useState(null)
    const [dates, setDates] = useState(null)

    function selectBranch(e) {
        setbranch(e.target.value)
        getKopsotilgan({
            branchId: e.target.value,
            brand: brand === "ALL" ? null : brand,
            category: category === "ALL" ? null : category,
            startDate: dates ? dates[0] : null,
            endDate: dates ? dates[1] : null
        })
    }

    function selectCategory(e) {
        setCategory(e.target.value)
        getKopsotilgan({
            branchId: branch ? branch : users.branchId,
            category: e.target.value === "ALL" ? null : e.target.value,
            brand: brand === "ALL" ? null : brand,
            startDate: dates ? dates[0] : null,
            endDate: dates ? dates[1] : null
        })
    }

    function selectBrand(e) {
        setBrand(e.target.value)
        getKopsotilgan({
            branchId: branch ? branch : users.branchId,
            category: category === "ALL" ? null : category,
            brand: e.target.value === "ALL" ? null : e.target.value,
            startDate: dates ? dates[0] : null,
            endDate: dates ? dates[1] : null
        })
    }


    const [counter, setCounter] = useState(0)
    const [chart, setChart] = useState(null)


    useEffect(() => {
        getKopsotilgan({
            branchId: users.branchId,
            category: null,
            brand: null,
            startDate: null,
            endDate: null
        })
        getBolim(users.businessId)
        getFirma(users.businessId)
    }, [])

    useEffect(() => {
        let a = []
        if (KopsotilgantovarlarReducer.kopsotilgan) {
            KopsotilgantovarlarReducer.kopsotilgan.map(item => {
                a.push({
                    label: item.name,
                    y: item.amount
                })
            })
        }

        setChart(a)
    }, [KopsotilgantovarlarReducer.current])


    const options = {
        title: {
            text: "Ko'p sotilgan mahsulotlar"
        },
        animationEnabled: true,
        data: [
            {
                // Change type to "doughnut", "line", "splineArea", etc.
                type: "column",
                dataPoints: chart

            }
        ]
    }


    return (
        <div className="col-md-12 mt-4 ">
            <div className="row p-3">
                <MainHeaderText text={t('ExpenseReport.10')}/>

            </div>
            <div className="row px-2 align-items-center my-4">
                <div className="col-md-3">
                    <FormControl sx={{m: 1, minWidth: '100%'}} size="small">
                        <InputLabel id="demo-select-small-label">Baza</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={branch}
                            label="Baza"
                            IconComponent={KeyboardArrowDownIcon}
                            onChange={selectBranch}
                        >
                            {
                                users.branches.map(item =>
                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </div>
                <div className="col-md-3">
                    <FormControl sx={{m: 1, minWidth: '100%'}} size="small">
                        <InputLabel id="demo-select-small-label">{t('ProductList.4')}</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={category}
                            label={t('ProductList.4')}
                            IconComponent={KeyboardArrowDownIcon}
                            onChange={selectCategory}
                        >
                            <MenuItem value="ALL">
                                <em>Barchasi</em>
                            </MenuItem>
                            {
                                BolimReducer.bolimlar ?
                                    BolimReducer.bolimlar.map(item =>
                                        <MenuItem value={item.id}>{item.name}</MenuItem>) : <MenuItem value="">
                                        <em>Barchasi</em>
                                    </MenuItem>
                            }
                        </Select>
                    </FormControl>
                </div>
                <div className="col-md-3">
                    <FormControl sx={{m: 1, minWidth: '100%'}} size="small">
                        <InputLabel id="demo-select-small-label">{t('Hamkorlar.b')}</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={brand}
                            label={t('Hamkorlar.b')}
                            IconComponent={KeyboardArrowDownIcon}
                            onChange={selectBrand}
                        >
                            <MenuItem value="ALL">
                                <em>Barchasi</em>
                            </MenuItem>
                            {
                                FirmaReducer.firmalar ?
                                    FirmaReducer.firmalar.map(item =>
                                        <MenuItem value={item.id}>{item.name}</MenuItem>) : <MenuItem value="">
                                        <em>Barchasi</em>
                                    </MenuItem>
                            }
                        </Select>
                    </FormControl>
                </div>
                <div className="col-md-3">
                    <RangePicker style={{width: '100%'}} onChange={(values) => {
                        let a = []
                        if (values !== null) {
                            values.map(item => {
                                a.push(formatDateYear(item))
                            })
                            setDates(a)
                            getKopsotilgan({
                                branchId: branch ? branch : users.branchId,
                                startDate: a ? a[0] : null,
                                endDate: a ? a[1] : null,
                                category: category === "ALL" ? null : category,
                                brand: brand === "ALL" ? null : brand
                            })
                        } else {
                            setDates(null)
                            getKopsotilgan({
                                branchId: branch ? branch : users.branchId,
                                startDate: null,
                                endDate: null,
                                category: category === "ALL" ? null : category,
                                brand: brand === "ALL" ? null : brand
                            })
                        }
                    }
                    }/>
                </div>
            </div>

            {/*{*/}
            {/*    KopsotilgantovarlarReducer.kopsotilgan ?*/}
            {/*        <div>*/}

            {/*            <CanvasJSChart options={options}/>*/}
            {/*        </div>:''*/}
            {/*}*/}

            <div class="row p-3">
                <div class="col-md-12">
                    <CardBody>
                        {
                            KopsotilgantovarlarReducer.kopsotilgan ?
                                <div className="mt-4 table-responsive">
                                    <CommonTable size={0} page={KopsotilgantovarlarReducer.kopsotilgan?.length}
                                                 pagination={false} data={KopsotilgantovarlarReducer.kopsotilgan}
                                                 columns={columns}
                                    />
                                </div>
                                : <div>
                                    <h2 className={'text-center'}>{KopsotilgantovarlarReducer.message}</h2>
                                </div>
                        }
                    </CardBody>
                </div>
            </div>
        </div>
    )
}

export default connect((KopsotilgantovarlarReducer, users, branchreducer, ReportsReducer, BolimReducer, FirmaReducer), {
    getReports,
    getKopsotilgan,
    getbranch,
    getBolim,
    getFirma
})(KopSotilgan)
