import {connect} from "react-redux";
import { Grid } from '@mui/material';
import PageTitleWrapper from '../PageTitleWrapper';
import Results from './Results';
import PageHeader from './PageHeader';
import taskStatusReducer from "../../../../../reducer/taskStatusReducer";
function ManagementUsers({taskStatusReducer}) {
  return (
    <>
        <title>Tasks</title>
      <PageTitleWrapper>
        <PageHeader  />
      </PageTitleWrapper>
      <Grid
        sx={{
          px: 4
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          <Results   tasksStatus={taskStatusReducer} />
        </Grid>
      </Grid>
    </>
  );
}

export default connect((taskStatusReducer),{}) (ManagementUsers);
