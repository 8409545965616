import {Table} from 'antd';
import './commonTable.css'

const CommonTable = ({data,columns,size,page,pagination,total,handleLimitChange,handlePageChange,rowSelection,onchange, loading = false, scroll = {
  y: 350,
  x: 800
}}) => (
    <Table
        loading={loading}
        columns={columns}
        dataSource={data?.map((item, index) => {
            return {...item, index: index + 1 + (page * size), key:item.id}
        })}
        scroll={scroll}
        onChange={onchange}
        rowSelection={rowSelection}
        pagination={pagination && {
            total:total,
            showTotal: false,
            showTitle:true,
            pageSize:size,
            hideOnSinglePage:true,
            showSizeChanger:true,
            onShowSizeChange:handleLimitChange,
            pageSizeOptions:["5", "10","25","50","100"],
            current:page+1,
            onChange:handlePageChange
        }}
    />
);
export default CommonTable;


