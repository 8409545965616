import React, {useEffect, useState} from 'react'
import '../all-buseness/allbusenesses.css'
import CSV from '../../../../../../img/CSV.png'
import {Link} from 'react-router-dom'
import {connect} from "react-redux";
import allbusinessreducer, {
   getAllArchiveBusiness,
    activeArchiveBusiness,
} from "../../reducers/allbusinessreducer";
import users from "../../../../../../reducer/users";
import {useTranslation} from "react-i18next";
import formatDate from "../../../../../../util";
import {Modal, ModalBody, ModalFooter} from "reactstrap";
import CommonTable from "../../../../../Components/CommonTable";


function ArchiveBusiness({allbusinessreducer, users,getAllArchiveBusiness,activeArchiveBusiness}) {

    const {t} = useTranslation()
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(5);
    const [search,setSearch] = useState()


    const handlePageChange = (newPage) => {
        setPage(newPage - 1);
    };
    const handleLimitChange = (event, size) => {
        setPage(0)
        setSize(size);
    };


    const columns = [
        {
            title: 'Id',
            dataIndex: 'index',
            rowScope: 'row',
            width: '2%',
        },
        {
            title: 'Biznes nomi',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Tavsif',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Amallar',
            dataIndex: 'id',
            key: 'id',
            render: (item, values) => <div>
                <button onClick={() => deleteModaltoggle(item)} className={'btn btn-info'}>Arxivdan chiqarish
                </button>
            </div>
        },
    ];


    useEffect(() => {
        getAllArchiveBusiness(
            {
                page:page,
                size:size,
                name:search
            }
        )
    }, [allbusinessreducer.current,page,size,search])







    const [deletemodal, setdeletemodal] = useState(false)
    const [deleteID, setdeletID] = useState('')

    function deleteModaltoggle(item) {
        setdeletemodal(!deletemodal)
        setdeletID(item)
    }

    function deleteFunc() {
        activeArchiveBusiness(deleteID)
        deleteModaltoggle('')
    }


    return (
        <div className="mt-2">
            <div className="rowStyleSA2">
                <h2>Arxivdagi bizneslar</h2>
                <div className="izlashBH">
                    <button><img src={CSV} alt=""/> Export CSV</button>
                    <div className="izlashBox2">
                        <input type="text" onChange={(e)=>setSearch(e.target.value)} placeholder={t('num.37')}/>
                    </div>
                </div>
                <div className="table-responsive  table-wrapper-scroll-y my-custom-scrollbar">
                    <div className="mt-4 table-responsive">
                        <CommonTable size={size} page={page} handleLimitChange={handleLimitChange}
                                     handlePageChange={handlePageChange}
                                     total={allbusinessreducer.archiveBusiness?.totalItems}
                                     pagination={true} data={allbusinessreducer.archiveBusiness?.business_list}
                                     columns={columns}
                        />
                    </div>
                </div>

                <Modal isOpen={deletemodal} toggle={deleteModaltoggle}>
                    <ModalBody>
                        <h5>{t('super.17')}</h5>
                    </ModalBody>
                    <ModalFooter>

                        <button onClick={() => deleteModaltoggle('')}
                                className={'btn btn-danger'}>Yo'q
                        </button>
                        <button onClick={deleteFunc} className={'btn btn-success'}>Ha
                        </button>
                    </ModalFooter>
                </Modal>
            </div>
        </div>
    )
}

export default connect((allbusinessreducer, users), {
   getAllArchiveBusiness,activeArchiveBusiness
})(ArchiveBusiness)
