import {useEffect, useState} from 'react'
import {connect} from "react-redux";
import users from "../../../../../reducer/users";
import './savdoQoshish.css'
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import XaridReducer, {getXaridProductType} from "../../Haridlar/reducer/XaridReducer";
import {formatDateMinus} from "../../../../../util";
import ModalLoading from "../../../../ModalLoading";
import lossReducer,{saveLossProduct} from "../../../../../reducer/lossReducer";
import MaxsulotlarRoyxariReducer,{getBarcodeAndName} from "../../Maxsulotlar/reducer/MaxsulotlarRoyxariReducer";

function SavdoQoshish({
                          getBarcodeAndName,
                          saveLossProduct,
                          lossReducer,
                          MaxsulotlarRoyxariReducer,
                          match,
                          users
                      }) {

    const {t} = useTranslation()
    const {handleSubmit, register, formState: {errors}, setValue, getValues} = useForm()
    const [input, setInput] = useState(
        {
            maxsulotnomishtrix: ''
        }
    )




    const [branch,setBranch] =useState(null)




    const [xisob, setxisob] = useState(0)
    const [jamixisob, setjamixisob] = useState(0)
    const [arr1, setarr1] = useState([])

    function pushesh(val) {
        if (val.amount == 0) {
            toast.warning('Mahsulot bazada qolmagan!')
        } else {
            let order = false
            let trade = false
            arr1.map(item => {
                if (item.productTypePriceId === null) {
                    if (item.productId === val.productId) {
                        if (item.tradeProductId) {
                            item.delete = false
                            trade = true
                            order = true
                        } else {
                            order = true
                        }

                    }
                } else if (item.productId === null) {
                    if (item.productTypePriceId === val.productTypePriceId) {
                        if (item.tradeProductId) {
                            item.delete = false
                            trade = true
                            order = true
                        } else {
                            order = true
                        }
                    }
                }
            })
            if (order === true) {
                if (trade === false) {
                    toast.success('Mahsulot jadvalda bor')
                }
            } else {
                arr1.push({
                    productId: val.productId ? val.productId : null,
                    productTypePriceId: val.productTypePriceId ? val.productTypePriceId : null,
                    totalSalePrice: 0,
                    quantity: 0,
                    name: val.name,
                    salePrice:0,
                    measurementName: val.measurementName,
                    amount: val.amount,
                    disabled: false,
                    active: false,
                    delete: false,
                    type: val.type
                })
                order = false
            }
            let b = [...arr1]
            setarr1(b)

            xisobkitob()
        }

        input.maxsulotnomishtrix = ''
        let a = {...input}
        setInput(a)
        setComboArray([])
    }


    const [ComboArray, setComboArray] = useState([])
    const [checkedWarning, setCheckedWarning] = useState(false)
    const [editCheckedWarning, setEditCheckedWarning] = useState(false)

    function maxsulotnomishtrix(e) {
        input.maxsulotnomishtrix = e.target.value
        let code = {...input}
        setInput(code)
        getBarcodeAndName({
            branchId: branch ? branch : users.branchId,
            name: e.target.value
        })
    }

    useEffect(()=>{
        let a = []
        if (MaxsulotlarRoyxariReducer.productSearch){
            MaxsulotlarRoyxariReducer.productSearch.filter(val => {
                if (input.maxsulotnomishtrix === '') {
                    setComboArray([])
                } else if (val.name === input.maxsulotnomishtrix) {
                    pushesh(val)
                } else if (val.barcode === input.maxsulotnomishtrix) {
                    pushesh(val)
                } else if (val.name.toUpperCase().includes(input.maxsulotnomishtrix.toUpperCase())) {
                    a.push(val)
                } else if (val.barcode.includes(input.maxsulotnomishtrix)) {
                    a.push(val)
                }

            })
        }
        setComboArray(a)
    },[input.maxsulotnomishtrix])

    function ChangeBranch(e) {
        getBarcodeAndName({
            branchId: e.target.value,
            name: ''
        })
        setComboArray([])
        setarr1([])
        input.maxsulotnomishtrix = ''
        let code = {...input}
        setInput(code)
    }
    function changeCount(e, id) {
        arr1.map((item, index) => {
            if (index === id) {
                if (parseFloat(e.target.value) > (parseFloat(item.amount))) {
                    item.quantity = parseFloat(e.target.value)
                    item.totalSalePrice = item.tradedQuantity * Math.round((item.salePrice + Number.EPSILON) * 100) / 100
                    item.active = true
                    item.disabled = false
                    setCheckedWarning(true)
                    setEditCheckedWarning(true)

                } else {
                    item.quantity = parseFloat(e.target.value)
                    item.totalSalePrice = item.tradedQuantity * Math.round((item.salePrice + Number.EPSILON) * 100) / 100
                    item.disabled = false
                    item.active = false
                    setCheckedWarning(false)
                    setEditCheckedWarning(false)

                }
            }
        })
        let a = [...arr1]
        setarr1(a)
        xisobkitob()
    }

    // function editS() {
    //     if (SavdoQoshishReducer.trade) {
    //         const {branch, customer, paidSum, payDate, debtSum, payMethod, paymentStatus} = SavdoQoshishReducer.trade
    //         setValue('branchId', branch?.id)
    //         setValue('customerId', customer?.id)
    //         setValue('payDate', formatDateMinus(payDate))
    //         setValue('paidSum', paidSum)
    //         setValue('payMethodId', payMethod?.id)
    //         setValue('paymentStatusId', paymentStatus?.id)
    //         setdebtSumm(debtSum)
    //     }
    //     if (SavdoQoshishReducer.tradeProductList) {
    //         let ARRAY = []
    //         let a = 0
    //         let b = 0
    //         SavdoQoshishReducer.tradeProductList.map(item => {
    //             ARRAY.push({
    //                 productId: item.product ? item.product?.id : null,
    //                 productTypePriceId: item.productTypePrice ? item.productTypePrice?.id : null,
    //                 totalSalePrice: item.totalSalePrice,
    //                 tradedQuantity: item.tradedQuantity,
    //                 tradeProductId: item.id,
    //                 name: item.product ? item?.product?.name : item?.productTypePrice?.name,
    //                 salePrice: item.product ? item.product?.salePrice : item?.productTypePrice?.salePrice,
    //                 measurementName: item.product ? item.product?.measurement?.name : item?.productTypePrice?.product?.measurement?.name,
    //                 amount: item.remainQuantity+item.tradedQuantity,
    //                 disabled: false,
    //                 active: false,
    //                 delete: false,
    //                 type: item.type
    //             })
    //             a += item.tradedQuantity
    //             b += item.totalSalePrice
    //         })
    //         setarr1(ARRAY)
    //         setxisob(a)
    //         setjamixisob(b)
    //     }
    // }


    function deleteM(ind, tradedId) {
        if (tradedId === null) {
            arr1.map((item, index) => {
                if (index === ind) {
                    arr1.splice(index, 1)
                }
            })
        } else {
            arr1.map((item, index) => {
                if (index === ind) {
                    item.delete = true
                }
            })
        }
        let ad = [...arr1]
        setarr1(ad)


        let b = 0
        let c = 0
        arr1.filter(val => val.delete === false).map(item => {
            b += item.tradedQuantity
            c += (item.tradedQuantity * item.salePrice)

        })
        setxisob(b)
        setjamixisob(c)
    }

    const [saveModal,setSaveModal] = useState(false)



    const history = useHistory()

    function onSubmitTrade(data) {
        if(arr1.length > 0){
            if(checkedWarning){
                toast.warning('Yetarli miqdor omborda yo\'q')
            }
            else{
                saveLossProduct({
                    ...data,
                    userId:users.id,
                    lossProductDtoList:arr1
                })
                setSaveModal(true)
            }
        }
        else{
            toast.error('Mahsulot tanlang')

        }


    }

    useEffect(() => {
        if (lossReducer?.saveBoolean) {
            setarr1([])
            setComboArray([])
            setSaveModal(false)
            history.push("/headerthird/lossProducts")
        }

    }, [lossReducer.current])

    function xisobkitob() {
        let a = 0
        let c = 0
        arr1.filter(item=>item.delete===false).map(item => {
            a += item.quantity
            c += (item.quantity * Math.round((item.salePrice + Number.EPSILON) * 100)) / 100
        })
        setxisob(a)
        setjamixisob(c)
    }

    return (
        <div className="savdoQBox">
            <div className={'row mt-5'}>
                <form onSubmit={handleSubmit(onSubmitTrade)}>
                    <h5 className="mt-1 text-center mb-3">
                        {
                            match?.params.id ? t('Roles.42') : t('Sidebar1.yoqotilganmahsulot')
                        }
                    </h5>
                    <div className="col-md-12 d-flex ">
                        <div className="col-md-3 col-sm-12 mb-3">
                            <label htmlFor="">{t('ProductList.8')}</label>
                            <select {...register('branchId', {required: true})}
                                    onChange={ChangeBranch}
                                    className={'form-control'}>
                                {
                                    users.branches?
                                    users.branches.map(item => <option value={item.id}>{item.name}</option>):''
                                }
                            </select>
                        </div>
                    </div>


                    <div className="col-md-12 border mt-5 p-3">
                        <div className="row">
                            <div className="col-md-6 offset-3 p-0 position-relative">
                                <input type="text" value={input.maxsulotnomishtrix} onChange={maxsulotnomishtrix}
                                       className={'form-control'} placeholder={'Mahsulot nomi / shtrix kodini '}/>
                                {
                                    ComboArray.length !== 0 ?
                                        <div className={'combo-trade-array position-absolute z-index'}>
                                            {
                                                ComboArray.map(item =>
                                                    <p onClick={() => pushesh(item)}>
                                                        {item.name}
                                                    </p>
                                                )

                                            }
                                        </div>
                                        : ''
                                }
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className={'table mt-4'}>
                                <thead>
                                <tr>
                                    <th>{t('ProductList.1')}</th>
                                    <th>{t('Trade.12')}</th>
                                    <th>{t('Trade.13')}</th>
                                    <th>{t('Trade.14')}</th>
                                    <th>x</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    arr1.filter(val => val.delete === false).map((item, index) => <tr key={item.id}>
                                        <td><h5>{item.name}</h5></td>
                                        <td>
                                            <div className={'d-flex'}>
                                                <input value={item.quantity}
                                                       onChange={(e) => changeCount(e, index)}
                                                       type="number"
                                                       className={'form-control'}/>
                                                <input type="text" disabled={true} className={'form-control d-inline'}
                                                       value={item.measurementName}/>
                                            </div>

                                            {
                                                item.active ?
                                                    <p className={'text-danger text-center fw-2 mt-2'}>
                                                        Only {parseFloat(item.amount)} {item.measurementName} available
                                                        !</p> : ''
                                            }
                                        </td>
                                        <td>
                                            {item.salePrice}
                                        </td>
                                        <td>
                                            {item.quantity * item.salePrice}
                                        </td>
                                        <td>
                                            <button type={'button'}
                                                onClick={() => deleteM(index, item.tradeProductId ? item.tradeProductId : null)}
                                                className={'btn btn-danger'}>X
                                            </button>
                                        </td>
                                    </tr>)
                                }
                                </tbody>
                            </table>
                            <div className={'d-flex justify-content-around'}>
                                <div><h4>{t('Trade.15')} : {xisob}</h4></div>
                                <div><h4>{t('Trade.14')}: {jamixisob}</h4></div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 mt-5 border p-4">
                        <div className="row">
                            <div className="col-md-6 col-sm-12 mb-3">
                                <label htmlFor={'date'}>{t('Salary.s')}</label>
                                <input type="date"
                                       defaultValue={formatDateMinus()}
                                       {...register('date', {required: true})}
                                       className={'form-control'} id={'date'}/>
                            </div>
                        </div>
                    </div>
                    <div className={'col-md-12 mt-5 d-flex justify-content-end  p-4'}>
                        <button className={'btn btn-outline-primary px-5 py-2'} type={'submit'}>{t('Buttons.6')} </button>
                        {/*<button className={'btn btn-outline-primary m-1'}>{t('Trade.19')}</button>*/}
                    </div>
                </form>

                <ModalLoading isOpen={saveModal}/>
            </div>
        </div>
    )
}

export default connect((XaridReducer,users,lossReducer,MaxsulotlarRoyxariReducer), {
    getXaridProductType,saveLossProduct,getBarcodeAndName})(SavdoQoshish)
