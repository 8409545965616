import {connect} from "react-redux";
import MahsulotTurlariReducer, {
    deleteProductType,
    editProductType,
    getProductType,
    saveProductType
} from "../reducer/MahsulotTurlariReducer";
import {Link} from 'react-router-dom'
import CSV from '../../../../../img/CSV.png'
import Excel from '../../../../../img/Excel.png'
import Print from '../../../../../img/Print.png'
import Data from '../../../../../img/Data.png'
import Pdf from '../../../../../img/PDF.png'
import Edit from '../../../../../img/Edit.png'
import Delete from '../../../../../img/Delete.png'
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import './mahsulotturlari.css'
import users from "../../../../../reducer/users";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import Loading from "../../../../Loading";
import ModalLoading from "../../../../ModalLoading";

function MahsulotTurlari({
                             saveProductType,
                             getProductType,
                             users,
                             MahsulotTurlariReducer,
                             editProductType,
                             deleteProductType
                         }) {

    const {t} = useTranslation()
    const [input, setInput] = useState(
        {
            search: '',
            typeName: '',
            typeAmount: '',
            val: [[]],
        })

    function typeName(e) {
        input.typeName = e.target.value
        let a = {...input}
        setInput(a)
    }

    const {register, setValue, reset, handleSubmit, resetField, formState: {errors}} = useForm()

    function typeAmount(e) {
        input.typeAmount = e.target.value
        let a = {...input}
        setInput(a)
    }

    const [visible, setvisible] = useState(5)
    let [counter,setCounter] = useState(0)
    function koproq() {
        let a = MahsulotTurlariReducer.productType.length

        for (let i=0; a;i++){
            counter+=visible
            if (counter<a){
                setvisible(prev => prev + 5)
                return;
            }else {
                toast.error(`Ko'rish uchun ma'lumot yo'q`)
                return
            }
        }
    }

    function search(e) {
        input.search = e.target.value
        let a = {...input}
        setInput(a)
    }

    const [active, setActive] = useState(false)

    function toggle() {
        setActive(!active)
        input.typeName = ''
        input.val = [[]]
        let b = {...input}
    }


    function handleAdd() {
        input.val.push([])
        let a = {...input}
        setInput(a)
    }

    const [saveModal,setSaveModal] = useState(false)
    function save(data) {
        if (editId === null) {
            saveProductType({
                businessId: users.businessId,
                name: input.typeName,
                values: input.val
            })
        } else {
            editProductType({
                id:editId,
                businessId: users.businessId,
                name: input.typeName,
                values: input.val
            })
        }
        setSaveModal(true)

    }

    useEffect(()=>{
        if (MahsulotTurlariReducer.saveBoolean){
            input.typeName = ''
            input.val = [[]]
            let b = {...input}
            setInput(b)
            setEditId(null)
            toggle()
        }
        setSaveModal(false)
    },[MahsulotTurlariReducer.current])
    function handleDelete(i) {
        const deleteVal = input.val
        deleteVal.splice(i, 1)
        let a = {...input}
        setInput(a)
    }

    const [deletemodal, setdeletemodal] = useState(false)


    const [editId, setEditId] = useState(null)


    function editt(id) {
        toggle()
        setEditId(id)
        let a = MahsulotTurlariReducer.productType.filter(item => item.productTypeId === id)
        input.typeName = a[0].name
        let values = []
        a[0].values.map(item=>{
            values.push(item.name)
        })
        input.val = values
        let b = {...input}
        setInput(b)
    }
    function handleChange(onChangevalue, i) {
        const inputdate = input.val
        inputdate[i] = onChangevalue.target.value
        let a = {...input}
        setInput(a)
    }


    const [deleteID, setdeletID] = useState('')

    function deleteType() {
        deleteProductType(deleteID)
        deleteModaltoggle()
        setdeletID('')
    }

    function deleteModaltoggle(productId) {
        setdeletID(productId)
        setdeletemodal(!deletemodal)
    }

    useEffect(() => {
        getProductType()
    }, [MahsulotTurlariReducer.current])

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
    }, [MahsulotTurlariReducer.getBoolean])

    useEffect(() => {
        setLoading(false)
    }, [])



    return (
        <div className="col-md-12 mt-4 mb-4">
            <div className="textHeaderBL">
                <h2>{t('ProductType.1')}</h2>
                <p>{t('ProductType.2')}</p>
            </div>
            <div className="rowStyleBL">
                <div className="qoshish">
                    <h5>{t('ProductType.3')}</h5>
                    {
                        users.addProductType ?
                            <button className={'btn btn-primary'} onClick={toggle}>+ {t('Buttons.2')}</button> :''
                    }
                </div>
                {
                    users.viewProductType ?
                        loading?
                        <div>
                            <div className="izlashBL">
                                <div className="izlashBox1">
                                    <p>{t('Buttons.8')}</p>
                                    <select id="">
                                        <option value="">25</option>
                                    </select>
                                    <button><img src={CSV} alt=""/> Export CSV</button>
                                    <button><img src={Excel} alt=""/> Export Excel</button>
                                    <button><img src={Print} alt=""/> Print</button>
                                    <button><img src={Pdf} alt=""/>Export PDF</button>

                                </div>
                                <div className="izlashBox2">
                                    <input type="text" placeholder={t('Hamkorlar.i')} onChange={search} value={input.search}/>
                                </div>
                            </div>

                            {
                                MahsulotTurlariReducer.productType?
                                    <div className="table-responsive table-wrapper-scroll-y my-custom-scrollbar mb-4">
                                        <table className='table  table-bordered mt-4'>
                                            <thead>
                                            <tr>
                                                <th>T/R</th>
                                                <th>{t('ProductType.1')}</th>
                                                <th>{t('ProductType.2')}</th>
                                                <th>{t('Hamkorlar.amal')}</th>
                                            </tr>
                                            </thead>

                                            <tbody>
                                            {
                                                MahsulotTurlariReducer?.productType
                                                    .map((item, index) =>
                                                        <tr key={item.id}>
                                                            <td>{index + 1}</td>
                                                            <td>{item?.name}</td>
                                                            <td>
                                                                {item?.values.map(item2 =>
                                                                    <span className={'p-0 m-0'}>{item2.name}, </span>
                                                                )
                                                                }
                                                            </td>

                                                            <td>
                                                                {
                                                                    users.editProductType ?   <button className='btn btn-info' onClick={() => editt(item.productTypeId)}>
                                                                        <img
                                                                            src={Edit} alt=""/> {t('Buttons.1')}</button>:''
                                                                }
                                                                {
                                                                    users.deleteProductType ?    <button className='btn btn-danger'
                                                                                                         onClick={() => deleteModaltoggle(item.productTypeId)}>
                                                                        <img src={Delete} alt=""/> {t('Buttons.3')}</button>:''
                                                                }
                                                            </td>

                                                            <Modal isOpen={deletemodal} toggle={deleteModaltoggle}>
                                                                <ModalBody>
                                                                    <h5>{t('Buttons.12')} ?</h5>
                                                                </ModalBody>
                                                                <ModalFooter>
                                                                    <button onClick={() => deleteType()}
                                                                            className={'btn btn-outline-primary'}>{t('Buttons.3')}</button>
                                                                    <button onClick={() =>{
                                                                        setdeletemodal(!deletemodal)
                                                                        setdeletID('')
                                                                    }}
                                                                            className={'btn btn-outline-primary'}>{t('Buttons.7')}</button>
                                                                </ModalFooter>
                                                            </Modal>
                                                        </tr>)
                                            }

                                            </tbody>
                                        </table>
                                        { MahsulotTurlariReducer.productType?
                                            MahsulotTurlariReducer.productType.length > 5 ?<button onClick={koproq} className={'btn btn-outline-danger form-control'}>{t('Buttons.5')}</button>:'':''
                                        }
                                        {/*<button onClick={koproq} className={'btn btn-outline-danger form-control'}>{t('Buttons.5')}</button>*/}
                                    </div>
                                    :<div className={'text-center'}>
                                        <h4 className={'text-center'}>{MahsulotTurlariReducer.message || t('Darslik.mtt')}</h4>
                                    </div>
                            }

                        </div>:<Loading/> :''
                }


                <Modal isOpen={active} toggle={toggle}>
                    <ModalHeader>
                        {t('pro. Mahsulotturini')}
                    </ModalHeader>
                    <ModalBody>
                        <label htmlFor="xl">{t('pro.turnomi')}</label>
                        <input value={input.typeName} onChange={typeName} type="text" className={'form-control'}
                               id={'xl'}/>

                        <div className={'mt-1'}>
                            {
                                    input.val.map((data, i) => {
                                        return (
                                            <div className={'d-flex'}>
                                                <input value={data} className={'form-control mt-2'} type="text"
                                                       onChange={e => handleChange(e, i)}/>
                                                {
                                                    i === 0 ? <button onClick={handleAdd}
                                                                      className={'btn mt-2 mr-1 btnLeft btn-primary'}>+</button>
                                                        : editId === null ? <button className={'btn mt-2 btnLeft mr-1 btn-danger'}
                                                                  onClick={() => handleDelete(i)}>x
                                                        </button> :''
                                                }
                                                {

                                                }

                                            </div>

                                        )
                                    })

                            }
                        </div>

                    </ModalBody>
                    <ModalFooter>
                        <button className={'btn btn-primary'} onClick={save}>{t('Buttons.6')}</button>
                        <button className={'btn btn-outline-primary'} onClick={() => {
                            toggle()
                            setEditId(null)
                            input.typeName = ''
                            input.val = [[]]
                            let a = {...input}
                            setInput(a)
                        }}>{t('Buttons.7')}</button>
                    </ModalFooter>
                </Modal>

                <ModalLoading isOpen={saveModal}/>
            </div>
        </div>)
}

export default connect((MahsulotTurlariReducer, users), {
    getProductType, saveProductType, editProductType, deleteProductType
})(MahsulotTurlari)
