import "../../Maxsulotlar/MahsulotlarRuyxati/maxsulotlarRoyxati.css"
import {Link, useHistory} from "react-router-dom"
import React from "react";
import {connect} from 'react-redux'
import {useEffect, useState} from 'react'
import MaxsulotlarRoyxariReducer, {
    getProductFilter,
    getProductProduction,
} from "../../Maxsulotlar/reducer/MaxsulotlarRoyxariReducer";
import branchreducer, {getbranch} from "../../../../../reducer/branchreducer";
import {useTranslation} from "react-i18next";
import {
    Box,
    Divider, IconButton,
    InputAdornment, styled, Table,
    TableBody,
    TableCell,
    TableContainer, TableHead, TablePagination,
    TableRow, TextField, Tooltip,
    Typography, useTheme
} from "@mui/material";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import Loading from "../../../../Loading";
import CSV from "../../../../../img/CSV.png";
import {BaseUrl} from "../../../../../middleware";
import Excel from "../../../../../img/Excel.png";
import Print from "../../../../../img/Print.png";
import Pdf from "../../../../../img/PDF.png";
import formatDate, {formatDateWithTime} from "../../../../../util";
import {DatePicker} from 'antd'
import balanceReducer, {getBalance, getTradeHistory} from "../../../../../reducer/balanceReducer";
import users from "../../../../../reducer/users";
import formatDateYear from "../../../../../reducer/sana";
const {RangePicker} = DatePicker

const TableRowDivider = styled(TableRow)(
    ({ theme }) => `
    height: ${theme.spacing(2)};
  `
);

const LabelSuccess = styled(Box)(
    ({ theme }) => `
    display: inline-block;
    background: ${theme.colors.success.lighter};
    color: ${theme.colors.success.main};
    text-transform: uppercase;
    font-size: ${theme.typography.pxToRem(11)};
    font-weight: bold;
    padding: ${theme.spacing(1, 2)};
    border-radius: ${theme.general.borderRadiusSm};
  `
);

const LabelError = styled(Box)(
    ({ theme }) => `
    display: inline-block;
    background: ${theme.colors.error.lighter};
    color: ${theme.colors.error.main};
    text-transform: uppercase;
    font-size: ${theme.typography.pxToRem(11)};
    font-weight: bold;
    padding: ${theme.spacing(1, 2)};
    border-radius: ${theme.general.borderRadiusSm};
  `
);

const LabelWarning = styled(Box)(
    ({ theme }) => `
    display: inline-block;
    background: ${theme.colors.warning.lighter};
    color: ${theme.colors.warning.main};
    text-transform: uppercase;
    font-size: ${theme.typography.pxToRem(11)};
    font-weight: bold;
    padding: ${theme.spacing(1, 2)};
    border-radius: ${theme.general.borderRadiusSm};
  `
);

const IconButtonWrapper = styled(IconButton)(
    ({ theme }) => `
    transition: ${theme.transitions.create(['transform', 'background'])};
    transform: scale(1);
    transform-origin: center;

    &:hover {
        transform: scale(1.1);
    }
  `
);

const TableHeadWrapper = styled(TableHead)(
    ({ theme }) => `
      .MuiTableCell-root {
          text-transform: none;
          font-weight: normal;
          color: ${theme.colors.alpha.black[100]};
          font-size: ${theme.typography.pxToRem(16)};
          padding: ${theme.spacing(2)};
      }

      .MuiTableRow-root {
          background: transparent;
      }
  `
);

const SearchInputWrapper = styled(TextField)(
    ({ theme }) => `
    background: ${theme.colors.alpha.white[100]};
    border-radius: ${theme.general.borderRadius};

    .MuiInputBase-input {
        font-size: ${theme.typography.pxToRem(16)};
    }
  `
);

function Balance({

                     match,
                     getProductFilter,
                     branchreducer,
                     getBalance, balanceReducer,
                     getbranch,
                     getTradeHistory,
                     getProductProduction,
                     users,
                     MaxsulotlarRoyxariReducer,
                 }) {

    const [input, setInput] = useState(
        {
            baza: null,
            sotvemas: '',
            name: '',
            check: '',
        }
    )
    const theme = useTheme();
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false)
    const {t} = useTranslation()
    const [query, setQuery] = useState(null);
    const [deadlineFilter, setDeadlineFilter] = useState(null)
    const [projectIdFilter, setProjectIdFilter] = useState(null)
    const [projectTypeFilter, setProjectTypeFilter] = useState(null)
    const [statusIdFilter, setStatusIdFilter] = useState(null)
    const [activeFilter, setActiveFilter] = useState(false)
    const [limit, setLimit] = useState(10);
    const [mainBranchId, setMainBranchId] = useState(null)
    function changefirma(e) {
        setInput((prev) => ({...prev, firma: e.value}))
        getProductFilter({
            businessId:users.businessId,
            params:{
                brand_id:e.value === "ALL" ? null :e.value,
                branch_id:input.baza === "ALL" ? null : input.baza,
                categoryId:input.bolim === 'ALL' ? null : input.bolim
            }
        })
    }

    function changebaza(e) {
        setInput((prev) => ({...prev, baza: e.value}))
        getProductFilter({
            businessId:users.businessId,
            params:{
                branch_id:e.value === "ALL" ? null :e.value,
                brand_id:input.firma === "ALL" ? null : input.firma,
                categoryId:input.bolim === 'ALL' ? null : input.bolim
            }

        })
    }

    useEffect(() => {
        getProductFilter({
            businessId:users.businessId,
            branchId:null,
            brandId:null
        })

        getbranch(users.businessId)
    }, [])

    const handleQueryChange = (event) => {
        event.persist();
        setQuery(event.target.value);
        setStatusIdFilter(null)
        setProjectIdFilter(null)
        setProjectTypeFilter(null)
        setActiveFilter(false)

        getProductProduction({
            branchId: mainBranchId ? mainBranchId : users.branchId,
            productId: productId?productId: match.params.id,
            params: {
                page: page,
                size: limit,
            }
        })
    };

    const handlePageChange = (_event, newPage) => {
        setPage(newPage);

        getTradeHistory({
            branchId: mainBranchId?mainBranchId:users.branchId,
            params:{
                startDate: startDateValue,
                endDate: endDateValue,
                page: newPage,
                size: limit
            }
        })
    };

    const handleLimitChange = (event) => {
        setLimit(parseInt(event.target.value));
        getTradeHistory({
            branchId: mainBranchId?mainBranchId:users.branchId,
            params:{
                startDate: startDateValue,
                endDate: endDateValue,
                page: page,
                size: parseInt(event.target.value)
            }
        })

    };

    const [startDateValue, setStartDateValue] = useState(null)
    const [endDateValue, setEndDateValue] = useState(null)


    useEffect(()=>{
        getTradeHistory({
            branchId: mainBranchId?mainBranchId:users.branchId,
            params:{
                startDate: startDateValue,
                endDate: endDateValue,
                page: page,
                size: limit
            }
        })
    },[])


    let baza = branchreducer.branch ? branchreducer.branch?.map(item => ({value: item.id,label: item.name})) : ''
    const bazaOptions = [{value: "ALL",label: 'Barchasi'},...baza]

    const [productId, setProductId] = useState(null)




    return (
        <div className="col-md-12 mt-4 mb-4">

            <div className="rowStyleMax colorback">
                <div className="qoshish">
                    <h5>{t('Buttons.16')}</h5>
                </div>

                <div className="d-flex justify-content-between align-items-center">
                    <div className="col-md-6">
                        {
                            users.branches?
                                <div className="minBox">
                                    <select value={mainBranchId} onChange={(e)=>{
                                        setMainBranchId(e.target.value)
                                        setPage(0)
                                        setLimit(10)
                                        setStartDateValue(null)
                                        setEndDateValue(null)
                                        getTradeHistory({
                                            branchId: e.target.value,
                                            params:{
                                                startDate: null,
                                                endDate: null,
                                                page: page,
                                                size: limit
                                            }
                                        })
                                    }} className={'form-control'} >
                                        {
                                            users.branches.map((item,index)=> <option value={item.id}>{item.name}</option>)
                                        }
                                    </select>
                                </div>:<div><h4 className={'text-center'}>NOT FOUND BRANCH</h4></div>
                        }
                    </div>
                    <div className={'col-md-6'} style={{marginTop:'-30px'}}>
                        <RangePicker onChange={(values) => {
                            let a = []
                            values.map(item => {
                                a.push(formatDateYear(item))

                                getTradeHistory({
                                    branchId: mainBranchId?mainBranchId:users.branchId,
                                    params:{
                                        startDate: a[0],
                                        endDate: a[1],
                                        page: page,
                                        size: limit
                                    }
                                })
                            })}
                        }/>
                    </div>

                </div>
                <>
                    <Divider />
                    {
                        // users.viewProductAdmin || users.viewProduct ?
                        loading? (
                            <Loading/>
                        ) : (
                            balanceReducer.tradeHistory?
                                <Box pb={3}>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>#</TableCell>
                                                    <TableCell>{t('Hamkorlar.tu')}</TableCell>
                                                    <TableCell align={'center'}>{t('Lids.sana')}</TableCell>
                                                    <TableCell align={'center'}>{t('trade.us')}</TableCell>
                                                    <TableCell align={'center'}>{t('trade.hs')}</TableCell>
                                                    <TableCell align={'center'}>{t('block.summasi')}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    balanceReducer.tradeHistory?.allBalanceHistory.map((item,index)=><TableRow key={index}>
                                                        <TableCell>{index+1}</TableCell>
                                                        <TableCell>{item?.payMethodType}</TableCell>
                                                        <TableCell align={'center'}>{formatDateWithTime(item.date)}</TableCell>
                                                        <TableCell align={'center'}>{item?.totalSumma}</TableCell>
                                                        <TableCell align={'center'}>{item?.accountSumma}</TableCell>
                                                        <TableCell align={'center'}>{item.plus===false?<p style={{color:'red'}}>{item?.summa}</p>:item.summa}</TableCell>
                                                    </TableRow>)
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                    <Box pt={1} display="flex" justifyContent="space-between">
                                        <div className="print">
                                            <button><img src={CSV} alt=""/> Export CSV</button>
                                            {
                                                users.getExcel ?
                                                    <a href={`${BaseUrl}/excel/export-to-excel/`+users.businessId} download><button><img src={Excel} alt="excel"/>Export Excel</button></a>:''}
                                            <button><img src={Print} alt=""/> Print</button>
                                            <button><img src={Pdf} alt=""/>Export PDF</button>
                                        </div>
                                        <TablePagination
                                            component="div"
                                            count={balanceReducer.tradeHistory?.totalItems}
                                            page={page}
                                            onPageChange={handlePageChange}
                                            rowsPerPage={limit}
                                            rowsPerPageOptions={[5, 10, 15,20]}
                                            onRowsPerPageChange={handleLimitChange}
                                        />
                                    </Box>
                                </Box>:<div><h4 className={'text-center'}>{t('Darslik.mtt')}</h4></div>
                        )

                    }
                </>
            </div>
        </div>
    )
}

export default connect((MaxsulotlarRoyxariReducer, users, branchreducer,balanceReducer), {
    getProductFilter,
    getbranch,
    getBalance,
    getTradeHistory,
    getProductProduction,
})(Balance)
