function Eslatma(){
    return(
        <div className={'row'}>
            <div className="col-md-12">
                <table className={'table mt-3'}>
                    <thead>
                    <tr>
                        <th>Sana</th>
                        <th>Name</th>
                        <th>Summa</th>
                        <th>Amallar</th>
                    </tr>
                    </thead>
                    <tbody>

                    </tbody>
                </table>
            </div>
        </div>
    )
}
export default Eslatma