import {connect} from "react-redux";
import {useHistory, useLocation} from 'react-router-dom'
import {useTranslation} from "react-i18next";
import {Tabs} from "antd";

import BarchaSavdolar from "./BarcaSavdolar/BarchaSavdolar";
import Balance from "./Balance/Balance";
import SavdoQoshish from "./SavdoQoshish/SavdoQoshish";
import LossProducts from "./Yoqotilgan Mahsulotlar/LossProducts";
import MijozlarGuruxi from "../Hamkorlar/MijozGurux/MijozlarGuruxi";
import MijozGuruhlari from "../Hamkorlar/MijozGuruhlari/MijozGuruhlari";
import users from "../../../../reducer/users";

import './savdo.css'

function Savdo({users}) {
    const {t} = useTranslation()
    const history = useHistory()
    const location = useLocation()

    const items = [
        {
            label: t('Sidebar.19'),
            key: '/main/trade',
            children: <BarchaSavdolar/>,
        },
        // {
        //     label: `Savo`,
        //     key: '2',
        //     children: <Lavozimlar/>,
        // },
        {
            label: t('Sidebar1.mahsulotyuqotish'),
            key: '/main/trade/addLossProducts',
            children: <SavdoQoshish/>,
        },
        {
            label: t('Sidebar1.yoqotilganmahsulot'),
            key: '/main/trade/lossProducts',
            children: <LossProducts/>,
        },
        {
            label: `Kassa`,
            key: '/main/trade/balance',
            children: <Balance/>,
        },
        {
            label: t("Hamkorlar.mijozlar"),
            key: '/main/trade/customer',
            children: <MijozlarGuruxi/>,
        },
        {
            label: t("Sidebar.8"),
            key: '/main/trade/customerGroup',
            children: <MijozGuruhlari/>,
        },
    ]

    const onChange = (key) => {
        history.push(key)
    };

    return (
        <div className={'row list'} id={'savdo'}>
            <h4 className={'p-3 mainText'}>{t('Sidebar.18')}</h4>
            <div className={'px-3'}>
                <Tabs
                    activeKey={location.pathname}
                    onChange={onChange}
                    type="card"
                    items={items}
                />
            </div>
        </div>
    )
}

export default connect((users), {})(Savdo)
