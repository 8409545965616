import './xarid.css'
import {useEffect, useState} from "react";
import {ModalBody, ModalHeader, ModalFooter, Modal} from "reactstrap";
import {connect} from "react-redux";
import XaridReducer, {
    getXarid5,
    saveXarid,
    editXarid,
    getXaridProductType
} from '../reducer/XaridReducer'
import tolovreducer, {gettolovholati} from "../../../../../reducer/tolovreducer";
import users from "../../../../../reducer/users";
import {useHistory} from 'react-router-dom'
import {toast} from "react-toastify";
import TaminotReducer, {
    getTaminot,
    saveTaminot
} from "../../Hamkorlar/reducer/TaminotReducer";
import branchreducer, {getbranch} from "../../../../../reducer/branchreducer";
import kgreducer, {getkg} from "../../../../../reducer/kgreducer";
import PayReducer, {getPay} from "../../../../../reducer/PayReducer";
import ExchangeStatusR, {getOtkazmaStatus} from "../../../../../reducer/ExchangeStatusR";
import {useTranslation} from "react-i18next";
import {ImCancelCircle} from "react-icons/im";
import {useForm} from "react-hook-form";
import ModalLoading from "../../../../ModalLoading";
import ValyutaReducer, {getValyuta} from "../../Settings/DukonSozlamalari/reducers/ValyutaReducer";
import payReducer from "../../../../../reducer/PayReducer";

function Xarid({
                   ValyutaReducer,
                   getValyuta,
                   ExchangeStatusR,
                   getOtkazmaStatus,
                   getXarid5,
                   PayReducer,
                   saveXarid,
                   getXaridProductType,
                   getkg,
                   getTaminot,
                   saveTaminot,
                   editXarid,
                   XaridReducer,
                   users,
                   match,
                   getPay,
                   TaminotReducer, getbranch, tolovreducer, gettolovholati,
               }) {

    const {t} = useTranslation()
    const [active2, setActive2] = useState(false);

    const [input, setInput] = useState(
        {
            diller: '',
            qisqaeslatma: '',
            baza: null,
            xaridsanasi: '',
            tulovmuddati: '',
            xaridstatusi: '',
            //    ------tulov qilish
            tulovusuli: '',
            paidon: '',
            eslatma: '',
            //    ----- yetkazib berish
            yetkazibberishnarxi2: 0,
            editable: false,
            paidSum: '',
            paidDollar: ''
        }
    )

    const {register, reset, handleSubmit, resetField, formState: {errors}} = useForm()
    const [placeholders, setPlaceholders] = useState(
        {
            qisqaeslatmaPlaceholder: "",
            xaridMiqdoriPlaceholder: "",
            donaNarxiPlaceholder: "",
            donaSotishNarxiPlaceholder: "",
            tolovSummasiPlaceholder: "",
            yetkazibBerishManziliPlaceholder: "",
            yetkazibBerishNarxiPlaceholder: "",
            dillerSelectStyle: "",
            xaridStatusiSelectStyle: "",
            tolovUsuliSelectStyle: "",
            tolovStatusiSelectStyle: "",
            bazaSelectStyle: "",
            xaridSanasiSelectStyle: "",
        }
    )

    const [xisob, setXisob] = useState(0)
    const [jamixisobSum, setjamiXisobSum] = useState(0)
    const [jamixisobDollar, setjamiXisobDollar] = useState(0)

    function diller(e) {
        input.diller = e.target.value
        let a = {...input}
        setInput(a)
    }

    function qisqaeslatma(e) {
        input.qisqaeslatma = e.target.value
        let a = {...input}
        setInput(a)
    }

    function baza(e) {
        input.baza = e.target.value
        let a = {...input}
        getXaridProductType(e.target.value)
        setInput(a)
    }

    function xaridstatusi(e) {
        input.xaridstatusi = e.target.value
        let a = {...input}
        setInput(a)
    }

    function paidon(e) {
        input.paidon = e.target.value
        let a = {...input}
        setInput(a)
    }

    function eslatma(e) {
        input.eslatma = e.target.value
        let a = {...input}
        setInput(a)
    }


    function yetkazibberishnarxi2(e) {
        input.yetkazibberishnarxi2 = e.target.value
        let a = {...input}
        setInput(a)
    }

    function toggle() {
        setActivesupplier(!activesupplier)
    }

    function toggle2() {
        setActive2(!active2)
    }

    const [XaridArrayPost, setXaridArrayPost] = useState([])
    const [XaridArray, setXaridArray] = useState([])
    const [XaridSearchValue, setXaridSearchValue] = useState('')

    function XaridSearch(e) {
        let a = []
        setXaridSearchValue(e.target.value)
        XaridReducer.xaridMahsulot?.filter(val => {
            if (e.target.value === '') {
                setXaridArray([])
            } else if (val.name === e.target.value) {
                AddXaridArray(val)
            } else if (val.barcode === e.target.value) {
                AddXaridArray(val)
            } else if (val.name.toUpperCase().includes(e.target.value.toUpperCase())) {
                a.push(val)
            } else if (val.barcode.includes(e.target.value)) {
                a.push(val)
            }
        })
        setXaridArray(a)
    }

    useEffect(() => {
        let totalSum = 0
        let totalSumDollar = 0
        let totalQuantity = 0
        XaridArrayPost.filter(val => val.delete === false).map(item => {
                if (item.buyDollar) {
                    totalQuantity += parseFloat(item.purchasedQuantity)
                    totalSumDollar += item.purchasedQuantity * item.buyPriceDollar;
                } else {
                    totalQuantity += parseFloat(item.purchasedQuantity)
                    totalSum += item.purchasedQuantity * item.buyPrice;
                }
            }
        )
        setjamiXisobSum(totalSum)
        setjamiXisobDollar(totalSumDollar)
        setXisob(totalQuantity)
    }, [XaridArrayPost]);

    function ComboChangeAmount(e, index, isDollar) {
        let b = XaridArrayPost
        b[index][e.target.name] = e.target.value
        if (e.target.name === 'buyPrice') {
            if (isDollar) {
                b[index].buyPriceDollar = e.target.value
                b[index].buyPrice = e.target.value * ValyutaReducer.currency?.course
            } else {
                b[index].buyPrice = e.target.value
                b[index].buyPriceDollar = (e.target.value / ValyutaReducer.currency?.course)
            }
        }
        if (e.target.name === 'salePrice') {
            if (isDollar) {
                b[index].salePriceDollar = e.target.value
                b[index].salePrice = e.target.value * ValyutaReducer.currency?.course

            } else {
                b[index].salePrice = e.target.value
                b[index].salePriceDollar = (e.target.value / ValyutaReducer.currency?.course)
            }
        }
        b[index].totalDollar = parseFloat(b[index].purchasedQuantity * b[index].buyPriceDollar)
        b[index].totalSum = parseFloat(b[index].purchasedQuantity * b[index].buyPrice)
        let a = [...XaridArrayPost]
        setXaridArrayPost(a)

    }

    function ComboChangeChecked(e, index) {
        let b = XaridArrayPost
        b[index][e.target.name] = e.target.checked
        let a = [...XaridArrayPost]
        setXaridArrayPost(a)
    }

    function AddXaridArray(item) {
        setXaridArray([])
        setXaridSearchValue('')
        let a = XaridArrayPost
        let order = false
        let purchase = false
        XaridArrayPost?.map(val => {
            if (item.productTypePriceId === null && val.productId === item.productId) {
                if (item.purchaseProductId) {
                    item.delete = false
                    purchase = true
                    order = true
                }
                order = true
            } else if (item.productId === null && val.productTypePriceId === item.productTypePriceId) {
                if (item.purchaseProductId) {
                    item.delete = false
                    purchase = true
                    order = true
                }
                order = true
            }
        })
        if (order === true) {
            if (purchase === false) {
                toast.warning('Mahsulot jadvalda bor')
            }
        } else {
            a.push({
                buyPrice: item.buyPrice,
                buyPriceDollar: item.buyPriceDollar,
                buyDollar: false,
                salePrice: item.salePrice,
                salePriceDollar: item.salePriceDollar,
                salePriceDollarBoolean: false,
                totalSum: 0,
                totalDollar: 0,
                productId: item.productId,
                productTypePriceId: item.productTypePriceId,
                purchaseProductId: null,
                purchasedQuantity: 0,
                delete: false,
                amount: item.amount,
                name: item.name,
                measurement: item.measurementName
            })
        }

        setXaridArrayPost(a)
    }

    const [deleteEdit, setDeleteEdit] = useState(false)

    function DeleteXaridArrayPost(indx, purchasesId) {
        if (purchasesId === null) {
            XaridArrayPost?.map((item, index) => {
                if (indx === index) {
                    XaridArrayPost.splice(index, 1)
                }
            })
        } else {
            XaridArrayPost?.map((item, index) => {
                if (index === indx) {
                    item.delete = true
                }
            })
        }
        let b = [...XaridArrayPost]
        setXaridArrayPost(b)
        setXaridArray([])
        setXaridSearchValue('')
    }

    const dateObj = new Date();
    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObj.getDate()).slice(-2);
    const year = dateObj.getFullYear();

    const shortDate = `${year}-${month}-${day}`;

    useEffect(() => {
        input.paidon = shortDate
        let a = {...input}
        setInput(a)
    }, [])

    useEffect(() => {
        getPay(users.businessId)
        getValyuta(users.businessId)
        getXaridProductType(users.branchId)
        if (match?.params.id) {
            getXarid5(match.params.id)
        }
        getXarid5(match?.params.id)
    }, [])

    useEffect(() => {
        if (match?.params.id) {
            editX()
        }
    }, [XaridReducer.getBoolean])

    const [sellerId, setSellerId] = useState('')

    function editX() {
        const {
            deliveryPrice, branch, paidSum, paidDollar, paymentStatus, supplier, seller,
            totalSum, totalDollar, purchaseStatus, date, description, editable
        } = XaridReducer.xaridOne
        input.yetkazibberishnarxi2 = deliveryPrice
        input.xaridstatusi = purchaseStatus?.id
        input.xaridsanasi = date
        input.qisqaeslatma = description
        input.editable = editable
        setSellerId(seller?.id)
        input.baza = branch?.id
        input.eslatma = paymentStatus?.id
        input.diller = supplier?.id
        input.paidSum = paidSum
        input.paidDollar = paidDollar
        setjamiXisobSum(totalSum)
        setjamiXisobDollar(totalDollar)
        let array = XaridReducer.xaridProductList
        let a = []
        array?.map(item => {
              const buyDollar = item.product ? item?.product?.buyDollar : item?.productTypePrice?.product?.buyDollar
            a.push({
                buyPrice: item.buyPrice,
                buyPriceDollar: item.buyPriceDollar,
                buyDollar: buyDollar,
                salePriceDollarBoolean: item.product ? item?.product?.saleDollar : item?.productTypePrice?.product?.saleDollar,
                salePrice: item.salePrice,
                salePriceDollar: item.salePriceDollar,
                totalSum: item.totalSum,
                totalDollar: item.totalDollar,
                productId: item?.product ? item?.product?.id : null,
                productTypePriceId: item?.productTypePrice ? item?.productTypePrice?.id : null,
                purchaseProductId: item.id,
                purchasedQuantity: item.purchasedQuantity,
                delete: DeleteXaridArrayPost ? deleteEdit : false,
                name: item.product ? item?.product?.name : item?.productTypePrice?.name,
                measurement: item.product ? item.product?.measurement?.name : item?.productTypePrice?.product?.measurement?.name
            })
        })
        console.log(a)
        setXaridArrayPost(a)
    }

    function saqla() {

        if (input.avans > jamixisobDollar) {
            toast.warning(`Siz tan narxdan kop summa kiritdingiz`)
        } else {
            if (match.params.id === undefined) {
                saveXarid(
                    {
                        supplerId: input.diller,
                        seller: users.id,
                        purchaseStatusId: input.xaridstatusi,
                        paymentStatusId: input.eslatma,
                        branchId: input.baza === null ? users.branchId : input.baza,
                        date: input.paidon,
                        paymentMethodId: input.tulovusuli,
                        totalSum: jamixisobSum,
                        totalDollar: jamixisobDollar,
                        description: input.qisqaeslatma,
                        deliveryPrice: input.yetkazibberishnarxi2,
                        purchaseProductsDto: XaridArrayPost,
                        debtDollar: jamixisobDollar - input.paidDollar,
                        debtSum: jamixisobSum - input.paidSum,
                        paidSum: parseFloat(input.paidSum),
                        paidDollar: parseFloat(input.paidDollar)
                    }
                )


            } else {
                editXarid(
                    {
                        branchId: input.baza,
                        date: input.xaridsanasi,
                        deliveryPrice: input.yetkazibberishnarxi2,
                        description: input.qisqaeslatma,
                        paymentStatusId: input.eslatma,
                        purchaseProductsDto: XaridArrayPost,
                        paymentMethodId: input.tulovusuli,
                        purchaseStatusId: input.xaridstatusi,
                        seller: sellerId,
                        supplerId: input.diller,
                        totalSum: jamixisobSum,
                        totalDollar: jamixisobDollar,
                        id: match.params.id,
                        debtDollar: jamixisobDollar - input.paidDollar,
                        debtSum: jamixisobSum - input.paidSum,
                        paidSum: parseFloat(input.paidSum),
                        paidDollar: parseFloat(input.paidDollar)
                    })
            }
        }
        if (input.avans <= jamixisobDollar) {
            setSaveModal(true)
        }


    }

    const [activesupplier, setActivesupplier] = useState(false);
    const [changedtype, setChangedType] = useState('false')


    useEffect(() => {
        getTaminot(users.businessId)
        getOtkazmaStatus()
        getkg(users.businessId)
    }, [TaminotReducer.current])
    useEffect(() => {
        getbranch(users.businessId)
    }, [])
    useEffect(() => {
        gettolovholati()
    }, [])

    function changeType(e) {
        setChangedType(e.target.value)
    }

    function toggleEmpty() {
        setActivesupplier(!activesupplier)
        resetField('name', '')
        resetField('inn', '')
        resetField('telegram', '')
        resetField('phoneNumber', '')
        resetField('companyName', '')
    }

    const history = useHistory()
    const [saveModal, setSaveModal] = useState(false)

    useEffect(() => {
        if (XaridReducer.saveBoolean) {
            history.push('/main/purchase/table')
            input.avans = 0
            input.yetkazibberishnarxi2 = 0
            let a = {...input}
            setInput(a)
        }
        // toggle()
        setSaveModal(false)
    }, [XaridReducer.current])

    function onSubmit(data) {
        saveSupplier(data)
        reset('')
        toast.success(t('Toast.3'))
    }

    function saveSupplier(data) {
        saveTaminot(
            {
                ...data, juridical: changedtype === 'true', businessId: users.businessId
            }
        )
        toggle()
    }

    return (
        <div className='p-4 xarid rounded-5'>
            <div className='bg-white p-4'>
                <h5 className={'text-center mt-3'}>{t('Purchase.10')}</h5>
                <div className="row">
                    <div className='col-4 col-sm-12 col-md-6'>
                        <div className={'d-flex'}>
                            <div style={{width: '100%'}}>
                                <label htmlFor={'dil'}>{t('Purchase.2')}</label>
                                {
                                    <select name="" value={input.diller} onChange={diller} id={'dil'}
                                            className={'form-control'}>
                                        {
                                            TaminotReducer.taminot ?
                                                TaminotReducer.taminot?.map(item =>
                                                    input.diller == '' ? input.diller = item.id
                                                        : <option value={item.id}>{item.name}</option>) : ''
                                        }
                                    </select>
                                }
                                {
                                    input.diller ? "" :
                                        <p style={{
                                            color: 'red',
                                            marginTop: '4px'
                                        }}>{placeholders.dillerSelectStyle}</p>
                                }
                            </div>
                            <h4 style={{cursor: 'pointer', marginTop: '25px', fontSize: '30px'}}
                                onClick={toggle}>+</h4>
                        </div>
                    </div>
                    <div className="col-4 col-sm-12 col-md-6">
                        <label htmlFor={'qisqa'}>{t('Buttons.17')}</label>
                        <input type="text" className={'form-control'} value={input.qisqaeslatma}
                               placeholder={placeholders.qisqaeslatmaPlaceholder} onChange={qisqaeslatma}
                               id={'qisqa'}/>
                    </div>
                    <div className="col-4 col-sm-12 col-md-6">
                        <label htmlFor={'baza'}>{t('ProductList.8')}</label>
                        <select name="" id={'baza'} value={input.baza} onChange={baza} className={'form-control'}>
                            {/*<option value="">Tanlash</option>*/}
                            {
                                users.branches?.map(item =>
                                    input.baza == '' ? input.baza = item.id :
                                        <option value={item.id}>{item.name}</option>)
                            }
                        </select>
                        {
                            input.baza ? "" :
                                <p style={{
                                    color: 'red',
                                    marginTop: '4px',
                                    lineHeight: '13px'
                                }}>{placeholders.bazaSelectStyle}</p>
                        }
                    </div>
                    <div className="col-4 col-sm-12 col-md-6">
                        <label htmlFor={'status'}>{t('Purchase.3')}</label>
                        <select name="" required={'required'} value={input.xaridstatusi} onChange={xaridstatusi}
                                className={'form-control'}
                                id={'status'}>
                            {
                                ExchangeStatusR.exchangestatus?.map(item =>
                                    input.xaridstatusi == '' ? input.xaridstatusi = item.id :
                                        <option value={item.id}>{item.status}</option>)
                            }
                        </select>
                        {
                            input.xaridstatusi ? "" :
                                <p style={{
                                    color: 'red',
                                    marginTop: '4px',
                                    lineHeight: '13px'
                                }}>{placeholders.xaridStatusiSelectStyle}</p>
                        }
                    </div>
                </div>
                {activesupplier ?
                    <Modal isOpen={activesupplier} toggle={toggle}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <ModalHeader>
                                {t('Supplier.4')}
                            </ModalHeader>
                            <ModalBody>
                                <div className="row">
                                    <div className="in col-md-12 justify-content-between d-flex col-sm-12 mb-2">
                                        <span style={{width: '100%'}}>
                                            <label htmlFor="">{t('Supplier.9')}</label>
                                            <select value={changedtype} className={'form-control'}
                                                    onChange={changeType}>
                                                <option value={'false'}>{t("Supplier.10")}</option>
                                                <option value={'true'}>{t("Supplier.11")}</option>
                                            </select>
                                        </span>
                                        {
                                            changedtype === 'true' ?
                                                <div className="col-md-6 col-sm-12 mb-2">
                                                    <label htmlFor={'log1'}>INN</label>
                                                    <input type="text"
                                                           {...register('inn', {required: true})}
                                                           placeholder={errors.inn ? errors.inn?.type === "required" && "INN is required" : 'inn'}
                                                           defaultValue={''}
                                                           className={'form-control plcolor'}
                                                           id={'log1'}/>
                                                </div> : ''
                                        }
                                    </div>
                                    <div className={'col-md-12'}>
                                        {
                                            changedtype === 'true' ?
                                                <div style={{width: "100%"}}>
                                                    <label htmlFor={'ism'}>{t("Supplier.12")}</label>
                                                    <input type="text" id={'ism'}
                                                           {...register('name', {required: true})}
                                                           placeholder={errors.name ? errors.name?.type === "required" && "Name is required" : 'name'}
                                                           defaultValue={''}
                                                           className={'form-control'}
                                                           required="required"/>
                                                </div> :
                                                <div>
                                                    <label htmlFor={'ism'}>{t('Employ.8')}</label>
                                                    <input type="text" id={'ism'}
                                                           {...register('name', {required: true})}
                                                           placeholder={errors.name ? errors.name?.type === "required" && "Name is required" : 'name'}
                                                           defaultValue={''}
                                                           className={'form-control'}
                                                           required="required"/>
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className={'col-md-6 col-sm-12 mb-3'}>
                                        <label htmlFor={'ot'}>{t('Supplier.6')}</label>
                                        <input
                                            {...register('telegram', {required: true})}
                                            placeholder={errors.telegram ? errors.telegram?.type === "required" && "Telegram is required" : 'telegram'}
                                            id={'telegram'} type="text"
                                            className={'form-control'} required={"required"}/>
                                    </div>
                                    <div className={'col-md-6 col-sm-12 mb-3'}>
                                        <label htmlFor={'ot'}>{t('Supplier.7')}</label>
                                        <input type="text"
                                               {...register('phoneNumber', {required: true})}
                                               placeholder={errors.phoneNumber ? errors.phoneNumber?.type === "required" && "Phone Num is required" : 'phoneNumber'}
                                               className={'form-control'} required={"required"}/>
                                    </div>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <button className={'btn btn-outline-primary'}
                                        type={"submit"}
                                >SAVE
                                </button>
                                <button type={"button"} className={'btn btn-outline-primary'}
                                        onClick={toggleEmpty}>{t('Buttons.7')}</button>
                            </ModalFooter>
                        </form>
                    </Modal> : ''
                }
                <div className="row">
                    <div className="col-md-12">
                        <input type="text"
                               value={XaridSearchValue} onChange={XaridSearch}
                               className={'form-control'}
                               placeholder={t('block.mskyn')}/>
                        {
                            XaridArray.length !== 0 ?
                                <div className={'Combo-array'}>
                                    {
                                        XaridArray?.map(item =>
                                            <p onClick={() => AddXaridArray(item)}>
                                                {item.name}
                                            </p>
                                        )
                                    }
                                </div>
                                : ''
                        }

                        <div className="table-responsive">
                            <table className={'table mt-3 border'}>
                                <thead>
                                <tr>
                                    <th>{t('ProductEdit.2')}</th>
                                    <th>{t('Purchase.20')}</th>
                                    <th>{t('Purchase.21')}</th>
                                    <th>{t('Purchase.22')}</th>
                                    <th>{t('ProductList.12')}</th>
                                    <th>x</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    XaridArrayPost.filter(val => val.delete === false)?.map((item, index) =>
                                        <tr className={'text-center'}>
                                            <td>
                                                <div>
                                                    <h4>{item.name}</h4>
                                                    <p>{item.amount} {item.measurement}</p>
                                                </div>
                                            </td>
                                            <td>
                                                <div className={'d-flex'}>
                                                    <input className={'form-control'} name={'purchasedQuantity'}
                                                           value={item.purchasedQuantity}
                                                           onChange={(e) => ComboChangeAmount(e, index, item.buyDollar)}
                                                           type="number"
                                                           min={0}
                                                           style={{width: '100px'}}
                                                    />
                                                    <input className={'form-control'} type="text" disabled={true}
                                                           style={{width: '100px'}}
                                                           value={item.measurement}/>
                                                </div>
                                            </td>
                                            <td>
                                                <div className={'d-flex align-items-center'}>
                                                    <input type="checkbox"
                                                           onChange={(e) => ComboChangeChecked(e, index,item.buyDollar)}
                                                           name={'buyDollar'}
                                                           value={item.buyDollar}/>
                                                    <h6 className={'p-0 m-0 ms-2'}>{t('block.dl')}</h6>
                                                </div>
                                                <div className={'d-flex align-items-center'}>
                                                    <input type="number" min={0} className={'form-control'}
                                                           name={"buyPrice"}
                                                           style={{width: '150px'}}
                                                           onChange={(e) => ComboChangeAmount(e, index, item.buyDollar)}
                                                           value={item.buyDollar ? item.buyPriceDollar : item.buyPrice}
                                                           placeholder={item.buyPrice}/>
                                                    {
                                                        item.buyDollar ?
                                                            <h3>USD</h3> : <h3>{t('Hamkorlar.sum')}</h3>
                                                    }
                                                </div>

                                            </td>
                                            <td>
                                                {item.buyDollar ? item.totalDollar.toFixed(2) : item.totalSum.toFixed(2)}
                                                {
                                                    item.buyDollar ?
                                                        <h3>USD</h3> : <h3>{t('Hamkorlar.sum')}</h3>
                                                }
                                            </td>
                                            <td>
                                                <div className={'d-flex align-items-center'}>
                                                    <input type="checkbox"
                                                           onChange={(e) => ComboChangeChecked(e, index)}
                                                           name={'salePriceDollarBoolean'}
                                                           value={item.salePriceDollarBoolean}/>
                                                    <h6 className={'p-0 m-0 ms-2'}>{t('block.dl')}</h6>
                                                </div>
                                                <div className={'d-flex align-items-center'}>
                                                    <input type="number" min={0} className={'form-control'}
                                                           name={"salePrice"}
                                                           style={{width: '150px'}}
                                                           onChange={(e) => ComboChangeAmount(e, index, item.salePriceDollarBoolean)}
                                                           value={item.salePriceDollarBoolean ? item.salePriceDollar : item.salePrice}/> {
                                                    item.salePriceDollarBoolean ?
                                                        <h3>USD</h3> : <h3>{t('Hamkorlar.sum')}</h3>
                                                }
                                                </div>
                                            </td>
                                            <td className={'text-danger'}><ImCancelCircle
                                                onClick={() => DeleteXaridArrayPost(index, item.purchaseProductId
                                                    ? item.purchaseProductId : null)}
                                                style={{width: '30px', height: '30px'}}/></td>
                                        </tr>
                                    )
                                }
                                </tbody>
                            </table>
                        </div>
                        <h6>{t('Purchase.23')}: {xisob}</h6>
                    </div>
                </div>
                <h5 className={'text-center mt-5'}>{t('Purchase.24')}</h5>
                {
                    jamixisobSum > 0 &&
                    <div className="row mt-2">
                        <div className="col-md-6 d-flex align-items-center"><h6>Sumda olingan mahsulotlar
                            summasi: {jamixisobSum} {t('Hamkorlar.sum')}</h6>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor={'paidSum'}>Sumdagi to'lov</label>
                            <input type="number" min={0} value={input.paidSum}
                                   id="paidSum"
                                   onChange={(e) => setInput({...input, paidSum: e.target.value})}
                                   className={'form-control'}/>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor={'paymentMethod'}>To'lov turi</label>
                            <select id={'paymentMethod'} className={'form-control'} value={input.tulovusuli}
                                    onChange={(e) => setInput({...input, tulovusuli: e.target.value})}
                            >
                                {
                                    PayReducer?.paymethod.map(item =>
                                        input.tulovusuli == '' ? input.tulovusuli = item.id :
                                            <option value={item.id}>{item.type}</option>)
                                }
                            </select>
                        </div>
                    </div>
                }
                {
                    jamixisobDollar > 0 &&
                    <div className="row mt-2">
                        <div className="col-md-6 d-flex align-items-center"><h6>Dollarda olingan mahsulotlar
                            summasi: {jamixisobDollar.toFixed(2)} USD</h6>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor={'paidDollar'}>Dollardagi to'lov</label>
                            <input type="number" min={0} value={input.paidDollar}
                                   id="paidDollar"
                                   onChange={(e) => setInput({...input, paidDollar: e.target.value})}
                                   className={'form-control'}/>
                        </div>
                        <div className="col-md-3 d-flex align-items-center">
                            <h6>To'lov turi : Doim Naqd</h6>
                        </div>
                    </div>
                }
                <div className="row mt-2">
                    <div className="col-6 col-sm-12 col-md-4">
                        <label htmlFor={'area1'}>{t('Purchase.28')}</label>

                        <select name="" id={'area1'} className={'form-control'} value={input.eslatma}
                                onChange={eslatma}>
                            {
                                tolovreducer.tolovholati?.map(item =>
                                    input.eslatma == '' ? input.eslatma = item.id :
                                        <option value={item.id}>{item.status}</option>)
                            }
                        </select>
                        {
                            input.eslatma ? ""
                                :
                                <p style={{
                                    color: 'red',
                                    marginTop: '4px',
                                    lineHeight: '13px'
                                }}>{placeholders.tolovStatusiSelectStyle}</p>
                        }
                    </div>
                    <div className="col-6 col-sm-12 col-md-4">
                        <label htmlFor={'paid'}>Sana</label>
                        <input type="date" value={input.paidon} onChange={paidon} className={'form-control'}
                               id={'paid'}/>
                    </div>
                    <div className='col-6 col-sm-12 col-md-4'>
                        <label htmlFor={'yet2'}>(+){t('Purchase.31')}</label>
                        <input type="number" min={0} value={input.yetkazibberishnarxi2}
                               placeholder={placeholders.yetkazibBerishNarxiPlaceholder}
                               id="yetkizibBerishNarxiSelect" onChange={yetkazibberishnarxi2}
                               className={'form-control'}/>
                    </div>
                </div>
                <div className="row">
                    <div className={'col-6 col-sm-12 mt-2 col-md-12 d-flex justify-content-between align-items-center'}>
                        <div>
                            <h5 className={'text-danger'}>{t('Purchase.32')}!: {jamixisobSum - input.paidSum} {t('tes.158')}</h5>
                            <h5 className={'text-danger'}>{t('Purchase.32')}!: {(jamixisobDollar - input.paidDollar).toFixed(2)} $</h5>
                        </div>
                        <div>
                            <button className={'btn btn-primary'} onClick={saqla}>{t('Buttons.6')} </button>
                        </div>
                    </div>
                </div>
            </div>
            <ModalLoading isOpen={saveModal}/>
        </div>
    )
}

export default connect((PayReducer, XaridReducer, kgreducer, users, TaminotReducer, branchreducer, tolovreducer, ExchangeStatusR, ValyutaReducer), {
    getOtkazmaStatus,
    getXaridProductType,
    getkg,
    getXarid5,
    saveXarid,
    getPay,
    editXarid,
    getTaminot,
    saveTaminot,
    getbranch,
    gettolovholati,
    getValyuta
})(Xarid)
