import { Box, Card, Grid, Typography, useTheme } from '@mui/material';
import Chart from 'react-apexcharts';
import XaridReducer from "../../Haridlar/reducer/XaridReducer";
import {connect} from "react-redux";
import TaminotReducer from "../../Hamkorlar/reducer/TaminotReducer";
import accountreducer from "../../../../../reducer/accountreducer";
import {useTranslation} from "react-i18next";

function Block5 ({XaridReducer, TaminotReducer, accountreducer}) {
  const {t} = useTranslation();
  const theme = useTheme();
  const chart1Options = {
    chart: {
      background: 'transparent',
      toolbar: {
        show: false
      },
      sparkline: {
        enabled: true
      }
    },
    theme: {
      mode: theme.palette.mode === 'dark' ? 'light' : 'dark'
    },
    stroke: {
      colors: [theme.colors.warning.main],
      width: 3
    },
    colors: [theme.colors.warning.main],
    markers: {
      size: 0
    },
    grid: {
      padding: {
        right: 5,
        left: 5
      }
    },
    tooltip: {
      fixed: {
        enabled: true
      },
      x: {
        show: false
      },
      y: {
        title: {
          formatter() {
            return '$';
          }
        }
      },
      marker: {
        show: false
      }
    },
    yaxis: {
      show: false
    },
    legend: {
      show: false
    }
  };
  const chart1Data = [
    {
      name: 'Bitcoin',
      data: [47, 38, 56, 24, 56, 24, 65]
    }
  ];

  const chart2Options = {
    chart: {
      background: 'transparent',
      toolbar: {
        show: false
      },
      sparkline: {
        enabled: true
      }
    },
    theme: {
      mode: theme.palette.mode === 'dark' ? 'light' : 'dark'
    },
    stroke: {
      colors: [theme.colors.error.main],
      width: 3
    },
    colors: [theme.colors.error.main],
    markers: {
      size: 0
    },
    grid: {
      padding: {
        right: 5,
        left: 5
      }
    },
    tooltip: {
      fixed: {
        enabled: true
      },
      x: {
        show: false
      },
      y: {
        title: {
          formatter() {
            return 'ta';
          }
        }
      },
      marker: {
        show: false
      }
    },
    legend: {
      show: false
    }
  };
  const chart2Data = [
    {
      name: 'Cardano',
      data: [38, 44, 56, 47, 26, 24, 45]
    }
  ];

  const chart3Options = {
    chart: {
      background: 'transparent',
      toolbar: {
        show: false
      },
      sparkline: {
        enabled: true
      }
    },
    theme: {
      mode: theme.palette.mode === 'dark' ? 'light' : 'dark'
    },
    stroke: {
      colors: [theme.colors.success.main],
      width: 3
    },
    colors: [theme.colors.success.main],
    markers: {
      size: 0
    },
    grid: {
      padding: {
        right: 5,
        left: 5
      }
    },
    tooltip: {
      fixed: {
        enabled: true
      },
      x: {
        show: false
      },
      y: {
        title: {
          formatter() {
            return '$';
          }
        }
      },
      marker: {
        show: false
      }
    },
    legend: {
      show: false
    }
  };
  const chart3Data = [
    {
      name: 'Ethereum',
      data: [38, 43, 24, 56, 35, 56, 65]
    }
  ];

  const chart4Options = {
    chart: {
      background: 'transparent',
      toolbar: {
        show: false
      },
      sparkline: {
        enabled: true
      }
    },
    theme: {
      mode: theme.palette.mode === 'dark' ? 'light' : 'dark'
    },
    stroke: {
      colors: [theme.colors.info.main],
      width: 3
    },
    colors: [theme.colors.info.main],
    markers: {
      size: 0
    },
    grid: {
      padding: {
        right: 5,
        left: 5
      }
    },
    tooltip: {
      fixed: {
        enabled: true
      },
      x: {
        show: false
      },
      y: {
        title: {
          formatter() {
            return '$';
          }
        }
      },
      marker: {
        show: false
      }
    },
    legend: {
      show: false
    }
  };
  const chart4Data = [
    {
      name: 'Ripple',
      data: [33, 56, 24, 23, 24, 65, 43]
    }
  ];

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <Card
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: 2.5
          }}
        >
          <Box>
            <Typography
              variant="caption"
              sx={{
                fontWeight: 'bold'
              }}
            >
              {t('xisob.Dillerlar')}
            </Typography>
            <Typography style={{paddingTop:15}} variant="h4">{TaminotReducer.taminot ? TaminotReducer.taminot.length : 0} ta</Typography>
          </Box>
          <Box ml={2} flexGrow={1}>
            <Chart
              options={chart1Options}
              series={chart1Data}
              type="line"
              height={80}
            />
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: 2.5
          }}
        >
          <Box>
            <Typography
              variant="caption"
              sx={{
                fontWeight: 'bold'
              }}
            >
              {t('xisob.Haridlar')}
            </Typography>
            <Typography style={{paddingTop:15}} variant="h4">{XaridReducer.xaridlar ? XaridReducer.xaridlar.length : 0} ta</Typography>
          </Box>
          <Box ml={2} flexGrow={1}>
            <Chart
              options={chart2Options}
              series={chart2Data}
              type="line"
              height={80}
            />
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: 2.5
          }}
        >
          <Box>
            <Typography
              variant="h5"
              width={"200px"}
              color={"#666"}
            >
              {t('xisob.harids')}
            </Typography>
            <Typography style={{paddingTop:15}} fontSize={"20px"} fontWeight={"bold"} color={"#00930d"}> {accountreducer.Purchases? accountreducer.Purchases?.myPurchase.toString().replace(/\B(?=(\d{3})+(?!\d))/g,","):''} so'm</Typography>
          </Box>
          <Box ml={2} flexGrow={1}>
            <Chart
              options={chart3Options}
              series={chart3Data}
              type="line"
              height={80}
            />
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: 2.5
          }}
        >
          <Box>
            <Typography
                variant="h5"
                width={"200px"}

                color={"#666"}

            >
              {t('xisob.dillq')}
            </Typography>
            <Typography style={{paddingTop:15}} fontSize={"20px"} fontWeight={"bold"} color={"#ff1224"}>{accountreducer.Purchases?.myDebt.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",")} so'm</Typography>
          </Box>
          <Box ml={2} flexGrow={1}>
            <Chart
              options={chart4Options}
              series={chart4Data}
              type="line"
              height={80}
            />
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
}

export default connect((XaridReducer, TaminotReducer, accountreducer),{}) (Block5);
