import '../MaxsulotlarXisoboti/maxsulotxisoboti.css'
import React, {useState, useEffect} from "react";

import {connect} from 'react-redux'

import {useTranslation} from "react-i18next";
import users from "../../../../../reducer/users";
import {DatePicker, Segmented} from "antd";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import formatDateYear from "../../../../../reducer/sana";
import {
    Avatar,
    AvatarGroup, Box, Card, FormControl, IconButton, InputLabel, MenuItem, Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead, TablePagination,
    TableRow,
    Tooltip,
    Typography,
    Button
} from "@mui/material";
import formatDate, {prettify} from "../../../../../util";
import MaxsulotlarRoyxariReducer, {getProductRemainHistory} from "../../Maxsulotlar/reducer/MaxsulotlarRoyxariReducer";
import SavdoQoshishReducer, {getBackingProduct} from "../../Savdo/reducer/SavdoQoshishReducer";
import {Link} from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import MainHeaderText from "../../../../Components/MainHeaderText";
import './productRemain.css'
import CardBody from "../../../../Components/CardBody";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CommonTable from "../../../../Components/CommonTable";

const {RangePicker} = DatePicker

function MaxsulotMiqdoriQoldigi({
                                    users,
                                    MaxsulotlarRoyxariReducer,
                                    getProductRemainHistory,
                                    SavdoQoshishReducer,
                                    getBackingProduct
                                }) {

    const {t} = useTranslation()

    const columns = [
        {
            title: 'Id',
            dataIndex: 'index',
            width: '3%',
        },
        {
            title: t('Salary.n'),
            dataIndex: 'name',
            key: 'name',
            width: '10%',

        },
        {
            title: t('xisob.urtacha'),
            dataIndex: 'average',
            key: 'average',
            render: (item, value) => <p className={'m-0'}>{item} {value?.measurement}</p>,
            width: '10%',

        },
        {
            title: t('xisob.zaxirak'),
            dataIndex: 'day',
            key: 'day',
            render: (item, value) => <p className={'m-0'}>{item} kun</p>,
            width: '12%',

        },
        {
            title: t('xisob.buoyuch'),
            dataIndex: 'month',
            key: 'month',
            render: (item, value) => <p className={'m-0'}>{item} {value?.measurement}</p>,
            width: '10%',
        },
        {
            title: t('xisob.bhum'),
            dataIndex: 'average',
            key: 'average',
            render: (item, value) => <p className={'m-0'}>{parseFloat(item * 7).toFixed(2)} {value?.measurement}</p>,
            width: '10%',
        },
        {
            title: t('xisob.30'),
            dataIndex: 'average',
            key: 'average',
            render: (item, value) => <p className={'m-0'}>{parseFloat(item * 30).toFixed(2)} {value?.measurement}</p>,
            width: '10%',
        },
        {
            title: t('xisob.Ombordagimiqdori'),
            dataIndex: 'amount',
            key: 'amount',
            render: (item, value) => <p className={'m-0'}>{item} {value?.measurement}</p>,
            width: '10%',
        },
    ];

    const columnsBacking = [
        {
            title: 'Id',
            dataIndex: 'index',
            width: '3%',
        },
        {
            title: t('Salary.n'),
            dataIndex: 'productName',
            key: 'prodcutName',
            width: '10%',

        },
        {
            title: t('xisob.Qaytarilganmiqdor'),
            dataIndex: 'quantity',
            key: 'quantity',
            render: (item, value) => <p className={'m-0'}>{item} {value?.measurement}</p>,
            width: '10%',

        },

        // {
        //     title: t('Davomat.amal'),
        //     dataIndex: 'productId',
        //     key: 'productId',
        //     render: (item,value) => <Link
        //         to={`/main/productRemains/${item}/${value.productName}/${value.measurement}`}>
        //         <RemoveRedEyeIcon
        //             size={'large'}/>
        //     </Link>,
        //     width: '10%',
        // },
    ];


    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(5);
    const [branch, setBranch] = useState(null)
    const [pageBacking, setPageBacking] = useState(0);
    const [limitBacking, setLimitBacking] = useState(5);
    const [branchBacking, setBranchBacking] = useState(null)

    const handlePageChangeBacking = (newPage) => {
        setPageBacking(newPage - 1);
    };

    const handleLimitChangeBacking = (event, size) => {
        setLimitBacking(parseInt(size));
    };

    const handlePageChange = (newPage) => {
        setPage(newPage - 1);
    };

    const handleLimitChange = (event, size) => {
        setLimit(parseInt(size));
    };

    function changeBranch(e) {
        setBranch(e.target.value)
    }

    const [view, setView] = useState('remained')

    useEffect(() => {
        getProductRemainHistory({
            branchId: branch ? branch : users.branchId,
            params: {
                page: page,
                size: limit
            }
        })
    }, [page, limit])

    useEffect(() => {
        if (branch) {
            getProductRemainHistory({
                branchId: branch ? branch : users.branchId,
                params: {
                    page: 0,
                    size: 5
                }
            })
            setPage(0)
            setLimit(5)
        }
    }, [branch])
    useEffect(() => {
        getBackingProduct({
            branchId: branchBacking ? branchBacking : users.branchId,
        })
        setPageBacking(0)
        setLimitBacking(5)
    }, [branchBacking])

    return (
        <div className="col-md-12 mt-4 mb-4">
            <div className="row d-flex justify-content-between aling-items-end p-3">
                <div class="col-md-4">
                    <MainHeaderText text={t('xisob.Mahsulotmiqdori')}/>
                </div>
                <div className="col-md-4 productRemain">
                    <Segmented size={'large'} onChange={(e) =>
                        setView(e)
                    } options={[
                        {label: t('xisob.MahsulotZahirasi'), value: 'remained'},
                        {label: t('xisob.QaytarilganMahsulotlar'), value: 'returned'},
                    ]}/>
                </div>
            </div>
            {
                view === 'remained' ?
                    <div className={'row p-3 mt-4'}>
                        <div class="col-md-12">
                            <CardBody>
                                <div className="col-md-12">
                                    <div className="row px-2 my-4  d-flex justify-content-between align-items-center">
                                        <div className='col-md-4'>
                                            <h5>{t('xisob.MahsulotZahirasi')}</h5>
                                        </div>
                                        <div className="col-md-3">
                                            <FormControl sx={{m: 1, minWidth: '100%'}} size="small">
                                                <InputLabel id="demo-select-small-label">Baza</InputLabel>
                                                <Select
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small"
                                                    value={branch ? branch : users.branchId}
                                                    label="Baza"
                                                    IconComponent={KeyboardArrowDownIcon}
                                                    onChange={changeBranch}
                                                >
                                                    {
                                                        users.branches.map(item =>
                                                            <MenuItem value={item.id}>{item.name}</MenuItem>
                                                        )
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <>
                                        {
                                            MaxsulotlarRoyxariReducer.productRemain ?
                                                <div className="mt-4 table-responsive">
                                                    <CommonTable size={limit} page={page}
                                                                 total={MaxsulotlarRoyxariReducer.productRemain?.totalItems}
                                                                 pagination={true}
                                                                 data={MaxsulotlarRoyxariReducer.productRemain?.getProductAmountList}
                                                                 handleLimitChange={handleLimitChange}
                                                                 handlePageChange={handlePageChange}
                                                                 columns={columns}
                                                    />
                                                </div>
                                                : <h4 className={'text-center'}>{t('Darslik.mtt')}</h4>
                                        }
                                    </>
                                </div>
                            </CardBody>
                        </div>
                    </div>
                    :
                    view === "returned" ?
                        <div className={'row p-3 mt-4'}>
                            <div class="col-md-12">
                                <CardBody>
                                    <div class="col-md-12">
                                        <div
                                            className="row px-2 my-4 d-flex justify-content-between align-items-center">
                                            <div className={'col-md-4'}>
                                                <h5>{t('xisob.QaytarilganMahsulotlar')}</h5>
                                            </div>
                                            {
                                                users.branches.length > 1 &&
                                                <div className="col-md-3">
                                                    <FormControl sx={{m: 1, minWidth: '100%'}} size="small">
                                                        <InputLabel id="demo-select-small-label">Baza</InputLabel>
                                                        <Select
                                                            labelId="demo-select-small-label"
                                                            id="demo-select-small"
                                                            value={branchBacking ? branchBacking : users.branchId}
                                                            label="Baza"
                                                            IconComponent={KeyboardArrowDownIcon}
                                                            onChange={(e) => setBranchBacking(e.target.value)}
                                                        >
                                                            {
                                                                users.branches.map(item =>
                                                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                                                )
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            }
                                        </div>
                                        <>
                                            {
                                                SavdoQoshishReducer.backingProduct ?
                                                    <div className={'mt-4 table-responsive'}>
                                                        <CommonTable size={limitBacking} page={pageBacking}
                                                                     total={SavdoQoshishReducer.backingProduct?.totalItems}
                                                                     pagination={false}
                                                                     data={SavdoQoshishReducer.backingProduct}
                                                                     handleLimitChange={handleLimitChangeBacking}
                                                                     handlePageChange={handlePageChangeBacking}
                                                                     columns={columnsBacking}
                                                        />
                                                    </div>
                                                    : <h4 className={'text-center'}>{t('Darslik.mtt')}</h4>
                                            }
                                        </>
                                    </div>
                                </CardBody>
                            </div>
                        </div>
                        : ''
            }
        </div>
    )
}

export default connect((users, MaxsulotlarRoyxariReducer, SavdoQoshishReducer), {
    getProductRemainHistory, getBackingProduct
})(MaxsulotMiqdoriQoldigi)
