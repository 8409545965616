import React from 'react'
import './xarajatlarXisoboti.css'
import {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {useTranslation} from "react-i18next";
import XarajatTurlariReducer, {
    deleteXarajatlarTurlari, editXarajatlarTurlari,
    getXarajatlarTurlari, getXarajatlarTurlariByBranch, saveXarajatlarTurlari
} from "../../Xarajatlar/reducer/XarajatTurlariReducer";
import XarajatlarReducer, {getXarajatlar} from "../../Xarajatlar/reducer/XarajatlarReducer";
import BolimReducer, {getBolim} from "../../Maxsulotlar/reducer/BolimReducer";
import users from "../../../../../reducer/users";
import CanvasJSReact from '../KopSotilganTovarlar/assets/canvasjs.react';
import accountreducer, {getAccount} from "../../../../../reducer/accountreducer";
import XarajatXisobotReducer, {
    getXarajatlarxisobot,
} from "../reducer/XarajatXisobotReducer";
import formatDate, {prettify} from "../../../../../util";
import {DatePicker} from "antd";
import formatDateYear from "../../../../../reducer/sana";
import MainHeaderText from "../../../../Components/MainHeaderText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuItem from "@mui/material/MenuItem";
import CardBody from "../../../../Components/CardBody";
import ExcelButtons from "../../../../Components/ExcelButtons";
import {SearchAnt} from "../../../../Components/SelectAnt";
import CommonTable from "../../../../Components/CommonTable";
import moment from "moment/moment";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const {RangePicker} = DatePicker

function XarajatlarXisoboti({
                                getXarajatlarxisobot,
                                getXarajatlarTurlariByBranch,
                                XarajatTurlariReducer,
                                XarajatXisobotReducer,
                                accountreducer,
                                getAccount,
                                BolimReducer,
                                users,
                                getBolim,
                                XarajatlarReducer,
                                getXarajatlar
                            }) {

    const {t} = useTranslation()


    const columns = [
        {
            title: 'Id',
            dataIndex: 'index',
            width: '3%',
        },
        {
            title: t('Trade.24'),
            dataIndex: 'type',
            key: 'type',
            width: '5%',

        },
        {
            title: t('ExpenseReport.2'),
            dataIndex: 'totalSum',
            key: 'totalSum',
            render: (item) => <p className={'m-0'}>{prettify(item, 3)} so'm</p>,
            width: '10%',
        },
    ];


    const [input, setInput] = useState(
        {
            baza: '',
            bolim: '',
            aniqsana: '',
            view: '',
            izlash: '',
        }
    )


    const [branch, setBranch] = useState(null)
    const [outlayCategory, setOutlayCategory] = useState(null)
    const [dates, setDates] = useState(null)


    function selectBranch(e) {
        setBranch(e.target.value)
        getXarajatlarTurlariByBranch(e.target.value)
        setOutlayCategory('ALL')
        getXarajatlarxisobot({
            branchId: e.target.value,
            outlay: null,
            startDate: dates ? dates[0] : null,
            endDate: dates ? dates[1] : null
        })
    }

    function selectOutlayCategory(e) {
        setOutlayCategory(e.target.value)
        getXarajatlarxisobot({
            branchId: branch ? branch : users.branchId,
            outlay: e.target.value === "ALL" ? null : e.target.value,
            startDate: dates ? dates[0] : null,
            endDate: dates ? dates[1] : null
        })
    }


    function view(e) {
        input.view = e.target.value
        let a = {...input}
        setInput(a)
    }


    function izlash(e) {
        input.izlash = e.target.value
        let a = {...input}
        setInput(a)
    }

    useEffect(() => {
        getXarajatlar(users.businessId)
        getBolim(users.businessId)
        getAccount(users.branchId)
        getXarajatlarxisobot({
            branchId: branch ? branch : users.branchId,
            outlay: null,
            startDate: null,
            endDate: null
        })
        getXarajatlarTurlariByBranch(branch ? branch : users.branchId)
    }, [])

    useEffect(() => {
        let a = []
        if (XarajatXisobotReducer.xarajatxisobot) {
            XarajatXisobotReducer.xarajatxisobot?.map(item => {
                a.push({
                    label: item.outlayCategory?.title,
                    y: item.totalSum
                })
            })
        }
        setChart(a)
    }, [XarajatXisobotReducer.current])

    const [chart, setChart] = useState(null)

    const options = {
        title: {
            text: "Xarajatlar "
        },
        animationEnabled: true,
        data: [
            {
                // Change type to "doughnut", "line", "splineArea", etc.
                type: "column",
                dataPoints: chart

            }
        ]
    }

    return (
        <div className="col-md-12 mt-4 mb-4">
            <div class="row p-3">
                <MainHeaderText text={t('ExpenseReport.1')}/>
            </div>
            <div className="row px-2 align-items-center my-4">
                <div className="col-md-4">
                    <FormControl sx={{m: 1, minWidth: '100%'}} size="small">
                        <InputLabel id="demo-select-small-label">{t('cost.baza')}</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={branch}
                            label={t('cost.baza')}
                            IconComponent={KeyboardArrowDownIcon}
                            onChange={selectBranch}
                        >
                            <MenuItem value="">
                                <em>{t('num.25')}</em>
                            </MenuItem>
                            {
                                users.branches.map(item =>
                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </div>
                <div className="col-md-4">
                    <FormControl sx={{m: 1, minWidth: '100%'}} size="small">
                        <InputLabel id="demo-select-small-label">{t('xisob.xarajattur')}</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={outlayCategory}
                            label={t('xisob.xarajattur')}
                            IconComponent={KeyboardArrowDownIcon}
                            onChange={selectOutlayCategory}
                        >
                            <MenuItem value="">
                                <em>{t('num.25')}</em>
                            </MenuItem>
                            {
                                XarajatTurlariReducer.xarajatturlari ?
                                    XarajatTurlariReducer.xarajatturlari.map(item =>
                                        <MenuItem value={item.id}>{item.title}</MenuItem>) : <MenuItem value="">
                                        <em>{t('num.25')}</em>
                                    </MenuItem>
                            }
                        </Select>
                    </FormControl>
                </div>
                <div className="col-md-4">
                    <RangePicker onChange={(values) => {
                        let a = []
                        if (values !== null) {
                            values?.map(item => {
                                a.push(formatDateYear(item))
                            })
                            setDates(a)
                            getXarajatlarxisobot({
                                branchId: branch ? branch : users.branchId,
                                startDate: a ? a[0] : null,
                                endDate: a ? a[1] : null,
                                outlay: outlayCategory === "ALL" ? null : outlayCategory
                            })
                        } else {
                            setDates(null)
                            getXarajatlarxisobot({
                                branchId: branch ? branch : users.branchId,
                                startDate: null,
                                endDate: null,
                                outlay: outlayCategory === "ALL" ? null : outlayCategory
                            })
                        }
                    }
                    }/>
                </div>
            </div>
            {
                XarajatXisobotReducer.xarajatxisobot ?
                    <div className={'row p-3'}>
                        <div class="col-md-12">
                            <CardBody>
                                <div className="col-md-12">
                                    <div className="d-flex justify-content-between align-items-center mb-4">
                                        <h5>{t('num.45')}</h5>
                                        <h3 className={'customer-debt'}>{t('num.47')} <span
                                            className={'customer-debt-sum'}>({prettify(accountreducer.Purchases.myOutlay)} so'm)</span>
                                        </h3>
                                    </div>
                                    <ExcelButtons/>
                                    <div className="mb-3">
                                        <SearchAnt value={input.izlash} onChange={izlash}/>
                                    </div>
                                    <div className="table-responsive mb-4">
                                        <CommonTable size={0} page={XarajatXisobotReducer.xarajatxisobot?.length}
                                                     pagination={false} data={XarajatXisobotReducer.xarajatxisobot}
                                                     columns={columns}
                                        />
                                    </div>
                                </div>
                            </CardBody>
                        </div>
                        {/*<CanvasJSChart options = {options}/>*/}

                    </div> :
                    <div>
                        <h4 className={'text-center'}>{XarajatXisobotReducer.message}</h4>
                    </div>
            }


        </div>
    )
}

export default connect((XarajatTurlariReducer, users, XarajatXisobotReducer, accountreducer, BolimReducer, XarajatlarReducer), {
    getXarajatlarTurlariByBranch,
    getXarajatlar,
    getXarajatlarxisobot,
    getAccount,
    getBolim,
    getXarajatlarTurlari,
    saveXarajatlarTurlari,
    editXarajatlarTurlari,
    deleteXarajatlarTurlari
})(XarajatlarXisoboti)
