import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../../../../../../../api";

const slice = createSlice({
    name: 'reducer',
    initialState: {
        foydaMahsulot: [],
        foydaMahsulot2: null,
        message:null,
        foydaBolim:null,
        foydaFilial: [],
        foydaFirma: null,
        foydaMijoz: null,
        foydaSavdo:null
    },
    reducers: {
        getFrom: (state, action) => {
            state.foydaMahsulot = action.payload.object
        },
        getFromsana: (state, action) => {
             if(action.payload.success){
                 state.foydaMahsulot2 = action.payload.object
             }
             else{
                 state.foydaMahsulot2  = null
                 state.message = action.payload.message
             }

        },
        getFrom2: (state,action)=>{
            if(action.payload.success){
                state.foydaBolim = action.payload.object
            }
            else{
                state.foydaBolim  = null
                state.message = action.payload.message
            }
        },
        getFrom3: (state,action)=>{
            if(action.payload.success){
                state.foydaFilial = action.payload.object
            }
            else{
                state.foydaFilial  = null
                state.message = action.payload.message
            }
        },
        getFrom4: (state,action)=>{
            if(action.payload.success){
                state.foydaFirma = action.payload.object
            }
            else{
                state.foydaFirma  = null
                state.message = action.payload.message
            }
        },
        getFrom5: (state,action)=>{
            if(action.payload.success){
                state.foydaMijoz = action.payload.object
            }
            else{
                state.foydaMijoz  = null
                state.message = action.payload.message
            }
        },
        getFrom6: (state,action)=>{
            if(action.payload.success){
                state.foydaSavdo = action.payload.object
            }
            else{
                state.foydaSavdo  = null
                state.message = action.payload.message
            }
        }
    }

});

export const getFoydaMahsulot=(data)=>apiCall({
    url: `/reports/benefit-by-branch/${data.branchId}/?date=${data.date}`,
    method:'get',
    onSuccess: slice.actions.getFromsana.type,
    onFail:slice.actions.getFromsana.type
});
export const getFoydaMahsulot2=(data)=>apiCall({
    url: `/reports/benefit-by-branch/${data.branchId}/?startDate=${data.startDate}&endDate=${data.endDate}`,
    method:'get',
    onSuccess: slice.actions.getFromsana.type,
    onFail:slice.actions.getFromsana.type
});

export const getFoydaBolim=(data)=>apiCall({
    url: `/reports/benefit-by-category/${data.branchId}/?date=${data.date}`,
    method:'get',
    onSuccess: slice.actions.getFrom2.type,
    onFail:slice.actions.getFrom2.type
});
export const getFoydaBolim2=(data)=>apiCall({
    url: `/reports/benefit-by-category/${data.branchId}/?startDate=${data.startDate}&endDate=${data.endDate}`,
    method:'get',
    onSuccess: slice.actions.getFrom2.type,
    onFail:slice.actions.getFrom2.type
});
export const getFoydaFirma=(data)=>apiCall({
    url: `/reports/benefit-by-brand/${data.branchId}/?date=${data.date}`,
    method:'get',
    onSuccess: slice.actions.getFrom4.type,
    onFail: slice.actions.getFrom4.type
});
export const getFoydaFirma2=(data)=>apiCall({
    url: `/reports/benefit-by-brand/${data.branchId}/?startDate=${data.startDate}&endDate=${data.endDate}`,
    method:'get',
    onSuccess: slice.actions.getFrom4.type,
    onFail: slice.actions.getFrom4.type
});
export const getFoydaFilial=(data)=>apiCall({
    url: '/benefit-lost/one-date/'+data,
    method:'get',
    onSuccess: slice.actions.getFrom3.type
});
export const getFoydaMijoz=(data)=>apiCall({
    url: `/reports/benefit-by-customer/${data.branchId}/?date=${data.date}`,
    method:'get',
    onSuccess: slice.actions.getFrom5.type,
    onFail: slice.actions.getFrom5.type
});
export const getFoydaMijoz2=(data)=>apiCall({
    url: `/reports/benefit-by-customer/${data.branchId}/?startDate=${data.startDate}&endDate=${data.endDate}`,
    method:'get',
    onSuccess: slice.actions.getFrom5.type,
    onFail: slice.actions.getFrom5.type
});
export default slice.reducer