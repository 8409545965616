import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../api";
import {toast} from "react-toastify";

const slice = createSlice({
    name: 'productionCost',
    initialState: {
        productionCost: null,
        productionCostAll:null,
        current: false,
        getBoolean: false,
        message: '',
        saveBoolean:false
    },
    reducers: {
        getFrom: (state, action) => {
            if (action.payload.success){
                state.productionCost = action.payload.object
            }else {
                state.productionCost = null
                state.message = action.payload.message
            }
            state.getBoolean = !state.getBoolean
            state.saveBoolean=false
        },
        getFromAll: (state, action) => {
            if (action.payload.success){
                state.productionCostAll = action.payload.object
            }else {
                state.productionCostAll = null
                state.message = action.payload.message
            }
            state.getBoolean = !state.getBoolean
            state.saveBoolean=false
        },
        savefrom: (state, action) => {
            if (action.payload.success){
                state.saveBoolean=true
                toast.success('Saqlandi')
            }else {
                toast.error(action.payload.message)
            }
            state.current = !state.current
        },
        editfrom: (state, action) => {
            if (action.payload.success){
                state.saveBoolean=true
                toast.success('Tahrirlandi')
            }else {
                toast.error(action.payload.message)
            }
            state.current = !state.current
        },
        deletefrom: (state, action) => {
            if (action.payload.success){
                state.current = !state.current
                toast.success('Ro\'yhatdan o`chirildi')
            }else {
                toast.error(action.payload.message)
            }
        }
    }
});

export const getProductionCost = (data) => apiCall({
    url: '/cost-type/get-by-branch-pageable/' +data.branchId,
    method: 'get',
    params:data.params,
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type,
});
export const getProductionCostAll = (data) => apiCall({
    url: '/cost-type/get-by-branch/' +data,
    method: 'get',
    onSuccess: slice.actions.getFromAll.type,
    onFail: slice.actions.getFromAll.type,
});


export const saveProductionCost = (data) => apiCall({
    url: '/cost-type',
    method: 'post',
    data,
    onSuccess: slice.actions.savefrom.type,
    onFail: slice.actions.savefrom.type,
});
export const editProductionCost = (data) => apiCall({
    url: '/cost-type/'+data.id,
    method: 'put',
    data,
    onSuccess: slice.actions.editfrom.type,
    onFail: slice.actions.editfrom.type,
});
export const deleteProductionCost = (data) => apiCall({
    url: '/cost-type/'+data,
    method: 'delete',
    data,
    onSuccess: slice.actions.deletefrom.type,
    onFail: slice.actions.deletefrom.type,
});


export default slice.reducer