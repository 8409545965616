import {useState, useEffect} from 'react';
import {
    Box,
    Card,
    Grid,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableContainer,
    TableRow,
    Typography,
} from '@mui/material';
import {connect} from "react-redux";
import Loading from "../../../../Loading";
import users from "../../../../../reducer/users";
import DarsliklarReducer, {getDarslik} from "../reducer/DarsliklarReducer";
import Select from "react-select";
import XodimReducer, {getXodim} from "../../Hodimlar/reducer/XodimReducer";
import LessonUserReducer ,{getViewByUserId,getViewByLesson} from "../reducer/LessonUserReducer";
import {Progress} from "reactstrap";
import {useTranslation} from 'react-i18next';


const LessonUser = ({
                  users, DarsliklarReducer, getDarslik, getXodim, XodimReducer, LessonUserReducer, getViewByLesson, getViewByUserId
              }) => {
    const {t} = useTranslation()
    const [loading, setLoading] = useState(false)
    const [selectUserValue,setSelectUserValue] = useState(null)
    const [selectLessonValue,setSelectLessonValue] = useState(null)
    const optionsUser =  XodimReducer.xodimlar ? XodimReducer.xodimlar?.map((item) => {
        return ({value: item.id, label: item.firstName + " " + item.lastName})
    }) : null
    const optionsLesson =  DarsliklarReducer.darsliklar ?  DarsliklarReducer.darsliklar?.map((item) => {
        return ({value: item.id, label: item.name})
    }) : null

    useEffect(() => {
        getDarslik(users.businessId)
        getXodim(users.businessId)
        setLoading(true)
    }, [])
    function changeUserSelect(data) {
        setSelectUserValue(data)
        setSelectLessonValue(null)
        getViewByUserId(data.value)
    }
    function changeLessonSelect(data) {
        setSelectLessonValue(data)
        setSelectUserValue(null)
        getViewByLesson(data.value)
    }

    return (
        <>
            <Box className="MuiPageTitle-wrapper p-5">
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h3" component="h3" gutterBottom>
                            {t('tes.114')}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Grid
                sx={{
                    px: 4
                }}
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                spacing={4}
            >
                <Grid item xs={12}>
                    <>
                        <Box
                            display="flex"
                            alignItems="center"
                            flexDirection={{xs: 'column', sm: 'row'}}
                            justifyContent={{xs: 'center', sm: 'space-between'}}
                            pb={3}
                        >
                                <div style={{display:'flex', gap: 10, alignItems:'center'}}>
                                    <p style={{marginBottom:5, fontWeight:500}}> {t(`tes.115`)} </p>
                                    <div style={{width: 130}} >
                                        <Select value={selectLessonValue} options={optionsLesson} onChange={changeLessonSelect}/>
                                    </div>
                                </div>
                                <div style={{display:'flex', gap: 10, alignItems:'center'}}>
                                    <p style={{marginBottom:5, fontWeight:500}}> {t(`tes.116        `)} </p>
                                    <div style={{width: 130}} >
                                        <Select value={selectUserValue} options={optionsUser} onChange={changeUserSelect}/>
                                    </div>
                                </div>
                        </Box>
                        <Card>
                            <Divider/>
                            {
                                LessonUserReducer?.lessonView ?
                                    loading ?
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>#</TableCell>
                                                        <TableCell>{t('tes.85')}</TableCell>
                                                        <TableCell>{t('tes.117')}</TableCell>
                                                        <TableCell>{t('tes.118')}</TableCell>
                                                        <TableCell>{t('tes.119')}</TableCell>
                                                        <TableCell>{t('tes.120')}</TableCell>
                                                        <TableCell>{t('tes.121')}</TableCell>
                                                        <TableCell>{t('tes.122')}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        LessonUserReducer?.lessonView.map((item, index) => <TableRow key={index}>
                                                            <TableCell>{index + 1}</TableCell>
                                                            <TableCell>{item.firstName + " " + item.lastName}</TableCell>
                                                            <TableCell>{item.lessonName}</TableCell>
                                                            <TableCell>{item.view+"/"+item.lessonView}</TableCell>
                                                            <TableCell>
                                                                <Progress animated value={item.view} style={{fontSize:'12px',textAlign:'center',fontWeight:'700'}}  max={item.lessonView}>
                                                                    {(item.view/item.lessonView)*100 > 100 ? '100' : Math.round((item.view/item.lessonView)*100)} %
                                                                </Progress>
                                                            </TableCell>
                                                            <TableCell>{item.solveTest ? 'ishlangan' : 'ishlanmagan'}</TableCell>
                                                            <TableCell>{item.testResult}</TableCell>
                                                            <TableCell>{item.finish ? "tugatilgan":"tugatilmagan"}</TableCell>
                                                        </TableRow>)
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        : <Loading/> : <h4 className={'text-center'}>{t('Darslik.mtt')}</h4>
                            }
                        </Card>
                    </>
                </Grid>
            </Grid>
        </>
    );
};
export default connect((users, DarsliklarReducer,XodimReducer,LessonUserReducer), {
    getDarslik,
    getXodim,
    getViewByUserId,
    getViewByLesson
})(LessonUser)
