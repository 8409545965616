import {Link} from 'react-router-dom'
import Edit from '../../../../../img/Edit.png'
import Delete from '../../../../../img/Delete.png'
import './barcasavdolar.css'
import React, {useState, useEffect, useRef,useReducer} from "react";
import {connect} from "react-redux";
import TaminotReducer, {getTaminot} from "../../Hamkorlar/reducer/TaminotReducer";
import SavdoQoshishReducer, {
    deleteSavdolar,
    editSavdolar,
    getSavdolar, getSavdolar2, getSavdolar3, getSavdolarPageSize, getTradeById,
    saveSavdolar,
} from "../reducer/SavdoQoshishReducer";
import users from "../../../../../reducer/users";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import ValyutaReducer from "../../Settings/DukonSozlamalari/reducers/ValyutaReducer";
import {useTranslation} from "react-i18next";
import formatDate, {prettify} from "../../../../../util";
import Loading from "../../../../Loading";
import {Checkbox, Pagination} from "antd";
import Imagecom from "../../../../Imagecom";
import moment from "moment/moment";
import {useReactToPrint} from "react-to-print";
import checkReducer, {getInvoice} from "../../../../../reducer/checkReducer";
import {AiOutlineFileText} from "react-icons/ai";
import PayReducer, {getPay} from "../../../../../reducer/PayReducer";
import CardBody from "../../../../Components/CardBody";
import CommonTable from "../../../../Components/CommonTable";

function BarchaSavdolar({
                            getPay,
                            ValyutaReducer,
                            deleteSavdolar,
                            getTaminot,
                            getInvoice,
                            SavdoQoshishReducer,
                            users,
                            getSavdolarPageSize,
                            getTradeById,
                            checkReducer,
                            PayReducer
                        }) {

    const {t} = useTranslation()
    const [state, dispatch] = useReducer(reducer, {
        page: 0,
        size: 5,
    });

    function reducer(state, action) {
        console.log(state,action)
       switch (action.type) {
           case 'page':
              return {...state,page:action.page-1}
       }
    }

    console.log(state)

    const [pageData, setPageData] = useState({
        page: 0,
        size: 5,
    })
    const [viewOneTrade, setViewOneTrade] = useState(false)

    function getOneById(id) {
        getTradeById(id)
        setViewOneTrade(true)
    }


    const columns = [
        {
            key: "id",
            title: 'Id',
            dataIndex: 'index',
            width: '3%',
            fixed: 'left',
        },
        {
            title: t('Davomat.hodim'),
            dataIndex: 'sellerName',
            key: 'sellerName',
            width: '5%',
        },
        {
            title: t('PurchaseList.6'),
            dataIndex: 'customerName',
            key: 'customerName',
            width: '5%',
        },
        {
            title: t('xisob.tulovt'),
            dataIndex: 'paymentStatus',
            key: 'paymentStatus',
            width: '10%',
            render: (item, record) => <div className="d-flex align-items-center gap-2">
                {item === "TOLANGAN" ?
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.85786 12.1421 0.5 8 0.5C3.85786 0.5 0.5 3.85786 0.5 8C0.5 12.1421 3.85786 15.5 8 15.5ZM11.444 6.09537C11.6347 5.85015 11.5906 5.49674 11.3453 5.30602C11.1001 5.11529 10.7467 5.15946 10.556 5.40468L7.55074 9.26858C7.48392 9.3545 7.35822 9.36565 7.27731 9.29283L5.3763 7.58192C5.14539 7.3741 4.78972 7.39282 4.5819 7.62373C4.37408 7.85464 4.3928 8.21031 4.62371 8.41813L6.52472 10.129C7.09107 10.6388 7.97098 10.5607 8.43876 9.95926L11.444 6.09537Z"
                              fill="url(#paint0_linear_878_8045)"/>
                        <defs>
                            <linearGradient id="paint0_linear_878_8045" x1="8" y1="0.5" x2="8" y2="15.5"
                                            gradientUnits="userSpaceOnUse">
                                <stop stopColor="#21EB66"/>
                                <stop offset="1" stopColor="#12D583"/>
                            </linearGradient>
                        </defs>
                    </svg> : item === "TOLANMAGAN" ?
                        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M6.47599 1.12444C7.14024 -0.0414791 8.85976 -0.0414812 9.52401 1.12444L15.2781 11.2243C15.9237 12.3575 15.0834 13.75 13.7541 13.75H2.24591C0.916594 13.75 0.0763281 12.3575 0.721903 11.2243L6.47599 1.12444ZM8.74995 10.75C8.74995 11.1642 8.41417 11.5 7.99995 11.5C7.58574 11.5 7.24995 11.1642 7.24995 10.75C7.24995 10.3358 7.58574 10 7.99995 10C8.41417 10 8.74995 10.3358 8.74995 10.75ZM8.56245 4.75C8.56245 4.43934 8.31061 4.1875 7.99995 4.1875C7.68929 4.1875 7.43745 4.43934 7.43745 4.75V8.5C7.43745 8.81066 7.68929 9.0625 7.99995 9.0625C8.31061 9.0625 8.56245 8.81066 8.56245 8.5V4.75Z"
                                  fill="#E02323"/>
                        </svg> :
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M9.97708 0.82583C12.5301 1.52169 14.5408 3.53243 15.2367 6.08542C15.5271 7.15112 14.6046 8.0625 13.5 8.0625H10C8.89543 8.0625 8 7.16707 8 6.0625V2.5625C8 1.45793 8.91138 0.53536 9.97708 0.82583ZM0.5 8.81251C0.5 5.21043 3.3215 2.26734 6.875 2.07276V6.56254C6.875 8.01228 8.05025 9.18754 9.5 9.18754H13.9898C13.7952 12.741 10.8521 15.5625 7.25 15.5625C3.52208 15.5625 0.5 12.5404 0.5 8.81251Z"
                                  fill="#28303F"/>
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M9.97708 0.82583C12.5301 1.52169 14.5408 3.53243 15.2367 6.08542C15.5271 7.15112 14.6046 8.0625 13.5 8.0625H10C8.89543 8.0625 8 7.16707 8 6.0625V2.5625C8 1.45793 8.91138 0.53536 9.97708 0.82583ZM0.5 8.81251C0.5 5.21043 3.3215 2.26734 6.875 2.07276V6.56254C6.875 8.01228 8.05025 9.18754 9.5 9.18754H13.9898C13.7952 12.741 10.8521 15.5625 7.25 15.5625C3.52208 15.5625 0.5 12.5404 0.5 8.81251Z"
                                  fill="url(#paint0_linear_901_2758)"/>
                            <defs>
                                <linearGradient id="paint0_linear_901_2758" x1="7.89538" y1="0.771744" x2="7.89538"
                                                y2="15.5625"
                                                gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#FFBC0E"/>
                                    <stop offset="1" stopColor="#FF7C1E"/>
                                </linearGradient>
                            </defs>
                        </svg>

                }
                <span>
                    {`${item} ${item !== "TOLANMAGAN" ? `(${record?.payMethod})` : ""}`}
                </span>
            </div>,
        },
        {
            title: t('cost.baza'),
            dataIndex: 'branchName',
            key: 'branchName',
            width: '7%',
        },
        {
            title: t('block.js'),
            dataIndex: 'totalSum',
            key: 'totalSum',
            render: (item) => <p className={'m-0'}>{prettify(item, 3)} so'm</p>,
            width: '10%',
        },
        {
            title: t('block.ts'),
            dataIndex: 'paidSum',
            key: 'paidSum',
            render: (item) => <p className={'m-0'}>{prettify(item, 3)} so'm</p>,
            width: '12%',
        },
        {
            title: t('xisob.qoldiqq'),
            dataIndex: 'debt',
            key: 'debt',
            render: (item) => <p className={'m-0'}>{prettify(item, 3)} so'm</p>,
            width: '10%',
        },
        {
            title: t('xisob.Foyda'),
            dataIndex: 'profit',
            key: 'profit',
            render: (item) => <p className={'m-0'}>{prettify(item, 3)} so'm</p>,
            width: '10%',
        },
        {
            title: t('Salary.s'),
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (item) => <p className={'m-0'}>{moment(new Date(item)).format('lll')}</p>,
            width: '8%',

        },
    ];
    const [openCheckList, setOpenCheckList] = useState(false)

    const defaultCheckedList = columns.map((item) => item.key);
    const [checkedList, setCheckedList] = useState(defaultCheckedList);
    const options = columns.map(({key, title}) => ({
        label: title,
        value: key,
    }));
    const newColumns = columns.map((item) => ({
        ...item,
        hidden: !checkedList.includes(item.key),
    }));

    const newChange = (pageNumber, page) => {
        setPageData((prev) => ({...prev, pageSize: page, page: pageNumber - 1}));
        localStorage.setItem("pageSize", JSON.stringify(page))

    }

    const componentRef = useRef();
    const componentRef2 = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const handlePrint2 = useReactToPrint({
        content: () => componentRef2.current,
    });
    const [printDisplay, setPrintDisplay] = useState('none')
    const [input, setInput] = useState(
        {
            baza: null,
            tradeAction: null,
            mahsulotizlash: null,
            sananibelgilash: '',
            view: '',
            izlash: ''
        }
    )

    function baza(e) {
        input.baza = e.target.value === '' ? null : e.target.value
        let a = {...input}
        setInput(a)
        getInvoice(e.target.value === '' ? users.branchId : e.target.value)
    }


    useEffect(() => {
        if (users.viewTradeAdmin && !input.baza) {
            getSavdolarPageSize({
                id: users.businessId,
                params: {
                    page: pageData.page,
                    size: pageData.size,
                    invoice: input.mahsulotizlash,
                    backing: input.tradeAction
                }
            })
        } else {
            getSavdolarPageSize({
                id: input.baza ? input.baza : users.branchId,
                params: {
                    page: pageData.page,
                    size: pageData.size,
                    invoice: input.mahsulotizlash,
                    backing: input.tradeAction
                }
            })
        }
    }, [input.baza, input.tradeAction, input.mahsulotizlash, pageData])

    function tradeAction(e) {
        input.tradeAction = e.target.value === '' ? null : e.target.value
        let a = {...input}
        setInput(a)
    }

    function mahsulotizlash(e) {
        input.mahsulotizlash = e.target.value === '' ? null : e.target.value
        let a = {...input}
        setInput(a)
    }


    useEffect(() => {
        getTaminot(users.businessId)
    }, [ValyutaReducer.current, SavdoQoshishReducer.current,])

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
    }, [SavdoQoshishReducer.getTradeBool])

    useEffect(() => {
        setLoading(false)
        getPay(users.businessId)
    }, [])

    const [deletemodal, setdeletemodal] = useState(false)
    const [deleteID, setdeletID] = useState('')

    function deleteFunc() {
        deleteSavdolar(deleteID)
        deleteModaltoggle('')
    }

    function deleteModaltoggle(item) {
        setdeletemodal(!deletemodal)
        setdeletID(item)
    }

    const onShowSizeChange = (current, pageSize) => {
        setPageData({page: 0, size: pageSize})
    };
    const changePage = (page, pageSize) => {
        setPageData({page: page - 1, size: pageSize})
    };

    const checkModalOpen = (id, branchId) => {
        getInvoice(branchId ? branchId : (input.baza === 'barcasi' || input.baza === '') ? users.branchId : input.baza)
        getTradeById(id)
        setPrintDisplay('block')
    }

    useEffect(() => {
        if (printDisplay === 'block') {
            handlePrint()
            setTimeout(() => {
                setPrintDisplay('none')
            }, 500)
        }
    }, [SavdoQoshishReducer.getOneBoolean]);

    const germanCurrencyFormatter = new Intl.NumberFormat("uz", {notation: "standard"});


    return (
        <div className="col-md-12 mt-2 mb-4 mt-4 ">
            <div className="textHeader">
                <h2>{t('Trade.1')}</h2>
            </div>
            {
                users.viewTrade || users.viewTradeAdmin ?
                    <div className="rowStyleH">
                        <div className="qoshish">
                            <h5>{t('Buttons.16')}</h5>
                        </div>
                        <div className="row cont">
                            <div className="col-md-6">
                                <h6>{t('ProductList.8')}:</h6>
                                <select name="" className={'form-control'} value={input.baza} onChange={baza} id="">
                                    {
                                        users.viewTradeAdmin ? <option value="">Barchasi</option> : null
                                    }
                                    {
                                        users.branches?.map(item => <option value={item.id}>{item.name}</option>)
                                    }
                                </select>
                            </div>
                            {
                                users.viewTradeAdmin || users.viewTrade ? (
                                    <div className="col-md-6">
                                        <h6>Savdo holati:</h6>
                                        <select name="" className={'form-control'} value={input.tradeAction}
                                                onChange={tradeAction} id="">
                                            <option value="">Barchasi</option>
                                            <option value="true">Qaytarilganlar</option>
                                            <option value="false">Qaytarilmaganlar</option>
                                        </select>
                                    </div>
                                ) : null
                            }

                            <div className="col-md-12">
                                <h6>{t('trade.srbi')}</h6>
                                <input type="text" value={input.mahsulotizlash} placeholder={t('trade.srbi')}
                                       onChange={mahsulotizlash}
                                       className={'form-control'} min={0}/>
                            </div>
                            {/*<div className="col-md-6">*/}
                            {/*    <h6>{t('Trade.3')}:</h6>*/}
                            {/*    <input type="date" className={'form-control'} value={input.sananibelgilash}*/}
                            {/*           onChange={sananibelgilash}/>*/}
                            {/*</div>*/}
                        </div>
                        {/*<div className="row">*/}
                        {/*    */}
                        {/*</div>*/}
                    </div> : ''
            }
            <div className="row p-3">
                <div className="col-md-12">
                    <CardBody>
                        <div className="col-md-12">
                            <div className="qoshish mt-4">
                                <h5>{t('PurchaseList.9')}</h5>
                            </div>
                            <div className={`${openCheckList ? "animatsiyaBlock mb-2" : "hiddenBlock"}`}>
                                <Checkbox.Group
                                    value={checkedList}
                                    options={options}
                                    onChange={(value) => {
                                        setCheckedList(value);
                                    }}
                                />
                            </div>
                            {
                                SavdoQoshishReducer?.savdolarPageSize ?
                                    <div>
                                        <div className="table-responsive pb-4">
                                            <CommonTable total={SavdoQoshishReducer?.savdoTotal}
                                                         data={SavdoQoshishReducer?.savdolarPageSize}
                                                         // loading={loading}
                                                         scroll={{y: 'max-content', x: 1200}}
                                                         handlePageChange={(e)=>dispatch({type:'page',page:e})}
                                                         pagination={true}
                                                         page={state.page}
                                                         size={state}
                                                         columns={newColumns}
                                            />
                                        </div>
                                    </div> :
                                    <div>
                                        <h4 className={'text-center'}>{SavdoQoshishReducer.message}</h4>
                                    </div>
                            }
                        </div>
                    </CardBody>
                </div>
            </div>
            <div className="rowStyleH2">
                <div className="qoshish">
                    <h5>{t('Trade.1')}</h5>
                    {
                        users.addTrade ?
                            <Link to={'/shopping'}>
                                <button className='btn btn-primary'>+{t('Buttons.2')}</button>
                            </Link>
                            : ''
                    }
                </div>
                {
                    users.viewTrade || users.viewMyTrade || users.viewTradeAdmin ?
                        loading ?
                            SavdoQoshishReducer?.savdolarPageSize ?
                                <div>
                                    <div className="table-responsive table-wrapper-scroll-y my-custom-scrollbar">
                                        <table className='table table-striped table-bordered mt-4 '>
                                            <thead>
                                            <tr>
                                                <th>T/R</th>
                                                <th>{t('Trade.4')}</th>
                                                <th>{t('Trade.5')}</th>
                                                <th>Sotuvchi</th>
                                                <th>{t('Pagination.10')}</th>
                                                <th>{t('Purchase.15')}</th>
                                                <th>{t('ProductList.8')}</th>
                                                <th>{t('Purchase.4')}</th>
                                                <th>{t('Purchase.26')}</th>
                                                <th>{t('Purchase.22')}</th>
                                                <th>{t('Trade.6')}</th>
                                                <th>{t('Supplier.8')}</th>
                                                <th>{t('Hamkorlar.amal')}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                SavdoQoshishReducer.savdolarPageSize?.map((item, index) => <tr
                                                    key={item?.id}>
                                                    <td>{(pageData.page * pageData.size) + index + 1}</td>
                                                    <td>{formatDate(item?.payDate)}</td>
                                                    <td style={{backgroundColor: item.backing ? "#ffcc11" : 'none'}}>{item?.invoice}</td>
                                                    <td>{`${item?.trader?.firstName} ${item?.trader?.lastName}`}</td>
                                                    <td>{item?.customer?.name}</td>
                                                    <td>{item?.customer?.phoneNumber}</td>
                                                    <td>{item?.branch?.name}</td>
                                                    <td>{item?.paymentStatus?.status}</td>
                                                    <td>{item?.payMethod?.type}</td>
                                                    <td>
                                                        <div>
                                                            <h5>{item?.totalSum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {t('Hamkorlar.sum')}
                                                            </h5>
                                                        </div>
                                                        <div>
                                                            <h6>{item?.totalSumDollar} $
                                                            </h6>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            <h5>{item?.paidSum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {t('Hamkorlar.sum')}
                                                            </h5>
                                                        </div>
                                                        <div>
                                                            <h6>{item?.paidSumDollar} $
                                                            </h6>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            <h5>{item?.debtSum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {t('Hamkorlar.sum')}
                                                            </h5>
                                                        </div>
                                                        <div>
                                                            <h6>{item?.debtSumDollar} $
                                                            </h6>
                                                        </div>
                                                    </td>
                                                    <td>

                                                        <button
                                                            onClick={() => checkModalOpen(item.id, item?.branch?.id)}
                                                            className='taxrirlash checkView'><AiOutlineFileText
                                                            fontSize={18}/>{t('trade.chsh')}</button>
                                                        <button
                                                            onClick={() => getOneById(item.id)}
                                                            className='taxrirlash checkView'><AiOutlineFileText
                                                            fontSize={18}/>Ko'rish
                                                        </button>
                                                        {
                                                            users.editTrade ?
                                                                <Link to={'/turliTavar/' + item?.id}>
                                                                    <button className='taxrirlash'><img src={Edit}
                                                                                                        alt=""/> {t('Buttons.1')}
                                                                    </button>
                                                                </Link> : ''
                                                        }
                                                        {
                                                            users.editTrade ?
                                                                <Link
                                                                    to={'/mahsulotQaytarish/' + item?.id + "/" + item?.id}>
                                                                    <button className='taxrirlash'><img src={Edit}
                                                                                                        alt=""/>{t('trade.q')}
                                                                    </button>
                                                                </Link> : ''
                                                        }
                                                        {
                                                            users.deleteTrade ?
                                                                <button onClick={() => deleteModaltoggle(item.id)}
                                                                        className='ochirish'><img
                                                                    src={Delete} alt=""/> {t('Buttons.3')}</button> : ''
                                                        }
                                                    </td>
                                                </tr>)
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="mt-3 pagination">
                                        <Pagination
                                            showSizeChanger
                                            onShowSizeChange={onShowSizeChange}
                                            pageSizeOptions={[5, 10, 20, 50]}
                                            pageSize={pageData.size}
                                            onChange={changePage}
                                            total={SavdoQoshishReducer?.savdoTotal}
                                        />
                                    </div>
                                </div> :
                                <div className={'border border-2'}>
                                    <h4 className={'text-center'}>{SavdoQoshishReducer.message || t('Darslik.mtt')}</h4>
                                </div> : <Loading/>
                        : ''
                }
                <Modal isOpen={deletemodal} toggle={deleteModaltoggle}>
                    <ModalBody>
                        <h5>{t('Buttons.12')} ?</h5>
                    </ModalBody>
                    <ModalFooter>
                        <button onClick={deleteFunc}
                                className={'btn btn-outline-primary'}>{t('Buttons.3')}</button>
                        <button onClick={() => deleteModaltoggle('')}
                                className={'btn btn-outline-primary'}>{t('Buttons.7')}</button>
                    </ModalFooter>
                </Modal>

                <div style={{display: printDisplay}} className={'shoppingmodal p-2'} ref={componentRef}>
                    <div className={'d-flex justify-content-center align-items-center'}>
                        {
                            checkReducer.check ?
                                checkReducer.check.photoId ?
                                    <Imagecom id={checkReducer.check.photoId}/> : ''
                                : ''
                        }
                    </div>
                    <h2 className={'text-center'}>{
                        checkReducer.check ?
                            checkReducer.check.name : ''
                    }
                    </h2>
                    {
                        checkReducer.check ?
                            <div dangerouslySetInnerHTML={{__html: checkReducer.check.description}}>
                            </div>
                            : ''
                    }
                    <br/>
                    <div className={'d-flex justify-content-between align-items-center'}>
                        <h4 style={{fontSize: 12, fontWeight: 600}}>
                            {
                                moment(new Date(SavdoQoshishReducer?.trade?.createdAt)).format("DD:MM:YYYY")
                            }
                        </h4>
                        <h4 style={{fontSize: 12, fontWeight: 600}}>
                            {
                                moment(new Date(SavdoQoshishReducer?.trade?.createdAt)).format("HH:mm:ss")
                            }
                        </h4>
                    </div>
                    <div className={'d-flex justify-content-between align-items-center'}>
                        <h4 style={{fontSize: 12, fontWeight: 600}}>
                            {t('tarde.sri')}
                        </h4>
                        <h4 style={{fontSize: 12, fontWeight: 600}}>
                            {
                                SavdoQoshishReducer?.trade?.invoice
                            }
                        </h4>
                    </div>
                    <div className={'d-flex align-items-center justify-content-between'}>
                        <h4 style={{fontSize: 12, fontWeight: 600}}>{t('Hamkorlar.mijoz')} </h4>
                        <h5 style={{fontSize: 12, fontWeight: 600}}> {SavdoQoshishReducer?.trade?.customer?.name}</h5>
                    </div>
                    <div style={{borderBottom: "1px dashed #000"}}></div>
                    <div className={'mt-3 table-responsive'}>
                        {
                            SavdoQoshishReducer?.tradeProductList?.map((item, index) => <div key={item.id}>
                                <h4 style={{
                                    fontSize: 12,
                                    fontWeight: 600
                                }}>{index + 1}{".  "}{item.productTypePrice ? item?.productTypePrice?.name : item?.product?.name}</h4>
                                <div style={{marginLeft: 20, marginTop: -7}}
                                     className={"d-flex align-items-center justify-content-between"}>
                                    <h4 style={{
                                        fontSize: 12,
                                        fontWeight: 600,
                                        lineHeight: 1
                                    }}>{item.subMeasurement ? (item?.productTypePrice ? item?.productTypePrice?.product?.measurement.value : item?.product?.measurement.value) * item.tradedQuantity : item.tradedQuantity} {item.subMeasurement ? (item?.productTypePrice ? item?.productTypePrice?.product?.measurement?.subMeasurement?.name : item?.product?.measurement?.subMeasurement?.name) : item?.productTypePrice ? item?.productTypePrice?.product?.measurement.name : item?.product?.measurement.name} *
                                        {(item.totalSalePrice / item.tradedQuantity)} {t('Hamkorlar.sum')}</h4>
                                    <h4 style={{fontSize: 12, fontWeight: 600, lineHeight: 1}}>
                                        = {germanCurrencyFormatter.format(parseFloat(item.totalSalePrice).toFixed(0))}{t('Hamkorlar.sum')}
                                    </h4>
                                </div>
                            </div>)
                        }
                    </div>
                    {
                        SavdoQoshishReducer?.trade?.backing &&
                        <div className={'mt-3 table-responsive pt-1'} style={{borderTop: '1px solid gray'}}>
                            <h4 style={{fontSize: 12, fontWeight: 600, textAlign: 'center'}}>Qaytarilgan
                                maxsulotlar</h4>
                            {
                                SavdoQoshishReducer?.tradeProductList?.map((item, index) =>
                                    item.backing > 0 &&
                                    <div key={item.id}>
                                        <h4 style={{
                                            fontSize: 12,
                                            fontWeight: 600
                                        }}>{index + 1}{".  "}{germanCurrencyFormatter.format(parseFloat(item.productTypePrice).toFixed(0)) ? item?.productTypePrice?.name : item?.product?.name}</h4>
                                        <div style={{marginLeft: 20, marginTop: -7}}
                                             className={"d-flex align-items-center justify-content-between"}>
                                            <h4 style={{
                                                fontSize: 12,
                                                fontWeight: 600,
                                                lineHeight: 1
                                            }}>{item.subMeasurement ? (item?.productTypePrice ? item?.productTypePrice?.product?.measurement.value : item?.product?.measurement.value) * item.backing : item.backing} {item.subMeasurement ? (item?.productTypePrice ? item?.productTypePrice?.product?.measurement?.subMeasurement?.name : item?.product?.measurement?.subMeasurement?.name) : item?.productTypePrice ? item?.productTypePrice?.product?.measurement.name : item?.product?.measurement.name} *
                                                {(item.totalSalePrice / item.tradedQuantity)} {t('Hamkorlar.sum')}</h4>
                                            <h4 style={{fontSize: 12, fontWeight: 600, lineHeight: 1}}>
                                                = {germanCurrencyFormatter.format(parseFloat((item.totalSalePrice / item.tradedQuantity) * item.backing).toFixed(0))}{t('Hamkorlar.sum')}
                                            </h4>
                                        </div>
                                    </div>)
                            }
                        </div>
                    }

                    <div style={{borderBottom: "1px dashed #000", marginTop: 20}}></div>
                    <div className={'d-flex'}>
                        <div style={{width: "100%"}}>
                            <div className={"d-flex justify-content-between"}>
                                <h4 style={{fontSize: 14, fontWeight: 800}}>{t('Buttons.10')} </h4>
                                <h4 style={{
                                    fontSize: 14,
                                    fontWeight: 800
                                }}>{germanCurrencyFormatter.format(parseFloat(SavdoQoshishReducer?.trade?.totalSum).toFixed(0))} {t('Hamkorlar.sum')}</h4>
                            </div>
                            {
                                SavdoQoshishReducer.paymentDtoList ?
                                    SavdoQoshishReducer.paymentDtoList.map(item =>
                                        <div className={"d-flex justify-content-between"}>
                                            <h4 style={{fontSize: 13, fontWeight: 600}}>{
                                                PayReducer.paymethod.filter(val => item.paymentMethodId === val.id)[0]?.type
                                            }:</h4>
                                            <h4 style={{
                                                fontSize: 13,
                                                fontWeight: 600
                                            }}>{germanCurrencyFormatter.format(parseFloat(item.paidSum).toFixed(0))} {t('Hamkorlar.sum')}</h4>
                                        </div>
                                    ) : ''
                            }
                            <div className={"d-flex justify-content-between"}>
                                <h4 style={{fontSize: 13, fontWeight: 600}}>{('To\'langan summa:')}</h4>
                                <h4 style={{
                                    fontSize: 13,
                                    fontWeight: 600
                                }}>{germanCurrencyFormatter.format(parseFloat(SavdoQoshishReducer?.trade?.paidSum).toFixed(0))} {t('Hamkorlar.sum')}</h4>
                            </div>
                            {
                                SavdoQoshishReducer?.trade?.customer ?
                                    <div className={"d-flex justify-content-between"}>
                                        <h4 style={{fontSize: 13, fontWeight: 600}}>{t('trade.bn')} </h4>
                                        <h4 style={{fontSize: 13, fontWeight: 600}}>
                                            {germanCurrencyFormatter.format(parseFloat(SavdoQoshishReducer?.trade?.debtSum).toFixed(0))} {t('Hamkorlar.sum')}
                                        </h4>
                                    </div> : ''
                            }
                            {
                                SavdoQoshishReducer?.trade?.customer ?
                                    <div className={"d-flex justify-content-between"}>
                                        <h4 style={{fontSize: 14, fontWeight: 800}}>{t('trade.uq')}</h4>
                                        <h4 style={{
                                            fontSize: 14,
                                            fontWeight: 800
                                        }}>{germanCurrencyFormatter.format(parseFloat(SavdoQoshishReducer.trade?.customer?.debt).toFixed(0))} {t('Hamkorlar.sum')}</h4>
                                    </div> : ''
                            }
                        </div>
                    </div>
                    <div style={{borderBottom: "1px dashed #000"}}></div>
                    {
                        checkReducer.check ?
                            <div dangerouslySetInnerHTML={{__html: checkReducer.check.footer}}>
                            </div>
                            : ''
                    }
                </div>
                <Modal isOpen={viewOneTrade} size={'xl'} ref={componentRef2}
                       toggle={() => setViewOneTrade(!viewOneTrade)}>
                    <ModalHeader>
                        <h4>
                            Savdo Ma'lumotlarini ko'rish
                        </h4>
                    </ModalHeader>
                    <ModalBody>
                        {
                            SavdoQoshishReducer.trade ?
                                <div>
                                    <div className="col-md-12 d-flex">
                                        <div className="col-md-6">
                                            <h6>{t('block.xs')} {formatDate(SavdoQoshishReducer.trade.createdAt)}</h6>
                                            <h6>{t('block.tt')} {SavdoQoshishReducer.trade?.payMethod?.type}</h6>
                                            <h6>{t('block.th')} {SavdoQoshishReducer.trade?.paymentStatus?.status}</h6>
                                        </div>
                                        <div className="col-md-6">
                                            <h6>Mijoz: {SavdoQoshishReducer.trade?.customer?.name}</h6>
                                            <h6 className={'d-flex justify-content-between'}> {t('block.js')}
                                                <div>
                                                    <h6>{SavdoQoshishReducer.trade?.totalSum} {t('Hamkorlar.sum')}</h6>
                                                    <h6>{(SavdoQoshishReducer.trade?.totalSum / ValyutaReducer?.currency?.course).toFixed(2)} $</h6>
                                                </div>
                                            </h6>
                                            <h6 className={'d-flex justify-content-between'}> {t('block.ts')}
                                                <div>
                                                    <h6>{SavdoQoshishReducer.trade?.paidSum} {t('Hamkorlar.sum')}</h6>
                                                    <h6>{(SavdoQoshishReducer.trade?.paidSum / ValyutaReducer?.currency?.course).toFixed(2)} $</h6>
                                                </div>
                                            </h6>
                                            <h6 className={'d-flex justify-content-between'}>{t('Hamkorlar.qarz')}
                                                <div>
                                                    <h6>{SavdoQoshishReducer.trade?.debtSum} {t('Hamkorlar.sum')}</h6>
                                                    <h6>{(SavdoQoshishReducer.trade?.debtSum / ValyutaReducer?.currency?.course).toFixed(2)} $</h6>
                                                </div>
                                            </h6>
                                        </div>
                                    </div>
                                </div> : t('Darslik.mtt')
                        }
                        {SavdoQoshishReducer.tradeProductList ?
                            <div className={'table-responsive'}>
                                <table className={'table table-bordered'}>
                                    <thead>
                                    <tr>
                                        <th>{t('block.m')}</th>
                                        <th>{t('block.mi')}</th>
                                        <th>{t('block.q')}</th>
                                        <th>{t('block.f')}</th>
                                        <th>{t('block.js')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        SavdoQoshishReducer.tradeProductList.map(item =>
                                            <tr>
                                                <th>{item?.product ? item.product?.name : item?.productTypePrice?.name}</th>
                                                <th>{item.remainQuantity} {item?.product ? item.product?.measurement?.name : item?.productTypePrice?.product?.measurement?.name}</th>
                                                <th>{item?.tradedQuantity} {item?.product ? item.product?.measurement?.name : item?.productTypePrice?.product?.measurement?.name}</th>
                                                <th>
                                                    <div>
                                                        <h6>{item?.profit} {t('Hamkorlar.sum')}</h6>
                                                        <h6>{(item?.profit / ValyutaReducer?.currency?.course).toFixed(2)} $</h6>
                                                    </div>

                                                </th>

                                                <th>
                                                    <div>
                                                        <h6>{item.totalSalePrice} {t('Hamkorlar.sum')}</h6>
                                                        <h6>{(item.totalSalePrice / ValyutaReducer?.currency?.course).toFixed(2)} $</h6>
                                                    </div>

                                                </th>
                                            </tr>
                                        )
                                    }

                                    </tbody>
                                </table>
                            </div>
                            : <div><h4 className={'text-center'}>{t('Darslik.mtt')}</h4></div>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <button className={'btn btn-danger'} onClick={() => setViewOneTrade(!viewOneTrade)}>Chiqish
                        </button>
                        <button className={'btn btn-info'} onClick={() => handlePrint2()}>Pdfni yuklash</button>
                    </ModalFooter>
                </Modal>

            </div>

        </div>

    )
}

export default connect((TaminotReducer, checkReducer, SavdoQoshishReducer, users, ValyutaReducer, PayReducer), {
    getSavdolar3,
    getInvoice,
    getTradeById,
    getSavdolar,
    getTaminot,
    getSavdolar2,
    saveSavdolar,
    editSavdolar,
    deleteSavdolar,
    getSavdolarPageSize,
    getPay
})(BarchaSavdolar)
