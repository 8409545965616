import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../../../../../api";

const slice = createSlice({
    name: 'mijozhisobot',
    initialState: {
        mijozhisobot: [],
        mijozhisobot2:null,
        loading: false,
        message:''
    },
    reducers: {
        getFrom: (state, action) => {
            state.mijozhisobot = action.payload.object
            state.loading = !state.loading
        },
        getFrom2: (state, action) => {
             if (action.payload.success){
                 state.mijozhisobot2 = action.payload.object
             }
             else{
                 state.message = action.payload.message
                 state.mijozhisobot2 = null
             }
            state.loading = !state.loading
        },
    }
});

export const getMijozhisobot=(data)=>apiCall({
    url: '/trade/product/get-all-trade/'+data,
    method:'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type
});
// export const getMijozhisobotsana=(data)=>apiCall({
//     url: `/trade/product/get-all-trade/${data.branchId}/?startDate=${data.startDate}&endDate=${data.endDate}`,
//     method:'get',
//     onSuccess: slice.actions.getFrom.type
// });

export const getMijozhisobot2=(data)=>apiCall({
    url: '/reports/customer/'+data,
    method:'get',
    onSuccess: slice.actions.getFrom2.type,
    onFail: slice.actions.getFrom2.type
});

export const getMijozhisobot3=(data)=>apiCall({
    url: `/reports/customer/${data.branchId}/?categoryId=${data.customerId}`,
    method:'get',
    onSuccess: slice.actions.getFrom2.type,
    onFail: slice.actions.getFrom2.type
});

export const getMijozhisobotsana=(data)=>apiCall({
    url: `/reports/customer/${data.branchId}`,
    method:'get',
    params: {
        page: data.page,
        size: data.size,
        customerId: data.customer,
        sellerId: data.seller,
        startDate: data.startDate,
        endDate: data.endDate
    },
    onSuccess: slice.actions.getFrom2.type,
    onFail:slice.actions.getFrom2.type
});


export default slice.reducer
