import React, {useState, useEffect} from 'react';
import {
    Box, Button, Dialog, DialogTitle,
    Grid, IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead, TablePagination,
    TableRow, Tabs, ToggleButton, ToggleButtonGroup, Tooltip,
    Typography
} from '@mui/material';
import {Link} from "react-router-dom";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import {styled} from "@mui/material/styles";
import TableRowsTwoToneIcon from "@mui/icons-material/TableRowsTwoTone";
import GridViewTwoToneIcon from "@mui/icons-material/GridViewTwoTone";
import {useTranslation} from "react-i18next";
import users from "../../../../../reducer/users";
import {connect} from "react-redux";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import LavozimReducer, {
    deleteLavozim,
    editLavozim,
    getLavozim,
    saveLavozim
} from "../../Hodimlar/reducer/LavozimReducer";
import ModalLoading from "../../../../ModalLoading";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import Loading from "../../../../Loading";
import "./orgStructura.css"
import OrgChart from "./OrgChart/OrgChart";

function OrgStruktura({users, getLavozim, LavozimReducer, deleteLavozim}) {

    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [openComponent,setOpenComponent] = useState(1);

    const handleCreateUserOpen = () => {
        setOpen(true);
    };

    const handleCreateUserClose = () => {
        setOpen(false);
    };


    const [filters, setFilters] = useState({
        role: null
    });

    const [toggleView, setToggleView] = useState(1);


    const TabsWrapper = styled(Tabs)(
        ({theme}) => `
    @media (max-width: ${theme.breakpoints.values.md}px) {
      .MuiTabs-scrollableX {
        overflow-x: auto !important;
      }

      .MuiTabs-indicator {
          box-shadow: none;
      }
    }
    `
    );
    const handleTabsChange = (_event, tabsValue) => {
        let value = null;

        if (tabsValue !== 'all') {
            value = tabsValue;
        }

        setFilters((prevFilters) => ({
            ...prevFilters,
            role: value
        }));

    };

    const [activeLidStatus, setActiveLidStatus] = useState(false)

    const [saveModal, setSaveModal] = useState(false)

    function LidStatusToggle() {
        setActiveLidStatus(!activeLidStatus)
    }

    useEffect(() => {
        getLavozim(users.businessId)
    }, [])

    const [loading, setLoading] = useState(false)
    const [deletemodal, setdeletemodal] = useState(false)
    const [deleteID, setdeletID] = useState('')
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        setLoading(false)
    }, [])

    useEffect(() => {
        setLoading(true)
    }, [LavozimReducer.getBoolean])

    function deleteModaltoggle(id) {
        setdeletemodal(!deletemodal)
        setdeletID(id)
    }

    function deleteFunc() {
        deleteLavozim(deleteID)
        setSaveModal(true)
    }

    useEffect(() => {
        if (LavozimReducer.deleteBoolean) {
            setdeletID('')
            deleteModaltoggle('')
        }
        setSaveModal(false)
        getLavozim(users.businessId)
    }, [LavozimReducer.deleteCurrent])

    const handleChangePage = (_event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            <TabsWrapper
                onChange={handleTabsChange}
                scrollButtons="auto"
                textColor="secondary"
                value={filters.role || 'all'}
                variant="scrollable"
            >
                <ToggleButtonGroup
                    sx={{
                        mt: {xs: 2, sm: 0}
                    }}
                    exclusive
                >
                    <Tooltip title={t('ol.20')}  arrow>
                        <ToggleButton disableRipple onClick={()=>setToggleView(1)}>
                            <TableRowsTwoToneIcon/>
                        </ToggleButton>
                    </Tooltip>
                    <Tooltip title={t('ol.21')} arrow>
                        <ToggleButton disableRipple onClick={()=>setToggleView(2)}>
                            <GridViewTwoToneIcon/>
                        </ToggleButton>
                    </Tooltip>
                </ToggleButtonGroup>
            </TabsWrapper>
            {
                toggleView == 1 ?
                    <div className={'colorback mt-4 m-3 p-4'}>

                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <Typography variant="h4" component="h4" gutterBottom>
                                    {t('Org.org')}
                                </Typography>

                            </Grid>

                        </Grid>

                        <Box
                            display="flex"
                            alignItems="center"
                            flexDirection={{xs: 'column', sm: 'row'}}
                            justifyContent={{xs: 'center', sm: 'space-between'}}
                            pb={3}
                        >
                            <div className={'d-flex mt-4 mb-4'}>
                                <Grid item>
                                    <Link to={'/main/addRole'}>
                                        <Button
                                            sx={{mt: {xs: 2, sm: 0}}}
                                            onClick={handleCreateUserOpen}
                                            variant="contained"
                                            startIcon={<AddTwoToneIcon fontSize="small"/>}
                                        >
                                            {t('Org.qlo')}
                                        </Button>
                                    </Link>
                                </Grid>

                                {/*<Grid item className={'ms-2'}>*/}
                                {/*    <Button sx={{mt: {xs: 2, sm: 0}}} variant="contained">*/}
                                {/*        {t('Org.e')}*/}
                                {/*    </Button>*/}
                                {/*</Grid>*/}

                                {/*<button className={'btn btn-outline-dark ms-2'}>Export</button>*/}

                            </div>


                        </Box>


                        <Dialog
                            fullWidth
                            maxWidth="xl"
                            open={open} onClose={handleCreateUserClose}>
                            <DialogTitle sx={{p: 3}}>
                                <Typography variant="h4" gutterBottom>
                                    {t('Org.ysq')}
                                </Typography>
                            </DialogTitle>

                        </Dialog>
                        <div className={'mb-2'}>
                            <input type="search" placeholder={t('ol.1')} className={'form-control'}/>
                        </div>
                        {
                            LavozimReducer.lavozimlar ?
                                loading ?
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>#</TableCell>
                                                    <TableCell>{t('Lids.Ismi')}</TableCell>
                                                    <TableCell align={'center'}>{t('Davomat.amal')}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    LavozimReducer.lavozimlar?.map((item, index) => <TableRow key={index}>
                                                        <TableCell>{index + 1}</TableCell>
                                                        <TableCell>{item.name}</TableCell>
                                                        <TableCell align="center">
                                                            <Typography noWrap>
                                                                <Tooltip title={t('ol.14')} arrow>
                                                                    <IconButton color="primary">
                                                                        <Link to={'/headerthird/hr/addRole/' + item.id}>
                                                                            <EditTwoToneIcon fontSize="small"/>
                                                                        </Link>
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title={t('ol.15')} arrow>
                                                                    <IconButton
                                                                        onClick={() => deleteModaltoggle(item.id)}
                                                                        color="primary">
                                                                        <DeleteTwoToneIcon fontSize="small"/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>)
                                                }

                                            </TableBody>
                                        </Table>
                                    </TableContainer> : <Loading/> : <div><h4 className={'text-center'}>{t('Darslik.mtt')}</h4></div>
                        }
                        <Box className={'d-flex justify-content-end'} p={2}>
                            <TablePagination
                                component="div"
                                count={LavozimReducer?.lavozimlar?.length}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                rowsPerPageOptions={[5, 10, 15]}
                            />
                        </Box>
                    </div>
                : <OrgChart/>
            }

            <Modal isOpen={deletemodal} toggle={deleteModaltoggle}>
                <ModalBody>
                    <h5>{t('Buttons.12')} ?</h5>
                </ModalBody>
                <ModalFooter>
                    <button onClick={deleteFunc}
                            className={'btn btn-outline-primary'}>{t('Buttons.3')}</button>
                    <button onClick={() => deleteModaltoggle('')}
                            className={'btn btn-outline-primary'}>{t('Buttons.7')}</button>
                </ModalFooter>
            </Modal>


            <Modal isOpen={activeLidStatus} toggle={LidStatusToggle}>
                <ModalHeader>
                    <h6>{t('Davomat.lsqushish')}</h6>
                </ModalHeader>
                <ModalBody className={'d-flex'}>
                    <div className="col-md-6">
                        <label htmlFor="mjs">{t('Davomat.mijozstatus')}</label>
                        <input className={'form-control'} type="text"/>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="mjs">{t('Davomat.rb')}</label>
                        <input className={'form-control'} type="color"/>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button className={'btn btn-outline-primary'}>{t('Davomat.saqlash')}</button>
                    <button onClick={LidStatusToggle} className={'btn btn-outline-primary'}>{t('Davomat.chiqish')}</button>
                </ModalFooter>
            </Modal>

            <ModalLoading isOpen={saveModal}/>
        </>
    );
}
export default connect((users, LavozimReducer), {getLavozim, saveLavozim, editLavozim, deleteLavozim})(OrgStruktura)
