import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../../../../../api";
// import {toast} from "react-toastify";

const slice = createSlice({
    name: 'reports',
    initialState: {
        reports: null,
        reports2: null,
        message:''
    },
    reducers: {
        getFrom: (state, action) => {
            if (action.payload.success){
                state.reports = action.payload.object
            }
            else{
                state.message = action.payload.message
                state.reports = null
            }

        },
        getFrom2: (state, action) => {
            if (action.payload.success){
                state.reports2 = action.payload.object
            }
            else{
                state.reports2 = null
                state.message = action.payload.message
            }
        },

    }

});


export const getQoldiq3=(data)=>apiCall({
    url: `/reports/all-by-branch/${data.branchId}/?${data.category ? `categoryId=${data.category}`:''}${data.brand ? `&brandId=${data.brand}`:''}`,
    method:'get',
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type
});
export const getQoldiq2=(data)=>apiCall({
    url: '/reports/amounts-branch',
    method:'get',
    params:data,
    onSuccess: slice.actions.getFrom2.type,
    onFail:slice.actions.getFrom2.type
});

export default slice.reducer