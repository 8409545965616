import '../MaxsulotlarXisoboti/maxsulotxisoboti.css'
import React, {useState, useEffect} from "react";

import {connect} from 'react-redux'

import {useTranslation} from "react-i18next";
import users from "../../../../../reducer/users";
import {DatePicker} from "antd";
import {
    Avatar,
    AvatarGroup, Box, Card, FormControl, IconButton, InputLabel, MenuItem, Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead, TablePagination,
    TableRow,
    Tooltip,
    Typography,
    Button
} from "@mui/material";
import formatDate from "../../../../../util";
import SavdoQoshishReducer, {getBackingProductMore} from "../../Savdo/reducer/SavdoQoshishReducer";
import {Link} from "react-router-dom";


function RemainProductMore({
                               users,
                               SavdoQoshishReducer,
                               getBackingProductMore,
                                match
                           }) {

    const {t} = useTranslation()

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(5);
    const [branch, setBranch] = useState(null)


    const handlePageChange = (_event, newPage) => {
        setPage(newPage);
    };

    const handleLimitChange = (event) => {
        setLimit(parseInt(event.target.value));
    };

    function changeBranch(e) {
        setBranch(e.target.value)
    }



    useEffect(() => {
        getBackingProductMore({
            branchId: branch ? branch : users.branchId,
            params: {
                productId:match.params.id,
                page: page,
                size: limit
            }
        })
    }, [page, limit,branch])



    return (
        <div className="col-md-12 mt-4 mb-4">
            <div className="col-md-12 d-flex align-items-start justify-content-between">
                <div className="textHeader col-md-4">
                    <h2>{t('xisob.Mahsulotmiqdori')}</h2>
                </div>
            </div>


                    <div className="rowStyleMax2">
                        <div className="qoshish mt-4 mb-5 d-flex align-items-center">
                            <div className={'d-flex align-items-start'}>
                                <h5>{t('xisob.Mahsulot:')} {match.params.name}</h5>
                            </div>
                            {
                                users.branches?.length > 1 ?
                                    <div className="col-4 col-sm-4">
                                        <h6>{t('Salary.f')}</h6>
                                        <select className={'form-control'} value={branch ? branch : users.branchId}
                                                onChange={changeBranch}>
                                            {
                                                users.branches ?
                                                    users.branches.map((item, index) =>
                                                        <option key={index} value={item.id}>{item.name}</option>) : ''
                                            }
                                        </select>

                                    </div>
                                    : ''
                            }
                        </div>
                        <Card>
                            <>
                                {
                                    SavdoQoshishReducer.backingProductMore ?
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            #
                                                        </TableCell>
                                                        <TableCell>{t('Hamkorlar.mijoz')}</TableCell>
                                                        <TableCell>{t('block.mi')}</TableCell>
                                                        <TableCell>{t('Hamkorlar.sana ')}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        SavdoQoshishReducer.backingProductMore?.getLessProduct.map((item, index) =>
                                                            <TableRow>
                                                                <TableCell>{(page * limit) + index + 1}</TableCell>
                                                                <TableCell>{item.customerName}</TableCell>
                                                                <TableCell>{parseFloat(item.quantity).toFixed(2)} {match.params.measurement}</TableCell>
                                                                <TableCell>{formatDate(item.date)}</TableCell>
                                                            </TableRow>
                                                        )
                                                    }

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        : <h4 className={'text-center'}>{t('Darslik.mtt')}</h4>
                                }
                                {
                                    SavdoQoshishReducer.backingProductMore ?
                                        <Box p={2}>
                                            <TablePagination
                                                component="div"
                                                count={SavdoQoshishReducer.backingProductMore?.totalItem}
                                                onPageChange={handlePageChange}
                                                onRowsPerPageChange={handleLimitChange}
                                                page={page}
                                                rowsPerPage={limit}
                                                rowsPerPageOptions={[5, 8, 10]}
                                            />
                                        </Box>
                                        : ''
                                }
                            </>
                        </Card>

                    </div>
        </div>
    )
}

export default connect((users, SavdoQoshishReducer), {getBackingProductMore})(RemainProductMore)
