import {connect} from "react-redux";
import {useEffect} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {useTranslation} from "react-i18next";
import {Tabs} from "antd";

import Bazalar from "./bazalar/Bazalar";
import Dukon from "./DukonSozlamalari/Dukon";
import users from "../../../../reducer/users";

import './sozlamalar.css'

function Sozlamalar({users}) {
    const {t} = useTranslation()
    const history = useHistory()
    const location = useLocation()

    const items = [
        {
            label: t('Sidebar.41'),
            key: '/main/settings/branches',
            children: <Bazalar/>,
        },
        {
            label: t('Sidebar.40'),
            key: `/main/settings/settingsShop`,
            children: <Dukon/>,
        },
    ]

    useEffect(() => {
        !items.some(item=>item.key === location.pathname) && history.push('/main/settings/branches')
    }, []);
    const onChange = (key) => {
        history.push(key)
    };
    return(
        <div className={'row list'} id={'setting'}>
            <h4 className={'p-3 mainText'}>{t('Sidebar.40')}</h4>
            <div className={'px-3'}>
                <Tabs
                    activeKey={location.pathname}
                    onChange={onChange}
                    type="card"
                    items={items}
                />
            </div>
        </div>
    )
}
export default  connect((users),{})  (Sozlamalar)
