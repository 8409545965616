import {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useHistory} from 'react-router-dom'
import {useTranslation} from "react-i18next";
import {Input} from "antd";
import LavozimReducer, {getLavozim} from "../reducer/LavozimReducer";
import users from "../../../../../reducer/users";
import CommonTable from "../../../../Components/CommonTable";
import {setLocalStorage, tablePageSize} from "../../../../../util";
import "./lavozimlar.css"
import {EditButton, QushishButton} from "../../../../Components/Buttons";

function Lavozimlar({getLavozim, users, LavozimReducer}) {
    const {t} = useTranslation()
    const history = useHistory()
    const [pageData, setPageData] = useState({
        page: 0,
        pageSize: tablePageSize ? tablePageSize : 10,
        loading: false,
        searchValue: ""
    });
    const columns = [
        {
            key: "id",
            title: 'Id',
            dataIndex: 'index',
            width: '3%',
            fixed: 'left',
        },
        {
            title: t('Roles.1'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('Buttons.9'),
            dataIndex: 'profit',
            key: 'profit',
            render: (item, record) => <div>
                {
                    users.editRole ? <>
                          <EditButton  onClick={() => history.push(`/main/addRole/${record?.id}`)}/>
                      </>
                    : ''
                }
            </div>,
            width: '20%',
        },
    ];

    function searchRole(e) {
        setPageData( (prev) => ({...prev, searchValue: e?.target?.value}))
    }

    useEffect(() => {
        getLavozim(users.businessId)
        setPageData( (prev) => ({...prev, loading: true}))
    }, [LavozimReducer.current])

    useEffect(() => {
        setPageData( (prev) => ({...prev, loading: false}))
    }, [LavozimReducer.getBoolean])

    useEffect(() => {
        setPageData( (prev) => ({...prev, loading: true}))
    }, [])

    const newChange = (pageNumber, page) => {
        setPageData((prev) => ({ ...prev, pageSize: page, page: pageNumber - 1 }));
        setLocalStorage("pageSize", page)
    };

    return (
      <div className="col-md-12 pt-4 pb-4 ">
          <div className="rowStyleL">
              <div className="qoshish mb-3">
                  <h5>{t('Roles.3')}</h5>
                  {
                      users.addRole ? <QushishButton onClick={() => history.push('/main/addRole')} buttonText={t('Buttons.2')}/> : ''
                  }
              </div>
              {users.viewRole || users.viewRoleAdmin ?
                    <div>
                        <div className="mb-2">
                            <Input allowClear rootClassName="w-full" onChange={searchRole}
                                   placeholder={t("num.37")}/>
                        </div>
                        <CommonTable loading={pageData.loading} scroll={{x: 1200}}
                                     total={LavozimReducer?.lavozimlar?.filter((val) => pageData.searchValue === '' ? val : val.name.toUpperCase().includes(pageData.searchValue.toUpperCase()) && val)?.length} handlePageChange={newChange}
                                     pagination={true} page={pageData.page} size={pageData.pageSize}
                                     data={LavozimReducer?.lavozimlar?.filter((val) => pageData.searchValue === '' ? val : val.name.toUpperCase().includes(pageData.searchValue.toUpperCase()) && val)} columns={columns}/>

                    </div>
                : null
              }
          </div>
      </div>
    )
}

export default connect((LavozimReducer, users), {
    getLavozim,
})(Lavozimlar)
