import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "../api";
import {toast} from "react-toastify";

const slice = createSlice({
    name: 'tasks',
    initialState: {
        tasks:null,
        taskPage:null,
        taskPageAble:null,
        taskByProject:null,
        taskAll:null,
        current:false,
        getBoolean:false,
        getOneTaskBoolean:false,
        getPage:false,
        saveBoolean:false,
        saveBooleanInvalid:false,
        deleteBoolean: false,
        message:'',
        taskOne: null
    },
    reducers: {
        getFrom: (state, action) => {
            if (action.payload.success){
                state.tasks = action.payload.object.content
                state.taskPage = action.payload.object
            }
            else{
                state.tasks = null
                state.taskPage = null
                state.message = action.payload.message
            }
            state.getBoolean = !state.getBoolean
            state.saveBoolean = false
            state.saveBooleanInvalid = false

        },
        getFromPage: (state, action) => {
            if (action.payload.success){
                state.taskPageAble = action.payload.object
            }
            else{
                state.taskPageAble = null
                state.message = action.payload.message
            }
            state.getBoolean = !state.getBoolean
            state.getPage = !state.getPage
            state.saveBoolean = false
            state.saveBooleanInvalid = false

        },
        getFromAll: (state, action) => {
            if (action.payload.success){
                state.taskAll = action.payload.object
            }
            else{
                state.taskAll = null
                state.message = action.payload.message
            }
            state.getBoolean = !state.getBoolean
            state.saveBoolean = false
            state.saveBooleanInvalid = false

        },
        getFromProject: (state, action) => {
            if (action.payload.success){
                state.taskByProject = action.payload.object
            }
            else{
                state.taskByProject = null
                state.message = action.payload.message
            }
            state.getBoolean = !state.getBoolean
            state.saveBoolean = false
            state.saveBooleanInvalid = false

        },
        getTaskView:(state,action)=>{
            if (action.payload.success){
                state.taskOne = action.payload.object
            }
            else{
                state.taskOne = null
            }
            state.getOneTaskBoolean = !state.getOneTaskBoolean
        },
        savefrom: (state,action) => {
            if (action.payload.success){
                toast.success('Saqlandi')
                state.saveBoolean = true
            }
            else{
                toast.error(action.payload.message)
            }
            state.current=!state.current
        },
        editfrom: (state,action) => {
            if (action.payload.success){
                toast.success('Tahrirlandi')
                state.saveBoolean = true
            }
            else{
                toast.error(action.payload.message)
            }
            state.current=!state.current

        },
        invalidFrom: (state,action) => {
            if (action.payload.success){
                toast.success('Success')
                state.saveBooleanInvalid = true
            }
            else{
                toast.error(action.payload.message)
            }

            state.current=!state.current

        },
        deletefrom:(state,action)=>{
            if(action.payload.success){
                toast.success('Ruyhatdan o`chirildi')
                state.deleteBoolean=!state.deleteBoolean
            }
            else {
                toast(action.payload.message)
            }
            state.current=!state.current
        }
    }
});

export const getTask=(data)=>apiCall({
    url: `/task/get-by-branch/`,
    method:'get',
    params:data.params,
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type,
});
export const getTaskByName=(data)=>apiCall({
    url: `/task/get-by-name/${data.name}`,
    method:'get',
    params:data.params,
    onSuccess: slice.actions.getFrom.type,
    onFail: slice.actions.getFrom.type,
});
export const getTaskPage=(data)=>apiCall({
    url: `/task/get-by-branch-pageable/`,
    method:'get',
    params:data.params,
    onSuccess: slice.actions.getFromPage.type,
    onFail: slice.actions.getFromPage.type,
});
export const getTaskAllByBranch=(data)=>apiCall({
    url: `/task/branch/${data.branchId}`,
    method:'get',
    params:data.params,
    onSuccess: slice.actions.getFromAll.type,
    onFail: slice.actions.getFromAll.type,
});
export const getTaskByProject=(data)=>apiCall({
    url: `/task/get-by-project/${data}`,
    method:'get',
    onSuccess: slice.actions.getFromAll.type,
    onFail: slice.actions.getFromAll.type,
});
export const getOneTask=(data)=>apiCall({
    url: `/task/`+data,
    method:'get',
    onSuccess: slice.actions.getTaskView.type,
    onFail: slice.actions.getTaskView.type,
});
export const saveTask=(data)=>apiCall({
    url: '/task',
    method:'post',
    data,
    onSuccess: slice.actions.savefrom.type,
    onFail: slice.actions.savefrom.type,
});
export const saveTaskDuplicate=(data)=>apiCall({
    url: '/task/duplicate-task/'+data,
    method:'post',
    onSuccess: slice.actions.savefrom.type,
    onFail: slice.actions.savefrom.type,
});
export const taskProduction=(data)=>apiCall({
    url: '/production/task-production',
    method:'post',
    data,
    onSuccess: slice.actions.savefrom.type,
    onFail: slice.actions.savefrom.type,
});
export const editTask=(data)=>apiCall({
    url: `/task/`+data.id,
    method:'put',
    data,
    onSuccess: slice.actions.editfrom.type,
    onFail: slice.actions.editfrom.type,
});

export const taskInvalidAmount=(data)=>apiCall({
    url: `/task/edit-invalid-amount-production/`+data.taskId,
    method:'put',
    data,
    onSuccess: slice.actions.invalidFrom.type,
    onFail: slice.actions.invalidFrom.type,
});
export const changeTaskStatusId=(data)=>apiCall({
    url: `/task/edit/${data.id}/${data.statusId}`,
    method:'put',
    onSuccess: slice.actions.editfrom.type,
    onFail: slice.actions.editfrom.type,
});
export const deleteTask=(data)=>apiCall({
    url: '/task/'+data,
    method:'delete',
    onSuccess: slice.actions.deletefrom.type,
    onFail: slice.actions.deletefrom.type,
});

export default slice.reducer