import './supplierProfile.css'
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import CardBody from "../../../../../Components/CardBody";
import img from "../../../../../../img/Jami2.svg";
import profile from "../../../../../../assets/Frame 1000005568.svg";
import moment from "moment/moment";
import totalTrade from "../../../../../../assets/fund accounting (1).svg";
import {prettify} from "../../../../../../util";
import profileProfit from "../../../../../../assets/money transfer.svg";
import CommonTable from "../../../../../Components/CommonTable";
import ExcelButtons from "../../../../../Components/ExcelButtons";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import React, {useEffect, useState} from "react";
import view from "../../../../../../assets/Subtract.svg";
import TaminotReducer,{getSupplierOne,getSupplierPurchaseInfo,getSupplierPreventedInfo} from "../../reducer/TaminotReducer";
import users from "../../../../../../reducer/users";

function SupplierProfile({getSupplierOne,TaminotReducer,match,getSupplierPurchaseInfo,getSupplierPreventedInfo}) {
    const {t} = useTranslation()
    const [viewCheck,setViewCheck] = useState(false)
    const [viewTrade,setViewTrade] = useState({})

    function isOpenViewCheck(id){
        const resultFilter = TaminotReducer.supplierPurchaseInfo.find(item=>id === item.purchase.id)
        setViewTrade(resultFilter)
    }

    useEffect(() => {
        getSupplierOne(match.params.id)
        getSupplierPurchaseInfo(match.params.id)
        getSupplierPreventedInfo(match.params.id)
    }, [])

    const [page, setPage] = useState(0);
    const [size, setSize] = useState(5);
    const handlePageChange = (newPage) => {
        setPage(newPage - 1);
    };
    const handleLimitChange = (event, size) => {
        setPage(0)
        setSize(size);
    };


    const columns = [
        {
            title: 'Sana',
            dataIndex: 'createAt',
            key: 'createAt',
            width: '13%',
            render: (item) =>
                <p className={'m-0 p-0'} style={{whiteSpace:'nowrap'}}>
                    {(moment(new Date(item)).format('L')).replace(/\//g, "-")}
                </p>
        },
        {
            title: 'Xarid chekini ko’rish',
            dataIndex: 'id',
            key: 'id',
            width: '22%',
            render: (item,value) =>
                <button className={'profile-check-button'} onClick={()=>{
                    setViewCheck(true)
                    isOpenViewCheck(value.purchase?.id)
                }}>
                    Chekni ko’rish
                    <img src={view} width={14} height={14} alt={item}/>
                </button>

        },
        {
            title: 'Narxi',
            dataIndex: 'totalSum',
            key: 'totalSum',
            width: '20%',
            render: (item,value) =>
                <p className={'m-0 p-0'} style={{whiteSpace:'nowrap'}}>
                    {prettify(value.purchase?.totalSum)} so'm
                </p>
        },
        {
            title: 'To’langan summasi',
            dataIndex: 'totalSum',
            key: 'totalSum',
            render: (item,value) =>
                <p style={{whiteSpace:'nowrap',
                    color: value?.purchase?.paidSum === 0 ? '#E02323' : value?.purchase?.paidSum > 0 && value?.purchase?.paidSum < value?.purchase?.totalSum? '#FF851F' : '#57CA22'
                }} className={'m-0 p-0'}>
                    {prettify(value?.purchase?.paidSum)} so'm
                </p>,
            width: '20%'
        },
        {
            title: 'Holati',
            dataIndex: 'totalSum',
            key: 'totalSum',
            render: (item,value) => <span style={{whiteSpace:'nowrap'}}  className={`profile-status
            profile-status-${value?.purchase?.paidSum === 0 ? 'red' : value?.purchase?.paidSum > 0 && value?.purchase?.paidSum < value?.purchase?.totalSum?  'warning' : 'green'}`}>
                {value?.purchase?.paidSum === 0 ? 'Qarzga savdo' : value?.purchase?.paidSum > 0 && value?.purchase?.paidSum < value?.purchase?.totalSum?  'Qisman tolangan' : 'Savdo tolandi'}
            </span>,
            width: '25%'
        },
    ];

    const [pageTwo, setPageTwo] = useState(0);
    const [sizeTwo, setSizeTwo] = useState(5);
    const handlePageChangeTwo = (newPage) => {
        setPageTwo(newPage - 1);
    };
    const handleLimitChangeTwo = (event, size) => {
        setPageTwo(0)
        setSizeTwo(size);
    };


    const columnsTwo = [
        {
            title: 'Sana',
            dataIndex: 'createAt',
            key: 'createAt',
            width: '12%',
            render: (item) =>
                <p className={'m-0 p-0'}>
                    {(moment(new Date(item)).format('L')).replace(/\//g, "-")}
                </p>
        },
        {
            title: 'Turi',
            dataIndex: 'paidSum',
            key: 'paidSum',
            width: '20%',
            render: (item) =>
                <p className={'m-0 p-0'}>
                    {item > 0 ? "To'landi" : 'Buyurtma'}
                </p>
        },
        {
            title: 'Qarz',
            dataIndex: 'debtSum',
            key: 'debtSum',
            render: (item) =>
                <span className={`profile-status 
            profile-status-${item > 0 ? 'red' : 'green'}`}>
                {prettify(item)} so'm
            </span>,
            width: '22%'
        },
        {
            title: 'To\'lov',
            dataIndex: 'paidSum',
            key: 'paidSum',
            render: (item) => <span className={`profile-status 
            profile-status-${item > 0 ? 'green' :  'red'}`}>
                {prettify(item)} so'm
            </span>,
            width: '18%'
        },
    ];

    const columnsTradeProduct = [
        {
            title: 'Mahsulot',
            dataIndex: 'id',
            key: 'id',
            width: '20%',
            render: (item,value) =>
                <p className={'m-0 p-0'}>
                    {value?.product?.name}
                </p>
        },
        {
            title: 'Miqdori',
            dataIndex: 'purchasedQuantity',
            key: 'purchasedQuantity',
            width: '15%',
            render: (item,value) =>
                <p className={'m-0 p-0'}>
                    {item} {value?.product?.measurement?.name}
                </p>
        },
        {
            title: 'Olish narxi',
            dataIndex: 'buyPrice',
            key: 'buyPrice',
            render: (item,value) =>
                <p className={'m-0 p-0'}>
                    {prettify(item)} so'm
                </p>,
            width: '15%'
        },
        {
            title: 'Sotish narxi',
            dataIndex: 'salePrice',
            key: 'salePrice',
            render: (item,value) =>
                <p className={'m-0 p-0'}>
                    {prettify(item)} so'm
                </p>,
            width: '15%'
        },
        // {
        //     title: 'Foyda',
        //     dataIndex: 'profit',
        //     key: 'profit',
        //     render: (item) => <span className={`profile-status
        //     profile-status-${item > 0 ? 'green' :  'red'}`}>
        //         {prettify(item)} so'm
        //     </span>,
        //     width: '25%'
        // },
        {
            title: 'Jami Summa',
            dataIndex: 'totalSum',
            key: 'totalSum',
            render: (item) => <p className={'m-0 p-0'}>{prettify(item)} so'm</p>,
            width: '25%'
        },
    ];


    const {name,phoneNumber,createdByName,createdAt,debtDollar,debt,purchaseTotalDollar,purchaseTotalSum} = TaminotReducer.oneSupplier

    return (
        <div className="row p-3">
            <div className="col-md-4">
                <CardBody>
                    <div className='d-flex justify-content-start align-items-top gap-3'>
                        <div>
                            <img width={64} height={64} src={profile} alt="profile"/>
                        </div>
                        <div className={'d-flex justify-content-between align-items-start flex-column'}>
                            <p className={'profile-name'}>
                                Ta'minotchi:<strong>{name}</strong> 👋🏻
                            </p>
                            <span className={'profile-active'}>
                            Faol
                        </span>
                        </div>
                    </div>
                    <div className='mt-4 row d-flex flex-column gap-3'>
                        <div className='col-md-9 d-flex justify-content-between align-items-center'>
                            <p className={'profile-detail'}>Telefon</p>
                            <strong className={'profile-detail'}>+{phoneNumber}</strong>
                        </div>
                        <div className='col-md-9 d-flex justify-content-between align-items-center'>
                            <p className={'profile-detail'}>Qo'shgan Hodim</p>
                            <strong className={'profile-detail'}>{createdByName}</strong>
                        </div>
                        <div className='col-md-9 d-flex justify-content-between align-items-center'>
                            <p className={'profile-detail'}>Qo'shilgan sana</p>
                            <strong
                                className={'profile-detail'}>{(moment(new Date(createdAt)).format('L')).replace(/\//g, ".")}</strong>
                        </div>
                    </div>
                    <div>
                        <div style={{background: '#EEF2FC'}} className={'profile-trade'}>
                            <div className={'d-flex justify-content-start align-items-end gap-2'}>
                                <img src={totalTrade} width={32} height={32} alt="totalTrade"/>
                                <p className={'profile-trade-text'}>Umumiy Xarid</p>
                            </div>
                            <div className={'mt-3'}>
                                <p className={'profile-trade-sum'}>{prettify(purchaseTotalSum)} so’m </p>
                                <p className={'profile-trade-sum'}>{prettify(purchaseTotalDollar)} $ </p>
                            </div>
                        </div>
                        {/*<div style={{background: '#DEF8DF'}} className={'profile-trade'}>*/}
                        {/*    <div className={'d-flex justify-content-start align-items-end gap-2'}>*/}
                        {/*        <img src={profileProfit} width={32} height={32} alt="profileProfit"/>*/}
                        {/*        <p className={'profile-trade-text'}>Mijozdan olingan daromad</p>*/}
                        {/*    </div>*/}
                        {/*    <div className={'mt-3'}>*/}
                        {/*        <p style={{color: '#05B06C'}}*/}
                        {/*           className={'profile-trade-sum'}>{prettify(1000000)} so’m fake</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </CardBody></div>
            <div className="col-md-8">
                <CardBody>
                    <div className={'row'}>
                        <div className="col-md-5 profile-report-texts">
                            <strong>Xaridlar va oldi-berdilar</strong>
                            <p>Ta'minotchidan xaridlar va qarzlar pul oldi berdilari</p>
                        </div>
                        <div className="col-md-7 d-flex justify-content-start align-items-center gap-4">
                            <div>
                                <p className={'profile-balance'}>Balance</p>
                            </div>
                            <div className={'d-flex justify-content-start align-items-center gap-3'}>
                                <div>
                                    <p className={'profile-currency'}>Dollarda</p>
                                    <span style={debtDollar > 0 ? {background: '#FFEFEF', color: '#E02323'} : {
                                        background: '#EEFAE8',
                                        color: '#57CA22'
                                    }} className={'profile-currency-total'}>{debtDollar} $</span>
                                </div>
                                <div>
                                    <p className={'profile-currency'}>So’mda</p>
                                    <span style={debt > 0 ? {background: '#FFEFEF', color: '#E02323'} : {
                                        background: '#EEFAE8',
                                        color: '#57CA22'
                                    }}
                                          className={'profile-currency-total'}> {debt?.toString().replace('-', "")} so'm</span>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4 table-responsive">
                            <CommonTable size={size} page={page} handleLimitChange={handleLimitChange}
                                         handlePageChange={handlePageChange}
                                         total={TaminotReducer.supplierPurchaseInfo?.length}
                                         pagination={true} data={TaminotReducer.supplierPurchaseInfo}
                                         columns={columns}
                                         scroll={{x: 1000, y: 330}}
                            />
                        </div>
                    </div>
                </CardBody>
            </div>
            <div className="col-md-12 mt-4">
                <CardBody>
                    <div className={'row'}>
                        <div className="col-md-12 profile-report-texts">
                            <ExcelButtons/>
                        </div>
                        <div className="mt-4 table-responsive">
                            <CommonTable size={sizeTwo} page={pageTwo} handleLimitChange={handleLimitChangeTwo}
                                         handlePageChange={handlePageChangeTwo}
                                         total={TaminotReducer.supplierPay?.length}
                                         pagination={true} data={TaminotReducer.supplierPay}
                                         columns={columnsTwo}
                                         scroll={{x: 1000, y: 330}}/>
                        </div>
                    </div>
                </CardBody>
            </div>

            <Modal isOpen={viewCheck} size={'xl'} toggle={() => setViewCheck(!viewCheck)}>
                <ModalHeader>
                    <div className="col-md-12 px-3 profile-report-texts d-flex gap-2 align-items-center flex-row">
                        <p>Xarid raqami:</p>
                        <strong className={'p-0 m-0'}>{viewTrade?.purchase?.invoice}</strong>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-md-6 profile-report-texts d-flex align-items-center flex-row">
                            <p>Xarid sanasi:</p>
                            <strong>{(moment(new Date(viewTrade.createAt)).format('L')).replace(/\//g, ".")}</strong>
                        </div>
                        <div className="col-md-6 profile-report-texts d-flex align-items-center flex-row">
                            <p>To'lov turi:</p>
                            <strong>{viewTrade.purchase?.paymentMethod?.type}</strong>
                        </div>
                        <div className="col-md-6 profile-report-texts d-flex align-items-center flex-row">
                            <p>To'lov Holati:</p>
                            <strong>{viewTrade.purchase?.paymentStatus?.status}</strong>
                        </div>
                        <div className="col-md-6 profile-report-texts d-flex align-items-center flex-row">
                            <p>Jami summa:</p>
                            <strong>{viewTrade.purchase?.totalSum} so'm</strong>
                        </div>
                        <div className="col-md-6 profile-report-texts d-flex align-items-center flex-row">
                            <p>To'langan summa:</p>
                            <strong>{viewTrade.purchase?.paidSum} so'm</strong>
                        </div>
                        <div className="col-md-6 profile-report-texts d-flex align-items-center flex-row">
                            <p>Qarz summa:</p>
                            <strong>{viewTrade.purchase?.debtSum} so'm</strong>
                        </div>
                        {/*<div className="col-md-6 profile-report-texts d-flex align-items-center flex-row">*/}
                        {/*    <p>Foyda</p>*/}
                        {/*    <strong>{viewTrade.purchase?.totalProfit} so'm</strong>*/}
                        {/*</div>*/}
                    </div>
                    <div className="row">
                        <CommonTable total={viewTrade?.purchaseProductlist?.length}
                                     pagination={false} data={viewTrade?.purchaseProductlist}
                                     columns={columnsTradeProduct}
                                     scroll={{x: 1000, y: 330}}
                        />
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default connect((TaminotReducer,users),{
    getSupplierOne,getSupplierPurchaseInfo,getSupplierPreventedInfo
})  (SupplierProfile)